export const modelConstants = {
	FETCH_ENTITIES_STARTED: "MODEL_FETCH_ENTITIES_STARTED",
	FETCH_ENTITIES_DONE: "MODEL_FETCH_ENTITIES_DONE",
	FETCH_ENTITY_STARTED: "MODEL_FETCH_ENTITY_STARTED",
	FETCH_ENTITY_DONE: "MODEL_FETCH_ENTITY_DONE",
	CREATE_ENTITY_STARTED: "MODEL_CREATE_ENTITY_STARTED",
	CREATE_ENTITY_DONE: "MODEL_CREATE_ENTITY_DONE",
	UPDATE_ENTITY_STARTED: "MODEL_UPDATE_ENTITY_STARTED",
	UPDATE_ENTITY_DONE: "MODEL_UPDATE_ENTITY_DONE",
	INVALIDATE_ENTITIES: "MODEL_INVALIDATE_ENTITIES",
	DELETE_ENTITY_STARTED:"MODEL_DELETE_ENTITY_STARTED",
	DELETE_ENTITY_DONE:"MODEL_DELETE_ENTITY_DONE",
	CLEAR_STATUS:"MODEL_CLEAR_STATUS"
	
}