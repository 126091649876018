import { galaxiaSpaceConstants, blocklyConstants } from "../constants";
import { galaxiaSpace, initialState } from "../reducers/galaxia_space_reducer";
import { appsActions } from "./model";
import * as api from "../api";

export const galaxiaSpaceActions = {
    initiateMainWorkspace,
    openModal,
    closeModal,
    openSyncModal,
    closeSyncModal,
    firstLoad,
    // titleOnChange,
    // remixApp,

    selectCurrentApp,
    setTemporaryApp,
    loadFromLocalStorage,
    // changeStatus,

    synchroCodeAce,
    synchroHybridCode,
    saveCurrentApp,
    saveBlocklyXml,
    loadBlocklyXml,
    loadBlocklyXmlDone,

    loadCode,
    loadCodePython,
    saveCodeAce,
    resetGalaxiaSpace,

    changeStateGalaxiaSpaceEditor,

    importApp
};

// function changeStatus(text) {
//     return (dispatch, getState) => {
//         dispatch({ type: galaxiaSpaceConstants.CHANGE_STATUS, payload: text})
//     }
// }

function resetGalaxiaSpace() {
    return (dispatch) => {
        dispatch({ type: galaxiaSpaceConstants.INVALIDATE_GALAXIA_SPACE_STATE });
    };
}

function initiateMainWorkspace() {
    return (dispatch, getState) => {
        let params = new URL(document.location).searchParams;
        let id_param = params.get("app");
        console.log("id_param", id_param);

        // On récupère ses Apps
        // dispatch(appsActions.getApps()).then((resp) => {
        //     let apps = resp.payload;
        //     if (apps.length > 0) {
        //         //Trie des apps par ordre de date de modification
        //         apps = apps.sort((a, b) => {
        //             return new Date(b.updatedAt) - new Date(a.updatedAt);
        //         });
        //         const appId = apps[0]._id;
        //         //On récupère la plus récente
        //         dispatch(galaxiaSpaceActions.selectCurrentApp(appId));
        //         // Si il n'en a pas : En créer une autre
        //     } else {
        // dispatch(galaxiaSpaceActions.setTemporaryApp());
        //     }
        // });

        // if (id_param) {
        //     dispatch(galaxiaSpaceActions.selectCurrentApp(id_param));
        // } else {
        dispatch(galaxiaSpaceActions.loadFromLocalStorage());
        // }
    };

    // return (dispatch, getState) => {
    //     dispatch(galaxiaSpaceActions.loadFromLocalStorage());
    // };
}

function loadFromLocalStorage() {
    return (dispatch, getState) => {
        dispatch({ type: galaxiaSpaceConstants.INVALIDATE_GALAXIA_SPACE_STATE });
        dispatch(galaxiaSpaceActions.loadBlocklyXml());
        dispatch(galaxiaSpaceActions.loadCode());
        dispatch(galaxiaSpaceActions.loadCodePython());
    };
}

function selectCurrentApp(appId) {
    return (dispatch, getState) => {
        dispatch({ type: galaxiaSpaceConstants.SELECT_APP_STARTED });

        return api.AppApi.getOne(appId)
            .then((resp) => {
                dispatch({ type: galaxiaSpaceConstants.INVALIDATE_GALAXIA_SPACE_STATE });
                dispatch({
                    type: galaxiaSpaceConstants.SELECT_APP_SUCCEEDED,
                    payload: resp.data,
                });
                dispatch(galaxiaSpaceActions.loadBlocklyXml());
                closeModal();
            })
            .catch((err) => {
                dispatch({
                    type: galaxiaSpaceConstants.SELECT_APP_FAILED,
                    payload: err,
                });
            });
    };
}

function setTemporaryApp(isFromDeleteApp) {
    return (dispatch, getState) => {
        //let galaxiaSpaceEditorState= getState().galaxiaSpace.galaxiaSpaceEditorState;
        dispatch({ type: galaxiaSpaceConstants.INVALIDATE_GALAXIA_SPACE_STATE, modalIsOpen: isFromDeleteApp });
        let apps = getState().model.apps.apps;
        let newAppState = Object.assign({}, initialState);

        // Si l'utilisateur a déja une application
        if (apps && apps.length > 0) {
            newAppState.nom = `${"Ma super application"} n°${apps.length + 1}`;
            // Si il n'en a pas
        } else {
            newAppState.nom = `${"Ma super application"} n°${1}`;
        }
        try{
            localStorage.setItem("thingz_galaxia_space_xml", newAppState.xml);
        }catch(e){
            console.log("failed setItem tempApp")
        }
        dispatch(galaxiaSpaceActions.loadBlocklyXml());
        dispatch({
            type: galaxiaSpaceConstants.SELECT_APP_SUCCEEDED,
            payload: newAppState,
            modalIsOpen: isFromDeleteApp,
        });
    };
}

//// Sauvegarde

function saveCurrentApp() {
    return (dispatch, getState) => {
        // Pas de gestion de connection pour galaxia.
        // if (getState().galaxiaSpace.readOnly) return;

        //  sauvegarde le xml dans le localstorage
        if (getState().galaxiaSpace.xml) {
            try {
                window.localStorage.setItem("thingz_galaxia_space_xml", getState().galaxiaSpace.xml);
            } catch (e) {
                dispatch({ type: galaxiaSpaceConstants.SAVE_XML_FAILED });
            }
        }

        // sauvegarde le code (même vide) de Ace dans le local storage
        try {
            if (getState().galaxiaSpace.codePy === null) {
                window.localStorage.setItem("thingz_galaxia_space_codeAcePy", "");
            } else {
                window.localStorage.setItem("thingz_galaxia_space_codeAcePy", getState().galaxiaSpace.codePy);
            }
        } catch (error) {
            dispatch({ type: galaxiaSpaceConstants.SAVE_CODE_ACE_FAILED });
        }
    };
}

// //Gestion du XML généré et utilisé par blockly ainsi que le C++ de "l'interface Hybride"

function saveBlocklyXml(xml, codeHybride) {
    return (dispatch, getState) => {
        if (getState().blockly.mainWorkspace && getState().blockly.mainWorkspace.getAllBlocks().length == 0) {
            dispatch(galaxiaSpaceActions.loadBlocklyXml());
        } else if (
            getState().blockly.mainWorkspace &&
            (getState().blockly.mainWorkspace.getAllBlocks().length > 1 || (getState().galaxiaSpace.codeHybride && getState().galaxiaSpace.codeHybride.localeCompare(codeHybride)))
        ) {
            dispatch({ type: galaxiaSpaceConstants.SAVE_XML, xml, codeHybride });
            if (
                getState().galaxiaSpace.readOnly ||
                (getState().blockly.mainWorkspace.getAllBlocks().length != 0 && xml && xml.match(/<xml xmlns="http:\/\/www.w3.org\/1999\/xhtml">[\s]*<variables>[\s]*<\/variables>[\s]*<\/xml>/g))
            )
                return;
            dispatch(saveCurrentApp());
        }
    };
}

function loadBlocklyXml() {
    return (dispatch, getState) => {
        let xml = null;
        //Utilisateur connecté?
        // const readOnly = getState().galaxiaSpace.readOnly;
        // if ((getState().session.authenticated && getState().session.checked) || readOnly) {
        //     xml = getState().galaxiaSpace.xml;
        // } else {
        try {
            xml = window.localStorage.getItem("thingz_galaxia_space_xml");
        } catch (e) {
            dispatch({ type: galaxiaSpaceConstants.LOAD_XML_FAILED });
        }
        // }
        dispatch({ type: galaxiaSpaceConstants.LOAD_XML, xml });
    };
}

function loadBlocklyXmlDone() {
    return { type: galaxiaSpaceConstants.LOAD_XML_DONE };
}

// Cette Action switch le mode d'affichage Blockly/Hybride/Ace
function changeStateGalaxiaSpaceEditor(currentState, targetState, readOnly) {
    // Si on va vers Blockly, met à jour un props pour déclancher la mise à jour et le rafraîchissement de l'affichage du code hybride & de Blockly
    if (targetState === "Blockly" || targetState === "BlocklyPython") {
        let xmlReadyToLoad = true;
        return { type: galaxiaSpaceConstants.EDITOR_STATE, targetState, readOnly, xmlReadyToLoad };
    }
    // ToDo : dans un SI target.startWith("Ace")
    // déplacer depuis galaxia.js l.203 : charge le code sauvegardé s'il existe OU lance la synchronisation automatique avec le Blockly courrant
    return { type: galaxiaSpaceConstants.EDITOR_STATE, targetState, readOnly };
}

function saveCodeAce(codeAce) {
    return (dispatch, getState) => {
        console.log("codeAce", codeAce);
        dispatch({ type: galaxiaSpaceConstants.SAVE_CODE_ACE, codeAce });
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            dispatch(saveCurrentApp());
        }, 500);
    };
}

// Load code Cpp only
function loadCode() {
    let codeAce = null;
    return (dispatch, getState) => {
        //utilisateur logué => on cherche le code sauvé dans le state
        // if (getState().session.authenticated && getState().session.checked) {

        //     //Si le code n'existe pas dans le state on le fait correspondre avec le code généré par blockly

        //     if (getState().galaxiaSpace.code == null || !getState().galaxiaSpace.code) {
        //         dispatch(synchroHybridCode());
        //         dispatch({ type: galaxiaSpaceConstants.SYNCHRO_CODE_ACE });

        //         return;
        //     }
        //     codeAce = getState().galaxiaSpace.code;

        // utilisateur non loggué => on cherche dans le local storage
        // } else {
        try {
            codeAce = window.localStorage.getItem("thingz_galaxia_space_codeAce");
            //on vérifie si le code récupéré existe ou pas
            // if (codeAce == null || !codeAce) {
            // 	dispatch(synchroHybridCode())
            // 	dispatch({ type: galaxiaSpaceConstants.SYNCHRO_CODE_ACE })
            // 	return;
            // }
        } catch (e) {
            dispatch({ type: galaxiaSpaceConstants.LOAD_CODE_FAILED });
        }
        // }
        return dispatch({ type: galaxiaSpaceConstants.LOAD_CODE, codeAce });
    };
}

function loadCodePython() {
    let codeAce = null;
    return (dispatch, getState) => {
        //utilisateur logué => on cherche le code sauvé dans le state
        // if (getState().session.authenticated && getState().session.checked) {
        //     //Si le code n'existe pas dans le state on le fait correspondre avec le code généré par blockly
        //     if (getState().galaxiaSpace.codePy == null || !getState().galaxiaSpace.codePy) {
        //         dispatch(synchroHybridCode());
        //         dispatch({ type: galaxiaSpaceConstants.SYNCHRO_CODE_ACE });

        //         return;
        //     }
        //     codeAce = getState().galaxiaSpace.codePy;

        //     // utilisateur non loggué => on cherche dans le local storage
        // } else {
        try {
            codeAce = window.localStorage.getItem("thingz_galaxia_space_codeAcePy");
            // //on vérifie si le code récupéré existe ou pas
            // if (codeAce == null || !codeAce) {
            // 	dispatch(synchroHybridCode())
            // 	dispatch({ type: galaxiaSpaceConstants.SYNCHRO_CODE_ACE })
            // 	return;
            // }
        } catch (e) {
            dispatch({ type: galaxiaSpaceConstants.LOAD_CODE_FAILED });
        }
        // }
        return dispatch({ type: galaxiaSpaceConstants.LOAD_CODE_PYTHON, codeAce });
    };
}

function synchroCodeAce() {
    return { type: galaxiaSpaceConstants.SYNCHRO_CODE_ACE };
}

function synchroHybridCode() {
    return (dispatch, getState) => {
        let state = getState();
        let code;

        try {
            code = window.Blockly.Galaxia.workspaceToCode(state.blockly.mainWorkspace);
            dispatch(saveBlocklyXml(state.galaxiaSpace.xml, code));
        } catch (error) {
            console.error("Blockly Python n'est pas encore configuré");
            code = "#Erreur de synchronisation\nwhile True:\n\tpass";

            dispatch(saveBlocklyXml(state.galaxiaSpace.xml, code));
        }
    };
}

// Gère l'ouverture de la Modal "SYNCHRO" de ACE
function openSyncModal() {
    return (dispatch) => {
        dispatch({ type: galaxiaSpaceConstants.OPEN_SYNC_MODAL });
    };
}

function closeSyncModal() {
    return (dispatch) => {
        dispatch({ type: galaxiaSpaceConstants.CLOSE_SYNC_MODAL });
    };
}

// Gère l'ouverture de la Modal "OUVRIR" les applications
function openModal() {
    return (dispatch) => {
        dispatch({ type: galaxiaSpaceConstants.OPEN_MODAL });
    };
}

function closeModal() {
    return (dispatch) => {
        dispatch({ type: galaxiaSpaceConstants.CLOSE_MODAL });
    };
}

function firstLoad() {
    return (dispatch) => dispatch({ type: galaxiaSpaceConstants.FIRST_LOAD });
}

function importApp(type, content){
    return (dispatch) => {
        switch(type){
            case "blockly":
                dispatch({ type: galaxiaSpaceConstants.LOAD_XML, xml:content });
                dispatch(changeStateGalaxiaSpaceEditor("Blockly", "Blockly", true))
            break;
            case "python":
                dispatch({ type: galaxiaSpaceConstants.LOAD_CODE_PYTHON, codeAce:content });
                dispatch(changeStateGalaxiaSpaceEditor("AcePython", "AcePython", true))
            break;
        }
    }
}

// function titleOnChange(nom) {
// 	return (dispatch) => {
// 		dispatch({ type: galaxiaSpaceConstants.TITLE_CHANGE, nom })

// 	}
// }

// function remixApp(appId){
// 	return (dispatch, getState) => {
// 		let appToRemix =  getState().galaxiaSpace
// 		if(appId !== appToRemix.id)
// 			return
// 		if (getState().session.authenticated && getState().session.checked) {
// 			dispatch({ type: galaxiaSpaceConstants.SAVE_NEW_APP_STARTED })
// 			return api.AppApi.clone(appToRemix).then(resp => {
// 				dispatch({ type: galaxiaSpaceConstants.SAVE_NEW_APP_SUCCEEDED, payload: resp.data })
// 				dispatch(galaxiaSpaceActions.loadBlocklyXml());
// 				dispatch(appsActions.getApps())
// 			})
// 			.catch(err => {
// 				console.log(err)
// 				dispatch({ type: galaxiaSpaceConstants.SAVE_NEW_APP_FAILED, payload: err })
// 			})
// 		}else{
// 			//not logged
// 			dispatch({ type: galaxiaSpaceConstants.SELECT_APP_STARTED });
// 			return api.AppApi.getOne(appId)
// 			.then(resp => {
// 				dispatch({ type: galaxiaSpaceConstants.INVALIDATE_GALAXIA_SPACE_STATE })
// 				let app = resp.data
// 				app.readOnly = false
// 				dispatch({
// 					type: galaxiaSpaceConstants.SELECT_APP_SUCCEEDED,
// 					payload: app
// 				});
// 				dispatch(galaxiaSpaceActions.saveCurrentApp());
// 				dispatch(galaxiaSpaceActions.loadBlocklyXml());
// 			})
// 			.catch(err => {
// 				dispatch({
// 					type: galaxiaSpaceConstants.SELECT_APP_FAILED,
// 					payload: err
// 				});
// 			});
// 		}
// 	}
// }
