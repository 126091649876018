import {modelConstants,cluesConstants} from '../../constants'
import * as api from "../../api";
import { normalize,denormalize } from 'normalizr'
import { clueSchema,cluesSchema } from "../../schemas";


export const cluesActions={
fetchFirstClue,
fetchAllByStep,
saveClues,
createClue,
deleteClue,
mergeClues}

function saveClues(){
	return(dispatch,getState)=>{
		
		let clues = Object.values(getState().model.clues.entities)
		console.log(clues)
		return api.clues.saveClues(clues)
		.then(response=>{

			let cluesListSchema=[clueSchema]
			let entities = normalize(response.data,cluesListSchema)
			console.log("entities",entities)
			dispatch({ type: modelConstants.FETCH_ENTITIES_DONE, originalEntities: "clues", entities })
		})
	}
}
function fetchFirstClue(currentStepId){
	return dispatch=>{
		dispatch({ type: modelConstants.FETCH_ENTITIES_STARTED, entities: "clues" });
		return api.clues.fetchFirstClue(currentStepId)
		.then(response => {
			let entities = normalize(response.data, clueSchema)
			
			dispatch({ type: modelConstants.FETCH_ENTITIES_DONE, originalEntities: "clues", entities })
		})
		.catch(error => {
			//Pas de première étape, on en crée une automatiquement
			
			//dispatch({ type:cluesConstants.FETCH_BY_COURSE_FAILED, error })
		})
	}
}

function fetchAllByStep(currentStepId){
	console.log(currentStepId)
	return dispatch=>{
		dispatch({type:modelConstants.FETCH_ENTITIES_STARTED,entites:"clues"});
		return api.clues.fetchAllByStep(currentStepId)
		.then(response=>{

			let cluesListSchema=[clueSchema]
			let entities = normalize(response.data,cluesSchema)
			console.log("entities",entities)
			dispatch({ type: modelConstants.FETCH_ENTITIES_DONE, originalEntities: "clues", entities })
			
		}).catch(error => {
			console.log('erreur',error)
			
			//dispatch({ type:cluesConstants.FETCH_BY_COURSE_FAILED, error })
		})

	}

}

function createClue(currentStepId,previousClueId){
	return (dispatch,getState)=>{
		dispatch({type:modelConstants.CREATE_ENTITY_STARTED,entities:'clues'});
		return api.clues.createClue(currentStepId,previousClueId)
		.then(response=>{
			let entities=normalize(response.data,clueSchema)
			console.log('entities',entities)
			let currentClues = getState().editor.currentClueId
			console.log('currentCLues',currentClues)
			currentClues.push(entities.result)
			console.log('result',currentClues)
			let actualState=getState().model.clues.entities;
			
			
			
		entities.entities.clues = this.mergeClues(actualState,entities.entities.clues)
		console.log('afterMerge',entities)
		console.log(entities.result)
		entities.result=currentClues


console.log(entities)
			dispatch({type: modelConstants.CREATE_ENTITY_DONE, originalEntities:'clues',entities})
		}).catch(error=>{
			console.log('error',error)
			dispatch({type:cluesConstants.CREATE_CLUE_FAILED})
		})
	}
}

function deleteClue(currentStepId,currentClueId){
	return(dispatch,getState)=>{
		dispatch({type:modelConstants.DELETE_ENTITY_STARTED,entities:"clue"});
		return api.clues.deleteClue(currentStepId,currentClueId)
		.then(response=>{

			let entities=normalize(response.data,clueSchema);
			console.log('entities from delete',entities)
			let currentClues=getState().editor.currentClueId;
			let state = getState().model.clues.entities
			for (let i=0;i<currentClues.length;i++){
				if(currentClues[i]==entities.result){
					currentClues.splice(i);
					 delete  state[i]
					 entities.entities.clues = state
					 entities.result=currentClues
					break;
				}
			dispatch({ type: modelConstants.DELETE_ENTITIES_DONE, originalEntities: "clues", entities })


			}


		})
	}
}


function mergeClues(actualClues,newClue){

		if(!newClue)
		newClue = {};
	for(let e in actualClues){
		if(newClue[e])
			continue;
		newClue[e] = {};
		newClue[e] = Object.assign({}, actualClues[e]);
	}
	return newClue;

}