import axios from 'axios';
import runtimeEnv from '@mars/heroku-js-runtime-env';

const BASE_USER_URL = runtimeEnv().REACT_APP_API_BASE_USER_URL;
const API_PASSWORD ='/reset-password';

export function resetPassword(user){
// Request API.
return axios
  .post(BASE_USER_URL+API_PASSWORD, {
    code:user.token,
    password:user.newPassword,
    passwordConfirmation: user.verifyNewPassword
  })

}