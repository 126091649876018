import { blocklyConstants } from "../constants";

export const blocklyActions = {
    mainWorkspaceInitiated,
    mainWorkspaceInvalid,
    solutionWorkspaceInitiated,
    solutionWorkspaceInvalid,
    switchBlocklyVersion,
};

function mainWorkspaceInitiated(workspace) {
    return { type: blocklyConstants.MAIN_WORKSPACE_INITIATED, workspace };
}

function mainWorkspaceInvalid() {
    return { type: blocklyConstants.MAIN_WORKSPACE_INVALID };
}

function solutionWorkspaceInitiated(workspace) {
    return { type: blocklyConstants.SOLUTION_WORKSPACE_INITIATED, workspace };
}

function solutionWorkspaceInvalid() {
    return { type: blocklyConstants.SOLUTION_WORKSPACE_INVALID };
}

function switchBlocklyVersion(version) {
    return { type: blocklyConstants.CHANGE_VERSION, version };
}
