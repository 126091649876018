import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";

import { HeaderFreeCreation } from "../../components/HeaderFreeCreation";
import { HeaderFreeCreationControls } from "../../components/HeaderFreeCreationControls";
import { ExtensionInformationComponent } from "../../components/ExtensionInformationComponent";
import { Blockly, ScriptVersion } from "../../containers/Blockly";
import { Extension } from "../Extension";
import { FloatingMessage } from "../../components/FloatingMessage";
import { getActualUser, getUser } from "../../reducers/user_reducer";
import { modalConstants, freeCreationConstants } from "../../constants";
import { modalActions } from "../../actions";
import { Ace } from "../../containers/Ace";
import { SerialMonitor } from "../SerialMonitor";
import { Simulator } from "../Simulator";

import { Button, Container, Grid, Icon, Popup } from "semantic-ui-react";

import { blocklyActions, extensionActions, freeCreationActions, galaxiaSpaceActions, notificationActions, serialMonitorActions } from "../../actions";

import { appsActions, modelActions, spreadsheetsActions } from "../../actions/model";

import runtimeEnv from "@mars/heroku-js-runtime-env";

import "./GalaxiaSpace.css";

class GalaxiaSpace extends Component {
    constructor(props) {
        super(props);
        this.state = {
            readOnly: false,
        };
    }

    componentDidMount() {
        document.title = "Espace Galaxia - Thingz";
        this.props.dispatch(galaxiaSpaceActions.initiateMainWorkspace(this.props.match.params));
    }

    componentDidUpdate(prevProps) {
        const values = queryString.parse(this.props.location.search);
        const valuesPrev = queryString.parse(prevProps.location.search);

        if (!values.app && valuesPrev.app) {
            this.props.dispatch(galaxiaSpaceActions.initiateMainWorkspace(this.props.match.params));
        }
    }

    componentWillUnmount() {
        this.props.dispatch(modelActions.invalidate());
        this.props.dispatch(notificationActions.deleteAllNotificationMessages());
        this.props.dispatch(galaxiaSpaceActions.resetGalaxiaSpace());
    }

    compilationRequest = () => {
        if (this.props.galaxiaSpace.galaxiaSpaceEditorState.startsWith("Blockly") || this.props.galaxiaSpace.galaxiaSpaceEditorState.startsWith("BlocklyAndAce")) {
            try {
                if (this.props.blockly.mainWorkspace === null) return;
                const code = window.Blockly.Galaxia.workspaceToCode(this.props.blockly.mainWorkspace);
                this.props.dispatch(extensionActions.sendMessage({ msg: { cmd: "compilation", data: code, url: runtimeEnv().REACT_APP_COMPILATION_SERVER, filterBoards: ["galaxia"] }, timeout: 180000 }));
            } catch (error) {
                console.log("Erreur blockly", error);
                if (error) {
                    this.props.dispatch(modalActions.addModalMessageMid({ type: modalConstants.MODAL_MESSAGE_TYPE.COMBINATED_ERROR }));
                }
                return;
            }
        } else if (this.props.galaxiaSpace.galaxiaSpaceEditorState.startsWith("Ace")) {
            // ToDo : retirer le cpp
            try {
                let code;
                let lang;
                if (this.props.galaxiaSpace.galaxiaSpaceEditorState === "AcePython") {
                    if (this.props.galaxiaSpace.codePy === null) {
                        this.props.dispatch(modalActions.addModalMessageMid({ type: modalConstants.MODAL_MESSAGE_TYPE.COMBINATED_ERROR }));
                        return;
                    }
                    code = this.props.galaxiaSpace.codePy;
                    lang = "py";
                } else {
                    if (this.props.galaxiaSpace.code === null) {
                        this.props.dispatch(modalActions.addModalMessageMid({ type: modalConstants.MODAL_MESSAGE_TYPE.COMBINATED_ERROR }));
                        return;
                    }
                    code = this.props.galaxiaSpace.code;
                    lang = "cpp";
                }
                this.props.dispatch(extensionActions.sendMessage({ msg: { cmd: "compilation", data: code, language: lang, url: runtimeEnv().REACT_APP_COMPILATION_SERVER, filterBoards: ["galaxia"] }, timeout: 180000 }));
            } catch (error) {
                console.log("Erreur ace", error);
                if (error) {
                    this.props.dispatch(modalActions.addModalMessageMid({ type: modalConstants.MODAL_MESSAGE_TYPE.COMBINATED_ERROR }));
                }
                return;
            }
            return;
        }
    };

    // génération du code en Ace/Hybride
    autoSaveXml = (xml, codePy, codeHybride) => {
        // console.log("autoSaveXml", this.props.galaxiaSpace.xml);
        // console.log("xml", xml);
        // console.log("codePy", codePy);
        // console.log("codeHybride", codeHybride);
        this.props.dispatch(galaxiaSpaceActions.saveBlocklyXml(xml, codePy, codeHybride));
    };

    autoSaveAce = (code) => {
        this.props.dispatch(galaxiaSpaceActions.saveCodeAce(code));
    };

    xmlLoaded = () => {
        this.props.dispatch(galaxiaSpaceActions.loadBlocklyXmlDone());
    };

    xmlLoadError = (e) => {
        this.props.dispatch(modalActions.addModalMessageMid({
        type: modalConstants.MODAL_MESSAGE_TYPE.BLOCKLY_XML_LOAD_FAILED,
        msg: e.message
        })
        )
        this.onImportApp("blockly", '')
    }

    onMainWorkspaceStatusChanged = (workspace) => {
        if (workspace !== null) {
            this.props.dispatch(blocklyActions.mainWorkspaceInitiated(workspace));
        } else {
            this.props.dispatch(blocklyActions.mainWorkspaceInvalid());
        }
    };

    synchroCodeAce = () => {
        this.props.dispatch(galaxiaSpaceActions.synchroHybridCode());
        this.props.dispatch(galaxiaSpaceActions.synchroCodeAce());
        this.props.dispatch(galaxiaSpaceActions.saveBlocklyXml(this.props.galaxiaSpace.xml, this.props.galaxiaSpace.codeHybride));
    };

    // ToDo : créer & commenter les méthodes handleAppOpening
    // handleOpening = () => {
    //     this.props.dispatch(galaxiaSpaceActions.openSyncModal());
    // };

    triggerSyncButton = () => {
        return (
            <Popup
                basic
                position="top center"
                trigger={
                    <Button size="small" icon color="purple" onClick={this.handleSyncModalOpening}>
                        <Icon name="sync" />
                    </Button>
                }>
                Synchro
            </Popup>
        );
    };

    handleSyncModalOpening = () => {
        this.props.dispatch(galaxiaSpaceActions.openSyncModal());
    };

    closeSyncModal = () => {
        this.props.dispatch(galaxiaSpaceActions.closeSyncModal());
    };

    //gestion de la base
    getBoardStatus = () => {
        if (this.props.extension.isComOpen) return { color: "green", title: "Ta base est bien détectée", autoConnect: this.props.extension.autoConnect };
        if (this.props.extension.boardStatus === "IN_BOOTLOADER") return { color: "blue", title: "Ta base est en mode démarrage", autoConnect: this.props.extension.autoConnect };
        if (this.props.extension.boardStatus === "FOUND") return { color: "yellow", title: "Ta base est en mode démarrage", autoConnect: this.props.extension.autoConnect };
        return { color: "red", title: "Aucune base n'est détectée", autoConnect: this.props.extension.autoConnect };
    };

    onBoardDisconnect = () => {
        this.props.dispatch(extensionActions.close());
    };

    onBoardConnect = () => {
        this.props.dispatch(extensionActions.open());
    };

    onBoardAutoConnect = () => {
        this.props.dispatch(extensionActions.toggleAutoConnect());
    };

    remixApp = () => {
        if (this.props.galaxiaSpace.id) {
            this.props.dispatch(galaxiaSpaceActions.remixApp(this.props.galaxiaSpace.id));
        }
    };

    //Dispatche l'action qui va changer le mode d'affichage piloté par les trois boutons de HeaderFreeCreationControls.js
    changeStateGalaxiaSpaceEditor = (currentState, targetState, readOnly) => {
        this.props.dispatch(galaxiaSpaceActions.changeStateGalaxiaSpaceEditor(currentState, targetState, readOnly));
        this.props.dispatch(galaxiaSpaceActions.synchroHybridCode());

        // charge le code sauvegardé s'il existe OU synchronise automatique avec le Blockly courrant
        let codeAceLocal = null
        try {
            window.localStorage.getItem("thingz_galaxia_space_codeAcePy") 
        } catch (error) {
            console.log("failed getItem codeAce")
        }
        if (codeAceLocal) {
            this.props.dispatch(galaxiaSpaceActions.loadCodePython());
        } else {
            // this.synchroCodeAce();
            // Synchronise le code à afficher et le sauvegarde dans le local storage
            this.props.dispatch(galaxiaSpaceActions.synchroCodeAce());
            this.props.dispatch(galaxiaSpaceActions.saveBlocklyXml(this.props.galaxiaSpace.xml, this.props.galaxiaSpace.codeHybride));
        }
    };

    onImportApp = (type, content) => {
        this.props.dispatch(galaxiaSpaceActions.importApp(type, content))
    }

    //Render qui ne renvoie que le Workspace de Blockly
    renderBlockly = () => {
        let mode = "python";
        return (
            <Grid.Row className={"innerRows"} style={{ flex: 1 }} columns="equal">
                <Grid.Column id={"columnTarget"} style={{ height: "100%" }}>
                    <Container id={"FreeCreationBlocklyContainer"}>
                        <Blockly
                            autoSave={this.autoSaveXml}
                            codeGeneration={mode}
                            lang={window.LANG}
                            onMainWorkspaceStatusChanged={this.onMainWorkspaceStatusChanged}
                            readOnly={this.props.galaxiaSpace.readOnly}
                            version={ScriptVersion.BlocklyGalaxia}
                            xml={this.props.galaxiaSpace.xml}
                            xmlReadyToLoad={this.props.galaxiaSpace.xmlReadyToLoad}
                            xmlLoaded={this.xmlLoaded}
                            onLoadXmlError={this.xmlLoadError}
                        />
                    </Container>
                </Grid.Column>
            </Grid.Row>
        );
    };

    //Render qui renvoie le Workspace de Blockly et la console de Ace
    renderBlocklyAndAce = () => {
        let mode = "python";
        let init = "while True:\r\n\tpass\r\n";

        return (
            <Grid.Row className={"innerRows"} style={{ flex: 1 }} columns="equal">
                <Grid.Column id={"columnTarget"} style={{ height: "100%" }}>
                    <Container id={"FreeCreationBlocklyContainer"}>
                        <Blockly
                            autoSave={this.autoSaveXml}
                            codeGeneration={mode}
                            lang={window.LANG}
                            onMainWorkspaceStatusChanged={this.onMainWorkspaceStatusChanged}
                            readOnly={this.props.galaxiaSpace.readOnly}
                            version={ScriptVersion.BlocklyGalaxia}
                            xml={this.props.galaxiaSpace.xml}
                            xmlReadyToLoad={this.props.galaxiaSpace.xmlReadyToLoad}
                            xmlLoaded={this.xmlLoaded}
                        />
                    </Container>
                </Grid.Column>
                <Grid.Column id={"FreeAceComponent"} width={5}>
                    <div id="aceEditorDiv" style={{ height: 100 + "%", width: 100 + "%" }}>
                        <Ace value={this.props.galaxiaSpace.codeHybride || init} readOnly={true} mode={mode} />
                    </div>
                </Grid.Column>
            </Grid.Row>
        );
    };

    //Render qui renvoie Ace Uniquement
    renderAce = () => {
        let mode = "python";
        let value = this.props.galaxiaSpace.codePy;

        return (
            <Grid.Row className={"FreeCreationRowAce"} style={{ flex: 1, width: "100%" }} centered columns={1} verticalAlign="middle">
                <Grid.Column id={"FreeAceComponentAlone"} style={{ height: "100%" }}>
                    <div id="" style={{ height: 100 + "%", width: 100 + "%" }}>
                        <Ace autoSaveAce={this.autoSaveAce} mode={mode} readOnly={this.props.galaxiaSpace.readOnly} value={value} synchroCode={this.synchroCodeAce} />
                    </div>
                </Grid.Column>
            </Grid.Row>
        );
    };

    //Render general qui va permettre de switcher entre les trois modes en un seul appel de fonction dans le JSX ci-dessous
    renderGalaxiaSpaceEditor = () => {
        //changement pour prendre en compte le fait qu'il y ait plusieurs B&A
        if (this.props.galaxiaSpace.galaxiaSpaceEditorState.startsWith("BlocklyAndAce")) {
            return this.renderBlocklyAndAce();
        }
        if (this.props.galaxiaSpace.galaxiaSpaceEditorState.startsWith("Ace")) {
            return this.renderAce();
        }
        return this.renderBlockly();
    };

    renderSerialMonitor = () => {
        if (!this.props.serialMonitor.open) return null;
        return (
            <Grid.Row className={"FreeCreationRow"} centered>
                <Grid.Column style={{ width: "80%" }}>
                    <SerialMonitor />
                </Grid.Column>
            </Grid.Row>
        );
    };

    render() {
        const boardStatus = this.getBoardStatus();
        return (
            <Grid id="freeCreationGrid">
                <FloatingMessage messages={this.props.messages} />
                <Grid.Row className="FreeCreationRow">
                    <Extension galaxia={true}/>
                    <HeaderFreeCreation notification={this.props.notification} user={this.props.user} galaxia={true}/>
                    <HeaderFreeCreationControls
                        // autoSave={this.props.freeCreation.readOnly?false:this.autoSave}
                        autoSave={this.autoSave}
                        appSavingState={this.props.galaxiaSpace.appState}
                        onCompileClick={this.compilationRequest}
                        compilationLoading={this.props.extension.pendingCompilation}
                        user={this.props.user}
                        // conservation du nom freecreation pour conserver le fonctionnment des headers
                        freeCreation={this.props.galaxiaSpace}
                        changeStateGalaxiaSpaceEditor={this.changeStateGalaxiaSpaceEditor}
                        editorState={this.props.galaxiaSpace.galaxiaSpaceEditorState}
                        synchroCodeAce={this.synchroCodeAce}
                        triggerSyncButton={this.triggerSyncButton}
                        syncModalIsOpen={this.props.galaxiaSpace.syncModalIsOpen}
                        closeSyncModal={this.closeSyncModal}
                        // onRemixClick={this.remixApp}
                        // ToDo : passer cette valeur via un props retrouvable lors du switch Ace => hybride/Blockly
                        fromGalaxiaSpace={true}
                        xml={this.props.galaxiaSpace.xml}
                        codeHybride={this.props.galaxiaSpace.codeHybride}
                        codeAce={this.props.galaxiaSpace.codePy}
                        onImportApp={this.onImportApp}
                    />
                </Grid.Row>
                {this.renderGalaxiaSpaceEditor()}
                {/* {this.renderSerialMonitor()} */}
                <Simulator />
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        extension: state.extension,
        messages: state.modal.messages,
        user: getActualUser(state),
        session: state.session,

        apps: state.model.apps,

        // ToDo : renomer ce props englobant freeC & Gspace
        galaxiaSpace: state.galaxiaSpace,

        galaxiaSpace_isLoading: state.galaxiaSpace.isLoading,
        blockly: state.blockly,
        code: state.galaxiaSpace.code,

        serialMonitor: state.serialMonitor,
        spreadsheets: state.model.spreadsheets,
        notification: state.notification,
    };
}

const connectedGalaxiaSpace = connect(mapStateToProps)(GalaxiaSpace);
export { connectedGalaxiaSpace as GalaxiaSpace };
