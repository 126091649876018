import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';

import {Grid, Header, Card, Button, Placeholder, Message, Divider, Icon, Input} from 'semantic-ui-react'

import './CoursesComponent.css'

import CourseSummaryComponent from '../CourseSummaryComponent/CourseSummaryComponent';
import {ErrorComponent} from '../ErrorComponent'
import { timingSafeEqual } from 'crypto';

class CoursesComponent extends Component {

    constructor(props){
        super(props);
        this.state = {
            link_is_copied: false,
        }

        this.started = []
        this.unlock = []
        this.lock = []
        this.redirectToCreation = this.redirectToCreation.bind(this);
    }

    componentDidUpdate(prevProps){
        
    }

    componentDidMount(prevProps){

    }

    renderContext(error){

        return (
            <div>
                {this.getStructureOwnerContext()}
                {/* Courses are blocked also for students plus this doesn't work since groups introduction */}
                {/* {this.getStructureMemberContext()} */}
                {this.getStandardCoursesContext()}
            </div>
        )
    }

    getStructureOwnerContext(){ 
        if(!this.props.user)
            return null;

        let structureOwned = this.props.users.entities[this.props.user._id] ? this.props.structures.entities[this.props.users.entities[this.props.user._id].structureOwned] : undefined;

        if(structureOwned) {
            let started = this.coursesStarted();
            let allCourses = [
                ...this.coursesUnlocked(this.coursesStarted()),
                ...this.coursesLocked(this.coursesStarted()),
            ].sort()

            return (
                <div>
                    <Grid.Row>
                        <Grid.Column>
                            <Message info style={{display:'block'}}>
                                <Message.Header>Compte Enseignant</Message.Header>
                                <p>Tous les parcours ont été débloqués pour vous</p>
                            </Message>
                        </Grid.Column>
                    </Grid.Row>
                    <Divider horizontal hidden></Divider>
                    <Grid.Row>
                        <Header size={'medium'}>Reprendre</Header>
                        {(started.length > 0 && (<Card.Group>{started}</Card.Group>)) || (this.isLoading() && this.renderLoading()) || this.renderNoStarted() }
                    </Grid.Row>
                    <Divider horizontal hidden></Divider>
                    <Grid.Row>
                        <Header size={'medium'}>Tous les parcours</Header>
                        {(allCourses.length > 0 && (<Card.Group>{allCourses}</Card.Group>)) || (this.isLoading() &&  this.renderLoading()) || this.renderNoUnlock()}
                    </Grid.Row>
                </div>
            )
        } else {
            return null
        }
    }

    getStructureMemberContext(displayActivities = false){
        if(!this.props.user)
            return null;

        let structure = this.props.users.entities[this.props.user._id] ? this.props.structures.entities[this.props.users.entities[this.props.user._id].structure] : undefined;
        let structureOwned = this.props.users.entities[this.props.user._id] ? this.props.structures.entities[this.props.users.entities[this.props.user._id].structureOwned] : undefined;

        if(structure && !structureOwned && !displayActivities) {
            let started = this.coursesStarted();
            let unlock = this.coursesUnlocked(started);
            let lock = this.coursesLocked(started);
        
            return (
                <div>
                    <Grid.Row>
                        <Header size={'medium'}>Mes parcours</Header>
                            
                        {(started.length > 0 && (<Card.Group>{started}</Card.Group>)) || (this.isLoading() &&  this.renderLoading()) || this.renderNoStarted()}
                      
                    </Grid.Row>

                    <Divider horizontal hidden></Divider>
                    <Grid.Row>
                        <Header size={'medium'}>A découvrir</Header>
                        
                        {(unlock.length > 0 && (<Card.Group>{unlock}</Card.Group>)) || (this.isLoading() &&  this.renderLoading()) || this.renderNoUnlock()}
                      
                    </Grid.Row>
                </div>
            )
        } 
        else if (displayActivities) {
            let activities = this.activities();

            return (
                <div>
                    <Grid.Row>
                        {(activities.length > 0 && (<Card.Group>{activities}</Card.Group>)) || this.renderNoActivity()}
                    </Grid.Row>
                </div>
            )
        }
        else {
            return null
        }
    }

    getStandardCoursesContext(){
        if(!this.props.user)
            return null;

        let structure = this.props.users.entities[this.props.user._id] ? this.props.structures.entities[this.props.users.entities[this.props.user._id].structure] : undefined;
        let structureOwned = this.props.users.entities[this.props.user._id] ? this.props.structures.entities[this.props.users.entities[this.props.user._id].structureOwned] : undefined;

        if(!structureOwned){
            let started = this.coursesStarted();
            let unlock = this.coursesUnlocked(started);
            let lock = this.coursesLocked(started);
    
            return (
                <div>
                    <Grid.Row>
                        <Header size={'medium'}>Mes parcours</Header>
                        {(started.length > 0 && (<Card.Group>{started}</Card.Group>)) || (this.isLoading() &&  this.renderLoading()) || this.renderNoStarted()}
                    </Grid.Row>
                    <Divider horizontal hidden></Divider>
                    <Grid.Row>
                        <Header size={'medium'}>À découvrir</Header>
                        {(unlock.length > 0 && (<Card.Group>{unlock}</Card.Group>)) || (this.isLoading() &&  this.renderLoading()) || this.renderNoUnlock()}
                    </Grid.Row>
                    <Divider horizontal hidden></Divider>
                    <Grid.Row>
                        <Header size={'medium'}>
                            <Header.Content>À débloquer</Header.Content>
                        </Header>
                        {(lock.length > 0 && (<Card.Group>{lock}</Card.Group>)) || (this.isLoading() &&  this.renderLoading()) || this.renderNoLock() }
                    </Grid.Row>
                </div>
            )
        }
        return null
    }

    renderCourseSummary() {
        let summary = []
        for(let course in this.props.courses.entities ){
            let progression = null;
            for(let p in this.props.progressions.entities){
                let step = this.props.steps.entities[this.props.progressions.entities[p].step];
                if(step && step.course === this.props.courses.entities[course]._id){
                    progression = this.props.progressions.entities[p];
                    break;
                }
            }
            let currentStep = progression ? this.props.steps.entities[progression.step] : null;
            currentStep = currentStep ? progression.ended ? course.stepsCount : currentStep.currentPositionInCourse-1 : null;
            summary.push((<CourseSummaryComponent role={this.props.role} key={this.props.courses.entities[course]._id} course={this.props.courses.entities[course]} started={progression !== null} currentStep={currentStep}/>))
        }
        return summary
    }

    renderLoading(){
        let l = [];
        for(let i = 0; i<3; i++){
            l.push((<Placeholder key={i}>
                <Placeholder.Header image>
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Header>
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Paragraph>
            </Placeholder>))
        }
        return l
    }

    redirectToCreation() {
        this.props.history.push(`/creation`)
    }

    renderError(){
        // let error = {};
        // if(this.props.courses.error === null)
        //     return null;
        // try{
        //     console.log(this.props.courses.error.response.data.message.message)
        //     error.header = this.props.courses.error.response.data.message.message
        //     if(!error.header && (typeof this.props.courses.error.response.data.message === 'string' || typeof this.props.courses.error.response.data.error === 'string')){
        //         error.header = typeof this.props.courses.error.response.data.message === 'string' ? this.props.courses.error.response.data.message : this.props.courses.error.response.data.error;
        //     }

        // }catch(err){
        //     try{
        //         error.header = this.props.courses.error.response.data.message
        //     }catch(err){
        //         error.header = 'Une erreur est survenue'
        //         if(typeof this.props.courses.error === 'string')
        //             error.header = this.props.courses.error
        //     }
        // }

        // try{
        //     if(this.props.courses.error.response.data.message.name === "accountNotConfirmed")
        //         error.details = "Pour accéder aux parcours "+this.props.courses.error.response.data.message.data.details;
        //     else
        //         error.details = this.props.courses.error.response.data.message.data.details;
        // }catch(err){
        //    error.details = null;
        // }
        // return error;
        return <ErrorComponent error={this.props.courses.error}  forbidden={{header: "Compte non confirmé", details: "Pour accéder aux parcours ouvre l'email d'activation que nous t'avons envoyé. Grâce aux parcours, tu vas apprendre à utiliser ton kit et à programmer dans l'espace de création. Ils te donneront plein d'idées de créations."}}/>
    }


    renderNoStarted(){
        return (
            <Message>
                {/* Ajout d'un boutons redirigeant vers la page des parcours ??? */}
                <Message.Header>Tu n'as pas encore commencé de parcours</Message.Header>
            </Message>
        )
    }

    renderNoUnlock(){
        return (
            <Message>
                <Message.Header>Tu as commencé tous les parcours débloqués</Message.Header>
            </Message>
        )
    }

    renderNoLock(){
        return (
            <Message success>
                <Message.Header>Félicitations! Tu as débloqué tous les parcours</Message.Header>
                <Message.Content>
                    D'autres arriveront bientôt 😉
                </Message.Content>
            </Message>
        )
    }

    renderNoActivity() {
        return (
            <Message>
                <Message.Header>Il n'y a pas d'activité pour le moment</Message.Header>
            </Message>
        )
    }

    activities() {
        let courses = {}
		Object.values(this.props.finishedCourses.entities).forEach(f => {
            const course = this.props.courses.entities[f.course]
    
			if(course && course.isActivity && f.user === this.props.user._id){
				if(!courses[f.course]){
					courses[f.course] = {finished: f, course: course, currentStep:course.stepsCount, started: true}
				}else{
					if(f.createdAt > courses[f.course].finished.createdAt){
						courses[f.course].finished = f
					}
				}
			}
		})

		Object.values(this.props.progressions.entities).forEach(p => {
            if(p.user !== this.props.user._id)
                return;
            const step = this.props.steps.entities[p.step];
            
			if(step){
				const course = this.props.courses.entities[step.course]
				if(course && course.isActivity){
					if(!courses[step.course]){
						courses[step.course] = {progression: p, course: course, currentStep: p.ended ? course.stepsCount : step.currentPositionInCourse-1, started: true}
					}else{
						if(!courses[step.course].progression){
							courses[step.course].progression = p
                            courses[step.course].currentPositionInCourse = p.ended ? course.stepsCount : step.currentPositionInCourse-1
                            if(courses[step.course].finished){
                                courses[step.course].update = courses[step.course].finished.xml != courses[step.course].progression.xml
                            }
						}else if(courses[step.course].progression.createdAt < p.createdAt || courses[step.course].progression.updatedAt < p.updatedAt){
							courses[step.course].progression = p
							courses[step.course].currentPositionInCourse = p.ended ? course.stepsCount : step.currentPositionInCourse-1
                            if(courses[step.course].finished){
                                courses[step.course].update = courses[step.course].finished.xml != courses[step.course].progression.xml
                            }
                        }
					}
				}
			}
        })
        
		Object.values(this.props.courses.entities).forEach(course => {
			if(!courses[course._id] && course.isActivity){
				courses[course._id] = {currentStep: 0, course:course, started:false}
			}
		})

        courses = Object.values(courses)
        .sort((a, b) => {
            return  b.course.updatedAt > a.course.updatedAt 
        })
        .map(c => {
			return (<CourseSummaryComponent key={c.course._id}  user={this.props.user} progression={c.progression} finished={c.finished} course={c.course} currentStep={c.currentStep}  started={c.started} update={c.update || false} locked={false}/>)
        })
        return courses
        // let activities = [];
        // if(!this.props.courses.entities) {
        //     return activities;
        // }   

        // if (this.props.progressions.entities) {
        //     for (let progression in this.props.progressions.entities) {
        //         progression = this.props.progressions.entities[progression];
        //         let step = this.props.steps.entities[progression.step];
    
        //         if (step) {
        //             if (this.props.courses.entities[step.course]) {
        //                 let course = this.props.courses.entities[step.course];
        //                 if (!course.isActivity) {
        //                     continue;
        //                 }

        //                 let finish = false;
        //                 for (let finishCourse in this.props.finishedCourses.entities) {
        //                     finishCourse = this.props.finishedCourses.entities[finishCourse];
        //                     if (finishCourse.user === progression.user && finishCourse.course === course._id) {
        //                         let update = finishCourse.endedXml !== progression.xml ? true : false
        //                         activities.push((<CourseSummaryComponent key={course._id} user={this.props.user} course={course} progression={progression} finished={finishCourse} update={update} currentStep={course.stepsCount} started={true} locked={false} />));
        //                         finish = true;
        //                     }
        //                     continue;				
        //                 }
        //                 if (finish) {
        //                     continue;
        //                 }
        //                 activities.push((<CourseSummaryComponent key={course._id} user={this.props.user} course={course} progression={progression} update={true} currentStep={progression.ended ? course.stepCount : step.currentPositionInCourse -1} started={true} locked={false} />))
        //             }
        //         }
        //     }
        // }

        // for (let course in this.props.courses.entities) {
        //     course = this.props.courses.entities[course];
        //     if (course.isActivity && !activities.find(e => {return e.key === course._id})) {
        //         activities.push((<CourseSummaryComponent role={this.props.role} key={course._id} course={course} started={false} locked={false} />));
        //     }
        // }
        
        // return activities.sort((a, b) => {
		// 	a = new Date(a.props.course.updatedAt);
		// 	b = new Date(b.props.course.updatedAt);
		// 	return b - a;
		// });
    }

    coursesStarted(){
        let courses = [];
        if(!this.props.courses.entities || !this.props.progressions.entities || !this.props.steps.entities || !this.props.finishedCourses.entities)
            return courses;
        
        for(let p in this.props.progressions.entities){
            p = this.props.progressions.entities[p];
            if(p.user !== this.props.user._id)
                continue
            const step = this.props.steps.entities[p.step];

            if(step){
                if(this.props.courses.entities[step.course]){
                    if (this.props.courses.entities[step.course].isActivity) {
                        continue;
                    }
                    let course = this.props.courses.entities[step.course];
                    let finished = course.finishedcourses && course.finishedcourses[0] && this.props.finishedCourses.entities[course.finishedcourses[0]]? this.props.finishedCourses.entities[course.finishedcourses[0]] : null;
                    courses.push((<CourseSummaryComponent role={this.props.role} key={course._id} course={course} started={true} locked={false} callback={this.props.callbackAppDeleteProgress} currentStep={p.ended ? course.stepsCount : step.currentPositionInCourse-1} finished={finished}/>));
                } 
            }
        }
        return courses;
    }
    
    

    coursesUnlocked(coursesStarted){
        let courses = [];
        if(!this.props.courses.entities || !this.props.finishedCourses.entities)
            return courses;
        for(let c in this.props.courses.entities){
            c = this.props.courses.entities[c];
            if(c.unlockedUsers && c.unlockedUsers[0] && !coursesStarted.find(s => {return s.key === c._id})){
                let finished = c.finishedcourses && c.finishedcourses[0] && this.props.finishedCourses.entities[c.finishedcourses[0]] ? this.props.finishedCourses.entities[c.finishedcourses[0]] : null;
                courses.push((<CourseSummaryComponent role={this.props.role} key={c._id} course={c} started={false} locked={false} finished={finished}/>))
            }
        }
        return courses
    }

    coursesLocked(coursesStarted){
        let courses = [];
        if(!this.props.courses.entities || !this.props.finishedCourses.entities)
            return courses;
        for(let c in this.props.courses.entities){
            c = this.props.courses.entities[c];
            if(c.lockedUsers && c.lockedUsers[0] && !coursesStarted.find(s => {return s.key === c._id})){
                let finished = c.finishedcourses && c.finishedcourses[0] && this.props.finishedCourses.entities[c.finishedcourses[0]] ? this.props.finishedCourses.entities[c.finishedcourses[0]] : null;
                courses.push((<CourseSummaryComponent role={this.props.role} key={c._id} course={c} started={false} locked={true} finished={finished}/>))
            }
        }
        return courses
    }

    userIsStructureOwner(){
        return Object.values(this.props.structures.entities).find(structure => {
            return structure.owner === this.props.user._id
        })
    }

    isLoading(){
        return (this.props.courses.isLoading >0 || this.props.courses.error || this.props.courses.entities === null)
    }

    structureUrl(structure){
        if(structure) {
            return `${window.location.origin.toString()}/g/${structure.pin}`
        } else {
            return ''
        }
    }

    handleCopy(){
        /* Get the text field */
        let copyText = document.getElementById("myStructureUrl")
        /* Select the text field */
        copyText.select()
        /* Copy the text inside the text field */
        document.execCommand("copy")
        /* Deselect the input value */
        this.clearSelection()
        /* Alert the copied text */

        this.setState({link_is_copied: true})
    }

    clearSelection(){
        if(window.getSelection){
            window.getSelection().removeAllRanges()
        }else if(document.selection){
            document.selection.empty()
        }
    }

    render() {
        
        let error = this.renderError();
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column floated={'left'} width={8}>
                        <Header size={'huge'}>{this.props.user && (this.props.user.structure || this.userIsStructureOwner) ? 'Activités' : 'Parcours'}</Header>
                    </Grid.Column>
                        
                    <Grid.Column width={8}>
                        <Button floated={'right'} compact={true} onClick={this.redirectToCreation} color='orange' basic size={'big'}>
                        Création libre
                        </Button>
                    </Grid.Column>
                </Grid.Row>

                {this.props.user && (this.props.user.structure || this.userIsStructureOwner()) &&
                <Fragment>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            {this.getStructureMemberContext(true)}
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column floated={'left'} width={8}>
                            <Header size={'huge'}>Parcours</Header>
                        </Grid.Column>
                    </Grid.Row>
                </Fragment>}

                <Grid.Row>
                    <Grid.Column width={16}>
                        {error !== null && (
                            error
                        )}
                        {/* <Divider horizontal hidden></Divider> */}

                        {this.renderContext(error)}

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export default withRouter(CoursesComponent)