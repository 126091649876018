import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Container, Form, TextArea, Button, Label, Icon } from 'semantic-ui-react'
import { 
	serialMonitorActions,
	extensionActions
} from '../../actions';

import './SerialMonitor.css'

class SerialMonitor extends Component {

	constructor(){
		super();
		this.state = { inputValue: ""}
	}

	componentDidUpdate(){
		let textarea = document.getElementById('monitor');
		textarea.scrollTop = textarea.scrollHeight;
	}
	
	onInputChange = (event) =>{
		this.setState({inputValue: event.target.value})
	}

	onSendClick = () => {
		this.props.dispatch(serialMonitorActions.push("\r\n>>>"+this.state.inputValue+"\r\n"))
		this.props.dispatch(extensionActions.sendMessage({msg:{cmd:"write", value:this.state.inputValue}, timeout:200}))
	}

	onCloseSerialClick = () => {
		this.props.dispatch(serialMonitorActions.close())
	}

	render(){
		return (
			<Form>
				<Label size="large" id="serialClose" icon='delete' circular color='red' floating title="Fermer le moniteur" onClick={this.onCloseSerialClick}/>
    		<TextArea rows={15} id="monitor" value={this.props.serialMonitor.log} style={{resize: "none"}}>
    		</TextArea>
    		<Form.Group widths='equal'>
	    		<Form.Field onChange={this.onInputChange}>
			      <input placeholder='' />
			    </Form.Field>
			    <Button type="submit" onClick={this.onSendClick}>Envoyer</Button>
		    </Form.Group>
  		</Form>
		)
	}
}

function mapStateToProps(state) {
	return {
		serialMonitor: state.serialMonitor
	}
}

const connectedSerialMonitor = connect(mapStateToProps)(SerialMonitor)
export { connectedSerialMonitor as SerialMonitor }