import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ToolboxActivityComponent } from '../ToolboxActivityComonent/ToolboxActivityComponent';

import { Button, Form, Message, Popup, Segment, Checkbox, TextArea, Icon, Dropdown } from 'semantic-ui-react';

import MediaQuery from 'react-responsive'

import JoditEditor from "jodit-react";


import './FormActivityCreatorComponent.css';

import FormActivityCreatorAvailabilitySettings from './FormActivityCreatorAvailabilitySettings';

class FormActivityCreatorComponent extends Component {
    constructor(props) {
        super(props);
        const buttons=['bold','underline',
		'italic',
		'eraser',
		'|',
        { name: "ul", list:null},
        { name: "ol", list:null},
		'|',
		'outdent',
        'indent',
        '|',
        { name: "paragraph", list:{div: "Normal", h2: "Titre 1", h3: "Titre 2", h4: "Titre 3"}},
        '|',
        'link',
		'|',
		'hr',
		'fullsize',
        ]
        this.state = {
            title: '', 
            instruction: '',
            bufferInstruction: '',
            description: '',
            bufferDescription:"",
            isValidate: false,
            error: false,
            errorMsg: '',
            inStore: null,
            config: { askBeforePasteHTML: false, autofocus: true,readonly: false, showCharsCounter: false, showWordsCounter: false, minHeight: "230px", buttons: buttons, buttonsMD: buttons, buttonsSM: buttons, buttonsXS:buttons, events: {beforeChange: ()=>{console.log("CHANGE")}}}
        };

        this.myRef = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (this.props.activity) {
            this.setState({inStore: this.props.activity.inStore ? true : false})
            if (this.props.activity.title) {
                this.setState({title: this.props.activity.title, isValidate: true});
            }
            if (this.props.activity.description) {
                this.setState({description: this.props.activity.description, isValidate: true, bufferDescription: this.props.activity.description});
            }
        }

        if (this.props.step) {
            if (this.props.step.instruction) {
                this.setState({instruction: this.props.step.instruction, isValidate: true, bufferInstruction: this.props.step.instruction});
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.activity && prevProps.activity !== this.props.activity) {
            this.setState({inStore: this.props.activity.inStore ? true : false})
            if (this.props.activity.title) {
                this.setState({title: this.props.activity.title, isValidate: true});
            }
            if (this.props.activity.description) {
                this.setState({description: this.props.activity.description, isValidate: true, bufferDescription:this.props.activity.description});
            }
        }

        if (this.props.step && prevProps.step !== this.props.step) {
            if (this.props.step.instruction) {
                this.setState({instruction: this.props.step.instruction, isValidate: true, bufferInstruction: this.props.step.instruction});
            }
        }

        if ((prevState.title !== '' && prevState.title !== this.state.title) || (prevState.description !== '' && prevState.description !== this.state.description) || (prevState.instruction !== '' && prevState.instruction !== this.state.instruction) || (prevState.inStore !== this.state.inStore && prevState.inStore !== null)) {
            this.props.fieldChange(true);
        }
    }

    changeStoreDisponibility = (event, data) => {
        this.setState({inStore: data.checked})
    }

    handleChange(e) {
         this.setState({[e.target.name]: e.target.value});
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.state.title) {
            this.setState({error: true, errorMsg: 'Veuillez saisir le nom de l\'activité'});
            return
        }
        if (this.state.error && this.state.title) {
            this.setState({error: false, errorMsg: ''});

        }
        this.setState({isValidate: true});
        this.props.saveStepInstruction(this.state.instruction);
        if (!this.props.activity && !this.props.step) {
            this.props.createActivity({title: this.state.title, description: this.state.description, inStore: this.state.inStore === null ? true : this.state.inStore});
        }
        else {
            this.props.updateActivity({title: this.state.title, description: this.state.description, inStore: this.state.inStore === null ? true : this.state.inStore});
        }
    }

    renderForm(size){
        return <Form size={size} style={{display: "flex", flexDirection: "column",flex: "1 1" }}>
            {this.state.error && 
            <Message negative>
                <p>{this.state.errorMsg}</p>
            </Message>}

            <Form.Input 
                className='form_activity_field'
                label="Nom de l'activité" 
                name='title'
                type='text' 
                value={this.state.title} 
                onChange={this.handleChange} 
                size="small"
            />

            <div style={{flex:1}}>
                <Form.Field
                    className='form_activity_field'
                    style={{flex:1}}
                >
                    <label>Description pédagogique de l'activité <Popup position="top center" trigger={<Icon name='question circle outline' />}>
                        <Popup.Header>Description pédagogique de l'activité</Popup.Header>
                        <Popup.Content>
                            Décrivez votre activité : les thématiques abordées, les briques nécessaires, les pré-requis, les compétences visées, la difficulté... 
                            <p>Cette description permet à un.e enseignant.e de comprendre l'objectif de votre activité. Les élèves peuvent la voir également</p>
                        </Popup.Content>
                    </Popup></label>
                    <JoditEditor
                        ref={this.ref}
                        value={this.state.bufferDescription}
                        config={this.state.config}
                        tabIndex={1} // tabIndex of textarea
                        // onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={newContent => {
                            if(newContent.includes("<ul>") || newContent.includes("<ol>")) 
                                this.setState({bufferDescription: newContent.replace("<ul>", "<ul class=\"ui list\">").replace("<ol>", "<ol class=\"ui list\">")})
                            this.setState({description: newContent.replace("<ul>", "<ul class=\"ui list\">").replace("<ol>", "<ol class=\"ui list\">")})

                        }}
                    />
                </Form.Field>
                <Form.Field
                    className='form_activity_field'
                    style={{flex:1}}
                >
                    <label>Consigne pour l'élève <Popup position="top center" trigger={<Icon name='question circle outline' />}>
                        <Popup.Header>Consigne pour l'élève</Popup.Header>
                        <Popup.Content>
                            Donnez toutes les informations nécessaires pour que vos élèves puissent réaliser leur travail.
                            <p>
                                Cette consigne sera visible par vos élèves durant la réalisation de l'activité.
                            </p>
                        </Popup.Content>
                    </Popup></label>
                    <JoditEditor
                        ref={this.ref}
                        value={this.state.bufferInstruction}
                        config={this.state.config}
                        tabIndex={1} // tabIndex of textarea
                        // onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={newContent => {
                            if(newContent.includes("<ul>") || newContent.includes("<ol>")) 
                                this.setState({bufferInstruction: newContent.replace("<ul>", "<ul class=\"ui list\">").replace("<ol>", "<ol class=\"ui list\">")})
                            this.setState({instruction: newContent.replace("<ul>", "<ul class=\"ui list\">").replace("<ol>", "<ol class=\"ui list\">")})

                        }}
                    />
                </Form.Field>
            </div>
            
            
            
            <FormActivityCreatorAvailabilitySettings reportChanges={this.props.setAccessibility} groups={this.props.groups} selectedCourses={this.props.selectedCourses}/>

            <Form.Field>
            <Checkbox checked={this.state.inStore === null ? true : this.state.inStore} onChange={this.changeStoreDisponibility} label={<label>Activité disponible sur la bibliothèque d'activités <Popup position="top center" trigger={<Icon name='question circle outline' />}><Popup.Header>Bibliothèque d'activités</Popup.Header>
                        <Popup.Content>
                            La bibliothèque est un espace dans lequel vous pouvez découvrir des activités faites par d'autres enseignants.
                            <p>
                                Si vous souhaitez que votre activité soit disponible sur cet espace, cochez cette case.
                            </p>
                        </Popup.Content>
                    </Popup></label>}/>
            </Form.Field>

            <Form.Field>
                <label>Bloc racine <Popup position="top center" trigger={<Icon name='question circle outline' />}>
                    <Popup.Header>Bloc racine</Popup.Header>
                    <Popup.Content>
                        Choisissez le bloc racine utilisé par cette activité
                    </Popup.Content>
                </Popup>
                </label>
                <Dropdown defaultValue={"setup"} onChange={this.props.changeDefaultBlock} fluid selection options={[{
                    key: 'setup',
                    text: 'Au démarrage, pour toujours',
                    value: 'setup',
                },
                {
                    key: 'only_setup',
                    text: 'Au démarrage',
                    value: 'only_setup',
                },
                {
                    key: 'only_loop',
                    text: 'Pour toujours',
                    value: 'only_loop',
                },]}/>
            </Form.Field>
            
            <Button.Group vertical size={size}>
            <ToolboxActivityComponent
                step={this.props.step}
                saveStepToolboxXml={this.props.saveStepToolboxXml}
                size={size}
            />
            
            <Button style={{marginTop:"5px"}} size={size} fluid className='activity_form_button' onClick={this.handleSubmit} floated='left' type='submit' color='green'>
                Sauvegarder
            </Button>

            </Button.Group>

        </Form>
    }

    render() {

        let disabled = this.state.isValidate ? false : true;

        return(
            <Segment style={{display: "flex", flexDirection: "column",flex: "1 1" }}>
                <MediaQuery maxDeviceHeight={660}>
                    {this.renderForm("mini")}
                </MediaQuery> 
                <MediaQuery minDeviceHeight={661} maxDeviceHeight={699}>
                    {this.renderForm("tiny")}
                </MediaQuery> 
                <MediaQuery minDeviceHeight={700}>
                    {this.renderForm("small")}
                </MediaQuery> 
            </Segment>
        )
    }
}

export { FormActivityCreatorComponent }