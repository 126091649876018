import React, {Component} from 'react';
// import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
// import {logout} from "../../actions/session_actions";
import {Container, Dropdown, Segment, Icon, Image, Button, Modal} from 'semantic-ui-react';
import md5 from 'js-md5'

import './UserHeaderComponent.css'

class UserHeaderComponent extends Component {

    constructor(props){
        super(props)
        this.state = {
            modal: {
                open:false,
                text:"",
                title:"",
                action:null
            }
        }
        this.disconnect = this.disconnect.bind(this)
        this.goToProfil = this.goToProfil.bind(this)
    }

    disconnect(){
        this.props.logout(this.props.history)
    }


    goToProfil(){
        this.props.history.push(`/profil`)
    }

    openModalStandard = () => {
        let structureName = this.props.structure.name
        this.setState({modal:{
            open: true,
            text: "Es-tu sûr de vouloir quitter la structure "+this.Capitalize(structureName)+"?",
            title: "Confirmation de sortie de structure",
            action: () => {this.closeModal();this.props.leaveStructure()}
        }})
    }

    openModalGuest = () => {
        this.setState({modal:{
            open: true,
            text: "Es-tu sûr de vouloir supprimer ton compte ?",
            title: "Confirmation de suppression",
            action: () => {this.closeModal(); this.props.deleteAccount()}
        }})
    }

    closeModal = () =>{
        this.setState({modal: {
            open:false
        }})
    }

    Capitalize(str){
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    renderLeaveStructure(){
        return (
            <Dropdown.Item onClick={this.openModalStandard} color="yellow">Quitter la structure</Dropdown.Item>
        )
    }

    renderGuest(){
        if(this.props.hideIfGuest && this.props.user){
            return <Segment floated='right'>{this.Capitalize(this.props.user.username)}</Segment>
        }
        return (
            <Button color="red" float="right" style={{margin:"5px"}} onClick={this.openModalGuest}>Supprimer le compte</Button>
        )
    }

    renderStandard(){
        // let structure = this.props.user.structure && typeof this.props.user.structure === 'object' ? this.props.structures.entities[this.props.user.structure._id] : this.props.structures.entities[this.props.user.structure]
        return (
            <div>
                {this.props.user ? (
    
                        <Segment floated='right'>
                            {/* <Image className="userAvatarCircularBordered" circular centered size="small" src={'https://www.gravatar.com/avatar/'+md5(this.this.props.user.email.toLowerCase())+'?&d=robohash&s=200'}/> */}
                            <Icon name='user circle'/>
                            <Dropdown compact pointing='top right' text={this.props.user && this.props.user.username}>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={this.goToProfil}>Profil</Dropdown.Item>
                                    {this.props.structure && this.props.user.role && this.props.user.role.type !== "guest" && 
                                        this.renderLeaveStructure()
                                    }
                                    <Dropdown.Item onClick={this.disconnect}>Déconnexion</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Segment>
                ) : (
                    <div className='dummyUHC'></div>
                )}
    
            </div>
        )
    }

    render(){
        if(!this.props.user)
            return null;

        let user;
        if(this.props.user.role.type == "guest"){
            user = this.renderStandard();
        }else{
            user = this.renderStandard();
        }

        return (
            <div>
                {user}
                <Modal dimmer="blurring" closeOnDimmerClick={true} onClose={this.closeModal} open={this.state.modal.open}>
                    <Modal.Header>{this.state.modal.title}</Modal.Header>
                    <Modal.Content scrolling>
                        {this.state.modal.text}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic onClick={this.closeModal}>Non</Button>
                        <Button color="red" onClick={this.state.modal.action}>Oui</Button>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
};


// UserHeaderComponent.propTypes = {
//     history: PropTypes.shape({
//         push: PropTypes.func.isRequired,
//     }),
// };


export default withRouter(UserHeaderComponent)
