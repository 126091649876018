import * as api from "../../api";

import { progressionsConstants } from '../../constants';
import { modelConstants, modalConstants } from '../../constants';

import { progressionsSchema, progressionSchema, userSimpleStructureSchema } from '../../schemas';
 
import { modalActions } from '../';

import {normalize} from 'normalizr'

export const progressionsActions = {
	fetch,
	fetchAll,
	fetchByCourse,
	fetchAllByStep,
	update,
	nextStep,
	nextClue,
	deleteProgress,
	deleteProgressByCourse
}

function fetch(id){
	return dispatch => {
		dispatch({type: modelConstants.FETCH_ENTITY_STARTED, entities:"progressions"});
		return api.progressions.fetch(id).then(response => {
			dispatch({type: modelConstants.FETCH_ENTITY_DONE, originalEntities:"progressions", entities: normalize(response.data, progressionSchema)});
		}).catch(error => {
			dispatch({type: progressionsConstants.FETCH_FAILED, error});
		})
	}
}

function deleteProgress(progressionId){
	return (dispatch,getState) => {
		dispatch({type: modelConstants.DELETE_ENTITY_STARTED, entities: "progressions"});
		return api.progressions.deleteProgress(progressionId).then(response => { 
			let entities = getState().model.progressions.entities;
			let newEntities = {...entities}
			delete newEntities[response.data._id];
			dispatch({type: modelConstants.DELETE_ENTITY_DONE, originalEntities:"progressions", entities: {entities: {progressions: newEntities}}})
		}).catch(error => {
			console.log(error)
			dispatch({type: progressionsConstants.DELETE_PROGRESSION_FAILED, error})
		})
	}
}

function deleteProgressByCourse(courseId){
	return (dispatch,getState) => {
		dispatch({type: modelConstants.DELETE_ENTITY_STARTED, entities: "progressions"});
		return api.progressions.deleteProgressByCourse(courseId).then(response => { 
			let entities = getState().model.progressions.entities;
			let newEntities = {...entities}
			delete newEntities[response.data._id];
			dispatch({type: modelConstants.DELETE_ENTITY_DONE, originalEntities:"progressions", entities: {entities: {progressions: newEntities}}})
		}).catch(error => {
			console.log(error)
			dispatch({type: progressionsConstants.DELETE_PROGRESSION_FAILED, error})
		})
	}
}

function fetchAll(){
	return dispatch => {
		dispatch({type: modelConstants.FETCH_ENTITIES_STARTED, entities:"progressions"});
		
		// try{
		// let user = {_id: "5cece", structure:{"_id": "9009d"}};
		// console.log("NORMALIZE")
		// console.log("normalize", normalize(user, userSimpleStructureSchema))
		// }catch(error){
		// 	console.log(error);
		// }
		
		return api.progressions.fetchAll().then(response => {
			dispatch({type: modelConstants.FETCH_ENTITIES_DONE, originalEntities:"progressions", entities: normalize(response.data, progressionsSchema)});
		}).catch(error => {
			dispatch({type: progressionsConstants.FETCH_ALL_FAILED, error})
		})
	}
}

function fetchByCourse(course){
	return dispatch => {
		dispatch({type: modelConstants.FETCH_ENTITIES_STARTED, entities:"progressions"});
		return api.progressions.fetchByCourse(course).then(response => {
			let entities = normalize(response.data, progressionSchema)
			dispatch({type: modelConstants.FETCH_ENTITIES_DONE, originalEntities:"progressions", entities});
		}).catch(error => {
			if(error.response.status === 404){
				dispatch(createForCourse(course))
			}
			dispatch({type: progressionsConstants.FETCH_BY_COURSE_FAILED, error})
		})
	}
}

function fetchAllByStep(step) {
	return dispatch => {
		dispatch({type: modelConstants.FETCH_ENTITIES_STARTED, entities:"progressions"});
		return api.progressions.fetchAllByStep(step).then(response => {
			dispatch({type: modelConstants.FETCH_ENTITIES_DONE, originalEntities:"progressions", entities: normalize(response.data, progressionsSchema)});
		}).catch(error => {
			dispatch({type: progressionsConstants.FETCH_ALL_FAILED, error})
		})
	}
}

function createForCourse(course){
	return (dispatch, getState) => {
		dispatch({type: modelConstants.CREATE_ENTITY_STARTED, entities:"progressions"})
		return api.progressions.createForCourse(course).then(response => {
			let entities = normalize(response.data, progressionSchema)
			try{
				let c = entities.entities.courses[course];
				if(window.analytics)
				window.analytics.track(`Course started - ${c.title}`, {course: c})
			}catch(error){
				if(window.analytics){
					window.analytics.track('Course started segment error', {error})
				}
			}
			dispatch({type: modelConstants.CREATE_ENTITY_DONE, originalEntities:"progressions", entities})
		}).catch(error => {
			console.log(error)
			dispatch({type: progressionsConstants.CREATE_BY_COURSE_FAILED, error})
		})
	}
}

function update(progressionId){
	return (dispatch, getState) => {
		dispatch({type: modelConstants.UPDATE_ENTITY_STARTED, entities:"progressions"})
		return api.progressions.update(getState().model.progressions.entities[progressionId]).then(response => {
			let entities = normalize(response.data, progressionSchema)
			dispatch({type: modelConstants.UPDATE_ENTITY_DONE, originalEntities:"progressions", entities})
		}).catch(error =>{
			dispatch({type: progressionsConstants.UPDATE_FAILED, error})
		});
	}
}

function nextStep(progressionId){
	return (dispatch, getState) => {
		dispatch({type: modelConstants.UPDATE_ENTITY_STARTED, entities:"progressions"})
		return api.progressions.nextStep(progressionId).then(response => {
			let entities = normalize(response.data, progressionSchema)
			try{
				let p = entities.entities.progressions[progressionId];
				let s = entities.entities.steps[p.step];
				let c = entities.entities.courses[s.course];
				if(p.ended)
					window.analytics.track(`Course ended - ${c.title}`, {course: c, step: s, progression: p})
				else
					window.analytics.track(`Next step - ${s.description} ${s._id}`, {course: c, step: s, progression: p})
			}catch(error){
				if(window.analytics)
					window.analytics.track('Next step segment error', {error})
			}
			dispatch({type: modelConstants.UPDATE_ENTITY_DONE, originalEntities:"progressions", entities})
		}).catch(error =>{
			dispatch({type: progressionsConstants.UPDATE_FAILED, error})
		});
	}
}

function nextClue(progressionId){
	return (dispatch, getState) => {
		dispatch({type: modelConstants.UPDATE_ENTITY_STARTED, entities:"progressions"})
		return api.progressions.nextClue(progressionId).then(response => {
			let entities = normalize(response.data, progressionSchema)
			try{
				let p = entities.entities.progressions[progressionId];
				let s = entities.entities.step[p.step];
				let c = entities.entities.course[s.course];
				window.analytics.track(`Ask next clue - ${s.description} ${s._id}`, {course: c, step: s, progression: p})
			}catch(e){
				if(window.analytics)
					window.analytics.track('Next clue segment error', {error: e})
			}
			console.log(entities)
			if(!entities.entities.clues){
				dispatch(modalActions.addModalMessageMid({type:modalConstants.MODAL_MESSAGE_TYPE.NO_CLUES}))
			}else if(getState().model.progressions.entities[progressionId].clues.length === Object.keys(entities.entities.clues).length){
				dispatch(modalActions.addModalMessageMid({type:modalConstants.MODAL_MESSAGE_TYPE.NO_MORE_CLUES}))
			}
			dispatch({type: modelConstants.UPDATE_ENTITY_DONE, originalEntities:"progressions", entities})
		}).catch(error => {
			console.log(error)
			dispatch({type: progressionsConstants.UPDATE_FAILED, error})
		})
	}
}



