import React from 'react'
import {withRouter} from 'react-router-dom';
import {appsActions, coursesActions, progressionsActions} from '../../../actions/model';

import AppSummaryComponent from '../../../components/AppSummaryComponent/AppSummaryComponent';

import {
	Container,
	Image,
	Segment,
	Header,
	Grid,
    Label,
    Icon,
    Modal,
	List,
	Item,
	Placeholder,
	Tab,
	Menu,
	Message,
	Divider,
	Button,
	Input,

} from 'semantic-ui-react';
import  '../Profil.css'

const ActivitiesTab = (props) => {
	function redirectToApp() {
		props.history.push('/')
	}
		
	return(
		<div className='pane'>
			<Divider horizontal hidden/>
			<Header>Mes Activités</Header>

			<Item.Group>
				{props.listActivitiesStarted().length > 0 ? (
					props.listActivitiesStarted()
				) : (
					<Message style={{display:'block'}} info>
						<Message.Header>
							<span>Tu n'as pas encore commencé d'activités</span>
						</Message.Header>
						<Divider hidden/>
							<div>
								Il n'y a peut être pas encore d'activités disponibles.
								<Divider hidden/>
								<Button compact onClick={() => {redirectToApp()}} color='orange' basic size={'big'}>
									Activités
								</Button>
							</div>
					</Message>
				)}
			</Item.Group>
			<Divider section/>
		</div>
	)
}

// export default CoursesTab;
export const ActivitiesTabWithRouter = withRouter(ActivitiesTab)
