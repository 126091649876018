import {bricksConstants} from '../../constants'

export const intialBricksState = {
    isLoading: null,
    error: null,

    bricks: null,
}

export const bricks = (state = intialBricksState, action) => {
    switch(action.type){
        case bricksConstants.FETCH_ALL_STARTED: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case bricksConstants.FETCH_ALL_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,

                bricks: action.payload,
            }
        }
        case bricksConstants.FETCH_ALL_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        }
        case bricksConstants.FETCH_ONE_STARTED: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case bricksConstants.FETCH_ONE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,

                bricks: action.payload,
            }
        }
        case bricksConstants.FETCH_ONE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        }
        default: {
            return state
        }

    }
}