import {usersBricksConstants} from '../constants'
import * as api from "../api"

export const usersBricksActions = {
    fetchAll,
    addUsersBricks,
    removeUsersBricks,
    updateQuantity,
    saveModifications,
}

function fetchAll(){
    return (dispatch, getState) => {
        dispatch({type: usersBricksConstants.FETCH_ALL_STARTED})
        api.usersBricksApi.fetchAll().then(resp => {
            const datas = resp.data
            dispatch({type: usersBricksConstants.FETCH_ALL_SUCCESS, payload: datas})
        })
        .catch(err => {
            dispatch({type: usersBricksConstants.FETCH_ALL_FAILED, payload: err})
        })
    }
}

function updateQuantity(brick){
    return (dispatch, getState) => {
        dispatch({type: usersBricksConstants.UPDATE_QUANTITY_STARTED})

        let updatedBricksList = getState().usersBricks.bricks.map((item, index) => {
            if(item.brick._id === brick.brick._id)
                return brick
            return item
        })

        try {
            dispatch({type: usersBricksConstants.UPDATE_QUANTITY_SUCCESS, payload: updatedBricksList})
        } catch(err) {
            dispatch({type: usersBricksConstants.UPDATE_QUANTITY_FAILED})
        }
    }
}

function addUsersBricks(brick){
    return (dispatch, getState) => {
        dispatch({type: usersBricksConstants.ADD_BRICKS_STARTED})
        console.log('brick',brick)
        let missingBrick = {
            _id: brick._id,
            user: brick.user,
            brick: Object.assign({}, brick.brick),
            count: brick.count,
        }
        // missingBrick.brick = brick._id
        missingBrick.count = brick.count
        try {
            dispatch({type: usersBricksConstants.ADD_BRICKS_SUCCESS, payload: missingBrick})
        } catch(err) {
            dispatch({type: usersBricksConstants.ADD_BRICKS_FAILED, payload: err})
        }
    }
}

function removeUsersBricks(brick) {
    console.log('brick', brick)
    return (dispatch, getState) => {
        dispatch({type: usersBricksConstants.REMOVE_BRICKS_STARTED})

        try {
            dispatch({type: usersBricksConstants.REMOVE_BRICKS_SUCCESS, payload: brick})
        } catch(err) {
            dispatch({type: usersBricksConstants.REMOVE_BRICKS_FAILED, payload: err})
        }
    }
}

function saveModifications(){
    return (dispatch, getState) => {
        
        const usersBricks = getState().usersBricks.bricks
        let nullCount_bricks = []
        let missing_bricks = []
        let existing_bricks = []

        usersBricks.forEach(brick => {
            // if(brick.count < 1) {
            //     nullCount_bricks.push(brick)
            // }
            if(brick._id) {
                existing_bricks.push(brick)
            } else {
                missing_bricks.push(brick)
            }
        })

        dispatch({type: usersBricksConstants.SAVE_MODIFICATIONS_STARTED})

        missing_bricks.forEach((brick) => {
            delete brick._id

            api.usersBricksApi.addUsersBricks(brick).then(resp => {
                dispatch({type: usersBricksConstants.SAVE_MODIFICATIONS_SUCCESS})
            })
            .catch(err => {
                dispatch({type: usersBricksConstants.SAVE_MODIFICATIONS_FAILED, payload: err})
            })
        })

        existing_bricks.forEach((brick) => {

            // if(brick.count < 1) {
            //     api.usersBricksApi.removeUsersBricks(brick).then(resp => {
            //         dispatch({type: usersBricksConstants.SAVE_MODIFICATIONS_SUCCESS, payload: resp.data})
            //     })
            //     .catch(err => {
            //         dispatch({type: usersBricksConstants.SAVE_MODIFICATIONS_FAILED, payload: err})
            //     })
            // } else {
                api.usersBricksApi.updateQuantity(brick).then(resp => {
                    dispatch({type: usersBricksConstants.SAVE_MODIFICATIONS_SUCCESS})
                })
                .catch(err => {
                    dispatch({type: usersBricksConstants.SAVE_MODIFICATIONS_FAILED, payload: err})
                })
            // }

        })

    }
}