import React, {Component} from 'react'
import {connect} from 'react-redux'

import { Blockly } from "../../containers/Blockly";
import HeaderComponent from "../HeaderComponent/HeaderComponent"
import HeaderCourse from "../HeaderCourse/HeaderCourse"
import Modal from "../../containers/Modal"
import {ExtensionInformationComponent} from '../ExtensionInformationComponent'

import {
    Grid,
    Container
} from 'semantic-ui-react'

import './GuidedTourComponent.css'

////////////////////////////////////
///////////   Joyride   ////////////
////////////////////////////////////
import Joyride from '../../joyride/index'
import { ACTIONS, EVENTS } from '../../joyride/constants'
// import { ACTIONS, EVENTS } from 'react-joyride'
// import { ACTIONS, EVENTS } from 'react-joyride/es/constants'
import PropTypes from 'prop-types'
import {guidedTourConstants} from '../../constants';
import {guidedTourActions} from '../../actions';

import { Simulator } from '../../containers/Simulator';
////////////////////////////////////

const Event = {InputText: "InputText", Click: "Click", Hover: "Hover"}

class GuidedTourComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            run:false,
 
            pauses: [],
        }

        this.keyupTimeout = null
        this.inputTextEvent = this.inputTextEvent.bind(this)
        this.onBlocklyStepDone = this.onBlocklyStepDone.bind(this)
    }

    static propTypes = {
      joyride: PropTypes.shape({
        callback: PropTypes.func
      })
    }
  
    static defaultProps = {
      joyride: {}
    }

    handleStart = (e) => {
    //   e.preventDefault()
  
      this.setState({
        run: true
      })
    //   if(this.props.guidedTour.solutions.find((solution) => {return solution.stepIndex === this.props.guidedTour.stepIndex})) {
    //     this.props.dispatch({type: guidedTourConstants.HAS_SOLUTION})
    //     //   this.props.dispatch(guidedTourActions.onStepDone())
    //     this.props.dispatch({type: guidedTourConstants.NEXTSTEP_DONE})
    //   }
      console.log('Nous commencons à l\'etape :' + this.props.guidedTour.stepIndex)
    }

    handleStop = (e) => {
    //   e.preventDefault()
  
      this.setState({
        run: false
      })
    }

    // handleNext = (e) => {
    //     //   e.preventDefault()
    //     console.log('Passage a l\'étape suivant')
    //     //   this.props.dispatch(guidedTourActions.onStepDone())
    //     this.props.dispatch({type: guidedTourConstants.NEXTSTEP_DONE})
    //     console.log('NEXT STEP FALSE')
    //     this.setState({
    //         run: true, // si le tour était en pause, le relancer et afficher l'étape suivant la dernière qui a été affichée à l'écran
    //     })
    //     // this.props.dispatch({ type: guidedTourConstants.INCREMENT_STEPINDEX })
    //     this.props.dispatch(guidedTourActions.goNextStep(this.props.guidedTour.solutions))
    //     // console.log('fin du set state')

    //     // console.log('Nous sommes maintenant à l\'etape :' + this.props.guidedTour.stepIndex)
    //     // if(this.props.guidedTour.solutions.find((solution) => {return solution.stepIndex === this.props.guidedTour.stepIndex})) {
    //     //     console.log(this.props.guidedTour.solutions)
    //     //     console.log('Cette étape a bien une solution !')
    //     //     this.props.dispatch({type: guidedTourConstants.HAS_SOLUTION})
    //     // } else {
    //     //     console.log('Cette n\'a pas de solution !')
    //     //     this.props.dispatch({type: guidedTourConstants.HAS_NOT_SOLUTION})
    //     // }

    // }
  
    handleJoyrideCallback = tour => {
        const { joyride } = this.props
        const { action, index, type } = tour
    
        if (type === EVENTS.TOUR_END) {
            // Update user preferences with completed tour flag
        } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            if(this.props.guidedTour.ignoreNextJoyrideCallback) {
                this.props.dispatch({type: guidedTourConstants.ENABLE_JOYRIDE_CALLBACK})
                return
            }
            if (this.state.pauses.includes(index)) {
                this.setState({ run: false })
            } else {
                // this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) })
                if (action === ACTIONS.PREV) {
                    this.props.dispatch(guidedTourActions.goPreviousStep(this.props.guidedTour.solutions))
                } else {
                    console.log('handleJoyrideCallback -> gonextstep')
                    this.props.dispatch(guidedTourActions.goNextStep(this.props.guidedTour.solutions))
                }

                // console.log(this.props.guidedTour.solutions)
                // console.log(this.props.guidedTour.stepIndex)
                // if(this.props.guidedTour.solutions.find((solution) => {return solution.stepIndex === this.props.guidedTour.stepIndex})) {
                //     console.log('Cette étape a bien une solution !')
                //     this.props.dispatch({type: guidedTourConstants.HAS_SOLUTION})
                // } else {
                //     console.log('Cette n\'a pas de solution !')
                //     this.props.dispatch({type: guidedTourConstants.HAS_NOT_SOLUTION})
                // }
            }
        }


        if (typeof joyride.callback === "function") {
            joyride.callback(tour)
        } else {
            // console.group(type)
            // console.log(tour) //eslint-disable-line no-console
            // console.groupEnd()
        }
    }

    doesStepHasSolution() {
        if(this.props.guidedTour.solutions.find((solution) => {return solution.stepIndex === this.props.guidedTour.stepIndex})) {
            console.log('Cette étape a bien une solution !')
            this.props.dispatch({type: guidedTourConstants.HAS_SOLUTION})
        } else {
            console.log('Cette n\'a pas de solution !')
            this.props.dispatch({type: guidedTourConstants.HAS_NOT_SOLUTION})
        }
    }

    componentDidMount() {
        this.setupEvent()

        this.handleStart()
    }

    componentDidUpdate(prevProps) {

        this.setupEvent()
        
        if(this.props.guidedTour.steps[this.props.guidedTour.stepIndex] && this.props.guidedTour.steps[this.props.guidedTour.stepIndex].hideNextButton) {
            if(document.querySelector('[aria-label=Suivant]')) { //To avoid error if component targeted does not exist
                document.querySelector('[aria-label=Suivant]').style.display = 'none'
            }
        }

        // if(this.props.guidedTour.guidedTourGoNextStep) {
        //     this.handleNext()
        // }
        
        // console.log(this.props.guidedTour.solutions)

        // console.log(this.props.guidedTour.stepIndex)
    }

    setupEvent() {
        if (this.props.step && !this.props.progression.ended) {
            let elem
            switch (this.props.step.event) {
                case Event.Click:
                    elem = document.querySelector('#' + this.props.step.eventTarget)
                    elem.addEventListener("click", this.nextStep)
                    break

                case Event.InputText:
                    elem = document.querySelector('#' + this.props.step.eventTarget)
                    elem.addEventListener("keyup", this.inputTextEvent)
                    break

                default:
                    // TODO
                    break
            }
        }
    }

    inputTextEvent() {
        clearTimeout(this.keyupTimeout)
        this.keyupTimeout = window.setTimeout(function () {
            this.nextStep()
        }.bind(this), 500)
    }

    onBlocklyStepDone() {
        if (!this.props.step.ended) {
            this.props.onStepDone()
        }
    }


    render() {

        let step = Object.assign({}, this.props.step)

        let currentSolution = this.props.guidedTour.solutions.find((solution) => {
            return solution.stepIndex === this.props.guidedTour.stepIndex
        })
        if(currentSolution) {
            step.id = currentSolution.stepIndex
            step._id = currentSolution.stepIndex
            step.xmlSolution = currentSolution.xml
        }
        // console.log('step', step)

        return (
            // scrollToFirstStep
            // showProgress
            <div className="course" id='courseMainDiv'>
                <Simulator/>
                <Joyride
                    debug={false}
                    run={this.state.run}
                    stepIndex={this.props.guidedTour.stepIndex}
                    continuous
                    scrollToFirstStep
                    showProgress
                    // hideBackButton
                    // showSkipButton
                    disableOverlayClose
                    disableCloseOnEsc
                    callback={this.handleJoyrideCallback}
                    steps={this.props.guidedTour.steps}
                    locale={{
                        back: 'Précédent',
                        next: 'Suivant',
                        last: 'Tu as terminé',
                        close: 'Fermer',
                        skip: 'Passer',
                    }}
                />

                {this.props.step === null && <p>Sorry this course doesn't exist...</p>}

                {this.props.step && window.Blockly &&

                    <Grid id='courseGird'>
                        
                    <Grid.Row>
                        <HeaderComponent course={this.props.course}
                            progression={this.props.progression}
                            step={this.props.step}/>
                    </Grid.Row>

                          

                
                    <HeaderCourse step={step}
                        progression={this.props.progression}
                        compilationLoading={this.props.compilationLoading}
                        resetApp={this.props.resetApp}
                        course={this.props.course}
                        onCompileClick={this.props.onCompileClick}
                        onNextStep={this.props.checkBlockly}
                        idExtensionChange={this.props.idExtensionChange}
                    />

                    <Grid.Row columns='equal' className='WorkspaceRow' style={{display: "flex", flex: "1 0 auto"}}>
                        <Grid.Column computer={4} style={{paddingRight: 0, display: "flex", flexDirection: "column", flex: "0 1 auto"}}>
                            <Modal progression={this.props.progression}/>
                        </Grid.Column>
                            <Grid.Column style={{paddingLeft:0}}>
                                <Container id='CourseBlocklyComponent'>
                                    <Blockly
                                        lang={window.LANG}
                                        step={step}
                                        updateWorkspaceSol={this.props.updateWorkspaceSol}
                                        onStepDone={this.onBlocklyStepDone}
                                        autoSave={this.props.autoSaveProgression}
                                        xml={this.props.progression.xml}
                                        resetAppRequest={this.props.resetAppRequest}
                                        resetAppDone={this.props.resetAppDone}
                                        ended={this.props.progression.ended}
                                        onMainWorkspaceStatusChanged={this.props.onMainWorkspaceStatusChanged}
                                        onSolutionWorkspaceStatusChanged={this.props.onSolutionWorkspaceStatusChanged}
                                    />
                                </Container>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid> 
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        progressions: state.model.progressions,
        steps: state.model.steps,
        courses: state.model.courses,

        guidedTour: state.guidedTour,
    }
}

//updateWorkspace={this.props.updateWorkspace}
export default connect(mapStateToProps)(GuidedTourComponent)