import {schema} from 'normalizr'
import { selectedCourseEntity, courseEntity, structureEntity, groupEntity } from './entities.js';

selectedCourseEntity.define({
    course: courseEntity,
    structure: structureEntity,
    group: groupEntity
})

export const selectedCourseSchema = selectedCourseEntity;

export const selectedCoursesSchema = new schema.Array(selectedCourseEntity);
