import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react'
import { withRouter } from "react-router-dom";
import technicien from '../../img/technicien.svg'

class ResetPasswordComponent extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            user: {
                newPassword: '',
                verifyNewPassword: '',
                token: '',

            }
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.getUserToken = this.getUserToken.bind(this);

    }
    componentWillUnmount() {
        const { resetPasswordResetState } = this.props.actions;
        resetPasswordResetState();
    }

    onSubmit() {
        const { user } = this.state;
        user.token = this.getUserToken();
        const { resetPassword } = this.props.actions;
        resetPassword(user)
    }

    onChange(e) {
        const { value, name } = e.target;
        const { user } = this.state;
        user[name] = value;
        this.setState({ user });
    }

    getUserToken() {
        var url = document.location.href;
        var token = url.substring(url.lastIndexOf('=') + 1);
        return token
    }

    render() {
        return (
            <div className='login-form'>
                {/*
      Heads up! The styles below are necessary for the correct render of this example.
      You can do same with CSS, the main idea is that all the elements up to the `Grid`
      below must have a height of 100%.
    */}
                <style>{`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}</style>
                <Grid textAlign='center' style={{ height: '80%' }} verticalAlign='middle'>
                    <Grid.Column style={{ maxWidth: 450 }}>
                        <Header as='h2' color='orange' textAlign='center'>
                            <Image
                                style={{ width: '4.5em', marginRight: "25px" }}
                                verticalAlign={'middle'}
                                circular
                                size={'large'}
                                src={technicien} />
                            Nouveau mot de passe 
                        </Header>
                        <Message>
                            Saisis deux fois ton nouveau mot de passe ci-dessous.
                        </Message>
                        <Form size='large' loading={this.props.isFetching} error={this.props.error !== null} success={this.props.success == true} >
                            <Segment stacked>

                                
                                    {this.props.error !== null && <Message
                                        error
                                        header='Erreur:'
                                        content={this.props.error.data.message}
                                    />}

                                {this.props.success == true &&
                                    <Message
                                        success  >
                                        <Message.Header>
                                            Ton mot de passe a été réinitialisé avec succes.
                                        </Message.Header>
                                        <Message.Content>
                                            <a href="/login"> Clique ici pour retourner à la page de connexion</a>
                                        </Message.Content>
                                    </Message>}


                                <Form.Input
                                    onChange={this.onChange}
                                    name={"newPassword"}
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    placeholder='Nouveau mot de passe'
                                    type='password' />
                                <Form.Input
                                    onChange={this.onChange}
                                    name={"verifyNewPassword"}
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    placeholder='Vérification du nouveau mot de passe'
                                    type='password'
                                />
                                <Button color='orange' fluid size='large'
                                    onClick={() => this.onSubmit()}>
                                    Enregistrer le nouveau mot de passe
                                </Button>
                            </Segment>
                        </Form>

                    </Grid.Column>
                </Grid>
            </div>
        );
    }
};

ResetPasswordComponent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

export default withRouter(ResetPasswordComponent);

