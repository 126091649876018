import ReactDOM from 'react-dom';
import React from 'react';
import {stepActions} from './model/steps_actions'
import {editorConstants, modelConstants, stepsConstants} from '../constants'
import * as api from "../api";
import {normalize} from 'normalizr'
import { stepSchema } from '../schemas';

export const editorActions={
saveCurrentStep,
saveCurrentStepChanges,
switchXmlMode,
loadBlocklyXmlDone,
saveChangeXml,
saveToolbox,
deleteCurrentStep,
saveCluesChanges
}

function saveCurrentStep(){
    return (dispatch, getState)=>{
    	dispatch({ type: modelConstants.UPDATE_ENTITY_STARTED,entities: "steps" })    
    	let currentStep=getState().model.steps.entities[getState().editor.id]
    	if(currentStep._id){    		
			return api.steps.saveCurrentStep(currentStep)

			.then(response => {
				let entities=normalize(response.data, stepSchema)
				dispatch({ type: modelConstants.UPDATE_ENTITY_DONE,originalEntities:"steps",entities })
				
			}).catch(err => {
				console.log(err)
				dispatch({ type: stepsConstants.UPDATE_ENTITY_FAILED, payload: err })
			})
 		}  
	}
}


function saveCurrentStepChanges(currentStepId,eventChanges){
	return (dispatch,getState)=>{
		if(getState().session.authenticated && getState().session.checked){
			let stepToUpdate= {...getState().model.steps.entities[currentStepId]}
			if(stepToUpdate!=null){
				
				if(eventChanges.data ==='description'){
					stepToUpdate.description=eventChanges.value	
				}
				if(eventChanges.data ==='instruction'){
					stepToUpdate.instruction=eventChanges.value
				}
				if(eventChanges.data ==='context'){
					stepToUpdate.context=eventChanges.value
				}
				if(eventChanges.data ==='contextMedia'){
					stepToUpdate.contextMedia=eventChanges.value	
				}

				/*if(eventChanges.data==='xml'){
					dispatch({type:editorConstants.XML_CHANGE_STARTED})
					if(getState().editor.xmlMode==='solution'){
						stepToUpdate.xmlSolution=eventChanges.value
					}
					else if(getState().editor.xmlMode==='start'){
						stepToUpdate.xmlStart=eventChanges.value
					}
					dispatch({type:editorConstants.XML_CHANGE_ENDED})
				}*/
				console.log("state",getState().model.steps.entities[currentStepId])
				
			}
			dispatch({type: editorConstants.SAVE_CHANGES,stepToUpdate})
		}
	}
}

function saveCluesChanges(eventChanges){

	return(dispatch,getState)=>{
		console.log(eventChanges)
		let clueToUpdate={...getState().model.clues.entities[eventChanges.clueid]}
		if(clueToUpdate){
			if(eventChanges.data==="text"){
				clueToUpdate.text=eventChanges.value
			}
			if(eventChanges.data==="title"){
				clueToUpdate.title=eventChanges.value
			}
			if(eventChanges.data==="autoDisplay"){
				clueToUpdate.autoDisplay=eventChanges.checked
			}
			if(eventChanges.data==="mediaLink"){
				clueToUpdate.mediaLink=eventChanges.value
			}

		dispatch({type:editorConstants.SAVE_CLUE_CHANGES,clueToUpdate})

		}
	}

}



function switchXmlMode(mode){
	return(dispatch,getState)=>{
		if (mode != getState().editor.xmlMode){
			dispatch({type:editorConstants.SWITCH_XML_MODE_STARTED, mode})
		}
	}
}

function loadBlocklyXmlDone() {
	return(dispatch)=>{
		 dispatch({ type:editorConstants.SWITCH_XML_MODE_SUCCEEDED})
	}
}


function saveChangeXml(currentStepId,xml){
	return(dispatch,getState)=>{
				

			let stepToUpdate= {...getState().model.steps.entities[currentStepId]}

		if(getState().blockly.mainWorkspace.getAllBlocks().length==0){
			let defaultXml='<xml xmlns="http://www.w3.org/1999/xhtml"><variables></variables><block type="setup" id="2aS:=@,OZEM~g%92/7IW" x="10" y="10"></block></xml>'
			if(getState().editor.xmlSolution==='solution'){
				stepToUpdate.xmlSolution=defaultXml
			}else if(getState().editor.xmlMode==='start'){
				stepToUpdate.xmlStart=defaultXml
			}
		}else if(getState().blockly.mainWorkspace.getAllBlocks().length > 1){

			if(getState().editor.xmlMode==='solution'){
						stepToUpdate.xmlSolution=xml
					}
					else if(getState().editor.xmlMode==='start'){
						stepToUpdate.xmlStart=xml
					}
				dispatch({type: editorConstants.SAVE_CHANGES,stepToUpdate})

			}
		}
	}


function saveToolbox(currentStepId,xml){
	return(dispatch,getState)=>{
		if(getState().session.authenticated && getState().session.checked){
			let stepToUpdate= {...getState().model.steps.entities[currentStepId]}
			if(stepToUpdate!=null){
				stepToUpdate.toolbox=xml
				dispatch({type: editorConstants.SAVE_CHANGES,stepToUpdate})
			}
		}
	}
}

function deleteCurrentStep(currentStepId){
	return(dispatch,getState)=>{
		if(getState().session.authenticated && getState().session.checked){
			dispatch({type:modelConstants.DELETE_ENTITY_STARTED,entities: "steps"})
			return api.steps.deleteCurrentStep(currentStepId)
			.then(response => {
						let entities=normalize(response.data, stepSchema)
						dispatch({ type: modelConstants.DELETE_ENTITY_DONE,originalEntities:"steps",entities })
					})
			.catch(error=>{
			console.log(error)
			dispatch({type: editorConstants.DELETE_ENTITY_FAILED, error})

	})
}}
}