import React, { Component } from 'react';
import {Button,Modal,Icon} from 'semantic-ui-react'

class StepCountComponent extends Component {
    constructor(props) {
        super(props)
        this.goToNextStep=this.goToNextStep.bind(this)
        this.goToPreviousStep=this.goToPreviousStep.bind(this)
        this.createNextStep=this.createNextStep.bind(this)
        this.createPreviousStep=this.createPreviousStep.bind(this)
        this.renderButtons=this.renderButtons.bind(this)
        this.saveCurrentStep=this.saveCurrentStep.bind(this)
        this.goToNextStepRender=this.goToNextStepRender.bind(this)
        this.goToPreviousStepRender=this.goToPreviousStepRender.bind(this)
        this.createPreviousStepRender=this.createPreviousStepRender.bind(this)
        this.createNextStepRender=this.createNextStepRender.bind(this)
        this.deleteCurrentStep=this.deleteCurrentStep.bind(this)
    }
    componentDidMount() {

    }
    componentDidUpdate(prevProps) {

    }
    goToNextStep(){
        return this.props.goToNextStep()
    }
    
    goToNextStepRender(){
        if (this.props.dataToSave) {
            return (
                <Modal
                    trigger={<Button color='yellow'>Etape Suivante</Button>}
                    header='Attention!'
                    content="Des modifications ne sont pas sauvegardées. Es tu sûr de vouloir changer d'étape?"
                    actions={['Annuler', { key: 'done', content: 'Oui', negative:true, onClick: this.goToNextStep }]}
                />
            )
        }else{
            return(  <Button color='yellow' onClick={this.goToNextStep}>Etape Suivante</Button>)
        }
    }

    goToPreviousStep(){
        return this.props.goToPreviousStep()
    }

    goToPreviousStepRender(){
        if (this.props.dataToSave) {
            return (
                <Modal
                    trigger={<Button color='yellow'>Etape Précédente</Button>}
                    header='Attention!'
                    content="Des modifications ne sont pas sauvegardées. Es tu sûr de vouloir changer d'étape?"
                    actions={['Annuler', { key: 'done', content: 'Oui', negative:true, onClick: this.goToPreviousStep }]}
                />
            )
        }else{
            return(  <Button color='yellow' onClick={this.goToPreviousStep}>Etape Précédente</Button>)
       
        }
    }


    createNextStep(){
        return this.props.createNextStep()
    }

    createNextStepRender(){
        if (this.props.dataToSave) {
            return (
                <Modal
                trigger={<Button color='green'>Créer une étape suivante</Button>}
                header='Attention!'
                content="Des modifications ne sont pas sauvegardées. Es tu sûr de vouloir changer d'étape?"
                actions={['Annuler', { key: 'done', content: 'Oui', negative:true, onClick: this.createNextStep }]}
            />
            )
        }else{
            return(  <Button color='green' onClick={this.createNextStep}>Créer une étape suivante</Button>) 
        }
    }

    createPreviousStep(){
        return this.props.createPreviousStep()
    }

    createPreviousStepRender(){
        if (this.props.dataToSave) {
            return (
                <Modal
                    trigger={<Button color='green'>Créer une étape précédente</Button>}
                    header='Attention!'
                    content="Des modifications ne sont pas sauvegardées. Es tu sûr de vouloir changer d'étape?"
                    actions={['Annuler', { key: 'done', content: 'Oui', negative:true, onClick: this.createPreviousStep }]}
                />
            )
        }else{
            return(  <Button color='green' onClick={this.createPreviousStep}>Créer une étape précédente</Button>)    
        }
    }

    saveCurrentStep(){
        return this.props.saveCurrentStep()
    }

    deleteCurrentStep(){
        return this.props.deleteCurrentStep()
    }


renderButtons(){
    if(this.props.step.currentPositionInCourse==1 && this.props.course.stepsCount>1){
        
     // cas où la currentStep est la première du parcours
        return (
            <div>
                <Button color='yellow' onClick={this.goToPreviousStep} disabled >Etape précédente</Button>
                {this.createPreviousStepRender()}
                {this.createNextStepRender()}
                {this.goToNextStepRender()}
            </div>
        )

    } else if ( this.props.course.stepsCount===1 && this.props.step.currentPositionInCourse===1){
       
        //cas où la step est la seule step du parcours
        return (
            <div>   
                <Button color='yellow' onClick={this.goToPreviousStep} disabled>Etape précédente</Button>
                {this.createPreviousStepRender()}
                {this.createNextStepRender()}
                <Button color='yellow' onClick={this.goToNextStep} disabled >Etape Suivante</Button>
            </div> 
        )

    } else if(!this.props.step.nextStep && this.props.course.stepCount!=1){
        
        //cas où la step est la dernière du parcours
        return( 
            <div>
                {this.goToPreviousStepRender()}
                {this.createPreviousStepRender()}
                {this.createNextStepRender()}
                <Button  color='yellow'onClick={this.goToNextStep} disabled >Etape Suivante</Button>
            </div>
        ) 
    }else {
        // tout les autres cas
        return (
            <div>
                {this.goToPreviousStepRender()}
                {this.createPreviousStepRender()}
                {this.createNextStepRender()}
                {this.goToNextStepRender()}
            </div>
        )
    }
}

    render() {
        
        if(this.props.step){
        return (
            <div id="stepCounter">
                <h1>Parcours : {this.props.course.title} </h1>
                <h2>Etape: {this.props.step.description}</h2>
                <p>{this.props.step.currentPositionInCourse}/{this.props.course.stepsCount}</p>
                <Button color ='blue' onClick={this.saveCurrentStep} disabled={this.props.dataToSave===true?false:true}>Sauvegarder les modifications</Button>
                <Modal
                trigger={<Button color='red' disabled={this.props.course.stepsCount > 1 ?false:true} attached='right'> Supprimer cette étape</Button>}
                header='Attention!'
                    content="Tu es sur le point de supprimer cette étape sans aucun état d'ame. Est-ce bien ce que tu souhaites?"
                    actions={['Annuler', { key: 'done', content: 'Oui', negative:true, onClick: this.deleteCurrentStep }]}/>
                {this.renderButtons()}

                
            </div>)
        }else {
            return "Chargement"
        }
    }
}
export { StepCountComponent }