import { extensionConstants } from '../constants';
import { modalConstants } from '../constants';
import { modalActions } from './modal_action';
import { spreadsheetsActions } from './model/spreadsheets_actions';
import { serialMonitorActions } from './';

import * as api from '../api';
import runtimeEnv from '@mars/heroku-js-runtime-env';

import {extensionWebSerial} from '../libs/ExtensionWebSerial';

export const extensionActions = {
    configure,
    connect,
    connected,
    disconnect,
    disconnected,
    sendMessage,
    checkMsg,
    updateToken,
    messageReceived,
    resetCompilationCode,
    newId,
    open,
    close, 
    toggleAutoConnect,
    getBricks,
    toggleWebSerial,
    setWebSerial,
    askPermissionModalChangeState
};

const trampoline = fn => (...args) => {
  let result = fn(...args)
  
  while (typeof result === 'function') {
    result = result()
  }
  
  return result
}

const initialReceiveObject = {
  currentStep: 'start',
  rawData: null,
  type: null,
  length: null,
  data: null
}

const thingzSerialProtocol = {
    type: {
        SYSTEM:1,
        USER:2,
        PLUGGED:3,
        UNPLUGGED:4
    }
}
let receiveObject = initialReceiveObject;
let timeoutInProgress = false;
let tmpLog = "";
let refreshTime = 250;
let lastReceived = Date.now();
let config = "extension";


//Set config to "extension" to use default chrome extension set it to "webserial" to use new WebSerial API
function configure(conf){
    config = conf;
}

function updateReceiveObject(receive){
    receiveObject = receive;
}

function resetReceiveObject(){
    receiveObject = initialReceiveObject;
}

function _getLibsFromCode(code){
    let libs = []
    for(let lib in extensionConstants.LIBS){
        if(code.includes(lib))
            libs.push(extensionConstants.LIBS[lib])
    }
    return libs;
}


/**
 *
 * Connect to Thingz Extenstion
 * Id is the extension id, can be changed in env files
 *
 */

function connect() {
    return (dispatch, getState) => {
        if(config == "webserial"){
            dispatch(connected(extensionWebSerial))
            return;
        }
        if(window.chrome === undefined){
            dispatch({ type: extensionConstants.NOT_ON_CHROME })
            return;
        }
        if(window.chrome.runtime === undefined){
            dispatch(setWebSerial(true))
            return;
        }
        dispatch({ type: extensionConstants.IS_CHROME});
        let id = getState().extension.extensionId;
        let port = window.chrome.runtime.connect(id === null ? runtimeEnv().REACT_APP_EXTENSION_ID : id);
       console.log(runtimeEnv().REACT_APP_EXTENSION_ID, config)
        dispatch(connected(port));
    }
}

function connected(port){
    return (dispatch, getState) => {
        let interval = getState().extension.reconnectionInterval;
        clearInterval(interval);
        dispatch({ type: extensionConstants.CONNECTED, port});
    }
}

/**
 *
 * Disconnect form extension
 *
 */

function disconnect(){
    return (dispatch, getState) => {
        let port = getState().extension.port;
        if(port)
            port.disconnect()
        if(getState().extension.timeout !== null)
            clearTimeout(getState().extension.timeout);
        clearInterval(getState().extension.reconnectionInterval)
        dispatch({type: extensionConstants.DISCONNECTED, reconnectionInterval: null});
    }
}

function disconnected(){
    return (dispatch, getState) =>{
        if(getState().extension.reconnectionInterval !== null)
            clearInterval(getState().extension.reconnectionInterval);
        
        if(getState().extension.timeout !== null)
            clearTimeout(getState().extension.timeout);

        // let reconnectionInterval = setInterval(function(){
        // }, 500)
        dispatch({type: extensionConstants.DISCONNECTED, reconnectionInterval:null});
        dispatch(setWebSerial(true));
    }
}

/**
 *
 * Send message to extension
 * Could be version, compilation or reset
 *
 */

function sendMessage(msg){
    return (dispatch, getState) => {
        let extension = getState().extension;
     
        if(extension.port && extension.pendingCompilation === false){
            checkMsg(msg, extension, dispatch);            
        }else if(msg.msg.cmd === "compilation"){
            dispatch(modalActions.addModalMessageMid({
                type: modalConstants.MODAL_MESSAGE_TYPE.CANT_SEND_MSG,
            }))
        }
    }
}

function checkMsg(msg, extension, dispatch) {
    if(msg.msg.cmd === "compilation"){
        dispatch(pendingCompilation(msg.msg.data));
        //Check if it's a flash of a galaxia. If so get potential libs and update cmd
        if(msg.msg.filterBoards && msg.msg.filterBoards.includes("galaxia")){
            let files = [{content:msg.msg.data, path:'code.py', main:true}]
            let libs = _getLibsFromCode(msg.msg.data);
            for(let lib of libs){
                // api.serverCompilationApi.fetchLib(lib).then(resp => {
                //     _extractFilesFromZip(resp).then(lib_files => {
                //         for(let file of lib_files){
                //             files.push({content:file.content, name:file.name})
                //         }
                //     })
                // })
                for(let lib_file of lib){
                    files.push(lib_file)
                }
            }

            msg.msg.cmd = "write_files"
            msg.msg.data = files
            sendRequest(msg, extension, dispatch);
            return;
        }
    }
	if (msg.msg.data && msg.msg.data.includes('setAuthorizationToken(#key)')) {
        dispatch(spreadsheetsActions.generateToken());
    }
    else {
        sendRequest(msg, extension, dispatch);

    }     
}

function sendRequest(msg, extension, dispatch) {
    if(extension.timeout)
        clearTimeout(extension.timeout);
    let timeout = setTimeout(() => {
        console.log("timeout ", msg.timeout)
        dispatch(error(msg.msg.cmd, "timeout"));
    }, msg.timeout)
    dispatch(pendingMessage(timeout));
    extension.port.postMessage(msg.msg);
}

function updateToken(token) {
    return (dispatch, getState) => {
        let extension = getState().extension;
        const regex = /#key/gi;
        const str = '\"' + token + '\"';
        let code = extension.compilationCode;
        code = code.replace(regex, str);
        sendRequest({msg: {cmd: 'compilation', data: code, url: runtimeEnv().REACT_APP_COMPILATION_SERVER}, timeout: 45000}, extension, dispatch);

        dispatch({type: extensionConstants.PENDING_COMPILATION, code});
    }
}

function open(noProtocol){
   return sendMessage({msg:{cmd:'open', options:{bitrate:9600}, noProtocol}, timeout:10000})
}

function close(){
   return sendMessage({msg:{cmd:'close'}, timeout:10000})
}

function getBricks(){
    return sendMessage({msg:{cmd:"getBricks"}, timeout:10000})
}

function toggleAutoConnect(){
    return (dispatch, getState) => {
        dispatch({type: extensionConstants.TOGGLE_AUTOCONNECT})
    }
}

function toggleWebSerial(){
    return {type: extensionConstants.TOGGLE_WEBSERIAL}
}

function setWebSerial(enable){
    return {type: extensionConstants.SET_WEBSERIAL, enable}
}

/**
 *
 * Something went wrong. Most probable cause is no response from the extension.
 * If the command was version -> the extension is not installed
 * Else the extension is not 100% sure and maybe it crashed so we send a reset command in order to try to go
 * back in a correct state
 *
 */

function error(cmd, err){
    console.log(cmd, err)
    return (dispatch, getState) => {
        switch(cmd){
        case "version":
            if(err === "timeout"){
                setTimeout(function(){
                    dispatch(sendMessage({msg:{cmd:"version"}, timeout:500}))
                }, 5000);
                return dispatch({type: extensionConstants.EXTENSION_NOT_INSTALLED, undefined})
            }
        break;
        case "write_files":
        case "compilation":
            if(err === "timeout"){
                 dispatch(modalActions.addModalMessageMid({
                    type: modalConstants.MODAL_MESSAGE_TYPE.COMPILATION_TIMEOUT,
                    text: "timeout"
                }))
            }
            dispatch({type: extensionConstants.COMPILATION_FAILURE, value: "timeout"});
            dispatch(sendMessage({msg:{cmd:"reset"}, timeout:500}))
        break;
        case "open":
            console.log("OPEN on error")
            setTimeout(function(){
                if(!getState().extension.isComOpen )
                    dispatch(sendMessage({msg:{cmd:'open', options:{bitrate:9600}}, timeout:10000}))
            }, 1000)
        break;
        case "search":
            setTimeout(function(){
                if(!getState().extension.isComOpen)
                    dispatch(sendMessage({msg:{cmd:'search'}, timeout:10000}))
            }, 1000)
        break;
        default:
            dispatch({ type: extensionConstants.ERROR, payload:{cmd, err}});
            dispatch(sendMessage({msg:{cmd:"reset"}, timeout:500}))
        }
    }
}

/**
 *
 * A message was sent, we are waiting for a response
 *
 */

function pendingMessage(timeout){
    return { type: extensionConstants.PENDING_MESSAGE, timeout};
}

/**
 *
 * A compilation request was sent
 *
 */


function pendingCompilation(code){
    return { type: extensionConstants.PENDING_COMPILATION, code};
}

/**
 *
 * Message received from the extension
 *
 */

function messageReceived(msg){
    return (dispatch, getState) => {
        let extension = getState().extension;
        // if(extension.pendingMsg === false)
        //     return dispatch(error(msg.cmd, "unexpected command"))
        clearTimeout(extension.timeout);
        // dispatch(messageDone(msg));
        //console.log(msg)
    }
}

function askPermissionModalChangeState(state){
    return {type: extensionConstants.ASK_PERMISSION_MODAL_CHANGE_STATE, state}
}

/**
 *
 * In order to know if the user tests their code we have a flag that specify if a compilation was issued. 
 * It need to be reset when the user changes step
 *
 */

function resetCompilationCode(){
    return {type: extensionConstants.RESET_COMPILATION_CODE, undefined};
}

/**
 *
 * Used in dev to change the id of the extension
 *
 */

function newId(value){
    return (dispatch, getState) => {
        dispatch({type: extensionConstants.NEW_ID, value});
        if(getState().extension.port !== null)
            dispatch(disconnect());
    }
}