import React from 'react'
import {withRouter} from 'react-router-dom'
import {
    Form,
    Segment,
    Message,
    Button,
    Header,
    Divider
} from 'semantic-ui-react'

const InstructorForm = (props) => {

    return (
        <Form size='large' loading={props.isLoading} error={props.error !== null}>
            <Segment>
                {props.error && (
                    <Message error>
                    <Header>Erreur d'inscription</Header>
                    {props.error.data.message.map((error, key) => {return <p key={key}>{error.message}</p>})}</Message>
                )}
                <Form.Input
                    autoFocus
                    name={'username'}
                    onChange={props.onUserChange}
                    fluid
                    icon='user'
                    iconPosition='left'
                    placeholder='Nom ou pseudo'
                    style={{marginBottom:'5px'}}
                    label='Nom ou pseudo'
                    type='text'
                />
                <Form.Input
                    name={'email'}
                    onChange={props.onUserChange}
                    fluid
                    icon='mail'
                    iconPosition='left'
                    placeholder='E-mail'
                    style={{marginBottom:'5px'}}
                    label='E-mail'
                    />
                <Form.Input
                    name={'password'}
                    onChange={props.onUserChange}
                    fluid
                    icon='lock'
                    iconPosition='left'
                    placeholder='Mot de passe'
                    style={{marginBottom:'5px'}}
                    label='Mot de passe'
                    type='password'
                />
                
                {/* Section exclusive au role Instructor */}

                <Divider/>
                {/* <Segment> */}
                <Form.Input
                    type={'text'}
                    name={'name'}
                    onChange={props.onStructureChange}
                    fluid
                    // icon='home'
                    // iconPosition='left'
                    placeholder="Nom de la structure"
                    style={{marginBottom:'5px'}}
                    label="Nom de la structure"
                />
                <Form.Select
                    name={'type'}
                    onChange={props.onStructureChange}
                    fluid
                    // icon='home'
                    // iconPosition='left'
                    placeholder="Type de structure"
                    style={{marginBottom:'5px'}}
                    label="Type de structure"
                    options={[
                        {key: 'epri', value: 'Ecole primaire', text: 'Ecole primaire'},
                        {key: 'coll', value: 'Collège', text: 'Collège'},
                        {key: 'lycé', value: 'Lycée', text: 'Lycée'},
                        {key: 'asso', value: 'Association', text: 'Association'},
                        {key: 'autr', value: 'Autre', text: 'Autre'},
                    ]}
                />
                <Form.Input
                    type={'text'}
                    name={'city'}
                    onChange={props.onUserChange}
                    fluid
                    // icon='home'
                    // iconPosition='left'
                    placeholder="Ville"
                    style={{marginBottom:'5px'}}
                    label="Ville"
                />
                </Segment>
                <Button type="submit" color='orange' fluid size='large'
                        onClick={(e) => {e.preventDefault();props.onSubmit(props.history, 'instructor')}}>
                    Inscription
                </Button>
            {/* </Segment> */}
        </Form>
    )
}

export default withRouter(InstructorForm)