import React, { Component } from 'react'
import {connect} from 'react-redux'
import { Detector } from 'react-detect-offline';
import runtimeEnv from '@mars/heroku-js-runtime-env';

import { 
	Input,
	Label, 
	Icon,
	Title,
 } from 'semantic-ui-react'

import { freeCreationActions } from '../../../actions'

import './InputSaveApp.css'


class InputSaveApp extends Component {

	constructor(props) {
		super(props)

		this.timer = null

		this.handleChange = this.handleChange.bind(this)
		this.handleSave = this.handleSave.bind(this)
	}
	
	handleChange(event) {
		const value = event.target.value
		this.props.dispatch(freeCreationActions.titleOnChange(value))
		this.handleSave()
		// this.props.autoSave(window.Blockly.Xml.domToPrettyText(window.Blockly.Xml.workspaceToDom(this.workspace)))
	}

	handleSave() {
		clearTimeout(this.timer)
		// arrow function  plutot qu'appel direct a la fonction pour éviter un side effect du scope
		this.timer = setTimeout(() => {this.props.dispatch(freeCreationActions.saveCurrentApp())}, 500)
	}

	renderInput(online, error) {
		//Rendu Conditionnel pour affichage titre en readOnly 
		if (this.props.freeCreation.readOnly === true) {
			return <div>
				<h4>{this.props.freeCreation.nom}</h4>
				</div>
		}else {
				return <Input 
					size='small' 
					onChange={this.handleChange} 
					error={error} fluid type='text' 
					value={this.props.freeCreation.nom ? this.props.freeCreation.nom : ''} 
					loading={this.props.freeCreation.isLoading?true:false} 
					disabled={!online?true:false}/>
			}

		// if(this.props.freeCreation.isLoading) {
		// 	return <Input size='small' onChange={this.handleChange} error={error} fluid type='text' value={this.props.freeCreation.nom ?  this.props.freeCreation.nom : ''} loading/>
		// }

		// if(!online) {
		// 	return(<Input size='small' onChange={this.handleChange} error={error} fluid type='text' value={this.props.freeCreation.nom ?  this.props.freeCreation.nom : ''} disabled/>)
		// } else {
		// 	return(<Input size='small' onChange={this.handleChange} error={error} fluid type='text' value={this.props.freeCreation.nom ?  this.props.freeCreation.nom : ''}/>)
		// }
	}

	renderStatus(online) {
		
		// let renderStatus = null
		let className = "HeaderFreeCreationStatusText"
		let text = ""
		
		// Si perte de connection :
		if(!online){
			text = "Hors ligne : Ton application sera sauvegardée au rétablissement de la connexion internet"
			className += " text-orange"
		} else {
			if(this.props.freeCreation.readOnly === true){
				text = "Tu consultes l'application d'un autre utilisateur"
				className += " text-orange"
			}else if(this.props.freeCreation.isLoading === false && !this.props.freeCreation.id){
				text = "En ligne : Ton application sera sauvegardée dès que tu aura modifié ton application"
			}else if(this.props.freeCreation.isLoading === false){
				text = "En ligne : Ton application est sauvegardée"
			}else if(this.props.freeCreation.isLoading === true){
				text = "En ligne : Ton application est en cours de sauvegarde"
			}
	
			if(this.props.freeCreation.error){
				text = "Erreur : Une erreur est survenue lors de la sauvegarde de ton application"
				className += " text-red"
			}
		}

		if(this.props.status !== text)
			this.updateStatus(text)
		
		return <div className={className}>{this.props.status}</div>
	}

	updateStatus(text) {
		// if(this.props.status !== text) {
			this.props.dispatch(freeCreationActions.changeStatus(text))
		// }
	}

	render() {
		let error = this.props.state === 'notSaved';
		return (
			<div>
				<Detector polling={{url: runtimeEnv().REACT_APP_CONNECTION_DETECTION_POOLING, enabled: true, interval: 6000}} render={({online}) => (
					this.renderInput(online, error)
				)}/>
				<Detector polling={{url: runtimeEnv().REACT_APP_CONNECTION_DETECTION_POOLING, enabled: true, interval: 6000}} render={({online}) => (
					this.renderStatus(online, error)
				)}/>
			</div>
		)
	}
}

function mapStateToProps (state) {
    return {
        freeCreation: state.freeCreation,
		status: state.freeCreation.status,
	
    }
}

const connectedInputSaveApp = connect(mapStateToProps)(InputSaveApp)
export {connectedInputSaveApp as InputSaveApp}