import { LOCATION_CHANGE } from 'react-router-redux';

import { modelConstants } from '../../constants';
import { coursePageConstants } from '../../constants';
import { progressionsConstants } from '../../constants';


const initialState = {
    entities: {},
    isLoading: 0
};

export const progressions = (state = initialState, action) => {
	switch (action.type) {
    case modelConstants.FETCH_ENTITIES_STARTED:
    case modelConstants.FETCH_ENTITY_STARTED:
    case modelConstants.CREATE_ENTITY_STARTED:
    case modelConstants.UPDATE_ENTITY_STARTED:
    case modelConstants.DELETE_ENTITY_STARTED:
        if(action.entities === "progressions")
            return {
                ...state,
                isLoading: state.isLoading + 1
            }
        return state;
    case modelConstants.FETCH_ENTITIES_DONE:
    case modelConstants.CREATE_ENTITY_DONE:
    case modelConstants.UPDATE_ENTITY_DONE:
    case modelConstants.FETCH_ENTITY_DONE:
        if(action.entities.entities && action.entities.entities.progressions ){
            return {
                ...state,
                entities: mergeProgressions(state.entities, action.entities.entities.progressions),
                isLoading: action.originalEntities === "progressions" && state.isLoading > 0 ? state.isLoading - 1 : state.isLoading
            }
        }else if(action.originalEntities === "progressions"){
            return {
                ...state,
                isLoading: state.isLoading > 0 ? state.isLoading-1 : 0
            }
        }
        return state;

    case modelConstants.DELETE_ENTITY_DONE:
        if(action.entities.entities && action.entities.entities.progressions ){
            return {
                ...state,
                entities: action.entities.entities.progressions,
                isLoading: action.originalEntities === "progressions" && state.isLoading > 0 ? state.isLoading - 1 : state.isLoading
            }
        }else if(action.originalEntities === "progressions"){
            return {
                ...state,
                isLoading: state.isLoading > 0 ? state.isLoading-1 : 0
            }
        }
        return state;
    case coursePageConstants.SAVE_PROGRESSION_XML:
    	return {
    		...state,
    		entities:{
    			...state.entities,
    			[action.progression]: {...state.entities[action.progression], xml: action.xml}
    		}
        }
    case progressionsConstants.FETCH_FAILED:
    case progressionsConstants.FETCH_ALL_FAILED:
    case progressionsConstants.FETCH_BY_COURSE_FAILED:
    case progressionsConstants.CREATE_BY_COURSE_FAILED:
    case progressionsConstants.UPDATE_FAILED:
    case progressionsConstants.DELETE_PROGRESSION_FAILED:
    	return {
    		...state,
    		isLoading: state.isLoading > 0 ? state.isLoading-1 : 0,
    		error: action.error
    	}

    case coursePageConstants.RESET_TO_PREVIOUS_XML:
        let newXml = String(state.entities[action.progression].previousXml);
        if(!newXml || newXml.length === 0)
            return state;
        return {
            ...state,
            entities:{
                ...state.entities,
                [action.progression]: {...state.entities[action.progression], xml: newXml}
            }
        }
    case modelConstants.INVALIDATE_ENTITIES:
        return initialState;
    default:
        return state;
  }
}

function mergeProgressions(currentProgressions, newProgressions){
    if(!newProgressions)
    newProgressions = {};
    
    for(let c in currentProgressions){
        if(newProgressions[c])
            continue;
        newProgressions[c] = {};
        newProgressions[c] = {...currentProgressions[c]};
    }
    return newProgressions;
}