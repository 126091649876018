import React, {Component} from 'react';
import { Message, Accordion, Icon, Segment, List, Button, Input, Table, Popup, Dropdown, Divider, Tab } from 'semantic-ui-react';

import { GuestsListPDF } from '../../GuestsListPDF';

export default class AdministrationAddUsers extends Component{

    constructor(props){
        super(props)

        this.state = {
            open: false,
            id:1,
            showRequestResult:false,
            users:[
                {id: 0, username:""}
			],
			group: "nogroup",
            downloadPdf: false
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.users.isLoading > this.props.users.isLoading && Object.keys(prevProps.users.entities).length < Object.keys(this.props.users.entities).length){
			this.setState(prev => {
				let users = this.state.users.filter(user => {
					return !Object.values(this.props.users.entities).find(u => {
						//On vient de créer cette utilisateur
						return (user.username.length === 0 || ( user.username === u.username && u.password));
					})
				})
				let id = prev.id;
				if(users.length == 0){
					users.push({id:id, username:""});
					id++;
				}
				return {
                    ...prev,
                    showRequestResult: true,
                    downloadPdf: true,
                    users:users,
                    id:id
				}
			})
		}
    }

    openAddStudents = () => {
        this.setState(prev => {
			return {
				...prev,
                open: !prev.open
			}
		})
    }

    addStudentsToList = () => {
		this.setState(prev => {
			return {
				...prev,
				users: [...prev.users, {username: "", id:prev.id}],
				id: prev.id+1,
			}
		})
    }
    
    addStudentUsernameChanged = (event, data) =>{
		event.persist();
		this.setState(prev => {
			let users = prev.users.map(user => {
				if(user.id !== parseInt(event.target.attributes.studentid.value))
					return user;
				let u = user;	
				u.username = data.value;
				return u
			})

			return{
                ...prev.addStudents,
                users: users
			}
		})
	}


    removeStudentsFromList = (event) => {
		if(this.state.users.length === 1)
			return;
		event.persist();
		const studentId = event.target.attributes.studentid.value;
		this.setState(prev => {
			let users = prev.users.filter(user => {
				return user.id !== parseInt(studentId)
			})
			return {
                ...prev,
                users: users
				
			}
			
		})
	}

    onAddStudentsSubmit = () => {
		let group = this.state.group !== 'nogroup' ? this.state.group : null
		this.props.onAddStudents(this.state.users,group);
	}

	onPdfDownloaded = (blob) => {
		this.setState(prev => {
			if(!prev.downloadPdf)
				return;

			if(window.navigator.msSaveBlob){
				window.navigator.msSaveBlob(blob, `${this.props.structure.name}_élèves_${new Date().toLocaleDateString('fr-FR')}`)
			}else{
				setTimeout(()=>{
					var a = window.document.createElement("a");
					a.href = window.URL.createObjectURL(blob, {type: "application/pdf"});
					a.download = `${this.props.structure.name}_élèves_${new Date().toLocaleDateString('fr-FR')}`;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
				}, 20)
			}
			return {
					...prev,
					downloadPdf:false
			}
		})
	}

	onChangeGroup = (e, data) => {
		this.setState(prev => {
			return {
				group: data.value
			}
		})
	}

    renderRequestResult(){
		let createdUsers = Object.values(this.props.users.entities).filter(learner => {
			return (learner.structure === this.props.structure._id || (learner.group && this.props.groups.entities[learner.group] && this.props.groups.entities[learner.group].structure === this.props.structure._id)) && learner.password
		})

		if(createdUsers.length == 0)
			return null

		let webTable =  createdUsers.map(user => {
			return (
				<Table.Row key={user._id}><Table.Cell>{user.username}</Table.Cell><Table.Cell>{user.password}</Table.Cell><Table.Cell>{user.group && this.props.groups.entities[user.group] ? this.props.groups.entities[user.group].name : ""}</Table.Cell></Table.Row>
			)
		})

		const UsersTable = () => (
			<Table celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Nom d'utilisateur</Table.HeaderCell>
							<Table.HeaderCell>Mot de passe</Table.HeaderCell>
							<Table.HeaderCell>Groupe</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{webTable}
					</Table.Body>
				</Table>
		);

		


		if(!this.state.open)
			return null
		
		//Use a key attr to GuestsListPDF because PDFDownload link seems to not be able to regenerate the pdf
		//so better create a new each time the content changes
		return(
			<Segment>
				<Message floating warning style={{display:"block"}}>
					<Message.Header>Attention</Message.Header>
					<p>Sauvegardez bien les mots de passe de vos élèves, nous ne pourrons pas vous les redonner</p>
					<p>
					{this.state.open && <GuestsListPDF autoDownload={this.onPdfDownloaded} key={createdUsers.map(user => (user._id)).join('')} users={createdUsers} groups={this.props.groups.entities} structureName={this.props.structure.name} filename={`${this.props.structure.name}_élèves_${new Date().toLocaleDateString('fr-FR')}`}/>}
					</p>
				</Message>
				<p>
					Les utilisateurs suivants ont été créés.
				</p>
				<UsersTable/>
			</Segment>
		)
	}

	renderError(){
		let error = this.props.users.error;
		if(!error)
			return;
		if(error instanceof Error)
			error = [{message: error.toString()}]
		else if(typeof error === "string"){
				error = [{message: error}]
		}
		return (
			<Message error header="Erreur" list={error.map(error => (error.message))}/>
		)
	}

    render(){
        let newUsers = this.state.users.map((user, i) => {
			let addButton = null;
			if(i == this.state.users.length - 1){
			addButton = (<Popup content="Ajouter un élève" trigger={<Button onClick={this.addStudentsToList}><Icon name="add"/></Button>}/>)
			}
			return (
				<List.Item key={i}>
					<Input placeholder="Nom de l'élève" action onChange={this.addStudentUsernameChanged}>
						<input studentid={user.id} value={user.username}/>
						<Popup content="Supprimer l'élève" trigger={<Button color='red' onClick={this.removeStudentsFromList} studentid={user.id}><Icon studentid={user.id} name="delete"/></Button>}/>
						{addButton}
					</Input>
					
				</List.Item>
			)
		})

		let groups = Object.values(this.props.groups.entities).filter(group => {
			return group._id != "nogroup"
		})
		groups = groups.map( group => {
			return {
				key: group._id,
				text:  group.name,
				value: group._id,			
			}
		}).sort((a, b) => {
			return a.text.localeCompare(b.text);
		})
		groups.unshift({
			key: "nogroup",
			text: "Aucun groupe",
			value: "nogroup"
		})
        return (
            <React.Fragment>
                <Accordion.Title
                    index={1}
                    onClick={this.openAddStudents}
                    active={this.state.open}>
                    <Icon name='user plus' />
                    Ajouter des élèves
                </Accordion.Title>
                <Accordion.Content active={this.state.open}>
                    <Segment loading={this.props.users.isLoading > 0} style={{border: 0}}>
						{this.renderError()}
						<p>
							Dans le groupe
						</p>
						<Dropdown
							placeholder=''
							selection
							options={groups}
							value={this.state.group}
							onChange={this.onChangeGroup}
						/>
                        <List>
                            {newUsers}
                        </List>
						<Divider hidden/>
                        <Button compact basic color='orange' onClick={this.onAddStudentsSubmit}>Valider</Button>
                        {this.state.showRequestResult && this.renderRequestResult()}
                    </Segment>
                </Accordion.Content>
            </React.Fragment>
        )
    }
}