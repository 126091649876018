import React from 'react';
import './Bandeau.css'
import {Container, Grid} from 'semantic-ui-react'


const Bandeau = props => {
    return (
      <Container fluid className={'bandeau-container'}>
      	
      </Container>
    );
};

Bandeau.propTypes = {};

export default Bandeau;
