import axios from 'axios';
import {sessionService} from 'redux-react-session'
import runtimeEnv from '@mars/heroku-js-runtime-env'
import history from '../history'

const API_BASE_URL = runtimeEnv().REACT_APP_API_BASE_URL;

const client = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: ''
    }
});

client.interceptors.request.use(async function (config) {
    try{
    const jwt = await sessionService.loadUser();
    if(jwt)
        config.headers.Authorization = 'Bearer ' + jwt.jwt;
  }catch(e){
      return config;
  }
return config
}, function (error) {
    return Promise.reject(error)
});

client.interceptors.response.use(null, function(error){
    if(error.response.data.statusCode === 403 && error.response.data.message && error.response.data.message.logout)
        history.push("/logout");
    else
      return Promise.reject(error);
})


export const clues = {
fetchFirstClue,
fetchAllByStep,
saveClues,
createClue,
createFirstClue,
deleteClue,

}
function fetchAllByStep(currentStepId){
  return client.get('/clues/fetchAllByStep/'+currentStepId)

}
function fetchFirstClue(currentStepId){ 
   return client.get('/clues/fetchFirstClue/'+currentStepId)

}
function saveClues(clues){
  //console.log(JSON.stringify(clues))
  return client.put('/clues/saveClues',{clues})
}
function createClue(currentStepId,previousClueId){

  console.log({"currentStepId":currentStepId,"previousClueId":previousClueId})
  return client.post('/clues/createClue/',{"currentStepId":currentStepId,"previousClueId":previousClueId})
}
function createFirstClue(currentStepId){
  console.log('coucou')
  return client.post('/clues/createFirstClue/'+currentStepId)
}
function deleteClue(currentStepId,currentClueId){
  console.log('currentStepId',currentStepId)
  console.log('currentClueId',currentClueId)
  return client.delete('/clues/deleteClue/'+currentClueId+'?currentStepId='+currentStepId)

}