import React, {Component} from 'react';
import {connect} from "react-redux";

import {PageWithSideMenu} from "../PageWithSideMenu";
import {ActivityRenderingListComponent} from '../../components/ActivityRenderingListComponent';

import {getActualUser} from "../../reducers/user_reducer";

import {coursesActions, progressionsActions, structuresActions, modelActions, selectedCoursesActions} from "../../actions/model";

class ActivityRenderingList extends Component {

    componentDidMount() {
        if (this.props.match.params.id) {
            //this.props.dispatch(modelActions.invalidate())
            this.props.dispatch(coursesActions.fetch(this.props.match.params.id));
            this.props.dispatch(structuresActions.fetchStructures());
            this.props.dispatch(selectedCoursesActions.fetchAllForStructure());
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.courses.isLoading > 0 && prevProps.courses.isLoading !== this.props.courses.isLoading) { 
            let course = Object.values(this.props.courses.entities).find(c => c._id === this.props.match.params.id)
            if (course) {
                let activityId = course._id;
                this.props.dispatch(progressionsActions.fetchAllByStep(this.props.courses.entities[activityId].firstStep));
            }
        }
    }

    render() {
        let structure = this.props.users.entities[this.props.user._id] ? this.props.structures.entities[this.props.users.entities[this.props.user._id].structureOwned] : undefined;
        
        let groups = {...this.props.groups};
        groups.array = []
        if(structure)
            groups.array = Object.keys(groups.entities).filter(group => {
                return groups.entities[group].structure === structure._id
            }).sort((a, b) => {
                return groups.entities[a].name.localeCompare(groups.entities[b].name)
            })
        if(groups.array.length > 0){
            groups.entities = groups.array.reduce((obj, key) => {
                obj[key] = groups.entities[key]
                obj[key].learners =[]
                return obj
            }, {})
        }else{
            groups.entities = {}
        }
        groups.array.push("nogroup")
        groups.entities.nogroup = {
            _id:"nogroup",
            name:"Élèves sans groupe",
            learners:[]
        }
        return (
            <PageWithSideMenu pageName={"structure"}>
                <ActivityRenderingListComponent 
                    activity={this.props.courses.entities[this.props.match.params.id]}
                    progressions={this.props.progressions.entities}
                    finished={this.props.finishedCourses.entities}
                    users={this.props.users && this.props.users.entities}
                    steps={this.props.steps.entities}
                    structures={this.props.structures.entities}
                    groups={groups}
                    selectedCourses={this.props.selectedCourses.entities}
                    user={this.props.user}
                />
            </PageWithSideMenu>
        );
    }
}

function mapStateToProps(state) {
    return {
        steps: state.model.steps,
        courses: state.model.courses,
        progressions: state.model.progressions,
        finishedCourses: state.model.finishedCourses,
        structures: state.model.structures,
        selectedCourses: state.model.selectedCourses,
        users: state.model.users,
        groups: state.model.groups,
        user: getActualUser(state)
    }
}

const connectedActivityRenderingList = connect(mapStateToProps)(ActivityRenderingList);
export {connectedActivityRenderingList as ActivityRenderingList}