import { freeCreationConstants, blocklyConstants } from "../constants";
import { HeaderFreeCreationControls } from "../components/HeaderFreeCreationControls";
import { freeCreation, initialState } from "../reducers/free_creation_reducer";
import { appsActions } from "./model";
import * as user from "../reducers/user_reducer";
import * as api from "../api";
import * as session from "redux-react-session";

export const freeCreationActions = {
    initiateMainWorkspace,
    selectCurrentApp,
    setTemporaryApp,
    loadFromLocalStorage,
    changeStatus,

    changeStateFreeCreationEditor,

    synchroCodeAce,
    synchroHybridCode,
    saveCurrentApp,
    saveBlocklyXml,
    loadBlocklyXml,
    loadBlocklyXmlDone,
    openModal,
    closeModal,
    openSyncModal,
    closeSyncModal,
    firstLoad,
    titleOnChange,

    loadCode,
    loadCodePython,
    saveCodeAce,
    remixApp,
    resetFreeCreation,
};

function changeStatus(text) {
    return (dispatch, getState) => {
        dispatch({ type: freeCreationConstants.CHANGE_STATUS, payload: text });
    };
}

function resetFreeCreation() {
    return (dispatch) => {
        dispatch({ type: freeCreationConstants.INVALIDATE_FREE_CREA_STATE });
    };
}

function initiateMainWorkspace() {
    return (dispatch, getState) => {
        let params = new URL(document.location).searchParams;
        let id_param = params.get("app");

        // Si l'utilisateur est authentifié, nous initialisons le workspace en fonction de projets sauvegardés
        if (getState().session.authenticated && getState().session.checked) {
            // On récupère ses Apps
            dispatch(appsActions.getApps())
                .then((resp) => {
                    let apps = resp.payload;
                    if (id_param) {
                        dispatch(freeCreationActions.selectCurrentApp(id_param));
                    }
                    // Si l'utilisateur est l'auteur d'une ou plusieurs application, charger la dernière app modifiée
                    else if (apps.length > 0) {
                        //Trie des apps par ordre de date de modification
                        apps = apps.sort((a, b) => {
                            return new Date(b.updatedAt) - new Date(a.updatedAt);
                        });
                        const appId = apps[0]._id;
                        //On récupère la plus récente
                        dispatch(freeCreationActions.selectCurrentApp(appId));
                        // Si il n'en a pas : En créer une autre
                    } else {
                        dispatch(freeCreationActions.setTemporaryApp());
                        // dispatch(freeCreationActions.loadBlocklyXml());
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
            // si l'utilisateur n'est pas connecté et accède à une app via ses params
        } else if (id_param && !getState().session.authenticated) {
            dispatch(freeCreationActions.selectCurrentApp(id_param));
        } else {
            dispatch(freeCreationActions.loadFromLocalStorage());
        }
    };
}

function loadFromLocalStorage() {
    return (dispatch, getState) => {
        dispatch({ type: freeCreationConstants.INVALIDATE_FREE_CREA_STATE });
        dispatch(freeCreationActions.loadBlocklyXml());
        dispatch(freeCreationActions.loadCode());
        dispatch(freeCreationActions.loadCodePython());
    };
}
//
function selectCurrentApp(appId) {
    return (dispatch, getState) => {
        dispatch({ type: freeCreationConstants.SELECT_APP_STARTED });

        return api.AppApi.getOne(appId)
            .then((resp) => {
                dispatch({ type: freeCreationConstants.INVALIDATE_FREE_CREA_STATE });
                dispatch({
                    type: freeCreationConstants.SELECT_APP_SUCCEEDED,
                    payload: resp.data,
                });
                dispatch(freeCreationActions.loadBlocklyXml());
                closeModal();
            })
            .catch((err) => {
                dispatch({
                    type: freeCreationConstants.SELECT_APP_FAILED,
                    payload: err,
                });
            });
    };
}

function setTemporaryApp(isFromDeleteApp) {
    return (dispatch, getState) => {
        //let freeCreationEditorState= getState().freeCreation.freeCreationEditorState;
        dispatch({ type: freeCreationConstants.INVALIDATE_FREE_CREA_STATE, modalIsOpen: isFromDeleteApp });
        let apps = getState().model.apps.apps;
        let newAppState = Object.assign({}, initialState);

        // Si l'utilisateur a déja une application
        if (apps && apps.length > 0) {
            newAppState.nom = `${"Ma super application"} n°${apps.length + 1}`;
            // Si il n'en a pas
        } else {
            newAppState.nom = `${"Ma super application"} n°${1}`;
        }
        try{
            localStorage.setItem("thingz_free_crea_xml", newAppState.xml);
        }catch(e){
            console.log("failed setItem tempApp")
        }
        dispatch(freeCreationActions.loadBlocklyXml());
        dispatch({
            type: freeCreationConstants.SELECT_APP_SUCCEEDED,
            payload: newAppState,
            modalIsOpen: isFromDeleteApp,
        });
    };
}
//sauvegarde
function saveCurrentApp() {
    return (dispatch, getState) => {
        // Vérifie si l'utilisateur est connecté
        if (getState().session.authenticated && getState().session.checked) {
            //Application conforme ?
            if (getState().freeCreation.nom !== null) {
                let app = getState().freeCreation;
                //Cas app existante
                if (app.id) {
                    // Si l'application n'appartient pas à l'utilisateur (pas d'user dans l'app) alors pas de sauvegarde : return
                    if (!app.user || app.user._id != getState().session.user.user._id) return;
                    dispatch({ type: freeCreationConstants.SAVE_CURRENT_APP_STARTED });
                    return api.AppApi.save(app)
                        .then((resp) => {
                            dispatch({ type: freeCreationConstants.SAVE_CURRENT_APP_SUCCEEDED });
                        })
                        .catch((err) => {
                            dispatch({ type: freeCreationConstants.SAVE_CURRENT_APP_FAILED, payload: err });
                        });

                    //Cas nouvelle APP
                } else {
                    dispatch({ type: freeCreationConstants.SAVE_NEW_APP_STARTED });
                    return api.AppApi.create(app)
                        .then((resp) => {
                            dispatch({ type: freeCreationConstants.SAVE_NEW_APP_SUCCEEDED, payload: resp.data });
                            dispatch(appsActions.getApps());
                        })
                        .catch((err) => {
                            console.log(err);
                            dispatch({ type: freeCreationConstants.SAVE_NEW_APP_FAILED, payload: err });
                        });
                }
            }
        } else {
            //si utilisateur non connecté
            if (getState().freeCreation.readOnly) return;

            // sauvegarde du xml dans le localstorage
            if (getState().freeCreation.xml) {
                try {
                    window.localStorage.setItem("thingz_free_crea_xml", getState().freeCreation.xml);
                } catch (e) {
                    dispatch({ type: freeCreationConstants.SAVE_XML_FAILED });
                }
            }

            // sauvegarde le code cpp de Ace (même vide) dans le local storage
            try {
                // Évite la sauvegarde de la chaîne de caractère "null" dans le local storage
                if (getState().freeCreation.code === null) {
                    window.localStorage.setItem("thingz_free_crea_codeAce", "");
                } else {
                    window.localStorage.setItem("thingz_free_crea_codeAce", getState().freeCreation.code);
                }
            } catch (error) {
                dispatch({ type: freeCreationConstants.SAVE_CODE_ACE_FAILED });
            }

            // sauvegarde le code python de Ace (même vide) dans le local storage
            try {
                // Évite la sauvegarde de la chaîne de caractère "null" dans le local storage
                if (getState().freeCreation.codePy === null) {
                    window.localStorage.setItem("thingz_free_crea_codeAcePy", "");
                } else {
                    // console.log("SAVE : ",  getState().freeCreation.codePy);
                    window.localStorage.setItem("thingz_free_crea_codeAcePy", getState().freeCreation.codePy);
                }
            } catch (error) {
                dispatch({ type: freeCreationConstants.SAVE_CODE_ACE_FAILED });
            }
        }
    };
}

//Gestion du XML généré et utilisé par blockly ainsi que le C++ de "l'interface Hybride"

function saveBlocklyXml(xml, codeHybride) {
    return (dispatch, getState) => {
        if (getState().blockly.mainWorkspace && getState().blockly.mainWorkspace.getAllBlocks().length == 0) {
            dispatch(freeCreationActions.loadBlocklyXml());
        } else if (
            getState().blockly.mainWorkspace &&
            (getState().blockly.mainWorkspace.getAllBlocks().length > 1 || (getState().freeCreation.codeHybride && getState().freeCreation.codeHybride.localeCompare(codeHybride)))
        ) {
            dispatch({ type: freeCreationConstants.SAVE_XML, xml, codeHybride });
            if (
                getState().freeCreation.readOnly ||
                (getState().blockly.mainWorkspace.getAllBlocks().length != 0 && xml && xml.match(/<xml xmlns="http:\/\/www.w3.org\/1999\/xhtml">[\s]*<variables>[\s]*<\/variables>[\s]*<\/xml>/g))
            )
                return;
            dispatch(saveCurrentApp());
        }
    };
}

function loadBlocklyXml() {
    return (dispatch, getState) => {
        let xml = null;
        //Utilisateur connecté?
        const readOnly = getState().freeCreation.readOnly;
        if ((getState().session.authenticated && getState().session.checked) || readOnly) {
            xml = getState().freeCreation.xml;
        } else {
            try {
                xml = window.localStorage.getItem("thingz_free_crea_xml");
            } catch (e) {
                dispatch({ type: freeCreationConstants.LOAD_XML_FAILED });
            }
        }
        dispatch({ type: freeCreationConstants.LOAD_XML, xml });
    };
}

function loadBlocklyXmlDone() {
    return { type: freeCreationConstants.LOAD_XML_DONE };
}

// Cette Action switch le mode d'affichage Blockly/Hybride/Ace
function changeStateFreeCreationEditor(currentState, targetState, readOnly) {
    console.log({ currentState, targetState, readOnly });

    // ToDo : Cette vérification semble obselète : plus de prblme de chargement / d'affichage du code hybride une fois commenté
    // Si on ne vient pas d'Ace et que l'on ne va pas vers Ace, met à jour un props pour déclancher l'update & le rafraîchissement de l'affichage du code hybride & de Blockly
    if (!currentState.startsWith("Ace") && !targetState.startsWith("Ace")) {
        console.log("TargetSTATE !", targetState);
        let xmlReadyToLoad = true;
        return { type: freeCreationConstants.EDITOR_STATE, targetState, readOnly, xmlReadyToLoad };
    }

    return { type: freeCreationConstants.EDITOR_STATE, targetState, readOnly };
}

//Gestion du C++
function saveCodeAce(codeAce) {
    return (dispatch, getState) => {
        dispatch({ type: freeCreationConstants.SAVE_CODE_ACE, codeAce });
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            dispatch(saveCurrentApp());
        }, 500);
    };
}

function loadCode() {
    let codeAce = null;
    return (dispatch, getState) => {
        //utilisateur logué => on cherche le code sauvé dans le state
        if (getState().session.authenticated && getState().session.checked) {
            //Si le code n'existe pas dans le state on le fait correspondre avec le code généré par blockly
            if (getState().freeCreation.code == null || !getState().freeCreation.code) {
                dispatch(synchroHybridCode());
                dispatch({ type: freeCreationConstants.SYNCHRO_CODE_ACE });

                return;
            }
            codeAce = getState().freeCreation.code;

            // utilisateur non loggué => on cherche dans le local storage
        } else {
            try {
                codeAce = window.localStorage.getItem("thingz_free_crea_codeAce");
                // on vérifie si le code récupéré existe ou pas
                if (codeAce === null || !codeAce) {
                    // dispatch(synchroHybridCode())
                    // dispatch(synchroCodeAce())
                    return;
                }
            } catch (e) {
                dispatch({ type: freeCreationConstants.LOAD_CODE_FAILED });
            }
        }
        return dispatch({ type: freeCreationConstants.LOAD_CODE, codeAce });
    };
}

function loadCodePython() {
    let codeAce = null;
    return (dispatch, getState) => {
        //utilisateur logué => on cherche le code sauvé dans le state
        if (getState().session.authenticated && getState().session.checked) {
            //Si le code n'existe pas dans le state on le fait correspondre avec le code généré par blockly
            if (getState().freeCreation.codePy == null || !getState().freeCreation.codePy) {
                dispatch(synchroHybridCode());
                dispatch({ type: freeCreationConstants.SYNCHRO_CODE_ACE });

                return;
            }
            codeAce = getState().freeCreation.codePy;

            // utilisateur non loggué => on cherche dans le local storage
        } else {
            try {
                codeAce = window.localStorage.getItem("thingz_free_crea_codeAcePy");
                //on vérifie si le code récupéré existe ou pas
                if (codeAce === null || !codeAce) {
                    // dispatch(synchroHybridCode())
                    // dispatch(synchroCodeAce())
                    return;
                }
            } catch (e) {
                dispatch({ type: freeCreationConstants.LOAD_CODE_FAILED });
            }
        }
        return dispatch({ type: freeCreationConstants.LOAD_CODE_PYTHON, codeAce });
    };
}

function synchroCodeAce() {
    return { type: freeCreationConstants.SYNCHRO_CODE_ACE };
}

function synchroHybridCode() {
    return (dispatch, getState) => {
        let state = getState();
        let code;

        try {
            if (state.freeCreation.freeCreationEditorState === "BlocklyAndAcePython" || state.freeCreation.freeCreationEditorState === "AcePython") {
                code = window.Blockly.Python.workspaceToCode(state.blockly.mainWorkspace);
            } else {
                code = window.Blockly.Arduino.workspaceToCode(state.blockly.mainWorkspace);
            }
            dispatch(saveBlocklyXml(state.freeCreation.xml, code));
        } catch (error) {
            console.log("Erreur blockly");
            code = "#Les briques wifi et bluetooth ne sont pas encore\n#configurées pour le mode Python... patience !\ndef setup():\n\tpass\n\ndef loop():\n\tpass";

            dispatch(saveBlocklyXml(state.freeCreation.xml, code));

            // this.props.dispatch(modalActions.addModalMessageMid({
            // type: modalConstants.MODAL_MESSAGE_TYPE.COMBINATED_ERROR}
            // 	)
            // )
        }
        // return
    };
}

// Gère l'ouverture de la Modal "OUVRIR" les applications
function openModal() {
    return (dispatch) => {
        dispatch({ type: freeCreationConstants.OPEN_MODAL });
    };
}

function closeModal() {
    return (dispatch) => {
        dispatch({ type: freeCreationConstants.CLOSE_MODAL });
    };
}

// Gère l'ouverture de la Modal "SYNCHRO" de ACE
function openSyncModal() {
    return (dispatch) => {
        dispatch({ type: freeCreationConstants.OPEN_SYNC_MODAL });
    };
}

function closeSyncModal() {
    return (dispatch) => {
        dispatch({ type: freeCreationConstants.CLOSE_SYNC_MODAL });
    };
}

function firstLoad() {
    return (dispatch) => dispatch({ type: freeCreationConstants.FIRST_LOAD });
}

function titleOnChange(nom) {
    return (dispatch) => {
        dispatch({ type: freeCreationConstants.TITLE_CHANGE, nom });
    };
}

function remixApp(appId) {
    return (dispatch, getState) => {
        let appToRemix = getState().freeCreation;
        if (appId !== appToRemix.id) return;
        if (getState().session.authenticated && getState().session.checked) {
            dispatch({ type: freeCreationConstants.SAVE_NEW_APP_STARTED });
            return api.AppApi.clone(appToRemix)
                .then((resp) => {
                    dispatch({ type: freeCreationConstants.SAVE_NEW_APP_SUCCEEDED, payload: resp.data });
                    dispatch(freeCreationActions.loadBlocklyXml());
                    dispatch(appsActions.getApps());
                })
                .catch((err) => {
                    console.log(err);
                    dispatch({ type: freeCreationConstants.SAVE_NEW_APP_FAILED, payload: err });
                });
        } else {
            //not logged
            dispatch({ type: freeCreationConstants.SELECT_APP_STARTED });
            return api.AppApi.getOne(appId)
                .then((resp) => {
                    dispatch({ type: freeCreationConstants.INVALIDATE_FREE_CREA_STATE });
                    let app = resp.data;
                    app.readOnly = false;
                    dispatch({
                        type: freeCreationConstants.SELECT_APP_SUCCEEDED,
                        payload: app,
                    });
                    dispatch(freeCreationActions.saveCurrentApp());
                    dispatch(freeCreationActions.loadBlocklyXml());
                })
                .catch((err) => {
                    dispatch({
                        type: freeCreationConstants.SELECT_APP_FAILED,
                        payload: err,
                    });
                });
        }
    };
}
