import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import UserStatus from '../../containers/UserStatus';
import {ConnectionStatus} from '../../containers/ConnectionStatus';

import {
  Container,
  Image,
  Menu,
  Flag,
  Button
} from 'semantic-ui-react'

import "./HeaderFreeCreation.css"

const HeaderFreeCreation = props => {
  let langUrl = new URL(window.location.href);
  if(window.LANG === "en"){
    langUrl.searchParams.set('lang', 'fr');
  }else{
    langUrl.searchParams.set('lang', 'en');
  }
  return (
    <Menu borderless fluid id="HeaderFreeCreationMenu">
      <Container fluid>
        <Menu.Item className='logo'>
          <Link to='/'>
            <Image size='small' spaced='left' src='../../img/thingz_logo.png'/>
          </Link>
        </Menu.Item>
        <Menu.Menu position='right'>
          <Menu.Item>
            <ConnectionStatus notification={props.notification} user={props.user} galaxia={props.galaxia}/>
          </Menu.Item>
          <Menu.Item fitted>
              <UserStatus/>
          </Menu.Item>
          <Menu.Item >
            {window.LANG === "en" &&
              <a style={{marginRight:"10px"}} href={langUrl.toString()}><Image size="mini" src="../../img/fr.png"/></a>
            }
            {window.LANG == "fr" &&
              <a style={{marginRight:"10px"}} href={langUrl.toString()}><Image size="mini" src="../../img/gb.png"/></a>
            }
          </Menu.Item>
        </Menu.Menu>
      </Container>
    </Menu>
  );
}

export {HeaderFreeCreation};
