import {combineReducers} from 'redux'
import {coursePage} from './course_page_reducer'
import {guidedTour} from './guided_tour_reducer'
import {modal} from "./modal_reducer";
import {extension} from './extension_reducer'
import {blockly} from './blockly_reducer'
import {sessionReducer} from 'redux-react-session';
import {login} from './login_reducer'
import {register} from './register_reducer'
import {guestAccount} from './guestAccount_reducer'
import {forgotPassword} from './forgot_password_reducer'
import {resetPassword} from './reset_password_reducer'
import {serialMonitor} from './serial_monitor_reducer';
import {editor} from './editor_reducer';
import {freeCreation} from './free_creation_reducer'
import {galaxiaSpace} from './galaxia_space_reducer';
import {usersBricks} from './usersBricks_reducer';
import {socketIo} from './socketIo_reducer';
import {pluggedBricks} from './plugged_bricks';
import {notification} from './notification_reducer';
import {simulator} from  './simulator_reducer';

import {apps} from './model/apps_reducer'
import {courses} from './model/courses_reducer';
import {progressions} from './model/progressions_reducer';
import {steps} from './model/steps_reducer';
import {clues} from './model/clues_reducer';
import {finishedCourses} from './model/finishedCourses_reducer';
import {bricks} from './model/bricks_reducer';
import {structures} from './model/structures_reducer';
import {users} from './model/users_reducer';
import {spreadsheets} from './model/spreadsheets_reducer';
import {groups} from './model/groups_reducer'
import {selectedCourses} from './model/selectedCourses_reducer';


let model = combineReducers({
    apps,
    bricks,
    courses,
    progressions,
    steps,
    clues,
    finishedCourses,
    structures,
    users,
    spreadsheets,
    groups,
    selectedCourses
})

export default combineReducers({
    // courses,
    model,
    usersBricks,
    freeCreation,
    galaxiaSpace,
    coursePage,
    guidedTour,
    modal,
    session: sessionReducer,
    extension,
    blockly,
    login,
    register,
    guestAccount,
    forgotPassword,
    resetPassword,
    serialMonitor,
    editor,
    socketIo,
    pluggedBricks,
    notification,
    simulator
})






