import React from "react";
import { Online, Offline, Detector } from "react-detect-offline";
import runtimeEnv from "@mars/heroku-js-runtime-env";

import { InputSaveApp } from "./InputSaveApp";
import { HeaderFreeCreationControlsShare } from "./HeaderFreeCreationControlsShare";
import { HeaderFreeCreationControlsNewAppButton } from "../HeaderFreeCreationControlsNewAppButton";
// import { HeaderFreeCreationControlsOpenAppModal } from '../HeaderFreeCreationControlsOpenAppModal'
import { HeaderFreeCreationControlsOpenAppModalWithRouter } from "../HeaderFreeCreationControlsOpenAppModal";
import { SerialMonitorButton } from "../../containers/SerialMonitorButton";
import { Link } from "react-router-dom";
import { SimulatorButton } from "../../containers/Simulator";

import { Button, Card, Container, Divider, Dropdown, Grid, Header, HeaderSubheader, Icon, Modal, Popup } from "semantic-ui-react";

import { withRouter } from "react-router-dom";

import "./HeaderFreeCreationControls.css";
import { ImportExportAppComponent } from "../ImportExportAppComponent/ImportExportAppComponent";

const HeaderFreeCreationControlsRaw = (props) => {
    function renderCompileButtonState(online) {
        let txt = window.LANG === "en" ? "Run my program" : "Téléverser";
        let txt_extended = window.LANG === "en" ? "Send program to board" : "Envoyer le programme sur la carte";
        if (!online) {
            return (
                <Popup position="bottom right" trigger={<Button icon labelPosition='right' size="medium" floated="right" className="HeaderFreeCreationControlsButton" disabled color="orange" onClick={props.onCompileClick}><Icon name='rocket'/>{txt}</Button>}><Popup.Header>{txt}</Popup.Header>
                    <Popup.Content>
                        {txt_extended}
                    </Popup.Content>
                </Popup>
            );
        } else if (props.compilationLoading === true) {
            return (
                <Popup position="bottom right" trigger={<Button icon labelPosition='right' size="medium" floated="right" className="HeaderFreeCreationControlsButton" loading disabled color="orange" onClick={props.onCompileClick}><Icon name='rocket'/>{txt}</Button>}><Popup.Header>{txt}</Popup.Header>
                    <Popup.Content>
                        {txt_extended}
                    </Popup.Content>
                </Popup>
            );
        } else {
            return (
                <Popup position="bottom right" trigger={<Button icon labelPosition='right' size="medium" floated="right" className="HeaderFreeCreationControlsButton" color="orange" onClick={props.onCompileClick}><Icon name='rocket'/>{txt}</Button>}><Popup.Header>{txt}</Popup.Header>
                    <Popup.Content>
                        {txt_extended}
                    </Popup.Content>
                </Popup>
            );
        }
    }

    function renderSerialMonitorButton() {
        return <SerialMonitorButton floated="right" />;
    }

    function renderSimulatorButton() {
        return <SimulatorButton codeSource={props.editorState} floated="right" />;
    }

    //Trois fonctions qui gèrent les différents modes d'affichage
    function onClickBlockly() {
        if (props.fromGalaxiaSpace) {
            return props.changeStateGalaxiaSpaceEditor(props.editorState, "Blockly", true); // (currentState, targetState, readOnly)
        }
        if (props.editorState.endsWith("Python")) {
            return props.changeStateFreeCreationEditor(props.editorState, "BlocklyPython", true); //Dirty hack to remember we were in Python
        }
        return props.changeStateFreeCreationEditor(props.editorState, "Blockly", true);
    }

    function onClickBlocklyAndAce() {
        if (props.fromGalaxiaSpace) {
            return props.changeStateGalaxiaSpaceEditor(props.editorState, "BlocklyAndAcePython", true);
        }
        if (props.editorState.endsWith("Python")) {
            return props.changeStateFreeCreationEditor(props.editorState, "BlocklyAndAcePython", true);
        }
        return props.changeStateFreeCreationEditor(props.editorState, "BlocklyAndAceCpp", true);
    }

    function onClickAce() {
        if (props.fromGalaxiaSpace) {
            return props.changeStateGalaxiaSpaceEditor(props.editorState, "AcePython", true);
        }
        if (props.editorState.endsWith("Python")) {
            return props.changeStateFreeCreationEditor(props.editorState, "AcePython", false);
        }
        return props.changeStateFreeCreationEditor(props.editorState, "AceCpp", false);
    }

    function onRemixClick() {
        props.onRemixClick(props.freeCreation._id);
    }

    function onLangChange(event, index) {
        if (props.fromGalaxiaSpace) {
            return;
        }
        let val = index.value;

        if (val === "AcePy") {
            if (props.editorState.startsWith("Ace")) {
                val = "AcePython";
            } else if (props.editorState.startsWith("BlocklyAndAce")) {
                val = "BlocklyAndAcePython";
            }
        } else if (val === "AceCpp") {
            if (props.editorState.startsWith("Ace")) {
                val = "AceCpp";
            } else if (props.editorState.startsWith("BlocklyAndAce")) {
                val = "BlocklyAndAceCpp";
            }
        }
        return props.changeStateFreeCreationEditor(props.editorState, val, false);
    }

    function synchroCodeAce() {
        return props.synchroCodeAce;
    }

    function renderSynchroButton() {
        if (props.editorState.startsWith("Ace")) {
            return (
                <Modal
                    onClose={() => props.closeSyncModal()}
                    open={props.syncModalIsOpen}
                    trigger={props.triggerSyncButton()}
                    header="Attention !"
                    content={
                        "Ton code va être remplacé par la représentation en " +
                        (props.editorState.endsWith("Python") ? "Python" : "C++") +
                        " de ta construction de blocs actuelle. Es-tu sûr de vouloir faire une synchronisation ?"
                    }
                    actions={["Annuler", { key: "done", content: "Synchroniser", positive: true, onClick: synchroCodeAce() }]}
                />
            );
        }
    }

    function renderCodeLangSelection() {
        let value;
        let options = [
            { key: "cpp", text: "C++", value: "AceCpp" },
            { key: "py", text: "Python", value: "AcePy" },
        ];

        if (props.editorState === "AceCpp" || props.editorState === "Ace" || props.editorState === "BlocklyAndAceCpp" || props.editorState === "BlocklyAndAce") value = options[0].value;
        else value = options[1].value;

        //ajout du dropdown en mode hybride B&A
        if (props.editorState.startsWith("Ace") || props.editorState.startsWith("BlocklyAndAce")) {
            return <Dropdown selection compact options={options} value={value} style={{ marginLeft: "10px" }} onChange={onLangChange} />;
        }
    }

    function renderRemix(isOnline) {
        // if(props.freeCreation.readOnly){
        return (
            <Popup
                basic
                trigger={
                    <Button size="small" disabled={!isOnline} icon color="blue" onClick={onRemixClick}>
                        <Icon name="copy" />
                    </Button>
                }>
                <Popup.Header>Remixer</Popup.Header>
                <p>Créer une nouvelle création à partir de celle-ci</p>
            </Popup>
        );
        // }

        return null;
    }

    function renderImportExport() {
        return (
            <ImportExportAppComponent xml={props.xml} codeHybride={props.codeHybride} codeAce={props.codeAce} onImport={props.onImportApp} context={(props.editorState.includes("blockly") || props.editorState.includes("Blockly")) ? "blockly" : "python" }/>
        );

    }

    function renderShare() {
        if (props.freeCreation.readOnly) return null;
        return <HeaderFreeCreationControlsShare app={props.freeCreation} />;
    }

    function renderWorkspaceButtonGroup() {
        return (
            <React.Fragment>
                <Button.Group className="workspaceButtonGroup">
                    <Popup
                        basic
                        position="top center"
                        trigger={
                            <Button size="small" icon color="yellow" onClick={onClickBlockly} active={props.editorState === "Blockly" || props.editorState === "BlocklyPython"  ? true : false}>
                                <Icon name="puzzle piece" />
                            </Button>
                        }>
                        Blockly
                    </Popup>
                    <Popup
                        basic
                        position="top center"
                        trigger={
                            <Button size="small" icon color="yellow" onClick={onClickBlocklyAndAce} active={props.editorState.startsWith("BlocklyAndAce") ? true : false}>
                                <Icon name="arrows alternate horizontal" />
                            </Button>
                        }>
                        Hybride
                    </Popup>
                    <Popup
                        basic
                        position="top center"
                        trigger={
                            <Button size="small" icon color="yellow" onClick={onClickAce} active={props.editorState.startsWith("Ace") ? true : false}>
                                <Icon name="code" />
                            </Button>
                        }>
                        Texte
                    </Popup>
                    {renderSynchroButton()}
                </Button.Group>
                {!props.fromGalaxiaSpace && renderCodeLangSelection()}
                {/* {renderCodeLangSelection()} */}
                {/* Lecture seule  */}
                {/* {renderReadOnlyButton()} */}
            </React.Fragment>
        );
    }

    function renderFreeCreationLogged() {
        return (
            // <Container fluid>
            <Grid columns="equal" verticalAlign="middle">
                <Grid.Row className="loggedFreeCreationControlsRow">
                    <Detector
                        polling={{ url: runtimeEnv().REACT_APP_CONNECTION_DETECTION_POOLING }}
                        render={({ online }) => (
                            <Grid.Column>
                                <Button onClick={() => props.history.goBack()} basic icon="arrow left" style={{ marginLeft: "20px", marginRight: "10px" }} />{" "}
                                <Button.Group>
                                    <HeaderFreeCreationControlsNewAppButton online={online} />
                                    {/* <Divider vertical/> */}
                                    <HeaderFreeCreationControlsOpenAppModalWithRouter online={online} />
                                    {renderRemix(online)}
                                    {renderShare()}
                                </Button.Group>{" "}
                                {renderWorkspaceButtonGroup()}
                                {/* </div> */}
                            </Grid.Column>
                        )}
                    />

                    <Grid.Column width={5}>
                        {/* ? */}
                        <InputSaveApp autoSave={props.autoSave} state={props.appSavingState} freeCreation={props.freeCreation} />
                        {/* ? */}
                    </Grid.Column>
                    <Grid.Column>
                        <Detector polling={{ url: runtimeEnv().REACT_APP_CONNECTION_DETECTION_POOLING }} render={({ online }) => renderCompileButtonState(online)} />
                        {renderSimulatorButton()}
                        {renderSerialMonitorButton()}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            // </Container>
        );
    }

    function renderFreeCreationNotLogged() {
        let readOnly = props.freeCreation.readOnly;
        return (
            <Grid columns="equal" verticalAlign="middle">
                <Grid.Column>
                    <Button onClick={() => props.history.goBack()} basic icon="arrow left" style={{ marginLeft: "20px", marginRight: "10px" }} />{" "}
                    {(!props.fromGalaxiaSpace && (<Button.Group>renderRemix(true)</Button.Group>))||renderImportExport()} {renderWorkspaceButtonGroup()}
                </Grid.Column>
                <Grid.Column width={3}>
                    <Header color="orange" className="centered">
                        {!props.fromGalaxiaSpace ? "Création Libre" : "Espace Galaxia"}
                        {readOnly ? (
                            <Header.Subheader>
                                Tu consultes l'application <strong>{props.freeCreation.nom}</strong>
                                <br></br>
                                <Link to="/creation">Pour créer ta propre application, c'est ici !</Link>
                            </Header.Subheader>
                        ) : (
                            <Header.Subheader>Laisse libre cours à ton imagination !</Header.Subheader>
                        )}
                    </Header>
                </Grid.Column>
                <Grid.Column>
                    <Detector polling={{ url: runtimeEnv().REACT_APP_CONNECTION_DETECTION_POOLING }} render={({ online }) => renderCompileButtonState(online)} />
                    {!props.fromGalaxiaSpace && renderSimulatorButton()}
                    {!props.fromGalaxiaSpace && renderSerialMonitorButton()}
                    {/* {renderSimulatorButton()} */}
                    {/* {renderSerialMonitorButton()} */}
                </Grid.Column>
            </Grid>
        );
    }
    return (
        <Card fluid id="HeaderFreeCreationControlsCard">
            <Card.Header>
                {(props.user && props.user.confirmed && !props.fromGalaxiaSpace && renderFreeCreationLogged()) || renderFreeCreationNotLogged()}
                {/* {(props.user && props.user.confirmed && renderFreeCreationLogged()) || renderFreeCreationNotLogged()} */}
            </Card.Header>
        </Card>
    );
};

const HeaderFreeCreationControls = withRouter(HeaderFreeCreationControlsRaw);
export { HeaderFreeCreationControls };
