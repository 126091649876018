import axios from 'axios'
import runtimeEnv from '@mars/heroku-js-runtime-env';
import history from '../history'

const BASE_USER_URL = runtimeEnv().REACT_APP_API_BASE_USER_URL;
const API_REGISTER_URL = '/local/register';
const API_LOGIN_URL = '/local';
const API_CONFIRM_URL = '/email-confirmation?confirmation=';

export function registerUser(user) {
    return axios.post(BASE_USER_URL + API_REGISTER_URL + '?locale=fr_FR', user)
}

export function registerGuest(datas) {
    return axios.post(BASE_USER_URL + API_REGISTER_URL + '/guest' + '?locale=fr_FR', datas)
}

export function loginUser(user) {
    
    return axios.post(BASE_USER_URL + API_LOGIN_URL + '?locale=fr_FR', user)
}

export function confirmUser(userToken) {
    // axios.get(BASE_USER_URL + API_CONFIRM_URL + userToken).then(resp => {console.log(resp)})
	return axios.get(BASE_USER_URL + API_CONFIRM_URL + userToken)
}
