import React, {Component} from 'react';
import { Link } from 'react-router-dom'

import ReactTimeAgo from 'react-time-ago'

import {Item, Divider, Tab, Modal, Segment, Message, Input, Icon, Image, Card, Button, Container, Header, List} from 'semantic-ui-react'

import {ProfilePicture} from '../ProfilePicture';
import CourseSummaryComponent from '../CourseSummaryComponent/CourseSummaryComponent'
import AppSummaryComponent from '../AppSummaryComponent/AppSummaryComponent'

import {StudentStats} from '../../containers/StudentStats'

class StudentProfilComponent extends Component {

	constructor(props){
		super(props)
		this.state = {
			link_is_copied: false,
			modal:{
				open:false,
				text:"",
				actionDescription:"",
				learnerId:""
			}
		}
	}

	capitalize(str){
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	deleteStudent = (event, data) => {
		this.props.onDeleteClick(this.state.modal.learnerId);
		this.closeModal()
	}

  openModal = (event, data) => {
  	let text;
  	let actionDescription;
  	const learner = this.props.user;
  	if(learner.role.type === "guest"){
  		text = "Supprimer";
			actionDescription = `Vous êtes sur le point de retirer ${this.capitalize(learner.username)} de ${this.capitalize(this.props.structure.name)}. Ce compte ne sera plus utilisable`
  	}else{
  		text = "Supprimer de la structure";
			actionDescription = `Vous êtes sur le point de retirer ${this.capitalize(learner.username)} de ${this.capitalize(this.props.structure.name)}. Ce compte ne fera plus partie de votre structure`
  	}
  	let modal = {
  		open:true,
  		text,
  		actionDescription,
  		learnerId: data.learnerid
  	}
  	this.setState({modal})
  }

  closeModal = () => {
  	const modal = {close: true};
  	this.setState({modal})
  }

	renderDeleteButton(learner){
		let text;
		if(learner.role.type === "guest"){
			text = "Supprimer le compte";
		}else{
			text = "Désassocier";
		}
		return (
			<Button size="medium"  learnerid={learner._id} basic color="red" floated="right" onClick={this.openModal}>{text}</Button>
		)
	}

	renderProfil = () => {
		return (
			<Tab.Pane>
				<Container>
					<div style={{margin:"5px", display: "block", textAlign:"center"}}>
						<ProfilePicture diameter={80} email={this.props.user.email} />
						<Header size="medium">{this.props.user.username}</Header>
					</div>
					<Divider />
					<div>
						<Header size="small">Statistiques de progression</Header>
						<Divider hidden />
						<StudentStats studentId={this.props.user._id}/>
					</div>
					<Divider />
					<div>
						<Header size="small">Structure</Header>
						{this.props.structure.name}
					</div>
					<Divider />
					<div>
						<Header size="small">Groupe</Header>
						{this.props.group ? this.props.group.name : "Aucun groupe"}
					</div>
					<Divider />
					<div>
						<Header size="small">Dernière activité</Header>
						{this.props.user.online && "En ligne"}
						{!this.props.user.online && this.props.user.lastSeen && <ReactTimeAgo date={new Date(this.props.user.lastSeen)} locale="fr"/>}
					</div>
					<Divider />
					<div>
						<Header size="small">Type de compte</Header>
						{this.props.user.role.type === 'guest' && "Compte élève"}
						{this.props.user.role.type !== 'guest' && "Compte utilisateur, invité dans votre structure"}
					</div>
				</Container>
			</Tab.Pane>
		)
	}

	renderCourses = () => {
		
		let courses = {}
		this.props.finishedCourses.forEach(f => {
			const course = this.props.courses[f.course]
			if(course && ! course.isActivity){
				if(!courses[f.course]){
					courses[f.course] = {finished: f, course: course, currentStep:course.stepsCount}
				}else{
					if(f.createdAt > courses[f.course].finished.createdAt){
						courses[f.course].finished = f
					}
				}
			}
		})

		this.props.progressions.forEach(p => {
			const step = this.props.steps[p.step];
			if(step){
				const course = this.props.courses[step.course]
				if(course && !course.isActivity){
					if(!courses[step.course]){
						courses[step.course] = {progression: p, course: course, currentStep: p.ended ? course.stepsCount : step.currentPositionInCourse-1}
					}else{
						if(!courses[step.course].progression){
							courses[step.course].progression = p
							courses[step.course].currentPositionInCourse = p.ended ? course.stepsCount : step.currentPositionInCourse-1
						}else if(courses[step.course].progression.createdAt < p.createdAt || courses[step.course].progression.updatedAt < p.updatedAt){
							courses[step.course].progression = p
							courses[step.course].currentPositionInCourse = p.ended ? course.stepsCount : step.currentPositionInCourse-1
						}
					}
				}
			}
		})

		Object.values(this.props.courses).forEach(course => {
			if(!courses[course._id] && !course.isActivity){
				courses[course._id] = {currentStep: 0, course:course, consultation:"disabled"}
			}
		})

		courses = Object.values(courses).map(c => {
			return (<CourseSummaryComponent key={c.course._id}  user={this.props.user} progression={c.progression} finished={c.finished} course={c.course} currentStep={c.currentStep}  consultation={c.consultation || true} showElapsed/>)
		})

		return (
			<Tab.Pane>
				<Container>
					<Segment>
						<Header>Suivre la progression</Header>
						<p>
							Suivez l'avancement de chaque élève en consultant leurs progressions dans les différents parcours proposés.
						</p>
					</Segment>
					<Header>Parcours</Header>
				  {(courses.length > 0 && 
				  	(<Card.Group>
				  		{courses}
				  	</Card.Group>))||(<Message header="Aucune progression" content={this.capitalize(this.props.user.username)+" n'a pas commencé de parcours"}/>)}
				</Container>
			</Tab.Pane>
		)
	}

	renderApplications = () => {
		let apps = [];
		for(let app of this.props.apps){
			apps.push((<AppSummaryComponent key={app._id} app={app} hideButton={false}/>))
		}
		return (
			<Tab.Pane>

			{(apps.length > 0 && (
				<Item.Group>
					{apps}
				</Item.Group>
			))||(<Message header="Aucune application" content={this.capitalize(this.props.user.username)+" n'a pas créé d'application"}/>)}
			</Tab.Pane>
		)
	}

	renderActivities = () => {
		let inProgress = [];
		let validate = [];

		let activities = {}
		for(let progression of this.props.progressions){
			if (!progression) {
				continue;
			}
			
			let step = this.props.steps[progression.step];
			if (step) {
				let course = this.props.courses[step.course];

				if (!course || !course.isActivity) {
					continue
				}

				let finish = false;
				for (let finishCourse of this.props.finishedCourses) {
					if (finishCourse.course === course._id) {
						let update = finishCourse.endedXml !== progression.xml ? true : false
						activities[course._id] = {course: course, user: this.props.user, progression: progression, finished:finishCourse, currentStep: course.stepsCount}
						validate.push((<CourseSummaryComponent key={course._id} user={this.props.user} course={course} progression={progression} finished={finishCourse} consultation={true} update={update} currentStep={course.stepsCount} showElapsed/>));
						finish = true;
					}
					continue;				
				}
				if (finish) {
					continue;
				}
				activities[course._id] = {course: course, user: this.props.user, progression: progression, currentStep: progression.ended ? course.stepsCount : step.currentPositionInCourse-1}
				inProgress.push((<CourseSummaryComponent key={course._id} user={this.props.user} course={course} progression={progression} consultation={true} update={true} currentStep={step.currentPositionInCourse-1} showElapsed/>))
			}
		}

		Object.values(this.props.courses).forEach(course => {
			if(!activities[course._id] && course.isActivity && this.props.selectedCourses.find(select => {return select.course === course._id})){
				activities[course._id] = {currentStep: 0, course:course, consultation:"disabled"}
			}
		})

		activities = Object.values(activities).map(a => {
			return (<CourseSummaryComponent key={a.course._id}  user={this.props.user} progression={a.progression} finished={a.finished} course={a.course} currentStep={a.currentStep}  consultation={a.consultation || true} showElapsed/>)
		})


		
		return (
			<Tab.Pane>
				<Container>
					<Segment>
						<Header>Suivre la progression</Header>
						<p>
							Suivez l'avancement de chaque élève en consultant leurs progressions dans les activités que vous avez créées.
						</p>
						<p>
							Pour chaque élève vous verrez les activités:
							<List bulleted>
								<List.Item>actuellement disponibles pour l'élève</List.Item>
								<List.Item>commencées par l'élève même si elles ne sont plus disponibles aujourd'hui</List.Item>
							</List>
						</p>
					</Segment>
					<Header>Activités</Header>
				  {(activities.length > 0 && 
				  	(<Card.Group>
				  		{activities}
				  	</Card.Group>))||(<Message header="Aucune activité en cours" content={this.capitalize(this.props.user.username)+" n'a pas commencé d'activités"}/>)}
				</Container>
			</Tab.Pane>
		)
	}

	renderStudent(){
		const panes = [
			{menuItem: "Profil", render: this.renderProfil},
			{menuItem: "Parcours", render: this.renderCourses},
			{menuItem: "Activités", render: this.renderActivities},
			{menuItem: "Créations Libres", render: this.renderApplications},
		]
		return (
			<div>
				<div style={{display: "inline-flex", flexDirection:"row", alignItems:"center", flexBasis: "auto"}}>
					
					<Button as={Link} to='/structure' basic  icon="arrow left" style={{marginRight:'10px'}}/>
					{this.props.user.online && !this.props.user.animate && <span style={{float: "left", verticalAlign:"middle"}} className={"StudentProfilOnlineDot StudentProfilOnlineDotAnimate"}></span>}
					<Header style={{margin:"10px 0 10px 0"}} size="large">{this.capitalize(this.props.user.username)}</Header>
					
				</div>
				{this.renderDeleteButton(this.props.user)}
				<Tab panes={panes}/>
			</div>
		)
	}

	
	render(){
		if(!this.props.user)
			return (
				<Message negative style={{display: "block"}}>
			    <Message.Header>Erreur</Message.Header>
			    <Message.Content>
			    	<p>Élève non trouvé</p>
			    	<Link to='/'>Retour à l'accueil</Link>
			    </Message.Content>
			  </Message>
			)
		return (
			<Container>
				{this.renderStudent()}
				<Modal onClose={this.closeModal} open={this.state.modal.open} dimmer="blurring" size='small'>
		    	<Header icon='trash' content="Confirmation de suppression" />
			    <Modal.Content>
			      <p>
			      	{this.state.modal.actionDescription}
			      </p>
			    </Modal.Content>
			    <Modal.Actions>
			      <Button basic color='grey' onClick={this.closeModal}>
			        <Icon name='remove' /> Annuler
			      </Button>
			      <Button color='red' onClick={this.deleteStudent}>
			        <Icon name='trash' /> {this.state.modal.text}
			      </Button>
			    </Modal.Actions>
			  </Modal>
			</Container>

		)
	}

}

export { StudentProfilComponent };