import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'
import * as sessionActions from '../actions/session_actions'
import {getActualUser} from "../reducers/user_reducer";
import LoginComponent from "../components/LoginComponent/LoginComponent";
import HeaderMain from "../components/HeaderMain/HeaderMain";


class Login extends Component {

    render() {
        document.title = "Connexion - Thingz"
        return (
            <div style={{flex: 1}}>
                <HeaderMain/>
                <LoginComponent user={this.props.user}
                                actions={this.props.actions}
                                isLoading={this.props.login.isFetching}
                                error={this.props.login.error}/>
            </div>
        );
    }
}

const mapDispatch = (dispatch) => {
    return {
        actions: bindActionCreators(sessionActions, dispatch)
    };
};

const mapStateToProps = (state) => {
    return {
        user: getActualUser(state),
        login: state.login
    }
}

Login.propTypes = {};

export default connect(mapStateToProps, mapDispatch)(Login);
