import { sessionConstants } from '../constants';

const initialState = {
  isFetching: false,
  error: null,
  success:null,
}

export function forgotPassword(state = initialState, action) {
  switch (action.type) {
    case sessionConstants.FORGOT_PASSWORD:
      return {
        ...state,
        isFetching: true
      }
    case sessionConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        success:true
      }
    case sessionConstants.FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.error.response,
        success:null
      }
    case sessionConstants.FORGOT_PASSWORD_RESET_STATE:
      return {
        ...state,
        isFetching: false,
        error: null,
        success:null
      }
    default:
      return state;
  }
}