import {simulatorConstants} from "../constants";

export const simulatorActions = {
    open,
    close,
    updateCode
};

function open(){
	return {type: simulatorConstants.OPEN}
}

function close(){
	return {type: simulatorConstants.CLOSE}
}

function updateCode(code){
    return {type: simulatorConstants.UPDATE_CODE, code}
}