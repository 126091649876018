import React, {Component} from 'react';

import ChannelComponent from "../components/ChannelComponent/ChannelComponent";
import {connect} from 'react-redux';
import {modalActions} from "../actions";
import {progressionsActions} from "../actions/model"

class Modal extends Component {
    constructor(props){
        super(props);
        this.timeoutMsg = null;
        this.timeoutIndices = null;
    }
    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {
        // if (prevProps && prevProps.avancement._id !== this.props.avancement._id) {
        //     this.props.dispatch(initContextMessage());
        //     this.timeoutIndices = window.setTimeout(() => {
        //         this.props.dispatch(initIndicesMessages());
        //     }, 2000)
        //     return
        // }

        // if (prevProps && prevProps.avancement.step !== this.props.avancement.step) {
        //     this.timeoutMsg = window.setTimeout(() => {
        //         this.props.dispatch(initContextMessage());
        //     }, 1200);
        //     this.timeoutIndices = window.setTimeout(() => {
        //         this.props.dispatch(initIndicesMessages())
        //     }, 1200)
        // }
    }


    componentWillUnmount() {
        this.props.dispatch(modalActions.destroyAllModalMessage())
    }


    addIndice = () => {
        this.props.dispatch(progressionsActions.nextClue(this.props.progression._id));
    }


    render() {
        let isActivity = false;
        const step = this.props.steps.entities[this.props.progression.step]
        if(step){
            const course = this.props.courses.entities[step.course];
            if(course){
                isActivity = course.isActivity
            }
        }
        return (
            <ChannelComponent messages={this.props.messages}
                              addIndice={this.addIndice}
                              currentStepId={this.props.progression.step}
                              isActivity={isActivity}/>
        );
        return null;
    }
}

Modal.propTypes = {};

function mapStateToProps(state) {
    return {
        messages: state.modal.messages,
        steps: state.model.steps,
        courses: state.model.courses
    }
}

export default connect(mapStateToProps)(Modal);
