import {modalConstants} from "../constants";

const initialModalState = {
    messages: [],
    isOffline: false,
}

export const modal = (state = initialModalState, action) => {

    switch (action.type) {

        case modalConstants.ADD_MODAL_MESSAGE: {
            const messages = [...state.messages];
            messages.push(action.payload);
            return {
                ...state,
                messages: messages
            }
        }

        case modalConstants.DESTROY_ALL_MODAL_MESSAGE : {
            return {
                ...state,
                messages: []
            }
        }

        case modalConstants.DESTROY_TEMP_MODAL_MESSAGE : {
            const messages = [...state.messages];
            const updatedMessages = [];
            messages.forEach(message => {
                if (message.temp === false) {
                    updatedMessages.push(message)
                }
            });
            return {
                ...state,
                messages: updatedMessages
            }
        }

        case modalConstants.DESTROY_CLUES_MESSAGE: {
            const messages = [...state.messages];
            const updatedMessages = [];
            messages.forEach(message => {
                if(message.type != modalConstants.MODAL_MESSAGE_TYPE.CLUE){
                    updatedMessages.push(message)
                }
            })
            return {
                ...state,
                messages: updatedMessages
            }
        }

        case modalConstants.MODAL_MESSAGE_TYPE.OFFLINE_ERROR: {
            return {
                ...state,
                isOffline: true
            }
        }

        case modalConstants.MODAL_MESSAGE_TYPE.OFFLINE_RECOVERY: {
            return {
                ...state,
                isOffline: false
            }
        }

        default: {
            return state
        }
    }
}


/*
export const getStep = state => {
    const avancement = getActualAvancement(state);
    const course = getSelectedCourse(state);

    return course.steps[avancement.step];
}
*/




