import React, {Component} from 'react';
import { Link } from 'react-router-dom'
import ReactTimeAgo from 'react-time-ago'

import {ProfilePicture} from '../ProfilePicture';

import {
	Card,
	Button, 
	Popup,
    Dropdown,
    Progress,
    List,
    Icon
} from 'semantic-ui-react'
import { StudentStats } from '../../containers/StudentStats';


export default class StructureStudentCard extends Component {

    constructor(props){
        super(props)

        this.state = {
            groupPopup:{
                content:"Transférer l'élève vers un autre groupe",
                isOpen: undefined,
                on:"hover",
                position:undefined,
                style:undefined,
                hoverable:undefined,
                hideOnScroll:true
            }
        }
    }

    toggleGroupPopup = () => {
        let groups = this.props.groups.filter(group => {
            if(this.props.learner.group)
                return group._id != this.props.learner.group
            return group._id != "nogroup"
        })
        .sort((a,b)=>{
            if(a._id === "nogroup")
                return b
            if(b._id === "nogroup")
                return a
            return a.name.localeCompare(b.name)
        })
        .map(group => ({icon:"group", content:group.name, key:group._id, value:group._id, onClick:this.onTransferStudent}))
        this.setState(prev => {
            return {
                ...prev,
                groupPopup:{
                    content:(<List selection verticalAlign='middle' items={groups}></List>),
                    on:"click",
                    position:"bottom center",
                    style:{maxHeight: "200px", overflowY:"auto",padding:"10px"},
                    hoverable:true,
                    hideOnScroll:false
                }
            }
        })
    }

    closeGroupPopup = () => {
        this.setState(prev =>{
            return  {
                ...prev,
                groupPopup:{
                    content:"Transférer l'élève vers un autre groupe",
                    isOpen:undefined,
                    on:"hover",
                    position:undefined,
                    style:undefined,
                    hoverable:undefined,
                    hideOnScroll:undefined
                }
            }
        })
    }

    onTransferStudent = (e, data) => {
        let group = data.value === "nogroup" ? null : data.value
        this.props.onTransferStudent(this.props.learner._id, group)
    }

    onDeleteStudent = () =>{
        this.props.onDeleteStudent(this.props.learner._id)
    }

    renderDeleteButton(){
		let text;
		if(this.props.learner.role.type === "guest"){
			text = "Supprimer le compte";
		}else{
			text = "Désassocier";
		}
		return (
			<Button.Group floated="right" basic size="tiny">
				{this.props.groups && this.props.groups.length > 0 && 
                <Popup hoverable={this.state.groupPopup.hoverable}  
                hideOnScroll={this.state.groupPopup.hideOnScroll} 
                content={this.state.groupPopup.content} 
                on={this.state.groupPopup.on} 
                position={this.state.groupPopup.position} 
                onClose={this.closeGroupPopup} 
                onUnmount={this.closeGroupPopup} 
                style={this.state.groupPopup.style} 
                trigger={(
                    <Button basic icon="exchange" onClick={this.toggleGroupPopup}>
                    </Button>
                )}/>}
                <Popup content='Supprimer le compte' trigger={(<Button basic color="red" onClick={this.onDeleteStudent} icon="trash"></Button>)}/>
			</Button.Group>
		)
	}

    render(){
        return (
            <Card key={this.props.learner._id}>
                <Card.Content>
                    <div style={{textAlign:"right", float:"right"}}>
                        <ProfilePicture diameter={50} email={this.props.learner.email}/>
                    </div>
                    <Card.Header>{this.props.learner.online && !this.props.learner.animate && <span className={"StudentProfilOnlineDot StudentProfilOnlineDotAnimate"}></span>}<Link style={{color: "#FE9A76", textDecoration: "underline"}} to={"/student/"+this.props.learner._id}>{this.props.learner.username}</Link></Card.Header>
                    <Card.Meta>{!this.props.learner.online && this.props.learner.lastSeen &&
                        <Popup position="top center" content="Dernière connexion" trigger={
                            <span><Icon name="eye"/> <ReactTimeAgo date={new Date(this.props.learner.lastSeen)} locale="fr"/></span>}
                        />
                        }
                    </Card.Meta>
                    <Card.Meta>
                        <Popup position="top center" trigger={
                            <span><Icon name="stopwatch"/> <StudentStats studentId={this.props.learner._id} mode="elapsed" fetchStudent={false}/></span>
                        }
                        content="Temps passé"/>
                    </Card.Meta>
                    <Card.Description>
                        <StudentStats studentId={this.props.learner._id} mode="summary" fetchStudent={false}/>
                    </Card.Description>
                </Card.Content>
                <Card.Content extra>
                        {this.renderDeleteButton()}
                </Card.Content>
            </Card>
        )
    }
}

