import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {appsActions, coursesActions, progressionsActions, structuresActions, usersActions} from '../../actions/model';
// import {structuresActions} from '../../actions/model'
import {getActualUser} from '../../reducers/user_reducer';
import md5 from 'js-md5'

import HeaderMain from '../../components/HeaderMain/HeaderMain';
import {Inventaire} from '../../components/InventaireComponent';
import AppSummaryComponent from '../../components/AppSummaryComponent/AppSummaryComponent';
import CoursesComponent from '../../components/CoursesComponent/CoursesComponent';
import CourseSummaryComponent from '../../components/CourseSummaryComponent/CourseSummaryComponent';

import {AppsTabWithRouter} from './Tabs/AppsTab'
import {CoursesTabWithRouter} from './Tabs/CoursesTab'
import {ActivitiesTabWithRouter} from './Tabs/ActivitiesTab';
import {ProfilePicture} from '../../components/ProfilePicture';


import {
	Container,
	Image,
	Segment,
	Header,
	Grid,
    Label,
    Icon,
    Modal,
	List,
	Item,
	Placeholder,
	Tab,
	Menu,
	Message,
	Divider,
	Button,
	Input,
	Form

} from 'semantic-ui-react';
import  './Profil.css'
import { PageWithSideMenu } from '../PageWithSideMenu';

// import profil from '../../img/miss.svg'

class Profil extends Component {

  constructor(props){
	super(props)
	this.state = {
		activeItem: 0,
		spreadsheetUrl: ''
	}
      this.listApps = this.listApps.bind(this)
	  this.listCoursesStarted = this.listCoursesStarted.bind(this)
	  this.listActivitiesStarted = this.listActivitiesStarted.bind(this)
	  this.handleSpreadsheetUrlSubmit = this.handleSpreadsheetUrlSubmit.bind(this)
	  this.handleSpreadsheetUrlFormChange = this.handleSpreadsheetUrlFormChange.bind(this)
	  this.displaySpreadsheetUrlForm = this.displaySpreadsheetUrlForm.bind(this)
	  this.handleSpreadsheetUrlCancel = this.handleSpreadsheetUrlCancel.bind(this)
  }

	componentDidMount(prevProps){
		document.title = "Profil - Thingz"
		this.fetchAll()
	}
	
  componentDidUpdate(prevProps){
		if(!prevProps.user && this.props.user){
	    	this.fetchAll()
		}
	}

	capitalize(str){
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

  fetchAll(){
      this.props.dispatch(coursesActions.fetchForUser())
      this.props.dispatch(progressionsActions.fetchAll())
			this.props.dispatch(appsActions.getApps())
			this.props.dispatch(structuresActions.fetchStructures())
			this.props.dispatch(usersActions.me())
  }

  redirectToCreation() {
    this.props.history.push(`/creation`)
	}
	
	redirectToParcours() {
		this.props.history.push('/')
	}

	filterApps(e){
		this.props.dispatch(appsActions.filterApps(e.target.value))
	}

	handleItemClick(e) {
		this.setState({ activeItem: e.target.id })
	}

	spreadsheetUrlForm(isDisplay = true) {
		return (
			<Form id='spreadsheetUrlForm' className='spreadsheetUrlForm' style={isDisplay ? {display: 'block'} : {display: 'none'}}>
				<Form.Input
					type='url'
					placeholder='Url' 
					value={this.state.spreadsheetUrl} 
					onChange={this.handleSpreadsheetUrlFormChange} 
				/>
				{/* <Button onClick={(e) => this.handleSpreadsheetUrlSubmit(e, this.props.users.entities[this.props.user._id])}>Valider</Button> */}
				<Button color='green' onClick={(e) => this.handleSpreadsheetUrlSubmit(e, this.props.users.entities[this.props.user._id])}>Valider</Button>
				<Button color='red' onClick={(e) => this.handleSpreadsheetUrlCancel()}>Annuler</Button>
			</Form>
		)
	}

	displaySpreadsheetUrlForm() {
		document.querySelector('#spreadsheetUrl').style.display = 'none';
		document.querySelector('#spreadsheetUrlForm').style.display = 'block';
	}

	handleSpreadsheetUrlFormChange(e) {
		this.setState({spreadsheetUrl : e.target.value});
	}

	handleSpreadsheetUrlSubmit(e, user) {
		e.preventDefault();
		this.props.dispatch(usersActions.updateSpreadsheetUrl(user._id, {spreadsheetUrl: this.state.spreadsheetUrl}));
		this.setState({spreadsheetUrl: ''});
		if (user.spreadsheetUrl) {
			document.querySelector('#spreadsheetUrl').style.display = 'block';
			document.querySelector('#spreadsheetUrlForm').style.display = 'none';
		}
	}

	handleSpreadsheetUrlCancel() {
		const user = this.props.users.entities[this.props.user._id];
		if (user.spreadsheetUrl) {
			document.querySelector('#spreadsheetUrl').style.display = 'block';
			document.querySelector('#spreadsheetUrlForm').style.display = 'none';
		}
		else {
			this.setState({spreadsheetUrl: ''});
		}
	}

	deleteProgression = (idcallback) => {
	  console.log(`idAPP callback ===> ${idcallback} `)
	  const p = this.props.progressions.entities;
	  console.log('---___ progression ___---');
	  console.log(p);
	  console.log('--------------------');
	  const pId = Object.keys(p).find(id => { // <= callback qui va retourner l'id si les conditions sont bonnes
	                  const progression = this.props.progressions.entities[id]; // on recupère l'id de la progression (string)
	                  const step = this.props.steps.entities[progression.step]; // on récupère la step de l'id ci-dessus
	                  return  step && step.course == idcallback && progression.user === this.props.user._id// si la l'id de parcours lié à la step  est le même que le idcallback on retourne l'id du parcours
	  })
	  if(pId){
	      this.props.dispatch(progressionsActions.deleteProgress(pId)); // si il return un id alors on peux dispatcher l'action
	  }
	  
  	}

	listCoursesStarted(){
		let courses = [];
        if(!this.props.courses.entities || !this.props.progressions.entities || !this.props.steps.entities || !this.props.finishedCourses.entities){
			return courses;
		}
    
    	for(let p in this.props.progressions.entities){
			p = this.props.progressions.entities[p];
			if(p.user != this.props.user._id)
				continue
      		const step = this.props.steps.entities[p.step];
      		if(step){
				if(this.props.courses.entities[step.course]){
					if (this.props.courses.entities[step.course].isActivity) {
                        continue;
                    }
					let course = this.props.courses.entities[step.course];
          			let finished = Object.values(this.props.finishedCourses.entities).filter(f => {
						  if(f.course === course._id && f.user === this.props.user._id)
						  	return f
					  }).reduce((selected, f)=>{
						if(!selected)
							return f
						if(selected.createdAt < f.createdAt)
							return f
						return selected
					  }, null)
          			courses.push((<CourseSummaryComponent callback={this.deleteProgression} key={course._id} course={course} started={true} locked={false} currentStep={finished ? course.stepsCount : step.currentPositionInCourse} finished={finished}/>));
        		}
      		}	
		}
    	return courses;
	  }
	  
	listActivitiesStarted() {
		let activities = [];
        if(!this.props.courses.entities) {
            return activities;
        }   

        if (this.props.progressions.entities) {
            for(let progression in this.props.progressions.entities){
                progression = this.props.progressions.entities[progression];
                let step = this.props.steps.entities[progression.step];
				if(progression.user !== this.props.user._id)
					continue
                if(step){
                    if(this.props.courses.entities[step.course]) {
                        let course = this.props.courses.entities[step.course];
                        if (!course.isActivity) {
                            continue;
						}
						
						let finish = false;
                        for (let finishCourse in this.props.finishedCourses.entities) {
                            finishCourse = this.props.finishedCourses.entities[finishCourse];
                            if (finishCourse.user === progression.user && finishCourse.course === course._id) {
                                let update = finishCourse.endedXml !== progression.xml ? true : false
                                activities.push((<CourseSummaryComponent key={course._id} user={this.props.user} course={course} progression={progression} finished={finishCourse} currentStep={course.stepsCount} update={update} started={true} locked={false} />));
                                finish = true;
                            }
                            continue;				
                        }
                        if (finish) {
                            continue;
                        }
                        activities.push((<CourseSummaryComponent key={course._id} user={this.props.user} course={course} progression={progression} currentStep={step.currentPositionInCourse-1} update={true} started={true} locked={false} />))
                    } 
                } 
            }
        }
        return activities;
	}

	listApps(){
    let apps = []
		if(this.props.apps.length < 1) {
				apps = [
					<Message style={{display: 'block'}}  info={this.props.user.confirmed ? true : false} warning={!this.props.user.confirmed ? true : false}>
						<Message.Header>
						{this.props.user && !this.props.user.confirmed ? (
							<span>Tu n'as pas validé ton adresse email</span>
						) : (
							<span>Tu n'as pas encore créé d'application</span>
						)}
						</Message.Header>
						<Divider hidden/>

						{this.props.user && !this.props.user.confirmed ? (
							<p>
								Pour accéder à tes créations ouvre l'email d'activation que nous t'avons envoyé.
								<br/>
								La sauvegarde en ligne du mode de création libre est réservée aux utilisateurs ayants validés l'adresse email de leur compte.					
								<br/>
								Ouvre l'email d'activation que nous t'avons envoyé.
							</p>
						) : (
							<p>
								Si tu es débutant je te conseille de faire le parcours d'introduction,
								il t'apprendra tout ce que tu as besoin de savoir pour bien débuter.
								<br/><br/>
								Sinon tu peux tout de suite te diriger vers l'espace de création libre.
							</p>
						)}
						<Divider hidden/>
						<Button compact onClick={() => {this.redirectToCreation()}} color='orange' basic size={'big'}>
							Création Libre
						</Button>
					</Message>
				]
		} else if(this.props.filteredApps.length > 0){
            apps = this.props.filteredApps.map((app, index) => (
				<AppSummaryComponent key={app._id} app={app}/>
			))
		} else {
			apps = [<Message visible>Aucune application ne correspond à tes critères de recherche</Message>]
		}
		return apps
	}

	renderLoading(){
      let l = [];
      for(let i = 0; i<3; i++){
          l.push((<Placeholder key={i}>
              <Placeholder.Header image>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
          </Placeholder>))
      }
      return l
	}

	renderProfilePicture(){
        return <Container textAlign="center"><ProfilePicture email={this.props.user.email}/></Container>
  }

	renderBandeau(){
		return (
			// <Container className='profil-bandeau-container' fluid>
			// 	<Grid>
			// 		<Grid.Row className='profil-bandeau-row'>
			// 			<Grid.Column className='profil-bandeau-column' width={16}>
			// 				<Container className='profil-bandeau-card' text>
			// 					{/*this.props.user && (<Image className="userAvatarCircularBordered" circular centered size="small" src={'https://www.gravatar.com/avatar/'+md5(this.props.user.email.toLowerCase())+'?&d=retro&s=200'}/>)*/}
			// 					{this.props.user && this.renderProfilePicture()}
								<Menu tabular>
									<Menu.Item name='Mon Compte' id={0} active={this.state.activeItem == 0} onClick={(e) => {this.handleItemClick(e)}} />
									<Menu.Item name='Mes Parcours' id={1} active={this.state.activeItem == 1} onClick={(e) => {this.handleItemClick(e)}} />
									<Menu.Item name='Mes Créations' id={2} active={this.state.activeItem == 2} onClick={(e) => {this.handleItemClick(e)}} />
									{this.props.user && this.props.user.structure &&
									<Menu.Item name='Mes Activités' id={3} active={this.state.activeItem == 3} onClick={(e) => {this.handleItemClick(e)}} />}
								</Menu>
			// 				</Container>
			// 			</Grid.Column>
			// 		</Grid.Row>
			// 	</Grid>
			// </Container>
		)
	}

	renderMonCompte(){
		if(!this.props.user)
			return null;

		let user=this.props.users.entities[this.props.user._id];

		let group;
		if(user && user.group && this.props.groups.entities[user.group]){
			group = this.props.groups.entities[user.group];
		}else{
			group = null
		}
		let structure
		if(group && this.props.structures.entities[group.structure]){
			structure = this.props.structures.entities[group.structure]
		}else if(user && this.props.structures.entities[user.structure]){
			structure = this.props.structures.entities[user.structure];
		}else{
			structure = null
		}
		let guestSpreadsheetUrl = null;
		if (structure) {
			for (let user in this.props.users.entities) {
				if (this.props.users.entities[user].structureOwned === structure._id) {
					guestSpreadsheetUrl = this.props.users.entities[user].spreadsheetUrl;
				}
			}
		}
		let structureOwned = this.props.users.entities[this.props.user._id] ? this.props.structures.entities[this.props.users.entities[this.props.user._id].structureOwned] : undefined;
		let isGuest = this.props.user.role && this.props.user.role.type ? this.props.user.role.type === "guest" : false;
		
		return(
			<div className='pane'>
				<Divider horizontal hidden/>
				<Header>
					Mes informations personnelles
				</Header>
				{ this.props.user &&
					<div>
						Nom d'utilisateur : {this.props.user && this.props.user.username}
						<br/>
						{ !isGuest && ("Adresse email : "+this.props.user.email)}
						<br/>
						{this.props.user.birthdate && 
							<span>Date de naissance : {this.props.user.birthdate}</span>
						}
						{structure && 
							<div>
								<p>Structure: {structure.name}</p>
								{group && <p>Groupe: {group.name}</p>}
								<Divider horizontal hidden/>

								{guestSpreadsheetUrl &&
									<div>
										Url de la feuille Google Sheets :
										<br />
										<a href={guestSpreadsheetUrl} target='_blank'>{guestSpreadsheetUrl}</a>
									</div>
								}
							</div>
						}

						<Divider horizontal hidden/>

						{structureOwned &&
							<span>
								<Header>Les informations de ma structure</Header>
								Structure : {this.capitalize(structureOwned.name)} ({structureOwned.type})
								<br/>
								Je suis enseignant dans la ville de : {this.capitalize(this.props.user.city)}							

								<Divider horizontal hidden/>

							</span>
						}

						{!structure && 
							<Fragment>
								Url de la feuille Google Sheets :
								{this.props.users.entities[this.props.user._id] && this.props.users.entities[this.props.user._id].spreadsheetUrl ? 
									<Fragment>
										<br />
										<div id='spreadsheetUrl'>
											<a id="spreadsheet_link" href={this.props.users.entities[this.props.user._id].spreadsheetUrl} target='_blank'>{this.props.users.entities[this.props.user._id].spreadsheetUrl}</a>
											<br />
											<Button onClick={this.displaySpreadsheetUrlForm}>Modifier</Button>
										</div> 
										
										{this.spreadsheetUrlForm(false)}
									</Fragment>
									:
									this.spreadsheetUrlForm()
								}
							</Fragment>
						}

						{this.props.user && !this.props.user.confirmed &&
							<Message warning style={{display:'block'}}>
								<Message.Header>
									Tu n'as pas validé ton adresse email
								</Message.Header>
								<Divider hidden/>
								Pour accéder à toutes les fonctionnalités du site ouvre l'email d'activation que nous t'avons envoyé.
							</Message>
						}
					</div>
				}
				<Divider section />
				{ this.props.user && this.props.user.confirmed &&
					<div>
						<Inventaire/>
						<Divider section />
					</div>
				}
			</div>
		)
	}

	renderMesParcours(){
		
		return(
			<div className='pane'>
				<Divider horizontal hidden/>
				<Header>Mes Parcours</Header>

				<Item.Group>
					{this.listCoursesStarted().length > 0 ? (
						this.listCoursesStarted()
					) : (
						<Message style={{display:'block'}} info={this.props.user.confirmed ? true : false} warning={!this.props.user.confirmed ? true : false}>
							{/* Ajout d'un boutons redirigeant vers la page des parcours ??? */}
							<Message.Header>
							{!this.props.user.confirmed ? (
								<span>Tu n'as pas validé ton adresse email</span>
							) : (
								<span>Tu n'as pas encore commencé de parcours</span>
							)}
							</Message.Header>
							<Divider hidden/>
							{!this.props.user.confirmed ? (
								<p>
									Pour accéder aux parcours ouvre l'email d'activation que nous t'avons envoyé.
									<br/>
									Grâce aux parcours, tu vas apprendre à utiliser ton kit et à programmer dans l'espace de création. Ils te donneront plein d'idées de créations.
								</p>
							) : (
								<p>
									Grâce aux parcours, tu vas apprendre à utiliser ton kit et à programmer dans l'espace de création. Ils te donneront plein d'idées de créations.
									<Divider hidden/>
									<Button compact onClick={() => {this.redirectToParcours()}} color='orange' basic size={'big'}>
										Parcours
									</Button>
								</p>
							)}


						</Message>
					)}
				</Item.Group>
				<Divider section/>
			</div>
		)
	}

	renderMesCreations(){
		return(
			<div className='pane'>
				<Divider horizontal hidden/>
				<Header>Mes Créations</Header>
				{ this.props.apps.length > 0 &&
					<Input onChange={(e)=>{this.filterApps(e)}} fluid icon='search' placeholder='Rechercher...'
						//    action={{ color: 'teal', labelPosition: 'right', icon: 'trash', content: 'Effacer' }}
					/>
				}
				<Item.Group>
					{this.props.apps_isLoading && this.renderLoading()}
					{this.props.user && this.listApps()}
				</Item.Group>
				<Divider section />
			</div>
		)
	}
	
	render(){
		// let isLoading = null
		// this.props.apps_isLoading || this.props.freeCreation_isLoading ? isLoading = true : isLoading = false

		return (
			<PageWithSideMenu pageName={"profil"}>
				<Container text>
					<Grid>
						<Grid.Column width={16}>
							<Grid.Row className='segment'>
								<Tab panes={[
									{ menuItem: 'Mon Compte', render: () => this.renderMonCompte() },
									{ menuItem: 'Mes Parcours', render: () => <CoursesTabWithRouter user={this.props.user} listCoursesStarted={this.listCoursesStarted} /> },
									{ menuItem: 'Mes Créations', render: () => <AppsTabWithRouter user={this.props.user} filteredApps={this.props.filteredApps} apps={this.props.apps} apps_isLoading={this.props.apps_isLoading} dispatch={this.props.dispatch}/> },
									{menuItem: 'Mes Activités', render: () => <ActivitiesTabWithRouter user={this.props.user} listActivitiesStarted={this.listActivitiesStarted} /> }
								]}/>
							</Grid.Row>
						</Grid.Column>
					</Grid>
				</Container>
			</PageWithSideMenu>
		)
	}
}

function mapStateToProps(state) {
	return {
		user: getActualUser(state),
		// user: state.session.user,
		structures: state.model.structures,
		groups: state.model.groups,
		users: state.model.users,
        courses: state.model.courses,
        progressions: state.model.progressions,
        steps: state.model.steps,
		finishedCourses: state.model.finishedCourses,
		
		freeCreation: state.freeCreation,
        apps: state.model.apps.apps,
        filteredApps: state.model.apps.filteredApps,
        apps_isLoading: state.model.apps.isLoading,
        freeCreation_isLoading: state.freeCreation.isLoading,
	}
}

const connectedProfil = connect(mapStateToProps)(Profil)
export { connectedProfil as Profil }
// export const ProfilRouter = withRouter(connectedProfil)