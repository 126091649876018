import React from 'react';
import PropTypes from 'prop-types';
import {Online, Offline, Detector} from 'react-detect-offline'

import './HeaderCourse.css'

import {CSSTransitionGroup} from 'react-transition-group'
import ReactHtmlParser from 'react-html-parser';
import {withRouter} from 'react-router-dom'
import runtimeEnv from '@mars/heroku-js-runtime-env';

import {
    Button,
    Grid,
    Icon,
    Popup
} from 'semantic-ui-react';

import { SerialMonitorButton } from '../../containers/SerialMonitorButton'
import { SimulatorButton } from '../../containers/Simulator';

// const classNames = require('classnames');

const HeaderCourse = (props) => {

    function onResetApp() {
        // if (props.step.baseWorkspace === null && !props.progression.previousXml) {
        //     return
        // }
        props.resetApp();
    }

    function onCheck() {
        if (props.progression.ended && props.course.isActivity) {
            props.onAlreadyValidateActivityClick();
            return;
        }
        else if (props.progression.ended) {
            props.history.push('/courses');
            return
        }
        else if (!props.progression.ended && props.course.isActivity) {
            props.onValidateActivityClick();
        }
        props.onNextStep()
    }

    function onChange(event){
        props.idExtensionChange(event.target.value)
    }

    function renderSerialMonitorButton(){
        return (
          <SerialMonitorButton/>
        )
    }

    function renderSimulatorButton() {   

        return (<SimulatorButton/>)
    }

    function renderSendCardButton(online) {   
        let txt = "Téléverser"
        let txt_extended = "Envoyer le programme sur la carte"
        if(!online) {
            return (
                <Popup position="bottom right" trigger={<Button icon labelPosition='right' id="testProgramme" className="" disabled color='orange' size='medium' onClick={props.onCompileClick}><Icon name='rocket'/>{txt}</Button>}><Popup.Header>{txt}</Popup.Header>
                    <Popup.Content>
                        {txt_extended}
                    </Popup.Content>
                </Popup>
            )
        }
        // return <Button icon labelPosition='right' id="testProgramme" className="" loading={props.compilationLoading} disabled={props.compilationLoading} color='orange' size='medium' onClick={props.onCompileClick}><Icon name='rocket'/>Tester mon programme</Button>

        if(props.compilationLoading === true){
            return (
                <Popup position="bottom right" trigger={<Button icon labelPosition='right' id="testProgramme" className="" loading disabled color='orange' size='medium' onClick={props.onCompileClick}><Icon name='rocket'/>{txt}</Button>}><Popup.Header>{txt}</Popup.Header>
                    <Popup.Content>
                        {txt_extended}
                    </Popup.Content>
                </Popup>
            ) 
        }else{
            return (
                <Popup position="bottom right" trigger={<Button icon labelPosition='right' id="testProgramme" className="" color='orange' size='medium' onClick={props.onCompileClick}><Icon name='rocket'/>{txt}</Button>}><Popup.Header>{txt}</Popup.Header>
                    <Popup.Content>
                        {txt_extended}
                    </Popup.Content>
                </Popup>
            ) 
        }
    }

    function renderEtapeSuivanteButton(online) {
        if (props.step.event === 'BlocklyClickCategory' || props.step.event === 'BlocklyCreateBlock' || props.noNextStepButton) {
            return
        }

        let txt = 'Etape suivante'
        let icon = 'arrow right';
        let popupText = "Valide ton étape et passe à la suivante"
        if (props.progression.ended && !props.course.isActivity) {
            txt = 'Retour aux parcours'
            icon = 'flag checkered'
        }
        else if (props.course.isActivity) {
            txt = "J'ai terminé";
            icon = 'flag checkered';

            if(!props.progression.ended)
                popupText = <div><p>Une fois ton travail terminé, valide le ici pour que ton enseignant.e sache que tu as fini cette activité.</p><p>Tu pourras toujours modifier ton rendu si tu souhaites apporter une correction.</p></div>
            else
                popupText = <div><p>Tu as déjà terminé cette activité mais tu peux encore modifier ton travail. Ton enseignant.e verra tes modifications</p></div>

        }

        let disabled = !online  || (props.course.isActivity && props.progression.ended ) ? true : false;

        return <Popup disabled={!props.course.isActivity} position="top center" trigger={
                <span>
                <Button icon labelPosition='right' id="etapeSuivante" color='blue' onClick={onCheck} disabled={disabled}>
                    <Icon name={icon}/>
                    {txt}
                </Button>
                </span>}>

                    {popupText}
                </Popup>
    }

    function renderIDExtension(){
        return <div className="ui input">
          <input type="text" placeholder="Extension id..." onChange={onChange}/>
        </div>
    }
    return ( 
        <Grid.Row columns={"equal"}>
            <Grid.Column  width={1}>
                <Button onClick={() => {
                let currentUrl = window.location.href;
                props.history.goBack() 
                setTimeout(function(){
                    // if location was not changed in 100 ms, then there is no history back
                    if(currentUrl === window.location.href){
                        // redirect to site root
                       props.history.push('/');
                    }
                }, 300);}} basic  icon="arrow left" style={{marginLeft:'20px'}}/>
            </Grid.Column>
            <Grid.Column  width={1}>
                {!props.progression.ended &&
                <div id='instructionHighLight'>
                    <CSSTransitionGroup
                        transitionName="fade3"
                        transitionEnterTimeout={500}
                        transitionLeaveTimeout={20}>
                        {!props.course.isActivity &&
                        <div className='descriptionCourse-text3' key={props.step.id}>
                            {ReactHtmlParser(props.step.description)}
                        </div>}
                    </CSSTransitionGroup>
                </div>}

                {props.progression.ended && !props.course.isActivity &&
                <div className='descriptionCourse-text3' key={1000}>Parcours terminé !!</div>}
            </Grid.Column>

            <Detector polling={{url: runtimeEnv().REACT_APP_CONNECTION_DETECTION_POOLING, enabled: true, interval: 6000}} render={({online}) => (
                <Grid.Column textAlign='right' style={{marginRight:'5px'}}>
                    <Button title="Annuler les modifications et revenir au début de l'étape" icon='trash alternate outline' size='medium' onClick={onResetApp}/>
                    {renderSerialMonitorButton()}
                    {renderSimulatorButton()}
                    {renderSendCardButton(online)}
                    {renderEtapeSuivanteButton(online)}
                    {/*{renderIDExtension()}*/}
                </Grid.Column>
            )}/>
        </Grid.Row>
    );
};

HeaderCourse.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

export default withRouter(HeaderCourse);