import axios from 'axios';
import {sessionService} from 'redux-react-session'
import runtimeEnv from '@mars/heroku-js-runtime-env';
import history from '../history'

const API_BASE_URL = runtimeEnv().REACT_APP_API_BASE_URL;

const client = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: ''
    }
});

client.interceptors.request.use(async function (config) {
		try{
	    const jwt = await sessionService.loadUser();
	    if(jwt)
	        config.headers.Authorization = 'Bearer ' + jwt.jwt;
  	}catch(e){
  		return config;
  	}
    return config
}, function (error) {
		return Promise.reject(error)
});

client.interceptors.response.use(null, function(error){
    if(error.response.data.statusCode === 403 && error.response.data.message && error.response.data.message.logout)
        history.push("/logout");
    else
    	return Promise.reject(error);
})


export const groupsApi = {
	create,
	update,
	remove
}

function create(group) {
	return client.post(`/groups`, group);
}

function update(group){
	return client.put(`/groups/${group._id}`, group)
}

function remove(groupId){
	return client.delete('/groups/'+groupId);
}