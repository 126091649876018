import React from 'react';

import {Label, Icon, Dropdown, Button, Checkbox, Segment, Popup} from 'semantic-ui-react'

import {NotificationComponent} from '../NotificationComponent';

import { Online, Offline, Detector } from 'react-detect-offline'
import runtimeEnv from '@mars/heroku-js-runtime-env';

const ConnectionStatusComponent = props => {

	function renderBoard(){
		// return (
		// 	<div>
		// 	<Label size="large" color={props.boardColor} title={props.boardStatus}>
		// 		<Icon name='plug' />Base
		// 		<Label.Detail>
		// 			<Icon name='angle down'/>
		// 		</Label.Detail>
		// 	</Label>
		// 	<Label pointing>Please enter a value</Label>
		// 	</div>
		// )
		function onMouseDownDropDown(event){
			event.nativeEvent.preventDefault();
			event.nativeEvent.stopPropagation();
		}

		function onAutoOpenClick(event){
			props.onAutoOpen();
		}

		function onAutoConnectClick(event){
			props.onAutoConnect();
		}

		function onConnectClick(){
			props.onConnect();
		}

		function onDisconnect(){
			props.onDisconnect();
		}

		if(props.boardStatus.buttonMode){
			return (<Button content={props.boardStatus.mainTitle} title={props.boardStatus.title} icon={props.boardStatus.icon} labelPosition='left' className={'icon '+props.boardStatus.color} compact onClick={props.onPair}/>)
		}
	
		return (
			<Dropdown onMouseDown={onMouseDownDropDown} text={props.boardStatus.mainTitle} title={props.boardStatus.title} icon={props.boardStatus.icon} floating labeled button className={'icon '+props.boardStatus.color} compact>
		    <Dropdown.Menu>
		      <Dropdown.Header icon='settings' content='Réglages' />
		      <Dropdown.Divider />
		      <Dropdown.Item onClick={onAutoOpenClick}>
		      	<Checkbox checked={props.boardStatus.autoOpen} label='Ouvrir le moniteur automatiquement'/>
		      </Dropdown.Item>
		      <Dropdown.Divider />
		      <Dropdown.Item onClick={onAutoConnectClick}>
		      	<Checkbox checked={props.boardStatus.autoConnect} label='Connexion automatique'/>
		      </Dropdown.Item>
		      <Dropdown.Item onClick={onConnectClick} label={{ color: 'green', empty: true, circular: true }} text='Connecter' disabled={props.boardStatus.autoConnect || props.boardStatus.disableConnect}/>
		      <Dropdown.Item onClick={onDisconnect} label={{ color: 'red', empty: true, circular: true }} text='Déconnecter' disabled={props.boardStatus.autoConnect || props.boardStatus.disableDisconnect}/>
				<React.Fragment>
					<Dropdown.Divider />
					<Dropdown.Item onClick={props.onWebSerialToggle}>
						<Checkbox checked={props.boardStatus.useWebSerial} label='Utiliser le WebSerial'/>
					</Dropdown.Item>
				</React.Fragment>
			</Dropdown.Menu>
		  </Dropdown>
		)
	}

	function renderInternet(online){
		return (
			//<Segment compact size="tiny" floated="right" clearing={true} inverted color='green'>
      	//<Icon name='world' />
    	//</Segment>
			<Label size="large" color={online ? "green" : "red"} title={online ? "Tu es connecté au serveur de création de Thingz" : "Connecte-toi à internet pour utiliser ta base"}>
				<Icon style={{margin:0}} name='world' />
			</Label>
		)
	}

	return (
	<div>
		<NotificationComponent notification={props.notification} user={props.user} />
		{renderBoard()}
		<Detector polling={{url: runtimeEnv().REACT_APP_CONNECTION_DETECTION_POOLING }} render={({ online }) => (
			 		renderInternet(online)
		)}/>
	</div>
	)
}

export default ConnectionStatusComponent;