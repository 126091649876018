import React, {Component} from 'react';

import * as sessionActions from "../actions/session_actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Container} from "semantic-ui-react"
import querySearch from "stringquery";

import RegisterComponent from "../components/RegisterComponent/RegisterComponent";
import HeaderMain from "../components/HeaderMain/HeaderMain";

class Register extends Component {

    render() {
        document.title = "Inscription - Thingz"
        
        const query = querySearch(this.props.router.location.search);
        
        return (
            <div style={{flex: 1, display:"flex", flexDirection:"column"}}>
                <HeaderMain/>
                <RegisterComponent 
                    actions={this.props.actions}
                    isLoading={this.props.register.isFetching}
                    error={this.props.register.error}
                    activeTab={query.type}
                />
            </div>
        );
    }
}

const mapDispatch = (dispatch) => {
    return {
        actions: bindActionCreators(sessionActions, dispatch)
    };
};

const mapStateToProps = (state) => {
    return {
        register: state.register
    }
}


export default connect(mapStateToProps, mapDispatch)(Register);
