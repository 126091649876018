import {schema} from 'normalizr'
import {courseEntity, finishedCourseEntity, userEntity, stepEntity} from './entities.js';

courseEntity.define({
	user: userEntity,
	finishedcourses: [finishedCourseEntity],
	steps: [stepEntity],
	firstStep: stepEntity,
	cloneFrom: courseEntity
})

export const courseSchema = courseEntity

export const coursesSchema = new schema.Array(courseSchema);