import React from 'react';

import { FormActivityCreatorComponent } from '../../components/FormActivityCreatorComponent/FormActivityCreatorComponent';

import { Segment, Responsive, Message, Icon } from 'semantic-ui-react';

import { useMediaQuery } from 'react-responsive'


const ModalActivityCreatorComponent = (props) => {

    function renderMessage(size){
        return (
            <div>
                <Message size={size}>
                    <Message.Content>
                        <p style={{width:"100%"}}> Utilisez l'espace de travail pour construire le <b>point de départ</b> de l'activité</p>
                    </Message.Content>
                    <Message.Header style={{display:"flex", flexDirection:"column"}}>
                        <Icon size="large" name="arrow right" style={{display:"flex", flex:"1", alignItems:"center"}}/>
                    </Message.Header>
                </Message>
                
                <Message size={size}>
                    <Message.Header style={{display:"flex", flexDirection:"column"}}>
                        <Icon size="large" name="arrow down" style={{display:"flex", flex:"1", alignItems:"center"}}/>
                    </Message.Header>
                    {/* <p>Cette interface vous permet de créer une activité pour vos élèves.<br /> Donnez un nom et une consigne à l'activité, choisissez les blocks que vos élèves auront à disposition en cliquant sur le bouton "Choisir les blocks nécessaires", puis sur l'espace de droite vous pouvez définir les blocks de départ de l'activité.<br /> N'oubliez pas ensuite de valider pour sauvegarder votre activté.<br /> Le bouton Publier / Retirer vous permet de rendre accessible ou non l'activité à vos élèves.
                    </p> */}
                    <Message.Content>
                        <p style={{width:"100%"}}> Renseignez le <b>titre</b>, la <b>consigne</b> et les <b>blocs disponibles</b> pour vos élèves grâce au formulaire ci-dessous</p>  
                    </Message.Content>
                </Message>
            </div>
        )
    }
    const isMiniHeight = useMediaQuery({ maxDeviceHeight: 660})
    const isTinyHeight = useMediaQuery({ minDeviceHeight: 661, maxDeviceHeight: 699})
    const isSmallHeight = useMediaQuery({minDeviceHeight: 700})
    let size = isMiniHeight ? "mini" : isTinyHeight ? "tiny" : "small"
    return(
        

                <FormActivityCreatorComponent 
                    activity={props.activity} 
                    step={props.step}
                    structure={props.structure}
                    groups={props.groups}
                    selectedCourses={props.selectedCourses}
                    createActivity={props.createActivity}
                    createStep={props.createStep}
                    updateActivity={props.updateActivity}
                    updateStep={props.updateStep}
                    saveStepInstruction={props.saveStepInstruction}
                    saveStepToolboxXml = {props.saveStepToolboxXml}
                    setAccessibility = {props.setAccessibility}
                    isPublish={props.isPublish}
                    publishActivity={props.publishActivity}
                    onUnload={props.onUnload}
                    fieldChange={props.fieldChange}
                    changeDefaultBlock={props.changeDefaultBlock}
                />
      
    )
}

export { ModalActivityCreatorComponent }