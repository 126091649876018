// import ReactDOM from 'react-dom'
// import React from 'react'

import {guidedTourConstants} from "../constants";

export const guidedTourActions = {
    onStepDone,
    goNextStep,
    goPreviousStep,
    check,
}

function onStepDone() {
    return (dispatch, getState) => {
        dispatch({type: guidedTourConstants.NEXTSTEP_DONE})
    }
}

function goNextStep(solutions) {
    console.log('Go Next Step')
    return (dispatch, getState) => {
        const hasSolution = check(solutions, getState().guidedTour.stepIndex)
        console.log('hasSolution', hasSolution)
        dispatch({type: guidedTourConstants.NEXTSTEP, hasSolution: hasSolution, ignore: getState().guidedTour.hasSolution})
        // check(solutions)
    }
}

function goPreviousStep(solutions) {
    return (dispatch, getState) => {
        const hasSolution = check(solutions, getState().guidedTour.stepIndex)
        dispatch({type: guidedTourConstants.PREVIOUSSTEP, hasSolution: hasSolution})
        // check(solutions)
    }
}

function check(solutions, stepIndex) {
        console.log(solutions)
        console.log(stepIndex + 1)
        if(solutions.find((solution) => {return solution.stepIndex === stepIndex + 1})) {
            console.log('Cette étape a bien une solution !')
            return true
        } else {
            console.log('Cette n\'a pas de solution !')
            return false
        }
}