export const modalConstants ={
	ADD_MODAL_MESSAGE: "MODAL_ADD_MESSAGE",
	DESTROY_ALL_MODAL_MESSAGE: "MODAL_DESTROY_ALL_MODAL_MESSAGE",
	DESTROY_TEMP_MODAL_MESSAGE: "MODAL_DESTROY_TEMP_MESSAGE",
    DESTROY_CLUES_MESSAGE: "MODAL_DESTROY_CLUES_MESSAGE",
	MODAL_MESSAGE_TYPE: {
        CONTEXT: 'CONTEXT',
        INSTRUCTION: 'INSTRUCTION',
        COURSE_ENDED: 'COURSE_ENDED',
        ACTIVITY_ENDED: 'ACTIVITY_ENDED',
        ACTIVITY_ALREADY_VALIDATE: 'ACTIVITY_ALREADY_VALIDATE',
        VALIDATE_ACTIVITY: 'VALIDATE_ACTIVITY',
        UPDATE_ACTIVITY: 'UPDATE_ACTIVITY',
        CLUE: 'CLUE',
        COMPILATION_ERROR: 'COMPILATION_ERROR',
        COMPILATION_SUCCESS: 'COMPILATION_SUCCESS',
        FLASH_ERROR: 'FLASH_ERROR',
        FLASH_SUCCESS: 'FLASH_SUCCESS',
        CHECK_ERROR: 'CHECK_ERROR',
        CHECK_ERROR_NO_MODIF: 'CHECK_ERROR_NO_MODIF',
        CHECK_ERROR_NO_COMPIL: 'CHECK_ERROR_NO_COMPIL',
        CHECK_UNKNOWN_ERROR: 'CHECK_UNKNOWN_ERROR',
        STEP_DONE: 'STEP_DONE',
        NO_REBOOT: 'NO_REBOOT',
        BAD_HEX_CHECKSUM: 'BAD_HEX_CHECKSUM',
        MORE_THAN_ONE_CARD: 'MORE_THAN_ONE_CARD',
        NO_CARD: 'NO_CARD',
        COMPILATION_TIMEOUT: 'COMPILATION_TIMEOUT',
        BOARD_NOT_BOOTED_AFTER_FLASH: 'BOARD_NOT_BOOTED_AFTER_FLASH',
        UNKNOWN_ERROR: 'UNKNOWN_ERROR',
        OFFLINE_ERROR: 'OFFLINE_ERROR',
        OFFLINE_RECOVERY: 'OFFLINE_RECOVERY',
        COMBINATED_ERROR: 'COMBINATED_BLOCKS_ERROR',
        NO_CODE_ERROR:'NO_CODE_ERROR',
        OVER_RECEIVING: 'OVER_RECEIVING',
        NO_MORE_CLUES: 'NO_MORE_CLUES',
        NO_CLUES: 'NO_CLUES',
        CANT_SEND_MSG: 'CANT_SEND_MSG',
        CANT_CONNECT: 'CANT_CONNECT',
        SIMULATOR_LANG_NOT_SUPPORTED: 'SIMULATOR_LANG_NOT_SUPPORTED',
        UNEXPECTED_CARD: 'UNEXPECTED_CARD',
        CONNECTION_LOST: 'CONNECTION_LOST',
        WRONG_TYPE_CARD: 'WRONG_TYPE_CARD',
        BLOCKLY_XML_LOAD_FAILED: 'BLOCKLY_XML_LOAD_FAILED'
	},
	CHECK_ERROR_MESSAGE: [
    "Tu vas y arriver !!",
    "Un indice pourrait t'aider",
    "Presque !!",
    "Ce n'est pas tout à fait ça",
    "Regarde un indice si c'est trop dur"
	],

}