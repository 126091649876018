import React, {Component} from 'react';
import { Message, Grid, Button, Card, Dropdown, Accordion, Icon, Checkbox, List, Divider, Modal, Header, Popup } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom'

import StructureStudentCard from '../../StructureStudentCard'


class StudentsTab extends Component{

    constructor(props){
        super(props)

        const initialState = {
            version:"1.0",
            isInitial:true,
            currentOrder: "alphabetic",
            modal: {
                close: true,
                actionDescription: "",
                text: ""
            },
            filter: {
                open: false,
                groups:[]
            }
        }
        try{
            let structureState = window.localStorage.getItem("structure");
            if(structureState){
                structureState = JSON.parse(structureState)
                if(structureState.students && structureState.students.version === initialState.version){
                    this.state = {...structureState.students}
                    this.state.filter.open = true;
                    this.state.modal.close = true;
                }else{
                    this.state = initialState
                }
            }else{
                this.state = initialState
            } 
        }catch(e){
            this.state = initialState
        }
        
    }

    componentDidMount(){
        if(this.state.isInitial){
            let g =[];
            this.props.groups.array.forEach(group => {
                g.push(group)
            })
            g.push("nogroup")
            this.setState(prevState => {
                return {isInitial: null, filter: {...prevState.filter, groups: g}}
            })
        }else{

        }
    }

    componentDidUpdate(prev){
        if(prev.groups.length != this.props.groups.length && this.state.filter.accessibility.group){
            this.props.groups.array.forEach(g => {
                if(!prev.groups.array.find(g2=>{return g2._id === g._id})){
                    //New group default add to filter
                    let groups = [...this.state.filter.accessibility.groups];
                    groups.push(g._id);
                    this.setState(prevState => {
                        return {filter: {...prevState.filter, groups: g}}
                    })
                }
            })
        }
        let structureState = null;
        try {
            structureState = window.localStorage.getItem("structure")
        } catch (error) {
            console.log("failed setItem structure")
        }
            
		if(structureState){
			structureState = JSON.parse(structureState)
		}else{
            structureState = {}
        }
        structureState.students = structureState.students || {};
        structureState.students = this.state
        try{
            window.localStorage.setItem("structure", JSON.stringify(structureState));
        }catch(e){
            console.log("failed setItem structure")
        }
    }

    capitalize(str){
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

    changeOrder = (e, data) => {
		this.setState({currentOrder: data.value})
    }

    toggle = (name, arg) => {
        this.setState(prev => {
            switch(name){
                case "groups":
                    let groups = [...this.state.filter.groups]
                    const index = groups.indexOf(arg);
                    if(index !== -1){
                        groups.splice(index, 1)
                    }else{
                        groups.push(arg)
                    }
                    return {filter: {...prev.filter, groups: groups}}
            }
        })
    } 
    
    openModal = (learnerId) => {
		let text;
		let actionDescription;
		const learner = this.props.users.entities[learnerId];
		if(learner.role.type === "guest"){
			text = "Supprimer";
				actionDescription = `Vous êtes sur le point de retirer ${this.capitalize(learner.username)} de ${this.capitalize(this.props.structure.name)}. Ce compte ne sera plus utilisable`
		}else{
			text = "Supprimer de la structure";
				actionDescription = `Vous êtes sur le point de retirer ${this.capitalize(learner.username)} de ${this.capitalize(this.props.structure.name)}. Ce compte ne fera plus partie de votre structure`
		}
		let modal = {
			close:false,
			text,
			actionDescription,
			learnerId: learnerId
		}
		this.setState({modal})
    }
    
    deleteStudent = (event, data) => {
		this.closeModal()
		this.props.onDeleteClick(this.state.modal.learnerId);
    }
    
    closeModal = ()=>{
        this.setState({modal:{close:true}})
    }

    redirectToActivities = (groupId)=>{
        this.props.history.push('structure?tab=activities&filterGroup='+groupId)
    }

    applyGroups(groups){
        groups = groups.filter(g => {
            return this.state.filter.groups.includes(g.key)
        })
        return groups
    }
    
    applyFilters(){
        let groups = []

        if(this.props.users.array.length > 0){
			groups = this.props.groups.array.map((groupId, i) => {
                let transferGroups = Object.values(this.props.groups.entities).filter(g => {return g._id != groupId})
                let group = this.props.groups.entities[groupId].learners.sort((a, b) => {
                    return this.handleStudentsOrder(a, b);
                }).map(learner => {
                    if(learner)
                        return (
                            <StructureStudentCard 
                                onDeleteStudent={this.openModal} 
                                onTransferStudent={this.props.onTransferStudent} 
                                key={learner._id} learner={learner} 
                                groups={transferGroups}/>
                        )
                })

                if(group.length == 0){
                    group = this.renderNoStudentInGroup(groupId);
                }else{
                    group = (
                        <Grid>
                            <Grid.Row columns="equal">
                                <Grid.Column >
                                    <Popup basic trigger={
                                        <Button floated="right" onClick={()=>this.redirectToActivities(groupId)}>Voir les activités</Button>
                                    }>Visualisez les activités disponibles pour ce groupe</Popup>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns="equal">
                                <Grid.Column>
                                    <Card.Group stackable doubling itemsPerRow={3}>
                                        {group}
                                    </Card.Group>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    )
                }

                let title = this.props.groups.entities[groupId] ? this.props.groups.entities[groupId].name : "Élèves sans groupe"
                return {
                    key: groupId,
                    title:{
                        content: title,
                    },
                    content: {
                        content: group
                    }
                }
            })

        }
        
        groups = this.applyGroups(groups)
        return groups

    }

    handleStudentsOrder(a, b){
		const l1 = a;
		const l2 = b;
		switch(this.state.currentOrder){
			case "lastSeen":
				if(l1.online && l2.online)
					return l1.username.localeCompare(l2.username);
				else if(l1.online)
					return -1;
				else if(l2.online)
					return 1;
				else{
					return new Date(l2.lastSeen) - new Date(l1.lastSeen);
				}
			case "alphabetic":
			default:
				return l1.username.localeCompare(l2.username);
		}
	}

    renderNoStudent(){
		return (
		  <Message info style={{display:'block'}}>
		  	<Message.Header>Aucun élève</Message.Header>
				<p>Utilisez l'onglet d'Administration pour ajouter des élèves.</p>
			</Message>
		)
	}

	renderNoStudentInGroup(groupId){
		return (
            <React.Fragment>
                <Grid>
                    <Grid.Row columns="equal">
                        <Grid.Column >
                            <Popup basic trigger={
                                <Button floated="right" onClick={()=>this.redirectToActivities(groupId)}>Voir les activités</Button>
                            }>Visualisez les activités disponibles pour ce groupe</Popup>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Message style={{display:'block'}}>
                    <Message.Header>Vous n'avez pas encore d'élèves dans ce groupe</Message.Header>
                </Message>
            </React.Fragment>
		)
	}
    
    renderFilters(){
        return <Accordion fluid styled>
            <Accordion.Title
                index={0}
                onClick={()=>{this.setState(prev => { return {filter: {...prev.filter, open: !prev.filter.open}}})}}
                active={this.state.filter.open}>
                <Icon name="filter"/>
                Filtres
            </Accordion.Title>
            <Accordion.Content active={this.state.filter.open}>
                <Grid columns={1} divided>
                    <Grid.Row>
                        <Grid.Column>
                            <b>Groupes</b>
                            <List>
                                {this.props.groups.array.map(group => {
                                    return <List.Item key={group}><Checkbox onClick={()=>{this.toggle("groups", this.props.groups.entities[group]._id)}} checked={this.state.filter.groups.indexOf(this.props.groups.entities[group]._id) !== -1} label={this.props.groups.entities[group].name}/></List.Item>
                                })}
                            </List>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Accordion.Content>
        </Accordion>
    }

    render(){
        let groups = this.applyFilters();
        
        if(groups.length > 0){
            groups = <Accordion fluid styled exclusive={false} panels={groups} defaultActiveIndex={groups.map((group, i) => i)}>
            </Accordion>
        }else{
            groups = this.renderNoStudent()
        }
        return (<div className='pane' style={{height: "100%"}}>
            <span>Trier par </span>
            <Dropdown onChange={this.changeOrder} value={this.state.currentOrder} inline options={[{key:"alphabetic", text: "Alphabétique", value:"alphabetic"},{key:"lastSeen", text: "Dernière connexion", value:"lastSeen"}]}>

            </Dropdown>
            <Divider hidden></Divider>
            {this.renderFilters()}
            <Divider hidden></Divider>
            {groups}
            <Modal onClose={this.closeModal} open={!this.state.modal.close} dimmer="blurring" size='small'>
                <Header icon='trash' content="Confirmation de suppression" />
                <Modal.Content>
                    <p>
                        {this.state.modal.actionDescription}
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color='grey' onClick={this.closeModal}>
                        <Icon name='remove' /> Annuler
                    </Button>
                    <Button color='red' onClick={this.deleteStudent}>
                        <Icon name='trash' /> {this.state.modal.text}
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>)
}
}

export default withRouter(StudentsTab)