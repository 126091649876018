import { appsConstants } from '../../constants'
import { freeCreationActions } from '../free_creation_actions.js'
import * as api from '../../api'

export const appsActions = {
    getApps,
    filterApps,
    deleteApp,
}

function getApps() {
    return (dispatch, getState) => {
        dispatch({ type: appsConstants.GET_APPS_STARTED })
            //
        return api.AppApi.getAll().then(resp => {

            // Trie des apps par date de création :            
            let datas = resp.data.sort((a,b) => {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                if (new Date(a.updatedAt) > new Date(b.updatedAt))
                    return -1
                if (new Date(a.updatedAt) < new Date(b.updatedAt))
                    return 1
                return 0
                // return new Date(b.createdAt) - new Date(a.createdAt);
            });
            return dispatch({ type: appsConstants.GET_APPS_SUCCEEDED, payload: datas })
        })
        .catch(err => {
            // alert(`Vos applications n'ont pu être récupérées : \n${err}`)
            dispatch({ type: appsConstants.GET_APPS_FAILED, payload: err })
        })
    }
}

function filterApps(string) {
    return(dispatch, getState) => {
        if(string) {
            dispatch({type: appsConstants.FILTER_APPS, input_string: string})
        } else {
            dispatch({type: appsConstants.RESET_APPS})
        }
    }
}

function deleteApp(appId, isFromCreation, isCurrentApp) {
    return(dispatch, getState) => {
        dispatch({ type: appsConstants.DELETE_APP_STARTED })
        return api.AppApi.destroy(appId)
            .then((resp) => {
                // construction du nouveau tableau d'applications après suppression
                let currentApps = getState().model.apps.apps;
                let newApps = [];
                for (let i = 0 ; i != currentApps.length ; i++ ) {
                    if (currentApps[i]._id != appId) {
                        newApps.push(currentApps[i]);
                    }
                }
                dispatch({ type: appsConstants.DELETE_APP_SUCCEED, apps: newApps })
                /*  Si l'appel est fait depuis la page création libre et que l'app à supprimer est l'app courrante, 
                    cet appel de setTemporary ne ferme pas la modal. */ 
                if (isFromCreation && isCurrentApp) {
                    dispatch(freeCreationActions.setTemporaryApp(isCurrentApp));
                }
            })
            .catch(err => {
                dispatch({ type: appsConstants.DELETE_APP_FAILED, payload: err })
            });
    };
}