import {usersBricksConstants} from '../constants'

export const initialUsersBrickState = {
    isLoading: null,
    error: null,

    bricks: null,
}

export const usersBricks = (state = initialUsersBrickState, action) => {
    switch(action.type) {
        case(usersBricksConstants.FETCH_ALL_STARTED):
            return {
                ...state,
                isLoading: true,
            }
        case(usersBricksConstants.FETCH_ALL_SUCCESS):
            return {
                ...state,
                isLoading: false,
                error: null,
                
                bricks: action.payload,
            }
        case(usersBricksConstants.FETCH_ALL_FAILED):
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        case(usersBricksConstants.ADD_BRICKS_STARTED):
            return {
                ...state,
                isLoading: true,
            }
        case(usersBricksConstants.ADD_BRICKS_SUCCESS):
            return {
                ...state,
                isLoading: false,

                bricks: [...state.bricks, action.payload],
            }
        case(usersBricksConstants.ADD_BRICKS_FAILED):
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }



        case(usersBricksConstants.REMOVE_BRICKS_STARTED):
            return {
                ...state,
                isLoading: true,
            }
        case(usersBricksConstants.REMOVE_BRICKS_SUCCESS):
        
            const datas = state.bricks.filter((item) => {
                return item.brick._id !== action.payload.brick._id
            })
            return {
                ...state,
                isLoading: false,

                bricks: datas,
            }
        case(usersBricksConstants.REMOVE_BRICKS_FAILED):
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }


            
        case(usersBricksConstants.UPDATE_QUANTITY_STARTED):
            return {
                ...state,
                isLoading: true,
            }
        case(usersBricksConstants.UPDATE_QUANTITY_SUCCESS):
            return {
                ...state,
                isLoading: false,

                bricks: action.payload,
            }
        case(usersBricksConstants.UPDATE_QUANTITY_FAILED):
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        case(usersBricksConstants.SAVE_MODIFICATIONS_STARTED):
            return {
                ...state,
                // isLoading: true,
            }
        case(usersBricksConstants.SAVE_MODIFICATIONS_SUCCESS):
            return {
                ...state,
                // isLoading: false,
            }
        case(usersBricksConstants.SAVE_MODIFICATIONS_FAILED):
            return {
                ...state,
                // isLoading: false,
                error: action.payload,
            }
        default:
            return state
    }
}