import React, {Component} from 'react';
import { Header, Accordion, Icon, Segment, Input, } from 'semantic-ui-react';

export default class AdministrationInviteUsers extends Component{

    constructor(props){
        super(props)

        this.state = {
            link_is_copied: false,
            open: false,
        }
    }

    componentDidUpdate(prevProps){

    }

    openInviteStudents = () => {
        this.setState(prev => {
			return {
				...prev,
                open: !prev.open
			}
		})
    }

    clearSelection(){
        if(window.getSelection){
            window.getSelection().removeAllRanges()
        }else if(document.selection){
            document.selection.empty()
        }
    }

    handleCopy = () => {
        /* Get the text field */
        let copyText = document.getElementById("myStructureUrl")
        /* Select the text field */
        copyText.select()
        /* Copy the text inside the text field */
        document.execCommand("copy")
        /* Deselect the input value */
        this.clearSelection()
        /* Alert the copied text */

        this.setState({link_is_copied: true})
  	}

    structureUrl(structure){
        if(structure) {
            return `${window.location.origin.toString()}/g/${structure.pin}`
        } else {
            return ''
        }
      }

    render(){
        return (
            <React.Fragment>
                <Accordion.Title
                    index={2}
                    onClick={this.openInviteStudents}
                    active={this.state.open}>
                    <Icon name='magic' />
                    Ajouter des élèves automatiquement (lien d'invitation)
                </Accordion.Title>
                <Accordion.Content active={this.state.open}>
                    <Segment style={{border: 0}}>
                    <Header>Lien d'association</Header>
					<p>L'adresse suivante permet à vos élèves de rejoindre votre espace. Un compte sera <b>créé automatiquement</b> pour chaque nouvel élève.</p>
					<p>Vous pouvez également les ajouter à votre structure en utilisant le formulaire <b>Ajouter des élèves</b></p>
					<div style={{margin:'10px'}}>
					{this.state.link_is_copied ? (
						<Input action={{ color: "teal", content: "Copier", icon: "check", onClick: () => this.handleCopy() }} fluid type="text" value={this.structureUrl(this.props.structure)} id="myStructureUrl"/>
					) : (
						<Input action={{ color: "blue", content: "Copier", icon: "copy", onClick: () => this.handleCopy() }} fluid type="text" value={this.structureUrl(this.props.structure)} id="myStructureUrl"/>
					)}
					</div>
                    </Segment>
                </Accordion.Content>
            </React.Fragment>
        )
    }
}