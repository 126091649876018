import axios from 'axios'
import runtimeEnv from '@mars/heroku-js-runtime-env';




const BASE_USER_URL = runtimeEnv().REACT_APP_API_BASE_USER_URL;
const API_PASSWORD ='/forgot-password';

export function forgotPassword(user){
// Request API.
 return axios
  .post(BASE_USER_URL+API_PASSWORD, {
    email: user.identifier,
    url: 'https://play.thingz.co/resetPassword'
  })
}