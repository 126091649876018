import {schema} from 'normalizr'
import {progressionsSchema} from './progressions_schema'
import {finishedCoursesSchema} from './finishedCourses_schema'
import {appsSchema} from './apps_schema'
import {stepSchema} from './steps_schema';
import {cluesSchema} from './clues_schema';
import { appEntity, userEntity, structureEntity, progressionEntity, finishedCourseEntity, groupEntity } from './entities';


userEntity.define({
	structureOwned: structureEntity,
	structure: structureEntity,
	progressions: [progressionEntity],
	finishedcourses: [finishedCourseEntity],
	group: groupEntity,
	apps: [appEntity]
})

const structure = new schema.Entity('structures', {
	owner: new schema.Entity("users", {}, { idAttribute: '_id' }),
	learners: new schema.Array(new schema.Entity("users", {}, { idAttribute: '_id' }))
}, {idAttribute: "_id"})

const progressions = new schema.Array(new schema.Entity('progressions', {
	step: stepSchema,
	user: new schema.Entity('users', {}, { idAttribute: '_id' }),
	clues: cluesSchema
}, {idAttribute: "_id"}))

export const userSchema = userEntity

//Probably not needed anymore
export const simpleUserSchema = userEntity
//Probably not needed anymore
export const userSchemaWithoutOwned = userEntity

export const usersSchema = new schema.Array(userSchema);
