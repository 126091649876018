import React from 'react';
import { Link } from 'react-router-dom'
import UserStatus from '../../containers/UserStatus'
import {Menu, Container, Image} from 'semantic-ui-react'
import Background from '../../img/thingz_logo.png';

import './HeaderMain.css';

const HeaderMain = props => {
    return (
        <Menu fluid borderless id="HeaderMainMenu">
            <Container fluid style={{alignItems:"center"}}>
                <Menu.Item  style={{margin:"3px", paddingTop:0, paddingBottom:0}}>
                    <Link to='/'>
                        {/* <div style={{background:"url("+Background+") no-repeat center center fixed", backgroundSize:"contain", height:"auto", width:"100%"}}> */}
                            <Image size="small" style={{width:"auto", maxHeight:"38px"}} src='../../img/thingz_logo.png'/>
                        {/* </div> */}
                    </Link>
                </Menu.Item>
                <Menu.Item position='right'>
                    <UserStatus dontFetch={props.dontFetch}/>
                </Menu.Item>
            </Container>
        </Menu>
    );
};

export default HeaderMain;
