import { courseSchema } from "../../schemas/index.js"
import Galaxia from "./Galaxia.js"
import Stk500v2 from "./Stk500v2.js"
import ThingzProtocol from "./ThingzProtocol"

const EXTENSION_STATE = {
    PORT_CLOSED: "PORT_CLOSE",
    PORT_OPENED: "PORT_OPENED",
    PORT_RESERVED: "PORT_RESERVED"
}

class ExtensionCmd{
    constructor(promise, resolve, reject){
        this.promise = promise
        this.resolve = resolve
        this.reject = reject
    }

    stop(){
        this.reject("reset")
    }
}

class ExtensionWebSerial{
    constructor(askForUserInteraction){
        this.ports = []
        this.cmds = []
        this.galaxia = null
        this.onMessageCB = null;
        this.onDisconnectCB = null;
        this.ignoreReset = false
        this.reader = null
        this.writer = null
        //Not used yet. Can be useful to explain to users why they have to interact with webserial connect board popup
        this.askForUserInteraction = askForUserInteraction;
    
        this.state = EXTENSION_STATE.PORT_CLOSED;

        //to be compatible with historical chrome port object
        //______________________________________________
        this.onMessage = {
            addListener: (callback) => {
                this.onMessageCB = callback;
            }
        }

        this.onDisconnect = {
            addListener: (callback) => {
                this.onDisconnectCB = callback;
            }
        }
        //______________________________________________
        this.init()
    }

    onSerialDisconnect = (event) => {
        console.log("onSerialdisconnect", event)

        this.thingzProtocol.setInitStrSent(false)
        this.thingzProtocol.setUseProtocol(false)

        let p = event.target
        // let callCb = this.onDisconnectCB //&& this.state !== EXTENSION_STATE.PORT_RESERVED;

        for(let i = 0; i < this.ports.length; i++){
            if(this.ports[i] == p){
                this.ports.splice(i, 1)
                break;
            }
        }
        console.log(p, this.port, p == this.port, this.ignoreReset)
        if(p == this.port){
            this.port = null
            this.reader = null
            this.writer = null
            if(this.state !== EXTENSION_STATE.PORT_RESERVED)
                this.state = EXTENSION_STATE.PORT_CLOSED;
            
            if(!this.ignoreReset){
                this.reset()
                this.respond({cmd: "open", value: "BOARD_DISCONNECTED", status:1})
            }
            this.ignoreReset = false
        }
    }

    onSerialConnect =  (event) => {
        this.ports.push(event.target)
    }

    async init(){
        try{
            this.thingzProtocol = new ThingzProtocol()
            let ports = await navigator.serial.getPorts()
            if(ports.length)
                this.ports = ports;
            
            navigator.serial.addEventListener("connect", this.onSerialConnect);

            navigator.serial.addEventListener("disconnect", this.onSerialDisconnect);
        }catch(e){
            console.log(e)
        }
    }

    getReader(){
        if(this.reader)
            return this.reader
        if(this.port){
            this.reader = this.port.readable.getReader()
            console.log(this.port.transformers)
            return this.reader;
        }
        return null
    }

    setReader(reader, readableStreamClosed){
        this.reader = reader;
        if(this.port && readableStreamClosed){
            this.port.readableStreamClosed = readableStreamClosed
        }
        if(!this.port.transformersReaders){
            this.port.transformersReaders = []
        }
        if(reader)
            this.port.transformersReaders.push(reader)
    }

    getWriter(){
        if(this.writer)
            return this.writer
        if(this.port){
            this.writer = this.port.writable.getWriter()
            return this.writer;
        }
        return null
    }

    prepareForWrite_(value){
        if(!this.thingzProtocol.useProtocol){
          let buf       = new ArrayBuffer(value.length);
          let bufView8  = new Uint8Array(buf);
         
          for(let i=0; i < value.length; i++){
            bufView8[i] = value.charCodeAt(i);
          }
          return bufView8;
        }else{
          let buf       = new ArrayBuffer(value.length+3);
          let bufView8  = new Uint8Array(buf);
          bufView8[0] = "s".charCodeAt(0);
          bufView8[1] = this.thingzProtocol.protocol.type.USER;
          bufView8[2] = value.length;
          for(let i=0; i < value.length; i++){
            bufView8[i+3] = value.charCodeAt(i);
          }
          // bufView8[bufView8.length-2] = 0xD;
          // bufView8[bufView8.length-1] = 0xA;
          return bufView8;
        }
    }

    async close_(){
        if(this.port){
            console.log(this.port)
            if(this.reader){
                await this.reader.cancel()
                try{
                    if(this.port.readableStreamClosed){
                        console.log("cancel")
                        await this.port.readableStreamClosed.catch(()=>{})
                    }
                }catch(e){
                    console.log(e)
                }
                try{
                    console.log("release lock")
                    this.reader.releaseLock()
                }catch(e){
                    console.log(e)
                }
                console.log(this.port)
                this.reader = null
            }
            if(this.writer){
                try{
                    await this.writer.close()
                }catch(e){
                    console.log(e)
                }
                try{
                    this.writer.releaseLock()
                }catch(e){
                    console.log(e)
                }
                this.writer = null
            }
        
            try{
                console.log("port close", this.port)
                await this.port.close();
            }catch(e){
                console.error(e)
            }
            this.port = null;
            console.log("port closed")
        }
        this.state = EXTENSION_STATE.PORT_CLOSED
    }

    //Read user data from the port
    handleRead_ = async () => {
        console.log("reading port")
        let reader = null
        while (this.port && this.port.readable && (reader = this.getReader()) && this.port.readable.locked) {
            // let reader = this.getReader();
            try {
                while (true) {
                    const { value, done } = await reader.read();
                    if (done) {
                        // |reader| has been canceled.
                        break;
                    }
                    // Do something with |value|...
                    let msgs = []
                    let msg = this.thingzProtocol.addData(value)
                        
                    if(msg){
                        do{
                            msgs[msgs.length] = msg
                            msg = this.thingzProtocol.addData()
                            console.log(msg)
                        }while(msg)

                        this.respond({cmd: "read", value: msgs})
                    }
                
                }
            } catch (error) {
                // Handle |error|...
                console.log(error)
            } finally {
                if(reader)
                    reader.releaseLock();
            }
        }
        console.log("done reading ", this.port)
    }

    async open_(update_state, readPort=true, baud){
        console.log("opening ", baud)
        if(this.port)
            await this.close_()
        if(this.ports.length == 0)
            throw "NO_CARD"
        if(this.ports.length > 1)
            throw "MORE_THAN_ONE_CARD"

        if(!baud){
            let board = this.getProtocol(this.ports[0].getInfo()).board
            if(board == "mega"){
                baud = 9600
            }else if(board == "galaxia"){
                baud = 921600
                // baud = 115200
            }else{
                baud = 115200
            }
        }
        console.log("opening ", baud)
        await this.ports[0].open({ baudRate: baud, buffersize:1024 });
        this.port = this.ports[0]
        
        if(update_state)
            this.state = EXTENSION_STATE.PORT_OPENED

        if(readPort){
            setTimeout(this.handleRead_)
        }
        return this.port
    }

    search(){
        return this.ports.length === 1
    }

    ignoreReset_(ignore){
        this.ignoreReset = ignore
    }

    disconnect(){
        this.onDisconnectCB = null;
        this.onMessageCB = null;
        this.close_()
        this.ports = []
        this.state = EXTENSION_STATE.PORT_CLOSED;
        try{
            navigator.serial.removeEventListener("connect", this.onConnect);

            navigator.serial.removeEventListener("disconnect", this.onDisconnect);
        }catch(e){

        }
    }

    isBoardFound(){
        return this.ports.length;
    }

    boardsToIDs(boards){
        return boards.reduce( (acc, cur) => {
            switch(cur){
                case 'mini':
                    return acc.concat([{"usbProductId": 32846, "usbVendorId":10755}, {"usbProductId": 77, "usbVendorId":10755}])
                case 'mini_bootloader':
                    return acc.concat([{"usbProductId": 77, "usbVendorId":10755}])
                case 'mega':
                    return acc.concat([{"usbProductId":66, "usbVendorId":9025}])
                case 'uno':
                    return acc.concat([{"usbProductId": 0x43, "usbVendorId": 0x2341}, {"usbProductId":0x1, "usbVendorId":0x2341}, {"usbProductId":0x43, "usbVendorId":0x2A03}, {"usbProductId":29987, "usbVendorId":6790}])
                case "galaxia":
                    return acc.concat([{"usbProductId":0x80A8, "usbVendorId":0x239A}])
            }
        }, [])
    }

    getProtocol(ids){
        if(ids.usbVendorId === 10755 && (ids.usbProductId === 32846 || ids.usbProductId === 77)){
            return {board: "mini", protocol: "stk500v2", bootloader: ids.usbProductId === 77 }
        }
        if(ids.usbVendorId === 9025 && ids.usbProductId === 66){
            return {board: "mega", protocol: "stk500v2"}
        }
        if((ids.usbVendorId === 0x2341 && ids.usbProductId === 0x43) || (ids.usbVendorId === 1 && ids.usbProductId === 0x2341) 
        || (ids.usbVendorId === 0x2A03 && ids.usbProductId === 0x43) || (ids.usbVendorId === 6790 && ids.usbProductId === 29987)){
            return {board: "uno", protocol: "stk500v1"}
        }
        if((ids.usbVendorId === 0x239A && ids.usbProductId === 0x80A8)){
            return {board: "galaxia", protocol:"galaxia"}
        }

    }

    askNewPermision = async(boards) => {
        // console.log(this.boardsToIDs(boards))
        try{
            let port = await navigator.serial.requestPort({filters: this.boardsToIDs(boards)}) //[{usbVendorId: 0x239A, usbProductId: 0x80A8}]
            this.ports = await navigator.serial.getPorts()
        }catch(e){
            console.error(e);
        }
    }

    async reset(){
        this.ignoreReset = false
        for(let cmd of this.cmds){
            cmd.stop()
        }
        this.cmds = []
        await this.close_()
    }

    respond(msg){
        if(this.onMessageCB)
            this.onMessageCB(msg)
    }

    async handle_msg(msg){
        // let promise, p_resolve, p_reject
        // promise = new Promise(async (resolve, reject) => {
        //     p_resolve = resolve
        //     p_reject = reject
            switch(msg.cmd){
                case "version":
                    this.respond({cmd: msg.cmd, status:0, value:"webserial"})
                    // resolve()
                    break;
    
                case "write_files":
                case "compilation":
    
                    if(this.state == EXTENSION_STATE.PORT_RESERVED){
                        this.respond({cmd: msg.cmd, value:"FLASH_INPROGRESS", status: 1})
                        // resolve()
                        return
                    }
                    if(this.state == EXTENSION_STATE.PORT_OPENED){
                        try{
                            await this.close_()
                        }catch(e){
                            this.respond({cmd:msg.cmd, value:"CLOSE_ERROR", status: 1})
                            // resolve()
                            return
                        }
                    }
    
                    try{
                        await this.open_(false, false)
                    }catch(e){
                        if(e.toString() === "NO_CARD"){
                            await this.askNewPermision(msg.filterBoards)
                            try{
                                await this.open_(false, false)
                            }catch(e){
                                this.respond({cmd:msg.cmd, value:e.toString(), status: 1})
                                return
                            }
                        }else{
                            this.respond({cmd:msg.cmd, value:e.toString(), status: 1})
                            return
                        }
                        // resolve()
                       
                    }
    
                    this.state = EXTENSION_STATE.PORT_RESERVED;
                    
                    let operations = {
                        connect: this.open_.bind(this),
                        disconnect: this.close_.bind(this),
                        askNewPermision: this.askNewPermision,
                        willReset: this.ignoreReset_.bind(this),
                        getReader: this.getReader.bind(this),
                        setReader: this.setReader.bind(this),
                        getWriter: this.getWriter.bind(this),
                        search: this.search.bind(this)
                    }

                    let protocol = this.getProtocol(this.port.getInfo());
                    switch(protocol.protocol){
                        case "stk500v2":
                            this.stk500v2 = new Stk500v2(this.port, operations, protocol.board)
                            try{
                                await this.stk500v2.flash(msg.data, msg.url)
                                this.respond({cmd:msg.cmd, status: 0})
                            }catch(e){
                                console.log(e)
                                this.close_()
                                if(e.value){
                                    switch(e.value){
                                        case "COMPILATION_ERROR":
                                            this.respond({cmd:msg.cmd, value: e.value,  compileError: e.text, status: 1})
                                        break;
                                        default:
                                            this.respond({cmd:msg.cmd, value: e.value,  status: 1})
                                        break;
                                    }
                                    
                                }else{
                                    this.respond({cmd:msg.cmd, value: e.toString(), status: 1})
                                }
                            }
                        break;
                        case "galaxia":
                            this.galaxia = new Galaxia(this.port, operations)
                            let files = msg.cmd == "write_files" ? msg.data : [{content: msg.data, path: "code.py", main:true}]
                        
                            try{
                                await this.galaxia.writeFiles(files)
                                await this.close_()
                                this.respond({cmd:msg.cmd, status: 0})
                                // resolve()
                                return
                            }catch(e){
                                console.log(e)
                                this.galaxia = null
                                await this.close_();
                                if(e && e.toString() !== "RELEASED")
                                    this.respond({cmd:msg.cmd, value: e ? e.toString(): "UNKNOWN", status: 1})
                                // resolve()
                                return
                            }
                        break;
                        case "stk500v1":
                            await this.close_()
                            this.respond({cmd:msg.cmd, value:"NOT_IMPLEMENTED", status: 1})
                            return
                    }
                    
                    break;
    
                case "open":
                    if(this.state == EXTENSION_STATE.PORT_RESERVED)
                        return this.respond({cmd: msg.cmd, value:"FLASH_INPROGRESS", status: 1})
    
                    if(this.state == EXTENSION_STATE.PORT_OPENED){
                        this.respond({cmd: msg.cmd, value:"ALREADY_OPENED", status: 1})
                        // resolve()
                    }else if(this.ports.length > 1){
                        this.respond({cmd: msg.cmd, value:"MORE_THAN_ONE_CARD", status: 1})
                        // resolve()
                    }else if(this.ports.length == 0){
                        this.respond({cmd: msg.cmd, value:"NO_CARD", status: 1})
                        // resolve()
                    }else{
                        if(this.getProtocol(this.ports[0].getInfo()).bootloader){
                            this.respond({cmd: msg.cmd, value:"IN_BOOTLOADER", status: 0})
                            return
                        }
                        try{
                            await this.open_(true, true, msg.options.bitrate)
                            // resolve()
                        }catch(e){
                            this.respond({cmd: msg.cmd, value:"CANT_CONNECT", status: 1})
                            // resolve()
                            return;
                        }
                        if(msg.noProtocol){
                            this.thingzProtocol.setUseProtocol(false)
                            this.respond({cmd: msg.cmd, value:"OPENED", status: 0})
                        }else{
                            let textEncoder = new TextEncoder("utf-8")
                            this.thingzProtocol.setUseProtocol(true)
                            this.respond({cmd: msg.cmd, value:"OPENED", status: 0})
                            setTimeout(async ()=>{
                                await this.getWriter().write(textEncoder.encode("THINGZ$start\r\n"))
                                this.thingzProtocol.setInitStrSent(true)
                            }, 1000)
                        }
                    }
                    break;
    
                case "write":
    
                    if(this.state == EXTENSION_STATE.PORT_RESERVED){
                        this.respond({cmd: msg.cmd, value:"FLASH_INPROGRESS", status: 1})
                        // resolve()
                        return
                    }
                    
                    if(this.state == EXTENSION_STATE.PORT_OPENED){
                        try{
                            const writer = this.getWriter();
                            const data = this.prepareForWrite_(msg.value)
                            await writer.write(data);
                            this.respond({cmd:msg.cmd, value:"SEND", status: 0})
                        }catch(e){
                            console.log(e)
                            this.respond({cmd:msg.cmd, value:"NOT_SEND", status: 1})
                            // resolve()
                            return
                        }
                    }else{
                        this.respond({cmd:msg.cmd, value:"COM_NOT_OPENED", status: 1})
                        // resolve()
                        return
                    }
                    break;
                
                case "close":
                    if(this.state == EXTENSION_STATE.PORT_RESERVED){
                        this.respond({cmd: msg.cmd, value:"FLASH_INPROGRESS", status: 1})
                        // resolve()
                        return
                    }
                    
                    if(this.state == EXTENSION_STATE.PORT_OPENED){
                        try{
                            await this.close_()
                            this.respond({cmd:msg.cmd, value:"SUCCESS", status: 0})
                            // resolve()
                            return
                        }catch(e){
                            this.respond({cmd:msg.cmd, value:"ERROR", status: 1})
                            // resolve()
                            return
                        }
                        
                    }else{
                        this.respond({cmd:msg.cmd, value:"COM_NOT_OPENED", status: 1})
                        // resolve()
                        return
                    }
                    break;
                case "search":
                    if(this.state == EXTENSION_STATE.PORT_RESERVED){
                        this.respond({cmd: msg.cmd, value:"FLASH_INPROGRESS", status: 1})
                        // resolve()
                        return
                    }
                    if(this.state == EXTENSION_STATE.PORT_OPENED){
                        this.respond({cmd: msg.cmd, value:"ALREADY_OPENED", status: 1})
                        // resolve()
                        return
                    }
                    if(this.ports.length == 1){
                        //TODO Thingz mini support: check vid/pid to verify if board is in bootloader
                        let bootloader = this.getProtocol(this.ports[0].getInfo()).bootloader
                        this.respond({cmd: msg.cmd, value: bootloader ? "IN_BOOTLOADER" : "FOUND", status: 0 })
                        // resolve()
                        return
                    }else if(this.ports.length == 0){
                        this.respond({cmd: msg.cmd, value:"NO_CARD", status: 1})
                        // resolve()
                        return
                    }else{
                        this.respond({cmd: msg.cmd, value:"MORE_THAN_ONE_CARD", status: 1})
                        // resolve()
                        return
                    }
                    break;
                case "getBricks":

                    if(this.state == EXTENSION_STATE.PORT_RESERVED){
                        this.respond({cmd: msg.cmd, value:"FLASH_INPROGRESS", status: 1})
                        return
                    }

                    if(this.state != EXTENSION_STATE.PORT_OPENED){
                        this.respond({cmd: msg.cmd, value:"NOT_OPENED", status: 1})
                        return
                    }

                    try{
                        const writer = this.getWriter();
                        let buf = new ArrayBuffer(4);
                        let bufView8 = new Uint8Array(buf);
                        bufView8[0] = "s".charCodeAt(0);
                        bufView8[1] = this.thingzProtocol.protocol.type.GET_BRICKS;
                        bufView8[2] = 1;
                        bufView8[3] = 1;
                        await writer.write(bufView8);
                        this.respond({cmd:msg.cmd, value:"SEND", status: 0})
                    }catch(e){
                        console.log(e)
                        this.respond({cmd:msg.cmd, value:"NOT_SEND", status: 1})
                        // resolve()
                        return
                    }
                    // resolve()
                    break;

                case "reset":
                    await this.reset()
                    this.respond({cmd: msg.cmd, status: 0})
                    // resolve()
                    break;
                default:
                    this.respond({cmd: msg.cmd, status: 0})
                    // resolve()
                    break;
            }
        // })

        // return new ExtensionCmd(promise, p_resolve, p_reject)
        
    }

    async postMessage(msg){
        console.log(msg)
        try{
            await this.handle_msg(msg)
            // this.cmds.push(cmd)
            // await cmd.promise
            // let index = 0;
            // for(let i = 0; i < this.cmds.length; i++){
            //     if(this.cmds[i] === cmd){
            //         index = i
            //     }
            // }
            // this.cmds.splice(index, 1)
        }catch(e){
            console.log(e)
            this.reset()
            this.respond({cmd: msg.cmd, value:"UNKNOWN_ERROR", status: 1})
        }
    }

}

let extensionWebSerial = new ExtensionWebSerial();
export {extensionWebSerial};