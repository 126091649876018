import axios from 'axios'
import {sessionService} from 'redux-react-session'
import runtimeEnv from '@mars/heroku-js-runtime-env';
import history from '../history'

const API_BASE_URL = 'https://compilation.thingz.co'

const client = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        Authorization: ''
    }
})



export const serverCompilationApi = {
    fetchLib,
}

function fetchLib(name) {
	return client.get(`/libs?name=`+name)
}