import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import {Online, Offline, Detector} from 'react-detect-offline';
import PropTypes from 'prop-types';

import runtimeEnv from '@mars/heroku-js-runtime-env';

import { Button, Grid, Icon, Popup } from 'semantic-ui-react';
import './HeaderActivityComponent.css';
import { SimulatorButton } from '../../containers/Simulator';

const HeaderActivityComponent = (props) => {
    function renderActivityTest(online) {
        let loading = props.compilationLoading ? true : false;
        let disabled = (!online || props.compilationLoading) ? true : false;
        let txt = "Téléverser"
        let txt_extended = "Envoyer le programme sur la carte"
        return (
            <Popup position="bottom right" trigger={<Button icon labelPosition='right' loading={loading} disabled={disabled} color='orange' size='medium' onClick={props.onCompileClick}><Icon name='rocket'/>{txt}</Button>}><Popup.Header>{txt}</Popup.Header>
                <Popup.Content>
                    {txt_extended}
                </Popup.Content>
            </Popup>
        ); 
    }

    function renderSimulatorButton(){
        return <SimulatorButton/>
    }

    function renderTryIt(){
        return <Popup trigger={<Button disabled={props.tryItDisabled}size="medium" color="blue" icon="bolt" content="Essayer l'activité" labelPosition="right" onClick={props.openActivity}/>}>
            Visualisez l'activité telle que la verront vos élèves 
        </Popup>
    }

    return(
        <Grid.Row className='header_acivity_container' columns='equal'>

            <Button onClick={() => props.history.goBack()} basic  icon="arrow left" style={{marginLeft:'20px'}}/>

            
            <Detector polling={{url: runtimeEnv().REACT_APP_CONNECTION_DETECTION_POOLING, enabled: true, interval: 6000}} render={({online}) => (
                <Grid.Column textAlign='right' style={{marginRight:'5px'}}>
                    {!props.consultation && <Popup trigger={<Button icon='trash alternate outline' size='medium' onClick={props.resetBlocklyRequest}/>}>Effacer les blocs</Popup>}
                    {!props.consultation && renderTryIt()}
                    {renderSimulatorButton()}
                    {renderActivityTest(online)}
                </Grid.Column>
            )}/>
        </Grid.Row>
    )
}

HeaderActivityComponent.propTypes = {
    history: PropTypes.shape({
        goBack: PropTypes.func.isRequired,
    }),
};

export default withRouter(HeaderActivityComponent);
