import runtimeEnv from '@mars/heroku-js-runtime-env';
import io from 'socket.io-client';

import {socketIoConstants} from "../constants";

export const socketIoActions = {
	init,
	close,
	addListener,
	removeListener
};

function init(){
	return (dispatch, getState) => {
		if(getState().socketIo.socket)
			return;
		let socket = io(runtimeEnv().REACT_APP_SOCKET_URL,
	 	{
		  //upgrade: false,
		  "query": "token="+getState().session.user.jwt,
		  withCredentials: true
		}
		);
		socket.on('connect', () => {
			console.log("Connected to server")
			dispatch({type: socketIoConstants.CONNECTED})
		})
		socket.on('disconnect', () => {
			dispatch({type: socketIoConstants.DISCONNECTED})
		})
		socket.on('thingzEvent', (data) => {
			const {listeners} = getState().socketIo
			for(let listener of listeners){
				if(listener(data))
					break;
			}
		})
		dispatch({type: socketIoConstants.INIT, socket})
	}
}

function close(){
	return (dispatch, getState) => {
		const socket = getState().socketIo.socket;
		if(socket){
			socket.disconnect();
		}
		dispatch({type: socketIoConstants.CLOSE})
	}
}

function addListener(listener){

	return (dispatch, getState) => {
		let listeners = [...getState().socketIo.listeners]
		if(!listeners.find(list => {
			return list === listener
		})){
			listeners.push(listener);
			dispatch({type: socketIoConstants.UPDATE_LISTENERS, listeners:listeners})
		}

	}
}

function removeListener(listener){
	return (dispatch, getState) => {
		let listeners = [...getState().socketIo.listeners]
		listeners = listeners.filter(list => {
			return list !== listener
		})
		dispatch({type: socketIoConstants.UPDATE_LISTENERS, listeners:listeners})
	}
}