import React, { Component } from 'react';
import { connect } from 'react-redux';

import ConnectionStatusComponent from '../../components/ConnectionStatusComponent/ConnectionStatusComponent'

import {extensionWebSerial} from '../../libs/ExtensionWebSerial';

import {
	extensionActions,
	serialMonitorActions
} from '../../actions';


class ConnectionStatus extends Component {

	getBoardStatus = () => {
		// if(this.props.galaxia){
		// 	switch(extensionWebSerial.status()){
		// 		case extensionWebSerial.OPEN:
		// 			return {color: "green", icon: "terminal", mainTitle: "Connecté à la carte", title:"Ta base fonctionne bien !", autoConnect: this.props.extension.autoConnect, autoOpen: this.props.serialMonitor.autoOpen, disableConnect: true, disableDisconnect: false};

		// 		default:
		// 			return {color: "red",  icon: "plug", mainTitle: "Appairer une carte", title:"Aucune base détectée. Si elle est bien branchée, rebranche-la et ce bouton redeviendra vert lorsqu'elle sera prête à être reprogrammée.", buttonMode: true};
		// 	}
		// }

		if(this.props.extension.isComOpen)
			return {color: "green", icon: "terminal", mainTitle: "Connecté à la carte", title:"Ta base fonctionne bien !", autoConnect: this.props.extension.autoConnect, autoOpen: this.props.serialMonitor.autoOpen, disableConnect: true, disableDisconnect: false, galaxia: this.props.galaxia, useWebSerial: this.props.extension.useWebSerial};
		if(this.props.extension.boardStatus === "IN_BOOTLOADER")
			return {color: "green", icon: "lightning", mainTitle: "Connecté à la carte", title:"Ta base est en phase de démarrage durant 8 secondes. Tu peux quand même la reprogrammer.", autoConnect: this.props.extension.autoConnect, autoOpen: this.props.serialMonitor.autoOpen, disableConnect: true, disableDisconnect: true, galaxia: this.props.galaxia, useWebSerial: this.props.extension.useWebSerial};
		if(this.props.extension.boardStatus === "FOUND" || this.props.extension.boardStatus === "CANT_CONNECT")
			return {color: "green", icon: "plug", mainTitle: "Connecté à la carte", title:"Ta base fonctionne bien ! Si tu veux utiliser le moniteur série, connecte-la.", autoConnect: this.props.extension.autoConnect, autoOpen: this.props.serialMonitor.autoOpen, disableConnect: false, disableDisconnect: true, galaxia: this.props.galaxia, useWebSerial: this.props.extension.useWebSerial};

		if(this.props.extension.useWebSerial){
			return {color: "red",  icon: "plug", mainTitle: "Appairer une carte", title:"Ajoute une carte à l'interface. Branche ta carte puis clique sur ce bouton", buttonMode: true, galaxia: this.props.galaxia, useWebSerial: this.props.extension.useWebSerial};
		}
		return {color: "red",  icon: "plug", mainTitle: "Déconnecté de la carte", title:"Aucune base détectée. Si elle est bien branchée, rebranche-la et ce bouton redeviendra vert lorsqu'elle sera prête à être reprogrammée.", autoConnect: this.props.extension.autoConnect, autoOpen: this.props.serialMonitor.autoOpen, disableConnect: true, disableDisconnect: true, galaxia: this.props.galaxia, useWebSerial: this.props.extension.useWebSerial};
	}

	onPair = () => {
		extensionWebSerial.askNewPermision(this.props.galaxia ? ["galaxia"] : ["uno", "mini", "mega"])
	}

	onBoardDisconnect = () => {
		this.props.dispatch(extensionActions.close())
	}

	onBoardConnect = () => {
		this.props.dispatch(extensionActions.open(this.props.galaxia))
	}

	onBoardAutoConnect = () => {
		this.props.dispatch(extensionActions.toggleAutoConnect())
	}

	onMonitorAutoOpen = () => {
		this.props.dispatch(serialMonitorActions.toggleAutoOpen())	
	}

	onWebSerialToggle = () => {
		this.props.dispatch(extensionActions.toggleWebSerial())	
	}

	render(){
		const boardStatus = this.getBoardStatus();
		return (
			<ConnectionStatusComponent 
				boardStatus={boardStatus} 
				onPair={this.onPair}
				onConnect={this.onBoardConnect}
				onDisconnect={this.onBoardDisconnect} 
				onAutoConnect={this.onBoardAutoConnect} 
				onAutoOpen={this.onMonitorAutoOpen}
				onWebSerialToggle={this.onWebSerialToggle}
				notification={this.props.notification} 
				user={this.props.user}
			/>
		)
	}
}

function mapStateToProps(state) {
	return {
		extension: state.extension,
		
		serialMonitor: state.serialMonitor
	}
}

const connectedConnectionStatus = connect(mapStateToProps)(ConnectionStatus)
export { connectedConnectionStatus as ConnectionStatus }