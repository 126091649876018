import axios from 'axios'
import {sessionService} from 'redux-react-session'
import runtimeEnv from '@mars/heroku-js-runtime-env';
import history from '../history'

const API_BASE_URL = runtimeEnv().REACT_APP_API_BASE_URL

const client = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: ''
    }
})

client.interceptors.request.use(async function (config) {
    try{
	    const jwt = await sessionService.loadUser()
	    if(jwt)
	        config.headers.Authorization = 'Bearer ' + jwt.jwt
	    // console.log('JWT', jwt)
  	}catch(e){
  		return config
  	}
    return config
}, function (error) {
		return Promise.reject(error)
})

client.interceptors.response.use(null, function(error){
    if(error.response.data.statusCode === 403 && error.response.data.message && error.response.data.message.logout)
        history.push("/logout");
    else
        return Promise.reject(error);
})

export const AppApi = {
    create,
    save,
    getAll,
    getOne,
    clone,
    destroy,
};

function create(app) {
	return client.post(`/app`, app);
}

function save(app) {
    return client.put(`/app/${app.id}`, app);
}

function getAll() {
    return client.get(`/app`);
}

function getOne(id) {
    return client.get(`/app/${id}`);
}

function clone(app) {
    return client.post('/app/clone', app);
}

function destroy(id) {
    return client.delete(`/app/${id}`);
}