export const coursePageConstants = {
	ANIMATION_STARTED: "COURSE_PAGE_ANIMATION_STARTED",
	ANIMATION_DONE: "COURSE_PAGE_ANIMATION_DONE",
    SAVE_PROGRESSION_XML: "COURSE_PAGE_SAVE_PROGRESSION_XML",
    SAVE_STEP_XML: "COURSE_PAGE_SAVE_STEP_XML",
	UPDATE_LAST_TESTED_CODE: "COURSE_PAGE_UPDATE_LAST_TESTED_CODE",
    RESET_TO_PREVIOUS_XML: "COURSE_PAGE_RESET_TO_PREVIOUS_XML",
    RESET_TO_PREVIOUS_XML_DONE: "COURSE_PAGE_RESET_TO_PREVIOUS_XML_DONE",
	CHECK_BLOCKLY_ERROR: {
        BLOCK_TYPE_ERROR: "BLOCK_TYPE_ERROR",
        CHILD_LENGTH_ERROR: "CHILD_LENGTH_ERROR",
        NULL_NODE_ERROR: "NULL_NODE_ERROR",
        NODE_NAME_ERROR: "NODE_NAME_ERROR",
        CONNEXION_NAME_ERROR: "CONNEXION_NAME_ERROR",
        NAME_ATTRIBUTE_ERROR: "NAME_ATTRIBUTE_ERROR",
        BLOCKY_UNIQUE_ID_ERROR: "BLOCKY_UNIQUE_ID_ERROR",
        VAR_UNIQUE_ID_ERROR: "VAR_UNIQUE_ID_ERROR",
        NOT_BLOCK_ERROR: "NOT_BLOCK_ERROR",
        UNKNOW_ERROR: "UNKNOW_ERROR",
        EMPTY_CHILDREN_ERROR: "EMPTY_CHILDREN_ERROR",
        COMBINATED_BLOCK_ERROR:"COMBINATED_BLOCK_ERROR"
    }
}