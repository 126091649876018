import React, { Component } from 'react';
import { Button, Modal, Icon, Checkbox, List } from 'semantic-ui-react';

import control_wait from '../../img/captures_blocks/control_wait.png';
import control_repeat_until from '../../img/captures_blocks/control_repeat_until.png';
import control_if from '../../img/captures_blocks/control_if.png';
import control_if_else from '../../img/captures_blocks/control_if_else.png';
import operator_equals from '../../img/captures_blocks/operator_equals.png'
import operator_lt from '../../img/captures_blocks/operator_lt.png';
import operator_lt_equals from '../../img/captures_blocks/operator_lt_equals.png';
import operator_gt from '../../img/captures_blocks/operator_gt.png';
import operator_gt_equals from '../../img/captures_blocks/operator_gt_equals.png';
import operator_not_equals from '../../img/captures_blocks/operator_not_equals.png';
import operator_and from '../../img/captures_blocks/operator_and.png';
import operator_or from '../../img/captures_blocks/operator_or.png';
import operator_not from '../../img/captures_blocks/operator_not.png';
import control_while from '../../img/captures_blocks/control_while.png';
import control_repeat from '../../img/captures_blocks/control_repeat.png';
import operator_random from '../../img/captures_blocks/operator_random.png';
import operator_add from '../../img/captures_blocks/operator_add.png';
import operator_subtract from '../../img/captures_blocks/operator_subtract.png';
import operator_multiply from '../../img/captures_blocks/operator_multiply.png';
import operator_divide from '../../img/captures_blocks/operator_divide.png';
import variable from '../../img/captures_blocks/variable.png';
import mesBlocs from '../../img/captures_blocks/mes_blocs.png';
import led_standard from '../../img/captures_blocks/led_standard.png';
import led_change from '../../img/captures_blocks/led_change.png';
import timed_led from '../../img/captures_blocks/timed_led.png';
import button from '../../img/captures_blocks/button.png';
import block_number_on from '../../img/captures_blocks/block_number_on.png';
import block_number_off from '../../img/captures_blocks/block_number_off.png';
import screen_msg from '../../img/captures_blocks/screen_msg.png';
import screen_OnOff from '../../img/captures_blocks/screen_OnOff.png';
import screen_delete_line from '../../img/captures_blocks/screen_delete_line.png';
import text_concatenation from '../../img/captures_blocks/text_concatenation.png';
import piezo_buzzer from '../../img/captures_blocks/piezo_buzzer.png';
import timed_buzzer from '../../img/captures_blocks/timed_buzzer.png';
import buzzer_notes from '../../img/captures_blocks/buzzer_notes.png';
import potentiometer_brick from '../../img/captures_blocks/potentiometer_brick.png';
import makey_makey_brick from '../../img/captures_blocks/makey_makey_brick.png';
import tactile_brick from '../../img/captures_blocks/tactile_brick.png';
import infrared from '../../img/captures_blocks/infrared.png';
import infra_bool from '../../img/captures_blocks/infra_bool.png';
import temperature_brick from '../../img/captures_blocks/temperature_brick.png';
import brightness from '../../img/captures_blocks/brightness.png';
import bluetooth_connection from '../../img/captures_blocks/bluetooth_connection.png';
import bluetooth_ok_connection from '../../img/captures_blocks/bluetooth_ok_connection.png';
import bluetooth_sendto from '../../img/captures_blocks/bluetooth_sendto.png';
import bluetooth_receivefrom from '../../img/captures_blocks/bluetooth_receivefrom.png';
import bluetooth_dataAvailable from '../../img/captures_blocks/bluetooth_dataAvailable.png';
import relay_brick from '../../img/captures_blocks/relay_brick.png';
import motion_detector from '../../img/captures_blocks/motion_detector.png';
import serial_monitor_print from '../../img/captures_blocks/serial_monitor_print.png'; 
import serial_monitor_print_new_line from '../../img/captures_blocks/serial_monitor_print_new_line.png'; 
import serial_monitor_available from '../../img/captures_blocks/serial_monitor_available.png'; 
import serial_monitor_read from '../../img/captures_blocks/serial_monitor_read.png'; 
import serial_monitor_read_string from '../../img/captures_blocks/serial_monitor_read_string.png'; 
import thingz_keyboard_keys from '../../img/captures_blocks/thingz_keyboard_keys.png';
import thingz_keyboard_letters from '../../img/captures_blocks/thingz_keyboard_letters.png';
import wifi_connect_network from '../../img/captures_blocks/wifi_connect_network.png';
import wifi_is_connect from '../../img/captures_blocks/wifi_is_connect.png';
import wifi_send_data from '../../img/captures_blocks/wifi_send_data.png';
import color_screen_print from '../../img/captures_blocks/color_screen_print.png';
import color_screen_println from '../../img/captures_blocks/color_screen_println.png';
import color_screen_clear from '../../img/captures_blocks/color_screen_clear.png';
import color_screen_rect from '../../img/captures_blocks/color_screen_rect.png';
import color_screen_circle from '../../img/captures_blocks/color_screen_circle.png';
import color_screen_is_out_of_screen from '../../img/captures_blocks/color_screen_is_out_of_screen.png';

import _ from 'lodash';

import './ToolboxActivityComponent.css';

class ToolboxActivityComponent extends Component {
    constructor(props) {
        super(props)
        this.renderContent = this.renderContent.bind(this)
        this.handleClickCategory = this.handleClickCategory.bind(this)
        this.handleClickBlock = this.handleClickBlock.bind(this)
        this.compileXml = this.compileXml.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
        this.handleClose = this.handleClose.bind(this)
        
        this.state = {
            modalOpen: false,
            isChange: false,
            xml: [{
                name: "Conditions",
                xml: '<category name="%{BKY_CATEGORY_CONTROL}" id="control" colour="#FFAB19" secondaryColour="#CF8B17">',
                checked: 'false',
                blocks: [
                    {
                        name: 'control_wait',
                        imgName: control_wait,
                        checked: 'false',
                        xml: '<block type="control_wait" id="control_wait">' +
                            '<value name="DURATION">' +
                            '<shadow type="math_positive_number_block">' +
                            '<field name="NUM">1</field>' +
                            '</shadow>' +
                            '</value>' +
                            '</block> '
                    },
                    {
                        name: 'control_if',
                        imgName: control_if,
                        checked: 'false',
                        xml: '<block type="control_if" id="control_if"></block>'
                    },
                    {
                        name: 'control_if_else',
                        imgName: control_if_else,
                        checked: 'false',
                        xml: '<block type="control_if_else" id="control_if_else"></block>'
                    },
                    {
                        name: 'operator_lt',
                        imgName: operator_lt,
                        checked: 'false',
                        xml: '<block type="operator_lt" id="operator_lt">' +
                            '<value name="OPERAND1">' +
                            '<shadow type="math_number_block">' +
                            '<field name="NUM">0</field>' +
                            '</shadow>' +
                            '</value>' +
                            '<value name="OPERAND2">' +
                            '<shadow type="math_number_block">' +
                            '<field name="NUM">0</field>' +
                            '</shadow>' +
                            '</value>' +
                            '</block>'
                    },
                    {
                        name: 'operator_lt_equals',
                        imgName: operator_lt_equals,
                        checked: 'false',
                        xml: '<block type="operator_lt_equals" id="operator_lt_equals">' +
                            '<value name="OPERAND1">' +
                            '<shadow type="math_number_block">' +
                            '<field name="NUM">0</field>' +
                            '</shadow>' +
                            '</value>' +
                            '<value name="OPERAND2">' +
                            '<shadow type="math_number_block">' +
                            '<field name="NUM">0</field>' +
                            '</shadow>' +
                            '</value>' +
                            '</block>'
                    },
                    {
                        name: 'operator_equals',
                        imgName: operator_equals,
                        checked: 'false',
                        xml: '<block type="operator_equals" id="operator_equals">' +
                            '<value name="OPERAND1">' +
                            '<shadow type="text_brick">' +
                            '<field name="TEXT">0</field>' +
                            '</shadow>' +
                            '</value>' +
                            '<value name="OPERAND2">' +
                            '<shadow type="text_brick">' +
                            '<field name="TEXT">0</field>' +
                            '</shadow>' +
                            '</value>' +
                            '</block>'
                    },
                    {
                        name: 'operator_gt_equals',
                        imgName: operator_gt_equals,
                        checked: 'false',
                        xml: '<block type="operator_gt_equals" id="operator_gt_equals">' +
                            '<value name="OPERAND1">' +
                            '<shadow type="math_number_block">' +
                            '<field name="NUM">0</field>' +
                            '</shadow>' +
                            '</value>' +
                            '<value name="OPERAND2">' +
                            '<shadow type="math_number_block">' +
                            '<field name="NUM">0</field>' +
                            '</shadow>' +
                            '</value>' +
                            '</block>'
                    },
                    {
                        name: 'operator_gt',
                        imgName: operator_gt,
                        checked: 'false',
                        xml: '<block type="operator_gt" id="operator_gt">' +
                            '<value name="OPERAND1">' +
                            '<shadow type="math_number_block">' +
                            '<field name="NUM">0</field>' +
                            '</shadow>' +
                            '</value>' +
                            '<value name="OPERAND2">' +
                            '<shadow type="math_number_block">' +
                            '<field name="NUM">0</field>' +
                            '</shadow>' +
                            '</value>' +
                            '</block>'
                    },
                    {
                        name: 'operator_not_equals',
                        imgName: operator_not_equals,
                        checked: 'false',
                        xml: '<block type="operator_not_equals" id="operator_not_equals">' +
                            '<value name="OPERAND1">' +
                            '<shadow type="text_brick">' +
                            '<field name="TEXT">0</field>' +
                            '</shadow>' +
                            '</value>' +
                            '<value name="OPERAND2">' +
                            '<shadow type="text_brick">' +
                            '<field name="TEXT">0</field>' +
                            '</shadow>' +
                            '</value>' +
                            '</block>'
                    },
                    {
                        name: 'operator_and',
                        imgName: operator_and,
                        checked: 'false',
                        xml: '<block type="operator_and" id="operator_and"></block>'
                    },
                    {
                        name: 'operator_or',
                        imgName: operator_or,
                        checked: 'false',
                        xml: '<block type="operator_or" id="operator_or"></block>'
                    },
                    {
                        name: 'operator_not',
                        imgName: operator_not,
                        checked: 'false',
                        xml: '<block type="operator_not" id="operator_not"></block>'
                    },
                ]
            },
            {
                name: 'Boucles',
                checked: 'false',
                xml: '<category name="%{BKY_CATEGORY_LOOPS}"  id="loops" colour="#FFAB19" secondaryColour="#CF8B17">',
                blocks: [
                    {
                        name: 'control_while',
                        imgName: control_while,
                        checked: 'false',
                        xml: '<block type="control_while" id ="control_while"></block>'
                    },
                    {
                        name: 'control_repeat',
                        imgName: control_repeat,
                        checked: 'false',
                        xml: '<block type="control_repeat" id="control_repeat">' +
                            '<value name="TIMES">' +
                            '<shadow type="math_whole_number_block">' +
                            '<field name="NUM">10</field>' +
                            '</shadow>' +
                            '</value>' +
                            '</block>'
                    },
                    {
                        name: 'control_repeat_until',
                        imgName: control_repeat_until,
                        checked: 'false',
                        xml: '<block type="control_repeat_until" id="control_repeat_until"></block>'
                    },
                ]
            },
            {
                name: 'Nombres',
                checked: 'false',
                xml: '<category name="%{BKY_CATEGORY_NUMBERS}"  id="number" colour="#59C059" secondaryColour="#389438">',
                blocks: [
                    {
                        name: 'operator_random',
                        imgName: operator_random,
                        checked: 'false',
                        xml: '<block type="operator_random" id="operator_random">' +
                            '<value name="FROM">' +
                            '<shadow type="math_number_block">' +
                            '<field name="NUM">1</field>' +
                            '</shadow>' +
                            '</value>' +
                            '<value name="TO">' +
                            '<shadow type="math_number_block">' +
                            '<field name="NUM">10</field>' +
                            '</shadow>' +
                            '</value>' +
                            '</block>'
                    },
                    {
                        name: 'operator_add',
                        imgName: operator_add,
                        checked: 'false',
                        xml: '<block type="operator_add" id="operator_add">' +
                            '<value name="NUM1">' +
                            '<shadow type="math_number_block">' +
                            '<field name="NUM">0</field>' +
                            '</shadow>' +
                            '</value>' +
                            '<value name="NUM2">' +
                            '<shadow type="math_number_block">' +
                            '<field name="NUM">0</field>' +
                            '</shadow>' +
                            '</value>' +
                            '</block>'
                    },
                    {
                        name: 'operator_subtract',
                        imgName: operator_subtract,
                        checked: 'false',
                        xml: '<block type="operator_subtract" id="operator_subtract">' +
                            '<value name="NUM1">' +
                            '<shadow type="math_number_block">' +
                            '<field name="NUM">0</field>' +
                            '</shadow>' +
                            '</value>' +
                            '<value name="NUM2">' +
                            '<shadow type="math_number_block">' +
                            '<field name="NUM">0</field>' +
                            '</shadow>' +
                            '</value>' +
                            '</block>'
                    },
                    {
                        name: 'operator_multiply',
                        imgName: operator_multiply,
                        checked: 'false',
                        xml: '<block type="operator_multiply" id="operator_multiply">' +
                            '<value name="NUM1">' +
                            '<shadow type="math_number_block">' +
                            '<field name="NUM">0</field>' +
                            '</shadow>' +
                            '</value>' +
                            '<value name="NUM2">' +
                            '<shadow type="math_number_block">' +
                            '<field name="NUM">0</field>' +
                            '</shadow>' +
                            '</value>' +
                            '</block>'
                    },
                    {
                        name: 'operator_divide',
                        imgName: operator_divide,
                        checked: 'false',
                        xml: '<block type="operator_divide" id="operator_divide">' +
                            '<value name="NUM1">' +
                            '<shadow type="math_number_block">' +
                            '<field name="NUM">0</field>' +
                            '</shadow>' +
                            '</value>' +
                            '<value name="NUM2">' +
                            '<shadow type="math_number_block">' +
                            '<field name="NUM">0</field>' +
                            '</shadow>' +
                            '</value>' +
                            '</block>'
                    }
                ]
            },
            {
                name: 'Variables',
                imgName: variable,
                checked: 'false',
                xml: '<category name="%{BKY_CATEGORY_VARIABLES}" id="data" colour="#FF8C1A" secondaryColour="#DB6E00" custom="VARIABLE">',
                blocks: [],
            },
            {
                name: 'Mes blocs',
                imgName: mesBlocs,
                checked: 'false',
                xml: '<category name="%{BKY_CATEGORY_MYBLOCKS}" id="more" colour="#FF6680" secondaryColour="#FF4D6A" custom="PROCEDURE">',
                blocks: []
            },
            {
                name: 'Led',
                checked: 'false',
                xml: '<category name="%{BKY_LED_CATEGORY_NAME}"  id="led" colour="#9966FF" secondaryColour="#774DCB">',
                blocks: [
                    {
                        name: 'led_standard',
                        imgName: led_standard,
                        checked: 'false',
                        xml: '<block type="led_standard" id="classic_led"></block>',
                    },
                    {
                        name: 'led_change',
                        imgName: led_change,
                        checked: 'false',
                        xml: '<block type="led_change" id="led_change"></block>'
                    },
                    {
                        name: 'timed_led',
                        imgName: timed_led,
                        checked: 'false',
                        xml: '<block type="timed_led" id="timed_led">' +
                            '<value name="DURATION">' +
                            '<shadow type="math_number_block">' +
                            '<field name="NUM">1</field>' +
                            '</shadow>' +
                            '</value>' +
                            '</block>'
                    }
                ]
            },
            {
                name: 'Bouton',
                checked: 'false',
                xml: '<category name="%{BKY_BUTTON_CATEGORY_NAME}" id="button" colour="#5CB1D6" secondaryColour="#2E8EB8">',
                blocks: [
                    {
                        name: 'button',
                        imgName: button,
                        checked: 'false',
                        xml: '<block type="button" id="button_block"></block>'
                    }
                ]
            },
            {
                name: 'Bloc Chiffre',
                checked: 'false',
                xml: '<category name="%{BKY_CATEGORY_BLOCK_NUMBER}" id="test_blocknumber" colour="#9966FF" secondaryColour="#774DCB">',
                blocks: [
                    {
                        name: 'block_number_on',
                        imgName: block_number_on,
                        checked: 'false',
                        xml: '<block type="block_number_on" id="block_number_on">'+
                            '<value name="NB">' +
                            '<shadow type="math_number_block">' +
                            '<field name="NUM">0</field>' +
                            '</shadow>' +
                            '</value>' +
                            '</block>'
                    },
                    {
                        name: 'block_number_off',
                        imgName: block_number_off,
                        checked: 'false',
                        xml: '<block type="block_number_off" id="block_number_off"></block>'
                    }
                ]
            },
            {
                name: 'Ecran',
                checked: 'false',
                xml: '<category name="%{BKY_CATEGORY_SCREEN}" id="screen" colour="#9966FF" secondaryColour="#774DCB">',
                blocks: [
                    {
                        name: 'screen_msg',
                        imgName: screen_msg,
                        checked: 'false',
                        xml: '<block type="screen_msg" id="screen_msg">'+
                            '<value name="VALEUR">' +
                            '<shadow type="text_brick">' +
                            '<field name="TEXT"></field>' +
                            '</shadow>' +
                            '</value>' +
                            '</block>'
                    },
                    {
                        name: 'screen_OnOff',
                        imgName: screen_OnOff,
                        checked: 'false',
                        xml: '<block type="screen_OnOff" id="screen_OnOff"></block>'
                    },
                    {
                        name: 'screen_delete_line',
                        imgName: screen_delete_line,
                        checked: 'false',
                        xml:     '<block type="screen_delete_line" id="screen_delete_line"></block>'
                    },
                    {
                        name: 'text_concatenation',
                        imgName: text_concatenation,
                        checked: 'false',
                        xml: '<block type="text_concatenation" id="text_concatenation">'+
                            '<value name="STR1">' +
                            '<shadow type="text_brick">' +
                            '<field name="TEXT"></field>' +
                            '</shadow>' +
                            '</value>' +
                            '<value name="STR2">' +
                            '<shadow type="text_brick">' +
                            '<field name="TEXT"></field>' +
                            '</shadow>' +
                            '</value>' +
                        '</block>'
                    }
                ]
            },
                        {
                name: 'Ecran couleur',
                checked: 'false',
                xml: '<category name="%{BKY_CATEGORY_COLORED_SCREEN}" id="colored_screen" colour="#9966FF" secondaryColour="#774DCB">',
                blocks: [
                    {
                        name: 'color_screen_print',
                        imgName: color_screen_print,
                        checked: 'false',
                        xml: '<block type ="color_screen_print" id="color_screen_print">'+
                              '<value name="VALEUR">' +
                                '<shadow type="text_brick">' +
                                  '<field name="TEXT"></field>' +
                                '</shadow>' +
                              '</value>' +
                              '<value name="color">' +
                                '<shadow type="colour_picker"></shadow>'+
                              '</value>' +
                            '</block>'
                    },
                    {
                        name: 'color_screen_println',
                        imgName: color_screen_println,
                        checked: 'false',
                        xml: '<block type ="color_screen_println" id="color_screen_println">'+
                              '<value name="VALEUR">' +
                                '<shadow type="text_brick">' +
                                  '<field name="TEXT"></field>' +
                                '</shadow>' +
                              '</value>' +
                              '<value name="color">' +
                                '<shadow type="colour_picker"></shadow>'+
                              '</value>' +
                            '</block>'
                    },
                    {
                        name: 'color_screen_clear',
                        imgName: color_screen_clear,
                        checked: 'false',
                        xml: '<block type ="color_screen_clear" id="color_screen_clear">'+
                              '<value name="color">' +
                                '<shadow type="colour_picker"></shadow>'+
                              '</value>' +
                            '</block>'
                    },
                    {
                        name: 'color_screen_rect',
                        imgName: color_screen_rect,
                        checked: 'false',
                        xml: '<block type ="color_screen_rect" id="color_screen_println">'+
                              '<value name="x">' +
                                '<shadow type="math_number_block">' +
                                  '<field name="NUM">0</field>' +
                                '</shadow>' +
                              '</value>' +
                              '<value name="y">' +
                                '<shadow type="math_number_block">' +
                                  '<field name="NUM">0</field>' +
                                '</shadow>' +
                              '</value>' +
                              '<value name="width">' +
                                '<shadow type="math_number_block">' +
                                  '<field name="NUM">10</field>' +
                                '</shadow>' +
                              '</value>' +
                              '<value name="height">' +
                                '<shadow type="math_number_block">' +
                                  '<field name="NUM">10</field>' +
                                '</shadow>' +
                              '</value>' +
                              '<value name="color">' +
                                '<shadow type="colour_picker"></shadow>'+
                              '</value>' +
                            '</block>'
                    },
                    {
                        name: 'color_screen_circle',
                        imgName: color_screen_circle,
                        checked: 'false',
                        xml: '<block type ="color_screen_circle" id="color_screen_println">'+
                              '<value name="x">' +
                                '<shadow type="math_number_block">' +
                                  '<field name="NUM">0</field>' +
                                '</shadow>' +
                              '</value>' +
                              '<value name="y">' +
                                '<shadow type="math_number_block">' +
                                  '<field name="NUM">0</field>' +
                                '</shadow>' +
                              '</value>' +
                              '<value name="radius">' +
                                '<shadow type="math_number_block">' +
                                  '<field name="NUM">10</field>' +
                                '</shadow>' +
                              '</value>' +
                              '<value name="color">' +
                                '<shadow type="colour_picker"></shadow>'+
                              '</value>' +
                            '</block>'
                    },
                    {
                        name: 'color_screen_is_out_of_screen',
                        imgName: color_screen_is_out_of_screen,
                        checked: 'false',
                        xml: '<block type ="color_screen_is_out_of_screen" id="color_screen_is_out_of_screen"/>'
                    },
                    {
                        name: 'text_concatenation',
                        imgName: text_concatenation,
                        checked: 'false',
                        xml: '<block type="text_concatenation" id="text_concatenation">'+
                            '<value name="STR1">' +
                            '<shadow type="text_brick">' +
                            '<field name="TEXT"></field>' +
                            '</shadow>' +
                            '</value>' +
                            '<value name="STR2">' +
                            '<shadow type="text_brick">' +
                            '<field name="TEXT"></field>' +
                            '</shadow>' +
                            '</value>' +
                        '</block>'
                    }
                ]
            },
            {
                name: 'Buzzer',
                checked: 'false',
                xml: '<category name="%{BKY_CATEGORY_BUZZER}" id="buzzer" colour="#CF63CF" secondaryColour="#BD42BD">',
                blocks: [
                    {
                        name: 'piezo_buzzer',
                        imgName: piezo_buzzer,
                        checked: 'false',
                        xml: '<block type="piezo_buzzer" id="piezo_buzzer">' +
                            '<value name="NOTE">' +
                            '<shadow type="math_number_block">' +
                            '<field name="NUM">450</field>' +
                            '</shadow>' +
                            '</value>' +
                            '</block>'
                    },
                    {
                        name: 'timed_buzzer',
                        imgName: timed_buzzer,
                        checked: 'false',
                        xml: '<block type="timed_buzzer" id="timed_buzzer">' +
                            '<value name="NOTE">' +
                            '<shadow type="math_number_block">' +
                            '<field name="NUM">450</field>' +
                            '</shadow>' +
                            '</value>' +
                            '<value name="DURATION">' +
                            '<shadow type="math_number_block">' +
                            '<field name="NUM">1</field>' +
                            '</shadow>' +
                            '</value>' +
                            '</block>'
                    },
                    {
                        name: 'buzzer_notes',
                        imgName: buzzer_notes,
                        checked: 'false',
                        xml: '<block type="buzzer_notes" id="buzzer_notes"></block>'
                    }
                ]
            },
            {
                name: 'Potentiomètre',
                checked: 'false',
                xml: '<category name="%{BKY_CATEGORY_POTENTIOMETER}" id="potentiometer" colour="#6699FF" secondaryColour="#774DCB">',
                blocks: [
                    {
                        name: 'potentiometer_brick',
                        imgName: potentiometer_brick,
                        checked: 'false',
                        xml: '<block type="potentiometer_brick" id="potentiometer"></block>'
                    }
                ]
            },
            {
                name: 'Tactile',
                checked: 'false',
                xml: '<category name="%{BKY_CATEGORY_MAKEY_MAKEY}" id="cat_makey_makey" colour="#5CB1D6" secondaryColour="#2E8EB8">',
                blocks: [
                    {
                        name: 'makey_makey_brick',
                        imgName: tactile_brick,
                        checked: 'false',
                        xml: '<block type="makey_makey_brick" id="makey_makey"></block>'
                    }
                ]
            },
            {
                name: 'Infrarouge',
                checked: 'false',
                xml: '<category name="%{BKY_CATEGORY_INFRARED}" id="infra" colour="#5CB1D6" secondaryColour="#2E8EB8">',
                blocks: [
                    {
                        name: 'infrared',
                        imgName: infrared,
                        checked: 'false',
                        xml: '<block type="infrared" id="infrared"></block>'
                    },
                    {
                        name: 'infra_bool',
                        imgName: infra_bool,
                        checked: 'false',
                        xml: '<block type="infra_boolean" id="infra_boolean"></block>'
                    }
                ]
            },
            {
                name: 'Météo',
                checked: 'false',
                xml: '<category name="%{BKY_CATEGORY_WEATHER}" id="weather" colour="#5CB1D6" secondaryColour="#2E8EB8">',
                blocks: [
                    {
                        name: 'temperature_brick',
                        imgName: temperature_brick,
                        checked: 'false',
                        xml: '<block type="temperature_brick" id="temperature_brick"></block>'
                    }
                ]
            },
            {
                name: 'Luminosité',
                checked: 'false',
                xml: '<category name="%{BKY_CATEGORY_BRIGHTNESS}" id="luminosity" colour="#5CB1D6" secondaryColour="#2E8EB8">',
                blocks: [
                    {
                        name: 'brightness',
                        imgName: brightness,
                        checked: 'false',
                        xml: '<block type="brightness" id="brightness"></block>'
                    }]
            },
            {
                name: 'Bluetooth',
                checked: 'false',
                xml: '<category name="%{BKY_CATEGORY_BLUETOOTH}" id="bluetooth" colour="#FF8C1A" secondaryColour="#DB6E00">',
                blocks: [
                    {
                        name: 'bluetooth_connection',
                        imgName: bluetooth_connection,
                        checked: 'false',
                        xml: '<block type="bluetooth_connection" id="bluetooth_connection">'+
                            '<value name="RESEAU">' +
                            '<shadow type="text_brick">' +
                            '<field name="TEXT">ThingzBT</field>' +
                            '</shadow>' +
                            '</value>' +
                            '</block>'
                    },
                    {
                        name: 'bluetooth_ok_connection',
                        imgName: bluetooth_ok_connection,
                        checked: 'false',
                        xml: '<block type="bluetooth_ok_connection" id="bluetooth_ok_connection">'+
                            '<value name="RESEAU">' +
                            '<shadow type="text_brick">' +
                            '<field name="TEXT">ThingzBT</field>' +
                            '</shadow>' +
                            '</value>' +
                            '</block>'
                    },
                    {
                        name: 'bluetooth_sendto',
                        imgName: bluetooth_sendto,
                        checked: 'false',
                        xml: '<block type="bluetooth_sendto" id="bluetooth_sendto">'+
                            '<value name="DATA">' +
                            '<shadow type="text_brick">' +
                            '<field name="TEXT"></field>' +
                            '</shadow>' +
                            '</value>' +
                            '</block>'
                    },
                    {
                        name: 'bluetooth_receivefrom',
                        imgName: bluetooth_receivefrom,
                        checked: 'false',
                        xml: '<block type="bluetooth_receivefrom" id="bluetooth_recievefrom"></block>'
                    },
                    {
                        name: 'bluetooth_dataAvailable',
                        imgName: bluetooth_dataAvailable,
                        checked: 'false',
                        xml: '<block type="bluetooth_dataAvailable" id="bluetooth_dataAliavable"></block>'
                    }
                ]
            },
            {
                name: 'Prise radiocommandée',
                checked: 'false',
                xml: '<category name="%{BKY_CATEGORY_RELAY}" id="relay" colour="#FF8C1A" secondaryColour="#DB6E00">',
                blocks: [
                    {
                        name: 'relay_brick',
                        imgName: relay_brick,
                        checked: 'false',
                        xml: '<block type="relay_brick" id="relay_brick"></block>'

                    }
                ]
            },
            {
                name: 'Détecteur de mouvements',
                checked: 'false',
                xml: '<category name="%{BKY_CATEGORY_MOTION_DETECTOR}" id="cat_motion_detector" colour="#5CB1D6" secondaryColour="#2E8EB8">',
                blocks: [ 
                    {
                        name: 'motion_detector',
                        imgName: motion_detector,
                        checked: 'false',
                        xml: '<block type="motion_detector" id="motion_detector"></block>'
                    }
                ]
            },
            {
                name: 'Moniteur série',
                checked: 'false',
                xml: '<category name="%{BKY_CATEGORY_SERIAL_MONITOR}" id="serial_monitor" colour="#FF8C1A" secondaryColour="#DB6E00">',
                blocks: [
                    {
                        name: 'serial_monitor_print_new_line',
                        imgName: serial_monitor_print_new_line,
                        checked: 'false',
                        xml: '<block type="serial_monitor_print_new_line" id="serial_monitor_print_new_line">'+
                            '<value name="ARG">' +
                            '<shadow type="text_brick">' +
                            '<field name="TEXT"></field>' +
                            '</shadow>' +
                            '</value>' +
                            '</block>'
                    },
                    {
                        name: 'serial_monitor_print',
                        imgName: serial_monitor_print,
                        checked: 'false',
                        xml: '<block type="serial_monitor_print" id="serial_monitor_print">'+
                            '<value name="ARG">' +
                            '<shadow type="text_brick">' +
                            '<field name="TEXT"></field>' +
                            '</shadow>' +
                            '</value>' +
                            '</block>'
                    },
                    {
                        name: 'serial_monitor_available',
                        imgName: serial_monitor_available,
                        checked: 'false',
                        xml: '<block type="serial_monitor_available" id="serial_monitor_available"></block>'
                    },
                    {
                        name: 'serial_monitor_read',
                        imgName: serial_monitor_read,
                        checked: 'false',
                        xml: '<block type="serial_monitor_read" id="serial_monitor_read"></block>'
                    },
                    {
                        name: 'serial_monitor_read_string',
                        imgName: serial_monitor_read_string,
                        checked: 'false',
                        xml: '<block type="serial_monitor_read_string" id="serial_monitor_read_string"></block>'
                    }
                ]
            },
            {
                name: 'Demande à l\'ordinateur',
                checked: 'false',
                xml:   '<category name="%{BKY_CATEGORY_COMPUTER}" id="computer_request" colour="#FF8C1A" secondaryColour="#DB6E00">',
                blocks: [
                    {
                        name: 'thingz_keyboard_letters',
                        imgName: thingz_keyboard_letters,
                        checked: 'false',
                        xml: '<block type="thingz_keyboard_letters" id="thingz_keyboard_letters">'+
                            '<value name="LETTERS">' +
                            '<shadow type="text_brick">' +
                            '<field name="TEXT"></field>' +
                            '</shadow>' +
                            '</value>' +
                            '</block>'
                    },
                    {
                        name: 'thingz_keyboard_keys',
                        imgName: thingz_keyboard_keys,
                        checked: 'false',
                        xml:     '<block type="thingz_keyboard_keys" id="thingz_keyboard_keys"></block>'
                    }
                ]

            },
            {
                name: 'Wifi',
                checked: 'false',
                xml: '<category name="%{BKY_WIFI_CATEGORY_NAME}" id="wifi_category_name" colour="#FF8C1A" secondaryColour="#DB6E00">',
                blocks: [
                    {
                        name: 'wifi_connect_network',
                        imgName: wifi_connect_network,
                        checked: 'false',
                        xml: '<block type="wifi_connect_network"            id="wifi_connect_network">'+
                            '<value name="NETWORK_NAME">' +
                            '<shadow type="text_brick">' +
                                '<field name="TEXT">nom</field>' +
                            '</shadow>' +
                            '</value>' +
                            '<value name="NETWORK_MDP">' +
                            '<shadow type="text_brick">' +
                                '<field name="TEXT">mdp</field>' +
                            '</shadow>' +
                            '</value>' +
                        '</block>'
                    },
                    {
                        name: 'wifi_is_connect',
                        imgName: wifi_is_connect,
                        checked: 'false',
                        xml: '<block type="wifi_is_connect" id="wifi_is_connect"></block>'
                    },
                    {
                        name: 'wifi_send_data',
                        imgName: wifi_send_data,
                        checked: 'false',
                        xml: '<block type="wifi_send_data" id="wifi_send_data">' +
                            '<value name="KEY">' +
                            '<shadow type="text_brick">' +
                                '<field name="TEXT">nom de la donnée</field>' +
                            '</shadow>' +
                            '</value>' +
                            '<value name="VALUE">' +
                            '<shadow type="text_brick">' +
                                '<field name="TEXT">valeur</field>' +
                            '</shadow>' +
                            '</value>' +
                        '</block>'
                    }
                ]
            }],
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.isChange !== this.state.isChange) {
            console.log(this.state.isChange)
        }
        if (this.props.step && prevProps.step !== this.props.step) {
            // Récupération des blocks enregistrés dans la toolbox afin qu'ils soient checked dans la popup
            if(this.props.step.toolbox) {
                let xml = [...this.state.xml];
                let isVariableCat = false;
                let isMesBlocsCat = false;
                if (this.props.step.toolbox.includes('BKY_CATEGORY_VARIABLES')) {
                    isVariableCat = true;
                }
                if (this.props.step.toolbox.includes('BKY_CATEGORY_MYBLOCKS')) {
                    isMesBlocsCat = true;
                }
                let regex = RegExp('<block type="([a-z_]+)"', 'g');
                let blocksMatche = [];
                let blocksName = [];
                while ((blocksMatche = regex.exec(this.props.step.toolbox)) !== null) {
                    blocksName.push(blocksMatche[1]);
                }
                for (let category of xml) {
                    if (isVariableCat && category.name === 'Variables') {
                        category.checked = 'true';
                    }
                    if (isMesBlocsCat && category.name === 'Mes blocs') {
                        category.checked = 'true';
                    }
                    let k = 0;
                    for (let block of category.blocks) {
                        if (blocksName.includes(block.name)) {
                            block.checked = 'true';
                            k++;
                        }
                    }
                    if (category.blocks.length !== 0 && category.blocks.length === k) {
                        category.checked = 'true';
                    }
                }
                this.setState({ xml });
            }
        }
    }

    handleOpen() {
        this.setState({modalOpen: true});
    }

    handleClose() {
        this.setState({modalOpen: false});
    }

    handleClickCategory(e, data) {
        this.setState({isChange: true});
        const xml = [...this.state.xml];
        for (let category of xml) {
            if (category.name === data.id) {
                if(!category.blocks.length) {
                    if (data.checked) {
                        category.checked = 'true';
                    }
                    else {
                        category.checked = 'false';
                    }
                }
                for (let block of category.blocks) {
                    if (data.checked) {
                        category.checked = 'true';
                        block.checked = 'true';
                    }
                    else {
                        category.checked = 'false';
                        block.checked = 'false';
                    }
                }
            }
        }
        this.setState({ xml });
    }

    handleClickBlock(e, data) {
        this.setState({isChange: true});
        const xml = [...this.state.xml];
        for (let category of xml) {
            let k = 0;
            for (let block of category.blocks) {
                if (data.id === block.name) {
                    if (data.checked) {
                        block.checked = 'true';
                    }
                    else {
                        block.checked = 'false';
                        if (category.checked) {
                            category.checked = 'false';
                        }
                    }
                }
                if (block.checked === 'true') {
                    k++;
                }
            }
            if (category.blocks.length !== 0 && category.blocks.length === k) {
                category.checked = 'true';
            }
        }
        this.setState({ xml });
    }

    compileXml() {
            let xml = '<xml>';
            
            for (let category of this.state.xml) {
                let checkbox = document.getElementById(category.name);
                if (checkbox) {
                    if (!category.blocks.length && checkbox.checked) {
                        xml += `${category.xml}</category>`;
                    }
                    let blockXml = '';
                    for (let block of category.blocks) {
                        checkbox = document.getElementById(block.name);
                        if (checkbox && checkbox.checked) {
                            blockXml += block.xml;
                        }
                    }
                    if (blockXml.length){
                        xml += `${category.xml}${blockXml}</category>`;
                    }
                }
            }
            xml += '</xml>';
            if(xml === '<xml></xml>')
                xml = null
            this.props.saveStepToolboxXml(xml);
        this.handleClose();
    }

    renderContent() {
        let blockList = [];
        
        for (let category of this.state.xml) {
            const categoryBlock = (
                <List.Header className='header_category_name'>
                    <Checkbox className='category_name' label={category.name} checked={category.checked === 'true' ? true : false} id={category.name} onChange={this.handleClickCategory} />
                    {!category.blocks.length && <img src={category.imgName} alt={category.name} />}
                </List.Header>
            )

            let checkboxes = [];
            for (let block of category.blocks) {  
                checkboxes.push(<List.Item className='blocks_list' key={block.name}><Checkbox checked={block.checked == 'false' ? false : true}  id={block.name} onChange={this.handleClickBlock} /><img src={block.imgName} alt={block.name} /></List.Item>)
            }

            const blocks = (
                <List.List>
                    <List.Content>
                        {checkboxes}
                    </List.Content>
                </List.List>
            )

            blockList.push(<List key={category.name} >{categoryBlock}{blocks}</List>)
        }
        return blockList;
    }

    render() {
        return (
            <Modal onClose={this.compileXml} trigger={<Button size={this.props.size} fluid color='grey' onClick={this.handleOpen}>Choisir les blocs nécessaires</Button>} open={this.state.modalOpen}>
                <Modal.Header>Choix des blocs à disposition de l'élève pour l'activité</Modal.Header>
                <Modal.Content>
                    {this.renderContent()}
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={this.compileXml}>Fermer</Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

export { ToolboxActivityComponent }