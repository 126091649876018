import { modelConstants,editorConstants } from '../../constants';


const initialState = {
    entities: {},
    isLoading: 0
};

export const clues = (state = initialState, action) => {
	switch (action.type) {
    case modelConstants.FETCH_ENTITIES_STARTED:
        if(action.entities === "clues")
            return {
                ...state,
                isLoading: state.isLoading + 1
            }
        return state;
    case modelConstants.FETCH_ENTITIES_DONE:
    case modelConstants.CREATE_ENTITY_DONE:
    case modelConstants.UPDATE_ENTITY_DONE:
    case modelConstants.FETCH_ENTITY_DONE:
        if(action.entities.entities && action.entities.entities.clues){
            return {
                ...state,
                entities: action.entities.entities.clues,
                isLoading: action.originalEntities === "clues" && state.isLoading > 0 ? state.isLoading - 1 : state.isLoading
            }
        }else if(action.originalEntities === "clues"){
            return {
                ...state,
                isLoading: state.isLoading > 0 ? state.isLoading-1 : 0
            }
        }
        return state;
    case modelConstants.INVALIDATE_ENTITIES:
        return initialState;
    case editorConstants.SAVE_CLUE_CHANGES:
        return{
            ...state,
            entities:{...state.entities,
                        [action.clueToUpdate._id]:action.clueToUpdate
            }
        }
    default:
        return state;
  }
}