import { modelConstants } from "../../constants";
import * as api from "../../api";
import { stepsConstants } from '../../constants';
import { normalize } from 'normalizr'
import { stepSchema } from "../../schemas";
import { editorConstants } from "../../constants/editor_constants";
import { cpus } from "os";

export const stepsActions = {
	fetchFirstByCourse,
	fetchLastByCourse,
	fetchNextStep,
	fetchPreviousStep,
	createFirstStepForCourse,
	createNextStep,
	createPreviousStep,
	saveCurrentStep,
	deleteCurrentStep,
	destroy
}

// Fonction qui va chercher la première étape du parcours lorsque l'on en sélectionne un en édition. Si le back ne renvoie aucune étape, on dispatch une action de création pour une première étape.

function fetchFirstByCourse(course) {
	return dispatch => {
		//dispatch({ type: editorConstants.CLEAR_STEPS_ENTITIES })
		dispatch({ type: modelConstants.FETCH_ENTITIES_STARTED, entities: "steps" });
		return api.steps.fetchFirstByCourse(course)
		.then(response => {
			let entities = normalize(response.data, stepSchema)			
			dispatch({ type: modelConstants.FETCH_ENTITIES_DONE, originalEntities: "steps", entities })
		})
		.catch(error => {
			//Pas de première étape, on en crée une automatiquement
			if (error.response.status === 404) {dispatch(createFirstStepForCourse(course))}
			dispatch({ type: stepsConstants.FETCH_BY_COURSE_FAILED, error })
		})
	}
}

function fetchLastByCourse(course) {
	return dispatch => {
		dispatch({ type: modelConstants.FETCH_ENTITIES_STARTED, entities: "steps" });
		return api.steps.fetchLastByCourse(course)
		.then(response => {
			let entities = normalize(response.data, stepSchema)			
			dispatch({ type: modelConstants.FETCH_ENTITIES_DONE, originalEntities: "steps", entities })
		})
		.catch(error => {
			dispatch({ type: stepsConstants.FETCH_BY_COURSE_FAILED, error })
		})
	}
}

// Fonction de navigation pour atteindre l'étape suivante depuis l'étape actuelle.
// On recherche l'information sur le Front 

function fetchNextStep(actualStep) {

	return dispatch => {
		dispatch({ type: modelConstants.FETCH_ENTITIES_STARTED, entities: "steps" })
		/*if (!actualStep.nextStep) {  dispatch (createNextStep (actualStep))
		dispatch({ type: stepsConstants.FETCH_BY_COURSE_FAILED})}else { 
			console.log(" Il n'y a plus d'autres étapes dans ce parcours")
			let entities = normalize(actualStep, stepSchema)
			
			return dispatch({ type: modelConstants.FETCH_ENTITIES_DONE, originalEntities: "steps", entities })
		}*/

		// Mon étape actuelle connait l'ensemble de l'étape suivante. Pas besoin d'interroger le Back, on récupère les informations du front.
		// Bémol : Ce systeme est risqué dans le cas où deux personnes interviennent simultanément sur un même parcours en même temps. Ce cas est peu probable pour le moment
		if (actualStep.nextStep.description!=null) {
			return api.steps.fetchNextStep(actualStep.nextStep._id)
			.then(response => {
				let entities = normalize(actualStep.nextStep,stepSchema)
		 		dispatch({ type: modelConstants.FETCH_ENTITIES_DONE, originalEntities: "steps", entities })
			})
			.catch(error => { 
				dispatch({ type: stepsConstants.FETCH_NEXT_STEP_FAILED, error }) 
			})
		// Mon étape actuelle connait uniquement l'id de son étape suivante, on interroge le back pour récupérer l'entité

		} else {
			return api.steps.fetchNextStep(actualStep.nextStep)
			.then(response => {
				let entities = normalize(response.data, stepSchema)
				dispatch({ type: modelConstants.FETCH_ENTITIES_DONE, originalEntities: "steps", entities })
			})
			.catch(error => { 
				dispatch({ type: stepsConstants.FETCH_NEXT_STEP_FAILED, error }) 
			})

		}
	}
}

// Fonction de Navigation vers l'étape précédente

function fetchPreviousStep(actualStep) {
	return dispatch => {
		dispatch({ type: modelConstants.FETCH_ENTITIES_STARTED, entities: "steps" })
		return api.steps.fetchPreviousStep(actualStep.id)
		.then(response => {
			let entities = normalize(response.data, stepSchema)
			dispatch({ type: modelConstants.FETCH_ENTITIES_DONE, originalEntities: "steps", entities })
		})
		.catch(error => { 
			dispatch({ type: stepsConstants.FETCH_PREVIOUS_STEP_FAILED, error }) 
			dispatch(createPreviousStep(actualStep))
		})
	}
}


// Fonction de création de la première étape d'un parcours s'il est vide 	
function createFirstStepForCourse(course){
	return (dispatch, getState) => {
		dispatch({type: modelConstants.CREATE_ENTITY_STARTED, entities:"steps"})
		return api.steps.createForCourse(course)
		.then(response => {
			let entities = normalize(response.data, stepSchema)
			dispatch({type: modelConstants.CREATE_ENTITY_DONE, originalEntities:"steps", entities})
		})
		.catch(error => {
			console.log(error)
			dispatch({type: stepsConstants.CREATE_BY_COURSE_FAILED, error})
		})
	}
}


//Fonction de création de l'étape suivante
function createNextStep(currentStep){
	return(dispatch)=>{
		dispatch({type:modelConstants.CREATE_ENTITY_STARTED, entities:"steps"})
		return api.steps.createNextStep(currentStep)
		.then(response=>{
			let entities = normalize(response.data,stepSchema)
			dispatch({type: modelConstants.CREATE_ENTITY_DONE, originalEntities:"steps", entities})
		})
		.catch(error=>{
			console.log(error)
			dispatch({type: stepsConstants.CREATE_NEXT_STEP_FAILED, error})
		})
	}
}

//Fonction de création de l'étape précédente 
function createPreviousStep(currentStep){
	return(dispatch)=>{
		dispatch({type:modelConstants.CREATE_ENTITY_STARTED,entities:"steps"})
		return api.steps.createPreviousStep(currentStep)
		.then(response=>{
			let entities = normalize(response.data,stepSchema)
			dispatch({type:modelConstants.CREATE_ENTITY_DONE, originalEntities:"steps",entities})
		})
		.catch(error=>{
			console.log(error)
			dispatch({type: stepsConstants.CREATE_PREVIOUS_STEP_FAILED,error})
		})
	}
}

function saveCurrentStep(currentStep){
	return (dispatch, getState) => {
		dispatch({type: modelConstants.UPDATE_ENTITY_STARTED, entities:"steps"})
		return api.steps.saveCurrentStep(currentStep)
		.then(response => {
			let entities = normalize(response.data, stepSchema)
			dispatch({type: modelConstants.UPDATE_ENTITY_DONE, originalEntities:"steps", entities})
		})
		.catch(error => {
			console.log(error)
			dispatch({type: stepsConstants.UPDATE_FAILED, error})
		})
	}
}

function deleteCurrentStep(currentStep){
	return (dispatch, getState) => {
		dispatch({type: modelConstants.UPDATE_ENTITY_STARTED, entities:"steps"})
		return api.steps.deleteCurrentStep(currentStep)
		.then(response => {
			let entities = getState().model.steps.entities;
			let newEntities = {...entities}
			delete newEntities[response.data._id];
			dispatch({type: modelConstants.DELETE_ENTITY_DONE, originalEntities:"steps", entities: {entities: {steps: newEntities}}})

			// let entities = normalize(response.data, stepSchema)
			// dispatch({type: modelConstants.UPDATE_ENTITY_DONE, originalEntities:"steps", entities})
		})
		.catch(error => {
			console.log(error)
			dispatch({type: stepsConstants.DELETE_FAILED, error})
		})
	}
}

function destroy(step){
	return (dispatch, getState) => {
		dispatch({type: modelConstants.UPDATE_ENTITY_STARTED, entities:"steps"})
		return api.steps.destroy(step)
		.then(response => {
			let entities = getState().model.steps.entities;
			let newEntities = {...entities}
			delete newEntities[response.data._id];
			dispatch({type: modelConstants.DELETE_ENTITY_DONE, originalEntities:"steps", entities: {entities: {steps: newEntities}}})

			// let entities = normalize(response.data, stepSchema)
			// dispatch({type: modelConstants.UPDATE_ENTITY_DONE, originalEntities:"steps", entities})
		})
		.catch(error => {
			console.log(error)
			dispatch({type: stepsConstants.DELETE_FAILED, error})
		})
	}
}



