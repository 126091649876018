import React, { Component } from 'react';
import { TextArea,Form } from 'semantic-ui-react'
import './EditorTextComponent.css'

class EditorTextComponent extends Component {
    constructor(props) {
        super(props)
       
    }

    saveChanges=(e,event)=> {
     this.props.saveStepChanges(event)
    }


    render() {
        if (this.props.step) {
            return (
                <div>
                <div className="flex">
                    <Form style={{ width: '50%' }}>
                        <h2>Description</h2>
                        <TextArea data='description' value={this.props.step.description} onChange={this.saveChanges}></TextArea>
                    </Form>
                    <Form style={{ width: '50%' }}>
                        <h2>Instructions</h2>
                        <TextArea  data='instruction' autoHeight rows={6} value={this.props.step.instruction} onChange={this.saveChanges}></TextArea>
                    </Form>
                </div>
                <div className="flex">
                    <Form style={{ width: '50%' }}>
                        <h2>Context</h2>
                        <TextArea data='context' rows={6} value={this.props.step.context} onChange={this.saveChanges}></TextArea>
                    </Form>
                    <Form style={{ width: '50%' }}>
                        <h2>contextMedia</h2>
                        <TextArea data="contextMedia" value={this.props.step.contextMedia} onChange={this.saveChanges}></TextArea>
                    </Form>
                    </div>
            </div>)
        } else { return null }
    }
}
export { EditorTextComponent }