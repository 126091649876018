import React, {Component} from 'react';

import {Modal, Image, Header, Segment, Grid, Divider, Button} from 'semantic-ui-react'

import './ModalProcedureComponent.css'

class ModalProcedureComponent extends Component{

	constructor(props){
		super(props);

		this.workspace = null;
		this.mutationRoot = null;
	}

	componentDidMount(){
		//this.setupBlockly()
	}

	setupBlockly(){
		console.log("INJECT")
		let oldToolbox = window.Blockly.Blocks.defaultToolbox
		window.Blockly.Blocks.defaultToolbox = null;
		this.workspace = window.Blockly.inject('blocklyProcedure', {
			media: '/assets/blockly/media/',
	    zoom: {
        controls: false,
        wheel: false,
        startScale: 0.9
	    },
	    comments: false,
	    collapse: false,
	    scrollbars: true
      //horizontalLayout:true
  	});
		window.Blockly.Blocks.defaultToolbox = oldToolbox;

		// Create the procedure declaration block for editing the mutation.
    this.mutationRoot = this.workspace.newBlock('procedures_declaration');
    // Make the declaration immovable, undeletable and have no context menu
    this.mutationRoot.setMovable(false);
    this.mutationRoot.setDeletable(false);
    this.mutationRoot.contextMenu = false;

    this.workspace.addChangeListener(() => {
      this.mutationRoot.onChangeFn();
      // Keep the block centered on the workspace
      const metrics = this.workspace.getMetrics();
      const {x, y} = this.mutationRoot.getRelativeToSurfaceXY();
      const dy = (metrics.viewHeight / 2) - (this.mutationRoot.height / 2) - y;
      let dx;
      
      dx = (metrics.viewWidth / 2) - (this.mutationRoot.width / 2) - x;
      // If the procedure declaration is wider than the view width,
      // keep the right-hand side of the procedure in view.
      if (this.mutationRoot.width > metrics.viewWidth) {
          dx = metrics.viewWidth - this.mutationRoot.width - x;
      }

      this.mutationRoot.moveBy(dx, dy);
  	});
	  this.mutationRoot.domToMutation(this.props.mutator);
	  this.mutationRoot.initSvg();
	  this.mutationRoot.render();
	  
	  // Allow the initial events to run to position this block, then focus.
	  setTimeout(() => {
	      this.mutationRoot.focusLastEditor_();
	  });
	}

	onClose = () => {
		if(this.workspace){
			this.workspace.dispose();
			this.workspace = null;
			this.mutationRoot = null;
		}
		this.props.onClose();
	}

	onOpen = () => {
		this.setupBlockly();
	}

	onActionClick = (event, data) => {
		if(data.id === "done"){
			if(this.props.callback){
				if(this.mutationRoot){
					this.props.callback(this.mutationRoot.mutationToDom(true))
				}else
					this.props.callback();
			}
		}

		this.onClose();
	}

	addParamString = () => {
		if (this.mutationRoot) {
        this.mutationRoot.addStringExternal();
    }
	}

	addParamNumber = () => {
		if (this.mutationRoot) {
        this.mutationRoot.addNumberExternal();
    }
	}

	addParamBool = () => {
		if (this.mutationRoot) {
        this.mutationRoot.addBooleanExternal();
    }
	}

	addLabel = () => {
		if (this.mutationRoot) {
        this.mutationRoot.addLabelExternal();
    }
	}

	render(){
		return (
			<Modal size={"large"} open={this.props.open} onMount={this.onOpen} onClose={this.onClose}>
		    <Modal.Header>Création d'une nouvelle procédure</Modal.Header>
		    <Modal.Content style={{ padding: 0}}>
		    	<div style={{ padding: 0, height: 20 + 'vh', width: 100 + '%' }} id="blocklyProcedure"></div>
		    	<Segment>
				    <Grid columns={4} relaxed='very'>
				      <Grid.Column>
				        <Segment onClick={this.addParamString} className={"ModalProcedureSegment"}>
				        	<Image centered size="tiny" src="/assets/blockly/media/string.svg"/>
				        	<Header textAlign="center" size="medium">
								    Ajouter un paramètre
								    <Header.Subheader>Type texte</Header.Subheader>
					  			</Header>
				        </Segment>
				      </Grid.Column>
				      <Grid.Column>
				        <Segment onClick={this.addParamNumber} className={"ModalProcedureSegment"}>
				        	<Image centered size="tiny" src="/assets/blockly/media/string.svg"/>
				        	<Header textAlign="center" size="medium">
								    Ajouter un paramètre
								    <Header.Subheader>Type nombre</Header.Subheader>
					  			</Header>
				        </Segment>
				      </Grid.Column>
				      <Grid.Column>
				        <Segment onClick={this.addParamBool} className={"ModalProcedureSegment"}>
				        	<Image centered size="tiny" src="/assets/blockly/media/bool.svg"/>
				        	<Header textAlign="center" size="medium">
								    Ajouter un paramètre
								    <Header.Subheader>Type booléen</Header.Subheader>
					  			</Header>
				        </Segment>
				      </Grid.Column>
				      <Grid.Column>
				        <Segment onClick={this.addLabel} className={"ModalProcedureSegment"}>
				        	<Image centered size="tiny" src="/assets/blockly/media/text.svg"/>
				        	<Header textAlign="center" size="medium">
								    Ajouter un label
								    <Header.Subheader style={{opacity:0}}>Text</Header.Subheader>
					  			</Header>
				        </Segment>
				      </Grid.Column>
				    </Grid>
					</Segment>
		    </Modal.Content>
		    <Modal.Actions onActionClick={this.onActionClick} actions={[{key: 'cancel', id:'cancel', content: 'Annuler'}, {key: 'done', id:'done', content: "Valider", positive:true}]}/>
		  </Modal>
		)
	}
}

export default ModalProcedureComponent;