import React, {Component} from 'react';
import {connect} from "react-redux";
import { Link } from 'react-router-dom'

import {Loader, Dimmer, Message} from 'semantic-ui-react'

import {getActualUser} from "../../reducers/user_reducer";

import {modelActions, usersActions, coursesActions, selectedCoursesActions} from "../../actions/model";

import {userSchema} from '../../schemas/users_schema'

import {StudentProfilComponent} from "../../components/StudentProfilComponent";
import {PageWithSideMenu} from "../PageWithSideMenu";

class StudentProfil extends Component {

    constructor(props){
        super(props);
        this.state = {selectedStudent: null};
    }

    componentDidMount() {
        document.title = 'Profil élève - Thingz'

       this.fetch();
    }

    componentWillUnmount(){
        this.props.dispatch(modelActions.invalidate())
    }


    componentDidUpdate(prevProps){
        if(prevProps.users.entities[this.props.match.params.id] && !this.props.users.entities[this.props.match.params.id]){
            this.setState({selectedStudent: null})
            this.fetch();
            return;
        }

        if(prevProps.users.isLoading > 0 && this.props.users.isLoading !== prevProps.users.isLoading){

            const user = Object.keys(this.props.users.entities).find(u => {
                return u === this.props.match.params.id;
            })
            if(user){
                this.setState({selectedStudent: user})
            }
        }
    }

    fetch(){
        this.props.dispatch(usersActions.fetchUser(this.props.match.params.id));
        this.props.dispatch(coursesActions.fetchAll());
        this.props.dispatch(selectedCoursesActions.fetchAllForStructure())
    }

    onStudentDeleteClick = (student) => {
        this.props.dispatch(usersActions.deleteUser(student, '/structure'))
    }

    renderLoading(){
        return (
            <Dimmer active inverted>
                <Loader size='large'>Chargement</Loader>
            </Dimmer>
        )
    }

    renderError(){
        try{
            if(typeof this.props.users.error.response.data.message === 'string')
                return (
                    <Message negative style={{display: "block"}}>
                        <Message.Header>Erreur</Message.Header>
                        <p>{this.props.users.error.response.data.message}</p>
                        <Link to='/'>Retour à l'accueil</Link>
                    </Message>
                )
            else
                return (
                    <Message negative style={{display: "block"}}>
                        <Message.Header>Erreur</Message.Header>
                        <p>{"Une erreur est survenue: "+this.props.users.error.response.statusText}</p>
                        <Link to='/'>Retour à l'accueil</Link>
                    </Message>
                )
        }catch(error){
            try{
                return (
                    <Message negative style={{display: "block"}}>
                        <Message.Header>Erreur</Message.Header>
                        <p>{"Une erreur est survenue: "+this.props.users.error.response.statusText}</p>
                        <Link to='/'>Retour à l'accueil</Link>
                    </Message>
                )
            }catch(error){
                return (
                    <Message negative style={{display: "block"}}>
                        <Message.Header>Erreur</Message.Header>
                        <p>{"Une erreur inconnue est survenue: "}</p>
                        <Link to='/'>Retour à l'accueil</Link>
                    </Message>
                )
            }
        }
    }

    renderUser(){
        if(!this.state.selectedStudent && !this.props.users.error){
            return this.renderLoading()
        }else if(this.props.users.error){
            return this.renderError()
        }else if(this.state.selectedStudent && this.props.users.entities[this.state.selectedStudent]){
            let user = this.props.users.entities[this.state.selectedStudent];

            let progressions = Object.values(this.props.progressions.entities).filter(progression => {
                return progression.user === user._id
            })
            progressions = progressions || []
            let finishedcourses = Object.values(this.props.finishedCourses.entities).filter(finishedcourse => {
               return finishedcourse.user === user._id
            })
            finishedcourses = finishedcourses || []
            let apps = Object.values(this.props.apps.apps).filter(app => {
                return app.user === user._id
            })
            apps = apps || []
            let group = this.props.groups.entities[user.group];
            let structure = this.props.structures.entities[user.structure] || (group && this.props.structures.entities[group.structure]) || {}
            let studentSelectCourses =[]

            if(structure){
                studentSelectCourses = Object.values(this.props.selectedCourses.entities).filter(select => {
                    if(select.structure === structure._id || (user && select.group && select.group === user.group)){
                        return true
                    }
                })
            }

            return (
                <StudentProfilComponent user={user} apps={apps} progressions={progressions} finishedCourses={finishedcourses} courses={this.props.courses.entities} steps={this.props.steps.entities} structure={structure} group={group} selectedCourses={studentSelectCourses} onDeleteClick={this.onStudentDeleteClick}/>
            )
        }else{
            return this.renderError()
        }
    }

    render() {
        return (
            <PageWithSideMenu ignoreStructureLearners={true}>
               {this.renderUser()}
            </PageWithSideMenu>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: getActualUser(state),
        structures: state.model.structures,
        groups: state.model.groups,
        progressions: state.model.progressions,
        finishedCourses: state.model.finishedCourses,
        apps: state.model.apps,
        courses: state.model.courses,
        steps: state.model.steps,
        users: state.model.users,
        selectedCourses: state.model.selectedCourses
    }
}

const connectedStudentProfil = connect(mapStateToProps)(StudentProfil);
export { connectedStudentProfil as StudentProfil }
