import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Grid, Header, Image, Message, Segment} from 'semantic-ui-react'
import {withRouter} from "react-router-dom";
import technicien from '../../img/technicien.svg'


class ForgotPasswordComponent extends Component{
    constructor(props,context){
        super(props,context);
        this.state={
            user:{
                identifier: ''
            }
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
       // this.onLogOut = this.onLogOut.bind(this);
        //this.redirectToRegister = this.redirectToRegister.bind(this)
    }

    componentWillUnmount(){
        const {forgotPasswordResetState} = this.props.actions;
        forgotPasswordResetState();
    }

    onSubmit() {
        const {user} = this.state;
        const {forgotPassword} = this.props.actions;
        forgotPassword(user);
    }

    onChange(e) {
        const {value, name} = e.target;
        const {user} = this.state;
        user[name] = value;
        this.setState({user});
    }

    /*onLogOut(history) {
        logout(history);
    }
*/
    redirectToLogin = () =>{
        this.props.history.push('/login')
    }
    redirectToRegister = () =>{
        this.props.history.push('/register')
    }

    render(){
        return( 
            <div style={{flex:1, display:'flex'}}>
                <Grid textAlign='center' style={{flex: "1 1 auto"}} verticalAlign='middle'>
                    <Grid.Column style={{maxWidth: 450}}>
                        <Header as='h2' color='orange' textAlign='center'>
                            <Image
                                style={{width: '4.5em', marginRight: "25px"}}
                                verticalAlign={'middle'}
                                circular
                                size={'large'}
                                src={technicien}/>
                            Mot de passe oublié 
                        </Header>
                        <Message>
                            
                            Saisis ton adresse email ci-dessous
                            
                        </Message>
                        <Form size='large' loading={this.props.isFetching} error={this.props.error !== null} success={this.props.success == true} >
                            <Segment>
                                {this.props.error !== null && <Message
                                error
                                header='Erreur :'
                                content={this.props.error.data.message}
                                />}
                                
                                {this.props.success == true && <Message
                                success
                            header =' Un email pour réinitialiser ton mot de passe a bien été envoyé'
                            
                        />}
                                <Form.Input
                                    onChange={this.onChange}
                                    name={"identifier"}
                                    fluid
                                    icon='user'
                                    iconPosition='left'
                                    placeholder='E-mail'
                                    style={{marginBottom:"5px"}}/>

                                <Button color='orange' fluid size='large'
                                        onClick={() => this.onSubmit()}>
                                    Recevoir un email
                                </Button>
                            </Segment>
                        </Form>
                        <Message style={{display:"block"}}>
                            <p>
                                <a style={{cursor: "pointer"}} onClick={this.redirectToLogin}>J'ai déjà un compte</a>
                            </p>
                            <p>
                            <a style={{cursor: "pointer"}} onClick={this.redirectToRegister}>Je n'ai pas de compte</a>
                            </p>
                        </Message>
                    </Grid.Column>
                </Grid>
            </div>
        );


        }
}

ForgotPasswordComponent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

export default withRouter(ForgotPasswordComponent);