import React from 'react'
import {withRouter} from 'react-router-dom';
import {appsActions, coursesActions, progressionsActions} from '../../../actions/model';

import AppSummaryComponent from '../../../components/AppSummaryComponent/AppSummaryComponent';

import {
	Container,
	Image,
	Segment,
	Header,
	Grid,
    Label,
    Icon,
    Modal,
	List,
	Item,
	Placeholder,
	Tab,
	Menu,
	Message,
	Divider,
	Button,
	Input,

} from 'semantic-ui-react';
import  '../Profil.css'

const CoursesTab = (props) => {
    
    function renderLoading(){
        let l = [];
        for(let i = 0; i<3; i++){
            l.push((<Placeholder key={i}>
                <Placeholder.Header image>
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Header>
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Paragraph>
            </Placeholder>))
        }
        return l
    }
	
	function redirectToParcours() {
		props.history.push('/')
	}
		
	return(
		<div className='pane'>
			<Divider horizontal hidden/>
			<Header>Mes Parcours</Header>

			<Item.Group>
				{props.listCoursesStarted().length > 0 ? (
					props.listCoursesStarted()
				) : (
					<Message style={{display:'block'}} info={props.user.confirmed ? true : false} warning={!props.user.confirmed ? true : false}>
						{/* Ajout d'un boutons redirigeant vers la page des parcours ??? */}
						<Message.Header>
						{!props.user.confirmed ? (
							<span>Tu n'as pas validé ton adresse email</span>
						) : (
							<span>Tu n'as pas encore commencé de parcours</span>
						)}
						</Message.Header>
						<Divider hidden/>
						{!props.user.confirmed ? (
							<div>
								Pour accéder aux parcours ouvre l'email d'activation que nous t'avons envoyé.
								<br/>
								Grâce aux parcours, tu vas apprendre à utiliser ton kit et à programmer dans l'espace de création. Ils te donneront plein d'idées de créations.
							</div>
						) : (
							<div>
								Grâce aux parcours, tu vas apprendre à utiliser ton kit et à programmer dans l'espace de création. Ils te donneront plein d'idées de créations.
								<Divider hidden/>
								<Button compact onClick={() => {redirectToParcours()}} color='orange' basic size={'big'}>
									Parcours
								</Button>
							</div>
						)}


					</Message>
				)}
			</Item.Group>
			<Divider section/>
		</div>
	)
}

// export default CoursesTab;
export const CoursesTabWithRouter = withRouter(CoursesTab)
