import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter, Link} from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago'
import DOMPurify from 'dompurify';

import {Button, Icon, Card, Modal, Header, Label, Dropdown, Menu, Popup, List, Accordion} from 'semantic-ui-react'

import ActivityShareModal from './ActivityShareModal/ActivityShareModal'

import './InstructorActivitySummaryComponent.css'
import { MultiProgressbar } from '../MultiProgressbar';


class InstructorActivitySummaryComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openModal: false,
            shareModalState: false
        }

        this.redirectToActivity = this.redirectToActivity.bind(this);
        this.redirectToRenderingList = this.redirectToRenderingList.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.deleteActivity = this.deleteActivity.bind(this);
    }

    renderCreated() {
        return (
            <List.Item>
                <b>Créée:</b> <ReactTimeAgo date={new Date(this.props.activity.createdAt)} locale="fr"/>
            </List.Item>
        )
    }

    renderUpdated() {
        return (
            <List.Item>
                <b>Dernière modification:</b> <ReactTimeAgo date={new Date(this.props.activity.updatedAt)} locale="fr"/>
            </List.Item>
        ) 
    }

    openModal() {
        this.setState({openModal: true});
    }

    closeModal() {
        this.setState({openModal: false});
    }

    openShareModal = () =>{
        if(!this.props.activity.shareId)
            this.props.getActivityShareId(this.props.activity._id)
        this.setState({shareModalState: true})
    }

    closeShareModal = () =>{
        this.setState({shareModalState: false})
    }

    deleteActivity() {
        this.props.deleteActivity(this.props.activity._id);
    }

    renderDeleteModal() {
        return (
                <Modal onClose={this.closeModal} open={this.state.openModal} dimmer="blurring" size='small'>
                    <Header icon='trash' content="Confirmation de suppression" />
                    <Modal.Content>
                        <p>
                            Si vous supprimez l'activité, toutes les progressions de vos élèves liées à cette activité seront perdues.
                        </p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic color='grey' onClick={this.closeModal}>
                            <Icon name='remove' /> Annuler
                        </Button>
                        <Button color='red' onClick={this.deleteActivity}>
                            <Icon name='trash' />  Supprimer l'activité
                        </Button>
                    </Modal.Actions>
                </Modal>
        )
    }

    renderStudientListButton() {
        let text = this.props.activity && this.props.activity.isActivity ? "Consulter la liste des rendus" : "Consulter la liste des progressions" 
        return (
            <Button floated={'right'} color='grey' onClick={this.redirectToRenderingList}>{text}</Button>
        )
    }

    renderOpenButton() {
        return(
            <Button primary floated={'right'} onClick={this.redirectToActivity} icon labelPosition="right">
                    Modifier <Icon name={'arrow right'}/>
            </Button>
        )
    }

    renderTryIt() {
        return(
            <Button primary floated={'right'} onClick={this.tryActivity} icon labelPosition="right">
                Essayer <Icon name="lightning"/>
            </Button>
        )
    }

    renderShareModal(){
        return (
            <ActivityShareModal shareId={this.props.activity.shareId} open={this.state.shareModalState} onClose={this.closeShareModal}/>
        )
    }

    redirectToActivity() {
        this.props.history.push(`/activityCreator/${this.props.activity._id}`)
    }

    tryActivity = () => {
        this.props.tryActivity(this.props.activity._id)
    }

    redirectToRenderingList() {
        this.props.history.push(`/activityRenderingList/${this.props.activity._id}`)
    }

    addNotStartedStudents(students){
        if(!this.props.activity)
            return students
        console.log("add not started ")
        let owner = this.props.users.entities[this.props.user._id];
        console.log("add not started 2", owner)
        if(!owner)
            return students

        let structure = Object.values(this.props.structures).find(s => { if(s.owner === owner._id)return s})
        console.log("add not started 3", structure)
        if(!structure)
            return students

        for (let user in this.props.users.entities) {
            user = this.props.users.entities[user]
            if(students.find(student => {
                return student.user._id === user._id
            }))
                continue
        
            if(user.structure === structure._id && (!this.props.activity.isActivity || Object.values(this.props.selectedCourses).find(selected => { return selected.course === this.props.activity._id && selected.structure === structure._id}))){
                students.push({user: user, notStarted:true})
            }else{
                let g = this.props.groups.find(g => { return g._id  === user.group})
                if(g && g.structure === structure._id && (!this.props.activity.isActivity || Object.values(this.props.selectedCourses).find(selected => { return selected.course === this.props.activity._id && (selected.structure === structure._id || selected.group === user.group)}))){
                    students.push({user: user, notStarted:true})
                }
            }


        }

        return students
    }

    getStudentsForCourse(){
        let students = []
        for (let progression in this.props.progressions) {
            progression = this.props.progressions[progression];
            let step =this.props.steps[progression.step]

            if(!step || !this.props.activity || step.course != this.props.activity._id)
                continue
            console.log("students for course 1")
            let finish = Object.values(this.props.finished).find(f => {
                return f.user === progression.user && f.course === step.course
            })

            if (!finish) {
                console.log("students for course 2", this.props.users.entities[progression.user], progression)
                if(this.props.users.entities[progression.user])
                    students.push({key:this.props.users.entities[progression.user]._id, user:this.props.users.entities[progression.user], progression:progression, update:true, currentStep: progression.ended ? this.props.activity.stepsCount : step.currentPositionInCourse-1})
            }
        }
        let finished = Object.values(this.props.finished).reduce((prev, finish) => {
            if(!this.props.activity || finish.course !== this.props.activity._id)
                return prev
            if(!Object.values(prev).find(f => {
                return f.user === finish.user && f.createdAt > finish.createdAt
            })){
                prev[finish.user] = finish;
            }
            return prev
        }, {})

        finished = Object.values(finished).forEach(finish => {
            if (this.props.activity && finish.course === this.props.activity._id) {
                let progression = Object.values(this.props.progressions).find(p => {
                    let step =this.props.steps[p.step]
                    if(p.user === finish.user && step && finish.course === step.course)
                        return p
                    return null
                })
                let update = progression && progression.createdAt > finish.createdAt
                console.log("students for course 3", this.props.users.entities[finish.user])
                if(this.props.users.entities[finish.user])
                    students.push({key:this.props.users.entities[finish.user]._id, user:this.props.users.entities[finish.user], progression:progression, update:update, finish: finish, currentStep: this.props.activity.stepsCount})
            }
        })

        students = this.addNotStartedStudents(students);
        console.log("student", students)
       return students
    }

    renderMenu(){
        if(!this.props.activity.isActivity)
            return null;
        return (
            <Popup trigger={
                <Dropdown item icon='ellipsis vertical' direction="left" style={{float:"right"}}>
                    <Dropdown.Menu>
                        <Dropdown.Item icon="share" content="Partager..." onClick={this.openShareModal}/>
                        <Dropdown.Item icon="trash" content="Supprimer..." onClick={this.openModal}/>
                    </Dropdown.Menu>
                </Dropdown>}
            >Plus d'options</Popup>
            
        )
    }

    renderAccessibility(){
        let isActivity = this.props.activity && this.props.activity.isActivity
        if(isActivity && this.props.available){
            switch(this.props.available.for){
                case "none":
                    return (
                        <span><b>Disponible pour:</b> Personne</span>
                    )
                break;
                case "all":
                    return (
                        <span><b>Disponible pour:</b> Tous les élèves</span>
                    )
                break;
                case "some":
                    return (
                        <span><b>Disponible pour:</b> {this.props.available.list.map((group, i)=>{
                            return group.name
                        }).join(" ,")}</span>
                                
                    )
                break;
            }
        }
    }

    renderAvailableLabel(){
        let isActivity = this.props.activity && this.props.activity.isActivity
        if(isActivity && this.props.available){
            switch(this.props.available.for){
                case "none":
                    return (
                        <Popup position="top center" trigger={
                            <Icon name="users" color="red"/>
                        }>
                            Activité visible par aucun élève
                        </Popup>
                    )
                break;
                case "all":
                    return (
                        <Popup position="top center" trigger={
                            <Icon name="users" color="green"/>
                        }>
                            Activité visible par tous les élèves de la structure
                        </Popup>
                    )
                break;
                case "some":
                    return (
                        <Popup position="top center" trigger={
                            <Icon name="users" color="blue"/>
                        }>
                            Activité visible par les élèves des groupes:
                            <List bulleted>
                                {this.props.available.list.map((group, i)=>{
                                    return <List.Item key={group._id}>{group.name}</List.Item>
                                })}
                            </List>
                        </Popup>
                    )
                break;
            }
        }
            // <Label color='green' className='label_publish'>
            //     Publié
            // </Label>
            // )||(
            // <Label color='red' className='label_publish'>
            //     Non publié
            // </Label>))
            // }{isActivity && ((this.props.activity.isPublish &&
            //     <Label color='green' className='label_publish'>
            //         Publié
            //     </Label>
            //     )||(
            //     <Label color='red' className='label_publish'>
            //         Non publié
            //     </Label>))
            //     }
    }

    renderAvailableInStoreLabel(){
        let isActivity = this.props.activity && this.props.activity.isActivity
        if(isActivity){
            if(this.props.activity.inStore){
                    return (
                        <Popup position="top center" trigger={
                            <Icon name="globe" color="green"/>
                        }>
                            Activité disponible dans la bibliothèque. Les autres enseignants peuvent trouver votre activité dans la bibliothèque d'activités.
                        </Popup>
                    )
            }else{
                return (
                    <Popup position="top center" trigger={
                        <Icon name="globe" color="red"/>
                    }>
                        Activité non disponible dans la bibliothèque. Les autres enseignants ne peuvent pas trouver votre activité dans le la bibliothèque d'activités.
                    </Popup>
                )
            }
        }else{

        }
    }

    renderProgressRatio(){
        let students = this.getStudentsForCourse()
        let ended, started, notStarted, total
        ended = 0
        notStarted=0
        started=0
        total = 0
        students.forEach(student => {
            if(student.notStarted){
                notStarted++;
                total++;
            }
            else{
                if(student.finish || (student.progression && student.progression.ended)){
                    ended++;
                    total++;
                }else if(student.progression && !student.progression.ended){
                    started++;
                    total++;
                }
            }
        })
        console.log(ended, started, notStarted, total)
        let bar = [{color: "#21ba45", title: "Élèves ayant terminé l'activité", info: `${ended} élèves sur ${total} : ${Math.round(ended/total*100)}%`, ratio:(ended/total*100)}, {color:"#fbbd08", title: "Élèves ayant commencé l'activité", info: `${started} élèves sur ${total} : ${Math.round(started/total*100)}%`, ratio:(started/total*100)}, {color:"#db2828", title: "Élèves n'ayant pas commencé l'activité", info: `${notStarted} élèves sur ${total} : ${Math.round(notStarted/total*100)}%`, ratio:(notStarted/total*100)}]
        return <MultiProgressbar bars={bar}/>
    }

    renderCloneFrom(){
        if(!this.props.originalUser)
            return <List.Item><b>Auteur:</b> moi</List.Item>
        return <List.Item>
            <b>Auteur:</b> moi - copie de <b>{this.props.originalUser.username}</b>
        </List.Item>
    }

    renderInfo(){
        if(!this.props.activity.isActivity)
            return
        return (
            <List bulleted>
                {this.renderCloneFrom()}
                {this.renderCreated()}
                {this.renderUpdated()}
            </List>
        )
    }

    renderMoreInfo(){
        if(!this.props.activity.isActivity)
            return
        const handleClick = (e, titleProps) => {
            const { index } = titleProps
            const { activeIndex } = this.state
            const newIndex = activeIndex === index ? -1 : index
        
            this.setState({ activeIndex: newIndex })
        }
        return (
            <Accordion fluid styled style={{boxShadow:"unset"}}>
                <Accordion.Title
                active={this.state.activeIndex === 0}
                index={0}
                onClick={handleClick}
                >
                    <Icon name='dropdown' />
                    Plus d'informations
                </Accordion.Title>
                <Accordion.Content active={this.state.activeIndex === 0}>
                {this.renderInfo()}
                </Accordion.Content>
            </Accordion>
        )
    }

    render() {
        //This component is now used to show course or activity
        let isActivity = this.props.activity && this.props.activity.isActivity
        return (
            <Card fluid>
                <Card.Content>      
                    <Card.Header>
                        {this.renderMenu()}
                        <div className='header_title_container'>
                            {this.props.activity.title}
                            <div style={{margin:"5px"}}>
                                {this.renderAvailableLabel()}
                                {this.renderAvailableInStoreLabel()}
                            </div>
                        </div>
                    </Card.Header>
                
                    {isActivity &&<Card.Meta style={{color:"unset"}}>
                        {/* {this.renderCloneFrom()}
                        <div>
                            {this.renderCreated()}
                        </div>
                        <div>
                            {this.renderUpdated()}
                        </div> */}
                        {this.renderAccessibility()}
                        {this.renderProgressRatio()}
                        
                    </Card.Meta>}
                    {!isActivity && <Card.Meta style={{color:"unset"}}>
                        {this.renderProgressRatio()}
                    </Card.Meta>}
                </Card.Content>
                <Card.Content extra style={{color:"unset"}}>
                    {this.props.activity.description ?  
                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.activity.description)}}>

                    </div>
                    : this.renderInfo()}
                </Card.Content>
                {this.props.activity.description && this.renderMoreInfo()}
                <Card.Content extra>
                    {isActivity && this.renderOpenButton()}
                    {isActivity && this.renderTryIt()}
                    {this.renderStudientListButton()}
                </Card.Content>
                {isActivity && this.renderDeleteModal() }
                {isActivity && this.renderShareModal()}
            </Card>
        )
    }
}

InstructorActivitySummaryComponent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

export default withRouter(InstructorActivitySummaryComponent);