import { galaxiaSpaceConstants } from "../constants";
import { galaxiaSpaceActions } from "../actions";

export const initialState = {
    firstLoad: true,
    isLoading: false,
    modalIsOpen: false,
    error: null,
    status: "",

    id: null,
    nom: null,
    date: null,
    updatedAt: null,
    description: null,

    readOnly: false,

    code: null,
    codeHybride: null,
    codePy: null,

    xml: '<xml xmlns="http://www.w3.org/1999/xhtml"><block type="setup" deletable="false" x="70" y="36"></block></xml>',
    picture_file_name: null,
    picture_content_type: null,
    picture_file_size: null,
    picture_updated_at: null,
    draft: null,
    version: 0,
    galaxiaSpaceEditorState: "Blockly",
    xmlReadyToLoad: false,
    aceReadOnly: false,
    user: null,
    appState: "saved",
    savedXml: null,
};

export function galaxiaSpace(state = initialState, action) {
    switch (action.type) {
        case galaxiaSpaceConstants.FIRST_LOAD:
            return {
                ...state,
                firstLoad: false,
            };
        case galaxiaSpaceConstants.CHANGE_STATUS:
            return {
                ...state,
                status: action.payload,
            };

        // GESTION CHARGEMENT CURRENT APP
        case galaxiaSpaceConstants.SELECT_APP_STARTED: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case galaxiaSpaceConstants.SELECT_APP_SUCCEEDED: {
            return {
                ...state,
                isLoading: false,
                error: null,

                id: action.payload.id,
                nom: action.payload.nom,

                // updatedAt: action.payload.updatedAt,
                // description: action.payload.description,
                code: action.payload.code,
                codePy: action.payload.codePy,
                xml: action.payload.xml,

                // ToDo : récupérer le dernier paramètre utilisé
                galaxiaSpaceEditorState: action.payload.galaxiaSpaceEditorState,

                //
                readOnly: action.payload.readOnly,
                // picture_file_name: action.payload.picture_file_name,
                // picture_content_type: action.payload.picture_content_type,
                // picture_file_size: action.payload.picture_file_size,
                // picture_updated_at: action.payload.picture_updated_at,
                // draft: action.payload.draft,
                version: action.payload.v,
                user: action.payload.user,
                modalIsOpen: action.modalIsOpen ? action.modalIsOpen : false,
            };
        }
        case galaxiaSpaceConstants.SELECT_APP_FAILED: {
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        }

        // Gère l'ouverture de la Modal "OUVRIR" les applications
        case galaxiaSpaceConstants.OPEN_MODAL: {
            return {
                ...state,
                modalIsOpen: true,
            };
        }
        case galaxiaSpaceConstants.CLOSE_MODAL: {
            return {
                ...state,
                modalIsOpen: false,
            };
        }

        // Gère l'ouverture de la Modal "SYNCHRO" de ACE
        case galaxiaSpaceConstants.OPEN_SYNC_MODAL: {
            return {
                ...state,
                syncModalIsOpen: true,
            };
        }
        case galaxiaSpaceConstants.CLOSE_SYNC_MODAL: {
            return {
                ...state,
                syncModalIsOpen: false,
            };
        }

        // GESTION SAUVEGARDE CURRENT APP
        case galaxiaSpaceConstants.SAVE_CURRENT_APP_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null,
            };

        case galaxiaSpaceConstants.SAVE_CURRENT_APP_SUCCEEDED:
            return {
                ...state,
                isLoading: false,
                error: null,
            };

        case galaxiaSpaceConstants.SAVE_CURRENT_APP_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        //GESTION SAUVEGARDE NEW APP
        case galaxiaSpaceConstants.SAVE_NEW_APP_STARTED:
            return {
                ...initialState,
                isLoading: true,
                error: null,
                galaxiaSpaceEditorState: state.galaxiaSpaceEditorState,
            };
        //pour le back
        case galaxiaSpaceConstants.SAVE_NEW_APP_SUCCEEDED:
            return {
                ...state,
                isLoading: false,
                id: action.payload.id,
                xml: action.payload.xml,
                nom: action.payload.nom,

                ////
                code: action.payload.code,
                codePy: action.payload.codePy,
                user: action.payload.user,
                error: null,
            };

        case galaxiaSpaceConstants.SAVE_NEW_APP_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        // GESTION SAUVEGARDE / CHARGEMENT XML DE BLOCKLY / MODE HYBRIDE

        case galaxiaSpaceConstants.SAVE_XML:
            return {
                ...state,
                codeHybride: action.codeHybride,
                xml: action.xml,
            };

        case galaxiaSpace.SAVE_XML_FAILED:
            return {
                ...state,
                error: action.payload,
            };

        case galaxiaSpaceConstants.LOAD_XML:
            return {
                ...state,
                xml: action.xml,
                xmlReadyToLoad: true,
            };
        case galaxiaSpaceConstants.LOAD_XML_FAILED:
            return {
                ...state,
                xml: null,
            };
        case galaxiaSpaceConstants.LOAD_XML_DONE:
            return {
                ...state,
                xmlReadyToLoad: false,
            };

        //GESTION SAUVEGARDE CPP DE ACE

        case galaxiaSpaceConstants.SAVE_CODE_ACE:
            let s = { ...state };
            /// Ace(ancienne forme) === AceCpp
            if (
                s.galaxiaSpaceEditorState === "Ace" ||
                s.galaxiaSpaceEditorState === "AceCpp" ||
                s.galaxiaSpaceEditorState === "blocklyAndAceCpp" ||
                s.galaxiaSpaceEditorState === "blocklyAndAcePython"
            )
                s.code = action.codeAce;

            if (s.galaxiaSpaceEditorState === "AcePython") s.codePy = action.codeAce;
            ////
            return s;
        case galaxiaSpaceConstants.SAVE_CODE_ACE_FAILED:
            return {
                ...state,
                code: null,
                error: action.error,
            };

        case galaxiaSpaceConstants.EDITOR_STATE:
            return {
                ...state,
                galaxiaSpaceEditorState: action.targetState,
                aceReadOnly: action.readOnly,
                xmlReadyToLoad: action.xmlReadyToLoad ? action.xmlReadyToLoad : false,
            };

        case galaxiaSpaceConstants.SYNCHRO_CODE_ACE:
            if (state.galaxiaSpaceEditorState === "AcePython") {
                return {
                    ...state,
                    codePy: state.codeHybride === null ? "while True:\r\n\tpass\r\n" : state.codeHybride,
                };
            } else {
                return state;
            }

        // GESTION SAUVEGARDE TITRE APP
        case galaxiaSpaceConstants.TITLE_CHANGE:
            return {
                ...state,
                nom: action.nom,
            };
        case galaxiaSpaceConstants.LOAD_CODE:
            return {
                ...state,
                code: action.codeAce,
            };
        case galaxiaSpaceConstants.LOAD_CODE_PYTHON:
            return {
                ...state,
                codePy: action.codeAce,
            };
        case galaxiaSpaceConstants.INVALIDATE_GALAXIA_SPACE_STATE:
            return {
                ...initialState,
                modalIsOpen: action.modalIsOpen ? action.modalIsOpen : false,
            };

        default:
            return state;
    }
}
