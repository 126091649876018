import React, {Component} from 'react';
import * as sessionActions from '../actions/session_actions';
import HeaderMain from "../components/HeaderMain/HeaderMain";
import ResetPasswordComponent from "../components/ResetPasswordComponent/ResetPasswordComponent";
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux';
import {getActualUser} from "../reducers/user_reducer";

class ResetPassword extends Component{

    render(){
        document.title = " Changement de mot de passe - Thingz"
        return(
            <div style={{flex: 1}}>
                <HeaderMain/>
                <ResetPasswordComponent user={this.props.user}
                                        actions={this.props.actions}
                                        error={this.props.resetPassword.error}
                                        success={this.props.resetPassword.success}
                                        isFetching={this.props.resetPassword.isFetching}
                />

            </div>
        );
    }

}

const mapDispatch = (dispatch) => {
    return {
        actions: bindActionCreators(sessionActions, dispatch)
    };
};

const mapStateToProps = (state) => {
    return {
        user: getActualUser(state),
        resetPassword:state.resetPassword,
    }
}

ResetPassword.propTypes={};
export default connect(mapStateToProps,mapDispatch)(ResetPassword)
