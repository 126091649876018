const TOOLBOX = '<xml id="toolbox" style="display: none">' +
'<category name="%{BKY_CATEGORY_CONTROL}" id="control" colour="#FFAB19" secondaryColour="#CF8B17">' +
  '<block type="control_wait" id="control_wait">' +
    '<value name="DURATION">' +
      '<shadow type="math_positive_number_block">' +
        '<field name="NUM">1</field>' +
      '</shadow>' +
    '</value>' +
  '</block>' +
  '<block type="control_if" id="control_if"></block>' +
  '<block type="control_if_else" id="control_if_else"></block>' +
  '<block type="operator_lt" id="operator_lt">' +
    '<value name="OPERAND1">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">0</field>' +
      '</shadow>' +
    '</value>' +
    '<value name="OPERAND2">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">0</field>' +
      '</shadow>' +
    '</value>' +
  '</block>' +
  '<block type="operator_lt_equals" id="operator_lt_equals">' +
    '<value name="OPERAND1">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">0</field>' +
      '</shadow>' +
    '</value>' +
    '<value name="OPERAND2">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">0</field>' +
      '</shadow>' +
    '</value>' +
  '</block>' +
  '<block type="operator_equals" id="operator_equals">' +
    '<value name="OPERAND1">' +
      '<shadow type="text_brick">' +
        '<field name="TEXT">0</field>' +
      '</shadow>' +
    '</value>' +
    '<value name="OPERAND2">' +
      '<shadow type="text_brick">' +
        '<field name="TEXT">0</field>' +
      '</shadow>' +
    '</value>' +
  '</block>' +
  '<block type="operator_gt_equals" id="operator_gt_equals">' +
    '<value name="OPERAND1">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">0</field>' +
      '</shadow>' +
    '</value>' +
    '<value name="OPERAND2">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">0</field>' +
      '</shadow>' +
    '</value>' +
  '</block>' +
  '<block type="operator_gt" id="operator_gt">' +
    '<value name="OPERAND1">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">0</field>' +
      '</shadow>' +
    '</value>' +
    '<value name="OPERAND2">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">0</field>' +
      '</shadow>' +
    '</value>' +
  '</block>' +
  '<block type="operator_not_equals" id="operator_not_equals">' +
    '<value name="OPERAND1">' +
      '<shadow type="text_brick">' +
        '<field name="TEXT">0</field>' +
      '</shadow>' +
    '</value>' +
    '<value name="OPERAND2">' +
      '<shadow type="text_brick">' +
        '<field name="TEXT">0</field>' +
      '</shadow>' +
    '</value>' +
  '</block>' +
  '<block type="operator_and" id="operator_and"></block>' +
  '<block type="operator_or" id="operator_or"></block>' +
  '<block type="operator_not" id="operator_not"></block>' +
'</category>' +
'<category name="%{BKY_CATEGORY_LOOPS}"  id="loops" colour="#FFAB19" secondaryColour="#CF8B17">' +
  '<block type="control_while" id ="control_while"></block>' +
  '<block type="control_repeat_until" id="control_repeat_until"></block>' +
  '<block type="control_repeat" id="control_repeat">' +
    '<value name="TIMES">' +
      '<shadow type="math_whole_number_block">' +
        '<field name="NUM">10</field>' +
      '</shadow>' +
    '</value>' +
  '</block>' +
/*'<block type="control_forever" id="control_forever"></block>' +
  '<block type="onInit" id="control_onInit"></block>' +*/
'</category>' +
'<category name="%{BKY_CATEGORY_NUMBERS}"  id="number" colour="#59C059" secondaryColour="#389438">' +
  '<block type="operator_random" id="operator_random">' +
    '<value name="FROM">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">1</field>' +
     '</shadow>' +
    '</value>' +
    '<value name="TO">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">10</field>' +
      '</shadow>' +
    '</value>' +
  '</block>' +
  '<block type="operator_add" id="operator_add">' +
    '<value name="NUM1">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">0</field>' +
      '</shadow>' +
    '</value>' +
    '<value name="NUM2">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">0</field>' +
      '</shadow>' +
    '</value>' +
  '</block>' +
  '<block type="operator_subtract" id="operator_subtract">' +
    '<value name="NUM1">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">0</field>' +
      '</shadow>' +
    '</value>' +
    '<value name="NUM2">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">0</field>' +
      '</shadow>' +
    '</value>' +
  '</block>' +
  '<block type="operator_multiply" id="operator_multiply">' +
    '<value name="NUM1">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">0</field>' +
      '</shadow>' +
    '</value>' +
    '<value name="NUM2">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">0</field>' +
      '</shadow>' +
    '</value>' +
  '</block>' +
  '<block type="operator_divide" id="operator_divide">' +
    '<value name="NUM1">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">0</field>' +
      '</shadow>' +
    '</value>' +
    '<value name="NUM2">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">0</field>' +
      '</shadow>' +
    '</value>' +
  '</block>' +
'</category>' +
'<category name="%{BKY_CATEGORY_VARIABLES}" id="data" colour="#FF8C1A" secondaryColour="#DB6E00" custom="VARIABLE">' +
'</category>' +
'<category name="%{BKY_CATEGORY_MYBLOCKS}" id="more" colour="#FF6680" secondaryColour="#FF4D6A" custom="PROCEDURE">' +
'</category>' +
'<category name="%{BKY_LED_CATEGORY_NAME}"  id="led" colour="#9966FF" secondaryColour="#774DCB">' +
  '<block type ="led_standard" id="led_standard"></block>' +
  '<block type ="led_change" id="led_change"></block>' +
  '<block type ="timed_led" id="timed_led">' +
    '<value name="DURATION">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">1</field>' +
      '</shadow>' +
    '</value>' +
 '</block>' +
'</category>' +
'<category name="%{BKY_BUTTON_CATEGORY_NAME}" id="button" colour="#5CB1D6" secondaryColour="#2E8EB8">' +
  '<block type ="button" id="button"></block>' +
'</category>' +
'<category name="%{BKY_CATEGORY_BLOCK_NUMBER}" id="test_blocknumber" colour="#9966FF" secondaryColour="#774DCB">' +
  '<block type ="block_number_on" id="block_number_on">' +
    '<value name="NB">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">0</field>' +
      '</shadow>' +
    '</value>' +
  '</block>' +
  '<block type ="block_number_off" id="block_number_off"></block>' +
'</category>' +
'<category name="%{BKY_CATEGORY_SCREEN}" id="screen" colour="#9966FF" secondaryColour="#774DCB">' +
  '<block type ="screen_msg" id="screen_msg">' +
    '<value name="VALEUR">' +
      '<shadow type="text_brick">' +
        '<field name="TEXT"></field>' +
      '</shadow>' +
    '</value>' +
  '</block>' +
  '<block type ="screen_OnOff" id="screen_OnOff"></block>' +
  '<block type ="screen_delete_line" id="screen_delete_line"></block>' +
  //     '<block type ="color_screen_print" id="color_screen_print">' +
  //   '<value name="VALEUR">' +
  //     '<shadow type="text_brick">' +
  //       '<field name="TEXT"></field>' +
  //     '</shadow>' +
  //   '</value>' +
  //   '<value name="color">' +
  //     '<shadow type="colour_picker"></shadow>' +
  //   '</value>' +
  // '</block>' +
'</category>' +
'<category name="%{BKY_CATEGORY_COLORED_SCREEN}" id="screen" colour="#9966FF" secondaryColour="#774DCB">' +
  '<block type ="color_screen_print" id="color_screen_print">' +
    '<value name="VALEUR">' +
      '<shadow type="text_brick">' +
        '<field name="TEXT"></field>' +
      '</shadow>' +
    '</value>' +
    '<value name="color">' +
      '<shadow type="colour_picker"></shadow>' +
    '</value>' +
  '</block>' +
  '<block type ="color_screen_println" id="color_screen_println">' +
    '<value name="VALEUR">' +
      '<shadow type="text_brick">' +
        '<field name="TEXT"></field>' +
      '</shadow>' +
    '</value>' +
    '<value name="color">' +
      '<shadow type="colour_picker"></shadow>' +
    '</value>' +
  '</block>' +
  '<block type ="color_screen_clear" id="color_screen_clear">' +
    '<value name="color">' +
      '<shadow type="colour_picker"></shadow>' +
    '</value>' +
  '</block>' +
  '<block type ="color_screen_rect" id="color_screen_rect">' +
    '<value name="x">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">0</field>' +
      '</shadow>' +
    '</value>' +
    '<value name="y">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">0</field>' +
      '</shadow>' +
    '</value>' +
    '<value name="width">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">10</field>' +
      '</shadow>' +
    '</value>' +
    '<value name="height">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">10</field>' +
      '</shadow>' +
    '</value>' +
    '<value name="color">' +
      '<shadow type="colour_picker"></shadow>' +
    '</value>' +
  '</block>' +
  '<block type ="color_screen_circle" id="color_screen_circle">' +
    '<value name="x">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">0</field>' +
      '</shadow>' +
    '</value>' +
    '<value name="y">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">0</field>' +
      '</shadow>' +
    '</value>' +
    '<value name="radius">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">10</field>' +
      '</shadow>' +
    '</value>' +
    '<value name="color">' +
      '<shadow type="colour_picker"></shadow>' +
    '</value>' +
  '</block>' +
  '<block type ="color_screen_is_out_of_screen" id="color_screen_is_out_of_screen"/>' +
  '<block type ="text_concatenation" id="text_concatenation">' +
    '<value name="STR1">' +
      '<shadow type="text_brick">' +
        '<field name="TEXT"></field>' +
      '</shadow>' +
    '</value>' +
    '<value name="STR2">' +
      '<shadow type="text_brick">' +
        '<field name="TEXT"></field>' +
      '</shadow>' +
    '</value>' +
  '</block>' +
'</category>' +
'<category name="%{BKY_CATEGORY_BUZZER}" id="buzzer" colour="#CF63CF" secondaryColour="#BD42BD">' +
  '<block type ="piezo_buzzer_no_var" id="piezo_buzzer_no_var">' +
    '<value name="NOTE">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">450</field>' +
      '</shadow>' +
    '</value>' +
  '</block>' +
  '<block type ="timed_buzzer_no_var" id="timed_buzzer_no_var">' +
    '<value name="NOTE">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">450</field>' +
      '</shadow>' +
    '</value>' +
    '<value name="DURATION">' +
      '<shadow type="math_number_block">' +
        '<field name="NUM">1</field>' +
      '</shadow>' +
    '</value>' +
  '</block>' +
  '<block type ="buzzer_notes" id="buzzer_notes"></block>' +
'</category>' +
'<category name="%{BKY_CATEGORY_POTENTIOMETER}" id="potentiometer" colour="#6699FF" secondaryColour="#774DCB">' +
  '<block type ="potentiometer_brick" id="potentiometer_brick"></block>' +
'</category>' +
'<category name="%{BKY_CATEGORY_MAKEY_MAKEY}" id="cat_makey_makey" colour="#5CB1D6" secondaryColour="#2E8EB8">' +
  '<block type ="makey_makey_brick" id="makey_makey_brick"></block>' +
'</category>' +
'<category name="%{BKY_CATEGORY_INFRARED}" id="infra" colour="#5CB1D6" secondaryColour="#2E8EB8">' +
// '<block type ="infrared" id="infrared"></block>' +
'<block type ="infrared_no_var" id="infrared_no_var"></block>' +
  '<block type ="infra_boolean" id="infra_boolean"></block>' +
  '<block type ="infra_boolean_no_var" id="infra_boolean_no_var"></block>' +
'</category>' +
'<category name="%{BKY_CATEGORY_WEATHER}" id="weather" colour="#5CB1D6" secondaryColour="#2E8EB8">' +
  '<block type ="temperature_brick" id="temperature_brick"></block>' +
'</category>' +
'<category name="%{BKY_CATEGORY_BRIGHTNESS}" id="luminosity" colour="#5CB1D6" secondaryColour="#2E8EB8">' +
  '<block type ="brightness" id="brightness"></block>' +
'</category>' +

'<category name="%{BKY_CATEGORY_RELAY}" id="relay" colour="#FF8C1A" secondaryColour="#DB6E00">' +
  '<block type ="relay_brick" id="relay_brick"></block>' +
'</category>' +
'<category name="%{BKY_CATEGORY_MOTION_DETECTOR}" id="cat_motion_detector" colour="#5CB1D6" secondaryColour="#2E8EB8">' +
  '<block type ="motion_detector" id="motion_detector"></block>' +
'</category>' +
'<category name="%{BKY_CATEGORY_SERIAL_MONITOR}" id="serial_monitor" colour="#FF8C1A" secondaryColour="#DB6E00">' +
  '<block type ="serial_monitor_print_new_line" id="serial_monitor_print_new_line">' +
    '<value name="ARG">' +
      '<shadow type="text_brick">' +
        '<field name="TEXT"></field>' +
      '</shadow>' +
    '</value>' +
  '</block>' +
  '<block type ="serial_monitor_print" id="serial_monitor_print">' +
    '<value name="ARG">' +
      '<shadow type="text_brick">' +
        '<field name="TEXT"></field>' +
      '</shadow>' +
    '</value>' +
  '</block>' +
  '<block type ="serial_monitor_available" id="serial_monitor_available"></block>' +
  '<block type ="serial_monitor_read" id="serial_monitor_read"></block>' +
  '<block type ="serial_monitor_read_string" id="serial_monitor_read_string"></block>' +
'</category>' +
// '<category name="%{BKY_CATEGORY_COMPUTER}" id="computer_request" colour="#FF8C1A" secondaryColour="#DB6E00">' +
//   '<block type ="thingz_keyboard_letters" id="thingz_keyboard_letters">' +
//     '<value name="LETTERS">' +
//       '<shadow type="text_brick">' +
//         '<field name="TEXT"></field>' +
//       '</shadow>' +
//     '</value>' +
//   '</block>' +
//   '<block type ="thingz_keyboard_keys" id="thingz_keyboard_keys"></block>' +
//   '<block type ="thingz_keyboard_mode" id="thingz_keyboard_mode"></block>' +
// '</category>' +
//BLUETOOTH
'<category name="%{BKY_CATEGORY_BLUETOOTH}" id="bluetooth" colour="#FF8C1A" secondaryColour="#DB6E00">' +
// '<block type ="bluetooth_connection" id="bluetooth_connection">' +
'<block type ="bluetooth_connection_no_var" id="bluetooth_connection_no_var">' +
'<value name="RESEAU">' +
  '<shadow type="text_brick">' +
    '<field name="TEXT">ThingzBT</field>' +
  '</shadow>' +
'</value>' +
'</block>' +
// '<block type ="bluetooth_ok_connection" id="bluetooth_ok_connection">' +
'<block type ="bluetooth_ok_connection_no_var" id="bluetooth_ok_connection_no_var">' +
'<value name="RESEAU">' +
  '<shadow type="text_brick">' +
    '<field name="TEXT">ThingzBT</field>' +
  '</shadow>' +
'</value>' +
'</block>' +
// '<block type ="bluetooth_sendto" id="bluetooth_sendto">' +
'<block type ="bluetooth_sendto_no_var" id="bluetooth_sendto">' +
'<value name="DATA">' +
  '<shadow type="text_brick">' +
    '<field name="TEXT"></field>' +
  '</shadow>' +
  '</value>' +
'</block>' +
// '<block type ="bluetooth_receivefrom" id="bluetooth_recievefrom"></block>' +
'<block type ="bluetooth_receivefrom_no_var" id="bluetooth_recievefrom_no_var"></block>' +
// '<block type ="bluetooth_dataAvailable" id="bluetooth_dataAliavable"></block>' +
'<block type ="bluetooth_dataAvailable_no_var" id="bluetooth_dataAliavable_no_var"></block>' +
'</category>' +
//FIN BLUETOOTH
//WIFI 
'<category name="%{BKY_WIFI_CATEGORY_NAME}" id="wifi_category_name" colour="#FF8C1A" secondaryColour="#DB6E00">' +
  // '<block type="wifi_connect_network" id="wifi_connect_network">' +
  '<block type="wifi_connect_network_no_var" id="wifi_connect_network_no_var">' +
    '<value name="NETWORK_NAME">' +
      '<shadow type="text_brick">' +
        '<field name="TEXT">nom</field>' +
      '</shadow>' +
    '</value>' +
    '<value name="NETWORK_MDP">' +
      '<shadow type="text_brick">' +
        '<field name="TEXT">mdp</field>' +
      '</shadow>' +
    '</value>' +
  '</block>' +
  // '<block type="wifi_is_connect" id="wifi_is_connect"></block>' +
  '<block type="wifi_is_connect_no_var" id="wifi_is_connect_no_var"></block>' +
  // '<block type="wifi_send_data" id="wifi_send_data">' +
  '<block type="wifi_send_data_no_var" id="wifi_send_data_no_var">' +
    '<value name="KEY">' +
      '<shadow type="text_brick">' +
        '<field name="TEXT">nom de la donnée</field>' +
      '</shadow>' +
    '</value>' +
    '<value name="VALUE">' +
      '<shadow type="text_brick">' +
        '<field name="TEXT">valeur</field>' +
      '</shadow>' +
    '</value>' +
  '</block>' +
'</category>' +

'</xml>';

const TOOLBOX_GALAXIA = '<xml xmlns="https://developers.google.com/blockly/xml" id="toolbox-categories-typed-variables" style="display: none">'+
  '<category name="%{BKY_CATEGORY_DISPLAY}" categorystyle="display_category">'+
    // '<label text="%{BKY_SUBCATEGORY_GALAXIA}"></label>'+
    '<block type="display_galaxia_show">'+
      '<value name="VALUE">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
    '</block>'+
    '<block type="display_galaxia_clear">'+
    '</block>'+
    '<block type="display_galaxia_led_set_colors">'+
      '<value name="RED">'+
          '<shadow type="math_number"></shadow>'+
      '</value>'+
      '<value name="GREEN">'+
          '<shadow type="math_number"></shadow>'+
      '</value>'+
      '<value name="BLUE">'+
          '<shadow type="math_number"></shadow>'+
      '</value>'+
    '</block>'+
    '<block type="display_galaxia_led_set_red">'+
      '<value name="RED">'+
          '<shadow type="math_number"></shadow>'+
      '</value>'+
    '</block>'+
    '<block type="display_galaxia_led_set_green">'+
      '<value name="GREEN">'+
          '<shadow type="math_number"></shadow>'+
      '</value>'+
    '</block>'+
    '<block type="display_galaxia_led_set_blue">'+
      '<value name="BLUE">'+
          '<shadow type="math_number"></shadow>'+
      '</value>'+
    '</block>'+
    '<block type="display_galaxia_led_get_red"></block>'+
    '<block type="display_galaxia_led_get_green"></block>'+
    '<block type="display_galaxia_led_get_blue"></block>'+
    '<label text="%{BKY_SUBCATEGORY_DISPLAY_PLOT}"></label>'+
    '<block type="display_galaxia_set_mode"></block>'+
    '<block type="display_galaxia_plot_add_point">'+
      '<value name="POINT">'+
        '<shadow type="math_number">'+
          '<field name="NUM">50</field>'+
        '</shadow>'+
      '</value>'+
    '</block>'+
    '<block type="display_galaxia_plot_set_y_scale">'+
      '<value name="MIN">'+
        '<shadow type="math_number">'+
          '<field name="NUM">0</field>'+
        '</shadow>'+
      '</value>'+
      '<value name="MAX">'+
        '<shadow type="math_number">'+
          '<field name="NUM">100</field>'+
        '</shadow>'+
      '</value>'+
    '</block>'+
    '<block type="display_galaxia_animate_function">'+
      '<value name="INTERVAL">'+
        '<shadow type="math_number">'+
          '<field name="NUM">1</field>'+
        '</shadow>'+
      '</value>'+
      '<value name="POINT">'+
        '<shadow type="math_number">'+
          '<field name="NUM">50</field>'+
        '</shadow>'+
      '</value>'+
    '</block>'+
    // '<label text="%{BKY_SUBCATEGORY_MICROBIT}"></label>'+
    // '<block type="show_number"></block>'+
    // '<block type="show_leds"></block>'+
    // '<block type="show_string"></block>'+
    // '<block type="show_icon"></block>'+
    // '<block type="show_gauge"></block>'+
    // '<block type="set_pixel"></block>'+
    // '<block type="set_light_pixel"></block>'+
    // '<block type="show_clock"></block>'+
    // '<block type="show_arrow"></block>'+
    // '<block type="clear"></block>'+
    // '<block type="display_lcdSetText"></block>'+
    // '<block type="display_lcdClear"></block>'+
    // '<block type="display_addOledText"></block>'+
    // '<block type="display_setOledPixel"></block>'+
    // '<block type="display_showOledIcon"></block>'+
    // '<block type="display_clearOledScreen"></block>'+
    // '<block type="display_setGroveSocketLed"></block>'+
    // '<block type="display_setLEDintensity"></block>'+
    // '<block type="display_defineNeopixel"></block>'+
    // '<block type="display_controlNeopixelLed"></block>'+
    // '<block type="display_controlColorNeopixelLed"></block>'+
    // '<block type="display_neopixel_controlAllLedRGB"></block>'+
    // '<block type="display_neopixel_controlAllLedPalette"></block>'+
    // '<block type="display_rainbowNeopixel"></block>'+
    // '<block type="display_setNumberGrove4Digit"></block>'+
    // '<block type="display_setClockGrove4Digit"></block>'+
    // '<block type="display_setLevelLedBar"></block>'+
    // '<block type="display_setTrafficLight"></block>'+
    // '<block type="display_morpionNewGame"></block>'+
    // '<block type="display_morpionMoveCursor"></block>'+
    // '<block type="display_morpionSetPlayerFigure"></block>'+
    // '<block type="display_morpionIsEndGame"></block>'+
    // '<block type="display_games_createSprite"></block>'+
    // '<block type="display_games_deleteSprite"></block>'+
    // '<block type="display_games_isSpriteDeleted"></block>'+
    // '<block type="display_games_moveSprite"></block>'+
    // '<block type="display_games_getSpritePosition"></block>'+
    // '<block type="display_games_changeScore"></block>'+
    // '<block type="display_games_getScore"></block>'+
    // '<block type="display_games_stopGame"></block>'+
    // '<block type="display_games_isEndGame"></block>'+
    // '<block type="display_games_restartGame"></block>'+
  '</category>'+
  '<category name="%{BKY_CATEGORY_IO}" categorystyle="io_category">'+
    // '<label text="%{BKY_SUBCATEGORY_GALAXIA}"></label>'+
    '<block type="io_galaxia_pause">'+
      '<value name="TIME">'+
          '<shadow type="math_number"></shadow>'+
      '</value>'+
    '</block>'+
    '<block type="time_galaxia_timer_start"/>'+
    '<block type="time_galaxia_timer_measure"/>'+
    '<block type="io_ifButtonPressed"></block>'+
    '<block type="io_isButtonPressed"></block>'+
    '<block type="io_onButtonEvent"></block>'+
    '<block type="io_ifTouchSensitiveButtonTouched"></block>'+
    '<block type="io_isTouchSensitiveButtonTouched"></block>'+
    '<block type="io_onTouchSensitiveButtonEvent"></block>'+
    // '<label text="%{BKY_SUBCATEGORY_MICROBIT}"></label>'+
    // '<block type="io_pause"></block>'+
    // '<block type="io_initChronometer"></block>'+
    // '<block type="io_getChronometer"></block>'+
    // '<block type="io_onPinPressed"></block>'+
    // '<block type="io_onMovement"></block>'+
    // '<block type="io_isPinPressed"></block>'+
    // '<label text="%{BKY_SUBCATEGORY_MICROPHONE}"></label>'+
    // '<block type="io_micro_onSoundDetected"></block>'+
    // '<block type="io_micro_getCurrentSound"></block>'+
    // '<block type="io_micro_wasSoundDetected"></block>'+
    // '<block type="io_micro_getSoundLevel"></block>'+
    // '<block type="io_micro_getHistorySounds"></block>'+
    // '<block type="io_micro_setSoundThreshold"></block>'+
    // '<block type="io_micro_soundCondition"></block>'+
    // '<label text="%{BKY_SUBCATEGORY_EXTERNAL_MODULES}"></label>'+
    // '<block type="io_getKeypadNumber"></block>'+
    // '<block type="io_getGroveThumbJoystick"></block>'+
    // '<block type="io_getGroveColoredButton"></block>'+
    // '<block type="io_setGroveColoredButton"></block>'+
    // '<block type="io_getGroveSlidePotentiometer"></block>'+
    // '<block type="io_getGroveRotaryAngle"></block>'+
    // '<block type="io_getGroveTactile"></block>'+
    // '<block type="io_getGroveButton"></block>'+
    // '<block type="io_getGroveSwitch"></block>'+
    '<label text="%{BKY_SUBCATEGORY_PINS}"></label>'+
    '<block type="io_galaxia_digital_signal"></block>'+
    '<block type="io_galaxia_readDigitalPin"></block>'+
    '<block type="io_galaxia_writeDigitalPin"></block>'+
    '<block type="io_galaxia_readAnalogPin"></block>'+
    // '<block type="io_digital_signal"></block>'+
    // '<block type="io_readDigitalPin"></block>'+
    // '<block type="io_writeDigitalPin"></block>'+
    // '<block type="io_readAnalogPin"></block>'+
    // '<block type="io_writeAnalogPin"></block>'+
    // '<block type="io_setPwm"></block>'+
    // '<block type="io_readPulseIn"></block>'+
    '<label text="%{BKY_SUBCATEGORY_SIGNALS}"></label>'+
    '<block type="io_isButtonPressedSignal"></block>'+
    '<block type="io_isTouchSensitiveButtonTouchedSignal"></block>'+
    '<block type="io_galaxia_readDigitalPinSignal"></block>'+
      
  '</category>'+
  '<category name="%{BKY_CATEGORY_COMMUNICATION}" categorystyle="communication_category">'+
    '<label text="%{BKY_SUBCATEGORY_SIMPLE_WIFI_COMMON}"></label>'+
    '<block type="communication_galaxia_simpleWifi_connect">'+
        '<value name="SSID">'+
          '<shadow type="text"></shadow>'+
        '</value>'+
        '<value name="PWD">'+
          '<shadow type="text"></shadow>'+
        '</value>'+
        '<value name="IP">'+
          '<shadow type="text"></shadow>'+
        '</value>'+
      '</block>'+
      '<block type="communication_galaxia_simpleWifi_connect_basic">'+
        '<value name="SSID">'+
          '<shadow type="text"></shadow>'+
        '</value>'+
        '<value name="PWD">'+
          '<shadow type="text"></shadow>'+
        '</value>'+
      '</block>'+
      '<block type="communication_galaxia_simpleWifi_create_ap">'+
        '<value name="SSID">'+
          '<shadow type="text"></shadow>'+
        '</value>'+
        '<value name="PWD">'+
          '<shadow type="text"></shadow>'+
        '</value>'+
      '</block>'+
    '<block type="communication_galaxia_simpleWifi_getMyIp"/>'+
    '<label text="%{BKY_SUBCATEGORY_SIMPLE_WIFI_SERVER}"></label>'+
    '<block type="communication_galaxia_simpleWifi_server_is_client_connected"></block>'+
    '<block type="communication_galaxia_simpleWifi_getLastConnectedIp"></block>'+
    '<block type="communication_galaxia_simpleWifi_receive_basic">'+
      '<value name="IP">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
    '</block>'+
    '<block type="communication_galaxia_simpleWifi_receive_delimiter"/>'+
    '<block type="communication_galaxia_simpleWifi_server_respond">'+
      '<value name="VALUE">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
    '</block>'+
    '<block type="communication_galaxia_simpleWifi_server_respond_with_delimiter">'+
      '<value name="VALUE">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
    '</block>'+
    '<label text="%{BKY_SUBCATEGORY_SIMPLE_WIFI_CLIENT}"></label>'+
    '<block type="communication_galaxia_simpleWifi_send_basic">'+
      '<value name="VALUE">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
      '<value name="IP">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
    '</block>'+
    '<block type="communication_galaxia_simpleWifi_send_basic_with_delimiter">'+
      '<value name="VALUE">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
      '<value name="IP">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
    '</block>'+
    '<block type="communication_galaxia_simpleWifi_send_wait_respond">'+
      '<value name="VALUE">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
      '<value name="IP">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
    '</block>'+
    '<block type="communication_galaxia_simpleWifi_send_wait_respond_with_delimiter">'+
      '<value name="VALUE">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
      '<value name="IP">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
    '</block>'+
    // '<label text="%{BKY_SUBCATEGORY_SIMPLE_HTTP}"></label>'+
    '<label text="%{BKY_SUBCATEGORY_SIMPLE_HTTP}"></label>'+
    '<block type="communication_galaxia_simpleHttp_wait_request">'+
    '</block>'+
    '<block type="communication_galaxia_simpleHttp_respond_complete">'+
      '<value name="VALUE">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
      '<value name="CODE">'+
        '<shadow type="math_number">'+
          '<field name="NUM">200</field>'+
        '</shadow>'+
      '</value>'+
    '</block>'+
    '<block type="communication_galaxia_simpleHttp_generate_page_no_refresh">'+
      '<value name="RELOAD">'+
        '<shadow type="math_number">'+
          '<field name="NUM">10</field>'+
        '</shadow>'+
      '</value>'+
    '</block>'+
    '<block type="communication_galaxia_simpleHttp_generate_page">'+
      '<value name="RELOAD">'+
        '<shadow type="math_number">'+
          '<field name="NUM">10</field>'+
        '</shadow>'+
      '</value>'+
    '</block>'+
    '<block type="communication_galaxia_simpleHttp_generate_page_no_refresh">'+
      '<value name="RELOAD">'+
        '<shadow type="math_number">'+
          '<field name="NUM">10</field>'+
        '</shadow>'+
      '</value>'+
    '</block>'+
    '<block type="communication_galaxia_simpleHttp_add_to_page">'+
      '<value name="DATA">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
    '</block>'+
    '<block type="communication_galaxia_simpleHttp_add_button_to_page">'+
      '<value name="TITLE">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
      '<value name="CMD">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
    '</block>'+
    '<block type="communication_galaxia_simpleHttp_button_event">'+
      '<value name="CMD">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
    '</block>'+
    '<block type="communication_galaxia_simpleHttp_add_gauge_to_page">'+
      '<value name="TITLE">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
      '<value name="VALUE">'+
        '<shadow type="math_number">'+
          '<field name="NUM">10</field>'+
        '</shadow>'+
      '</value>'+
      '<value name="MIN">'+
        '<shadow type="math_number">'+
          '<field name="NUM">0</field>'+
        '</shadow>'+
      '</value>'+
      '<value name="MAX">'+
        '<shadow type="math_number">'+
          '<field name="NUM">100</field>'+
        '</shadow>'+
      '</value>'+
    '</block>'+
    '<label text="%{BKY_SUBCATEGORY_SIMPLE_MQTT}"></label>'+
    '<block type="communication_galaxia_simple_mqtt_connect_complete">'+
      '<value name="BROKER">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
      '<value name="USERNAME">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
      '<value name="PASSWORD">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
      '<value name="PORT">'+
        '<shadow type="math_number">'+
          '<field name="NUM">1883</field>'+
        '</shadow>'+
      '</value>'+
    '</block>'+
    '<block type="communication_galaxia_simple_mqtt_subscribe">'+
      '<value name="TOPIC">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
    '</block>'+
    '<block type="communication_galaxia_simple_mqtt_publish">'+
      '<value name="TOPIC">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
      '<value name="VALUE">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
    '</block>'+
    '<block type="communication_galaxia_simple_mqtt_receive"></block>'+
    '<block type="communication_galaxia_simple_mqtt_on_connect"></block>'+
    '<block type="communication_galaxia_simple_mqtt_on_disconnect"></block>'+
    '<block type="communication_galaxia_simple_mqtt_is_connected"></block>'+
    '<label text="%{BKY_SUBCATEGORY_RADIO}"></label>'+
    '<block type="communication_galaxia_radioSendValue">'+
      '<value name="VALUE">'+
        '<shadow type="text"></shadow>'+
      '</value>'+
    '</block>'+
    '<block type="communication_galaxia_onRadioReceived"></block>'+
    // '<block type="communication_galaxia_RadioDataReceived"></block>'+
    // '<label text="%{BKY_SUBCATEGORY_MICROBIT}"></label>'+
    // '<block type="communication_radioSendString"></block>'+
    // '<block type="communication_radioSendNumber"></block>'+
    // '<block type="communication_radioSendValue"></block>'+
    // '<block type="communication_onRadioDataReceived"></block>'+
    // '<block type="communication_onRadioNumberReceived"></block>'+
    // '<block type="communication_onRadioValueReceived"></block>'+
    // '<block type="communication_radioConfig"></block>'+
    // '<block type="communication_serialWrite"></block>'+
    // '<block type="communication_onSerialDataReceived"></block>'+
    // '<block type="communication_graphSerialWrite"></block>'+
    // '<block type="communication_graphSerialWrite_datasFormat"></block>'+
    // '<block type="communication_playComputerMusic"></block>'+
    // '<block type="communication_playComputerFrequency"></block>'+
    // '<block type="communication_stopComputerMusic"></block>'+
    // '<block type="communication_serialRedirectUSB"></block>'+
    // '<block type="communication_serialInit"></block>'+
    // '<label text="%{BKY_SUBCATEGORY_SERIAL_CONNECTION}"></label>'+
    // '<label text="%{BKY_SUBCATEGORY_DATA_LOGGING}"></label>'+
    // '<block type="communication_writeOpenLogSd"></block>'+
    // '<label text="%{BKY_SUBCATEGORY_WIRELESS_COMMUNICATION}"></label>'+
    // '<block type="communication_sendBluetoothData"></block>'+
    // '<block type="communication_onBluetoothDataReceived"></block>'+
    // '<label text="%{BKY_SUBCATEGORY_TRACKING_MODULES}"></label>'+
    // '<block type="communication_onGPSDataReceived"></block>'+
    // '<block type="communication_analyzeGPSInfo"></block>'+
  '</category>'+
  '<category name="%{BKY_CATEGORY_SENSORS}" categorystyle="sensors_category">'+
    // '<label text="%{BKY_SUBCATEGORY_GALAXIA}"></label>'+
    '<label text="%{BKY_SUBCATEGORY_SENSOR_ACCELEROMETER}"></label>'+
    '<block type="sensors_galaxia_onAccelerometerEvent"></block>'+
    '<block type="sensors_galaxia_getAcceleration"></block>'+
    '<label text="%{BKY_SUBCATEGORY_SENSOR_MAGNETOMETER}"></label>'+
    '<block type="sensors_galaxia_getMagneticForce"></block>'+
    '<block type="sensors_getCompass"></block>'+
    '<block type="sensors_getCompassSignal"></block>'+
    // '<label text="%{BKY_SUBCATEGORY_MICROBIT}"></label>'+
    // '<block type="sensors_getAcceleration"></block>'+
    '<label text="%{BKY_SUBCATEGORY_SENSOR_LIGHT_SENSOR}"></label>'+
    '<block type="sensors_getLight"></block>'+
    // '<block type="sensors_calibrateCompass"></block>'+
    // '<block type="sensors_getCompass"></block>'+
    // '<block type="sensors_isCompassCalibrated"></block>'+
    '<label text="%{BKY_SUBCATEGORY_SENSOR_TEMPERATURE}"></label>'+
    '<block type="sensors_getTemperature"></block>'+
    '<label text="%{BKY_SUBCATEGORY_SIGNALS}"></label>'+
    '<block type="sensors_galaxia_getAccelerationSignal"></block>'+
    '<block type="sensors_galaxia_getMagneticForceSignal"></block>'+
    '<block type="sensors_getLightSignal"></block>'+
    '<block type="sensors_getTemperatureSignal"></block>'+
    // '<block type="sensors_getRotation"></block>'+
    // '<block type="sensors_getMagneticForce"></block>'+
    // '<label text="%{BKY_SUBCATEGORY_SENSORS_GAS}"></label>'+
    // '<block type="sensors_getSgp30Gas"></block>'+
    // '<block type="sensors_getMultichannelGas"></block>'+
    // '<block type="sensors_getMultichannelGasV2"></block>'+
    // '<block type="sensors_getO2gas"></block>'+
    // '<block type="sensors_getAirQualityValue"></block>'+
    // '<block type="sensors_getParticulateMatter"></block>'+
    // '<block type="sensors_getBmp280Data"></block>'+
    // '<label text="%{BKY_SUBCATEGORY_SENSORS_CLIMATE}"></label>'+
    // '<block type="sensors_getGroveMoisture"></block>'+
    // '<block type="sensors_getGroveTemperature"></block>'+
    // '<block type="sensors_getGroveHighTemperature"></block>'+
    // '<block type="sensors_dhtReadData"></block>'+
    // '<block type="sensors_TH02readData"></block>'+
    // '<block type="sensors_SHT31readData"></block>'+
    // '<block type="sensors_getGroveWaterAmount"></block>'+
    // '<block type="sensors_getRainGauge"></block>'+
    // '<block type="sensors_getAnemometer"></block>'+
    // '<block type="sensors_getGroveLight"></block>'+
    // '<label text="%{BKY_SUBCATEGORY_SENSORS_SOUNDLIGHT}"></label>'+
    // '<block type="sensors_getSi1145Light"></block>'+
    // '<block type="sensors_getUVindex"></block>'+
    // '<block type="sensors_getGroveSound"></block>'+
    // '<block type="sensors_getGroveUltrasonicRanger"></block>'+
    // '<label text="%{BKY_SUBCATEGORY_SENSORS_DISTANCEMOVEMENT}"></label>'+
    // '<block type="sensors_getGesture"></block>'+
    // '<block type="sensors_onGestureTypeDetected"></block>'+
    // '<block type="sensors_getGroveLineFinder"></block>'+
    // '<block type="sensors_getGroveTilt"></block>'+
    // '<block type="sensors_getGroveMotion"></block>'+
    // '<block type="sensors_getPiezoVibration"></block>'+
    // '<block type="sensors_getGroveButton"></block>'+
    // '<label text="%{BKY_SUBCATEGORY_SENSORS_OTHER}"></label>'+
  '</category>'+
  '<category name="%{BKY_CATEGORY_ACTUATORS}" categorystyle="actuators_category">'+
    '<label text="%{BKY_SUBCATEGORY_GALAXIA}"></label>'+
    '<block type="actuators_galaxia_sound_power">'+
      '<value name="FREQUENCY">'+
        '<shadow type="math_number">'+
          '<field name="NUM">440</field>'+
        '</shadow>'+
      '</value>'+
    '</block>'+
    '<block type="actuators_galaxia_play_frequency_with_duration">'+
    '<value name="FREQUENCY">'+
     '<shadow type="math_number">'+
       '<field name="NUM">440</field>'+
     '</shadow>'+
    '</value>'+
     '<value name="DURATION">'+
      '<shadow type="math_number">'+
       '<field name="NUM">500</field>'+
      '</shadow>'+
     '</value>'+
   '</block>'+
    '<block type="actuators_play_frequency">'+
      '<value name="FREQ">'+
        '<shadow type="math_number">'+
          '<field name="NUM">440</field>'+
        '</shadow>'+
      '</value>'+
    '</block>'+
    '<block type="actuators_galaxia_play_set_volume">'+
      '<value name="VOLUME">'+
        '<shadow type="math_number">'+
          '<field name="NUM">100</field>'+
        '</shadow>'+
      '</value>'+
    '</block>'+
    '<block type="actuators_galaxia_play_sample">'+
      '<value name="FILE">'+
        '<shadow type="text">'+
          '<field name="TEXT">fichier</field>'+
        '</shadow>'+
      '</value>'+
    '</block>'+
    // '<label text="Moteurs"></label>'+
    // '<block type="actuators_setServoAngle"></block>'+
    // '<block type="actuators_continuousServo_setSpeed"></block>'+
    // '<block type="actuators_setMotorPower"></block>'+
    // '<block type="actuators_setGroveRelayState"></block>'+
    // '<block type="actuators_setVibrationMotorState"></block>'+
    // '<label text="Musique"></label>'+
    // '<block type="actuators_playMusicGroveBuzzer"></block>'+
    // '<block type="actuators_music_playSong"></block>'+
    // '<block type="actuators_music_playNotes"></block>'+
    // '<block type="actuators_music_note"></block>'+
    // '<block type="actuators_music_playFrequency"></block>'+
    // '<block type="actuators_music_stop"></block>'+
    // '<block type="actuators_music_setVolume"></block>'+
    // '<block type="actuators_music_setTempo"></block>'+
    // '<block type="actuators_music_getTempo"></block>'+
    // '<label text="Speech"></label>'+
    // '<block type="actuators_speech_saySomething"></block>'+
  '</category>'+
  '<category name="%{BKY_CATEGORY_ROBOTS}" categorystyle="robots_category">'+
    '<label text="Maqueen"></label>'+
  //   '<block type="robots_getMaqueenUltrasonicRanger"></block>'+
  //   
  //   '<block type="robots_controlMaqueenLed"></block>'+
    '<block type="robots_galaxia_setMaqueenGo">'+
      '<value name="SPEED">'+
        '<shadow type="math_number">'+
          '<field name="NUM">255</field>'+
        '</shadow>'+
      '</value>'+
    '</block>'+
    '<block type="robots_galaxia_controlMaqueenMotor">'+
      '<value name="SPEED">'+
        '<shadow type="math_number">'+
          '<field name="NUM">255</field>'+
        '</shadow>'+
      '</value>'+
    '</block>'+
    '<block type="robots_galaxia_stopMaqueenMotors"></block>'+
    '<block type="robots_galaxia_readMaqueenPatrol"></block>'+
    '<block type="robots_galaxia_readMaqueenPlusPatrol"></block>'+
  //   '<block type="robots_setMaqueenServoAngle"></block>'+
  //   '<block type="robots_setMaqueenNeopixel"></block>'+
  //   '<block type="robots_setMaqueenNeopixelPalette"></block>'+
  //   '<block type="robots_setMaqueenRainbow"></block>'+
  //   '<block type="robots_setMaqueenBuzzer"></block>'+
  //   '<block type="robots_playMaqueenMusic"></block>'+
    '<label text="Codo"></label>'+
    '<block type="robots_galaxia_setCodoGo">'+
      '<value name="SPEED">'+
        '<shadow type="math_number">'+
          '<field name="NUM">100</field>'+
        '</shadow>'+
      '</value>'+
    '</block>'+
    '<block type="robots_galaxia_controlCodoMotor">'+
      '<value name="SPEED">'+
        '<shadow type="math_number">'+
          '<field name="NUM">100</field>'+
        '</shadow>'+
      '</value>'+
    '</block>'+
    '<block type="robots_galaxia_stopCodoMotors"></block>'+
  //   '<label text="Bit:Bot"></label>'+
  //   '<block type="robots_readBitBotLightSensor"></block>'+
  //   '<block type="robots_setBitbotGo"></block>'+
  //   '<block type="robots_controlBitBotMotor"></block>'+
  //   '<block type="robots_stopBitBotMotors"></block>'+
  //   '<block type="robots_setBitBotNeopixelPalette"></block>'+
  //   '<block type="robots_setBitBotNeopixel"></block>'+
  //   '<block type="robots_setBitBotRainbow"></block>'+
  //   '<block type="robots_readBitBotPatrol"></block>'+
  //   '<label text="Gamepad"></label>'+
  //   '<block type="robots_setGamepadLED"></block>'+
  //   '<block type="robots_setGamepadMotorVibration"></block>'+
  //   '<block type="robots_setGamepadBuzzerFreq"></block>'+
  //   '<block type="robots_playGamepadMusic"></block>'+
  //   '<block type="robots_onGamepadButtonEvent"></block>'+
  '</category>'+
  '<category name="%{BKY_CATEGORY_GROVE}" categorystyle="grove_category">'+
    '<block type="grove_galaxia_temperature"/>'+
    '<block type="grove_galaxia_temperature_signal"/>'+
    '<block type="grove_galaxia_ultrasonic"></block>'+
    '<block type="grove_galaxia_dht11"></block>'+
    '<block type="grove_galaxia_dht11_signal"></block>'+
    '<block type="grove_galaxia_servo">'+
    '<value name="ANGLE">'+
      '<shadow type="math_number">'+
        '<field name="NUM">90</field>'+
      '</shadow>'+
    '</value>'+
  '</block>'+
  '</category>'+
  '<sep></sep>'+
  '<category name="%{BKY_CATEGORY_LOGIC}" categorystyle="logic_category">'+
    '<block type="controls_if"></block>'+
    '<block type="logic_compare"></block>'+
    '<block type="logic_operation"></block>'+
    '<block type="logic_negate"></block>'+
    '<block type="logic_boolean"></block>'+
    '<block type="logic_null" disabled="false"></block>'+
    '<block type="logic_ternary"></block>'+
  '</category>'+
  '<category name="%{BKY_CATEGORY_LOOPS}" categorystyle="loop_category">'+
    // '<block type="on_start"></block>'+
    // '<block type="forever"></block>'+  
    '<block type="controls_repeat">'+
      '<value name="TIMES">'+
      '<shadow type="math_number">'+
        '<field name="NUM">10</field>'+
      '</shadow>'+
      '</value>'+
    '</block>'+
    '<block type="controls_whileUntil"></block>'+
    '<block type="controls_for">'+
    '  <value name="FROM">'+
    '    <shadow type="math_number">'+
    '      <field name="NUM">1</field>'+
    '    </shadow>'+
    '  </value>'+
    '  <value name="TO">'+
    '    <shadow type="math_number">'+
    '      <field name="NUM">10</field>'+
    '    </shadow>'+
    '  </value>'+
    '  <value name="BY">'+
    '    <shadow type="math_number">'+
    '      <field name="NUM">1</field>'+
    '    </shadow>'+
    '  </value>'+
    '</block>'+
    '<block type="controls_forEach"></block>'+
    '<block type="controls_flow_statements"></block>'+
    '   </category>'+
    '   <category name="%{BKY_CATEGORY_MATH}" categorystyle="math_category">'+
    '<block type="math_number" gap="32">'+
    '  <field name="NUM">123</field>'+
    '</block>'+
    '<block type="math_arithmetic">'+
    '  <value name="A">'+
    '    <shadow type="math_number">'+
    '      <field name="NUM">1</field>'+
    '    </shadow>'+
    '  </value>'+
    '  <value name="B">'+
    '    <shadow type="math_number">'+
    '      <field name="NUM">1</field>'+
    '    </shadow>'+
    '  </value>'+
    '</block>'+
    '<block type="math_single">'+
    '  <value name="NUM">'+
    '    <shadow type="math_number">'+
    '      <field name="NUM">9</field>'+
    '    </shadow>'+
    '  </value>'+
    '</block>'+
    '<block type="math_trig">'+
    '  <value name="NUM">'+
    '    <shadow type="math_number">'+
    '      <field name="NUM">45</field>'+
    '    </shadow>'+
    '  </value>'+
    '</block>'+
    '<block type="math_constant"></block>'+
    '<block type="math_number_property">'+
    '  <value name="NUMBER_TO_CHECK">'+
    '    <shadow type="math_number">'+
    '      <field name="NUM">0</field>'+
    '    </shadow>'+
    '  </value>'+
    '</block>'+
    '<block type="math_round">'+
    '  <value name="NUM">'+
    '    <shadow type="math_number">'+
    '      <field name="NUM">3.1</field>'+
    '    </shadow>'+
    '  </value>'+
    '</block>'+
    '<block type="math_modulo">'+
    '  <value name="DIVIDEND">'+
    '    <shadow type="math_number">'+
    '      <field name="NUM">64</field>'+
    '    </shadow>'+
    '  </value>'+
    '  <value name="DIVISOR">'+
    '    <shadow type="math_number">'+
    '      <field name="NUM">10</field>'+
    '    </shadow>'+
    '  </value>'+
    '</block>'+
    '<block type="math_constrain">'+
    '  <value name="VALUE">'+
    '    <shadow type="math_number">'+
    '      <field name="NUM">50</field>'+
    '    </shadow>'+
    '  </value>'+
    '  <value name="LOW">'+
    '    <shadow type="math_number">'+
    '      <field name="NUM">1</field>'+
    '    </shadow>'+
    '  </value>'+
    '  <value name="HIGH">'+
    '    <shadow type="math_number">'+
    '      <field name="NUM">100</field>'+
    '    </shadow>'+
    '  </value>'+
    '</block>'+
    '<block type="math_random_int">'+
    '  <value name="FROM">'+
    '    <shadow type="math_number">'+
    '      <field name="NUM">1</field>'+
    '    </shadow>'+
    '  </value>'+
    '  <value name="TO">'+
    '    <shadow type="math_number">'+
    '      <field name="NUM">100</field>'+
    '    </shadow>'+
    '  </value>'+
    '</block>'+
    '<block type="math_random_float"></block>'+
    '<block type="math_atan2">'+
    '  <value name="X">'+
    '    <shadow type="math_number">'+
    '      <field name="NUM">1</field>'+
    '    </shadow>'+
    '  </value>'+
    '  <value name="Y">'+
    '    <shadow type="math_number">'+
    '      <field name="NUM">1</field>'+
    '    </shadow>'+
    '  </value>'+
    '</block>'+
    '   </category>'+
    '   <category name="%{BKY_CATEGORY_TEXT}" categorystyle="text_category">'+
    '<block type="text"></block>'+
    '<!-- <block type="text_multiline"></block> -->'+
    '<block type="text_join"></block>'+
    '<block type="text_append">'+
    '  <value name="TEXT">'+
    '    <shadow type="text"></shadow>'+
    '  </value>'+
    '</block>'+
    '<block type="text_length">'+
    '  <value name="VALUE">'+
    '    <shadow type="text">'+
    '      <field name="TEXT">abc</field>'+
    '    </shadow>'+
    '  </value>'+
    '</block>'+
    '<block type="text_isEmpty">'+
    '  <value name="VALUE">'+
    '    <shadow type="text">'+
    '      <field name="TEXT"></field>'+
    '    </shadow>'+
    '  </value>'+
    '</block>'+
    '<block type="text_indexOf">'+
    '  <value name="VALUE">'+
    '    <block type="variables_get">'+
    '      <field name="VAR">text</field>'+
    '    </block>'+
    '  </value>'+
    '  <value name="FIND">'+
    '    <shadow type="text">'+
    '      <field name="TEXT">abc</field>'+
    '    </shadow>'+
    '  </value>'+
    '</block>'+
    '<block type="text_charAt">'+
    '  <value name="VALUE">'+
    '    <block type="variables_get">'+
    '      <field name="VAR">text</field>'+
    '    </block>'+
    '  </value>'+
    '</block>'+
    '<block type="text_getSubstring">'+
    '  <value name="STRING">'+
    '    <block type="variables_get">'+
    '      <field name="VAR">text</field>'+
    '    </block>'+
    '  </value>'+
    '</block>'+
    '<block type="text_changeCase">'+
    '  <value name="TEXT">'+
    '    <shadow type="text">'+
    '      <field name="TEXT">abc</field>'+
    '    </shadow>'+
    '  </value>'+
    '</block>'+
    '<block type="text_trim">'+
    '  <value name="TEXT">'+
    '    <shadow type="text">'+
    '      <field name="TEXT">abc</field>'+
    '    </shadow>'+
    '  </value>'+
    '</block>'+
    '<block type="text_count">'+
    '  <value name="SUB">'+
    '    <shadow type="text"></shadow>'+
    '  </value>'+
    '  <value name="TEXT">'+
    '    <shadow type="text"></shadow>'+
    '  </value>'+
    '</block>'+
    '<block type="text_replace">'+
    '  <value name="FROM">'+
    '    <shadow type="text"></shadow>'+
    '  </value>'+
    '  <value name="TO">'+
    '    <shadow type="text"></shadow>'+
    '  </value>'+
    '  <value name="TEXT">'+
    '    <shadow type="text"></shadow>'+
    '  </value>'+
    '</block>'+
    '<block type="text_reverse">'+
    '  <value name="TEXT">'+
    '    <shadow type="text"></shadow>'+
    '  </value>'+
    '</block>'+
    '<!-- <label text="Input/Output:" web-class="ioLabel"></label> -->'+
    '<!-- <block type="text_print">'+
    '    <value name="TEXT">'+
    '      <shadow type="text">'+
    '        <field name="TEXT">abc</field>'+
    '      </shadow>'+
    '    </value>'+
    '  </block> -->'+
    '<!-- <block type="text_prompt_ext">'+
    '    <value name="TEXT">'+
    '      <shadow type="text">'+
    '        <field name="TEXT">abc</field>'+
    '      </shadow>'+
    '    </value>'+
    '  </block> -->'+
    '   </category>'+
    '   <category name="%{BKY_CATEGORY_VARIABLES}" categorystyle="variable_category" custom="VARIABLE"></category>'+
    '   <category name="%{BKY_CATEGORY_LISTS}" categorystyle="list_category">'+
    '<block type="lists_create_with">'+
    '  <mutation items="0"></mutation>'+
    '</block>'+
    '<block type="lists_create_with"></block>'+
    '<block type="lists_repeat">'+
    '  <value name="NUM">'+
    '    <shadow type="math_number">'+
    '      <field name="NUM">5</field>'+
    '    </shadow>'+
    '  </value>'+
    '</block>'+
    '<block type="lists_length"></block>'+
    '<block type="lists_isEmpty"></block>'+
    '<block type="math_on_list"></block>'+
    '<block type="lists_reverse"></block>'+
    '<block type="lists_indexOf">'+
    '  <value name="VALUE">'+
    '    <block type="variables_get">'+
    '      <field name="VAR">list</field>'+
    '    </block>'+
    '  </value>'+
    '</block>'+
    '<block type="lists_getIndex">'+
    '  <value name="VALUE">'+
    '    <block type="variables_get">'+
    '      <field name="VAR">list</field>'+
    '    </block>'+
    '  </value>'+
    '</block>'+
    '<block type="lists_setIndex">'+
    '  <value name="LIST">'+
    '    <block type="variables_get">'+
    '      <field name="VAR">list</field>'+
    '    </block>'+
    '  </value>'+
    '</block>'+
    '<block type="lists_getSublist">'+
    '  <value name="LIST">'+
    '    <block type="variables_get">'+
    '      <field name="VAR">list</field>'+
    '    </block>'+
    '  </value>'+
    '</block>'+
    '<block type="lists_split">'+
    '  <value name="DELIM">'+
    '    <shadow type="text">'+
    '      <field name="TEXT">,</field>'+
    '    </shadow>'+
    '  </value>'+
    '</block>'+
    '<block type="lists_sort"></block>'+
    '   </category>'+
    '   <category name="%{BKY_CATEGORY_PROCEDURES}" categorystyle="procedure_category" custom="PROCEDURE"></category>'+
    '   <sep></sep>'+
    '   <category name="%{BKY_CATEGORY_COLOUR}" categorystyle="colour_category">'+
    '<block type="colour_picker"></block>'+
    '<block type="colour_random"></block>'+
    '<block type="colour_rgb">'+
    '  <value name="RED">'+
    '    <shadow type="math_number">'+
    '      <field name="NUM">100</field>'+
    '    </shadow>'+
    '  </value>'+
    '  <value name="GREEN">'+
    '    <shadow type="math_number">'+
    '      <field name="NUM">50</field>'+
    '    </shadow>'+
    '  </value>'+
    '  <value name="BLUE">'+
    '    <shadow type="math_number">'+
    '      <field name="NUM">0</field>'+
    '    </shadow>'+
    '  </value>'+
    '</block>'+
    '<block type="colour_blend">'+
    '  <value name="COLOUR1">'+
    '    <shadow type="colour_picker">'+
    '      <field name="COLOUR">#ff0000</field>'+
    '    </shadow>'+
    '  </value>'+
    '  <value name="COLOUR2">'+
    '    <shadow type="colour_picker">'+
    '      <field name="COLOUR">#3333ff</field>'+
    '    </shadow>'+
    '  </value>'+
    '  <value name="RATIO">'+
    '    <shadow type="math_number">'+
    '      <field name="NUM">0.5</field>'+
    '    </shadow>'+
    '  </value>'+
    '</block>'+
    '   </category>'+
    '   <sep></sep>'+
    ' </xml>';

export { TOOLBOX, TOOLBOX_GALAXIA }
