import { editorConstants, modelConstants, stepsConstants } from "../constants";
import { modelActions } from "../actions/model";

const initialEditorState = {
  
    id:null,
    xmlMode:'solution',
    xmlReadyToLoad:false,
    xmlTemp:null,
    dataToSave:false,
    currentClueId:null

}

export const editor = (state = initialEditorState, action) => {
    switch (action.type) {
        case modelConstants.FETCH_ENTITIES_STARTED:

            if (action.entities === "steps") {
                return {
                    ...state,
                    id:null,
                    dataToSave:false,
                    xmlTemp:null,
                    currentClueId:null
                    
                }
            }
            return state;
        case modelConstants.CREATE_ENTITY_DONE:
        case modelConstants.FETCH_ENTITIES_DONE:
        case modelConstants.DELETE_ENTITY_DONE:
            if (action.originalEntities==="steps") {
                return {
                    ...state,
                    id: action.entities.result                    
                }
            }
            if(action.originalEntities==="clues"){
                return{
                    ...state,
                    currentClueId:action.entities.result
                }
            }
        case stepsConstants.FETCH_BY_COURSE_FAILED:
            return {
                ...state,
                
                error: action.error,
                entities:{},
            }
            case modelConstants.INVALIDATE_ENTITIES:
            return{
               ...initialEditorState
                
            }
         /*case editorConstants.SAVE_DESCRIPTION:
         return{
            ...state,
            description:action.description}*/
            case editorConstants.SWITCH_XML_MODE_STARTED:
            return{
                ...state,
                xmlMode:action.mode,
                xmlReadyToLoad:true,
                xmlTemp:null,
            }
            case editorConstants.XML_CHANGE_STARTED:
            return{
                ...state,
                xmlReadyToLoad:true,
            }
            case editorConstants.XML_CHANGE_SUCCEEDED:
            return{
                ...state,
                xmlReadyToLoad:false,
            }
            case editorConstants.SWITCH_XML_MODE_SUCCEEDED:
            return{
                ...state,
                xmlTemp:action.xml,
                xmlReadyToLoad:false,
            }
            case editorConstants.SAVE_XML_START:
            case editorConstants.SAVE_XML_SOLUTION:
            return{
                ...state,
                xmlTemp:action.xml,
                dataToSave:true,
            }
            case modelConstants.CREATE_ENTITY_STARTED:
            case modelConstants.UPDATE_ENTITY_DONE:
            return{
                ...state,
                dataToSave:false,
            }
            case editorConstants.SAVE_CLUE_CHANGES:
            case editorConstants.SAVE_CHANGES:
            return{
                ...state,
                dataToSave:true,
            }



        default: {
            return state;
        }

    }
}