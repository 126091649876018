import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router'
import { Online, Offline, Detector } from 'react-detect-offline'

import HeaderComponent from "../../components/HeaderComponent/HeaderComponent";
import HeaderActivityComponent from '../../components/HeaderActivityCreatorComponent/HeaderActivityComponent';
import { ModalActivityCreatorComponent } from '../../components/ModalActivityCreatorComponent/ModalActivityCreatorComponent';
import { Blockly } from '../../containers/Blockly';
import { FloatingMessage } from '../../components/FloatingMessage';

import './ActivityCreator.css';

import { Extension } from '../Extension';

import { coursesActions, stepsActions, modelActions, selectedCoursesActions, progressionsActions } from '../../actions/model';
import { coursePageActions, blocklyActions, modalActions, extensionActions } from '../../actions';

import runtimeEnv from '@mars/heroku-js-runtime-env';

import { modalConstants } from "../../constants";

import { Grid, Container } from 'semantic-ui-react';
import { Simulator } from '../Simulator';
import { getActualUser } from '../../reducers/user_reducer';


class ActivityCreator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activityId: null,
            stepData: {
                id: null,
                instruction: '',
                toolbox: '',
                xmlStart: ''
            },
            resetBlocklyRequest: false,
            isPublish: false,
            isChange: false,
            loadXmlBlocklyDone: false,
            defaultBlock: "setup"
        };

        this.createActivity = this.createActivity.bind(this);
        this.updateActivity = this.updateActivity.bind(this);
        this.createStep = this.createStep.bind(this);
        this.updateStep = this.updateStep.bind(this); 
        this.saveStepInstruction = this.saveStepInstruction.bind(this);
        this.saveStepToolboxXml = this.saveStepToolboxXml.bind(this);
        this.saveStepBlocklyXml = this.saveStepBlocklyXml.bind(this);
        this.fieldChange = this.fieldChange.bind(this);
        this.onMainWorkspaceStatusChanged = this.onMainWorkspaceStatusChanged.bind(this);
        this.resetBlockly = this.resetBlockly.bind(this);
        this.resetBlocklyDone = this.resetBlocklyDone.bind(this);
        this.onCompileClick = this.onCompileClick.bind(this);
        this.publishActivity = this.publishActivity.bind(this);
        this.onUnload = this.onUnload.bind(this);
    }

    componentWillMount(){
        this.props.dispatch(modelActions.invalidate())
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.dispatch(coursesActions.fetchActivity(this.props.match.params.id));
            this.props.dispatch(selectedCoursesActions.fetchAllForStructure())

        }
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.courses.isLoading > 0 && prevProps.courses.isLoading !== this.props.courses.isLoading) { 
            let course = Object.values(this.props.courses.entities).find(c => {
                if(c._id === this.props.match.params.id || (!c.firstStep)){
                    //if we have an id param in the url or if the course as no firststep
                    return c;
                }
            })
            if (course) {
                let activityId = course._id
                this.setState({activityId, isPublish: this.props.courses.entities[activityId].isPublish});
                console.log(this.props.steps.entities)
                let step = Object.values(this.props.steps.entities).find(s => {
                    console.log(this.props.courses.entities[activityId])
                    if(s._id === this.props.courses.entities[activityId].firstStep)
                        return s;
                })
                console.log("step: ", step)
                if (!this.props.courses.entities[activityId].firstStep) {
                    this.createStep(activityId);
                }
                else if (step) {
                    let stepData = {
                        id: step._id,
                        instruction: step.instruction, 
                        toolbox: step.toolbox,
                        xmlStart: step.xmlStart
                    };
                    this.setState({ stepData });
                }
                else if (this.props.courses.entities[activityId].firstStep) {
                    this.props.dispatch(stepsActions.fetchFirstByCourse(activityId));
                }
            }
        }

        if (prevProps.steps.isLoading > 0 && prevProps.steps.isLoading !== this.props.steps.isLoading) { 

            if(this.state.openActivity){
                //request to open activity in a new tab have been made, step is now saved open it
                this.setState({openActivity: false}, ()=>{
                    this.openNewTab()
                })
            }

            console.log("in prev step")
            let step = Object.values(this.props.steps.entities).find(s => {
                if(this.props.courses.entities[this.state.activityId] && s._id === this.props.courses.entities[this.state.activityId].firstStep)
                    return s;
            })
            console.log(step)
            if (step) {
                let stepData = {
                    id: step._id,
                    instruction: step.instruction, 
                    toolbox: step.toolbox,
                    xmlStart: step.xmlStart
                };
                this.setState({ stepData });
            }
        }

        if ((!this.props.match.params.id || this.state.loadXmlBlocklyDone === true) && prevState.stepData.xmlStart && prevState.stepData.xmlStart !== this.state.stepData.xmlStart) {
            this.setState({isChange: true});
        }

        if (this.state.isChange === true) {
            window.addEventListener("beforeunload", this.onUnload);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onUnload);  
    }
    
    onUnload(event) {
        const confirmationMessage = '';
        event.returnValue = confirmationMessage;
        return confirmationMessage; 
    }

    createActivity(data) {
        this.setState({isChange: false});
        window.removeEventListener("beforeunload", this.onUnload);  
        this.props.dispatch(modalActions.addModalMessageMid({type: modalConstants.MODAL_MESSAGE_TYPE.VALIDATE_ACTIVITY}));
        this.props.dispatch(coursesActions.create({...data, isActivity: true}));
    }

    updateActivity(data) {
        if(!this.state.activityId){
            this.createActivity(data)
            return;
        }
        this.setState({isChange: false}, ()=>{
            window.removeEventListener("beforeunload", this.onUnload);  
            this.props.dispatch(modalActions.addModalMessageMid({type: modalConstants.MODAL_MESSAGE_TYPE.UPDATE_ACTIVITY}));
            this.props.dispatch(coursesActions.update(this.state.activityId, data));
            if(this.state.stepData.id)
                this.updateStep()
            if(this.state.access){
                this.props.dispatch(selectedCoursesActions.updateForCourse(this.state.activityId, this.state.access))
            }
        });
    }

    createStep(activityId) {
        let data = {...this.state.stepData, course: activityId};
        this.props.dispatch(stepsActions.createFirstStepForCourse(data));
    }

    updateStep() {
        this.props.dispatch(stepsActions.saveCurrentStep(this.state.stepData));
    }

    saveStepInstruction(instruction) {
        let stepData = {...this.state.stepData};
        stepData.instruction = instruction;
        this.setState({ stepData });
    }

    saveStepToolboxXml(xml) {
        if (xml !== this.state.stepData.toolbox) {
            let stepData = {...this.state.stepData};      
            stepData.toolbox = xml;
            this.setState({stepData, isChange: true});
        }
    }

    saveStepBlocklyXml(xml, code) {
        if (this.props.steps.entities[this.state.stepData.id] && (xml === this.props.steps.entities[this.state.stepData.id].xmlStart || !this.props.steps.entities[this.state.stepData.id].xmlStart)) {
            this.setState({loadXmlBlocklyDone: true});
        }
        let stepData = {...this.state.stepData};
        stepData.xmlStart = xml;
        this.setState({ stepData });
    }

    fieldChange(bool) {
        this.setState({isChange: bool});
    }

    setAccessibility= (access) => {
        this.setState({access: access, isChange: true})
    }

    onMainWorkspaceStatusChanged(workspace) {
        if (workspace !== null){
            this.props.dispatch(blocklyActions.mainWorkspaceInitiated(workspace));
        }
        else {
            this.props.dispatch(blocklyActions.mainWorkspaceInvalid());
        }
    }

    resetBlockly() {
        this.setState({resetBlocklyRequest: true});
    }

    resetBlocklyDone() {
        this.setState({resetBlocklyRequest: false});
    }

    onCompileClick() {
        try{
            if (this.props.blockly.mainWorkspace === null) {
                return;
            }
            const code = window.Blockly.Arduino.workspaceToCode(this.props.blockly.mainWorkspace);
            this.props.dispatch(extensionActions.sendMessage({msg:{cmd:"compilation", data:code, url: runtimeEnv().REACT_APP_COMPILATION_SERVER, filterBoards: ["mini", "mega", "uno"]}, timeout:60000}))
        } 
        catch(error) { 
             console.log ("Erreur blockly", error);
             if (error) {
                this.props.dispatch(modalActions.addModalMessageMid({
                    type: modalConstants.MODAL_MESSAGE_TYPE.COMBINATED_ERROR})
                )
            }
            return
        }
    }

    publishActivity() {
        let isPublish = this.state.isPublish ? false : true;
        this.props.dispatch(coursesActions.update(this.state.activityId, {isPublish: isPublish}));
        this.setState({isPublish});
    }

    openActivity = () => {
        this.props.dispatch(progressionsActions.deleteProgressByCourse(this.state.activityId));
        this.setState({openActivity: true}, ()=>{
            this.updateActivity();
        })
    }

    openNewTab = () => {        
        window.open(window.location.origin+"/course/"+this.state.activityId+"?delete=1", "_blank")
    }

    onOfflineError = () => {
        this.props.dispatch(coursePageActions.offlineError())
    }

    onOfflineRecovery = () => {
        this.props.dispatch(coursePageActions.offlineRecovery())
    }

    changeDefaultBlock = (e, data) => {
        this.setState({defaultBlock: data.value})
    }

    render() {
        let structure = Object.values(this.props.structures.entities).find(s => {
            if(s.owner === this.props.user._id){
                return true
            }
        })

        let groups = []
        if(structure){
            groups = Object.values(this.props.groups.entities).filter(group => {
                if(group.structure === structure._id){
                    return true
                }
            })
        }

        let selectedCourses = Object.values(this.props.selectedCourses.entities).filter(select => {
            if(select.course === this.props.match.params.id){
                if(structure && (select.structure === structure._id || (this.props.groups.entities[select.group] && this.props.groups.entities[select.group].structure === structure._id))){
                    return true
                }
            }
        })
        return(
            <div style={{display: 'flex', flexDirection: "column", flex: "1", minHeight:0}}>
                <Simulator/>
                <Prompt when={this.state.isChange === true} message="Attention, vous essayez de quitter la page sans avoir sauvegardé l'activité. Voulez-vous vraiment quitter ?" />

                <FloatingMessage messages={this.props.messages} />

                {/* <Detector polling={{ url: runtimeEnv().REACT_APP_CONNECTION_DETECTION_POOLING, enabled: true, interval: 6000}} render={({online}) => {
                    if(!online && !this.props.isOffline){
                        this.onOfflineError()
                    } 
                    else if(online && this.props.isOffline){
                        this.onOfflineRecovery()
                    }
                    return null
                }}/>
                 */}

                <Grid id='activityGird'>
                    <Grid.Row className='HeaderRow'>
                        <HeaderComponent 
                            title="Espace de création d'activtés"
                            hideUserStatusIfGuest={true} 
                        />
                    </Grid.Row>
                    <Extension/>
                    <HeaderActivityComponent 
                        resetBlocklyRequest={this.resetBlockly}
                        onCompileClick={this.onCompileClick} 
                        compilationLoading={this.props.extension.pendingCompilation}
                        activityCreator={true}
                        openActivity={this.openActivity}
                        tryItDisabled={this.state.activityId ? false: true}
                    />

                    <Grid.Row columns='equal' className='WorkspaceRow' style={{display: "flex", flex: "1 1 auto", minHeight:0}}>
                        <Grid.Column computer={4} style={{padding: 0, margin:0, display: "flex", flexDirection: "column", flex: "0 1 auto", overflowY:"auto", height:"100%"}}>
                            <ModalActivityCreatorComponent 
                                activity={this.props.courses.entities[this.state.activityId]} 
                                step={this.props.steps.entities[this.state.stepData.id]}
                                structure={structure}
                                groups={groups}
                                selectedCourses={selectedCourses}
                                createActivity={this.createActivity}
                                createStep={this.createStep}
                                updateActivity={this.updateActivity}
                                updateStep={this.updateStep}
                                saveStepInstruction={this.saveStepInstruction}
                                saveStepToolboxXml={this.saveStepToolboxXml}
                                isPublish={this.state.isPublish}
                                publishActivity={this.publishActivity}
                                onUnload={this.onUnload}
                                fieldChange={this.fieldChange}
                                setAccessibility={this.setAccessibility}
                                changeDefaultBlock={this.changeDefaultBlock}
                            />
                        </Grid.Column>
                        <Grid.Column style={{padding:0, margin:0, display: "flex", flexDirection: "column", flex: "1 0 auto"}}>
                            <Container id='CourseBlocklyComponent'>
                                <Blockly
                                    lang={window.LANG}
                                    xml={this.props.steps.entities[this.state.stepData.id] && this.props.steps.entities[this.state.stepData.id].xmlStart}
                                    autoSave={this.saveStepBlocklyXml}
                                    onMainWorkspaceStatusChanged={this.onMainWorkspaceStatusChanged}
                                    resetAppRequest={this.state.resetBlocklyRequest}
                                    resetAppDone={this.resetBlocklyDone}
                                    activityCreator={true}
                                    defaultBlock={this.state.defaultBlock}
                                    step={{toolbox: this.state.stepData.toolbox}}
                                />
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        steps: state.model.steps,
        courses: state.model.courses,
        isOffline: state.modal.isOffline,
        resetAppRequest: state.coursePage.resetToPreviousXml,
        blockly: state.blockly,
        messages: state.modal.messages,
        extension: state.extension,
        structures: state.model.structures,
        groups: state.model.groups,
        selectedCourses: state.model.selectedCourses,
        user: getActualUser(state)
    }
}

const connectedActivityCreator = connect(mapStateToProps)(ActivityCreator);
export {connectedActivityCreator as ActivityCreator}