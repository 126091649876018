import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Grid, Header, Image, Message, Segment, Container, Divider} from 'semantic-ui-react'
import {logout} from "../../actions/session_actions";
import {withRouter} from "react-router-dom";
import doc from '../../img/doc.svg'

class LoginComponent extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: {
                identifier: '',
                password: ''
            }
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onLogOut = this.onLogOut.bind(this);
        this.redirectToRegister = this.redirectToRegister.bind(this);
        this.redirectToForgotPassword = this.redirectToForgotPassword.bind(this);
    }



    onSubmit(history) {
        const {user} = this.state;
        const {login} = this.props.actions;
        login(user, history);
    }

    onChange(e) {
        const {value, name} = e.target;
        const {user} = this.state;
        user[name] = value;
        this.setState({user});
    }

    onLogOut(history) {
        logout(history);
    }

    redirectToRegister(){
        this.props.history.push('/register')
    }
    redirectToForgotPassword(){
        this.props.history.push('/forgotPassword')
    }

    redirectFreeCreation = ()=>{
        this.props.history.push('/creation')
    }

    render() {
        return (
            
            <div style={{height: "100vh", display:'flex', flexDirection:"column"}}>
                <Grid textAlign='center' style={{flex: "1 1 auto"}} verticalAlign='middle'>
                    <Grid.Column style={{maxWidth:"450px", width:"60%"}}>
                        <div style={{display: "flex", justifyContent: 'center', margin:"auto",  marginBottom:"5px", marginTop:"5px"}}>
                            <div style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
                                <Image
                                    style={{width: '4.5em'}}
                                    size={'large'}
                                    src={doc}/>
                            </div>
                            
                            <div style={{display: "flex", justifyContent:"center", alignItems:"center", width:"60%"}}>
                                <div>
                                    <Header as='h2' color='orange' textAlign='center' style={{marginBottom:"5px"}}>
                                        Vous êtes de retour 
                                    </Header>
                                    <p style={{textAlign: "center"}}>
                                        Connectez vous pour retrouver vos programmes et vos parcours
                                    </p>
                                </div>
                            </div>
                        </div>
                        <Form size='large' loading={this.props.isLoading} error={this.props.error !== null}>
                            <Segment>

                               {this.props.error !== null && 
                                <Message
                                  error
                                  header='Erreur de connexion'
                                  content={this.props.error.data.message}
                                />}
                                <Form.Input
                                    onChange={this.onChange}
                                    name={"identifier"}
                                    fluid
                                    icon='user'
                                    iconPosition='left'
                                    placeholder="E-mail ou Nom d'utilisateur"
                                    style={{marginBottom: "5px"}}
                                />
                                <Form.Input
                                    onChange={this.onChange}
                                    name={"password"}
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    placeholder='Mot de passe'
                                    type='password'
                                    style={{marginBottom: "5px"}}
                                />
                                <Button type="submit" color='orange' fluid size='large'
                                        onClick={(e) => {e.preventDefault();this.onSubmit(this.props.history)}}>
                                    Connexion
                                </Button>
                            </Segment>
                        </Form>
                        <Message style={{display: "block"}}>
                            <p>
                                <a style={{cursor: "pointer"}} onClick={this.redirectToRegister}>Je n'ai pas encore de compte</a>
                            </p>
                            <p>
                                <a style={{cursor: "pointer"}} onClick={this.redirectToForgotPassword}>J'ai oublié mon mot de passe</a>
                            </p>
                            <p>
                            <a style={{cursor: "pointer"}} onClick={this.redirectFreeCreation}>Je veux tester un programme sans me connecter</a>
                            </p>
                        </Message>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
};

LoginComponent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

export default withRouter(LoginComponent);
