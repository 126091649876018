import React, {Component} from 'react';
import {connect} from "react-redux";


import {Loader, Dimmer, Card, Header, Segment, Message} from 'semantic-ui-react'

import InfiniteScroll from 'react-infinite-scroller';

import {getActualUser} from "../../reducers/user_reducer";

import {modelActions, structuresActions, usersActions, coursesActions, stepsActions, groupsActions, selectedCoursesActions} from "../../actions/model";

import {StoreActivitySummary} from '../../components/StoreActivitySummary'

import {PageWithSideMenu} from "../PageWithSideMenu";


class ActivitiesStore extends Component {

    constructor(props) {
        super(props);

        this.state = {
            from: -25,
            limit: 25,
            hasMore: true,
            courses: [],
            initialLoad: true
        }
    }

    componentDidMount(prevProps) {
        document.title = "Bibliothèque d'activités - Thingz"
        if(this.props.user){
            this.fetch();
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.courses.order != prevProps.courses.order && this.props.courses.order){
            if(this.props.courses.order.length === 0){
                this.setState({hasMore: false})
            }
            if(this.props.courses.order.length){
                let courses = [...this.state.courses]
                for(let c of this.props.courses.order){
                    courses.push(c)
                }
                this.setState({courses: courses})
            }
        }
    }

    componentWillUnmount(){
        this.props.dispatch(modelActions.invalidate())
    }

    fetch(){
        // this.props.dispatch(structuresActions.fetchStructures());
        // this.props.dispatch(selectedCoursesActions.fetchAllForStructure());
        // this.props.dispatch(coursesActions.fetchInStore(this.state.from, this.state.limit));
    }

    fetchMore = ()=>{
        let initLoad = true
        console.log(window, document)
        if(window.innerHeight < document.documentElement.scrollHeight){
            initLoad = false
        }
        this.setState({from: this.state.from+this.state.limit, initialLoad:initLoad}, () => {
            this.props.dispatch(coursesActions.fetchInStore(this.state.from, this.state.limit));
        })
    }

    
    renderLoading(){
        return <Loader key="loader" active inline='centered' style={{marginTop:"10px", marginBottom:"10px"}}/>
    }

    renderActivities(){
        let activities = this.state.courses.map(c => {
            if(this.props.courses.entities[c]){
                let course = {...this.props.courses.entities[c]}
                let user = {...this.props.users.entities[course.user]}
                if(user)
                    course.user = user;
                return <StoreActivitySummary key={course._id} course={course} isOwner={this.props.user && user._id === this.props.user._id}/>
            }
            return null
        })
        return activities
        
    }

    render() {

        return (
            <PageWithSideMenu dontFetch={true} pageName={"store_activities"}>
                <Segment>
					<Header size="large">Bibliothèque</Header>		
				</Segment>

                {(this.state.hasMore || this.state.courses.length > 0) &&
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this.fetchMore}
                        hasMore={!this.props.courses.isLoading > 0 && this.state.hasMore}
                        loader={this.renderLoading()}
                        // useWindow={false}
                        initialLoad={this.state.initialLoad}
                        key="scroll"
                    >

                        <Card.Group itemsPerRow={3} stackable>
                            {this.renderActivities()}
                        </Card.Group>
                    </InfiniteScroll>
                }
                {(!this.state.hasMore && this.state.courses.length == 0) && 
                    <Message>
                        <Message.Header>Aucune activité</Message.Header>
                        <Message.Content>Il n'y a pas d'activité publique pour le moment</Message.Content>
                    </Message>
                }
          </PageWithSideMenu>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: getActualUser(state),
        structures: state.model.structures,
        users: state.model.users,
        courses: state.model.courses,
        groups: state.model.groups,
        selectedCourses: state.model.selectedCourses,
    }
}

const connectedActivitiesStore = connect(mapStateToProps)(ActivitiesStore);
export { connectedActivitiesStore as ActivitiesStore }
