import {notificationConstants} from "../constants";

export const notificationActions = {
    addNotificationMessage,
    deleteAllNotificationMessages,
    createNotificationMessage
}

function addNotificationMessage(notificationMessage) {
    notificationMessage = createNotificationMessage(notificationMessage);
    return (dispatch, getState) => {
        const messages = getState().notification.messages;
        if (messages.length >= 50) {
            messages.pop();
            dispatch({
                type: notificationConstants.DELETE_LAST_NOTIFICATION_MESSAGE, 
                payload: messages
            });
        }

        if (messages.length && messages[0].format === 'error' && messages[0].type === notificationMessage.type) {
            dispatch({
                type: notificationConstants.ADD_NOTIFICATION_MESSAGE,
                payload: messages
            });
            return
        }

        messages.splice(0, 0, notificationMessage);
        dispatch({
            type: notificationConstants.ADD_NOTIFICATION_MESSAGE,
            payload: messages
        });
    }
}

function deleteAllNotificationMessages() {
    return (dispatch) => {
        dispatch({type: notificationConstants.DETELE_ALL_NOTIFICATION_MESSAGES});
    }
}

function createNotificationMessage(notificationMessage) {
    if (notificationMessage.type === 'SPREADSHEET_URL_NOT_FOUND') {
        notificationMessage.text = "Aucune url n'est renseignée dans ton profil";
    }
    else if (notificationMessage.type === 'SPREADSHEET_ERROR_URL') {
        notificationMessage.text = "Il y a une erreur dans l'url de ton profil";
    }
    else if (notificationMessage.type === 'SPREADSHEET_FILE_NO_SHARE') {
        notificationMessage.text = "Le fichier Google Sheets n'est pas partagé";
    }
    else if (notificationMessage.type === 'SPREADSHEET_FILE_READ_ONLY') {
        notificationMessage.text = "Le fichier Google Sheets est en lecture seule";
    }
    else if (notificationMessage.type === 'SPREADSHEET_SEND_DATA') {
        notificationMessage.text = `Donnée reçue : ${notificationMessage.values.key}, ${notificationMessage.values.value}`;
    }
    else if (notificationMessage.type === 'BRICK_PLUGGED') {
        notificationMessage.text = `La brique ${notificationMessage.prettyName} est branchée`;
    }
    else if (notificationMessage.type === 'BRICK_UNPLUGGED') {
        notificationMessage.text = `La brique ${notificationMessage.prettyName} est débranchée`;
    }
    return notificationMessage;
}
