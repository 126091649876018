import React, {Component} from 'react';
import { Message, Accordion } from 'semantic-ui-react';
import AdministrationAddUsers from './AdministrationAddUsers';
import AdministrationAddGroups from './AdministrationAddGroups';
import AdministrationInviteUsers from './AdministrationInviteUsers';

export default class AdministrationTab extends Component{

    constructor(props){
        super(props)

        this.state = {
            addStudents:{
                open:false
            },
            addGroups:{
                open: false
            }
        }
    }

    

    openAddGroups = () => {
        this.setState(prev => {
			return {
				addGroups:{
					...prev.addGroups,
					open: !prev.addGroups.open
				}
			}
		})
    }

    renderSections(){
        return (
            <Accordion fluid styled>
				<AdministrationAddGroups structure={this.props.structure} groups={this.props.groups} onAddGroup={this.props.onAddGroup} onGroupUpdate={this.props.onGroupUpdate} onGroupSuppression={this.props.onGroupSuppression}/>
				<AdministrationAddUsers users={this.props.users} structure={this.props.structure} onAddStudents={this.props.onAddStudents} groups={this.props.groups}/>
				<AdministrationInviteUsers structure={this.props.structure}/>
			</Accordion>
        )
    }

    render(){
        return(
			<div className='pane' style={{height: "100%"}}>
				<Message style={{display:'block'}}>
                    <Message.Header>Administration</Message.Header>
                    <p>
                        Utilisez les formulaires ci-dessous pour administrer vos groupes et vos élèves
                    </p>
                </Message>
                {this.renderSections()}
			</div>
		)
    }
}