import React, {Component} from 'react';
import ChannelMessageComponent from '../ChannelMessageComponent/ChannelMessageComponent'
import './ChannelComponent.css'
import {FloatingMessage} from '../FloatingMessage'

import { Button, Icon, Segment } from 'semantic-ui-react'

export default class ChannelComponent extends Component{

    constructor(props){
        super(props);
        this.onAddIndice = this.onAddIndice.bind(this);
        this.state = {
            messages: []
        }
    }

    componentDidUpdate() {
        const channelElement = document.getElementById('channel');
        channelElement.scrollTop = channelElement.scrollHeight;
    }

    // componentWillMount(){
    //     this.computeWaitTime();
    // }
    // componentWillUpdate(){
    //     this.computeWaitTime()
    // }

    computeWaitTime(){
        let countNew;
        if(this.state.messages.length > this.props.messages.length){
            countNew = this.props.messages.length;
        }else{
            countNew = this.state.messages.length - this.props.messages.length;
        }
        let messages = [];
        this.props.messages.forEach((message, i) => {
            let m=message;
            if(i <= messages.length-countNew){
                m.wait = (i - (messages.length -countNew))*800+100;
            }
            messages.push(m);
        })
        this.setState({messages})
    }

    onAddIndice(){
        this.props.addIndice();
    }

    render() {
        let messages = this.props.messages.map((message, i) => {
            if (message.displayInChannel) {
                return <ChannelMessageComponent key={this.props.currentStepId+i.toString()} message={message} wait={message.noWait ? 100 : i*800+100}/>
            }
        })

        return (
            <div style={{display: "flex", flexDirection: "column", flex: "1 0 auto"}}>
                <FloatingMessage messages={this.props.messages} />
                <div className='channel-container'>
                    <div id='channel' className='messages-container'>
                            {messages}
                    </div>
                    {!this.props.isActivity &&
                        <Segment>
                            <Button.Group>
                                <Button icon labelPosition='left' onClick={this.onAddIndice} color='violet'>
                                    <Icon name='compass outline'/>
                                    Un indice
                                </Button>
                            </Button.Group>
                        </Segment>
                    }
                </div>
            </div>
        );
    }
}
