class CmdInviteTransformer {
    constructor() {
        this.container = '';
    }
  
    transform(chunk, controller) {
        this.container += chunk;
        if(this.container.includes(">>>")){
            controller.enqueue(this.container)
            this.container = ""
        }
    }
  
    flush(controller) {
        controller.enqueue(this.container);
    }
}

class CmdSendCodeTransformer {
    constructor() {
        this.container = '';
    }
  
    transform(chunk, controller) {
        this.container += chunk;
        // console.log(chunk)
        if(this.container.includes("#thgz end of flash")){
            controller.enqueue(this.container)
            this.container = ""
        }else
            controller.enqueue("chunk")
    }
  
    flush(controller) {
        controller.enqueue(this.container);
    }
}

class CmdCheckVersionTransformer {
    constructor() {
        this.container = '';
    }
  
    transform(chunk, controller) {
        this.container += chunk;
        // console.log(chunk)
        if(this.container.includes("#ok\r\n")){
            controller.enqueue(this.container)
            this.container = ""
        }
    }
  
    flush(controller) {
        controller.enqueue(this.container);
    }
}

class Galaxia{

    constructor(port, operations){
        this.port = port;
        this.operations = operations

        this.textEncoder = new TextEncoder()

    }

    timeout(ms){
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    async read(timeout_ms){
        let data;
        let timeout = setTimeout(()=>{
            let reader = this.operations.getReader()
            try{
                reader.cancel()
            }catch(e){
                console.log(e)
            }
        }, timeout_ms)
        let reader = this.operations.getReader()
        data = await reader.read()
        clearTimeout(timeout)
        return data
    }

    async retry(func, number, delay, ...args){
        let exc;
        for(let i = 0; i < number; i++){
            try{
                let status = await func(...args)
            }catch(e){
                console.log(e)
                exc = e
                await this.timeout(delay);
                continue;
            }
            return i+1;
        }
        throw exc;
    }

    appendLoadingMsg(str){
        let s = "import board\r\nimport displayio\r\nimport terminalio\r\n"
        s += "board.DISPLAY.auto_refresh = False\r\n"
        s += "group = displayio.Group()\r\n"
        s += "palette = displayio.Palette(2)\r\n"
        s += "palette[1] = 0xFFFFFF\r\n"
        s += "tilegrid = displayio.TileGrid(terminalio.FONT.bitmap, pixel_shader=palette, tile_width=6, tile_height=14, height=1, width=27)\r\n"
        s += "group.append(tilegrid)\r\n"
        s += "tilegrid[0] = terminalio.FONT.get_glyph('T'.encode('ascii')[0]).tile_index\r\n"
        s += "tilegrid[1] = 0x65\r\n"
        s += "tilegrid[2] = terminalio.FONT.get_glyph('l'.encode('ascii')[0]).tile_index\r\n"
        s += "tilegrid[3] = 0x65\r\n"
        s += "tilegrid[4] = terminalio.FONT.get_glyph('v'.encode('ascii')[0]).tile_index\r\n"
        s += "tilegrid[5] = terminalio.FONT.get_glyph('e'.encode('ascii')[0]).tile_index\r\n"
        s += "tilegrid[6] = terminalio.FONT.get_glyph('r'.encode('ascii')[0]).tile_index\r\n"
        s += "tilegrid[7] = terminalio.FONT.get_glyph('s'.encode('ascii')[0]).tile_index\r\n"
        s += "tilegrid[8] = terminalio.FONT.get_glyph('e'.encode('ascii')[0]).tile_index\r\n"
        s += "tilegrid[9] = terminalio.FONT.get_glyph('m'.encode('ascii')[0]).tile_index\r\n"
        s += "tilegrid[10] = terminalio.FONT.get_glyph('e'.encode('ascii')[0]).tile_index\r\n"
        s += "tilegrid[11] = terminalio.FONT.get_glyph('n'.encode('ascii')[0]).tile_index\r\n"
        s += "tilegrid[12] = terminalio.FONT.get_glyph('t'.encode('ascii')[0]).tile_index\r\n"
        s += "board.DISPLAY.show(group)\r\n"
        s += "board.DISPLAY.refresh()\r\n"

        str = str.split("\r\n");
        let inserted = 0;
        let final = []
        for(let i = 0; i < str.length; i++){
            final.push(str[i])
            if(i == (inserted+1)*10){
                switch(inserted % 3){
                    case 0:
                        final.push("tilegrid[13] = terminalio.FONT.get_glyph('.'.encode('ascii')[0]).tile_index\r\n")
                        final.push("tilegrid[14] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n")
                        final.push("tilegrid[15] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n")
                        final.push("board.DISPLAY.refresh()\r\n")
                    break;
                    case 1:
                        final.push("tilegrid[14] = terminalio.FONT.get_glyph('.'.encode('ascii')[0]).tile_index\r\n")
                        final.push("board.DISPLAY.refresh()\r\n")
                    break;
                    case 2:
                        final.push("tilegrid[15] = terminalio.FONT.get_glyph('.'.encode('ascii')[0]).tile_index\r\n")
                    break;
                }
                //final.push("board.DISPLAY.refresh()\r\n")
                inserted++;
            }
        }
        final.push("tilegrid[0] = terminalio.FONT.get_glyph('F'.encode('ascii')[0]).tile_index\r\n")
        final.push("tilegrid[1] = terminalio.FONT.get_glyph('i'.encode('ascii')[0]).tile_index\r\n")
        final.push("tilegrid[2] = terminalio.FONT.get_glyph('n'.encode('ascii')[0]).tile_index\r\n")
        final.push("tilegrid[3] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n")
        final.push("tilegrid[4] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n")
        final.push("tilegrid[5] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n")
        final.push("tilegrid[6] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n")
        final.push("tilegrid[7] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n")
        final.push("tilegrid[8] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n")
        final.push("tilegrid[9] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n")
        final.push("tilegrid[10] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n")
        final.push("tilegrid[11] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n")
        final.push("tilegrid[12] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n")
        final.push("tilegrid[13] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n")
        final.push("tilegrid[14] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n")
        final.push("tilegrid[15] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n")
        final.push("board.DISPLAY.refresh()\r\n")
        return s+final.join("\r\n");
    }

    appendClearScreenMsg(str){
        let final = "tilegrid[0] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n";
        final += "tilegrid[1] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n";
        final += "tilegrid[2] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n";
        final += "tilegrid[3] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n";
        final += "tilegrid[4] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n";
        final += "tilegrid[6] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n";
        final += "tilegrid[5] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n";
        final += "tilegrid[7] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n";
        final += "tilegrid[8] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n";
        final += "tilegrid[9] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n";
        final += "tilegrid[10] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n";
        final += "tilegrid[11] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n";
        final += "tilegrid[12] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n";
        final += "tilegrid[13] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n";
        final += "tilegrid[14] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n";
        final += "tilegrid[15] = terminalio.FONT.get_glyph(' '.encode('ascii')[0]).tile_index\r\n";
        final += "board.DISPLAY.refresh()\r\n";
        return str+final;
    }

    async interrupt(){
        let data = "\x03\x03"
        // let reader = this.operations.getReader()
        // console.log(reader)
        // try{
        //     reader.releaseLock()
        // }catch(e){
        //     console.log(e)
        // }
        // let reader = this.operations.getTransformer().pipeTo(new window.TextDecoderStream().writable).readable.pipeThrough(new window.TransformStream(new CmdInviteTransformer())).getReader()
        let textDecoder = new window.TextDecoderStream()
        const readableStreamClosed = this.port.readable.pipeTo(textDecoder.writable);
        let reader = textDecoder.readable.pipeThrough(new window.TransformStream(new CmdInviteTransformer())).getReader()
        // let reader = this.operations.getTransformer().pipeTo(new window.TextDecoderStream().writable).readable.pipeThrough(new window.TransformStream(new CmdInviteTransformer())).getReader()
        this.operations.setReader(reader)
        let writer = this.operations.getWriter();
        try{
            await writer.write(this.textEncoder.encode(data));
            await this.timeout(100);
            await writer.write(this.textEncoder.encode(data));
            data = await this.read(750)
            // console.log(data)
            if(data.done){
                this.operations.setReader(null)
            }
            if(data.value){
                reader.cancel()
                await readableStreamClosed.catch(()=>{})
                this.operations.setReader(null)
                return 0
            }
            // console.log(this.port)
        }catch(e){
            this.operations.setReader(null)
            console.error(e)
            return -1;
        }

        throw "FAILED_INTERRUPT"
    }

    async repl(){
        let data = "import microcontroller\r\nmicrocontroller.enable_repl_flash()\r\n"
        let writer = this.operations.getWriter();
        try{
            await writer.write(this.textEncoder.encode(data));
            await this.timeout(500);
            if(!this.port.readable && this.port.closed){
                return 0;
            }
        }catch(e){
            console.error(e)
            return -1;
        }
        throw "FAILED_REPL"
    }

    async wait_connection(){
        this.port = await this.operations.connect(false, false);
        return 0;
    }

    async check_version(files){
        // return files
        let filesToFlash = []
        let textDecoder = new window.TextDecoderStream()
        const readableStreamClosed = this.port.readable.pipeTo(textDecoder.writable);
        let reader = textDecoder.readable.pipeThrough(new window.TransformStream(new CmdCheckVersionTransformer())).getReader()
        this.operations.setReader(reader)
        for(let file of files){
            if(file.import){
                //Need to check version
                
                let writer = this.operations.getWriter()
                await writer.write(this.textEncoder.encode(file.import+"\r\nprint("+file.name+".version())\r\n#ok\r\n"));
                let data = await this.read(5000)
                // console.log(data)
                
                if(data){
                    if(data.value && data.value.includes("#ok")){
                        let index = data.value.lastIndexOf("version())\r\n");
                        let endIndex = data.value.indexOf("\r\n", index+"version())\r\n".length)
                        console.log(index, endIndex,data.value.substring(index+"version())\r\n".length, endIndex), file.version)
                        if(data.value.substring(index+"version())\r\n".length, endIndex) !== file.version)
                            filesToFlash[filesToFlash.length] = file
                    }else{
                        filesToFlash[filesToFlash.length] = file
                    }
                }else{
                    filesToFlash[filesToFlash.length] = file
                }
            }else{
                filesToFlash[filesToFlash.length] = file
            }
        }
        reader.cancel()
        await readableStreamClosed.catch(()=>{})
        return filesToFlash
    }

    async send_code(files){
        // console.log(files)
        let to_send = "import os\r\n"
        for(let file of files){
            if(file.main){
                this.main_file = file.path;
            }
            let dir = file.path.split('/')
            let mkdir = "/";
            for(let i = 0; i < dir.length -1; i++){
                mkdir += "/"+dir[i];
                if(dir[i]){
                    to_send += 'os.mkdir("'+mkdir+'")\r\n';
                }
            }
            if(file.mode && file.mode == "binary"){
                to_send += 'f = open("'+file.path+'", "wb")\r\n';
                to_send += file.content;
                to_send += 'f.close()\r\n';
            }else{
                to_send += 'f = open("'+file.path+'", "w")\r\n';
                let content = file.content;
                for(let i = 0; i < content.length; i += 100){
                    to_send += 'f.write(' + JSON.stringify(content.substring(i, i+100)) + ")\r\n";
                }
                to_send += 'f.close()\r\n';
            }
            
        }
        to_send += "#thgz end of flash\r\n";
        to_send = this.appendLoadingMsg(to_send)
        // for(let i = 0; i < 10; i++){
        //     try{
                // let reader = this.operations.getTransformer().pipeTo(new window.TextDecoderStream().writable).readable.pipeThrough(new window.TransformStream(new CmdSendCodeTransformer())).getReader()
                // this.operations.setReader(reader)
            //     break;
            // }catch(e){
            //     console.log(e)
            // }
            // await this.timeout(100)
        // }
        let textDecoder = new window.TextDecoderStream()
        const readableStreamClosed = this.port.readable.pipeTo(textDecoder.writable);
        let reader = textDecoder.readable.pipeThrough(new window.TransformStream(new CmdSendCodeTransformer())).getReader()
        this.operations.setReader(reader)
        try{
            let writer = this.operations.getWriter()
            for(let i = 0; i < to_send.length;){
                let next_i = to_send.indexOf('\r\n', i+200);
                if(next_i == -1)
                    next_i = to_send.length
                next_i+=2
                let sub = to_send.substring(i, next_i)
                i = next_i
                // console.log("write", i)
                let encode = this.textEncoder.encode(sub)
                // console.log(encode)
                await writer.write(encode);
                // console.log("done")
                let data = await this.read(5000)
                // console.log(data)
                if(data.value && data.value.includes("#thgz end of flash")){
                    reader.cancel()
                    await readableStreamClosed.catch(()=>{})
                    return 0;
                }
            }
            // console.log("write done")
            while(true){
                let data = await this.read(5000)
                // console.log("READING", data)
                
                if(data.value && data.value.includes("#thgz end of flash")){
                    reader.cancel()
                    await readableStreamClosed.catch(()=>{})
                    return 0;
                }

                if(data.done){
                    this.operations.setReader(null)
                    break
                }
            }
        }catch(e){
            console.error(e)
            throw "FAILED_SEND_CODE"
        }
        throw "FAILED_SEND_CODE"

    }

    async set_file_to_exec(){
        let data = this.appendClearScreenMsg("")
        data += "import microcontroller\r\nmicrocontroller.set_file_to_execute(\""+this.main_file+"\")\r\nmicrocontroller.reset()\r\n"
        let writer = this.operations.getWriter()
        try{
            await writer.write(this.textEncoder.encode(data));
            await this.timeout(100);
            if(!this.port.readable){
                return 0;
            }
        }catch(e){
            console.error(e)
            return -1;
        }
        throw "FAILED_REPL"
    }

    async wait_board_status_changed(state){
        let r =  await new Promise(resolve => {
            // console.log(this.operations.search())
            return resolve(this.operations.search() === state)
        })
        if(!r){
            throw {value: "NO_REBOOT"}
        }
        return r
    }

    async step(func, ...args){
        // console.log(func, args)
        let result = await func(...args)
        return result
    }

    async writeFiles(files){
        let retry = await this.step(this.retry.bind(this), this.interrupt.bind(this), 5, 250);
        console.log("interrupt success after "+retry+" tries")
        this.operations.willReset(true)
        retry = await this.step(this.retry.bind(this), this.repl.bind(this), 5, 250)
        this.operations.willReset(false)
        console.log("repl success after "+retry+" tries")
        retry = await this.step(this.retry.bind(this), this.wait_connection.bind(this), 20, 1000)
        console.log("reconnection success after "+retry+" tries")
        retry = await this.step(this.retry.bind(this), this.interrupt.bind(this), 5, 250);
        console.log("interrupt success after "+retry+" tries")
        let filesToFlash = await this.step(this.check_version.bind(this), files)
        await this.step(this.send_code.bind(this), filesToFlash);
        console.log("send_code success")
        this.operations.willReset(true)
        retry = await this.step(this.retry.bind(this), this.set_file_to_exec.bind(this), 5, 250)
        console.log("reset success")
        retry = await this.step(this.retry.bind(this), this.wait_board_status_changed.bind(this), 60, 1000, false)
        this.operations.willReset(false)
        console.log("disapear after ", retry, 'retries')
        retry = await this.step(this.retry.bind(this), this.wait_board_status_changed.bind(this), 60, 1000, true)
        console.log("reboot after ", retry, 'retries')
        return 0;
    }
}

export default Galaxia