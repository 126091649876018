import React, {Component} from 'react'
import { ToastContainer, toast } from "react-toastify";
import {modalConstants} from "../../constants";

import 'react-toastify/dist/ReactToastify.css';
import './FloatingMessage.css';

const Msg = (props) => {

	function renderClassInstruction() {
    if (props.message.type === modalConstants.MODAL_MESSAGE_TYPE.CONSIGNE) {
        return 'consigne'
    }
    return ''
	}

	return (
		<div role="alert" className="Toastify__toast-body">
			<div className="message" style={{margin:0}}>
				<div>
	  			<div className={"message-img-container "+ props.message.author.class}>
	  		</div>
	  		</div>
	  		<div className='message-body'>
	        <div className='message-body-header'>
	            {props.message.author && <p className='message-body-header-author'>{props.message.author.name}</p>}
	            {props.message.title && <p className='message-body-header-title'>{props.message.title}</p>}
	        </div>
	        <div className='message-body-content'>
	            {props.message.text && <p className={renderClassInstruction()}>{props.message.text}</p>}
	            {props.message.img && <img alt={"perso_profil"} className={'message-body-content-image'} src={props.message.img}/>}
				{props.message.link && <a href={props.message.link} className={'message-body-content-link'}>{props.message.link}</a>}
	        </div>
	      </div>
	      </div>
		</div>
	)
}


class FloatingMessage extends Component{

	componentDidMount(){
	}

	componentDidUpdate(prevProps){
		if(prevProps.messages === undefined || this.props.messages === undefined || this.props.messages === null) 
			return;
		
		if((prevProps.messages === null && this.props.messages !== null) || (prevProps.messages.length !== this.props.messages.length)){
			let lastMessage = this.props.messages[this.props.messages.length - 1];
			if(this.props.messages.length > 0 && !lastMessage.displayInChannel){
				toast(<Msg message={lastMessage} />, { autoClose: 10000 });
			}
		}
	}

	render(){
		return (
			<div>
		    <ToastContainer />
		  </div>
		)
	}
}

export {FloatingMessage}
