import {groupsConstants} from '../../constants'
import {modelConstants} from '../../constants'
import * as api from "../../api"

import { groupsSchema, groupSchema } from "../../schemas";

import {normalize} from 'normalizr'

export const groupsActions = {
    create,
    update,
    remove
}

function create(group) {
    return (dispatch, getState) => {
    
        dispatch({type: modelConstants.CREATE_ENTITY_STARTED, entities: "groups"})
        api.groupsApi.create(group).then(resp => {
            let group = resp.data;
           
			const entities = normalize(group, groupSchema)
			entities.entities.structures = {...getState().model.structures.entities}

			if(!entities.entities.structures[group.structure._id].groups)
				entities.entities.structures[group.structure._id].groups = []
			entities.entities.structures[group.structure._id].groups.push(group._id)

            dispatch({type: modelConstants.CREATE_ENTITY_DONE, originalEntities: "groups", entities})
        })
        .catch(err => {
			console.log(err)
			if(err.response && err.response.data && err.response.data.message)
				dispatch({type: groupsConstants.CREATE_FAILED, error: err.response.data.message})
			else
            	dispatch({type: groupsConstants.CREATE_FAILED, error: err})
        })
        
    }
}

function update(group){
	return (dispatch, getState) => {
		dispatch({type: modelConstants.UPDATE_ENTITY_STARTED, entities:"groups", _id: group._id});
		return api.groupsApi.update(group).then(response => {
			let group = response.data;
			let entities = normalize(group, groupSchema)
			entities.entities.structures = {...getState().model.structures.entities}

			if(!entities.entities.structures[group.structure._id].groups)
				entities.entities.structures[group.structure._id].groups = []
			if(!entities.entities.structures[group.structure._id].groups.includes(group._id))
				entities.entities.structures[group.structure._id].groups.push(group._id)

			dispatch({type: modelConstants.UPDATE_ENTITY_DONE, originalEntities:"groups", entities: entities});
		}).catch(err => {
			console.log(err)
			if(err.response && err.response.data && err.response.data.message)
				dispatch({type: groupsConstants.UPDATE_FAILED, error: err.response.data.message, _id:group._id})
			else
            	dispatch({type: groupsConstants.UPDATE_FAILED, error: err, _id:group._id})
		})
	}
}

function remove(groupId) {
    return (dispatch, getState) => {
		dispatch({type: modelConstants.DELETE_ENTITY_STARTED, entities:"groups", _id:groupId});
		return api.groupsApi.remove(groupId).then(response => {
			let entities = getState().model.groups.entities;
			let users = {...getState().model.users.entities}
			Object.keys(users).forEach(user => {
				if(users[user].group === response.data._id){
					users[user].group = null
					users[user].structure = response.data.structure._id
				}
			})
			let groups = {...entities}
			delete groups[response.data._id];
		
			dispatch({type: modelConstants.DELETE_ENTITY_DONE, originalEntities:"groups", entities: {entities: {groups: groups, users:users}}});
		}).catch(err => {
			console.log(err)
			if(err.response && err.response.data && err.response.data.message)
				dispatch({type: groupsConstants.DELETE_FAILED, error: err.response.data.message, _id:groupId})
			else
            	dispatch({type: groupsConstants.DELETE_FAILED, error: err, _id:groupId})
		})
	}
}