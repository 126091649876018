import {notificationConstants} from "../constants";

const initialNotificationState = {
    messages: [],
}

export const notification = (state = initialNotificationState, action) => {
    switch (action.type) {
        case notificationConstants.ADD_NOTIFICATION_MESSAGE: {
            return {
                ...state,
                messages: action.payload
            }
        }
        case notificationConstants.DELETE_LAST_NOTIFICATION_MESSAGE: {
            return {
                ...state,
                messages: action.payload
            }
        }
        case notificationConstants.DETELE_ALL_NOTIFICATION_MESSAGES: {
            return {
                ...state,
                messages: []
            }
        }
        default: {
            return state
        }
    }
}





