import React, {Component} from 'react';
import {Blockly} from '../../containers/Blockly'
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import HeaderCourse from "../HeaderCourse/HeaderCourse";
import Modal from "../../containers/Modal";
import {ExtensionInformationComponent} from '../ExtensionInformationComponent';
import {StepPopupInstruction} from '../StepPopupInstruction'
import {Simulator} from '../../containers/Simulator'
import {SerialMonitor} from '../../containers/SerialMonitor'
import './CourseComponent.css'

import {
  Grid,
  Container
} from 'semantic-ui-react';

const Event = {InputText: "InputText", Click: "Click", Hover: "Hover"};

export default class CourseComponent extends Component {

    constructor(props) {
        super(props);
        this.keyupTimeout = null;
        this.inputTextEvent = this.inputTextEvent.bind(this);
        this.onBlocklyStepDone = this.onBlocklyStepDone.bind(this);
    }

    componentDidMount() {
        this.setupEvent();
    }

    componentDidUpdate() {
        this.setupEvent();
    }

    setupEvent() {
        if (this.props.step && !this.props.progression.ended) {
            let elem;
            switch (this.props.step.event) {
                case Event.Click:
                    elem = document.querySelector('#' + this.props.step.eventTarget);
                    elem.addEventListener("click", this.nextStep);
                    break;

                case Event.InputText:
                    elem = document.querySelector('#' + this.props.step.eventTarget);
                    elem.addEventListener("keyup", this.inputTextEvent);
                    break;

                default:
                    // TODO
                    break;
            }
        }
    }

    inputTextEvent() {
        clearTimeout(this.keyupTimeout);
        this.keyupTimeout = window.setTimeout(function () {
            this.nextStep();
        }.bind(this), 500);
    }

    onBlocklyStepDone() {
        if (!this.props.step.ended) {
            this.props.onStepDone();
        }
    }

    renderSerialMonitor = () => {
        if(!this.props.serialMonitor.open)
            return null;
        return (
            <Grid.Row className={""} centered>
                <Grid.Column style={{width:"80%"}}>
                <SerialMonitor/>
                </Grid.Column>
            </Grid.Row>
        )
    }


    render() {
        let step = this.props.step;
        return (

            <div className="course" id='courseMainDiv'>
                <StepPopupInstruction step={step} open={step.event == "PlugBrick" && !this.props.progression.ended} skipStep={this.props.checkBlockly}/>
                {this.props.step === null && <p>Sorry this course doesn't exist...</p>}
                <Simulator/>
                
                {this.props.step && window.Blockly &&
                <Grid id='courseGird'>
                    
                    <Grid.Row className='HeaderRow'>
                        <HeaderComponent 
                            course={this.props.course}
                            progression={this.props.progression}
                            step={this.props.step}
                            title={this.props.course.title}
                            hideUserStatusIfGuest={true}/>
                    </Grid.Row>
                    
                    <HeaderCourse step={step}
                        progression={this.props.progression}
                        compilationLoading={this.props.compilationLoading}
                        resetApp={this.props.resetApp}
                        course = {this.props.course}
                        onCompileClick={this.props.onCompileClick}
                        onSimulatorClick={this.onSimulatorClick}
                        onNextStep={this.props.checkBlockly}
                        onValidateActivityClick={this.props.onValidateActivityClick}
                        onAlreadyValidateActivityClick={this.props.onAlreadyValidateActivityClick}
                        idExtensionChange={this.props.idExtensionChange}
                    />

                    <Grid.Row columns='equal' className='WorkspaceRow' style={{display: "flex", flex: "1 0 auto", padding:0, margin:0}}>
                        <Grid.Column computer={4} style={{padding: 0, display: "flex", flexDirection: "column", flex: "0 1 auto"}}>
                            <Modal progression={this.props.progression}/>
                        </Grid.Column>
                        <Grid.Column style={{padding:0, display: "flex", flexDirection: "column", flex: "1 0 auto"}}>
                            <Container id='CourseBlocklyComponent'>
                                <Blockly
                                    lang={window.LANG}
                                    step={step}
                                    updateWorkspaceSol={this.props.updateWorkspaceSol}
                                    onStepDone={this.onBlocklyStepDone}
                                    autoSave={this.props.autoSaveProgression}
                                    xml={this.props.progression.xml}
                                    resetAppRequest={this.props.resetAppRequest}
                                    resetAppDone={this.props.resetAppDone}
                                    ended={this.props.progression.ended}
                                    onMainWorkspaceStatusChanged={this.props.onMainWorkspaceStatusChanged}
                                    onSolutionWorkspaceStatusChanged={this.props.onSolutionWorkspaceStatusChanged}
                                    activityCourse={this.props.course.isActivity && true}
                                />
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                    {this.renderSerialMonitor()}
                </Grid> 
                }
            </div>
        )
    }
}

//updateWorkspace={this.props.updateWorkspace}
