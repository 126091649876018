import { blocklyConstants } from "../constants";

const initialState = {
    mainWorkspace: null,
    solutionWorkspace: null,
    currentVersion: process.env.REACT_APP_DEFAULT_BLOCKLY_VERSION,
};

export function blockly(state = initialState, action) {
    switch (action.type) {
        case blocklyConstants.INVALIDATE_MAIN_WORKSPACE:
            return {
                ...initialState,
            };
        case blocklyConstants.MAIN_WORKSPACE_INITIATED:
            return {
                ...state,
                mainWorkspace: action.workspace,
                isReady: true,
            };
        case blocklyConstants.MAIN_WORKSPACE_INVALID:
            return {
                ...state,
                mainWorkspace: null,
            };
        case blocklyConstants.SOLUTION_WORKSPACE_INITIATED:
            return {
                ...state,
                solutionWorkspace: action.workspace,
            };
        case blocklyConstants.SOLUTION_WORKSPACE_INVALID:
            return {
                ...state,
                solutionWorkspace: null,
            };
        case blocklyConstants.CHANGE_VERSION:
            return {
                ...state,
                currentVersion: action.version,
            };
        default:
            return state;
    }
}
