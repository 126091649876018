import { serialMonitorConstants } from '../constants';

const initialState = {
  log: "",
  open: false,
  autoOpen: false,
}

export function serialMonitor(state = initialState, action) {
  switch (action.type) {
    case serialMonitorConstants.CHANGE:
    return {
      ...state,
      log: action.log
    }
    case serialMonitorConstants.OPEN:
    return {
      ...state,
      open:true
    }
    case serialMonitorConstants.CLOSE:
    return {
      ...state,
      open:false,
      log: ""
    }
    case serialMonitorConstants.SET_AUTO_OPEN:
    return {
      ...state,
      autoOpen: action.autoOpen
    }
    case serialMonitorConstants.TOGGLE_AUTO_OPEN:
    return {
      ...state,
      autoOpen: state.autoOpen ? false : true
    }
    default:
      return state;
  }
}