
class ThingzProtocol{

    constructor(){
        this.packetParser  = {
            raw: null,
            type: null,
            length: null,
            currentStep: "start"
        }

        this.protocol = {
            type: {
                SYSTEM:1,
                USER:2,
                PLUGGED:3,
                UNPLUGGED:4,
                OVERRECEIVING: 5,
                GET_BRICKS: 6,
                PYTHON_INTERRUPT: 7,
                TOTAL:8
            }
        }
        
        this.useProtocol = false
        this.initStrSent = false

        this.textDecoder = new TextDecoder("utf-8")
    }

    isActif(){
        return this.useProtocol && this.initStrSent
    }

    setUseProtocol(status){
        this.useProtocol = status
    }

    setInitStrSent(status){
        this.initStrSent = status
    }

    addData(data){
        // console.log(data, this.packetParser)
        if(!this.isActif()){
            if(data)
                return {type: this.protocol.type.USER, data: this.textDecoder.decode(data)}
            return false
        }
        if(data){
            let r = new Uint8Array(this.packetParser.raw ? this.packetParser.raw.length + data.length: data.length);
            if(this.packetParser.raw){
                r.set(this.packetParser.raw)
            }
            r.set(data, this.packetParser.raw ? this.packetParser.raw.length : 0)
            this.packetParser.raw = r
        }
        // console.log(JSON.stringify(this.packetParser))

        switch(this.packetParser.currentStep){
            case "start":
                if(!this.packetParser.raw || this.packetParser.raw.length == 0)
                    return false;
                if(this.packetParser.raw[0] === 's'.charCodeAt(0)){
                    this.packetParser.currentStep = 'type'
                    return this.addData()
                }else{
                    console.log("PACKET PARSER: receive "+this.packetParser.raw[0]+" instead of s")
                    this.packetParser.raw = this.packetParser.raw.slice(1, this.packetParser.raw.length);
                    return this.addData()
                }
            case "type":
                if(this.packetParser.raw.length < 2){
                    return false
                }
                this.packetParser.type = this.packetParser.raw[1]
                this.packetParser.currentStep = 'length'
                return this.addData()
            case "length":
                if(this.packetParser.raw.length < 3){
                    return false
                }
                this.packetParser.length = this.packetParser.raw[2]
                this.packetParser.currentStep = 'data'
                return this.addData()
            case "data":
                if(this.packetParser.raw.length < 3 + this.packetParser.length){
                    return false
                }
                this.packetParser.data = this.textDecoder.decode(this.packetParser.raw.subarray(3, 3+this.packetParser.length))
                let msg = {...this.packetParser}
                this.packetParser.raw = this.packetParser.raw.slice(3+this.packetParser.length);
                this.packetParser.currentStep = "start"
                return msg

        }
    }

    resetPacketParser(){
        this.packetParser  = {
            raw: null,
            type: null,
            length: null,
            currentStep: "start",
        }
    }
}

export default ThingzProtocol