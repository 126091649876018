import { modelConstants,stepsConstants,editorConstants, coursePageConstants } from '../../constants';


const initialState = {
    entities: {},
    isLoading: 0
};

export const steps = (state = initialState, action) => {
	switch (action.type) {
    case modelConstants.CREATE_ENTITY_STARTED:
    case modelConstants.FETCH_ENTITIES_STARTED:
    case modelConstants.UPDATE_ENTITY_STARTED:
    case modelConstants.DELETE_ENTITY_STARTED:
        if(action.entities === "steps")
            return {
                ...state,
                isLoading: state.isLoading + 1
            }
        return state;
    case modelConstants.FETCH_ENTITIES_DONE:
    case modelConstants.CREATE_ENTITY_DONE:
    case modelConstants.UPDATE_ENTITY_DONE:
    case modelConstants.DELETE_ENTITY_DONE:
    case modelConstants.FETCH_ENTITY_DONE:
        if(action.entities.entities && action.entities.entities.steps){
            return {
                ...state,
                entities: mergeSteps(state.entities, action.entities.entities.steps),
                isLoading: action.originalEntities === "steps" && state.isLoading > 0 ? state.isLoading - 1 : state.isLoading
            }
        }else if(action.originalEntities === "steps"){
            return {
                ...state,
                isLoading: state.isLoading > 0 ? state.isLoading-1 : 0
            }
        }
        return state;
    case modelConstants.INVALIDATE_ENTITIES:
        return initialState;
    case stepsConstants.CREATE_BY_COURSE_FAILED:
    case stepsConstants.CREATE_NEXT_STEP_FAILED:
    case stepsConstants.CREATE_PREVIOUS_STEP_FAILED:
    case stepsConstants.UPDATE_FAILED:
    case stepsConstants.DELETE_FAILED:
        return{
            ...state,
            isLoading: state.isLoading > 0 ? state.isLoading-1 : 0,
            error: action.error
        }
    case editorConstants.SAVE_CHANGES:
        /*case editorConstants.SAVE_XML_SOLUTION:
        case editorConstants.SAVE_XML_START:*/
        return{
            ...state,
            entities : {...state.entities,
                        [action.stepToUpdate._id] : action.stepToUpdate}
        }
    case coursePageConstants.SAVE_STEP_XML:
        return {
            ...state,
            entities:{
                ...state.entities,
                [action.step]: {...state.entities[action.step], xmlStart: action.xml}
            }
        }
    default:{
        return state;
    }
  }
}

function mergeSteps(currentSteps, newSteps){
    if(!newSteps)
    newSteps = {};
    
    for(let c in currentSteps){
        if(newSteps[c])
            continue;
        newSteps[c] = {};
        newSteps[c] = {...currentSteps[c]};
    }
    return newSteps;
}