import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {Button, Confirm, Dropdown, Feed, Header, Icon, Item, Label, Modal, Popup} from 'semantic-ui-react'
import {withRouter} from 'react-router-dom'
import no_image from './no_image.png'
import './AppSummaryComponent.css'
import {
    freeCreationActions
} from '../../actions'
import {appsActions} from '../../actions/model'
import {getActualUser} from '../../reducers/user_reducer'
import BlocksSummaryComponent from '../BlocksSummaryComponent/BlocksSummaryComponent'

import AppShareModal from './AppShareModal/AppShareModal'

class AppSummaryComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            deleteModalState: false,
            shareModalState: false
        }
        this.redirectToApp = this.redirectToApp.bind(this)
        this.renderSelectButton = this.renderSelectButton.bind(this)
    }

    redirectToApp() {
        // this.props.history.push(`/creation/${this.props.app._id}`)
        this.props.history.push(`/creation?app=${this.props.app._id}`)
    }

    openDeleteModal = () => {
        this.setState({deleteModalState: true});
    }

    closeDeleteModal = () => {
        this.setState({deleteModalState: false});
    }

    openShareModal = () => {
        console.log('open modal', this.props.app._id);
        this.setState({shareModalState: true});
    }

    closeShareModal = () => {
        this.setState({shareModalState: false});
    }

    dispatchDeleteApp = () => {
        this.props.dispatch(appsActions.deleteApp(this.props.app._id));
    }

    renderMenu(){
        // if(!this.props.apps)
        //     return null;
        return (
            <Popup trigger={
                <Dropdown item icon='ellipsis vertical' direction="left" style={{float:"right"}}>
                    <Dropdown.Menu>
                        <Dropdown.Item icon="share" content="Partager..." onClick={this.openShareModal}/>
                        <Dropdown.Item icon="trash" content="Supprimer..." onClick={this.openDeleteModal}/>
                    </Dropdown.Menu>
                </Dropdown>}
            >Plus d'options</Popup>
            
        )
    }

    renderShareModal() {
        return (
            <AppShareModal app={this.props.app} open={this.state.shareModalState} onClose={this.closeShareModal}/>
        );
    }

    renderDeleteModal() {
        return (
            <Modal onClose={this.closeDeleteModal} open={this.state.deleteModalState} dimmer="blurring" size='small'>
                <Header icon='trash' content="Confirmation de suppression" />
                <Modal.Content>
                    <p>
                        Si vous supprimez l'application, toutes les données liées à cette application seront perdues.
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color='grey' onClick={this.closeDeleteModal}>
                        <Icon name='remove' /> Annuler
                    </Button>
                    <Button color='red' onClick={this.dispatchDeleteApp}>
                        <Icon name='trash' />  Supprimer l'application
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }

    renderSelectButton() {
        if(this.props.hideButton)
            return null;
        return (
            <Item.Extra>
                <Button primary floated={'right'} onClick={(e) => this.redirectToApp()} animated>
                    <Button.Content visible>
                        Ouvrir <Icon name={'right chevron'}/>
                    </Button.Content>
                    <Button.Content hidden>
                        <Icon name={'arrow right'}/>
                    </Button.Content>
                </Button>
            </Item.Extra>
        )
    }

    render() {
        return (
            <Item className="AppSummaryItem">
                {/* <Item.Image size={'tiny'} src={this.props.app.img || no_image}/> */}
                <Item.Content>
                    <Item.Header onClick={(e) => this.redirectToApp()}>
                        {this.props.app.nom}
                    </Item.Header>
                    {this.renderMenu()}
                    <Item.Meta>Créée le {new Date(this.props.app.createdAt).toLocaleDateString("fr-FR")}</Item.Meta>
                    <Item.Description>
                        <BlocksSummaryComponent app={this.props.app} />
                    </Item.Description>
                    {this.renderSelectButton()}
                    {this.renderDeleteModal()}
                    {this.renderShareModal()}
                </Item.Content>
            </Item>
        )
    }
}

AppSummaryComponent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
}

function mapStateToProps(state) {
	return {
        user: getActualUser(state),
        structure: state.structure,
        structureOwned: state.structureOwned,
	}
}

const connectedAppSummaryComponent = connect(mapStateToProps)(AppSummaryComponent)
export default withRouter(connectedAppSummaryComponent)
// export default withRouter(AppSummaryComponent)