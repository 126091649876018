import { modelConstants } from '../../constants';

export const modelActions = {
	invalidate,
	clearStatus
}


function invalidate(){
	return {type:modelConstants.INVALIDATE_ENTITIES}
}

function clearStatus(){
	return {type:modelConstants.CLEAR_STATUS}
}