import React, { Component, Fragment} from 'react'

import {
    Button,
    Icon,
    Modal,
    Popup,
    Header,
    Container,
    Input
  
  } from 'semantic-ui-react'

class HeaderFreeCreationControlsShare extends Component {

    constructor(props){
        super(props)

        this.state = {
            modalIsOpen: false
        }
    }

    openModal = () => {
        this.setState({modalIsOpen: true})
    }

    closeModal = () => {
        this.setState({modalIsOpen: false, link_is_copied: false})
    }

    clearSelection(){
        if(window.getSelection){
            window.getSelection().removeAllRanges()
        }else if(document.selection){
            document.selection.empty()
        }
    }

    handleCopy = () => {
        /* Get the text field */
        let copyText = document.getElementById("myAppUrl")
        /* Select the text field */
        copyText.select()
        /* Copy the text inside the text field */
        document.execCommand("copy")
        /* Deselect the input value */
        this.clearSelection()
        /* Alert the copied text */

        this.setState({link_is_copied: true})
  	}

    render(){
        return (
            <Fragment>
                <Popup basic content="Partager ma création" trigger={
                    <Button size="small" icon color="blue" onClick={this.openModal}>
                        <Icon name="share"/>
                    </Button>
                }/>
                <Modal open={this.state.modalIsOpen} closeOnEscape={true} closeOnDimmerClick={true} onClose={this.closeModal} size="small" closeIcon>
                    <Header icon="share" content="Partage"/>
                    <Modal.Content>
                    <Modal.Description>
                        <p>Utilise le lien suivant pour permettre à d'autres utilisateurs de copier ta création :</p>
                        <Container>
                            {this.state.link_is_copied ? (
                                <Input action={{ color: "teal", content: "Copier", icon: "check", onClick: () => this.handleCopy() }} fluid type="text" value={window.location.origin.toString()+"/creation?app="+this.props.app.id} id="myAppUrl"/>
                            ) : (
                                <Input action={{ color: "blue", content: "Copier", icon: "copy", onClick: () => this.handleCopy() }} fluid type="text" value={window.location.origin.toString()+"/creation?app="+this.props.app.id} id="myAppUrl"/>
                            )}
                        </Container>
                    </Modal.Description>
                    </Modal.Content>
                </Modal>
            </Fragment>
        )
    }
}

export { HeaderFreeCreationControlsShare };