import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Online, Offline, Detector} from 'react-detect-offline'

import {getActualUser} from "../reducers/user_reducer";

import CourseComponent from "../components/CourseComponent/CourseComponent";
import GuidedTourComponent from "../components/GuidedTourComponent/GuidedTourComponent";
import {Extension} from "./Extension"

import {progressionsActions, coursesActions, modelActions} from '../actions/model'

import {
    extensionActions,
    serialMonitorActions,
    blocklyActions,
    modalActions,
    coursePageActions,
    guidedTourActions

} from '../actions';

import {modalConstants} from "../constants";
import {extensionConstants} from '../constants';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import querySearch from "stringquery";




class Course extends Component {

    constructor(props){
        super(props);
        this.state = {selectedProgression: null};
    }

    componentDidMount() {     
        this.props.dispatch(progressionsActions.fetchByCourse(this.props.match.params.id));
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.progressions.isLoading > 0 && prevProps.progressions.isLoading !== this.props.progressions.isLoading){
            let p = Object.keys(this.props.progressions.entities).find(id => {
                let progression = this.props.progressions.entities[id];
                let step = this.props.steps.entities[progression.step];
                return step && step.course === this.props.match.params.id && progression.user === this.props.user._id; // aide pour parcourir le progresss
            })
            this.setState({selectedProgression: p})
            if(p && (!prevState.selectedProgression || !prevProps.progressions.entities[p] || prevProps.progressions.entities[p].step !== this.props.progressions.entities[p].step || (this.props.progressions.entities[p].ended && !prevProps.progressions.entities[p].ended) ))
                this.props.dispatch(modalActions.initContextMessage(p));
            if(p && (!prevState.selectedProgression || !prevProps.progressions.entities[p] || !prevProps.progressions.entities[p].clues || (prevProps.progressions.entities[p].clues && this.props.progressions.entities[p].clues && prevProps.progressions.entities[p].clues.length != this.props.progressions.entities[p].clues.length))){
                let prevClues = prevProps.progressions.entities[p] ? prevProps.progressions.entities[p].clues : []
                let clues = this.props.progressions.entities[p].clues;
                
                if(!prevState.selectedProgression || clues.length < prevClues.length){
                    clues = clues.map(clue => {
                        return this.props.clues.entities[clue];
                    })
                    this.props.dispatch(modalActions.initClues(clues))
                }else{
                    clues = clues.filter(clue => {
                        return !prevClues.find(prevClue => {
                            return prevClue === clue
                        })
                    })
                    clues = clues.map(clue => {
                        return this.props.clues.entities[clue];
                    })
                    this.props.dispatch(modalActions.addClues(clues))
                }
            }
            document.title = this.props.courses.entities[this.props.match.params.id] ? this.props.courses.entities[this.props.match.params.id].title+" - Thingz": "Parcours - Thingz"
        }
        if(!this.props.progressions.isLoading && this.state.selectedProgression && this.props.progressions.entities[this.state.selectedProgression] && !this.props.progressions.entities[this.state.selectedProgression].ended)
            this.checkNextStepOnBrickPlug(prevProps);
    }

    componentWillUnmount() {
        this.props.dispatch(modelActions.invalidate());
    }

    checkNextStepOnBrickPlug = (prevProps) => {
        try{
            let p = this.props.progressions.entities[this.state.selectedProgression];
            let step = this.props.steps.entities[p.step];

            //We are dealing with a plug event, is the brick plugged?
            if(step.event === "PlugBrick" && this.props.extension.isComOpen && this.props.pluggedBricks.bricks.find((brick) => {
                if(step.eventTarget === brick.name){
                    return true;
                }
            })){

                return this.nextStep();
            }else if(step.event === "PlugBrick" && !this.props.extension.isComOpen && !this.props.extension.pendingMsg && this.props.extension.boardStatus === "FOUND"){
                    //Auto openning serial link to be aware of plug event
                    console.log("openning")
                    this.props.dispatch(extensionActions.open());
            }
            if(!prevProps.extension.isComOpen && this.props.extension.isComOpen){
                //The serial link is available, check what brick already are plugged
                this.props.dispatch(extensionActions.getBricks());
            }
        }catch(e){
            return;
        }
    }

    onCompileClick = () => {

        try{
            if(this.props.blockly.mainWorkspace === null)
                return;
             
            const code = window.Blockly.Arduino.workspaceToCode(this.props.blockly.mainWorkspace);
            this.props.dispatch(extensionActions.sendMessage({msg:{cmd:"compilation", data:code, url: runtimeEnv().REACT_APP_COMPILATION_SERVER, filterBoards: ["mini", "mega", "uno"]}, timeout:60000}))
        }catch(error){ 
             console.log ("Erreur blockly", error);
             if(error){
              this.props.dispatch(modalActions.addModalMessageMid({
                type: modalConstants.MODAL_MESSAGE_TYPE.COMBINATED_ERROR}
                    )
                )
            }

            return
        }
    }

    onAlreadyValidateActivityClick = () => {
        this.props.dispatch(modalActions.addModalMessageMid({
            type: modalConstants.MODAL_MESSAGE_TYPE.ACTIVITY_ALREADY_VALIDATE}
            )
        )
    }

    onValidateActivityClick = () => {
        this.props.dispatch(coursePageActions.animationTransition());
    }

    checkBlockly = () => {
       this.props.dispatch(coursePageActions.checkBlockly(this.state.selectedProgression));
    }

    checkBlocklyGuidedTour = () => {
       this.props.dispatch(coursePageActions.checkBlocklyGuidedTour(this.props.guidedTour.hasSolution));
    }

    autoSaveProgression = (xml) => {
        if (this.props.courses.entities[this.props.match.params.id] && this.props.courses.entities[this.props.match.params.id]._id === runtimeEnv().REACT_APP_GUIDED_TOUR) {
            this.props.dispatch(coursePageActions.checkBlocklyGuidedTour(this.props.guidedTour.hasSolution, this.props.guidedTour.solutions))
            this.props.dispatch(extensionActions.resetCompilationCode());
        } else {
            this.props.dispatch(coursePageActions.checkXmlChangedSignificantly(this.state.selectedProgression, xml));
            this.props.dispatch(coursePageActions.saveProgressionXml(this.state.selectedProgression, xml));
        }
    };

    nextStep = () => {
        this.props.dispatch(progressionsActions.nextStep(this.state.selectedProgression))
    }

    resetApp = () => {
        this.props.dispatch(coursePageActions.resetToPreviousXml(this.state.selectedProgression));
        // this.props.dispatch(avancementActions.resetAppAvancement())
    };

    resetAppDone = () => {
        this.props.dispatch(coursePageActions.resetToPreviousXmlDone())
    };

    idExtensionChange = (value) => {
        this.props.dispatch(extensionActions.newId(value));
    }

    onMainWorkspaceStatusChanged = (workspace) => {
        if(workspace !== null){
            this.props.dispatch(blocklyActions.mainWorkspaceInitiated(workspace));
        }else{
            this.props.dispatch(blocklyActions.mainWorkspaceInvalid());
        }
    }

    onSolutionWorkspaceStatusChanged = (workspace) => {
        if(workspace !== null){
            this.props.dispatch(blocklyActions.solutionWorkspaceInitiated(workspace));
        }else{
            this.props.dispatch(blocklyActions.solutionWorkspaceInvalid());
        }
    }

    onOfflineError = () => {
        this.props.dispatch(coursePageActions.offlineError())
    }

    onOfflineRecovery = () => {
        this.props.dispatch(coursePageActions.offlineRecovery())
    }

    render() {

        if (this.state.selectedProgression) {
            return (
                // <div style={{height:'100%'}}>
                <div style={{display: 'flex', flexDirection: "column", flex: "1"}}>
                <Detector polling={{ url: runtimeEnv().REACT_APP_CONNECTION_DETECTION_POOLING, enabled: true, interval: 6000}} render={({online}) => {
                    if(!online && !this.props.isOffline){
                        this.onOfflineError()
                    } 
                    else if(online && this.props.isOffline){
                        this.onOfflineRecovery()
                    }
                    // console.log('-------------------')
                    return null
                }}/>
                    <Extension/>
                    {this.props.courses.entities[this.props.match.params.id] && this.props.courses.entities[this.props.match.params.id]._id === runtimeEnv().REACT_APP_GUIDED_TOUR ? (
                        <GuidedTourComponent
                            autoSaveProgression={this.autoSaveProgression}

                            onCompileClick={this.onCompileClick}
                            onStepDone={this.nextStep}

                            checkBlockly={this.checkBlockly}
                            checkBlocklyGuidedTour={this.checkBlocklyGuidedTour}
                            
                            onMainWorkspaceStatusChanged={this.onMainWorkspaceStatusChanged}
                            onSolutionWorkspaceStatusChanged={this.onSolutionWorkspaceStatusChanged}

                            compilationLoading={this.props.extension.pendingCompilation}

                            resetApp={this.resetApp}
                            resetAppDone={this.resetAppDone}
                            resetAppRequest={this.props.resetAppRequest}

                            course={this.props.courses.entities[this.props.match.params.id]}
                            step={this.props.steps.entities[this.props.progressions.entities[this.state.selectedProgression].step]}
                            progression={this.props.progressions.entities[this.state.selectedProgression]}

                            updateWorkspaceSol={this.updateWorkspaceSol}

                            extension={this.props.extension}
                            idExtensionChange={this.idExtensionChange}

                            blockly={this.props.blockly}
                            // guidedTourGoNextStep={this.props.guidedTourGoNextStep}
                            // guidedTourNextStepDone={this.guidedTourNextStepDone}
                        />
                    ) : (                        
                        <CourseComponent
                            autoSaveProgression={this.autoSaveProgression}

                            onCompileClick={this.onCompileClick}
                            onValidateActivityClick={this.onValidateActivityClick}
                            onAlreadyValidateActivityClick={this.onAlreadyValidateActivityClick}
                            onStepDone={this.nextStep}
                            checkBlockly={this.checkBlockly}
                            onMainWorkspaceStatusChanged={this.onMainWorkspaceStatusChanged}
                            onSolutionWorkspaceStatusChanged={this.onSolutionWorkspaceStatusChanged}

                            compilationLoading={this.props.extension.pendingCompilation}

                            resetApp={this.resetApp}
                            resetAppDone={this.resetAppDone}
                            resetAppRequest={this.props.resetAppRequest}

                            course={this.props.courses.entities[this.props.match.params.id]}
                            step={this.props.steps.entities[this.props.progressions.entities[this.state.selectedProgression].step]}
                            progression={this.props.progressions.entities[this.state.selectedProgression]}

                            updateWorkspaceSol={this.updateWorkspaceSol}

                            extension={this.props.extension}
                            idExtensionChange={this.idExtensionChange}
                            serialMonitor={this.props.serialMonitor}

                        />
                    )}
                </div>)
        } else {
            return (
                <div>
                    <p>Chargement...</p>
                </div>
            )
        }
    }
}

Course.propTypes = {};

function mapStateToProps(state) {
    return {
        progressions: state.model.progressions,
        steps: state.model.steps,
        courses: state.model.courses,
        clues: state.model.clues,
        isOffline: state.modal.isOffline,
        resetAppRequest: state.coursePage.resetToPreviousXml,
        compilationLoading: state.coursePage.compilationLoading,
        instructionHighLight: state.coursePage.instructionHighLight,
        extension: state.extension,
        blockly: state.blockly,
        guidedTour: state.guidedTour,
        serialMonitor: state.serialMonitor,
        pluggedBricks: state.pluggedBricks,
        user: getActualUser(state),
    }
}

export default connect(mapStateToProps)(Course)