import { modelConstants } from '../../constants';
import { spreadsheetsConstants } from '../../constants';


const initialState = {
    entities: {},
    results: [],
    error: null,
    isLoading: 0
};

export const spreadsheets = (state = initialState, action) => {
    switch (action.type) {
        case modelConstants.FETCH_ENTITIES_STARTED:
            if(action.entities === "spreadsheets")
                return {
                    ...state,
                    isLoading: state.isLoading + 1
                }
            return state;
        case modelConstants.FETCH_ENTITIES_DONE:
        case modelConstants.CREATE_ENTITY_DONE:
        case modelConstants.UPDATE_ENTITY_DONE:
        case modelConstants.FETCH_ENTITY_DONE:
            if(action.entities.entities && action.entities.entities.spreadsheets){
                return {
                    ...state,
                    entities: action.entities.entities.spreadsheets,
                    error: null,
                    isLoading: action.originalEntities === "spreadsheets" && state.isLoading > 0? state.isLoading - 1 : state.isLoading
                }
            }else if(action.originalEntities === "spreadsheets"){
                return {
                    ...state,
                    isLoading: state.isLoading > 0 ? state.isLoading-1 : 0
                }
            }
            return state;
        case spreadsheetsConstants.FETCH_ALL_FAILED:
            return {
                ...state,
                error: action.error,
                isLoading: state.isLoading > 0 ? state.isLoading-1 : 0
            }
        case modelConstants.INVALIDATE_ENTITIES:
            return initialState;
        default:
            return state;
    }

}