import { modelConstants } from '../../constants';
import { finishedCoursesConstants } from '../../constants';


const initialState = {
    entities: {},
    results: [],
    error: null,
    isLoading: 0
};

export const finishedCourses = (state = initialState, action) => {
    switch (action.type) {
        case modelConstants.FETCH_ENTITIES_STARTED:
            if(action.entities === "finishedCourses")
                return {
                    ...state,
                    isLoading: state.isLoading + 1
                }
            return state;
        case modelConstants.FETCH_ENTITIES_DONE:
        case modelConstants.CREATE_ENTITY_DONE:
        case modelConstants.UPDATE_ENTITY_DONE:
        case modelConstants.FETCH_ENTITY_DONE:
            if(action.entities.entities && action.entities.entities.finishedCourses){
                return {
                    ...state,
                    entities: mergeFinishedCourses(state.entities, action.entities.entities.finishedCourses),
                    error: null,
                    isLoading: action.originalEntities === "finishedCourses" && state.isLoading > 0? state.isLoading - 1 : state.isLoading
                }
            }else if(action.originalEntities === "finishedCourses"){
                return {
                    ...state,
                    isLoading: state.isLoading > 0 ? state.isLoading-1 : 0
                }
            }
            return state;
        case finishedCoursesConstants.FETCH_ALL_FAILED:
            return {
                ...state,
                error: action.error,
                isLoading: state.isLoading > 0 ? state.isLoading-1 : 0
            }
        case modelConstants.INVALIDATE_ENTITIES:
            return initialState;
        default:
            return state;
    }

}


function mergeFinishedCourses(currentCourses, newCourses){
    if(!newCourses)
        newCourses = {};
        
    for(let c in currentCourses){
        if(newCourses[c])
            continue;
        newCourses[c] = {};
        newCourses[c] = {...currentCourses[c]};
    }
    return newCourses;
}