import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import {Feed, Item, Button, Icon, Label} from 'semantic-ui-react'

import {getActualUser} from '../../reducers/user_reducer'

import './BlocksSummaryComponent.css'

class BlocksSummaryComponent extends Component {

    constructor(props) {
        super(props)

    }
    
    render() {
        // Un array qui accueillera tous les blocks contenus dans l'application
        let allBlocks = []
        // Un array qui acceuillera, pour l'application en question, une itération de chaque type de blocks présent ainsi que le compte de leur nombre d'occurences dans l'array "allBlocks"
        let blocks = []
        // Un objet contenant toutes les icones et les couleurs des différentes briques
        const blocksDatas = {
            // name, type, img, color
            led: { name: 'Led', type: 'led', img: 'https://cdn1.iconfinder.com/data/icons/thin-electronic-parts-components/24/thin_led_diode_light-512.png', color: 'rgb(153, 102, 255)'},
            button: { name: 'Bouton', type: 'button', img: 'https://cdn3.iconfinder.com/data/icons/office-productivity-and-communication/400/Productivity-17-512.png', color: 'rgb(92, 177, 214)'},
            number: { name: 'Block Chiffre', type: 'number', img: 'https://cdn2.iconfinder.com/data/icons/electronic-components-02/447/led_display_number_power_digit_7_segment-512.png', color: 'rgb(153, 102, 255)'},
            screen: { name: 'Ecran', type: 'screen', img: 'https://cdn3.iconfinder.com/data/icons/led-tv-1/512/led_tv-2-512.png', color: 'rgb(153, 102, 255)'},
            buzzer: { name: 'Buzzer', type: 'buzzer', img: 'https://cdn3.iconfinder.com/data/icons/electrical-components/100/feb-elecomp-go-04-512.png', color: 'rgb(207, 99, 207)'},
            potentiometer: { name: 'Potentiomètre',type: 'potentiometer', img: 'https://img.icons8.com/small/1600/potentiometer.png', color: 'rgb(102, 153, 255)'},
            makey: { name: 'Makey-Makey', type: 'makey', img: 'https://cdn3.iconfinder.com/data/icons/user-interface-2-6/85/touchscreen_tactile_touch_screen_hand-512.png', color: 'rgb(92, 177, 214)'},
            infra: { name: 'Infrarouge', type: 'infra', img: 'https://img.icons8.com/metro/1600/infrared-sensor.png', color: 'rgb(92, 177, 214)'},
            meteo: { name: 'Meteo', type: 'meteo', img: 'https://cdn4.iconfinder.com/data/icons/proglyphs-weather/512/Termomether_-_Cold-512.png', color:'rgb(92, 177, 214)'},
            brightness: { name: 'Luminosité',  type: 'brightness', img: 'https://cdn1.iconfinder.com/data/icons/photo-editor-3/24/i24_photoedit_brightness-512.png', color: 'rgb(92, 177, 214)'},
            bluetooth: { name: 'Bluetooth', type: 'bluetooth', img: 'https://cdn2.iconfinder.com/data/icons/electronics-2-14/24/515-512.png', color: 'rgb(255, 140, 26)'},
            relay: { name: 'Prise radiocommandée', type: 'relay', img: 'https://cdn2.iconfinder.com/data/icons/relays-and-electromagnets/283/ele828-512.png', color: 'rgb(255, 140, 26)'},
            motion: { name: 'Détecteur de mouvements', type: 'motion', img: 'https://static.thenounproject.com/png/40296-200.png', color: 'rgb(92, 177, 214)'}
        }

        // Un objet // array associatif contenant toutes les briques que l'on voudrait afficher
        const blocksIWant = {
            classic_led: blocksDatas.led,led_standard: blocksDatas.led,timed_led: blocksDatas.led,led_change: blocksDatas.led,
            button: blocksDatas.button,
            block_number_on: blocksDatas.number,block_number_off: blocksDatas.number,
            screen_msg: blocksDatas.screen,screen_OnOff: blocksDatas.screen,screen_delete_line: blocksDatas.screen,
            piezo_buzzer: blocksDatas.buzzer,timed_buzzer: blocksDatas.buzzer,
            potentiometer_brick: blocksDatas.potentiometer,
            makey_makey_brick: blocksDatas.makey,
            infrared: blocksDatas.infra,infra_boolean: blocksDatas.infra,
            temperature_brick: blocksDatas.meteo,
            brightness: blocksDatas.brightness,
            bluetooth_connection: blocksDatas.bluetooth,bluetooth_ok_connection: blocksDatas.bluetooth,bluetooth_sendto: blocksDatas.bluetooth,bluetooth_recievefrom: blocksDatas.bluetooth,
            relay_brick: blocksDatas.relay,
            motion_detector: blocksDatas.motion,
        }
        
        // Parse du xml de l'application String to DOMXml
        let xml = (new DOMParser()).parseFromString(this.props.app.xml, 'text/xml').documentElement

        // Recherche des tous les blocks que nous voulons afficher dans les infos de l'application
        // Puis stockage dans un array "allBlocks"
        // Remplacer par un map ?
        for(let blockIWant in blocksIWant) {
            xml.querySelectorAll('block[type=' + blockIWant + ']').forEach(block => {
                let name = block.querySelector("field[name='NOM']") || block.querySelector("field[name='NOMVAR']") || {};
                allBlocks.push({name: blocksIWant[blockIWant].name, datas: blocksIWant[blockIWant], varName: name.innerHTML})
            })
        }

        // Remplissage d'un array "blocks" constenant uniquement une itération de chaque type de blocks de l'array "allBlocks"
        // Chaque itération embarque avec elle une nouvelle propriétée qui représente le nombre d'occurence de chaque type de blocks dans l'application
        allBlocks.forEach(block => {
            if (!blocks.find(el => el.datas.type === block.datas.type)) {
                let blockOccurence = allBlocks.filter(element => element.datas.type === block.datas.type)
                for(let block in blockOccurence){
                    for(let blockSearch in blockOccurence){
                        if(blockSearch !== block && blockOccurence[block].varName == blockOccurence[blockSearch].varName){
                            delete blockOccurence[blockSearch].varName 
                            
                        }
                    }
                }
                blockOccurence = blockOccurence.filter(block => {
                    return block.varName
                })
          
                block.count = block.datas.type === 'relay' ? 1 : blockOccurence.length
                blocks.push(block)
            }
        })

        return blocks.map((block, index) => (        
            <Label title={block.count + ' x ' + block.name} key={index} style={{background: block.datas.color, color: '#fff'}} image>
                <img src={block.datas.img} alt={block.datas.name} style={{background: 'GhostWhite'}} />
                {/* {block.name} */} x {block.count}
            </Label>
        ))
    }
}

function mapStateToProps(state) {
	return {
		user: getActualUser(state),
    }
}

const connectedBlocksSummaryComponent = connect(mapStateToProps)(BlocksSummaryComponent)
export default connectedBlocksSummaryComponent