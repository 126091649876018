import React, {Component} from 'react';
import { Message, Grid, Button, Card, Dropdown, Accordion, Icon, Checkbox, List, Divider } from 'semantic-ui-react';
import { Link } from 'react-router-dom'
import querySearch from "stringquery";


import InstructorActivitySummaryComponent from '../../../../components/InstructorActivitySummaryComponent/InstructorActivitySummaryComponent';



export default class ActivityTab extends Component{

    constructor(props){
        super(props)

        const initialState = {
            isInitial:true,
            currentOrder: "lastModified",
            filter: {
                open: false,
                accessibility:{
                    all: true,
                    none: true,
                    group:false,
                    groups:[]
                },
                author:{
                    me:true,
                    others:true
                },
                store:{
                    private: true,
                    public: true
                }
            }
        }
        try{
            let structureState = window.localStorage.getItem("structure");
            if(structureState){
                structureState = JSON.parse(structureState)
                if(structureState.activity){
                    this.state = {...structureState.activity}
                    this.state.filter.open = true;
                }else{
                    this.state = initialState
                }
            }else{
                this.state = initialState
            }
        }catch(e){
            this.state = initialState
        }
         
    }

    componentDidMount(){
        const query = querySearch(this.props.location.search);
        if(query.filterGroup){
            let g=[]
            g.push(query.filterGroup)
            this.setState(prevState => {
                return {isInitial: null, urlFilter:true, filter: {...prevState.filter, open:true, accessibility: {...prevState.filter.accessibility, all:false, none:false, group:true, groups: g}}}
            })
        }else{
            if(this.state.isInitial){
                let g =[];
                this.props.groups.forEach(group => {
                    g.push(group._id)
                })
                g.push("nogroup")
                this.setState(prevState => {
                    return {isInitial: null, filter: {...prevState.filter, accessibility: {...prevState.filter.accessibility, groups: g}}}
                })
            }else{
    
            }

        }
    }

    componentDidUpdate(prev){
        if(prev.groups.length != this.props.groups.length && this.state.filter.accessibility.group && !this.state.urlFilter){
            this.props.groups.forEach(g => {
                if(!prev.groups.find(g2=>{return g2._id === g._id})){
                    //New group default add to filter
                    let groups = [...this.state.filter.accessibility.groups];
                    groups.push(g._id);
                    this.setState(prevState => {
                        return {filter: {...prevState.filter, accessibility: {...prevState.filter.accessibility, groups: groups}}}
                    })
                }
            })
        }
        let structureState = null
        try {
            window.localStorage.getItem("structure")
        } catch (error) {
            console.log("failed getItem structure")
        }
		if(structureState){
			structureState = JSON.parse(structureState)
		}else{
            structureState = {}
        }
        structureState.activity = structureState.activity || {};
        structureState.activity = this.state
        try{
            window.localStorage.setItem("structure", JSON.stringify(structureState));
        }catch(e){
            console.log("failed setItem structure")
        }
    }

    onOrderChanged = (e, data) => {
        console.log("SDDS")
        console.log(data)
		this.setState({currentOrder: data.value})
    }

    toggle = (name, arg) => {
        this.setState(prev => {
            switch(name){
                case "none":
                    return {filter: {...prev.filter, accessibility: {...prev.filter.accessibility, none: !prev.filter.accessibility.none}}}
                case "all":
                    const group = !prev.filter.accessibility.all ? false : prev.filter.accessibility.group        
                    return {filter: {...prev.filter, accessibility: {...prev.filter.accessibility, group: group, all: !prev.filter.accessibility.all}}}
                case "group":
                    const all = !prev.filter.accessibility.group ? false : prev.filter.accessibility.all  
                    return {filter: {...prev.filter, accessibility: {...prev.filter.accessibility, all:all, group: !prev.filter.accessibility.group}}}
                case "groups":
                    let groups = [...this.state.filter.accessibility.groups]
                    const index = groups.indexOf(arg);
                    if(index !== -1){
                        groups.splice(index, 1)
                    }else{
                        groups.push(arg)
                    }
                    return {filter: {...prev.filter, accessibility: {...prev.filter.accessibility, groups: groups}}}
                case "me":
                    return {filter: {...prev.filter, author: {...prev.filter.author, me: !prev.filter.author.me}}}
                case "others":
                    return {filter: {...prev.filter, author: {...prev.filter.author, others: !prev.filter.author.others}}}
                case "public":
                    return {filter: {...prev.filter, store: {...prev.filter.store, public: !prev.filter.store.public}}}
                case "private":
                    return {filter: {...prev.filter, store: {...prev.filter.store, private: !prev.filter.store.private}}}


            }
        })
    } 

    applyAccessibility(activities){
        if(!this.state.filter.accessibility.all && (!this.state.filter.accessibility.group || this.state.filter.accessibility.groups.length === 0)){
            activities = activities.filter(a => {
                return a.available.for !== "all"
            })
        }

        if(!this.state.filter.accessibility.none){
            activities = activities.filter(a => {
                return a.available.for !== "none"
            })
        }

        if(!this.state.filter.accessibility.group){
            activities = activities.filter(a => {
                return a.available.for !== "some"
            })
        }else{
            activities = activities.filter(a => { 
                if(a.available.for === "some"){
                    return a.available.list.some(group => {
                        return this.state.filter.accessibility.groups.includes(group._id)
                    })
                }
                return true
            })
        }

        return activities;
    }

    applyAuthor(activities){
        if(!this.state.filter.author.me){
            activities = activities.filter(a => {
                return a.originalUser !== null
            })
        }

        if(!this.state.filter.author.others){
            activities = activities.filter(a => {
                return a.originalUser === null
            })
        }

        return activities
    }

    applyStore(activities){
        if(!this.state.filter.store.public){
            activities = activities.filter(a => {
                return !a.activity.inStore
            })
        }

        if(!this.state.filter.store.private){
            activities = activities.filter(a => {
                return a.activity.inStore
            })
        }

        return activities
    }
    
    applyFilters(){
        let activities = [...this.props.activities];
        activities = this.applyAccessibility(activities);
        activities = this.applyAuthor(activities)
        activities = this.applyStore(activities)
        return activities
    }

    order(activities){
        if(this.state.currentOrder === "alphabetic"){
            activities = activities.sort((a, b) => {
                return a.activity.title.localeCompare(b.activity.title)
            })
        }else{
            activities = activities.sort((a, b) => {
                return new Date(b.activity.updatedAt) - new Date(a.activity.updatedAt)
            })
        }
        return activities
    }

    renderActivityTabMessage(){
		let message=[]
		if(this.props.error){
			let error = this.props.error
			if(error instanceof Error)
				error=[{message: error.toString()}]
			else if(typeof error === "string"){
				error = [{message: error}]
			}
			message.push(<Message key={message.length} error header="Erreur d'ajout" list={error.map(error => (error.message))}/>)

		}
		if(this.props.success){
			message.push(
					<Message  key={message.length} success header="Activité ajoutée" content={this.props.success}/>
			)
		}
		if(message.length){
			return (
				<Grid.Row style={{marginBottom: '10px'}}>
					{message}
				</Grid.Row>
			)
		}
		return null
    }
    
    renderFilters(){
        return <Accordion fluid styled>
            <Accordion.Title
                index={0}
                onClick={()=>{this.setState(prev => { return {filter: {...prev.filter, open: !prev.filter.open}}})}}
                active={this.state.filter.open}>
                <Icon name="filter"/>
                Filtres
            </Accordion.Title>
            <Accordion.Content active={this.state.filter.open}>
                <Grid columns={3} divided>
                    <Grid.Row>
                        <Grid.Column>
                            <b>Accessibilité</b>
                            <List>
                                <List.Item><Checkbox onClick={()=>{this.toggle("none")}} checked={this.state.filter.accessibility.none} label="A moi uniquement"/></List.Item>
                                <List.Item><Checkbox onClick={()=>{this.toggle("all")}} checked={this.state.filter.accessibility.all} label="A tous mes élèves"/></List.Item>
                                <List.Item><Checkbox onClick={()=>{this.toggle("group")}} checked={this.state.filter.accessibility.group} label="A certains groupes"/>
                                    {this.state.filter.accessibility.group && <List.List>
                                        {this.props.groups.map(group => {
                                            return <List.Item key={group._id}><Checkbox onClick={()=>{this.toggle("groups", group._id)}} disabled={!this.state.filter.accessibility.group} checked={this.state.filter.accessibility.groups.indexOf(group._id) !== -1} label={group.name}/></List.Item>
                                        })}
                                        <List.Item key={"nogroup"}><Checkbox onClick={()=>{this.toggle("groups", "nogroup")}} disabled={!this.state.filter.accessibility.group} checked={this.state.filter.accessibility.groups.indexOf("nogroup") !== -1} label={"Pas de groupe"}/></List.Item>
                                    </List.List>}
                                </List.Item>
                            </List>
                        </Grid.Column>
                        <Grid.Column>
                            <b>Auteur d'origine</b>
                            <List>
                                <List.Item><Checkbox onClick={()=>{this.toggle("me")}} checked={this.state.filter.author.me} label="Moi"/></List.Item>
                                <List.Item><Checkbox onClick={()=>{this.toggle("others")}} checked={this.state.filter.author.others} label="Autres auteurs"/></List.Item>
                            </List>
                        </Grid.Column>
                        <Grid.Column>
                            <b>Bibliothèque</b>
                            <List>
                                <List.Item><Checkbox onClick={()=>{this.toggle("private")}} checked={this.state.filter.store.private} label="Privée"/></List.Item>
                                <List.Item><Checkbox onClick={()=>{this.toggle("public")}} checked={this.state.filter.store.public} label="Publiée"/></List.Item>
                            </List>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Accordion.Content>
        </Accordion>
    }

    render(){
        let activities = this.applyFilters();
        activities = this.order(activities);
        activities = activities.map(a => {
            return <InstructorActivitySummaryComponent
                key={a.key}
			    activity={a.activity}
				deleteActivity={a.deleteActivity}
				getActivityShareId={a.getActivityShareId}
				originalUser={a.originalUser}
                available={a.available}
                tryActivity={this.props.tryActivity}
                progressions={this.props.progressions}
                selectedCourses={this.props.selectedCourses}
                finished={this.props.finishedCourses}
                steps={this.props.steps}
                users={this.props.users}
                user={this.props.user}
                structures={this.props.structures}
                groups={this.props.groups}
            />
        })

        return(
			<div className='pane' style={{height: "100%"}}>
				<Grid.Row width={8}>
                    <span>Trier par </span>
                    <Dropdown  inline onChange={this.onOrderChanged} value={this.state.currentOrder} options={[{key:"alphabetic", text: "Alphabétique", value:"alphabetic"},{key:"lastModified", text: "Dernière modification", value:"lastModified"}]}>
                    </Dropdown>
        		</Grid.Row>
                <Divider hidden></Divider>
                <Grid.Row style={{marginBottom: "10px"}}>
                    {this.renderFilters()}
                </Grid.Row>
                <Grid.Row style={{marginBottom: "10px"}}>
                    <Link to='/activityCreator'>
                        <Button color='orange' basic>
                            Créer une activité
                        </Button>
                    </Link>
                </Grid.Row>
				{this.renderActivityTabMessage()}
				<Grid.Row>
						{(activities.length && (<Card.Group>{activities}</Card.Group>)) || <p>Vous n'avez créé aucune activité pour le moment.</p>}
				</Grid.Row>
			</div>
		)
    }
}