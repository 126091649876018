export const usersBricksConstants = {
    'FETCH_ALL_STARTED':'USERSBRICKS_FETCH_ALL_STARTED',
    'FETCH_ALL_SUCCESS':'USERSBRICKS_FETCH_ALL_SUCCESS',
    'FETCH_ALL_FAILED':'USERSBRICKS_FETCH_ALL_FAILED',

    'ADD_BRICKS_STARTED':'USERSBRICKS_ADD_BRICKS_STARTED',
    'ADD_BRICKS_SUCCESS':'USERSBRICKS_ADD_BRICKS_SUCCESS',
    'ADD_BRICKS_FAILED':'USERSBRICKS_ADD_BRICKS_FAILED',

    'REMOVE_BRICKS_STARTED':'USERSBRICKS_REMOVE_BRICKS_STARTED',
    'REMOVE_BRICKS_SUCCESS':'USERSBRICKS_REMOVE_BRICKS_SUCCESS',
    'REMOVE_BRICKS_FAILED':'USERSBRICKS_REMOVE_BRICKS_FAILED',

    'UPDATE_QUANTITY_STARTED':'USERSBRICKS_UPDATE_QUANTITY_STARTED',
    'UPDATE_QUANTITY_SUCCESS':'USERSBRICKS_UPDATE_QUANTITY_SUCCESS',
    'UPDATE_QUANTITY_FAILED':'USERSBRICKS_UPDATE_QUANTITY_FAILED',


    'SAVE_MODIFICATIONS_STARTED':'USERSBRICKS_SAVE_MODIFICATIONS_STARTED',
    'SAVE_MODIFICATIONS_SUCCESS':'USERSBRICKS_SAVE_MODIFICATIONS_SUCCESS',
    'SAVE_MODIFICATIONS_FAILED':'USERSBRICKS_SAVE_MODIFICATIONS_FAILED',
}