import React from 'react'
import {withRouter} from 'react-router-dom'

import {
    Form,
    Segment,
    Message,
    Button,
    Header,
    Divider
} from 'semantic-ui-react'

const AuthenticatedForm = (props) => {
    let curr = new Date();
    curr.setDate(curr.getDate() + 3);
    let date = curr.toISOString().substr(0,10);
    return (
        <Form size='large' loading={props.isLoading} error={props.error !== null}>
            <Segment>
                {props.error && (
                    <Message
                    error
                    >
                    <Header>Erreur d'inscription</Header>
                    {props.error.data.message.map((error, key) => {return <p key={key}>{error.message}</p>})}</Message>
                )}
                <Form.Input
                    autoFocus
                    style={{marginBottom:'10px'}}
                    name={'username'}
                    onChange={props.onUserChange}
                    fluid
                    icon='user'
                    iconPosition='left'
                    placeholder='Nom ou pseudo'
                    label='Nom ou pseudo'
                    type='text'
                />
                <Form.Input
                    style={{marginBottom:'10px'}}
                    name={'email'}
                    onChange={props.onUserChange}
                    fluid
                    icon='mail'
                    iconPosition='left'
                    placeholder='E-mail'
                    label='E-mail'
                />
                <Form.Input
                    style={{marginBottom:'10px'}}
                    name={'password'}
                    onChange={props.onUserChange}
                    fluid
                    icon='lock'
                    iconPosition='left'
                    placeholder='Mot de passe'
                    label='Mot de passe'
                    type='password'
                />
                
                <Form.Input
                    style={{marginBottom:'10px'}}
                    type={'date'}
                    name={'birthday'}
                    onChange={props.onUserChange}
                    fluid
                    icon='calendar'
                    iconPosition='left'
                    label='Date de naissance'
                />
                </Segment>
                <Button type="submit" color='orange' fluid size='large'
                        onClick={(e) => {e.preventDefault();props.onSubmit(props.history, 'authenticated')}}>
                    Inscription
                </Button>
            
        </Form>
    )
}

export default withRouter(AuthenticatedForm)