import {schema} from 'normalizr'

export const appEntity = new schema.Entity('apps', {}, {idAttribute: '_id'});
export const clueEntity = new schema.Entity('clues', {}, {idAttribute: '_id'});
export const finishedCourseEntity = new schema.Entity('finishedCourses',{}, {idAttribute: '_id'});
export const courseEntity = new schema.Entity('courses', {}, {idAttribute: '_id'});
export const userEntity = new schema.Entity('users', {}, {idAttribute: '_id'});
export const stepEntity = new schema.Entity('steps', {}, {idAttribute: '_id'});
export const progressionEntity = new schema.Entity('progressions', {}, {idAttribute: '_id'});
export const spreadsheetEntity = new schema.Entity('spreadsheets', {}, {idAttribute: '_id'});
export const structureEntity = new schema.Entity('structures', {}, {idAttribute: '_id'});
export const groupEntity = new schema.Entity('groups', {}, {idAttribute: '_id'});
export const selectedCourseEntity = new schema.Entity('selectedCourses', {}, {idAttribute: '_id'});




