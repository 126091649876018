export const freeCreationConstants = {
    INVALIDATE_FREE_CREA_STATE: "FREE_CREA_INVALIDATE_FREE_CREA_STATE",
    SELECT_APP_STARTED: "FREE_CREA_SELECT_APP_STARTED",
    SELECT_APP_SUCCEEDED: "FREE_CREA_SELECT_APP_SUCCEEDED",
    SELECT_APP_FAILED: "FREE_CREA_SELECT_APP_FAILED",
    OPEN_MODAL: "FREE_CREA_OPEN_MODAL",
    CLOSE_MODAL: "FREE_CREA_CLOSE_MODAL",
    OPEN_SYNC_MODAL: "FREE_CREA_OPEN_SYNC_MODAL",
    CLOSE_SYNC_MODAL: "FREE_CREA_CLOSE_SYNC_MODAL",

    SAVE_XML: "FREE_CREA_SAVE_XML",
    LOAD_XML: "FREE_CREA_LOAD_XML",
    SAVE_XML_FAILED: "FREE_CREA_SAVE_XML_FAILED",
    LOAD_XML_FAILED: "FREE_CREA_LOAD_XML_FAILED",
    LOAD_XML_DONE: "FREE_CREA_LOAD_XML_DONE",
    // SAVE_CODE_FAILED: "FREE_CREA_SAVE_CODE_FAILED",
    // LOAD_CODE: "FREE_CREA_LOAD_CODE",
    // LOAD_CODE_FAILED: "FREE_CREA_OAD_CODE_FAILED",
    EDITOR_STATE: "FREE_CREA_EDITOR_STATE",
    GET_CPP: "FREE_CREA_GET_CPP",
    SYNCHRO_CODE_ACE: "FREE_CREA_SYNCHRO_CODE_ACE",
    SAVE_CURRENT_APP_STARTED: "FREE_CREA_SAVE_CURRENT_APP_STARTED",
    SAVE_CURRENT_APP_SUCCEEDED: "FREE_CREA_SAVE_CURRENT_APP_SUCCEEDED",
    SAVE_CURRENT_APP_FAILED: "FREE_CREA_SAVE_CURRENT_APP_FAILED",
    SAVE_NEW_APP_STARTED: "FREE_CREA_SAVE_NEW_APP_STARTED",
    SAVE_NEW_APP_SUCCEEDED: "FREE_CREA_SAVE_NEW_APP_SUCCEEDED",
    SAVE_NEW_APP_FAILED: "FREE_CREA_SAVE_NEW_APP_FAILED",

    FIRST_LOAD: "FREE_CREA_FIRST_LOAD",
    CHANGE_STATUS: "FREE_CREA_CHANGE_STATUS",
    TITLE_CHANGE: "FREE_CREA_TITLE_CHANGE",
    SAVE_CODE_ACE: "FREE_SAVE_CODE_ACE",
    SAVE_CODE_ACE_FAILED: "FREE_CREA_SAVE_CODE_ACE_FAILED",
    LOAD_CODE: "FREE_CREA_LOAD_CODE",
    LOAD_CODE_PYTHON: "FREE_CREA_LOAD_CODE_PYTHON",
    LOAD_CODE_FAILED: "FREE_CREA_LOAD_CODE",
};
