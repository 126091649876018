import React, { Component } from 'react'
import {connect} from 'react-redux'
import { bricksActions } from '../../../actions/model';
import { usersBricksActions } from '../../../actions';
import {getActualUser} from "../../../reducers/user_reducer"

import {
	Container,
	Image,
	Segment,
	Header,
	Grid,
    Label,
    Icon,
    Modal,
	List,
	Item,
    Placeholder,
    Message,
    Table,
    Input,
    Form,
    Button,
    Divider,
} from 'semantic-ui-react'

import './Brick.css'

class Brick extends Component {

    constructor(props){
        super(props)

        this.state = {
            quantity: null
        }
    }
        // brick._id === 'missing'

    handleDecrementQuantity(e){
        if(!this.props.brick._id && this.props.brick.count === 1){
            console.log('Removing a brick')
            this.handleRemoveBrick(this.props.brick)
        } else {
            console.log('Decrementing a brick')
            const count = this.props.brick.count < 1 ? 0 : this.props.brick.count - 1
            this.handleQuantityChange(this.props.brick, count)
        }
    }

    handleIncrementQuantity(e){
        // console.log('this.props.brick._id',this.props.brick._id)
        if(!this.props.brick._id && this.props.brick.count === 0){
            console.log('Adding a brick')
            this.handleAddBrick(this.props.brick)
        }else{
            console.log('Incrementing a brick')
            const count = this.props.brick.count >= 99 ? 99 : this.props.brick.count + 1
            this.handleQuantityChange(this.props.brick, count)
        }
    }

    handleQuantityChange(brick, count){
        this.props.dispatch(usersBricksActions.updateQuantity({
            _id: brick._id,
            user: null,
            brick: brick.brick,
            count: count,
        }))
    }

    handleAddBrick(brick){
        this.props.dispatch(usersBricksActions.addUsersBricks({
            _id: brick._id,
            user: null,
            brick: brick.brick,
            count: 1,
        }))
    }

    handleRemoveBrick(brick){
        this.props.dispatch(usersBricksActions.removeUsersBricks(brick))
    }

	render(){

        const brick = this.props.brick
        
		return (
            <Button.Group floated='right' size='mini' compact>
                
                <Button color='teal' onClick={(e) => this.handleDecrementQuantity(e)} title='Réduire la quantité' disabled={brick.count > 0 ? false : true}>-</Button>
                <Button basic>{brick.count}</Button>
                {/* <Form.Field name={brick.brick.name} onChange={(e) => this.handleQuantityChange(e)} control='input' type='number' value={brick.count} max={99} disabled/> */}
                <Button color='teal' onClick={(e) => this.handleIncrementQuantity(e)} title='Augmenter la quantité'>+</Button>
            </Button.Group>
		)
	}
}

function mapStateToProps(state) {
    return {
        user: getActualUser(state),
        bricks: state.model.bricks,
        bricks_isLoading: state.model.bricks.isLoading,
        usersBricks: state.usersBricks,
        usersBricks_isLoading: state.usersBricks.isLoading,
	}
}

const connectedBrick = connect(mapStateToProps)(Brick)
export { connectedBrick as Brick }