import React from 'react';
import { Popup } from 'semantic-ui-react';



const MultiProgressbar = (props) => {
    
    let p = props.bars.map((bar, i) => {
        let leftRadius = i==0;
        let rightRadius = i==props.bars.length-1
        console.log(bar.ratio)
        return (
            <Popup key={i} content={bar.info} header={bar.title} trigger={<div key={i} style={{backgroundColor: bar.color, width:bar.ratio+"%", height:"10px", margin:0, borderTopLeftRadius: leftRadius ? "4px" : "unset", borderBottomLeftRadius: leftRadius ? "4px" : "unset", borderTopRightRadius: rightRadius ? "4px" : "unset", borderBottomRightRadius: rightRadius ? "4px" : "unset"}}>

            </div>}></Popup>
        )
    })
   
    return(
        <div style={{width:"100%", height:"10px", display:"flex", borderRadius:'3px'}}>
            {p}
        </div>
      
    )
}

export { MultiProgressbar }