import { simulatorConstants } from '../constants';

const initialState = {
  open: false,
  code: null
}

export function simulator(state = initialState, action) {
  switch (action.type) {
    case simulatorConstants.OPEN:
    return {
      ...state,
      open:true
    }
    case simulatorConstants.CLOSE:
    return {
      ...state,
      open:false
    }
    case simulatorConstants.UPDATE_CODE:
    return {
      ...state,
      code:action.code
    }
    default:
      return state;
  }
}