import React, { Component } from 'react';
import ReactTimeAgo from 'react-time-ago'
import DOMPurify from 'dompurify';


import { Segment, Grid, Step, Header, Icon, Message } from 'semantic-ui-react';
import './ModalCourseConsultationComponent.css';
import { throwStatement } from '@babel/types';

class ModalCourseConsultationComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            validateActive: false,
            validateText: 'Activité non validée',
            inProgressActive: false,
            inProgressText: 'Pas d\'activité en cours'
        }

        this.showValidate = this.showValidate.bind(this);
        this.showInProgress = this.showInProgress.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.activityValidate !== this.props.activityValidate) {
            if (this.props.activityValidate) {
                this.setState({validateActive: true, validateText: 'Activité Validée'});
            }
            else {
                this.setState({validateText: 'Activité non validée'});
            }
        }

        if (prevProps.activityInProgress !== this.props.activityInProgress) {
            if (this.props.activityInProgress) {
                this.setState({inProgressText: 'Activité en cours'});
                if (!this.props.activityValidate) {
                    this.setState({inProgressActive: true});
                }
            }
            else {
                this.setState({inProgressText: 'Pas d\'activité en cours'});
            }
        }
    }

    renderActivityValidate() {
        if (this.props.finished) {
            return (
                <div>
                    <div className='date_activity'>Validée <ReactTimeAgo date={new Date(this.props.finished.createdAt)} locale="fr"/></div>
                </div>
            )
        }
    }

    renderActivityUpdated() {
        if ((this.props.finished && this.props.finished.endedXml !== this.props.progression.xml) || (this.props.progression.xml && !this.props.finished)) {        
            return (
                <div>
                    <div className='date_activity'>Modifiée <ReactTimeAgo date={new Date(this.props.progression.updatedAt)} locale="fr"/></div>
                </div>
            )
        }
    }

    showValidate() {
        this.setState({validateActive: true, inProgressActive: false});
        this.props.showValidate();
    }

    showInProgress() {
        this.setState({validateActive: false, inProgressActive: true});
        this.props.showInProgress();
    }

    renderElapsed(){
        let time = new Date(0);
        time.setHours(0)
        time.setMinutes(0)
        time.setSeconds(0)
        if(this.props.finished && this.props.finished.elapsed){
            time.setSeconds(parseInt(this.props.finished.elapsed))
        }else if(this.props.progression && this.props.progression.elapsed){
            time.setSeconds(parseInt(this.props.progression.elapsed))
        }else{
            return null;
        }
        let str="";
        if(time.getHours())
            str+=time.getHours()+" heure(s) ";
        str+=time.getMinutes()+" minute(s) ";
        str+=time.getSeconds()+" seconde(s)";
        return (<p>Temps passé: {str}</p>)
        
    }

    renderSteps(){
        let ValidateDisabled = this.props.activityValidate ? false : true;
        let InProgressDisabled = this.props.activityInProgress ? false : true;
        let progressText
        let validateText
       
        if(this.props.isCourse){
            progressText = this.props.activityInProgress ? "Parcours en cours" : "Pas de parcours en cours"
            validateText = this.props.activityValidate ? "Parcours validé" : "Parcours non validé"
            //Course consultation can show only a progression or a finished course not both
            return (
                <Step.Group>
                    {this.props.activityValidate &&
                    <Step active={this.props.activeStep === "validate"} disabled={ValidateDisabled} onClick={this.showValidate}>
                    {this.props.activityValidate && <Icon name='check' />}
                    <Step.Content>
                        <Step.Title>{validateText}</Step.Title>
                        <Step.Description>{this.renderActivityValidate()}</Step.Description>
                    </Step.Content>
                    </Step>}
                    {this.props.activityInProgress &&
                    <Step active={this.props.activeStep === "progress"} disabled={InProgressDisabled} onClick={this.showInProgress}>
                    {this.props.activityInProgress && <Icon name='hourglass half' />}
                    <Step.Content>
                        <Step.Title>{progressText}</Step.Title>
                        <Step.Description>{this.renderActivityUpdated()}</Step.Description>
                    </Step.Content>
                    </Step>}
                </Step.Group>)

        }else{
            //Activity can show both
            progressText = this.props.activityInProgress ? "Activité en cours" : "Pas d'activité en cours"
            validateText = this.props.activityValidate ? "Activité validée" : "Activité non validée"
            return (
                <Step.Group>
                    <Step active={this.props.activeStep === "validate"} disabled={ValidateDisabled} onClick={this.showValidate}>
                    {this.props.activityValidate && <Icon name='check' />}
                    <Step.Content>
                        <Step.Title>{validateText}</Step.Title>
                        <Step.Description>{this.renderActivityValidate()}</Step.Description>
                    </Step.Content>
                    </Step>
                    <Step active={this.props.activeStep === "progress"} disabled={InProgressDisabled} onClick={this.showInProgress}>
                    {this.props.activityInProgress && <Icon name='hourglass half' />}
                    <Step.Content>
                        <Step.Title>{progressText}</Step.Title>
                        <Step.Description>{this.renderActivityUpdated()}</Step.Description>
                    </Step.Content>
                    </Step>
                </Step.Group>)
        }
    }

    render() {
        if (!this.props.student || !this.props.activity || !this.props.step) {
            return(
                <div>
                    <p>Chargement...</p>
                </div>
            )
        }

        return (
            <div className='modalConsultation' style={{display: "flex", flexDirection: "column", flex: "1 0 auto"}}>
                <Segment className='segment_consultation'>
                    <Header>{this.props.student.username}</Header>
                    
                    <Grid.Column className='btn_group' textAlign='center'>
                        {this.renderSteps()}
                    </Grid.Column>
                    {this.renderElapsed()}
                    <Message className='message_consultation'>
                    <Message.Header>{this.props.activity.title}</Message.Header>
                    <p className='instruction'dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.step.instruction)}}>

                    </p>
                    </Message>
                </Segment>
            </div>
        )
    }
}

export { ModalCourseConsultationComponent }