import React, {Component} from 'react';
import { Link } from 'react-router-dom'
import querySearch from "stringquery";
import {withRouter} from 'react-router-dom'


import {
	Modal, 
	Segment,
	Message, 
	Input, 
	Icon,
	Card, 
	Button, 
	Container, 
	Header, 
	Divider,
	Grid, 
	Tab, 
	Accordion, 
	List, 
	Popup,
	Dropdown,
} from 'semantic-ui-react'

import './StructureComponent.css'

import AdministrationTab from './Tabs/AdministrationTab/AdministrationTab'
import CoursesTab from './Tabs/CoursesTab/CoursesTab'
import StructureStudentCard from './StructureStudentCard'
import { throwStatement } from '@babel/types';
import { compose } from 'redux';
import ActivityTab from './Tabs/ActivityTab/ActivvityTab';
import StudentsTab from './Tabs/StudentsTab/StudentsTab';

class StructureComponentWithoutRouter extends Component {

	constructor(props){
		super(props)
		let structureState = null
		try {
			window.localStorage.getItem("structure")	
		} catch (error) {
			console.log("failed getItem structure")
		} 
		if(structureState){
			structureState = JSON.parse(structureState)
		}
		let activeTab = structureState && structureState.currentTab ? structureState.currentTab: 0
		this.state = {
			modal:{
				close:true,
				text:"",
				actionDescription:"",
				learnerId:"",
			},
			studentsTab:{
				order:"alphabetic"
			},
			activeTab: activeTab,
			animate:false,
		}

		this.getActivities = this.getActivities.bind(this);
		this.renderStudientsTab = this.renderStudientsTab.bind(this);
		this.renderActivityTab = this.renderActivityTab.bind(this);
	}

	componentDidMount(){
		const query = querySearch(this.props.location.search);
		if(query.tab){
			let index=0
			switch(query.tab){
				case "admin":
					index = 0
				break;
				case "students":
					index=1
				break
				case "activities":
					index=2
				break;
				case "courses":
					index=3
				break;
			}
			this.setState({activeTab: index})
		}
		
	}

	componentDidUpdate(prevProps){
		const query = querySearch(this.props.location.search);
		const lastQuery = querySearch(prevProps.location.search);
		if(query.tab && query.tab != lastQuery.tab){
			let index=0
			switch(query.tab){
				case "admin":
					index = 0
				break;
				case "students":
					index=1
				break
				case "activities":
					index=2
				break;
				case "courses":
					index=3
				break;
			}
			this.setState({activeTab: index})
		}
	}

	componentWillUnmount(){
		let state = null
		try {
			window.localStorage.getItem("structure");
		} catch (error) {
			console.log("failed getItem structure")
		}	
		if(!state){
			state = {}
		}else{
			state = JSON.parse(state)
		}
		state.currentTab = this.state.activeTab
		try{
			window.localStorage.setItem("structure", JSON.stringify(state))
		}catch(e){
			console.log("failed setItem structure");
		}
	}

	capitalize(str){
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	
	  
	changeTab = (e, data) => {
		this.props.history.push("/structure")
		this.setState({activeTab: data.index})
	}

	renderTabs(){
		const panes = [
			{menuItem: {name:"Administration", content:"Administration", key:0, onClick:this.changeTab}, render: ()=>(<AdministrationTab 
				structure={this.props.structure} 
				users={this.props.users} 
				groups={this.props.groups} 
				onAddStudents={this.props.onAddStudents} 
				onAddGroup={this.props.onAddGroup}
				onGroupUpdate={this.props.onGroupUpdate}
				onGroupSuppression={this.props.onGroupSuppression}/>), onClick:this.changeTab},
			{menuItem: {name:"Élèves", content:"Élèves", key:1, onClick:this.changeTab}, render: this.renderStudientsTab},
			{menuItem: {name:"Activités", content:"Activités", key:2, onClick:this.changeTab}, render: this.renderActivityTab},
			{menuItem: {name:"Parcours", content: "Parcours", key:3, onClick:this.changeTab}, render: ()=>(
				<CoursesTab courses={this.props.courses.entities && Object.values(this.props.courses.entities).filter(c => !c.isActivity && c._id && this.props.courses.entities[c._id] && c.title /*historicaly isActity wa not set, but now we can have partial course in the store, epresenting attribute cloneFrom those don't have isActivity set either but ARE NOT course and must be filtered */)}
				groups={Object.values(this.props.groups.entities).filter(g=>{return g.structure === this.props.structure._id})} 
				progressions={this.props.progressions}
				finishedCourses={this.props.finishedCourses}
				selectedCourses={this.props.selectedCourses}
				steps={this.props.steps}
				users={this.props.users}
				user={this.props.user}
				structures={this.props.structures}
				/>
				)}
		];

		return (
			<Tab panes={panes} activeIndex={this.state.activeTab} style={{height: "100%"}}/>
		)
	}

	renderStudientsTab() {
		return(
			<StudentsTab 
				users={this.props.users} 
				groups={this.props.groups} 
				structure={this.props.structure}
				onTransferStudent={this.props.onTransferStudent}
				onDeleteClick={this.props.onDeleteClick}/>
		)
	}

	
	renderActivityTab() {
		let activities = this.getActivities();

		return(
			<ActivityTab location={this.props.location} 
			tryActivity={this.props.tryActivity} 
			activities={activities} 
			error={this.props.courses.error} 
			success={this.props.courses.success} 
			groups={Object.values(this.props.groups.entities).filter(g=>{return g.structure === this.props.structure._id})} 
			progressions={this.props.progressions}
			finishedCourses={this.props.finishedCourses}
			selectedCourses={this.props.selectedCourses}
			steps={this.props.steps}
			users={this.props.users}
			user={this.props.user}
			structures={this.props.structures}/>
		)
	}

	getActivities() {
		let activities = [];
		for (let course in this.props.courses.entities) {
			course = this.props.courses.entities[course];
			let selected = { for: "none", list: []}
			for(let select of this.props.selectedCourses){
				if(select.course === course._id){
					if(select.structure){
						selected.for = "all"
						break;
					}else if(select.group){
						if(this.props.groups.entities[select.group]){
							selected.for = "some"
							selected.list.push(this.props.groups.entities[select.group])
						}
					}				
				}
			}
			if (course.isActivity && course.user === this.props.structure.owner) {
				
				activities.push(
					 {
						key:course._id,
						activity:course,
						deleteActivity:this.props.deleteActivity,
						getActivityShareId:this.props.getActivityShareId,
						originalUser: course.cloneFrom && this.props.courses.entities[course.cloneFrom] && this.props.users.entities[this.props.courses.entities[course.cloneFrom].user] && this.props.users.entities[this.props.courses.entities[course.cloneFrom].user]._id !== this.props.user._id ? this.props.users.entities[this.props.courses.entities[course.cloneFrom].user] : null,
						available: selected
					}
				);
			}
		}

		return activities.sort((a, b) => {
			a = new Date(a.activity.createdAt);
			b = new Date(b.activity.createdAt);
			return b - a;
		});
	}

	render(){
		if(!this.props.structure)
			return (
				<Message negative style={{display: "block"}}>
			    <Message.Header>Erreur</Message.Header>
			    <Message.Content>
			    	<p>Votre établissement n'a pas été trouvé</p>
			    	<Link to='/'>Retour à l'accueil</Link>
			    </Message.Content>
			  </Message>
			)

		return (
			<Container style={{height: "100%"}}>
				<Segment>
					<Header size="large">{this.capitalize(this.props.structure.name)}</Header>		
				</Segment>
					
				{this.renderTabs()}
			</Container>
		)
	}
}

const StructureComponent =  withRouter(StructureComponentWithoutRouter)
export {  StructureComponent };