import React, {Component} from 'react';

import {Modal, Image, Header, Form, Button, Dropdown, Container} from 'semantic-ui-react'

// import './ModalProcedureComponent.css'

class ModalVariableComponent extends Component{

	constructor(props){
		super(props);

		this.state = {
			type: "int",
			name: ""
		}
		
	}

	componentDidMount(){
		//this.setupBlockly()
		
	}

	keyUp = (event) => {
		if(event.keyCode === 13){
			this.onActionClick(null, {id: "done"})
		}
	}

	onClose = () => {
		document.removeEventListener("keyup", this.keyUp)
		this.props.onClose();
	}

	onOpen = () => {
		document.addEventListener("keyup", this.keyUp)
		document.getElementById("variableFormInputName").focus();
	}

	onActionClick = (event, data) => {
		if(data.id === "done"){
			if(this.props.callback){
				if(this.props.type === "list"){
					this.props.callback(this.state.name, null, this.state.type)
				}else{
					this.props.callback(this.state.name)
				}
			}
		}

		this.onClose();
	}

	cbType = (event, data) => {
		this.setState({type:data.value})
	}

	cbName = (event) => {
		const {value, name} = event.target;
		this.setState({name: value})
	}

	render(){
		let title;
		let namePlaceholder; 
		if(this.props.type === "list"){
			title = "Création d'une nouvelle liste";
			namePlaceholder = "ma liste";
		}else{
			title = "Création d'une nouvelle variable";
			namePlaceholder = "ma variable";
		}
		return (
			<Modal size={"large"} open={this.props.open} onMount={this.onOpen} onClose={this.onClose}>
		    <Modal.Header>{title}</Modal.Header>
		    <Modal.Content style={{ padding: 0}}>
		    	<Form style={{margin: "auto", padding: "25px 0 25px 0", width: "80%"}}>
				    <Form.Field>
				      <label>Nom</label>
				      <input id="variableFormInputName" placeholder={namePlaceholder} onChange={this.cbName}/>
				    </Form.Field>
						{ this.props.type === "list" && 
						(<Form.Field>
				      <label>Type</label>
				     	<Dropdown
				     		onChange={this.cbType}
						  	fluid
						  	selection
						  	defaultValue={'int'}
						    options={[
						    	{
								    key: 'int',
								    text: 'Nombre',
								    value: 'int'
							  	},
							  	{
								    key: 'float',
								    text: 'Nombre à virgule',
								    value: 'float'
							  	},
							  	{
								    key: 'string',
								    text: 'Texte',
								    value: 'String'
							  	},
							  	{
								    key: 'bool',
								    text: 'Booléen',
								    value: 'bool'
							  	},
							  ]}
						  />
				    </Form.Field>)}
				  </Form>
		    </Modal.Content>
		    <Modal.Actions onActionClick={this.onActionClick} actions={[{key: 'cancel', id:'cancel', content: 'Annuler'}, {key: 'done', id:'done', content: "Valider", positive:true}]}/>
		  </Modal>
		)
	}
}

export default ModalVariableComponent;