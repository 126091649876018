import axios from 'axios'
import {sessionService} from 'redux-react-session'
import runtimeEnv from '@mars/heroku-js-runtime-env';
import history from '../history'

const API_BASE_URL = runtimeEnv().REACT_APP_API_BASE_URL

const client = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: ''
    }
})

client.interceptors.request.use(async function (config) {
    try{
	    const jwt = await sessionService.loadUser()
	    if(jwt)
	        config.headers.Authorization = 'Bearer ' + jwt.jwt
  	}catch(e){
  		return config
  	}
    return config
}, function (error) {
		return Promise.reject(error)
})

client.interceptors.response.use(null, function(error){
    if(error.response.data.statusCode === 403 && error.response.data.message && error.response.data.message.logout)
        history.push("/logout");
    else
      return Promise.reject(error);
})

export const structureApi = {
    fetchByName,
    linkUserToStructure,
    unlinkUserFromStructure,
    fetchStructure,
    update,
    fetchByPin
}

function unlinkUserFromStructure() {
    return client.put('/user/structure')
}

function linkUserToStructure(structure) {
    return client.put('/user/structure', structure)
}

function fetchStructure() {
	return client.get(`/structure`)
}

function fetchByName(name) {
    return client.get(`/structure/name/${name}`)
}

function fetchByPin(pin) {
    return client.get(`/structures/pin/${pin}`)
}

function update(id, url) {
    return client.put(`/structures/${id}`, url);
}