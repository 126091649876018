import React, {Component} from 'react';
import { Link } from 'react-router-dom'

import CourseSummaryComponent from '../CourseSummaryComponent/CourseSummaryComponent';

import {Container, Card, Button, Header, Message, Accordion, Icon, Grid, Divider, List, Checkbox} from 'semantic-ui-react';
import './ActivityRenderingListComponent.css';



class ActivityRenderingListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            studentRendering: [],
            filter:{
                open:false,
                groups:[],
                status:{
                    notStarted: true,
                    started: true,
                    ended: true
                }
            }
        }
    }

    componentDidMount(){
        let g =[];
        this.props.groups.array.forEach(group => {
            g.push(group)
        })
        
        this.setState(prevState => {
            return {filter: {...prevState.filter, groups: g}}
        })
    }

    componentDidUpdate(prev){
        if(prev.groups.array.length != this.props.groups.array.length){
            let groups = [...this.state.filter.groups];
            this.props.groups.array.forEach(g => {
                if(!prev.groups.array.find(g2=>{return g2 === g})){
                    //New group default add to filter
                    groups.push(g);
                }
                this.setState(prevState => {
                    return {filter: {...prevState.filter, groups: groups}}
                })
            })
        }
    }

    // getCardList() {
    //     let studentRendering = [];
    //     for (let progression in this.props.progressions) {
    //         progression = this.props.progressions[progression];
    //         let step =this.props.steps[progression.step]

    //         if(!step || !this.props.activity || step.course != this.props.activity._id)
    //             continue

    //         let finish = null
    //         for (let finished in this.props.finished) {
    //             finished = this.props.finished[finished];
    //             if (finished.user === progression.user && finished.course === step.course) {
    //                 if(!finish || finish.createdAt < finished.createdAt){
    //                     finish = finished;
    //                 }
    //             }
    //         }
    //         if (!finish) {
    //             if(this.props.users[progression.user])
    //                 studentRendering.push(<CourseSummaryComponent key={progression._id} showElapsed user={this.props.users[progression.user]} course={this.props.activity} progression={progression} consultation={true} update={true} renderingList={true} />)
    //         }else{
    //             let update = finish.endedXml !== progression.xml ? true : false;
    //             studentRendering.push(<CourseSummaryComponent key={progression._id} showElapsed user={this.props.users[progression.user]} course={this.props.activity} progression={progression} consultation={true} update={update} finished={finish} renderingList={true} />)
    //         }
    //     }
    //     return studentRendering
    // }

    toggle = (name, arg) => {
        this.setState(prev => {
            switch(name){
                case "groups":
                    let groups = [...this.state.filter.groups]
                    const index = groups.indexOf(arg);
                    if(index !== -1){
                        groups.splice(index, 1)
                    }else{
                        groups.push(arg)
                    }
                    return {filter: {...prev.filter, groups: groups}}
                case "notStarted":
                    return {filter: {...prev.filter, status: {...prev.filter.status, notStarted: !prev.filter.status.notStarted}}}
                case "started":
                    return {filter: {...prev.filter, status: {...prev.filter.status, started: !prev.filter.status.started}}}
                case "ended":
                    return {filter: {...prev.filter, status: {...prev.filter.status, ended: !prev.filter.status.ended}}}
                
            }
        })
    } 

    addNotStartedStudents(students){
        if(!this.props.activity)
            return students
        let owner = this.props.users[this.props.user._id];
        if(!owner)
            return students

        let structure = Object.values(this.props.structures).find(s => { if(s.owner === owner._id)return s})
        if(!structure)
            return students

        for (let user in this.props.users) {
            user = this.props.users[user]
            if(students.find(student => {
                return student.user._id === user._id
            }))
                continue
        
            if(user.structure === structure._id && (!this.props.activity.isActivity || Object.values(this.props.selectedCourses).find(selected => { return selected.course === this.props.activity._id && selected.structure === structure._id}))){
                students.push({user: user, notStarted:true})
            }else{
                console.log(this.props.groups.entities[user.group], user)
                if(this.props.groups.entities[user.group] && this.props.groups.entities[user.group].structure === structure._id && (!this.props.activity.isActivity || Object.values(this.props.selectedCourses).find(selected => { return selected.course === this.props.activity._id && (selected.structure === structure._id || selected.group === user.group)}))){
                    students.push({user: user, notStarted:true})
                }
            }


        }

        return students
    }

    applyGroups(students){
        students = students.filter(s => {
            return this.state.filter.groups.includes(s.user.group ? s.user.group : "nogroup")
        })
        return students
    }

    applyStatus(students){

        if(!this.state.filter.status.notStarted){
            students = students.filter(student => {
                return !student.notStarted
            })
        }

        if(!this.state.filter.status.started){
            students = students.filter(student => {
                return (!student.progression || student.progression.ended) || student.finish || student.notStarted
            })
        }

        if(!this.state.filter.status.ended){
            students = students.filter(student => {
                return !student.finish && (!student.progression || !student.progression.ended)
            })
        }
        return students
    }

    applyFilter(students){

        students = this.applyGroups(students);
        students = this.applyStatus(students);

        return students;

    }

    getStudentsForCourse(){
        let students = []
        for (let progression in this.props.progressions) {
            progression = this.props.progressions[progression];
            let step =this.props.steps[progression.step]

            if(!step || !this.props.activity || step.course != this.props.activity._id)
                continue

            let finish = Object.values(this.props.finished).find(f => {
                return f.user === progression.user && f.course === step.course
            })

            if (!finish) {
                if(this.props.users[progression.user])
                    students.push({key:this.props.users[progression.user]._id, user:this.props.users[progression.user], progression:progression, update:true, currentStep: progression.ended ? this.props.activity.stepsCount : step.currentPositionInCourse-1})
            }
        }
        let finished = Object.values(this.props.finished).reduce((prev, finish) => {
            if(!this.props.activity || finish.course !== this.props.activity._id)
                return prev
            if(!Object.values(prev).find(f => {
                return f.user === finish.user && f.createdAt > finish.createdAt
            })){
                prev[finish.user] = finish;
            }
            return prev
        }, {})

        finished = Object.values(finished).forEach(finish => {
            if (this.props.activity && finish.course === this.props.activity._id) {
                let progression = Object.values(this.props.progressions).find(p => {
                    let step =this.props.steps[p.step]
                    if(p.user === finish.user && step && finish.course === step.course)
                        return p
                    return null
                })
                let update = progression && progression.createdAt > finish.createdAt
                if(this.props.users[finish.user])
                    students.push({key:this.props.users[finish.user]._id, user:this.props.users[finish.user], progression:progression, update:update, finish: finish, currentStep: this.props.activity.stepsCount})
            }
        })

        students = this.addNotStartedStudents(students);

        students = this.applyFilter(students)
        return students.sort((a, b) => {
            return a.user.username.localeCompare(b.user.username)
        })
    }

    renderActivityProgressionsList(){
        let students = this.getStudentsForCourse();
        students =  students.map(student => {
            return (<CourseSummaryComponent key={student.key}  user={student.user} progression={student.progression} update={student.update} finished={student.finish} course={this.props.activity} currentStep={student.currentStep} consultation={student.notStarted ? "disabled" : true} renderingList showElapsed hideDescription/>)
        })
        if(students.length){
            return (<Card.Group itemsPerRow={3}>{students}</Card.Group>)
        }else{
            return (<Message><p>Il n'y a pas de rendu disponible pour le moment.</p></Message>)
        }
    }

    renderCourseProgressionsList(){
        let students = this.getStudentsForCourse();
        students =  students.map(student => {
            return (<CourseSummaryComponent key={student.key}  user={student.user} progression={student.progression} update={student.update} finished={student.finish} currentStep={student.currentStep} course={this.props.activity}  consultation={student.notStarted ? "disabled" : true} renderingList showElapsed noImage hideDescription/>)
        })
        if(students.length){
            return (<Card.Group itemsPerRow={3}>{students}</Card.Group>)
        }else{
            return (<Message><p>Il n'y a pas de rendu disponible pour le moment.</p></Message>)
        }
    }

    renderContent(){
        if(this.props.activity && this.props.activity.isActivity)
            return this.renderActivityProgressionsList()
        return this.renderCourseProgressionsList()
    }

    renderFilters(){
        return (
        <Accordion fluid styled>
            <Accordion.Title
                index={0}
                onClick={()=>{this.setState(prev => { return {filter: {...prev.filter, open: !prev.filter.open}}})}}
                active={this.state.filter.open}>
                <Icon name="filter"/>
                Filtres
            </Accordion.Title>
            <Accordion.Content active={this.state.filter.open}>
                <Grid columns={2} divided>
                    <Grid.Row>
                        <Grid.Column>
                            <b>Groupes</b>
                            <List>
                                {this.props.groups.array.map(group => {
                                    return <List.Item key={group}><Checkbox onClick={()=>{this.toggle("groups", this.props.groups.entities[group]._id)}} checked={this.state.filter.groups.indexOf(this.props.groups.entities[group]._id) !== -1} label={this.props.groups.entities[group].name}/></List.Item>
                                })}
                            </List>
                        </Grid.Column>
                        <Grid.Column>
                            <b>Statut</b>
                            <List>
                                <List.Item><Checkbox onClick={()=>{this.toggle("notStarted")}} checked={this.state.filter.status.notStarted} label="Non commencé"/></List.Item>
                                <List.Item><Checkbox onClick={()=>{this.toggle("started")}} checked={this.state.filter.status.started} label="Commencé"/></List.Item>
                                <List.Item><Checkbox onClick={()=>{this.toggle("ended")}} checked={this.state.filter.status.ended} label="Terminé"/></List.Item>
                            </List>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Accordion.Content>
        </Accordion>
        )
    }

    render() {
        return(
            <Container>   
				<div style={{display: "inline-flex", flexDirection:"row", alignItems:"center", flexBasis: "auto", marginBottom: "20px"}}>
					<Button as={Link} to='/structure' basic  icon="arrow left" style={{marginRight:'10px'}}/>
					<Header className='header_title' style={{margin:"10px 0 10px 0"}} size="large">{this.props.activity && this.props.activity.title}</Header>
				</div>
                {this.renderFilters()}
                <Divider hidden/>
                {this.renderContent()}            
            </Container>
        )
    }
}

export { ActivityRenderingListComponent } 