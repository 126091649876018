import React, {Component} from 'react';
import {withRouter, Link} from "react-router-dom";
import PropTypes from 'prop-types';
import {
    Button, 
    Form, 
    Grid, 
    Header, 
    Image, 
    Message, 
    Segment, 
    Radio,
    Tab,
    Card,
    Responsive,
    Ref,
    Divider
} from 'semantic-ui-react'

import AuthenticatedForm from './forms/AuthenticatedForm'
import InstructorForm from './forms/InstructorForm'
import miss from '../../img/miss.svg'

import './RegisterComponent.css';
class RegisterComponent extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            user: {
                email: '',
                password: '',
                username: '',
                birthday: new Date().toISOString(),
                city: '',
            },
            structureOwned: {
                name: '',
                type: '',
            },
        };
        this.formRef = React.createRef();
        this.onSubmit = this.onSubmit.bind(this)
        this.onUserChange = this.onUserChange.bind(this)
        this.onStructureChange = this.onStructureChange.bind(this)
        this.redirectToCourses = this.redirectToCourses.bind(this)
    }

    componentDidMount(){
        this.setState({activeTab: (this.props.activeTab || null)})
    }

    componentWillUnmount(){
        const {registerResetState} = this.props.actions;
        registerResetState();
    }

    componentDidUpdate(prevProps, prevState){
        if (prevState.activeTab !== this.state.activeTab && this.formRef.current) {
            this.formRef.current.scrollIntoView({
                block:'end'
            });
        }
    }

    onSubmit(history, userType) {
        const datas = {...this.state, userType};
        const {register} = this.props.actions;
        register(datas, history);
    }

    onUserChange(e, {value, name}) {
        const {user} = this.state;
        user[name] = value;
        this.setState({user});
    }

    onStructureChange(e, {name, value}) {
        const {structureOwned} = this.state;
        structureOwned[name] = value;
        this.setState({structureOwned});
    }

    redirectToCourses() {
        this.props.history.push('/courses')
    }

    redirectToLogin = () =>{
        this.props.history.push('/login')
    }
    redirectToForgotPassword = () =>{
        this.props.history.push('/forgotPassword')
    }

    redirectFreeCreation = ()=>{
        this.props.history.push('/creation')
    }

    onCardClick = (e, data) => {
        e.preventDefault();
        this.setState({activeTab: data.href})
    }

    renderWelcome(showExplainationText){
        return (
            <div style={{display: "flex", justifyContent: 'center', maxWidth:"450px", margin:"auto",  marginBottom:"5px", marginTop:"5px"}}>
                <div style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
                    <Image
                        style={{width: '4.5em'}}
                        size={'large'}
                        src={miss}/>
                </div>
                
                <div style={{display: "flex", justifyContent:"center", alignItems:"center", width:"60%"}}>
                    <div>
                        <Header as='h2' color='orange' textAlign='center' style={{marginBottom:"5px"}}>
                            Bienvenue
                        </Header>
                        {showExplainationText && 
                            <p style={{textAlign: "center"}}>
                                Créez votre compte pour découvrir des parcours, des défis et enregister vos programmes et progressions
                            </p>
                        }
                    </div>
                </div>
            </div>
        )
    }

    renderCards(side, columns){
        if(this.state.activeTab && !side)
            return null;
        let cards=(
            <React.Fragment>
                <Card
                    href='authenticated'
                    onClick={this.onCardClick}
                    color={!side || this.state.activeTab === "authenticated" ? "orange" : null}
                >
                    <Card.Content>
                        <Card.Header style={{color: side && this.state.activeTab !== "authenticated" ? "grey" : null}}>Compte personnel</Card.Header>
                        <Card.Description>
                        {!side?"J'apprends la programmation avec mon propre compte.":null}
                        </Card.Description>
                    </Card.Content>
                </Card>
                <Card
                    href='instructor'
                    onClick={this.onCardClick}
                    color={!side || this.state.activeTab === "instructor" ? "teal" : null}
                >
                    <Card.Content>
                        <Card.Header style={{color: side && this.state.activeTab !== "instructor" ? "grey" : null}}>Compte enseignant</Card.Header>
                        <Card.Description>
                            {!side ? "J'associe mes élèves, j'organise les activités et je suis les progressions." : null}
                        </Card.Description>
                    </Card.Content>
                </Card>
                <Card
                    href='guest'
                    onClick={this.onCardClick}
                    color={!side || this.state.activeTab === "guest" ? "blue" : null}
                >
                     <Card.Content>
                        <Card.Header style={{color: side && this.state.activeTab !== "guest" ? "grey" : null}}>Compte élève</Card.Header>
                        <Card.Description>
                            {!side?"Je rejoins la classe de mon enseignant.e.":null}
                        </Card.Description>
                    </Card.Content>
                </Card>
            </React.Fragment>
        )
        return (
            <React.Fragment>
                <Responsive maxWidth={880}>
                    <Card.Group stackable style={{alignItems:side?"center":null, justifyContent:"center", marginTop:"15px", flexDirection: columns?"column":"row"}}>
                        {cards}
                    </Card.Group>
                </Responsive>
                <Responsive minWidth={881}>
                    <Card.Group stackable style={{alignItems:side?"center":null, justifyContent:"center", marginTop:"15px",  flexDirection: columns?"column":"row", flexWrap:"nowrap"}}>
                        {cards}
                    </Card.Group>
                </Responsive>
            </React.Fragment>
            
        )
    }

    renderOtherOptions(){
        return (
            <Message compact>
                <p>
                    <a style={{cursor: "pointer"}} onClick={this.redirectToLogin}>J'ai déjà un compte</a>
                </p>
                <p>
                <a style={{cursor: "pointer"}} onClick={this.redirectFreeCreation}>Je veux tester un programme sans me connecter</a>
                </p>
            </Message>
        )
    }

    renderForm(){
        if(!this.state.activeTab)
            return null
        return (
            <Grid style={{alignItems:"center", padding:"10px"}} stackable>
                <Grid.Row style={{alignItems:"center", justifyContent:"space-evenly"}}>
                    <Grid.Column style={{flex:1}}>
                        {this.renderWelcome(true)}
                        {this.renderCards(true, true)}
                        {this.renderOtherOptions()}
                        
                    </Grid.Column>
                    <Ref innerRef={this.formRef}>
                        <Grid.Column style={{flex:2, minWidth:"60%"}} >
                            
                            {this.state.activeTab === "authenticated" && 
                                <AuthenticatedForm onSubmit={this.onSubmit} onUserChange={this.onUserChange} error={this.props.error} isLoading={this.props.isLoading} history={this.props.history}/>

                            }
                            {this.state.activeTab === "instructor" &&
                                <InstructorForm onSubmit={this.onSubmit} onUserChange={this.onUserChange} onStructureChange={this.onStructureChange} error={this.props.error} isLoading={this.props.isLoading} history={this.props.history}/>
                            }
                            {this.state.activeTab === "guest" &&
                                <React.Fragment>
                                <Segment style={{display:"block", textAlign:"initial"}}>
                                    <Header color="orange" style={{textAlign:"center"}}>
                                    C'est ta première visite
                                    </Header>
                                    <p>
                                    C'est ta première visite et ton enseignant.e t'a donné un lien d'invitation qui ressemble à : 

                                    </p>
                                    <p style={{textAlign:"center"}}>
                                        <b>https://play.thingz.co/g/XYZ0</b>
                                    </p>
                                        Il faut que tu le copies dans la barre d'adresse de ton navigateur pour choisir un mot de passe et rejoindre la classe
                                    <p>

                                    </p>
                                </Segment>
                                <Divider horizontal>Ou</Divider>
                                <Segment style={{display:"block", textAlign:"initial"}}>
                                    <Header color="orange" style={{textAlign:"center"}}>
                                        Tu as déjà un identifiant
                                    </Header>
                                    <p>
                                        Tu as déjà choisis un identifiant et un mot de passe ou alors ton enseignant.e t'a donné un identifiant et un mot de passe déjà prêt.
                                    </p>
                                    <p>
                                        Il faut te rendre sur la page suivante avec tes identifiants pour te connecter :
                                    </p>
                                    <p style={{textAlign:"center"}}>
                                        <Link to="/login">https://play.thingz.co/login</Link>
                                    </p>
                                </Segment>
                                </React.Fragment>
                        }
                        </Grid.Column>
                    </Ref>
                </Grid.Row>
            </Grid>
        )
    }

    render() {

        return (
            <div style={{flex:1, display:'flex'}}>
                <Grid textAlign='center' style={{flex: "1 1 auto"}} verticalAlign='middle'>
                    <Grid.Column style={{ width:"80%"}}>
                        {!this.state.activeTab && this.renderWelcome(true)}
                        {this.renderCards(false)}
                        {this.renderForm()}
                        {!this.state.activeTab && this.renderOtherOptions()}
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

RegisterComponent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

export default withRouter(RegisterComponent);
