import {appsConstants, modelConstants} from '../../constants'

const initialAppState = {
    isLoading: false,
    error: null,

    apps: [],
    filteredApps: []
}

export const apps = (state = initialAppState, action) => {
    switch (action.type) {
        case appsConstants.GET_APPS_STARTED: {
            return {
                ...state,
                isLoading: true,
                error: null,
            }
        }
        case modelConstants.FETCH_ENTITY_DONE: {
            if(action.entities.entities && action.entities.entities.apps){
                return {
                    ...state,
                    apps: action.entities.entities.apps
                }
            }
            return state;
        }
        case appsConstants.GET_APPS_SUCCEEDED: {
            return {
                ...state,
                isLoading: false,
                error: null,

                // apps: action.payload.apps
                apps: action.payload,
                filteredApps: action.payload
            }
        }
        case appsConstants.GET_APPS_FAILED: {
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        }
        case appsConstants.FILTER_APPS: {
            let filteredApps = state.apps.filter((app => {return app.nom.toLowerCase().includes(action.input_string.toLowerCase())}))
            return {
                ...state,
                filteredApps: filteredApps,
            }
        }
        case appsConstants.RESET_APPS: {
            return {
                ...state,
                filteredApps: state.apps,
            }
        }
        case modelConstants.INVALIDATE_ENTITIES: {
            return initialAppState
        }
        case appsConstants.DELETE_APP_STARTED: {
            return {
                ...state,
                error: null
            };
        }
        case appsConstants.DELETE_APP_SUCCEED: {
            return {
                ...state,
                apps: action.apps,
                filteredApps: action.apps,
                error: null
            };
        }
        case appsConstants.DELETE_APP_FAILED: {
            return {
                ...state,
                error: action.payload
            };
        }
        default: {
            return state
        }
    }
}