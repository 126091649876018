import { sessionConstants } from '../constants';

const initialState = {
    datas: null,
    isFetching: false,
    error: null,
    password: null
}

export function guestAccount(state = initialState, action) {
  switch (action.type) {
    case sessionConstants.REGISTER_GUEST_STARTED:
      return {
        ...state,
        isFetching: true
      }
    case sessionConstants.REGISTER_GUEST_SUCCEDED:
      return {
        ...state,
        isFetching: false,
        error: null,
        password: action.payload.data.password
      }
    case sessionConstants.REGISTER_GUEST_FAILED:
      return {
        ...state,
        isFetching: false,
        // error: JSON.stringify(action.error)
        error: action.error
      }
    case sessionConstants.REGISTER_RESET_STATE:
      return {
        ...state,
        isFetching: false,
        error: null
      }
    case sessionConstants.LINK_USER_TO_STRUCTURE_STARTED:
      return {
        ...state,
        isFetching: true
      }
    case sessionConstants.LINK_USER_TO_STRUCTURE_SUCCEEDED:
      return {
        ...state,
        isFetching: false,
        error: null
      }
    case sessionConstants.LINK_USER_TO_STRUCTURE_FAILED:
      return {
        ...state,
        isFetching: false,
        // error: JSON.stringify(action.payload)
        error: action.error
      }
    case sessionConstants.UNLINK_USER_TO_STRUCTURE_STARTED:
      return {
        ...state,
        isFetching: true
      }
    case sessionConstants.UNLINK_USER_TO_STRUCTURE_SUCCEEDED:
      return {
        ...state,
        isFetching: false,
        error: null
      }
    case sessionConstants.UNLINK_USER_TO_STRUCTURE_FAILED:
      return {
        ...state,
        isFetching: false,
        // error: JSON.stringify(action.payload)
        error: action.error
      }
    case sessionConstants.SHOW_GUEST_PASS_DONE:
      return {
        ...state,
        password: null
      }
    default:
      return state;
  }
}