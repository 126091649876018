import axios from 'axios';
import {sessionService} from 'redux-react-session'
import runtimeEnv from '@mars/heroku-js-runtime-env';
import history from '../history'

const API_BASE_URL = runtimeEnv().REACT_APP_API_BASE_URL;

const client = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: ''
    }
});

client.interceptors.request.use(async function (config) {
    try{
        const jwt = await sessionService.loadUser();
        if(jwt)
            config.headers.Authorization = 'Bearer ' + jwt.jwt;
        return config
    }catch(e){
        return config
    }
}, function (error) {
    console.log(error)
});

client.interceptors.response.use(null, function(error){
    console.log(error)
    if(error.response && error.response.data.statusCode === 403 && error.response.data.message && error.response.data.message.logout)
        history.push("/logout");
    else
        return Promise.reject(error);
})

export const courses = {
	fetchAll,
	fetch,
    fetchUnlock,
    fetchLock,
    fetchForUser,
    fetchActivity,
    create,
    update,
    destroy,
    getShareId,
    clone,
    fetchByShareId,
    fetchInStore,
}

function fetchAll() {
	return client.get('/course')
}

function fetchForUser() {
    return client.get('/course/forUser')
}

function fetch(id) {
	return client.get(`/course/${id}`);
}

function fetchLock(){
    return client.get(`/course/lock`);
}

function fetchUnlock(){
    return client.get(`/course/unlock`);
}

function fetchActivity(id) {
    return client.get(`/course/activity/${id}`);
}

function create(course) {
    return client.post('/course', course);
}

function update(id, course) {
    return client.put(`/course/${id}`, course);
}

function destroy(id) {
    return client.delete(`/course/${id}`);
}

function getShareId(id) {
    return client.get(`/course/shareId/${id}`);
}

function clone(shareId){
    return client.post(`/course/clone/${shareId}`);
}

function fetchByShareId(shareId) {
	return client.get('/course/read/'+shareId)
}

function fetchInStore(from, limit) {
    return client.get(`/course/inStore?from=${from}&limit=${limit}`)
}