import React from 'react';
import {Grid, Card, Image, Icon} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import {ProfilePicture} from '../ProfilePicture';

import profil from '../../img/miss.svg'

const Progression = props => {
    function renderProfile(){
        return <ProfilePicture email={props.user.email}/>
    }
    function capitalize(str){
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return (
        <Card>
            <Card.Content>
                {props.user && renderProfile()}
                {props.user && <Card.Header>{props.user.username}</Card.Header>}
                {props.user && props.user.role && props.user.role.type !== "guest" && <Card.Meta>{props.user.email}</Card.Meta>}
                {props.user && props.structureOwned && <Card.Meta>Enseignant de {capitalize(props.structureOwned.name)}</Card.Meta>}
                {props.user && props.structure && <Card.Meta>Élève de {capitalize(props.structure.name)}</Card.Meta>}
                {props.user && props.group && <Card.Meta>Groupe {capitalize(props.group.name)}</Card.Meta>}
            </Card.Content>
            
        </Card>
    );
};

Progression.propTypes = {};

export default Progression;