import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import UserStatus from '../../containers/UserStatus'
import {ConnectionStatus} from '../../containers/ConnectionStatus';
// import Logo from '../../components/LogoComponent/LogoComponent'
import './Header.css'

import {
    Grid,
    Progress,
    Menu,
    Image,
    Container,
    Header
} from 'semantic-ui-react';

const HeaderComponent = props => {
    return (
        <Menu fluid borderless id="HeaderFreeCreationMenu">
            <Container fluid>
                <Menu.Item className='logo'>
                    <Link to='/'>
                        <Image size='small' spaced='left' src='../../img/thingz_logo.png'/>
                    </Link>
                </Menu.Item>

                    <Fragment>
                        <Grid.Column className='innerColumns' style={{width: '100%'}}>
                            <Header textAlign='center'>{props.title}</Header>
                        </Grid.Column>

                        {props.course && !props.course.isActivity &&
                        <Grid.Column className='innerColumns' style={{width: '100%'}}>
                            <p className='ProgressParagraph'>Progression</p>
                            <Progress className='ProgressBar' percent={(props.step.currentPositionInCourse / props.course.stepsCount) * 100} color='blue' />
                        </Grid.Column>}
                    </Fragment>
                    
                    <Menu.Item position="right">
                      <ConnectionStatus/>
                    </Menu.Item>
                    <Menu.Item position="right">
                        <UserStatus hideIfGuest={props.hideUserStatusIfGuest}/>
                    </Menu.Item>
        
            </Container>
        </Menu>
    );
};

/*
* renderCurrentTask() {
        if (this.props.avancement.ended) {
            return (<p>Job is done !!</p>)
        } else {
            return (<p>Current task {this.props.avancement.step + 1}/{this.props.course.steps.length}</p>)
        }
    }
*
*
* */

export default HeaderComponent;
