import ledPicto  from './img/picto_led.png'
import buttonPicto  from './img/picto_bouton.png'
import weatherPicto from './img/picto_meteo.png'
import luminosityPicto from './img/picto_lumino.png'
import potentiometerPicto from './img/picto_potard.png'
import touchPicto from './img/picto_tactile.png'
import digitalDisplayPicto from './img/picto_afficheur.png'
import motionPicto from './img/picto_mouvement.png'
import rf433Picto from './img/picto_radio.png'
import buzzerPicto from './img/picto_buzz.png'
import infraredPicto from './img/picto_infrarouge.png'
import monitorPicto from './img/picto_monitor.png'
import screenPicto from './img/picto_ecran.png'

export default {
    led:led,
    button:button,
    weather:weather,
    luminosity: luminosity,
    potentiometer:potentiometer,
    touch:touch,
    motion: motion,
    digitalDisplay:digitalDisplay,
    rf433: rf433,
    buzzer:buzzer,
    infrared:infrared,
    screen:screen,
    coloredScreen:coloredScreen,
    monitor:monitor
}

function led(id){
    return {
        id: id,
        brick: "led",
        name: "Led",
        type: "actuator",
        outputs: [
            {
                type:"text",
                value:"Éteinte",
                bold: true
            }
        ],
        img: ledPicto
    }
}

function button(id, onChange, skulptOnChange){
    return {
        id:id,
        brick:"button",
        name:"Bouton",
        type:"sensor",
        img: buttonPicto,
        inputs:[
            {
                type:"check",
                text: "Appui verrouillé",
                onCheck:onChange, 
                value:false
            }, 
            {
                type:"button", 
                text:"Appui", 
                onUp:onChange, 
                onDown:onChange
            }
        ],
        skulptCbOnChange: skulptOnChange
    }
}

function weather(id, onChange, skulptCbTempChange, skulptCbHumidityChange){
    return{
        id:id,
        brick: "weather",
        name:"Météo",
        type:"sensor",
        img: weatherPicto,
        inputs:[
            {
                type:"range",
                text: "Température",
                min:0, 
                max:50,
                value:25, 
                onChange:onChange
            }, 
            {
                type:"range",
                text: "Humidité",
                min:0,
                max:100,
                value:50,
                onChange:onChange
            }
        ],
        skulptCbTempChange: skulptCbTempChange,
        skulptCbHumidityChange: skulptCbHumidityChange
    }
}

function luminosity(id, onChange, skulptCbLuminosityChange){
    return {
        id:id,
        brick: "luminosity",
        name:"Luminosité",
        type:"sensor",
        img: luminosityPicto,
        inputs:[
            {
                type:"range",
                text: "Valeur",
                min:0, 
                max:100,
                value:50, 
                onChange:onChange
            }
        ],
        skulptCbLuminosityChange: skulptCbLuminosityChange
    }
}

function potentiometer(id, onChange, skulptCbPositionChange){
    return {
        id:id,
        brick: "potentiometer",
        name:"Potentiomètre",
        type:"sensor",
        img: potentiometerPicto,
        inputs:[
            {
                type:"range",
                text: "Valeur",
                min:0, 
                max:100,
                value:50, 
                onChange:onChange
            }
        ],
        skulptCbPositionChange: skulptCbPositionChange,
    }
}

function touch(id, onChange, skulptCbOnChange){
    return {
        id:id,
        brick:"touch",
        name:"Tactile",
        type:"sensor",
        img: touchPicto,
        inputs:[
            {
                type:"check",
                text: "Toucher verrouillé",
                onCheck:onChange, 
                value:false
            }, 
            {
                type:"button", 
                text:"Toucher", 
                onUp:onChange, 
                onDown:onChange
            }
        ],
        skulptCbOnChange: skulptCbOnChange
    }
}

function motion(id, onChange, skulptCbOnChange){
   return  {
        id:id,
        brick:"motion",
        name:"Mouvement",
        type:"sensor",
        img: motionPicto,
        inputs:[
            {
                type:"check",
                text: "Mouvement verrouillé",
                onCheck:onChange, 
                value:false
            }, 
            {
                type:"button", 
                text:"Bouger", 
                onUp:onChange, 
                onDown:onChange
            }
        ],
        skulptCbOnChange: skulptCbOnChange
    }
}

function digitalDisplay(id){
    return {
        id: id,
        brick: "digitalDisplay",
        name: "Afficheur de chiffre",
        type: "actuator",
        outputs: [
            {
                type:"text",
                value:"Éteint",
                bold: true
            }
        ],
        img: digitalDisplayPicto
    }
}

function rf433(id){
    return {
        id: id,
        brick: "rf433",
        name: "Prise radiocommandée",
        type: "actuator",
        outputs: [
            {
                type:"text",
                value:"Éteinte",
                bold: true
            }
        ],
        img: rf433Picto
    }
}

function buzzer(id){
    let b = {
        id: id,
        brick: "buzzer",
        name: "Buzzer",
        type: "actuator",
        outputs:[
            {
                type:"text",
                value:"Ne sonne pas",
                bold: true
            }
        ],
        img: buzzerPicto
    }
    b.audioContext = new (window.AudioContext || window.webkitAudioContext)()
    b.oscillator = null
    b.onUnmount = ()=>{}
    return b
}

function infrared(id, onChange, skulptOnSignal){
    return {
        id:id,
        brick:"infrared",
        name:"Infrarouge",
        type:"sensor",
        img: infraredPicto,
        intervalResend: null,
        inputs:[
            {
                type:"number",
                text: "Signal",
                onChange:onChange, 
                min:"1",
                max:"0xffffffff",
                value:1
            }, 
            {
                type:"button", 
                text:"Générer le signal", 
                onUp:onChange, 
                onDown:onChange
            }
        ],
        skulptCbOnSignal: skulptOnSignal
    }
}

function screen(id){
    return {
        id:id,
        brick:"screen",
        name:"Écran",
        type:"actuator",
        img: screenPicto,
        outputs:[
            {
                type: "text",
                value: "",
                size: "1.4em",
                onContentTag:true,
                forceContentIfEmpty:true,
                whiteSpace: "pre"
            },
            {
                type: "text",
                value: "",
                size: "1.4em",
                onContentTag:true,
                forceContentIfEmpty:true,
                whiteSpace: "pre"
            },
            {
                type: "text",
                value: "",
                size: "1.4em",
                onContentTag:true,
                forceContentIfEmpty:true,
                whiteSpace: "pre"
            },
            {
                type: "text",
                value: "",
                size: "1.4em",
                onContentTag:true,
                forceContentIfEmpty:true,
                whiteSpace: "pre"
            },
            {
                type: "text",
                value: "",
                size: "1.4em",
                onContentTag:true,
                forceContentIfEmpty:true,
                whiteSpace: "pre"
            },
            {
                type: "text",
                value: "",
                size: "1.4em",
                onContentTag:true,
                forceContentIfEmpty:true,
                whiteSpace: "pre"
            }
        ]
    }
}

function coloredScreen(id){
    return {
        id:id,
        brick:"coloredScreen",
        name:"Écran couleur",
        type:"actuator",
        img: screenPicto,
        outputs:[
            {
                type: "text",
                value: "",
                size: "0.8em",
                color:"red",
            },
            {
                type: "monitor",
                value: "",
                display:"block",
                wordBreak:"break-word"
            }
        ]
    }
}

function monitor(){
    return {
        id:"monitor",
        name:"Moniteur série",
        type:"actuator",
        img: monitorPicto,
        outputs:[
            {
                type: "monitor",
                value: "",
                textColor: "green"
            }
        ]
    }
}