import { cond } from "lodash";
import { flashes } from "../../api";

class Stk500v2{

    constructor(port, operations, board){
        this.port = port;
        this.operations = operations
        this.released = false;
        this.board = board;
        this.sequenceNumber = 0;

        this.packetParser  = {
            raw: null,
            start: null,
            seq: null,
            length: null,
            e:null,
            data:null,
            checksum:null,
            allReceived:null,
            currentStep: null,
            removeOnChecksum: null
        }

        // this.textEncoder = new TextEncoder()

    }

    timeout(ms){
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    async read(timeout_ms){
        let data;
        let timeout = setTimeout(()=>{
            let reader = this.operations.getReader()
            try{
                reader.cancel()
            }catch(e){
                console.log(e)
            }
        }, timeout_ms)
        let reader = this.operations.getReader();
        data = await reader.read()
        clearTimeout(timeout)
        return data
    }

    async retry(func, number, delay, ...args){
        let exc;
        for(let i = 0; i < number; i++){
            if(this.released)
                throw "RELEASED";
            try{
                let status = await func(...args)
            }catch(e){
                console.log(e)
                exc = e
                await this.timeout(delay);
                continue;
            }
            return i+1;
        }
        throw exc;
    }

    async step(func, ...args){
        // console.log(func, args)
        let result = await func(...args)
        return result
    }

    async waitBoardStatusChanged(state){
        let r =  await new Promise(resolve => {
            // console.log(this.operations.search())
            return resolve(this.operations.search() === state)
        })
        if(!r){
            throw {value: "NO_REBOOT"}
        }
        return r
    }

    incrementSequenceNumber(){
        this.sequenceNumber += 1
        if(this.sequenceNumber == 256){
            this.sequenceNumber = 0;
        }
    }

    parsePacket(data){
        if(data){
            let r = new Uint8Array(this.packetParser.raw ? this.packetParser.raw.length + data.length: data.length);
            if(this.packetParser.raw){
                r.set(this.packetParser.raw)
            }
            r.set(data, this.packetParser.raw ? this.packetParser.raw.length : 0)
            this.packetParser.raw = r
        }

        switch(this.packetParser.currentStep){
            case "start":
                if(!this.packetParser.raw || this.packetParser.raw.length == 0)
                    return false;
                if(this.packetParser.raw[0] === 0x1b){
                    this.packetParser.currentStep = 'seq'
                    return this.parsePacket()
                }else{
                    console.log("PACKET PARSER: receive "+this.packetParser.raw[0]+" instead of 0x1b")
                    this.packetParser.raw = this.packetParser.raw.slice(1, this.packetParser.raw.length);
                    return this.parsePacket()
                }
            case "seq":
                if(this.packetParser.raw.length < 2){
                    return false;
                }
                this.packetParser.seq = this.packetParser.raw[1]
                if(this.packetParser.seq !== this.sequenceNumber){
                    console.log("PACKET PARSER: received sequence number "+this.packetParser.seq+" instead of "+this.sequenceNumber+". Packet will be ignored")
                    this.packetParser.removeOnChecksum = true
                }
                this.packetParser.currentStep = 'length'
                return this.parsePacket()
            case "length":
                if(this.packetParser.raw.length < 4){
                    return false
                }
                this.packetParser.length = this.packetParser.raw[2] << 8 || this.packetParser.raw[3]
                this.packetParser.currentStep = 'e'
                return this.parsePacket()
            case "e":
                if(this.packetParser.raw.length < 5){
                    return false
                }
                this.packetParser.currentStep = 'data'
                return this.parsePacket()
            case "data":
                if(this.packetParser.raw.length < 5+this.packetParser.length){
                    return false;
                }
                this.packetParser.data = this.packetParser.raw.subarray(5, 5+this.packetParser.length)
                this.packetParser.currentStep = 'checksum'
                return this.parsePacket()
            case "checksum":
                if(this.packetParser.raw.length < 6+this.packetParser.length){
                    return false;
                }
                this.incrementSequenceNumber()
                this.packetParser.checksum = this.packetParser.raw[5+this.packetParser.length]
                let checksum = this.packetParser.raw[0];
                for (let i = 1; i < 5+this.packetParser.length; i++) {
                    checksum = checksum ^ this.packetParser.raw[i];
                }
                if(checksum != this.packetParser.checksum){
                    console.log("PACKET PARSER: wrong checksum", checksum, this.packetParser.checksum)
                    this.packetParser.removeOnChecksum = true
                }
                if(this.packetParser.removeOnChecksum){
                    this.resetPacketParser()
                    return false;
                }
                return true
        }
    }
    resetPacketParser(){
        this.packetParser  = {
            raw: null,
            start: null,
            seq: null,
            length: null,
            e:null,
            data:null,
            checksum:null,
            allReceived:null,
            currentStep: "start",
            removeOnChecksum: null
        }
    }

    sendPacket(data){
        let buf = new Uint8Array(data.length + 6);
        buf[0] = 0x1b
        buf[1] = this.sequenceNumber
        buf[2] = data.length >> 8
        buf[3] = data.length & 0xff
        buf[4] = 0x0e
        for (let i = 0; i < data.length; i++) {
            buf[i + 5] = data[i];
        }
        let checksum = buf[0];
        for (let i = 1; i < buf.byteLength - 1; i++) {
            checksum = checksum ^ buf[i];
        }
        buf[buf.byteLength - 1] = checksum;
        
        let writer = this.operations.getWriter();
    
        this.resetPacketParser()
        console.log("SENDING ", buf.length, " bytes")
        return writer.write(buf)
    }

    async cmdSignOn(){
        let data = [0x01];
        
        await this.sendPacket(data);
        let end = false
        while(!end){
            data = await this.read(1000);
            if(data.done){
                this.operations.setReader(null)
                throw "CMD_SIGN_ON_FAILED"
            }
            if(data.value){
                let result = this.parsePacket(data.value)
                console.log(result, this.packetParser)
                if(result){
                    end = true
                    if(this.packetParser.data[0] == 0x1){
                        return true
                    }
                    throw "CMD_SIGN_ON_FAILED"
                }
            }else{
                throw "CMD_SIGN_ON_FAILED"
            }
        }        

    }

    async cmdEraseChip(){
        let data = [0x12, 0x9, 0x0, 0xAC, 0x80, 0x55, 0x00];
        
        await this.sendPacket(data);
        let end = false
        while(!end){
            data = await this.read(1000);
            if(data.done){
                this.operations.setReader(null)
                throw "CMD_ERASE_CHIP_FAILED"
            }
            if(data.value){
                let result = this.parsePacket(data.value)
                console.log(result, this.packetParser)
                if(result){
                    end = true
                    if(this.packetParser.data[0] == 0x12){
                        return true
                    }
                    throw "CMD_ERASE_CHIP_FAILED"
                }
            }else{
                throw "CMD_ERASE_CHIP_FAILED"
            }
        }        

    }

    async cmdEnterProgModeISP(){
        let data = [0x10, 0xc8, 0x64, 0x19, 0x20, 0x00, 0x53, 0x03, 0xac, 0x53, 0x00, 0x00];
        
        await this.sendPacket(data);
        let end = false
        while(!end){
            data = await this.read(1000);
            if(data.done){
                this.operations.setReader(null)
                throw "CMD_ENTER_PROGMODE_ISP"
            }
            if(data.value){
                let result = this.parsePacket(data.value)
                console.log(result, this.packetParser)
                if(result){
                    end = true
                    if(this.packetParser.data[0] == 0x10){
                        return true
                    }
                    throw "CMD_ENTER_PROGMODE_ISP"
                }
            }else{
                throw "CMD_ENTER_PROGMODE_ISP"
            }
        }        

    }

    async cmdLoadAddress(addr){
        console.log("LOAD ADDR: ", addr)
        // let a = this.board == "mini" ? Math.trunc(addr/256)+0x20 : Math.trunc(addr/256)
        // let data = [0x06, 0x80, Math.trunc(a/256), a % 256, (addr/128)%2 == 0 ? 0 : 0x80];
        let data = [0x06, (addr >> 24) & 0xFF, (addr >> 16) & 0xFF, (addr >> 8) & 0xFF, addr & 0xFF];
        console.log("LOAD ", data)
        await this.sendPacket(data);
        let end = false
        while(!end){
            data = await this.read(1000);
            if(data.done){
                this.operations.setReader(null)
                throw "CMD_LOAD_ADDR"
            }
            if(data.value){
                let result = this.parsePacket(data.value)
                console.log(result, this.packetParser)
                if(result){
                    end = true
                    if(this.packetParser.data[0] == 0x6){
                        return true
                    }
                    throw "CMD_LOAD_ADDR"
                }
            }else{
                throw "CMD_LOAD_ADDR"
            }
        }        

    }

    async cmdData(from, chunkLength){
        console.log("DATA: ", from)
        let data = [0x13, 0x01, 0x00, 0xc1, 0x0a, 0x40, 0x4c, 0x20, 0x00, 0x00];
        
        for(let i = 0; i < chunkLength; i++){
            if(from+i < this.bin.length)
                data[data.length] = this.bin[from+i]
            else{
                data[data.length] = 0xFF
            }
        }
        await this.sendPacket(data);
        let end = false
        while(!end){
            data = await this.read(1000);
            if(data.done){
                this.operations.setReader(null)
                throw "CMD_DATA"
            }
            if(data.value){
                let result = this.parsePacket(data.value)
                console.log(result, this.packetParser)
                if(result){
                    end = true
                    if(this.packetParser.data[0] == 0x13){
                        return true
                    }
                    throw "CMD_DATA"
                }
            }else{
                throw "CMD_DATA"
            }
        }        

    }

    async sendData(addr, binOffset, chunkLength){
        await this.cmdLoadAddress(addr)
        await this.cmdData(binOffset, chunkLength)
        return true;
    }

    async cmdLeaveProgModeISP(){
        let data = [0x11, 0x01, 0x01];
        
        await this.sendPacket(data);
        let end = false
        while(!end){
            data = await this.read(1000);
            if(data.done){
                this.operations.setReader(null)
                throw "CMD_LEAVE_PROGMODE_ISP"
            }
            if(data.value){
                let result = this.parsePacket(data.value)
                console.log(result, this.packetParser)
                if(result){
                    end = true
                    if(this.packetParser.data[0] == 0x11){
                        return true
                    }
                    throw "CMD_LEAVE_PROGMODE_ISP"
                }
            }else{
                throw "CMD_LEAVE_PROGMODE_ISP"
            }
        }        
    }
    

    isBootloader(){
        let ids = this.port.getInfo();
        return ids.usbVendorId === 10755 && ids.usbProductId === 77
    }

    async switchToBootloader(delay=true){
        console.log("Switching to bootloader")
        await this.operations.disconnect()
        if(this.board == "mini"){
            await this.operations.connect(false, false, 1200)
            await this.operations.disconnect()
            console.log(delay)
            if(delay)
                await this.timeout(2000)
            try{
                this.port = await this.operations.connect(false, false, 921600)
            }catch(e){
                if(e.toString() === "NO_CARD"){
                    await this.operations.askNewPermision(["mini_bootloader"])
                    try{
                        this.port = await this.operations.connect(false, false)
                    }catch(e){
                        throw "NO_REBOOT"
                    }
                }else{
                    throw e.toString()
                }
            }
        }else{
            this.port = await this.operations.connect(false, false, 115200)
            await this.timeout(250)

        }
    }

    async compile(code, url, lang){
        let fullUrl = url + (!lang || lang === "cpp" ? "" : "/python") + (this.board == "mini" ? "/mini" : "");
        let result = await fetch(fullUrl, {method: "POST", body: code});
        let text = await result.text()
        if(result.status != 200){
            throw {value: "COMPILATION_ERROR", text}
        }
        return text
    }

    parseBin(bin){
        this.bin = []
        // console.log(bin)
        for(let i = 0; i < bin.length; i++){
            // console.log(i, bin[i])
            if(bin[i] === ':'){
                let length =  parseInt(bin[i + 1] + bin[i + 2], 16);
                // console.log(length)
                if(bin[i+8] === '0'){
                    let sum = 0
                    for(let j = 0; j < 2*length+8; j+=2){
                        let byte = parseInt(bin[i+j+1] + bin[i+j+2], 16);
                        if(j > 7){
                            this.bin[this.bin.length] = byte
                        }
                        sum = (sum + byte) & 0xFF
                    }
                    // console.log(this.bin)
                    sum = ~sum + 1 
                    if(parseInt(bin[i+9+2*length] + bin[i+9+2*length+1], 16) !== (sum & 0xFF)){
                        console.log(bin[i+9+2*length] + bin[i+9+2*length+1], parseInt(bin[i+9+2*length] + bin[i+9+2*length+1], 16), sum & 0xFF)
                        throw "PARSE_BIN_CHECKSUM_FAILED"
                    }
                    i+=9+length*2
                }
            }
        }

        // let s = ""
        // for(let i = 0;  i < this.bin.length; i+=16){
        //     for(let j = 0; j < 16; j++){
        //         let n = this.bin[i+j].toString(16)
        //         if(n.length == 1){
        //             n = '0'+n
        //         }
        //         n = n.toUpperCase()
        //         s+=n
        //     }
        //     s+='\n'
        // }
        // console.log(s)

    }

    async flash(code, url, lang){
        let bin = await this.compile(code, url, lang)
        let baseAddr = 0x80000000;
        if(this.board == "mini"){
            baseAddr = 0x80002000
        }
        this.parseBin(bin)
        if(this.board == "mini" && !this.isBootloader() || this.board == "mega"){
            await this.switchToBootloader(this.board == "mini")
        }
        await this.step(this.retry.bind(this), this.cmdSignOn.bind(this), 5, 250)
        await this.step(this.retry.bind(this), this.cmdEraseChip.bind(this), 5, 250)
        await this.step(this.retry.bind(this), this.cmdEnterProgModeISP.bind(this), 5, 250)

        for(let i = 0; i < this.bin.length; i+=256){
            await this.step(this.retry.bind(this), this.sendData.bind(this), 5, 250, baseAddr + i/2, i, 256)
        }

        await this.step(this.retry.bind(this), this.cmdLeaveProgModeISP.bind(this), 5, 250)
        await this.operations.disconnect()
        if(this.board == "mini"){
            console.log("PLOP")
            try{
                await this.step(this.retry.bind(this), this.waitBoardStatusChanged.bind(this), 5, 250, false)
                await this.step(this.retry.bind(this), this.waitBoardStatusChanged.bind(this), 5, 250, true)
            }catch(e){
                console.log(e)
            }
            await this.step(this.retry.bind(this), this.operations.search, 5, 250)
        }
        return true
    }
}

export default Stk500v2