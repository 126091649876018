import {sessionService} from 'redux-react-session';


export const getActualUser = state => {
    if (state.session.user.user) {
        return state.session.user.user
    }
}

export const getUser = async (state) => {
    return await sessionService.loadUser()
}

export const getUserJwt = state => {
    return state.session.user.jwt
}