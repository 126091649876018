import axios from 'axios';
import {sessionService} from 'redux-react-session'
import runtimeEnv from '@mars/heroku-js-runtime-env';
import history from '../history'

const API_BASE_URL = runtimeEnv().REACT_APP_API_BASE_URL;

const client = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: ''
    }
});

client.interceptors.request.use(async function (config) {
    try{
        const jwt = await sessionService.loadUser();
        if(jwt)
            config.headers.Authorization = 'Bearer ' + jwt.jwt;
        return config
    }catch(e){
        return config
    }
}, function (error) {
});

client.interceptors.response.use(null, function(error){
    if(error.response.data.statusCode === 403 && error.response.data.message && error.response.data.message.logout)
        history.push("/logout");
    else
        return Promise.reject(error);
})

export const finishedCourses = {
	fetchAll
}

function fetchAll() {
	return client.get('/finishedCourses')
}