import React, {Component} from 'react'

import {Card, Button, Icon} from 'semantic-ui-react'

import {withRouter, Link} from 'react-router-dom'
import DOMPurify from 'dompurify';



const Comp = (props) => {

    function renderLink(){
        if(props.isOwner){
            return (
                <Link to={'/activityCreator/'+props.course._id}>
                <Button floated="right" animated color="green">
                    <Button.Content visible>
                        Modifier <Icon name={'right chevron'}/>
                    </Button.Content>
                    <Button.Content hidden>
                        <Icon name={'arrow right'}/>
                    </Button.Content>
                </Button>
                </Link>
            )
        }
        return (
            <Link to={'/course/read/'+props.course.share}>
            <Button floated="right" animated color="blue">
                <Button.Content visible>
                    Essayer <Icon name={'right chevron'}/>
                </Button.Content>
                <Button.Content hidden>
                    <Icon name={'arrow right'}/>
                </Button.Content>
            </Button>
            </Link>
        )
    }

	return (
        <Card key={props.course._id}>
            <Card.Content>
                <Card.Header>{props.course.title}</Card.Header>
                <Card.Meta>{props.course.user ? <span><b>{props.course.user.username}</b> - {new Date(props.course.updatedAt).toLocaleDateString()}</span> : null}</Card.Meta>
                <Card.Description style={{ maxHeight:"150px", overflowY:"auto"}}><div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.course.description)}}></div></Card.Description>
            </Card.Content>
            <Card.Content extra>
                {renderLink()}
            </Card.Content>
        </Card>
	)
}

const StoreActivitySummary = withRouter(Comp)

export  {StoreActivitySummary}