import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string'

import { coursesActions, progressionsActions, modelActions, usersActions, stepsActions } from '../../actions/model';
import { modalActions, extensionActions, blocklyActions } from '../../actions';

import { FloatingMessage } from '../../components/FloatingMessage';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';
import HeaderActivityComponent from '../../components/HeaderActivityCreatorComponent/HeaderActivityComponent';
import { ModalCourseConsultationComponent } from '../../components/ModalCourseConsultationComponent/ModalCourseConsultationComponent';
import { BlocklyCourseConsultationComponent } from '../../components/BlocklyCourseConsultationComponent/BlocklyCourseConsultationComponent';

import {Simulator} from '../Simulator'

import { Grid, Container } from 'semantic-ui-react';
import './CourseConsultation.css';

import runtimeEnv from '@mars/heroku-js-runtime-env';

import { modalConstants } from "../../constants";

class CourseConsultation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedStudent: null,
            finishedActivityId: null,
            activityInProgress: null,
            activityValidate: null,
            showBlocklyValidate: false,
        }

        this.showBlocklyValidate = this.showBlocklyValidate.bind(this);
        this.showInProgress = this.showInProgress.bind(this);
        this.onCompileClick = this.onCompileClick.bind(this);
        this.onMainWorkspaceStatusChanged = this.onMainWorkspaceStatusChanged.bind(this);
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        if (this.props.match.params.courseId) {
            this.props.dispatch(coursesActions.fetch(this.props.match.params.courseId));
            this.props.dispatch(stepsActions.fetchLastByCourse(this.props.match.params.courseId));
        }
        
        if(values.u){
            this.props.dispatch(usersActions.fetchUser(values.u));
            this.setState({selectedStudent: values.u})
        }

        if(values.p){
            this.setState({selectedProgression: values.p})
        }

        if(values.f){
            this.setState({selectedFinishedCourse: values.f}, ()=>{
                if(this.props.finishedCourses.entities[this.state.selectedFinishedCourse]){
                    this.setState({showBlocklyValidate: true})
                }
            })
        }

        if(this.props.finishedCourses.entities[this.state.selectedFinishedCourse]){
            this.setState({showBlocklyValidate: true})
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.finishedCourses.entities[this.state.selectedFinishedCourse] && !prevProps.finishedCourses.entities[this.state.selectedFinishedCourse]){
            this.setState({showBlocklyValidate: true})
        }
    }

    onCompileClick() {
        try{
            if (this.props.blockly.mainWorkspace === null) {
                return;
            }
            const code = window.Blockly.Arduino.workspaceToCode(this.props.blockly.mainWorkspace);
            this.props.dispatch(extensionActions.sendMessage({msg:{cmd:"compilation", data:code, url: runtimeEnv().REACT_APP_COMPILATION_SERVER, filterBoards: ["mini", "mega", "uno"]}, timeout:60000}))
        } 
        catch(error) { 
             console.log ("Erreur blockly", error);
             if (error) {
                this.props.dispatch(modalActions.addModalMessageMid({
                    type: modalConstants.MODAL_MESSAGE_TYPE.COMBINATED_ERROR})
                )
            }
            return
        }
    }

    showBlocklyValidate() {
        this.setState({showBlocklyValidate: true});
    }

    showInProgress() {
        this.setState({showBlocklyValidate: false});
    }

    onMainWorkspaceStatusChanged(workspace) {
        if (workspace !== null){
            this.props.dispatch(blocklyActions.mainWorkspaceInitiated(workspace));
        }
        else {
            this.props.dispatch(blocklyActions.mainWorkspaceInvalid());
        }
    }

    render() {

        let step;
        if(this.props.finishedCourses.entities[this.state.selectedFinishedCourse]){
            step = Object.values(this.props.steps.entities).find(s => {
                const c = this.props.courses.entities[this.props.match.params.courseId]
                if(c && c.stepsCount === s.currentPositionInCourse && s.course == c._id ){
                    return s
                }
                return null
            })
        }else if(this.props.progressions.entities[this.state.selectedProgression]){
            step = this.props.steps.entities[this.props.progressions.entities[this.state.selectedProgression].step]
        }

        let activityInProgress = false, activityValidate = false

        if(this.props.finishedCourses.entities[this.state.selectedFinishedCourse]){
            activityValidate = true

            if(this.props.progressions.entities[this.state.selectedProgression]){
                if(this.props.finishedCourses.entities[this.state.selectedFinishedCourse].createdAt < this.props.progressions.entities[this.state.selectedProgression].createdAt 
                || this.props.finishedCourses.entities[this.state.selectedFinishedCourse].createdAt < this.props.progressions.entities[this.state.selectedProgression].updatedAt)
                        activityInProgress = true
                else
                    activityInProgress = false
            }else{
                activityInProgress = false
            }
        }

        if(this.props.progressions.entities[this.state.selectedProgression]){
            if(this.props.finishedCourses.entities[this.state.selectedFinishedCourse]){
                if(this.props.finishedCourses.entities[this.state.selectedFinishedCourse].createdAt < this.props.progressions.entities[this.state.selectedProgression].createdAt 
                || this.props.finishedCourses.entities[this.state.selectedFinishedCourse].createdAt < this.props.progressions.entities[this.state.selectedProgression].updatedAt)
                    activityInProgress = true
                else
                    activityInProgress = false
            }else{
                activityInProgress = true
            }
        }

        if(this.props.courses.entities[this.props.match.params.courseId]){
            if(!this.props.courses.entities[this.props.match.params.courseId].isActivity){
                //Course can't have both progression and finished course
                if(this.props.progressions.entities[this.state.selectedProgression] && this.props.finishedCourses.entities[this.state.selectedFinishedCourse]){
                    activityInProgress = false
                }
            }
        }


        return (
            <div style={{display: 'flex', flexDirection: "column", flex: "1"}}>
                <Simulator/>
                <FloatingMessage messages={this.props.messages} />

                <Grid id='main_grid'>
                    <Grid.Row className='HeaderRow'>
                        <HeaderComponent title={this.props.courses.entities[this.props.match.params.courseId] ? this.props.courses.entities[this.props.match.params.courseId].title : "Interface de consultation d'activités"} />
                    </Grid.Row>

                    <HeaderActivityComponent 
                        onCompileClick={this.onCompileClick}
                        consultation={true} 
                    />

                    <Grid.Row columns='equal' className='WorkspaceRow' style={{display: "flex", flex: "1 0 auto"}}>
                        <Grid.Column computer={4} style={{paddingRight: 0, display: "flex", flexDirection: "column", flex: "0 2 35%"}}>
                            <ModalCourseConsultationComponent
                                activity={this.props.courses.entities[this.props.match.params.courseId]} 
                                isCourse={this.props.courses.entities[this.props.match.params.courseId] && !this.props.courses.entities[this.props.match.params.courseId].isActivity}
                                step={step}
                                progression={this.props.progressions.entities[this.state.selectedProgression]}
                                student={this.props.users.entities[this.state.selectedStudent]}
                                finished={this.props.finishedCourses.entities[this.state.selectedFinishedCourse]}
                                activityInProgress={activityInProgress}
                                activityValidate={activityValidate}
                                showInProgress={this.showInProgress}
                                showValidate={this.showBlocklyValidate}
                                activeStep={this.state.showBlocklyValidate ? "validate":"progress"}
                            />
                        </Grid.Column>
                        <Grid.Column style={{paddingLeft:0, display: "flex", flexDirection: "column", flex: "1 0 auto"}}>
                            <Container id='CourseBlocklyComponent'>
                                <BlocklyCourseConsultationComponent 
                                    lang={window.LANG}
                                    step={step}
                                    progression={this.props.progressions.entities[this.state.selectedProgression]}
                                    finished={this.props.finishedCourses.entities[this.state.selectedFinishedCourse]}
                                    showValidate={this.state.showBlocklyValidate}
                                    onMainWorkspaceStatusChanged={this.onMainWorkspaceStatusChanged}
                                />
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        steps: state.model.steps,
        courses: state.model.courses,
        progressions: state.model.progressions,
        finishedCourses: state.model.finishedCourses,
        users: state.model.users,
        blockly: state.blockly,
        messages: state.modal.messages
    }
}

const connectedCourseConsultation = connect(mapStateToProps)(CourseConsultation);
export {connectedCourseConsultation as CourseConsultation}