export const editorConstants={
    FETCH_STEP_STARTED:"EDITOR_FETCH_STEP_STARTED",
    FETCH_STEP_SUCEEDED:"EDITOR_FETCH_STEP_SUCEEDED",
    FETCH_STEP_FAILED:"EDITOR_FETCH_STEP_FAILED",
    CLEAR_STEPS_ENTITIES:"EDITOR_CLEAR_STEPS_ENTITIES",
    SAVE_DESCRIPTION:"EDITOR_SAVE_DESCRIPTION",
    SAVE_CURRENT_STEP_STARTED:"EDITOR_SAVE_CURRENT_STEP_STARTED",
    SAVE_CURRENT_STEP_SUCCEEDED:"EDITOR_SAVE_CURRENT_STEP_SUCCEEDED",
    SAVE_CURRENT_STEP_FAILED:"EDITOR_SAVE_CURRENT_STEP_FAILED",
    SAVE_INSTRUCTION:"EDITOR_SAVE_INSTRUCTION",
    SAVE_CONTEXT:"EDITOR_SAVE_CONTEXT",
    SAVE_CONTEXT_MEDIA:"EDITOR_SAVE_CONTEXT_MEDIA",
    SAVE_XML_SOLUTION:"EDITOR_SAVE_XML_SOLUTION",
    SAVE_XML_START:"EDITOR_SAVE_XML_START",
    SAVE_CHANGES:"EDITOR_SAVE_CHANGES",
    SWITCH_XML_MODE_STARTED:"EDITOR_SWITCH_XML_MODE_STARTED",
    SWITCH_XML_MODE_SUCCEEDED:"EDITOR_SWITCH_XML_MODE_SUCCEEDED",
    DELETE_ENTITY_FAILED:"EDITOR_DELETE_ENTITY_FAILED",
    XML_CHANGE_STARTED:"EDITOR_XML_CHANGE_STARTED",
    XML_CHANGE_SUCCEEDED:"EDITOR_XML_CHANGE_SUCCEEDED",
    SAVE_CLUE_CHANGES:"EDITOR_SAVE_CLUE_CHANGES",
}