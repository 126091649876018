import {bricksConstants} from '../../constants'
import * as api from "../../api"

export const bricksActions = {
    fetchAll,
    fetchOne,
}

function fetchAll() {
    return (dispatch, getState) => {
        dispatch({type: bricksConstants.FETCH_ALL_STARTED})
        api.bricksApi.fetchAll().then(resp => {
            const datas = resp.data
            dispatch({type: bricksConstants.FETCH_ALL_SUCCESS, payload: datas})
        })
        .catch(err => {
            dispatch({type: bricksConstants.FETCH_ALL_FAILURE, payload: err})
        })
    }
}

function fetchOne(brickId) {
    return (dispatch, getState) => {
        dispatch({type: bricksConstants.FETCH_ALL_STARTED})
        api.bricksApi.fetchOne(brickId).then(resp => {
            const datas = resp.data
            dispatch({type: bricksConstants.FETCH_ALL_SUCCESS, payload: datas})
        })
        .catch(err => {
            dispatch({type: bricksConstants.FETCH_ALL_FAILURE, payload: err})
        })
    }
}