import React, { Component } from 'react';
import {Button,Modal,Icon,Checkbox,List} from 'semantic-ui-react'

class ToolboxEditorComponent extends Component{
	constructor(props){
		super(props)
		this.renderContent=this.renderContent.bind(this)
		this.handleClickCategory=this.handleClickCategory.bind(this)
    this.handleClickBlock=this.handleClickBlock.bind(this)
		this.compileXml=this.compileXml.bind(this)
    this.saveToolbox=this.saveToolbox.bind(this)

   this.handleOpen=this.handleOpen.bind(this)
   this.handleClose=this.handleClose.bind(this)
		this.state={
			 xml:[{name:"control",
            xml:'<category name="%{BKY_CATEGORY_CONTROL}" id="control" colour="#FFAB19" secondaryColour="#CF8B17">',
            checked:'false',
            blocks:[
              {name:'control_wait',
              checked:'false',
							xml:'<block type="control_wait" id="control_wait">' +
      								'<value name="DURATION">' +
        								'<shadow type="math_positive_number_block">' +
          									'<field name="NUM">1</field>' +
        								'</shadow>' +
      								'</value>' +
    							'</block> '},
    						{name:'control_repeat_until',
                checked:'false',
                xml:'<block type="control_repeat_until" id="control_repeat_until"></block>' },
    						{name:'control_if',
                checked:'false',
                xml:'<block type="control_if" id="control_if"></block>' },
    						{name:'control_if_else',
                checked:'false',
                xml:'<block type="control_if_else" id="control_if_else"></block>' },
    						{name:'operator_lt',
                checked:'false',
                xml:'<block type="operator_lt" id="operator_lt">' +
      									'<value name="OPERAND1">' +
        										'<shadow type="math_number_block">' +
          											'<field name="NUM">0</field>' +
        											'</shadow>' +
      										'</value>' +
      										'<value name="OPERAND2">' +
        										'<shadow type="math_number_block">' +
          										'<field name="NUM">0</field>' +
        										'</shadow>' +
      										'</value>' +
   											'</block>' },
    						{name:'operator_lt_equals',
                checked:'false',
                xml:'<block type="operator_lt_equals" id="operator_lt_equals">' +
      							 '<value name="OPERAND1">' +
        								'<shadow type="math_number_block">' +
          								'<field name="NUM">0</field>' +
        								'</shadow>' +
      								'</value>' +
      								'<value name="OPERAND2">' +
        								'<shadow type="math_number_block">' +
          								'<field name="NUM">0</field>' +
        								'</shadow>' +
      								'</value>' +
    								'</block>' },
    						{name:'operator_equals',
                checked:'false',
                xml:'<block type="operator_equals" id="operator_equals">' +
      								'<value name="OPERAND1">' +
        							 '<shadow type="math_number_block">' +
            						'<field name="NUM">0</field>' +
        							 '</shadow>' +
      								'</value>' +
      								'<value name="OPERAND2">' +
        							 '<shadow type="math_number_block">' +
          							'<field name="NUM">0</field>' +
        							 '</shadow>' +
      								'</value>' +
    								'</block>' },
    						{name:'operator_gt_equals',
                checked:'false',
                xml:'<block type="operator_gt_equals" id="operator_gt_equals">' +
      										'<value name="OPERAND1">' +
        										'<shadow type="math_number_block">' +
          											'<field name="NUM">0</field>' +
        										'</shadow>' +
      										'</value>' +
      										'<value name="OPERAND2">' +
        										'<shadow type="math_number_block">' +
          											'<field name="NUM">0</field>' +
        										'</shadow>' +
      										'</value>' +
    									'</block>' },
    						{name:'operator_gt',
                checked:'false',
                xml:'<block type="operator_gt" id="operator_gt">' +
      									'<value name="OPERAND1">' +
        									'<shadow type="math_number_block">' +
          										'<field name="NUM">0</field>' +
        									'</shadow>' +
      									'</value>' +
      									'<value name="OPERAND2">' +
        									'<shadow type="math_number_block">' +
          										'<field name="NUM">0</field>' +
        									'</shadow>' +
      									'</value>' +
    								'</block>' },
    						{name:'operator_not_equals',
                checked:'false',
                xml:'<block type="operator_not_equals" id="operator_not_equals">' +
      										'<value name="OPERAND1">' +
        										'<shadow type="math_number_block">' +
          											'<field name="NUM">0</field>' +
        										'</shadow>' +
      										'</value>' +
      										'<value name="OPERAND2">' +
        										'<shadow type="math_number_block">' +
          											'<field name="NUM">0</field>' +
        										'</shadow>' +
      										'</value>' +
    									'</block>' },
    						{name:'operator_and',
                checked:'false',
                xml:'<block type="operator_and" id="operator_and"></block>' },
    						{name:'operator_or',
                checked:'false',
                xml:'<block type="operator_or" id="operator_or"></block>' },
    						{name:'operator_not',
                checked:'false',
                xml:'<block type="operator_not" id="operator_not"></block>' },
    					]
    				},
    				{name:'loops',
            checked:'false',
            xml:'<category name="%{BKY_CATEGORY_LOOPS}"  id="loops" colour="#4C97FF" secondaryColour="#3373CC">',
    				blocks:[
              {name:'control_while',
              checked:'false',
              xml:'<block type="control_while" id ="control_while"></block>'},
    					{name:'control_repeat',
              checked:'false',
              xml:'<block type="control_repeat" id="control_repeat">'+
                    '<value name="TIMES">' +
                      '<shadow type="math_whole_number_block">' +
                        '<field name="NUM">10</field>' +
                      '</shadow>' +
                    '</value>' +
                  '</block>' }
              ]
            },
            {name:'number',
            checked:'false',
            xml:'<category name="%{BKY_CATEGORY_NUMBERS}"  id="number" colour="#59C059" secondaryColour="#389438">',
            blocks:[
              {name:'operator_random',
              checked:'false',
              xml:'<block type="operator_random" id="operator_random">' +
                    '<value name="FROM">' +
                      '<shadow type="math_number_block">' +
                        '<field name="NUM">1</field>' +
                      '</shadow>' +
                    '</value>' +
                    '<value name="TO">' +
                      '<shadow type="math_number_block">' +
                        '<field name="NUM">10</field>' +
                      '</shadow>' +
                    '</value>' +
                  '</block>' },
              {name:'operator_add',
              checked:'false',
              xml:'<block type="operator_add" id="operator_add">' +
                      '<value name="NUM1">' +
                          '<shadow type="math_number_block">' +
                            '<field name="NUM">0</field>' +
                          '</shadow>' +
                        '</value>' +
                        '<value name="NUM2">' +
                          '<shadow type="math_number_block">' +
                            '<field name="NUM">0</field>' +
                          '</shadow>' +
                        '</value>' +
                      '</block>'},
              {name:'operator_subtract',
              checked:'false',
              xml:'<block type="operator_subtract" id="operator_subtract">' +
                    '<value name="NUM1">' +
                      '<shadow type="math_number_block">' +
                        '<field name="NUM">0</field>' +
                      '</shadow>' +
                    '</value>' +
                    '<value name="NUM2">' +
                      '<shadow type="math_number_block">' +
                        '<field name="NUM">0</field>' +
                      '</shadow>' +
                    '</value>' +
                  '</block>' },
              {name:'operator_multiply',
              checked:'false',
              xml:'<block type="operator_multiply" id="operator_multiply">' +
                    '<value name="NUM1">' +
                      '<shadow type="math_number_block">' +
                        '<field name="NUM">0</field>' +
                      '</shadow>' +
                    '</value>' +
                    '<value name="NUM2">' +
                      '<shadow type="math_number_block">' +
                        '<field name="NUM">0</field>' +
                      '</shadow>' +
                    '</value>' +
                  '</block>' },
              {name:'operator_divide',
              checked:'false',
              xml:'<block type="operator_divide" id="operator_divide">' +
                    '<value name="NUM1">' +
                      '<shadow type="math_number_block">' +
                        '<field name="NUM">0</field>' +
                      '</shadow>' +
                    '</value>' +
                    '<value name="NUM2">' +
                      '<shadow type="math_number_block">' +
                        '<field name="NUM">0</field>' +
                      '</shadow>' +
                    '</value>' +
                  '</block>' }
                  ]
                },
                {name:'data',
                checked:'false',
                xml:'<category name="%{BKY_CATEGORY_VARIABLES}" id="data" colour="#FF8C1A" secondaryColour="#DB6E00" custom="VARIABLE">',
                blocks:[],
                },

                {name:'led',
                checked:'false',
                xml:'<category name="%{BKY_LED_CATEGORY_NAME}"  id="led" colour="#9966FF" secondaryColour="#774DCB">',
                blocks:[
                  {name:'led_standard',
                  checked:'false',
                  xml:'<block type ="led_standard" id="classic_led"></block>',
                  },
                  {name:'led_change',
                  checked:'false',
                  xml:'<block type ="led_change" id="led_change"></block>'
                  },
                  {name:'timed_led',
                  checked:'false',
                  xml:'<block type ="timed_led" id="timed_led">'+
                        '<value name="DURATION">' +
                          '<shadow type="math_number_block">' +
                            '<field name="NUM">1</field>' +
                          '</shadow>' +
                        '</value>' +
                      '</block>'
                  }
                ]
                },
                {name:'buttons',
                checked:'false',
                xml:'<category name="%{BKY_BUTTON_CATEGORY_NAME}" id="button" colour="#5CB1D6" secondaryColour="#2E8EB8">',
                blocks:[
                  {name:'button',
                  checked:'false',
                  xml:'<block type ="button" id="button_block"></block>'
                  }
                ]
                },
                {name:'buzzer',
                checked:'false',
                xml:'<category name="%{BKY_CATEGORY_BUZZER}" id="buzzer" colour="#CF63CF" secondaryColour="#BD42BD">',
                blocks:[
                  {name:'piezo_buzzer',
                  checked:'false',
                  xml:'<block type ="piezo_buzzer" id="piezo_buzzer">'+
                        '<value name="NOTE">' +
                          '<shadow type="math_number_block">' +
                            '<field name="NUM">450</field>' +
                          '</shadow>' +
                        '</value>' +
                      '</block>'
                  },
                  {name:'timed_buzzer',
                  checked:'false',
                  xml:'<block type ="timed_buzzer" id="timed_buzzer">'+
                        '<value name="NOTE">' +
                          '<shadow type="math_number_block">' +
                            '<field name="NUM">450</field>' +
                          '</shadow>' +
                        '</value>' +
                        '<value name="DURATION">' +
                          '<shadow type="math_number_block">' +
                            '<field name="NUM">1</field>' +
                          '</shadow>' +
                        '</value>' +
                      '</block>'
                    },
                    {name:'buzzer_notes',
                    checked:'false',
                    xml:'<block type ="buzzer_notes" id="buzzer_notes"></block>'
                    }
                  ]
                  },
                  {name:'potentiometer',
                  checked:'false',
                  xml:'<category name="%{BKY_CATEGORY_POTENTIOMETER}" id="potentiometer" colour="#6699FF" secondaryColour="#774DCB">',
                  blocks:[
                    {name:'potentiometer_brick',
                    checked:'false',
                    xml:'<block type ="potentiometer_brick" id="potentiometer"></block>'
                  }
                ]
                },
                {name:'cat_makey_makey',
                checked:'false',
                xml:'<category name="%{BKY_CATEGORY_MAKEY_MAKEY}" id="cat_makey_makey" colour="#5CB1D6" secondaryColour="#2E8EB8">',
                blocks:[
                  {name:'makey_makey_brick',
                  checked:'false',
                  xml:'<block type ="makey_makey_brick" id="makey_makey"></block>'
                  }
                ]
                },
                {name:'infra',
                checked:'false',
                xml:'<category name="%{BKY_CATEGORY_INFRARED}" id="infra" colour="#5CB1D6" secondaryColour="#2E8EB8">',
                blocks:[
                  {name:'infrared',
                  checked:'false',
                  xml:'<block type ="infrared" id="infrared"></block>'
                  },
                  {name:'infra_bool',
                  checked:'false',
                  xml:'<block type ="infra_boolean" id="infra_boolean"></block>'}
                  ]
                },
                {name:'weather',
                checked:'false',
                xml:'<category name="%{BKY_CATEGORY_WEATHER}" id="weather" colour="#5CB1D6" secondaryColour="#2E8EB8">',
                blocks:[
                  {name:'temperature_brick',
                  checked:'false',
                  xml:'<block type ="temperature_brick" id="temperature_brick"></block>'}
                  ]
                },
                {name:'luminosity',
                checked:'false',
                xml:'<category name="%{BKY_CATEGORY_BRIGHTNESS}" id="luminosity" colour="#5CB1D6" secondaryColour="#2E8EB8">',
                blocks:[
                  {name:'brightness',
                  checked:'false',
                  xml:'<block type ="brightness" id="brightness"></block>'}]}
                  
                  
    						
    			], modalOpen:false,
          validToolbox:false
	}
 
}

componentWillMount(){
  if(this.props.step.toolbox){
  let propsToolbox=new DOMParser().parseFromString(this.props.step.toolbox, "text/xml")
  let category=null;
  let block=null;
    for(let i=0;i<this.state.xml.length;i++){
      category=this.state.xml[i]
      if(propsToolbox.getElementById(`${category.name}`)){
        category.checked='true'
        for(let j=0; j<category.blocks.length;j++){
          block=category.blocks[j];
          if(propsToolbox.getElementById(`${block.name}`)){
            block.checked='true'
          }
        }
      }
    }
  }
}

handleOpen(){
  this.state.modalOpen=true
  this.setState(this.state)
}

handleClose(){
  this.state.modalOpen=false
  this.setState(this.state)
}



handleClickCategory(e,data){
  let category=null
  let block=null
  let checkbox=null
  for(let i = 0 ; i<this.state.xml.length;i++){
      category=this.state.xml[i]
      if(category.name==data.id){
        if(data.checked==true) {
          category.checked='true'
        }else{
          category.checked='false'
        }
      }
  }

//on surveille si au moins une des catégories est cochée
  for (let j = 0; j<this.state.xml.length;j++){
    if(this.state.xml[j].checked=='true'){
      this.state.validToolbox=true
      break
    }else{
      this.state.validToolbox=false
    }
  }
  return this.setState(this.state)
}


handleClickBlock(e,data){
  let category=null
  let block=null
  let checkbox=null
  for(let i = 0 ; i<this.state.xml.length;i++){
    category=this.state.xml[i]
    for(let j=0; j<category.blocks.length;j++){
      block=category.blocks[j];
      if(data.id==block.name)
        if(data.checked==true){
          block.checked='true'
        }else{
          block.checked='false'
        }
    }
  }
return this.setState(this.state)
}

saveToolbox(xml){
  return this.props.saveToolbox(xml)
}

compileXml(){
  let xml="<xml>"
  let category=null
  let block=null
  let checkbox=null
    for(let i = 0; i<this.state.xml.length;i++){
      category=this.state.xml[i]
      checkbox=document.getElementById(category.name)
      if(checkbox && checkbox.checked){
        xml+=category.xml
        for (let j =0; j<category.blocks.length;j++){
          block=category.blocks[j]
          checkbox=document.getElementById(block.name)
          if(checkbox && checkbox.checked){
            xml+=block.xml
          }
        }
        xml += '</category>'
      }
    }
  xml+='</xml>'
  this.handleClose()
  return this.saveToolbox(xml)
}


renderContent(xml){
  let categories=[];
  let blockList=[]

					for (let category of this.state.xml){
						let categoryTab=[];
							const tab =(
                <List.Header >
                  <Checkbox label ={category.name} checked={category.checked=='true'?true:false} id={category.name}  onChange={this.handleClickCategory} />
                </List.Header>
              )

						let blockTab=[]
						let checkboxes=[]
							for( let block of category.blocks){
                checkboxes.push(<List.Item key={block.name}><Checkbox checked={block.checked=='false'?false:true} disabled={category.checked=='true'? false:true} id={block.name} label={block.name}  onChange={this.handleClickBlock}/></List.Item>) }
  const blocks=(
    <List.List>
      <List.Content> 
        {checkboxes}
      </List.Content>
    </List.List>
  )

              
						
					
						blockList.push(<List key={category.name} >{tab}{blocks}</List>)
						
				}
				return blockList
			}


render() {

	return(

			<Modal trigger={<Button color='red' onClick={this.handleOpen}>Définir une toolbox</Button>} open={this.state.modalOpen}>
				<Modal.Header>Définis la Toolbox de cette étape</Modal.Header>
				<Modal.Content>

{this.renderContent(this.props.xml)}


<Modal.Content></Modal.Content>
				</Modal.Content>
				          <Modal.Actions>
            <Button negative onClick={this.handleClose}>Fermer</Button>
            <Button positive disabled={this.state.validToolbox==true?false:true} content={this.state.validToolbox==true?'Valider la Toolbox':"Choisir minimum une catégorie"}  onClick={this.compileXml} />
          </Modal.Actions>
			</Modal>

		)
}
}
export {ToolboxEditorComponent}