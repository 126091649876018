import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Button,
  Icon,
  Card,
  Image,
  Input,
  Checkbox,
  Header,
  Container
} from 'semantic-ui-react'

class Monitor extends Component{

    constructor(props){
        super(props)
        this.monitor=React.createRef();
    }

    componentDidMount(){
        this.updateScroll()
    }
    componentDidUpdate(){
        this.updateScroll()
    }
    
    updateScroll(){
        if(this.monitor && this.monitor.current && this.monitor.current.scrollHeight){
           this.monitor.current.scrollTop = this.monitor.current.scrollHeight;
        }
    }
    render(){
        return (
            <div style={{height:"200px", overflowY:"scroll", width: "100%", color:"green", whiteSpace: "pre-wrap", color:this.props.textColor, fontSize:this.props.textSize, background:this.props.bgColor, wordBreak: this.props.wordBreak, display:this.props.display}} ref={this.monitor}>{this.props.children}</div>
        )
    }
}

class SimulatorBrick extends Component {

    constructor(props){
        super(props)
        this.state={
            
        }
    }

    componentWillUnmount(){
        if(this.props.onUnmount)
            this.props.onUnmount()
    }

    onChange = (e, data) => {
        
        //ids must be unique in the entire page for Input, so id = SimulatorBrickId+inputId instead of inputId
        let id = parseInt(e.target.id);
        if(isNaN(id))
            return
        id -= this.props.id*10;
        if(id >= 0 && this.props.inputs && this.props.inputs[id] && this.props.inputs[id].onChange){
            this.props.inputs[id].onChange(this.props.id, id, data.value)
        }
    }

    onCheck = (e, data) => {
        //ids must be unique in the entire page for Checkbox, so id = SimulatorBrickId+inputId instead of inputId
        let id = parseInt(e.target.id);
        if(isNaN(id))
            return
        id-=this.props.id*10;
        if(id >= 0 && this.props.inputs && this.props.inputs[id] && this.props.inputs[id].onCheck){
            this.props.inputs[id].onCheck(this.props.id, id, data.checked)
        }
    }

    onMouseUp = (e) => {
        let id = parseInt(e.target.getAttribute("brickid"));
        if(id >= 0 && this.props.inputs && this.props.inputs[id] && this.props.inputs[id].onUp){
            this.props.inputs[id].onUp(this.props.id, id, 'released')
        }
    }

    onMouseDown = (e) => {
        let id = parseInt(e.target.getAttribute("brickid"));
        if(id >= 0 && this.props.inputs && this.props.inputs[id] && this.props.inputs[id].onDown){
            this.props.inputs[id].onDown(this.props.id, id, 'pressed')
        }
    }

    renderMonitor(key, output){
        
        let d = (
            <Monitor key={key} textColor={output.textColor} textSize={output.textSize} bgColor={output.bgColor} wordBreak={output.wordBreak} display={output.display}>
                {output.value.slice(-20000)}
            </Monitor>
        )
        // d.scrollTop = d.scrollHeight;
        return d
    }

    renderControlsInput(){
        if(!this.props.inputs) return null
        return this.props.inputs.map((input, i) => {
            switch(input.type){
                case 'button':
                    return (<div title={input.title} key={i} style={{margin:"10px 0 10px 0"}}><Button brickid={i} onTouchStart={this.onMouseDown} onMouseDown={this.onMouseDown} onTouchEnd={this.onMouseUp} onMouseUp={this.onMouseUp} disabled={input.disabled}>{input.text}</Button></div>)
                case 'check':
                    return (<div key={i} ><Checkbox id={i+this.props.id*10} label={input.text} toggle checked={input.value} onChange={this.onCheck}></Checkbox></div>)
                case 'range':
                    return (<div key={i}><Header size="tiny">{input.text}</Header><Input id={i+this.props.id*10} type="range" min={input.min || null} max={input.max || null} value={input.value} onChange={this.onChange} style={{verticalAlign: "middle"}}/> <span >{input.value}</span></div>)
                case 'number':
                    return (<div key={i}><Header size="tiny">{input.text}</Header><Input id={i+this.props.id*10} type="number" min={input.min || null} max={input.max || null} value={input.value} onChange={this.onChange} style={{verticalAlign: "middle"}}/></div>)
            }
        })
    }

    renderOutputsMeta(){
        if(!this.props.outputs) return null
        return this.props.outputs.map((output, i) => {
            switch(output.type){
                case 'text':
                    if(!output.onContentTag)
                        return (<div key={i} style={{whiteSpace: output.whiteSpace,fontSize: output.size, fontWeight: output.bold ? "bold" : "normal", color: output.color}}>{output.value.length == 0 && output.forceContentIfEmpty ? <br></br> : output.value}</div>)
                default:
                    return null;
            }
        })
    }

    renderOutputsContent(){
        if(!this.props.outputs) return null
        return this.props.outputs.map((output, i) => {
            switch(output.type){
                case 'monitor':
                    return this.renderMonitor(i, output);
                case 'text':
                    if(output.onContentTag)
                        return (<div key={i} style={{whiteSpace:output.whiteSpace,fontSize: output.size, fontWeight: output.bold ? "bold" : "normal", color: output.color}}>{output.value.length == 0 && output.forceContentIfEmpty ? <br></br> : output.value}</div>)
                break;
                default:
                    return null;
            }
        })
    }

    renderControls(){
       if(this.props.type == "sensor"){
        return (
            <Card.Content extra>
                {this.renderControlsInput()}
            </Card.Content>
        )
       }
    }

    render(){
    return (
        <Card>
            <Card.Content>
            <Image
                floated="right"
                size="mini"
                src={this.props.img}
            />
            <Card.Header>{this.props.name}</Card.Header>
            <Card.Meta style={{fontSize: "1.3em", marginTop:"5px"}}>
                {this.renderOutputsMeta()}
            </Card.Meta>
            {this.renderOutputsContent()}
            </Card.Content>
            {this.renderControls()}
        </Card>
    )
    }
}


export { SimulatorBrick }