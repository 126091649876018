import {serialMonitorConstants} from "../constants";

export const serialMonitorActions = {
	push,
	clear,
	open,
	close,
	setAutoOpen,
	toggleAutoOpen
};

function push(str){
	return (dispatch, getState) =>{
		let {open, autoOpen} = getState().serialMonitor;
		let log = getState().serialMonitor.log ? getState().serialMonitor.log : "";
		log+= str;
		if(log.length > 5000){
			log =log.substring(str.length);
			if(log.length === 0){
				log = str.length > 5000 ? str.substring(str.length - 5000) : str
			}
		}
		// console.log(log)
		if(autoOpen && !open)
			dispatch({type: serialMonitorConstants.OPEN})
		if(getState().serialMonitor.open)
			dispatch({type: serialMonitorConstants.CHANGE, log})
	}
}

function clear(){
	return {type: serialMonitorConstants.CHANGE, log: ""}
}

function open(){
	return {type: serialMonitorConstants.OPEN}
}

function close(){
	return {type: serialMonitorConstants.CLOSE}
}

function setAutoOpen(autoOpen){
	return {type: serialMonitorConstants.SET_AUTO_OPEN, autoOpen};
}

function toggleAutoOpen(){
	return {type: serialMonitorConstants.TOGGLE_AUTO_OPEN};
}