import React, { Component } from 'react';
import {Dropdown,TextArea,Button,Menu,Table,Input,Checkbox} from 'semantic-ui-react';

class CluesEditorComponent extends Component{
	constructor (props){
		super(props)
		this.autoSaveClue=this.autoSaveClue.bind(this)
		this.createClue=this.createClue.bind(this)
		this.deleteClue=this.deleteClue.bind(this)

	}
	componentDidMount(){
				
	}
	componentWillUpdate(){

		
	}
	deleteClue(e,data){
	
		return this.props.deleteClue(data.clueid)
	}
	autoSaveClue(e,data){
		console.log(data)
		return this.props.autoSaveClue(e,data)
	}
	createClue(){
		return this.props.createClue()
	}
	renderTableClues(){
		console.log(this.props.cluesId)
		console.log(this.props.clues)
		if(this.props.clues  && this.props.cluesId && this.props.clues!=={} && this.props.cluesId!=={}){
	
		let tabRow=[]

		for(let i=0; i<this.props.cluesId.length; i++){
			console.log(this.props.clues[this.props.cluesId[i]])

		
			tabRow.push(<Table.Row key={this.props.clues[this.props.cluesId[i]].id}>
							<Table.Cell>{i+1}</Table.Cell>
							<Table.Cell><Input clueid={this.props.clues[this.props.cluesId[i]].id} data="title" defaultValue={this.props.clues[this.props.cluesId[i]].title} onChange={this.autoSaveClue}/></Table.Cell>
							<Table.Cell><Input clueid={this.props.clues[this.props.cluesId[i]].id} data="text"defaultValue={this.props.clues[this.props.cluesId[i]].text} onChange={this.autoSaveClue}/></Table.Cell>
							<Table.Cell><Input clueid={this.props.clues[this.props.cluesId[i]].id} data="mediaLink"defaultValue={this.props.clues[this.props.cluesId[i]].mediaLink} onChange={this.autoSaveClue}/></Table.Cell>
							<Table.Cell><Checkbox clueid={this.props.clues[this.props.cluesId[i]].id} data="autoDisplay" checked={this.props.clues[this.props.cluesId[i]].autoDisplay==true?true:false} toggle onChange={this.autoSaveClue}/></Table.Cell>
							<Table.Cell><Button clueid={this.props.clues[this.props.cluesId[i]].id} color="red" icon ="delete" onClick={this.deleteClue}></Button></Table.Cell>
						</Table.Row> )
		}


			 return (
			 	<Table.Body>
			 	{tabRow}
			 	</Table.Body>
			 	)
	}}

	render(){
console.log(this.props.clues)
		if(this.props.cluesId!==null || this.props.clues!=={}){
		return(
		<div>
		<h3>Indices </h3>
		<Button color='blue' onClick={this.createClue}>Créer un nouvel indice </Button>
		<Table>

		<Table.Header>
			      <Table.Row>

			        <Table.HeaderCell>Numéro</Table.HeaderCell>
			        <Table.HeaderCell>Titre</Table.HeaderCell>
			        <Table.HeaderCell>Texte</Table.HeaderCell>
			        <Table.HeaderCell>MediaLink</Table.HeaderCell>
			        <Table.HeaderCell>autoDisplay</Table.HeaderCell>
			        <Table.HeaderCell>Supprimer</Table.HeaderCell>

			      </Table.Row>
			    </Table.Header>
		{this.renderTableClues()}
		</Table>
		</div>
		)} else { return(<div>
		<h3>Indices vide </h3>
		<Button color='blue' onClick={this.createClue}>Créer un nouvel indice</Button>
		</div>)}

			
	
		}
}

export{CluesEditorComponent}