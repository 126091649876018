import {structureConstants} from '../../constants'
import {structureOwnedConstants} from '../../constants'
import {modelConstants} from '../../constants'
import * as api from "../../api"

import { structuresSchema, structureSchema } from "../../schemas";

import {normalize} from 'normalizr'

export const structuresActions = {
    fetchStructures,
    update,
    checkForStructureName,
    fetchStructureByPin
}

function fetchStructures(removeLearners) {
    return (dispatch, getState) => {
    
        dispatch({type: modelConstants.FETCH_ENTITIES_STARTED, entities: "structures"})
        api.structureApi.fetchStructure().then(resp => {
            let structures = resp.data;
            if(removeLearners){
                for(let structure of structures)
                structure.learners = null;
            }
            const entities = normalize(structures, structuresSchema)
            dispatch({type: modelConstants.FETCH_ENTITIES_DONE, originalEntities: "structures", entities})
        })
        .catch(err => {
            console.log(err)
            dispatch({type: structureConstants.FETCH_FAILED, payload: err})
        })
        
    }
}

function update(id, structure){
	return (dispatch, getState) => {
		dispatch({type: modelConstants.UPDATE_ENTITY_STARTED, entities:"structures"});
		return api.structureApi.update(id, structure).then(response => {
			let entities = normalize(response.data, structureSchema)
			dispatch({type: modelConstants.UPDATE_ENTITY_DONE, originalEntities:"structures", entities: entities});
		}).catch(error => {
            console.log(error)
			dispatch({type: structureConstants.UPDATE_FAILED, error})
		})
	}
}

function fetchStructureByPin(pin){
    return (dispatch, getState) => {
        dispatch({type: modelConstants.FETCH_ENTITIES_STARTED, entities:"structures"});
        return api.structureApi.fetchByPin(pin).then(response => {
			let entities = normalize(response.data, structureSchema)
			dispatch({type: modelConstants.FETCH_ENTITIES_DONE, originalEntities:"structures", entities: entities});
		}).catch(err => {
            console.log(err)
            if(err.response && err.response.data && err.response.data.message)
				dispatch({type: structureConstants.FETCH_FAILED, error: err.response.data.message})
			else
            	dispatch({type: structureConstants.FETCH_FAILED, error: err})
		})
    }
}

function checkForStructureName(params) {

	return (dispatch, getState) => {
		console.log('checkForStructureName -> params : ', params)
	}
}