import React, {Component} from 'react';
import {connect} from "react-redux";
import querySearch from "stringquery";
import {withRouter} from 'react-router-dom'

import {Loader, Dimmer} from 'semantic-ui-react'

import {StructureComponent} from "../../components/StructureComponent/StructureComponent";
import {ErrorComponent} from "../../components/ErrorComponent";

import {SocketIo} from '../SocketIo'

import {getActualUser} from "../../reducers/user_reducer";

import {modelActions, structuresActions, usersActions, coursesActions, stepsActions, groupsActions, selectedCoursesActions, progressionsActions} from "../../actions/model";
import {socketIoActions} from "../../actions"

import {PageWithSideMenu} from "../PageWithSideMenu";

class Structure extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isChange: false
        }
        this.deleteActivity = this.deleteActivity.bind(this);
    }

    componentDidMount(prevProps) {
        const query = querySearch(this.props.location.search);

        document.title = 'Structure - Thingz'
        if(this.props.user){
            this.fetch();
        }

        if(query.add){
            this.props.dispatch(coursesActions.cloneActivity(query.add))
			let newUrl = new URL(window.location.href);
			newUrl.searchParams.delete('add');
			window.history.replaceState(window.history.state,'',newUrl.toString());
		}
    }

    componentDidUpdate(prevProps){
        if(!prevProps.user && this.props.user){
           this.fetch();
        }

        if(this.state.tryActivity && prevProps.progressions.isLoading > 0 && prevProps.progressions.isLoading !== this.props.progressions.isLoading){
            this.props.history.push(`/course/${this.state.activityId}`)
        }
    }

    componentWillUnmount(){
        this.props.dispatch(modelActions.invalidate())
    }

    fetch(){
        this.props.dispatch(structuresActions.fetchStructures());
        this.props.dispatch(selectedCoursesActions.fetchAllForStructure());
        this.props.dispatch(coursesActions.fetchForUser());
    }

    onStudentDeleteClick = (student) => {
        this.props.dispatch(usersActions.deleteUser(student))
    }

    onTransferStudent = (studentId, groupId) => {
        this.props.dispatch(usersActions.updateGuest({_id: studentId, group:groupId}))
    }

    onAddStudents = (students, group) => {
        this.props.dispatch(usersActions.createGuests(students, group))
    }

    onAddGroup = (groupName) => {
        this.props.dispatch(groupsActions.create({name:groupName, structure:this.props.structures.entities[this.props.users.entities[this.props.user._id].structureOwned]}))
    }

    onGroupUpdate = (groupId, newName) =>{
        this.props.dispatch(groupsActions.update({_id: groupId, name: newName}))
    }

    onGroupSuppression = (groupId) => {
        this.props.dispatch(groupsActions.remove(groupId))
    }

    deleteActivity(id) {
        this.props.dispatch(coursesActions.destroy(id));
    }

    getActivityShareId=(id) => {
        this.props.dispatch(coursesActions.getShareId(id));
    }

    tryActivity=(id) => {
        
        this.setState({tryActivity: true, activityId: id}, ()=>{
            this.props.dispatch(progressionsActions.deleteProgressByCourse(id))
        })
    }

    renderLoading(){
        return (
            <PageWithSideMenu dontFetch={true} pageName={"structure"}>
                <Dimmer active inverted>
                    <Loader inverted>Chargement...</Loader>
                </Dimmer>
            </PageWithSideMenu>
        )
    }

    render() {
        if (!this.props.user || this.props.structures.isLoading > 0)
            return this.renderLoading();
        let structure = this.props.users.entities[this.props.user._id] ? this.props.structures.entities[this.props.users.entities[this.props.user._id].structureOwned] : undefined;
        
        let groups = {...this.props.groups};
        groups.array = []
        if(structure)
            groups.array = Object.keys(groups.entities).filter(group => {
                return groups.entities[group].structure === structure._id
            }).sort((a, b) => {
                return groups.entities[a].name.localeCompare(groups.entities[b].name)
            })
        if(groups.array.length > 0){
            groups.entities = groups.array.reduce((obj, key) => {
                obj[key] = groups.entities[key]
                obj[key].learners =[]
                return obj
            }, {})
        }else{
            groups.entities = {}
        }
        groups.array.push("nogroup")
        groups.entities.nogroup = {
            _id:"nogroup",
            name:"Élèves sans groupe",
            learners:[]
        }

        let users = {...this.props.users}
        users.array = []
        
        if(structure)
            users.array = Object.keys(users.entities).filter(user => {
                if(users.entities[user].group && groups.entities[users.entities[user].group]){
                    groups.entities[users.entities[user].group].learners.push(users.entities[user])
                    return true;
                }  
                if(users.entities[user].structure === structure._id){
                    groups.entities.nogroup.learners.push(users.entities[user])
                    return true
                }
                return false;
            })
        Object.values(this.props.courses.entities).forEach(course => {
            if(course.user === this.props.user._id){
                if(course.cloneFrom && this.props.courses.entities[course.cloneFrom] && this.props.users.entities[this.props.courses.entities[course.cloneFrom].user])
                    users.array.push(this.props.users.entities[this.props.courses.entities[course.cloneFrom].user]._id)
            }
        })
        if(users.array.length > 0){
            users.entities = users.array.reduce((obj, key) => {
                obj[key] = users.entities[key];
                return obj
            }, {})
            users.entities[this.props.user._id] = this.props.users.entities[this.props.user._id]
        }else{
            users.entities = {}
        }

        let selectedCourses = Object.values(this.props.selectedCourses.entities).filter(select => {
            if(this.props.courses.entities[select.course] && 
            ((structure && select.structure === structure._id) || (structure && this.props.groups.entities[select.group] && this.props.groups.entities[select.group].structure === structure._id))){
                return select
            }
        })
        console.log(window.history)
        return (
            <PageWithSideMenu dontFetch={true} pageName={"structure"}>
                {this.props.structures.error && 
                   <ErrorComponent error={this.props.structures.error}  forbidden={{header: "Compte non confirmé", details: "Pour accéder à votre espace enseignant vous devez confirmer votre email en ouvrant l'email que nous vous avons envoyé."}}/>
                || <StructureComponent 
                    structure={structure} 
                    structures={this.props.structures.entities}
                    courses={this.props.courses}
                    groups={groups}
                    users={users} 
                    progressions={this.props.progressions.entities}
                    finishedCourses={this.props.finishedCourses.entities}
                    selectedCourses={selectedCourses}
                    steps={this.props.steps.entities}
                    deleteActivity={this.deleteActivity}
                    onDeleteClick={this.onStudentDeleteClick}
                    onAddStudents={this.onAddStudents}
                    onAddGroup={this.onAddGroup}
                    onGroupUpdate={this.onGroupUpdate}
                    onGroupSuppression={this.onGroupSuppression}
                    onTransferStudent={this.onTransferStudent}
                    tryActivity={this.tryActivity}
                    getActivityShareId={this.getActivityShareId}
                    location={this.props.location}
                    user={this.props.user}/>}
                    
            </PageWithSideMenu>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: getActualUser(state),
        structures: state.model.structures,
        users: state.model.users,
        courses: state.model.courses,
        groups: state.model.groups,
        selectedCourses: state.model.selectedCourses,
        progressions: state.model.progressions,
        finishedCourses: state.model.finishedCourses,
        steps: state.model.steps
    }
}

const connectedStructure = withRouter(connect(mapStateToProps)(Structure));
export { connectedStructure as Structure }
