import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Button,
  Icon,
} from 'semantic-ui-react'

import { 
  simulatorActions,
  modalActions
} from '../../actions';

import { 
  modalConstants
} from '../../constants';

class SimulatorButton extends Component {

  onClick = () => {
    if (this.props.simulator.open) {
        this.props.dispatch(simulatorActions.close());
    }else{
        try{
            let code;
            switch(this.props.codeSource){
              case "Ace":
              case "AceCpp":
                this.props.dispatch(modalActions.addModalMessageMid({
                  type: modalConstants.MODAL_MESSAGE_TYPE.SIMULATOR_LANG_NOT_SUPPORTED,
                  text: "Je ne sais pas simuler du code Cpp, Je connais que le Python"
                }));
                return;
              case "AcePython":
                code = this.props.freeCreation.codePy
              break;
              default:
                code = window.Blockly.Python.workspaceToCode(this.props.blockly.mainWorkspace);
              break;
            }
            this.props.dispatch(simulatorActions.updateCode(code));
            this.props.dispatch(simulatorActions.open());
        }catch(e){
            console.error(e);
        }
    }
  }

  render(){
    return (
      <Button color="teal" size={this.props.size||"medium"} labelPosition={!this.props.small&&'right'} title={this.props.small&&"Ouvrir le simulateur"} icon floated={this.props.floated||null} onClick={this.onClick}> 
        <Icon name='computer' /> 
        {!this.props.small && "Simulateur"}
      </Button>
    )
  }
}

function mapStateToProps(state) {
  return {
    simulator: state.simulator,
    blockly: state.blockly,
    freeCreation: state.freeCreation
  }
}

const connectedSimulatorButton = connect(mapStateToProps)(SimulatorButton)
export { connectedSimulatorButton as SimulatorButton }