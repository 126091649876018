import React, { Component } from 'react';
import { connect } from 'react-redux';

import { 
	logout,
} from '../../actions/session_actions';

class Logout extends Component {

	componentDidMount(){
		this.props.dispatch(logout())
	}
	
	
	render(){
		return (
			"Déconnexion..."
		)
	}
}

function mapStateToProps(state) {
	return {
		
	}
}

const connectedLogout = connect(mapStateToProps)(Logout)
export { connectedLogout as Logout }