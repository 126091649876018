import {guidedTourConstants} from "../constants";

const initialGuidedTourState = {
    guidedTourGoNextStep: false,
    hasSolution: false,
    stepIndex: 0,
    steps: [

        { //1
            title:"Bienvenue",
            content:"Bienvenue dans ce parcours d'introduction. Le but est de te faire découvrir l'environnement de création et le fonctionnement des parcours pédagogiques. Tu peux cliquer sur suivant.",
            target:"body",
            placement:"center",
            type:"hover",
            disableBeacon: true
        },
        { //2
            title:"Ajoute l'extension maintenant",
            content:"Il faut que tu ajoutes l'extension en cliquant sur \"Ajouter à Chrome\"",
            target:"[href=\"https://chrome.google.com/webstore/detail/thingz-flash/ifpbemhfgibeaednjoidchimcbphpacn\"]",
            placement:"bottom",
            type:"hover",
            disableBeacon: true,
            hideNextButton: true,
            disableOverlay: true
        },
        { // 3
            title:"MissThingette",
            content:"Je te présente MissThingette. Elle t'accompagnera dans l'aventure et sera toujours de bon conseil.",
            //target:"#channel",
            target:".message:not(.negative):nth-child(1)",
            placement:"auto",
            type:"hover",
            disableBeacon: true
        },
        { // 4
            title:"Des indices",
            content:"Lorsque tu te sentiras bloqué, tu pourras toujours demander un indice",
            target:"button.violet",
            placement:"top",
            type:"hover",
            disableBeacon: true
        },
        { // 5
            content:"Essaie de demander un indice pour découvrir le Doc. Il est un peu bavard mais son aide est précieuse.",
            target:"button.violet",
            placement:"right",
            type:"hover",
            disableBeacon: true,
            disableOverlay: true
        },
        { // 6
            title:"Doc",
            content:"Lui, c'est le Doc. Il adore expliquer les choses et débloquer les situations.",
            target:"span.message:nth-child(2)",
            placement:"auto",
            type:"hover",
            disableBeacon: true
        },
        { // 7
            title:"Boîte à outils",
            content:"Voici la boite à outils. Elle contient les blocs pour programmer ta base Thingz",
            target:".blocklyFlyout",
            placement:"auto",
            type:"hover",
            disableBeacon: true
        },
        { // 8
            title:"Les blocs Scratch",
            content:"Attention, il ne faut pas confondre \"blocs\" et \"briques\".",
            target:".blocklyFlyout",
            placement:"auto",
            type:"hover",
            disableBeacon: true
        },
        { // 9
            title:"Les briques",
            content:"Les briques, ce sont les pièces qui sont dans ta boîte et que tu brancheras sur ta base plus tard. Pour le moment tu n'en as pas besoin.",
            target:"body",
            placement:"center",
            type:"hover",
            disableBeacon: true
        },
        { // 10
            title:"Les blocs",
            content:"Alors que les blocs, ce sont les pièces que tu vois sur ton écran et que tu vas assembler comme un puzzle pour faire un programme. Pour le moment on ne s'occupe que de ça.",
            target:".blocklyFlyout",
            placement:"auto",
            type:"hover",
            disableBeacon: true
        },   
        { // 11
            title:"Le bloc de base",
            content:"Ici, c'est le bloc de base. Tous les autres blocs de ton programme devront s'accrocher à l'intérieur pour fonctionner.",
            target:".blocklyBlockCanvas",
            placement:"auto",
            type:"hover",
            disableBeacon: true
        },
        { // 12 ---à enlever
            title:"Les blocs orphelins",
            content:"Si tu ajoutes des blocs dans l'espace de création et qu'ils ne sont pas attachés au bloc de base, ils seront grisés et désactivés.",
            target:".blocklyBlockCanvas",
            placement:"auto",
            type:"hover",
            disableBeacon: true
        },
        { // 13
            title:"Au démarrage",
            content:"Pour le moment, on n'utilisera que la partie \"Au démarrage de la carte\" (l'encoche en haut du bloc)",
            target:".blocklyBlockCanvas",
            placement:"auto",
            type:"hover",
            disableBeacon: true
        },
        { // 14
            title:"Ton objectif",
            content:"Ton but, c'est d'activer une LED et un buzzer sonore au démarrage. Il se peut que tu n'ai qu'une seule des deux briques mais ce n'est pas grave, tu auras un résultat dans tous les cas, tu vas voir.",
            target:"body",
            placement:"center",
            type:"hover",
            disableBeacon: true,
        },
        // Ici on sort du mode de parcours traditionnel et désactivons l'overlay pour laisser l'utilisateur interagir
        // avec la boite à outte faire prendre connaissance de l'environement de travailils
        // target: document.querySelectorAll('[data-id=classic_led]')[0],
        { // 15
            title:"Bloc Led",
            content:"Voici le bloc Led. Il te permet d'interagir avec une Led branchée sur ta base Thingz",
            target:"[data-id=timed_led]",
            placement:"right",
            type:"hover",
            disableBeacon: true,
            hideBackButton: true,
        },
        { // 16 Sol1
            title:"La LED s'allume au démarrage",
            content:"Place le bloc Led dans l'encoche du haut : \"Au démarrage de la carte\".",
            target:".blocklyBlockCanvas",
            placement:"top",
            type:"hover",
            disableBeacon: true,
            disableOverlay: true,
            hideNextButton: true
        },
        { // 17
            content:"Super ! Maintenant voyons le bloc buzzer.",
            target:"body",
            placement:"center",
            type:"hover",
            disableBeacon: true,
            hideBackButton:true,
            //hideNextButton: true
        },
        { // 18
            title:"Le buzzer sonne au démarrage",
            content:"Voici le bloc Buzzer. Il te permet d'interagir avec un buzzer branché sur ta base Thingz",
            target:"[data-id=timed_buzzer]",
            placement:"right",
            type:"hover",
            disableBeacon: true,
            //hideNextButton: true
        },
        { // 19 Sol2
            title:"Le buzzer sonne au démarrage",
            content:"Place le bloc Buzzer dans la partie supérieure \"Au démarrage\". Tu vas voir, le bloc de base va s'agrandir pour lui faire de la place.",
            target:".blocklyBlockCanvas",
            placement:"top",
            type:"hover",
            disableBeacon: true,
            disableOverlay: true,
            hideNextButton: true
        },
        { // 20
            content:"Super ! Allez encore une dernière chose à découvrir !",
            target:"body",
            placement:"center",
            type:"hover",
            disableBeacon: true,
            hideBackButton:true,
            //hideNextButton: true
        },
        { // 21
            title:"Les blocs d'instructions",
            content:"Jusqu'ici, tu as créé une séquence avec deux blocs d'instructions.",
            target:"body",
            placement:"center",
            type:"hover",
            disableBeacon: true,
            //disableOverlay: true,
            //hideNextButton: true
        },
        { // 22
            title:"Les blocs \"paramètres\"",
            content:"Mais il existe aussi des blocs qui donnent des valeurs à d'autres blocs, le plus souvent des nombres ou des textes.",
            target:"body",
            placement:"center",
            type:"hover",
            disableBeacon: true,
            //disableOverlay: true,
            //hideNextButton: true
        },
        { // 23
            title:"Des notes de musique en paramètre",
            content:"En voilà un ! En paramètre du bloc buzzer, il permet de choisir facilement une note de musique à jouer.",
            target:"[data-id=buzzer_notes]",
            placement:"auto",
            type:"hover",
            disableBeacon: true,
            //disableOverlay: true,
            //hideNextButton: true
        },
        { // 24
            title:"Joue une note",
            content:"Fais glisser ce bloc à l'intérieur du bloc buzzer (à la place de 440Hz) pour jouer une note.",
            target:"[data-id=buzzer_notes]",
            placement:"auto",
            type:"hover",
            disableBeacon: true,
            disableOverlay: true,
            hideNextButton: true
        },
        { // 25
            title:"On teste tout ça ?",
            content:"Il est temps de brancher ta base à l'ordinateur avec le câble USB et de brancher une LED, un buzzer, ou les deux.",
            target:"body",
            placement:"center",
            type:"hover",
            disableBeacon: true,
            //disableOverlay: true,
            hideBackButton:true
            //hideNextButton: true
        },
        { // 26
            title:"Téléverser",
            content:"Si tu as un kit Thingz, tu peux tester ton programme en cliquant ici. Sinon clique sur suivant.",
            target:"#testProgramme",
            placement:"auto",
            hideBackButton:true,
            type:"hover",
            disableOverlay: true,
            disableBeacon: true,
        },
        { // 27
            title:"Passer à la suite",
            content:"Tu peux vérifier ta réponse et terminer ce premier parcours en cliquant sur \"Etape suivante\".",
            target:"#etapeSuivante",
            placement:"auto",
            type:"hover",
            disableBeacon: true,
            disableOverlay: true,
        },
        /*{ // 28
            title:"Parler à l'équipe Thingz",
            content:"Et enfin, si tu as des difficultés, un problème à signaler ou des retours à nous faire, tu peux nous contacter directement via le chat. Par exemple, tu peux nous dire ce que tu as pensé de ce premier parcours, on est impatient d'avoir des retours !",
            target:".CHAT",
            placement:"auto",
            type:"hover",
            disableOverlay: true,
            disableBeacon: true,
        },*/
    ],

    solutions: [
        {
            stepIndex: 15,
            xml: '<xml xmlns="http://www.w3.org/1999/xhtml"><variables></variables><block type="setup" id="^}g=8R{@GUF34m]]G;|n" x="29" y="105"><statement name="SETUP_DO"><block type="timed_led" id="{L*i^B]I[WD!v@rf{%?o"><field name="NOM">led1</field><value name="DURATION"><shadow type="math_number_block" id="2UZLTNa@Ds.soqQI9roE"><field name="NUM">1</field></shadow></value></block></statement></block></xml>'
        },
        {
            stepIndex: 18,
            xml: '<xml xmlns="http://www.w3.org/1999/xhtml"><variables></variables><block type="setup" id="{wk?]b.!7SdddcU.?zb=" deletable="false" x="0" y="0"><statement name="SETUP_DO" notOrder="true"><block type="timed_led" id="Mhs[/g}ykF_?fNXKl7@$"><field name="NOM">led1</field><value name="DURATION"><shadow type="math_number_block" id="WUDYN~LLWoZX;5,;kb{|"><field name="NUM" notStrict="true">1</field></shadow></value><next><block type="timed_buzzer" id="gH3.lP![T7Ft=QXI8of~"><field name="NOM">speaker1</field><value name="NOTE"><shadow type="math_number_block" id="IVG=rW^06P^/B@qBp4AA"><field name="NUM">440</field></shadow></value><value name="DURATION"><shadow type="math_number_block" id="s14WwfzuQl7-N5kW=#uV"><field name="NUM">1</field></shadow></value></block></next></block></statement></block></xml>'
           // xml: '<xml xmlns="http://www.w3.org/1999/xhtml"><variables></variables><block type="setup" id="^}g=8R{@GUF34m]]G;|n" x="29" y="105"><statement name="SETUP_DO" notOrder=true><block type="timed_led" id="{L*i^B]I[WD!v@rf{%?o"><field name="NOM">led1</field><value name="DURATION"><shadow type="math_number_block" id="2UZLTNa@Ds.soqQI9roE"><field name="NUM" notStrict=true>1</field></shadow></value><next><block type="timed_buzzer" id="2MyMHHte?WK44s:GAbrC"><field name="NOM">speaker1</field><value name="NOTE"><shadow type="math_number_block" id="Gf0]Y2d(.G1b*7M)jJ%4"><field name="NUM" notStrict=true>440</field></shadow></value><value name="DURATION"><shadow type="math_number_block" id="jlY*nz!!XJ2KFj|h([~y"><field name="NUM">1</field></shadow></value></block></next></block></statement></block></xml>'
        },
        {
            stepIndex: 23,
            xml: '<xml xmlns="http://www.w3.org/1999/xhtml"><variables></variables><block type="setup" id="^}g=8R{@GUF34m]]G;|n" x="29" y="105"><statement name="SETUP_DO" notOrder="true"><block type="timed_led" id="{L*i^B]I[WD!v@rf{%?o"><field name="NOM">led1</field><value name="DURATION"><shadow type="math_number_block" id="2UZLTNa@Ds.soqQI9roE"><field name="NUM" notStrict="true">1</field></shadow></value><next><block type="timed_buzzer" id="2MyMHHte?WK44s:GAbrC"><field name="NOM">speaker1</field><value name="NOTE"><shadow type="math_number_block" id="Gf0]Y2d(.G1b*7M)jJ%4"><field name="NUM" notStrict="true">440</field></shadow> <block type="buzzer_notes" id="9*hsG6_Y-.3.D:e1#H5h"><field name="NOTE" notStrict="true">DO</field></block></value><value name="DURATION"><shadow type="math_number_block" id="jlY*nz!!XJ2KFj|h([~y"><field name="NUM" notStrict="true">1</field></shadow></value></block></next></block></statement></block></xml>'
        }
            ],
    ignoreNextJoyrideCallback: false,
};


export const guidedTour = (state = initialGuidedTourState, action) => {
    switch (action.type) {

        case guidedTourConstants.NEXTSTEP:
            return {
                ...state,
                stepIndex: state.stepIndex + 1,
                hasSolution: action.hasSolution,
                ignoreNextJoyrideCallback: action.ignore
            }
        case guidedTourConstants.PREVIOUSSTEP:
            return {
                ...state,
                stepIndex: state.stepIndex - 1,
                hadSolution: action.hasSolution
            }
        case guidedTourConstants.ENABLE_JOYRIDE_CALLBACK:
            return {
                ...state,
                ignoreNextJoyrideCallback: false
            }

        default: {
            return state
        }
    }
}