import { modelConstants } from '../../constants';
import { selectedCoursesConstants } from '../../constants';


const initialState = {
    entities: {},
    results: [],
    error: null,
    isLoading: 0
};

export const selectedCourses = (state = initialState, action) => {
    switch (action.type) {
        case modelConstants.FETCH_ENTITIES_STARTED:
            if(action.entities === "selectedCourses")
                return {
                    ...state,
                    isLoading: state.isLoading + 1
                }
            return state;
        case modelConstants.FETCH_ENTITIES_DONE:
        case modelConstants.CREATE_ENTITY_DONE:
        case modelConstants.FETCH_ENTITY_DONE:
            if(action.entities.entities && action.entities.entities.selectedCourses){
                return {
                    ...state,
                    entities: mergeSelectedCourses(state.entities, action.entities.entities.selectedCourses),
                    error: null,
                    isLoading: action.originalEntities === "selectedCourses" && state.isLoading > 0? state.isLoading - 1 : state.isLoading
                }
            }else if(action.originalEntities === "selectedCourses"){
                return {
                    ...state,
                    entities: {},
                    isLoading: state.isLoading > 0 ? state.isLoading-1 : 0
                }
            }
            return state;
        case modelConstants.DELETE_ENTITY_DONE:
            if(action.entities.entities && action.entities.entities.selectedCourses){
                return {
                    ...state,
                    entities: action.entities.entities.selectedCourses,
                    error: null,
                    isLoading: action.originalEntities === "selectedCourses" && state.isLoading > 0? state.isLoading - 1 : state.isLoading
                }
            }else if(action.originalEntities === "selectedCourses"){
                return {
                    ...state,
                    entities: {},
                    error: null,
                    isLoading: state.isLoading > 0 ? state.isLoading-1 : 0
                }
            }
            return state;
        case selectedCoursesConstants.FETCH_ENTITIES_FAILED:
            return {
                ...state,
                error: action.error,
                isLoading: state.isLoading > 0 ? state.isLoading-1 : 0
            }
        case modelConstants.INVALIDATE_ENTITIES:
            return initialState;
        default:
            return state;
    }

}


function mergeSelectedCourses(currentCourses, newCourses){
    if(!newCourses)
        newCourses = {};
        
    for(let c in currentCourses){
        if(newCourses[c])
            continue;
        newCourses[c] = {};
        newCourses[c] = {...currentCourses[c]};
    }
    return newCourses;
}