import {schema} from 'normalizr'
import { finishedCourseEntity, courseEntity } from './entities.js';

finishedCourseEntity.define({
	course: courseEntity
})

export const finishedCourseSchema = finishedCourseEntity;

export const finishedCoursesSchema = new schema.Array(finishedCourseEntity);
