import { normalize } from 'normalizr'

import {modelConstants} from '../../constants'
import {usersConstants} from '../../constants'

import * as api from "../../api"

import {logout} from "../session_actions";

import {userSchema, usersSchema} from "../../schemas";

import history from '../../history'

export const usersActions = {
	deleteUser,
	addUser,
	removeUser,
	fetchUser,
	updateSpreadsheetUrl,
	updatedUser,
	updateGuest,
	me,
	createGuests
}

function fetchUser(id){
	return (dispatch, getState) => {
		dispatch({type: modelConstants.FETCH_ENTITY_STARTED, entities:"users"})
		return api.users.fetch(id).then(response => {
			const entities = normalize(response.data, userSchema);
			dispatch({type: modelConstants.FETCH_ENTITY_DONE, originalEntities:"users", entities: entities})
		})
		.catch(error => {
			console.log(error)
			dispatch({type: usersConstants.FETCH_USER_FAILED, error})
		})
	}
}

function me(){
	return (dispatch, getState) => {
		dispatch({type: modelConstants.FETCH_ENTITY_STARTED, entities:"users"})
		return api.users.me().then(response => {
			const entities = normalize(response.data, userSchema);
			dispatch({type: modelConstants.FETCH_ENTITY_DONE, originalEntities:"users", entities: entities})
		})
		.catch(error => {
			console.log(error)
			dispatch({type: usersConstants.FETCH_USER_FAILED, error})
		})
	}
}

function deleteUser(id, redirect){
	return (dispatch, getState) => {
		dispatch({type: modelConstants.DELETE_ENTITY_STARTED, entities: "users"})
		return api.users.remove(id).then(response => {
			let users = {...getState().model.users.entities};
			delete users[id];
			dispatch({type: modelConstants.DELETE_ENTITY_DONE, originalEntities: "users", entities: {entities: {users: users}}})
			if(redirect){
				history.push(redirect);
			}
			try{
				if(id === getState().session.user.user._id){
					dispatch(logout());
				}
			}catch(error){

			}
		})
		.catch(error => {
			dispatch({type: usersConstants.DELETE_USER_FAILED, error})
		})
	}
}

function addUser(user){
	return (dispatch, getState) => {
		// let users = getState().model.users.entities;
		// let structures = getState().model.structures.entities;
		// structures = {...structures};
		// if(!structures[user.structure].learners)
		// 	structures[user.structure].learners = [];
		// structures[user.structure].learners.push(user._id);
		
		let newUsers = normalize(user, userSchema)
		dispatch({type: modelConstants.FETCH_ENTITIES_DONE, entities: newUsers})
	}
}

function removeUser(user){
	return (dispatch, getState) => {
		let users = getState().model.users.entities;
		let structures = getState().model.structures.entities;
		users = {... users};
		structures = {... structures};

		for(let u in users){
			if(u === user._id){
				if(structures[users[u].structure] && structures[users[u].structure].learners){
					structures[users[u].structure].learners = structures[users[u].structure].learners.filter(u => {
						return u != user._id
					})
				}
				delete users[u];
			}
		}
		let entities = {entities:{users, structures}};
		dispatch({type: modelConstants.DELETE_ENTITY_DONE, entities: entities})

	}
}

function updateSpreadsheetUrl(id, url){
	return (dispatch, getState) => {
		dispatch({type: modelConstants.UPDATE_ENTITY_STARTED, entities:"users"});
		return api.users.updateSpreadsheetUrl(id, url).then(response => {
			let user = {...getState().model.users.entities[id]};
			user.spreadsheetUrl = response.data.spreadsheetUrl;
			let entities = normalize(user, userSchema);
			dispatch({type: modelConstants.UPDATE_ENTITY_DONE, originalEntities:"users", entities: entities});
		}).catch(error => {
			console.log(error)
			dispatch({type: usersConstants.UPDATE_FAILED, error})
		})
	}
}

function createGuests(guests, group){
	return (dispatch, getState) => {
		dispatch({type: modelConstants.CREATE_ENTITY_STARTED, entities:"users"});
		return api.users.addGuests(guests.filter(g => (g.username && g.username.length > 0)), group).then(response => {
			let structures = getState().model.structures.entities;
			let users = response.data.guests;

			structures = {...structures};

			users = users.map(user => {
				user.user.password = user.password;
				return user.user;
			})


			let entities = normalize(users, usersSchema);
			entities.entities.structures = structures
			
			dispatch({type: modelConstants.CREATE_ENTITY_DONE, originalEntities:"users", entities: entities});
			if(response.data.errors && response.data.errors.length > 0 )
				dispatch({type: usersConstants.CREATE_FAILED, error: response.data.errors.map(error => {return {message:error}})})
		}).catch(error => {
			console.log(error)
			dispatch({type: usersConstants.CREATE_FAILED, error})
		})
	}
}

function updateGuest(guest){
	return (dispatch, getState) => {
		dispatch({type: modelConstants.UPDATE_ENTITY_STARTED, entities:"users"})
		return api.users.update(guest._id, guest).then(response => {
			const entities = normalize(response.data, userSchema);
			dispatch({type: modelConstants.UPDATE_ENTITY_DONE, originalEntities:"users", entities: entities})
		})
		.catch(error => {
			console.log(error)
			dispatch({type: usersConstants.UPDATE_FAILED, error})
		})
	}
}

//Use to update a user through socket
function updatedUser(user){
	let newUsers = normalize(user, userSchema)
	return {type: modelConstants.UPDATE_ENTITY_DONE, entities: newUsers}
}

function mergeUsers(currentUsers, newUsers){
	if(!newUsers)
		newUsers = {};
	for(let c in currentUsers){
		if(newUsers[c])
			continue;
		newUsers[c] = {};
		newUsers[c] = Object.assign({}, currentUsers[c]);
	}
	return newUsers;
}