import React, { Component, Fragment} from 'react'

import {
    Popup,
    Dropdown,
    Button
  
  } from 'semantic-ui-react'

class ImportExportAppComponent extends Component {

    constructor(props){
        super(props)
        this.fileNames = {
            blockly: "programme.blockly",
            python: "programme.py"
        }
        this.state = {
            fileType: "blockly",
            importFormat: ".blockly",
            fileDownloadUrl: null,
          }
    }

    download = (type) => {
        let output = ""
        switch(type){
            case "blockly":
                output = this.props.xml
            break;
            case "blocklyPython":
                output = this.props.codeHybride
            break;
            case "python":
                output = this.props.codeAce
            break
        }
        const blob = new Blob([output]);
        const fileDownloadUrl = URL.createObjectURL(blob);
        this.setState ({fileDownloadUrl: fileDownloadUrl, fileType: type.includes("ython") ? "python" : "blockly"}, 
        () => {
            this.dofileDownload.click(); 
            URL.revokeObjectURL(fileDownloadUrl);  // free up storage--no longer needed.
            this.setState({fileDownloadUrl: ""})
        })
    }

    upload = (type) => {
        let format = ""
        switch(type){
            case "blockly":
                format = '.blockly'
            break;
            case "python":
                format = '.py'
            break;
        }
        this.setState({importFormat: format, fileType: type}, 
        () => {
            this.dofileUpload.click()
        }
        )
    }

    openFile = (evt) => {
        let status = []; // Status output
        const fileObj = evt.target.files[0];
        const reader = new FileReader();
            
        let fileloaded = e => {
            this.props.onImport(this.state.fileType, e.target.result)
            // console.log(e.target.result)
            this.dofileUpload.value = ''
        }
        
        // Mainline of the method
        fileloaded = fileloaded.bind(this);
        reader.onload = fileloaded;
        reader.readAsText(fileObj);  
    }

    render(){
        let exp = {
            blockly : {
                enable: this.props.context == 'blockly',
                text: "Exporter l'assemblage de blocs",
                title: "Exporter l'assemblage au format blockly",
                icon: "puzzle"
            },
            python: {
                enable: true,
                text: this.props.context == 'blockly' ? "Exporter l'assemblage de blocs au format Python" : "Exporter le code Python",
                title: this.props.context == 'blockly' ? "Génère le code associé à l'assemblage de blocs et exporte le code dans un fichier Python" : "Exporte le code dans un fichier Python",
                icon: "code"
            }
        }

        let imp = {
            blockly : {
                enable: true,
                text: "Importer un assemblage de blocs",
                title: "Importer un assemblage de blocs en utilisant un fichier au format .blockly",
                icon: "puzzle"
            },
            python: {
                enable: true,
                text: "Importer du code Python",
                title: "Importer un fichier Python",
                icon: "code"
            }
        }
        return (
            <React.Fragment>
                <Button.Group>
                    <Popup basic position="top center" trigger={
                        <Dropdown icon='download' className={"icon blue"} button >
                            <Dropdown.Menu direction="right">
                            {/* <Dropdown.Header icon='settings' content='Exporte' /> */}
                            {/* <Dropdown.Divider /> */}
                            
                                <Dropdown.Item  onClick={()=>{this.download("blockly")}} icon={exp.blockly.icon} title={exp.blockly.title} text={exp.blockly.text} disabled={!exp.blockly.enable} />
                                <Dropdown.Item  onClick={()=>{this.download(this.props.context == 'blockly' ? "blocklyPython" : "python")}} icon={exp.python.icon} title={exp.python.title} text={exp.python.text} disabled={!exp.python.enable} />
                            </Dropdown.Menu>
                        </Dropdown>
                    }>Exporter</Popup>
                    <Popup basic position="top center" trigger={
                        <Dropdown icon='upload' className={"icon blue"} button >
                            <Dropdown.Menu direction="right" style={{right: "unset"}}>
                            {/* <Dropdown.Header icon='settings' content='Exporte' /> */}
                            {/* <Dropdown.Divider /> */}
                            
                                <Dropdown.Item onClick={()=>{this.upload("blockly")}} icon={imp.blockly.icon} title={imp.blockly.title} text={imp.blockly.text} disabled={!imp.blockly.enable} />
                                <Dropdown.Item onClick={()=>{this.upload("python")}} icon={imp.python.icon} title={imp.python.title} text={imp.python.text} disabled={!imp.python.enable} />
                            </Dropdown.Menu>
                        </Dropdown>
                    }>Importer</Popup>
                </Button.Group>
                <a style={{display: 'none'}}
                    download={this.fileNames[this.state.fileType]}
                    href={this.state.fileDownloadUrl}
                    ref={e=>this.dofileDownload = e}
                >download it</a>
                <input type="file" style={{display: 'none'}}
                    multiple={false}
                    accept={this.state.importFormat}
                    onChange={evt => this.openFile(evt)}
                    ref={e=>this.dofileUpload = e}
                />
            </React.Fragment>
        )
    }
}

export { ImportExportAppComponent };