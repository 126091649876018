import { modelConstants, usersConstants } from '../../constants';


const initialState = {
    entities: {},
    isLoading: 0,
    error: null
};

export const users = (state = initialState, action) => {
	switch (action.type) {
    case modelConstants.CREATE_ENTITY_STARTED:
    case modelConstants.FETCH_ENTITIES_STARTED:
    case modelConstants.UPDATE_ENTITY_STARTED:
    case modelConstants.DELETE_ENTITY_STARTED:
    case modelConstants.FETCH_ENTITY_STARTED:
        if(action.entities === "users")
            return {
                ...state,
                isLoading: state.isLoading + 1,
                error: null
            }
        return state;
    case modelConstants.FETCH_ENTITIES_DONE:
    case modelConstants.CREATE_ENTITY_DONE:
    case modelConstants.FETCH_ENTITY_DONE:
        if(action.entities.entities && action.entities.entities.users){
            return {
                ...state,
                entities: mergeUsersAfterFetch(state.entities, action.entities.entities.users),
                isLoading: action.originalEntities === "users" && state.isLoading > 0 ? state.isLoading - 1 : state.isLoading,
                error: action.originalEntities === "users" ? null : state.error
            }
        }else if(action.originalEntities === "users"){
            return {
                ...state,
                isLoading: state.isLoading > 0 ? state.isLoading-1 : 0,
                error: null
            }
        }
        return state;
    
    case modelConstants.UPDATE_ENTITY_DONE:
        if(action.entities.entities && action.entities.entities.users){
            return {
                ...state,
                entities: mergeUsers(state.entities, action.entities.entities.users),
                isLoading: action.originalEntities === "users" && state.isLoading > 0 ? state.isLoading - 1 : state.isLoading,
                error: action.originalEntities === "users" ? null : state.error
            }
        }else if(action.originalEntities === "users"){
            return {
                ...state,
                isLoading: state.isLoading > 0 ? state.isLoading-1 : 0,
                error: null
            }
        }
        return state;
    case modelConstants.DELETE_ENTITY_DONE:
        if(action.entities.entities && action.entities.entities.users){
            return {
                ...state,
                entities: action.entities.entities.users,
                isLoading: action.originalEntities === "users" && state.isLoading > 0 ? state.isLoading - 1 : state.isLoading
            }
        }else if(action.originalEntities === "users"){
            return {
                ...state,
                isLoading: state.isLoading > 0 ? state.isLoading-1 : 0
            }
        }
        return state;
    case usersConstants.FETCH_USER_FAILED:
        return {
            ...state,
            error: action.error
        }
    case usersConstants.UPDATE_FAILED:
    case usersConstants.CREATE_FAILED:
        let error = action.error;
        try{
            error = action.error.response.data.message
        }catch(er){

        }
        return {
            ...state,
            isLoading: state.isLoading > 0 ? state.isLoading-1 : 0,
            error: error
        }
    case modelConstants.INVALIDATE_ENTITIES:
        return initialState;
    default:{
        return state;
    }
  }
}


function mergeUsers(currentUsers, newUsers){
    if(!newUsers)
        newUsers = {};
    for(let c in currentUsers){
        if(newUsers[c])
            continue;
        newUsers[c] = {};
        newUsers[c] = Object.assign({}, currentUsers[c]);
    }
    return newUsers;
}

function mergeUsersAfterFetch(currentUsers, newUsers){
    if(!newUsers)
        newUsers = {};
    else
        newUsers = {...newUsers}

    for(let c in currentUsers){
        if(newUsers[c]){
            Object.keys(currentUsers[c]).forEach(key => {
                if(!newUsers[c].hasOwnProperty(key) && currentUsers[c].hasOwnProperty(key)){
                    if(typeof currentUsers[c][key] === "object"){
                        if (typeof Array.isArray === 'undefined') {
                            Array.isArray = function(obj) {
                              return Object.prototype.toString.call(obj) === '[object Array]';
                            }
                        };
                        if(Array.isArray(currentUsers[c][key])){
                            newUsers[c][key] = [...currentUsers[c][key]]
                        }else if(currentUsers[c][key] === null){
                            newUsers[c][key] = null
                        }else{
                            newUsers[c][key] = {...currentUsers[c][key]}
                        }
                    } 
                    else{
                        let n = currentUsers[c][key]
                        newUsers[c][key] = n
                    }
                }
            })
        }else{
            newUsers[c] = {};
            newUsers[c] = {...currentUsers[c]};
        }
    }
    return newUsers;
}