import React, {Component} from 'react';
import {connect} from "react-redux";

import { Grid, Container, Message, Header, Image, Segment, List } from 'semantic-ui-react';

import Bandeau from "../../components/Bandeau/Bandeau";

import HeaderMain from "../../components/HeaderMain/HeaderMain";
import technicien from '../../img/technicien.svg'



class ErrorHandling extends Component {

    componentDidMount() {
        
    }

    componentDidUpdate(prevProps) {
        
    }

    renderError(){
        if(!this.props.error)
            return (<Message style={{display: "block"}}>
                <Message.Content>
                    <p>
                        Erreur inconnue
                    </p>
                </Message.Content>
            </Message>)
        if(!this.props.error instanceof Error){
            return (<Message style={{display: "block"}}>
                <Message.Content>
                    <p>
                        {this.props.error}  
                    </p>
                </Message.Content>
            </Message>)
        }
        return (<Message style={{display: "block"}}>
            <Message.Content>
                <p style={{margin:"10px"}}>
                    {this.props.error.toString()}
                </p>
                <p style={{margin:"10px"}}>
                    {this.props.error.stack}
                </p>
            </Message.Content>
        </Message>)
    }

    renderHelp(){
        return (
            <Container>
                <Message error style={{display: "block"}}>
                <Message.Header>Une erreur est survenue</Message.Header>
                <p>
                    Nous n'arrivons pas à afficher cette page... Essayez de:
                </p>
                <List bulleted>
                    <List.Item>recharger la page</List.Item>
                    <List.Item>vous déconnecter/reconnecter</List.Item>
                </List>
                <p>
                    Si l'erreur persiste, vous trouverez des détails ci dessous que vous pouvez nous communiquer via le chat ou par email à l'adresse <b>contact@thingz.co</b>
                </p>
                </Message>
            </Container>
        )
    }

    render() {
        return (
            <div style={{flex: 1}}>
                <HeaderMain dontFetch={true}/>
                <Bandeau/>
                <Grid stackable container relaxed style={{marginTop:'10px'}}>
                    <Grid.Row columns='equal'>
                        <Grid.Column>
                            <Segment>
                                <div style={{marginBottom: "10px"}}>
                                    <Image
                                        centered
                                        circular
                                        size={'tiny'}
                                        src={technicien}/>
                                    {/* <Header color="orange">Erreur</Header> */}
                                </div>
                                {this.renderHelp()}
                                {this.renderError()}
                            </Segment>
                        </Grid.Column>
                     
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
       
    }
}

const connectedErrorHandling = connect(mapStateToProps)(ErrorHandling);
export {connectedErrorHandling as ErrorHandling}