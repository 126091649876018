import React, { Component } from 'react'
import {connect} from 'react-redux'
import { bricksActions } from '../../actions/model';
import { usersBricksActions } from '../../actions';
import {getActualUser} from '../../reducers/user_reducer'

import {Brick} from './BrickComponent'

import {
	Container,
	Image,
	Segment,
	Header,
	Grid,
    Label,
    Icon,
    Modal,
	List,
	Item,
    Placeholder,
    Message,
    Table,
    Input,
    Form,
    Button,
    Divider,
    SegmentGroup,
} from 'semantic-ui-react'

import './Inventaire.css'
import { relativeTimeRounding } from 'moment';

class Inventaire extends Component {

    constructor(props){
        super(props)

        this.state = {
            isEditing: false,
        }

        // this.toggleEdition = this.toggleEdition.bind(this)
    }

    componentWillMount() {
        this.fetchAll()
    }

    fetchAll(){
        this.props.dispatch(bricksActions.fetchAll())
        this.props.dispatch(bricksActions.fetchAll())
        this.props.dispatch(usersBricksActions.fetchAll())
    }

    renderLoading(){
        let l = [];
        for(let i = 0; i<3; i++){
            l.push((<Placeholder key={i}>
                        <Placeholder.Header image>
                            <Placeholder.Line />
                        </Placeholder.Header>
                        <Placeholder.Paragraph>
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder.Paragraph>
                    </Placeholder>))
        }
        return l
    }
    
    renderUsersBricksComponents(){
        let usersBricksRender = this.props.usersBricks.bricks
        // .filter((brick) => { return brick.count > 0 })
        .map((brick, index) => {
            if (brick.count > 0)
                return (
                    <Grid.Column className='brickColumn' key={brick.brick.name}>
                        <Segment className='brickSegment'>
                            {brick.brick.name}
                            <span className='spanBrickCount'>{brick.count}</span>
                        </Segment>
                    </Grid.Column>
                )
        })

        return usersBricksRender
    }

    renderEditionForm(){
        
        const allBricks = this.props.bricks.bricks
        const usersBricks = this.props.usersBricks.bricks
        let usersBricksRendered = []
        let missingBricksRendered = []

        allBricks.forEach((brick) => {
            const userBrick = usersBricks.find((userBrick)=>{return userBrick.brick.id === brick.id})

            if(userBrick){
                usersBricksRendered.push(
                    <Grid.Column className='brickColumn' key={userBrick.brick.name}>
                        <Segment className='brickSegment'>
                            {userBrick.brick.name}
                            <Brick brick={userBrick}/>
                        </Segment>
                    </Grid.Column>
                )
            } else {
                const dummyBrick = {_id: null, count: 0, user: null, brick: brick}
                missingBricksRendered.push(
                    <Grid.Column className='brickColumn' key={dummyBrick.brick.name}>
                        <Segment className='brickSegment'>
                            {dummyBrick.brick.name}
                            <Brick brick={dummyBrick}/>
                        </Segment>
                    </Grid.Column>
                )
            }
        })

        let allBricksRendered = [...usersBricksRendered, ...missingBricksRendered].sort((a,b) => {
            if (a.key < b.key)
                return -1
            if (a.key > b.key)
                return 1
            return 0
            // return a.key - b.key
        })

        return allBricksRendered
    }

    renderFormSubmitButton(){
        return this.state.isEditing ? (
            <Button color='green' floated='right' onClick={(e) => this.handleSubmit(e)}>Sauvegarder les modifications</Button>
        ) : (
            <Button /*color='yellow'*/ floated='right' onClick={() => this.toggleEdition()}>Modifier mon inventaire</Button>
            )
    }

    handleSubmit(e){
        this.props.dispatch(usersBricksActions.saveModifications())

        this.toggleEdition()
    }

    toggleEdition(){
        this.setState({
            isEditing: !this.state.isEditing,
        })
    }

	render(){

        let isLoading = null
        this.props.usersBricks_isLoading || this.props.bricks_isLoading ? isLoading = true : isLoading = false
        
        let usersBricks = []
        if(this.props.usersBricks.bricks && this.props.bricks.bricks){
            usersBricks = this.props.usersBricks.bricks
        }

		return (
            <Grid>
                <Grid.Column width={16}>
                    <Grid.Row>
                        <Header>Mes briques{this.renderFormSubmitButton()}</Header>
                            {isLoading && this.renderLoading()}
				            <div style={{marginBottom:'30px'}}></div>

                            {usersBricks.length < 1 && !this.props.usersBricks_isLoading && !this.state.isEditing &&
                                <Message content="Tu n'as pas encore remplis ton inventaire" icon='object group' info/>
                            }

                            {usersBricks.length >= 1 && !this.props.usersBricks_isLoading && !this.state.isEditing &&
                                <Grid columns={2} container>
                                        {this.renderUsersBricksComponents()}
                                </Grid>
                            }
                            {!this.props.usersBricks_isLoading && this.state.isEditing &&
                                <Grid columns={2} container>
                                        {this.renderEditionForm()}
                                </Grid>
                            }

                            {this.state.isEditing && usersBricks.length === this.props.bricks.length &&
                                <Message content="Tu as toutes les briques Thingz" icon='attention' info />
                            }
                    </Grid.Row>
                </Grid.Column>
            </Grid>
		)
	}
}

function mapStateToProps(state) {
    return {
        user: getActualUser(state),
        bricks: state.model.bricks,
        bricks_isLoading: state.model.bricks.isLoading,
        usersBricks: state.usersBricks,
        usersBricks_isLoading: state.usersBricks.isLoading,
		// user: state.session.user,
	}
}

const connectedInventaire = connect(mapStateToProps)(Inventaire)
export { connectedInventaire as Inventaire }