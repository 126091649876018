import React, {Component} from 'react';
import * as sessionActions from '../actions/session_actions'
import HeaderMain from "../components/HeaderMain/HeaderMain";
import ForgotPasswordComponent from "../components/ForgotPasswordComponent/ForgotPasswordComponent";
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux';
import {getActualUser} from "../reducers/user_reducer";



class ForgotPassword extends Component{

    render(){
        document.title = "Mot de passe oublié - Thingz"
        return(
            <div style={{flex: 1, display:"flex", flexDirection:"column"}}>
                <HeaderMain/>
                <ForgotPasswordComponent user={this.props.user}
                                            actions={this.props.actions}
                                            error={this.props.forgotPassword.error}
                                            success={this.props.forgotPassword.success}
                                            isFetching={this.props.forgotPassword.isFetching}
                />
            </div>
        );
    }

}

const mapDispatch = (dispatch) => {
    return {
        actions: bindActionCreators(sessionActions, dispatch)
    };
};

const mapStateToProps = (state) => {
    return {
        user: getActualUser(state),
        forgotPassword:state.forgotPassword,
    }
}

ForgotPassword.propTypes = {};
export default connect(mapStateToProps, mapDispatch)(ForgotPassword);