import { extensionConstants } from '../constants';

const initialReceiveObject = {
  currentStep: 'start',
  rawData: null,
  type: null,
  length: null,
  data: null
}

const initialState = {
  port: null,
  error: null,
  pendingMsg: false,
  pendingCompilation: false,
  timeout: null,
  extensionVersion: null,
  compilationCode: null,
  reconnectionInterval: null,
  extensionId: null,
  isChrome: null,
  isComOpen: false,
  receiveObject: initialReceiveObject,
  autoConnect: true,
  boardStatus: "NO_CARD",
  useWebSerial: false,
  askPermissionModalState: false,
}

export function extension(state = initialState, action) {
  switch (action.type) {
    case extensionConstants.CONNECTED:
      return {
        ...state,
        port: action.port,
        reconnectionInterval: null
      }
    case extensionConstants.DISCONNECTED:
      return {
        ...state,
        port: null,
        pendingMsg: false,
        pendingCompilation:false,
        isComOpen: false,
        extensionVersion: -1,
        reconnectionInterval: action.reconnectionInterval,
        timeout: null
      }
    case extensionConstants.PENDING_MESSAGE:
      return {
        ...state,
        pendingMsg: true,
        error: null,
        timeout: action.timeout
      }
    case extensionConstants.PENDING_COMPILATION:
      return {
        ...state,
        pendingCompilation: true,
        compilationCode: action.code,
      }
    case extensionConstants.ERROR:
      return {
        ...state,
        pendingMsg: false,
        timeout: null,
        error: action.payload
      }
    case extensionConstants.EXTENSION_INSTALLED:
      return {
        ...state,
        pendingMsg: false,
        timeout: null,
        extensionVersion: action.version
      }
    case extensionConstants.EXTENSION_NOT_INSTALLED:
      return {
        ...state,
        pendingMsg: false,
        timeout: null,
        extensionVersion: -1
      }
    case extensionConstants.COMPILATION_FAILURE:
      return {
        ...state,
        pendingMsg: false,
        pendingCompilation: false,
        timeout: null,
        error: action.error
      }
    case extensionConstants.COMPILATION_SUCCESS:
    return {
      ...state,
      pendingMsg: false,
      pendingCompilation: false,
      timeout: null
    }
    case extensionConstants.RESET_EXTENSION_DONE:
    return {
      ...state,
      pendingMsg: false,
      timeout: null
    }
    case extensionConstants.UNKNOWN_MSG_RECEIVE:
    return {
      ...state,
      pendingMsg: false,
      timeout: null
    }
    case extensionConstants.RESET_COMPILATION_CODE:
    return{
      ...state,
      compilationCode: null
    }
    case extensionConstants.NEW_ID:
    return {
      ...state,
      extensionId: action.value
    }
    case extensionConstants.NOT_ON_CHROME:
    return {
      ...state,
      isChrome:0
    }
    case extensionConstants.NOT_ON_CHROME_DESKTOP:
    return {
      ...state,
      isChrome:1
    }
    case extensionConstants.IS_CHROME:
    return{
      ...state,
      isChrome:2
    }
    case extensionConstants.OPEN_ERROR:
    return {
      ...state,
      isComOpen:false,
      pendingMsg: false,
      boardStatus: action.status,
      error: action.error
    }
    case extensionConstants.OPEN_SUCCESS:
    return {
      ...state,
      isComOpen: true,
      pendingMsg: false,
      boardStatus: "CONNECTED",
      error: ""
    }
    case extensionConstants.UPDATE_RECEIVE_OBJ:
    return {
      ...state,
      receiveObject: action.receiveObject
    }
    case extensionConstants.TOGGLE_AUTOCONNECT:
    return {
      ...state,
      autoConnect: state.autoConnect ? false : true
    }
    case extensionConstants.CLOSE_SUCCESS: 
    return {
      ...state,
      isComOpen: false,
      boardStatus: "NO_CARD",
      error: "",
      pendingMsg: false
    }
    case extensionConstants.CLOSE_ERROR:
    return {
      ...state,
      error: action.error,
      isComOpen:false,
      pendingMsg: false
    }
    case extensionConstants.SEARCH_RESULT:
    return {
      ...state,
      isComOpen: false,
      boardStatus: action.status,
      error: "",
      pendingMsg: false
    }
    case extensionConstants.SEARCH_ERROR:
    return {
      ...state,
      isComOpen: false,
      error: action.error,
      pendingMsg: false
    }
    case extensionConstants.READ_ERROR:
    return {
      ...state,
      boardStatus: action.error,
      pendingMsg: false
    }
    case extensionConstants.UPDATE_TOKEN:
    return {
      ...state,
      token: action.token
    }
    case extensionConstants.TOGGLE_WEBSERIAL:
    return {
      ...state,
      useWebSerial: !state.useWebSerial
    }
    case extensionConstants.SET_WEBSERIAL:
    return {
      ...state,
      useWebSerial: action.enable
    }
    case extensionConstants.ASK_PERMISSION_MODAL_CHANGE_STATE:
    return {
      ...state,
      askPermissionModalState: action.state
    }
    default:
      return state
  }
}