import {schema} from 'normalizr'
import {clueSchema} from './clues_schema';
import { progressionEntity, stepEntity, userEntity } from './entities';

progressionEntity.define({
	step: stepEntity,
	user: userEntity,
	clues: [clueSchema]
})
export const progressionSchema = progressionEntity

export const progressionsSchema = new schema.Array(progressionSchema);
