import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Button,
  Icon,
} from 'semantic-ui-react'

import { 
  serialMonitorActions,
  extensionActions
} from '../../actions';

class SerialMonitorButton extends Component {

  onClick = () => {
    if (this.props.serialMonitor.open) {
      this.props.dispatch(serialMonitorActions.close());
    }else{
      this.props.dispatch(serialMonitorActions.open());
    }
  }

  render(){
    return (
      <Button disabled={!this.props.extension.isComOpen} color="green" size={this.props.size||"medium"} title="Ouvrir le moniteur" icon floated={this.props.floated||null} onClick={this.onClick}> 
        <Icon name='terminal' />  
      </Button>
    )
  }
}

function mapStateToProps(state) {
  return {
    serialMonitor: state.serialMonitor,
    extension: state.extension
  }
}

const connectedSerialMonitorButton = connect(mapStateToProps)(SerialMonitorButton)
export { connectedSerialMonitorButton as SerialMonitorButton }