import { modelConstants } from '../../constants';
import { coursesConstants } from '../../constants';


const initialState = {
    entities: {},
    order: null,
    error: null,
    success: null,
    isLoading: 0
};

export const courses = (state = initialState, action) => {
    // console.log("ACTION: ", action)
    switch (action.type) {
        case modelConstants.FETCH_ENTITIES_STARTED:
        case modelConstants.UPDATE_ENTITY_STARTED:
        case modelConstants.CREATE_ENTITY_STARTED:
        case modelConstants.DELETE_ENTITY_STARTED:
            if(action.entities === "courses")
                return {
                    ...state,
                    isLoading: state.isLoading + 1
                }
            return state;
        case modelConstants.FETCH_ENTITIES_DONE:
        case modelConstants.FETCH_ENTITY_DONE:
            if(action.entities.entities && action.entities.entities.courses){
                return {
                    ...state,
                    entities: mergeCoursesAfterFetch(state.entities, action.entities.entities.courses),
                    order: action.originalEntities === "courses" ? action.entities.result : state.order,
                    isLoading: action.originalEntities === "courses" && state.isLoading > 0 ? state.isLoading - 1 : state.isLoading
                }
            }else if(action.originalEntities === "courses"){
                return {
                    ...state,
                    isLoading: state.isLoading > 0 ? state.isLoading-1 : 0,
                    order: []
                }
            }
            return state;
        case modelConstants.UPDATE_ENTITY_DONE:
        case modelConstants.CREATE_ENTITY_DONE:
            if(action.entities.entities && action.entities.entities.courses){
                console.log(action.entities)
                return {
                    ...state,
                    entities: mergeCourses(state.entities, action.entities.entities.courses),
                    success: action.type === modelConstants.CREATE_ENTITY_DONE && action.originalEntities === "courses" ? `L'activité ${action.entities.entities.courses[action.entities.result].title} a bien été ajoutée` : state.success,
                    isLoading: action.originalEntities === "courses" && state.isLoading > 0 ? state.isLoading - 1 : state.isLoading
                }
            }else if(action.originalEntities === "courses"){
                return {
                    ...state,
                    isLoading: state.isLoading > 0 ? state.isLoading-1 : 0
                }
            }
            return state;
        case modelConstants.DELETE_ENTITY_DONE:
            if(action.entities.entities && action.entities.entities.courses){
                return {
                    ...state,
                    entities: action.entities.entities.courses,
                    isLoading: action.originalEntities === "courses" && state.isLoading > 0 ? state.isLoading - 1 : state.isLoading
                }
            }else if(action.originalEntities === "courses"){
                return {
                    ...state,
                    isLoading: state.isLoading > 0 ? state.isLoading-1 : 0
                }
            }
            return state;
        case coursesConstants.FETCH_UNLOCK_FAILED:
        case coursesConstants.FETCH_LOCK_FAILED:
        case coursesConstants.FETCH_ALL_FAILED:
        case coursesConstants.FETCH_ACTIVITY_FAILED:
        case coursesConstants.CREATE_FAILED:
        case coursesConstants.UPDATE_FAILED:
            return {
                ...state,
                error: action.error,
                isLoading: state.isLoading > 0 ? state.isLoading-1 : 0
            }
        case modelConstants.INVALIDATE_ENTITIES:
           return initialState;
        case modelConstants.CLEAR_STATUS:
            return {
                ...state,
                error:null,
                success:null,
                order: null,
            }
        default:
            return state;
    }

}

function mergeCourses(currentCourses, newCourses){
    if(!newCourses)
        newCourses = {};
        
    for(let c in currentCourses){
        if(newCourses[c])
            continue;
        newCourses[c] = {};
        newCourses[c] = {...currentCourses[c]};
    }
    return newCourses;
}

function mergeCoursesAfterFetch(currentCourses, newCourses){
    if(!newCourses)
        newCourses = {};
    else
        newCourses = {...newCourses}

    for(let c in currentCourses){
        if(newCourses[c]){
            Object.keys(currentCourses[c]).forEach(key => {
                if(!newCourses[c].hasOwnProperty(key) && currentCourses[c].hasOwnProperty(key)){
                    if(typeof currentCourses[c][key] === "object"){
                        if (typeof Array.isArray === 'undefined') {
                            Array.isArray = function(obj) {
                              return Object.prototype.toString.call(obj) === '[object Array]';
                            }
                        };
                        if(Array.isArray(currentCourses[c][key])){
                            newCourses[c][key] = [...currentCourses[c][key]]
                        }else if(currentCourses[c][key] === null){
                            newCourses[c][key] = null
                        }else{
                            newCourses[c][key] = {...currentCourses[c][key]}
                        }
                    } 
                    else{
                        let n = currentCourses[c][key]
                        newCourses[c][key] = n
                    }
                }
            })
        }else{
            newCourses[c] = {};
            newCourses[c] = {...currentCourses[c]};
        }
    }
    return newCourses;
}