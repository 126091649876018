/**
 * Array completing the CSS content for Blockly.
 */
export const BLOCKLY_CSS = [
    ".blocklyToolboxDiv {",
    "background-color: #fff;",
    "}",

    ".blocklyTreeRow {",
    "cursor: pointer;",
    "text-align: center;",
    "margin: 10px 0px 10px 4px;",
    "border-left-width: 4px !important;",
    "}",

    ".blocklyTreeLabel {",
    "color: #575E75;",
    "cursor: pointer;",
    "font-family: Helvetica Neue, Helvetica, sans-serif;",
    "}",

    ".zelos-renderer.thingz-theme .blocklyText, .zelos-renderer.thingz-theme .blocklyFlyoutLabelText {",
    "font-weight: 500;",
    "}",

    ".blocklyFlyoutBackground {",
    "fill: #F9F9F9;",
    "}",
];

