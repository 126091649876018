import React, { Component } from 'react';
import './BlocklyCourseConsultationComponent.css';
import { throws } from 'assert';

class BlocklyCourseConsultationComponent extends Component {

    constructor(props) {
        super(props);
        this.workspace = null;

        this.loadInProgressXml = this.loadInProgressXml.bind(this);
        this.loadFinishedXml = this.loadFinishedXml.bind(this);
    }

    componentDidMount(){
        this.setupBlockly();
    }

    componentDidUpdate(prevProps) {
        console.log(this.props)
        if(this.workspace && (this.workspace.blocksArea_.width !== document.getElementById("blocklyDiv").offsetWidth || this.workspace.blocksArea_.height !== document.getElementById("blocklyDiv").offsetHeight)){
            window.Blockly.svgResize(this.workspace);
        }
        
        if (prevProps.finished !== this.props.finished && this.props.finished && this.props.progression && this.props.finished.user === this.props.progression.user && this.props.showValidate) {
            this.loadFinishedXml();
        } 
        
        if (prevProps.progression !== this.props.progression && !this.props.showValidate) {
            this.loadInProgressXml();
        }

        // if (prevProps.showValidate !== this.props.showValidate) {
            this.props.showValidate ? this.loadFinishedXml() : this.loadInProgressXml();
        // }
    }

    componentWillUnmout() {
        if (this.props.onMainWorkspaceStatusChanged)
            this.props.onMainWorkspaceStatusChanged(null);

        if (this.props.onSolutionWorkspaceStatusChanged)
            this.props.onSolutionWorkspaceStatusChanged(null);
    }

    setupBlockly() {
        console.log("in setup")
        this.workspace = window.Blockly.inject('blocklyDiv', {
            media: '/assets/blockly/media/',
            comments: true,
            disable: true,
            collapse: false,
            zoom: {
                controls: true,
                wheel: true,
                startScale: 0.75,
                maxScale: 4,
                minScale: 0.25,
                scaleSpeed: 1.1
            },
            readOnly: true,
            scrollbars: true
        });

        if (this.props.onMainWorkspaceStatusChanged)
            this.props.onMainWorkspaceStatusChanged(this.workspace);
    }

    loadInProgressXml() {
        console.log("in load in progress", this.props)
        if(!this.props.progression)
            return
        this.workspace.clear();
        let xml = null;
        try {
            //We used to double stringify the xml (using JSON.stringify in updateAppAvancement while it's unecessary)
            //We don't know if the xml is double stringify or not so we try to parse it
            xml = JSON.parse(this.props.progression.xml);
        } 
        catch (e) {
            xml = this.props.progression.xml;
        }

        if (!xml) {
            xml = '<xml><variables></variables><block type="setup" id="v]2uT~hJkGG6ThL[}F!E" deletable="false" x="70" y="36"></block></xml>';

        }
        window.Blockly.Xml.domToWorkspace(window.Blockly.Xml.textToDom(xml), this.workspace);
        this.placeBlockly()
    }

    loadFinishedXml() {
        console.log("in load finish", this.props)
        if(!this.props.finished)
            return
        this.workspace.clear();
        let xml = null;
        try {
            xml = JSON.parse(this.props.finished.endedXml);
        } 
        catch (e) {
            xml = this.props.finished.endedXml;
        }
        console.log(xml)
        if (!xml) {
            xml = '<xml><variables></variables><block type="setup" id="v]2uT~hJkGG6ThL[}F!E" deletable="false" x="70" y="36"></block></xml>';

        }
        window.Blockly.Xml.domToWorkspace(window.Blockly.Xml.textToDom(xml), this.workspace);
        this.placeBlockly()
    }

    placeBlockly() {
        let mainBg = document.querySelector('.blocklyMainBackground');
        let blocklyCanvas = document.querySelector(".blocklyBlockCanvas");
        let blocks = document.querySelector(".blocklyBlockCanvas>g");
        if (blocks) {
            let width = (mainBg.getBoundingClientRect().width - blocklyCanvas.getBoundingClientRect().width) / 2;
            let heigth = (mainBg.getBoundingClientRect().height - blocklyCanvas.getBoundingClientRect().height) * 0.2;
            blocks.setAttribute('transform', `translate(${width},${heigth}) scale(1)`);
        }
        else {

        }
    }

    render() {
        return (
            <div style={{ height: 100 + '%', width: 100 + '%' }}>
                <div id="blocklyDiv" style={{ height: 100 + '%', width: 100 + '%' }}>
                </div>
            </div>
        )
    }
}

export { BlocklyCourseConsultationComponent };
