import React from 'react';
import Progression from "../ProgressionComponent/Progression";
// import Success from "../Success/Success";
// import SocialNetwork from "../SocialNetworkComponent/SocialNetwork";
import {
    Button,
    Divider,
    Menu,
    Icon
} from 'semantic-ui-react';

import { Link } from 'react-router-dom'

import './SideMenu.css'

const SideMenu = props => {

    return (
        <div>
            <Progression user={props.user} structure={props.structure} group={props.group} structureOwned={props.structureOwned}/>
            
            <Menu fluid vertical>
                <Menu.Item as={Link} to="/profil" name='Mon profil' icon="user" active={'profil' === props.pageName}/>
                {((props.user.role && props.user.role.type === "instructor") || props.structureOwned ) && 
                <React.Fragment>
                    <Menu.Item as={Link} to='/structure' name='Espace enseignant' icon="group"  active={'structure' === props.pageName} />
                    <Menu.Item as={Link} to='/store/activities' content="Bibliothèque d'Activités (beta)" icon="list"  active={'store_activities' === props.pageName} />
                </React.Fragment>}
                <Menu.Item as={Link} to='/courses' name='Parcours' icon="trophy" active={'courses' === props.pageName} />
                <Menu.Item as={Link} to='/creation' name='Création libre' icon='puzzle'/>
                <Menu.Item as={Link} to='/galaxia' name='Espace Galaxia' icon='rocket'/>
                <Menu.Item as={"a"} href='https://thingz.co' name='Boutique' icon="shop" />
                {((props.user.role && props.user.role.type === "root") ) && <Menu.Item as={Link} to='/list' name='Espace admin' icon="group"  active={'admin' === props.pageName} />}
            </Menu>

            <div style={{width:"100%", textAlign:"center"}}>
                <Button.Group style={{display: "inline-block"}}>
                    <Button as={"a"} href="https://twitter.com/wemakethingz" compact color='twitter' icon="twitter"/>
                    <Button as={"a"} href="https://www.facebook.com/wemakethingz/" compact color='facebook' icon="facebook"/>
                </Button.Group>
            </div>
        </div>
    );
};

SideMenu.propTypes = {};

export default SideMenu;