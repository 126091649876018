import React, {Component} from 'react';
import {connect} from "react-redux";

import {Progress, Popup, Statistic, Divider} from 'semantic-ui-react'

import {usersActions} from "../../actions/model";
import { getActualUser } from '../../reducers/user_reducer';
import { structureEntity } from '../../schemas/entities';


class StudentStats extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {
       this.fetch();
    }

    componentDidUpdate(prevProps){
        
    }

    fetch(){
        if(this.props.fetchStudent === false)
            return
        this.props.dispatch(usersActions.fetchUser(this.props.studentId));
    }

    renderElapsed(elapsed){
        let time = new Date(0);
        time.setHours(0)
        time.setMinutes(0)
        time.setSeconds(0)
        time.setSeconds(parseInt(elapsed))
       
        let str="";
        if(time.getHours())
            str+=time.getHours()+"h ";
        str+=time.getMinutes()+"min ";
        str+=time.getSeconds()+"s";
        return str
    }

    renderSummary(coursesProgess, coursesTotal, activitiesProgress, activitiesTotal, totalElapsed){
        return (
            <React.Fragment>
                <Popup content={"Avancement dans les parcours: "+Math.round((coursesTotal > 0 ? coursesProgess/coursesTotal : 0)*100)+" %"} trigger={<Progress value={coursesProgess} total={coursesTotal} indicating size='tiny'/>}/>
                <Popup content={"Avancement dans les activités: "+Math.round((activitiesTotal > 0 ? activitiesProgress/activitiesTotal: 0)*100)+" %"} trigger={<Progress value={activitiesProgress} total={activitiesTotal}  indicating size='tiny'/>}/>
            </React.Fragment>
        );
    }

    renderFull(coursesProgess, coursesTotal, activitiesProgress, activitiesTotal, coursesProgessions, studentFinishedCourses, activitiesProgressions, totalElapsed, studentSelectCourses){
       
        let finishedActivities = Object.values(studentFinishedCourses).reduce((prev, finished) => {
            if(this.props.courses.entities[finished.course] && this.props.courses.entities[finished.course].isActivity && studentSelectCourses.find(select => {return select.course === finished.course}))
                return prev+1
            return prev
        }, 0)

        let coursesCount = 0;
        let activitiesCount = 0;
        Object.values(this.props.courses.entities).forEach((course) => {
            if(!course.isActivity)
                coursesCount++;
            else{
                if(studentSelectCourses.find(select => {
                    return select.course === course._id
                })){
                    activitiesCount++
                }
            }
        })

        
        
        let finishedCourses = Object.values(studentFinishedCourses).reduce((prev, finished) => {
            if(this.props.courses.entities[finished.course] && !this.props.courses.entities[finished.course].isActivity)
                return prev+1
            return prev
        }, 0)

        return (
            <React.Fragment>
                <Statistic.Group widths={3} size="tiny">
                    <Statistic>
                        <Statistic.Value>{coursesProgess}/{coursesTotal}</Statistic.Value>
                        <Statistic.Label>Étapes de parcours réalisées</Statistic.Label>
                    </Statistic>
                    <Statistic>
                        <Statistic.Value>{activitiesProgressions.length}/{activitiesCount}</Statistic.Value>
                        <Statistic.Label>Activités en cours</Statistic.Label>
                    </Statistic>
                    <Statistic>
                        <Statistic.Value>{finishedActivities}/{activitiesCount}</Statistic.Value>
                        <Statistic.Label>Activités terminées</Statistic.Label>
                    </Statistic>

                    <Statistic>
                        <Statistic.Value>{coursesProgessions.length}/{coursesCount}</Statistic.Value>
                        <Statistic.Label>Parcours en cours</Statistic.Label>
                    </Statistic>
                    <Statistic>
                        <Statistic.Value>{finishedCourses}/{coursesCount}</Statistic.Value>
                        <Statistic.Label>Parcours terminés</Statistic.Label>
                    </Statistic>
                    <Statistic>
                        <Statistic.Value>{this.renderElapsed(totalElapsed)}</Statistic.Value>
                        <Statistic.Label>Temps passé </Statistic.Label>
                    </Statistic>
                </Statistic.Group>
            </React.Fragment>
       )
    }

    render() {

        //nb of steps started or done
        let coursesProgess=0
        //total nb of steps
        let coursesTotal=0
        //nb of activities started or done
        let activitiesProgress=0;
        //nb of activities
        let activitiesTotal=0;
        //total elapsed time spend on courses or activities
        let totalElapsed = 0;

        let coursesProgessions = [] 
        let activitiesProgressions = []

        let studentSelectCourses =[]
        const structure = Object.values(this.props.structures.entities).find(structure => {
            return structure.owner === this.props.user._id
        })

        if(structure){
            studentSelectCourses = Object.values(this.props.selectedCourses.entities).filter(select => {
                if(select.structure === structure._id || (this.props.users.entities[this.props.studentId] && select.group && select.group === this.props.users.entities[this.props.studentId].group)){
                    return true
                }
            })
        }

        let studentProgressions = Object.values(this.props.progressions.entities).filter(progression => {
            return progression.user === this.props.studentId
        })

        let studentFinishedCourses = Object.values(this.props.finishedCourses.entities).filter(finishedCourses =>  {
            return finishedCourses.user === this.props.studentId
        })

        studentProgressions.forEach(progression => {
            const step = this.props.steps.entities[progression.step];
            if(step){
                const course = this.props.courses.entities[step.course];
                if(course && !Object.values(studentFinishedCourses).find(finished => { return finished.course === course._id})){
                    if(course.isActivity){
                        if(studentSelectCourses.find(select => {
                            return select.course === course._id
                        })){
                            activitiesProgressions.push(progression)
                            if(progression.elapsed)
                                totalElapsed += progression.elapsed
                        }
                    }
                    else{
                        coursesProgessions.push(progression)
                        if(progression.elapsed)
                            totalElapsed += progression.elapsed
                    }
                }
            }
        })

        activitiesProgressions.forEach(progression => {
            const s = this.props.steps.entities[progression.step]
            if(s){
                const inc = s.currentPositionInCourse > 0 ? s.currentPositionInCourse -1 : 0
                activitiesProgress += inc
            }
        })

        coursesProgessions.forEach(progression => {
            const s = this.props.steps.entities[progression.step]
            if(s){
                const inc = s.currentPositionInCourse > 0 ? s.currentPositionInCourse -1 : 0
                coursesProgess += inc
            }
        })

        studentFinishedCourses = studentFinishedCourses.reduce((prev, obj) => {
            prev[obj.course] = obj
            return prev
        }, {})

        studentFinishedCourses = Object.values(studentFinishedCourses)

        studentFinishedCourses.forEach(finishedCourse => {
            const course = this.props.courses.entities[finishedCourse.course]
            if(course){
                if(course.isActivity){
                    if(studentSelectCourses.find(select => {
                        return select.course === course._id
                    })){
                        activitiesProgress += course.stepsCount || 1
                        if(finishedCourse.elapsed)
                            totalElapsed += finishedCourse.elapsed
                    }
                }else{
                    if(finishedCourse.elapsed)
                        totalElapsed += finishedCourse.elapsed
                    coursesProgess += course.stepsCount || 1
                }
            }
        })

        Object.values(this.props.courses.entities).forEach(course => {
            if(course.isActivity){
                if(studentSelectCourses.find(select => {
                    return select.course === course._id
                })){
                    activitiesTotal += course.stepsCount || 1 
                }
            }else{
                coursesTotal += course.stepsCount || 1
            }
        })

        switch(this.props.mode){
            case "summary":
                return this.renderSummary(coursesProgess, coursesTotal, activitiesProgress, activitiesTotal)
            case "elapsed":
                return this.renderElapsed(totalElapsed)
            default:
                return this.renderFull(coursesProgess, coursesTotal, activitiesProgress, activitiesTotal, coursesProgessions, studentFinishedCourses, activitiesProgressions, totalElapsed, studentSelectCourses)
        }
    }
}

const mapStateToProps = (state) => {
    return {
        structures: state.model.structures,
        groups: state.model.groups,
        progressions: state.model.progressions,
        finishedCourses: state.model.finishedCourses,
        courses: state.model.courses,
        steps: state.model.steps,
        users: state.model.users,
        user: getActualUser(state),
        selectedCourses: state.model.selectedCourses
    }
}

const connectedStudentStats = connect(mapStateToProps)(StudentStats);
export { connectedStudentStats as StudentStats }
