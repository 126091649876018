import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactTimeAgo from 'react-time-ago'
import DOMPurify from 'dompurify';

import {Feed, Item, Button, Icon, Card, Image, Container, Popup, Progress} from 'semantic-ui-react'
import no_image from './no_image.png'
import './CourseSummaryComponent.css'


class CourseSummary extends Component {

    constructor(props) {
        super(props);
        this.redirectToCourse = this.redirectToCourse.bind(this);
        this.redirectToConsultation = this.redirectToConsultation.bind(this);
        this.redirectToEditor=this.redirectToEditor.bind(this);
    }

    redirectToCourse() {
        this.props.history.push(`/course/${this.props.course._id}`);
    }

    redirectToConsultation() {
        let params = []
        if(this.props.user)
            params.push("u="+this.props.user._id)
        if(this.props.progression)
            params.push("p="+this.props.progression._id)
        if(this.props.finished)
            params.push("f="+this.props.finished._id)
        if(params.length){
            params = params.join('&')
            params = "?"+params
        }else{
            params = null
        }
        this.props.history.push(`/courseConsulation/${this.props.course._id}${params}`);
    }

    redirectToEditor(){
        console.log(this.props.course)
        this.props.history.push(`/editor/${this.props.course.id}`)
    }
    

    renderAchieved(){
        if(this.props.finished && this.props.finished.createdAt){
            let date = new Date(this.props.finished.createdAt)
            if(isNaN(date.getTime()))
                return;
            return (
                <div>
                    <div>Parcours terminé le: {date.toLocaleDateString("fr-FR")}</div>
                </div>
            )
        }
    }

    renderActivityArchieved() {
        if(this.props.finished && this.props.finished.createdAt){
            let date = new Date(this.props.finished.createdAt)
            if(isNaN(date.getTime()))
                return;
            return (
                <div>
                    <Popup trigger={<div> <Icon name="check"/> <ReactTimeAgo date={date} locale="fr"/></div>}>Activité validée <ReactTimeAgo date={date} locale="fr"/></Popup>
                </div>
            )
        }
    }

    renderActivityUpdated() {
        if (this.props.progression && this.props.progression.updatedAt) {
            let date = new Date(this.props.progression.updatedAt)
            if(isNaN(date.getTime()))
                return;
            return (
                <div>
                    <Popup trigger={<div><Icon name="history" flipped="horizontally"/> <ReactTimeAgo date={date} locale="fr"/></div>}>Dernière modification <ReactTimeAgo date={date} locale="fr"/></Popup>
                </div>
            )
        }
    }

    renderProgression() {
        if (!isNaN(this.props.currentStep)) {
            return (
                <div>
                    <Popup content={"Progression: "+Math.round((this.props.course.stepsCount > 0 ? this.props.currentStep/this.props.course.stepsCount : 0)*100)+" %"} trigger={<Progress style={{display: "flex", marginBottom:"3px"}} value={this.props.currentStep} total={this.props.course.stepsCount} indicating size='tiny'/>}/> 
                </div>
            )
        }
    }

    renderElapsed(){
        let time = new Date(0);
        time.setHours(0)
        time.setMinutes(0)
        time.setSeconds(0)
        if(this.props.finished && this.props.finished.elapsed){
            time.setSeconds(parseInt(this.props.finished.elapsed))
        }else if(this.props.progression && this.props.progression.elapsed){
            time.setSeconds(parseInt(this.props.progression.elapsed))
        }else{
            return null;
        }
        let str="";
        if(time.getHours())
            str+=time.getHours()+" heure(s) ";
        str+=time.getMinutes()+" minute(s) ";
        str+=time.getSeconds()+" seconde(s)";
        return (<Popup trigger={<div><Icon name="stopwatch" /> {str}</div>}>Temps passé</Popup>)
    }

    renderFeed() {
        if (this.props.avancement) {
            return (
                <Feed>
                    <Feed.Event>
                        <Feed.Label image={'../../img/main.png'}/>
                        <Feed.Summary>
                            Tu as commencé ce parcours il y a 3 jours
                        </Feed.Summary>
                    </Feed.Event>
                </Feed>
            )
        }
    }

    renderEditButton(){
    
        if(this.props.role && this.props.role.name==='Administrator'){
            
            return(
 
                <Button secondary floated={'right'} onClick={this.redirectToEditor} animated>
                    <Button.Content visible>
                     Editer <Icon name={'edit'}/>
                    </Button.Content>
                    <Button.Content hidden>
                        <Icon name={'edit outline'}/>
                    </Button.Content>
                </Button>
         
            )
        }
    }

    renderStartButton() {
        if (this.props.started === false && this.props.locked === false) {
            return (
                    <Button primary floated={'right'} onClick={this.redirectToCourse} animated>
                        <Button.Content visible>
                            Commencer <Icon name={'right chevron'}/>
                        </Button.Content>
                        <Button.Content hidden>
                            <Icon name={'arrow right'}/>
                        </Button.Content>
                    </Button>
            )
        }else if(this.props.locked){
            return (

                    <Button disabled primary floated={'right'} animated>
                        <Button.Content visible>
                            Commencer <Icon name={'right chevron'}/>
                        </Button.Content>
                        <Button.Content hidden>
                            <Icon name={'arrow right'}/>
                        </Button.Content>
                    </Button>

            )
        }else if(this.props.started){
            return (

                    <Button primary floated={'right'} onClick={this.redirectToCourse} animated>
                        <Button.Content visible>
                            Continuer <Icon name={'right chevron'}/>
                        </Button.Content>
                        <Button.Content hidden>
                            <Icon name={'arrow right'}/>
                        </Button.Content>
                    </Button>
          
            )
        }
        return null;
    }
    
    renderResetCourseButton(){
        if(!this.props.locked && this.props.started && !this.props.course.isActivity){
            return(
                <span onClick={this.deleteProgress} className="CourseSummaryResetButton">
                    <Icon name="undo" > </Icon>
                    Réinitialiser le parcours
                </span>
            )
        }
    }

    renderConsultationButton() {
        if (this.props.consultation) {
            if(this.props.consultation === "disabled"){
                const text = this.props.course.isActivity ? "Activité non commencée" : "Parcours non commencé"
                return (
                    <Popup content={text} trigger={
                        <span style={{float: "right"}}>
                            <Button disabled={true} primary onClick={this.redirectToConsultation} animated>
                                <Button.Content visible>
                                    Consulter <Icon name={'right chevron'}/>
                                </Button.Content>
                                <Button.Content hidden>
                                    <Icon name={'arrow right'}/>
                                </Button.Content>
                            </Button>
                        </span>
                    }/>
                )
            }
            return (
                <Button primary floated={'right'} onClick={this.redirectToConsultation} animated>
                    <Button.Content visible>
                        Consulter <Icon name={'right chevron'}/>
                    </Button.Content>
                    <Button.Content hidden>
                        <Icon name={'arrow right'}/>
                    </Button.Content>
                </Button>
            )
            
        }
    }

    renderWarningNotStarted(){
        const text = this.props.course.isActivity ? "Activité non commencée" : "Parcours non commencé"
        return <div>{text}</div>
    }

    deleteProgress = () => {
        this.props.callback(this.props.course.id)
        console.log(` summaryComponent log : ------- ${this.props.course.id} -------`)
    }

    render() {
        return (
            <Card fluid className="CourseSummaryItem">
                <Card.Content>
                    {!this.props.course.isActivity && !this.props.noImage && <Image size={'small'} floated={"left"} src={this.props.course.img || no_image}/>}
                    <Card.Header>
                        {this.props.renderingList ? this.props.user.username : this.props.course.title}
                    </Card.Header>
                
                    <Card.Meta>
                        <div>
                            {this.renderProgression()}
                        </div>
                        <div>
                            {this.props.course.isActivity ? this.renderActivityArchieved() : this.renderAchieved()}
                        </div>
                        <div>
                            {this.props.course.isActivity && this.props.update && this.renderActivityUpdated()}
                        </div>
                        
                        {this.props.showElapsed && this.renderElapsed()}
                        {this.props.consultation === "disabled" && this.renderWarningNotStarted()} 
                    </Card.Meta>
                    {!this.props.hideDescription && 
                    <Card.Meta className="CourseSummaryDescription">
                        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.course.description)}}>

                        </div>
                    </Card.Meta>}
                </Card.Content>

                <Card.Content>
                    {this.renderResetCourseButton()}
                    {this.renderStartButton()}
                    {this.renderEditButton()}
                    {this.renderConsultationButton()}
                </Card.Content>
            </Card>
        )
    }
}

CourseSummary.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

export default withRouter(CourseSummary);