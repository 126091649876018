import React, {Component} from 'react'
import {connect} from 'react-redux'

import {getActualUser} from "../../reducers/user_reducer"
import {
    freeCreationActions
} from '../../actions'

import  {
    Button,
    Header,
    Icon,
    Form,
    Input,
    Modal,
    Dimmer,
    Loader,
    Popup
} from 'semantic-ui-react'

import './HeaderFreeCreationControlsNewAppButton.css'

class HeaderFreeCreationControlsNewAppButton extends Component {

    constructor(props){
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit() {
        this.props.dispatch(freeCreationActions.setTemporaryApp())
    }

    button() {
        if(!this.props.online) {
            return this.buttonDisabled()
        }

        // L'application n'a pas d'Id ( n'a pas étée sauvegardée )
        if(!this.props.freeCreation.id){
            return this.buttonDisabled()
        }

        return this.buttonEnabled(this.handleSubmit)
    }

    buttonEnabled(onClickEvent) {
        return (
            <Popup basic position="top center" trigger={
            <Button onClick={onClickEvent} className="" icon color="blue" size="small">
                <Icon name='file' />
            </Button>
            }>
                Nouveau
            </Popup>
        )
    }

    buttonDisabled() { 
        return (
            <Popup basic position="top center" trigger={
                <Button className="" disabled icon color="blue" size="small">
                    <Icon name='file' />
                </Button>
                }>
                    Nouveau
                </Popup>
        )
    }

    render() {
        return this.button()
    }
}

function mapStateToProps(state) {
	return {
        user: getActualUser(state),
        freeCreation: state.freeCreation,
		freeCreation_isLoading: state.freeCreation.isLoading,
        apps: state.model.apps.apps,
	}
}

const connectedHeaderFreeCreationControlsNewAppButton = connect(mapStateToProps)(HeaderFreeCreationControlsNewAppButton)
export { connectedHeaderFreeCreationControlsNewAppButton as HeaderFreeCreationControlsNewAppButton }