import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {getActualUser} from '../../reducers/user_reducer'
import BlocksSummaryComponent from '../BlocksSummaryComponent/BlocksSummaryComponent'

import  {
    Button,
    Header,
    Label,
    Icon,
    Modal,
    List,
    Dimmer,
    Loader,
    ModalDescription,
    Grid,
    Input,
    Message,
    Popup
} from 'semantic-ui-react'

import { freeCreationActions } from '../../actions'
import { appsActions } from '../../actions/model'

import './HeaderFreeCreationControlsOpenAppModal.css'

class HeaderFreeCreationControlsOpenAppModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            openDeleteModal: false,
            toDeleteAppId: null,
        }
        this.handleOpening = this.handleOpening.bind(this)
        this.handleSelection = this.handleSelection.bind(this)
    }

    handleOpening() {
        this.props.dispatch(appsActions.getApps())
        this.props.dispatch(freeCreationActions.openModal())
    }

    handleSelection(id, event) {
        this.props.history.push(`/creation?app=${id}`)
        this.props.dispatch(freeCreationActions.selectCurrentApp(id))
        this.props.dispatch(freeCreationActions.synchroCodeAce())
    }

    triggerButton() {
        return(
            <Popup basic position="top center" trigger={
                <Button className="" icon size='small' color="blue" onClick={this.handleOpening} disabled={!this.props.online}>
                    <Icon name='folder open' />
                </Button>}
            >Ouvrir</Popup>)
    }

	filterApps(e){
		this.props.dispatch(appsActions.filterApps(e.target.value))
	}

    deleteApp = (event) => {
        if (this.props.freeCreation.id === this.state.toDeleteAppId) {
            this.props.dispatch(appsActions.deleteApp(this.state.toDeleteAppId, true, true));
        } else {
            this.props.dispatch(appsActions.deleteApp(this.state.toDeleteAppId, true));
        }
        this.closeDeleteModal(event);
    }
    
    openDeleteModal = (event) => {
        // bloque le lancement de isLoading dont le basculement d'état provoque la fermeture de la Modal princiale
        event.stopPropagation();
        this.setState({openDeleteModal: true});
    }

    closeDeleteModal = (event) => {
        // bloque la fermeture prématurée de la Modal principale
        event.stopPropagation();
        this.setState({openDeleteModal: false});
    }

    renderDeleteModal() {
        return (
            <Modal onClose={this.closeDeleteModal} open={this.state.openDeleteModal} dimmer="blurring" size='small'>
                <Header icon='trash' content="Confirmation de suppression" />
                <Modal.Content>
                    <p>
                        Si vous supprimez l'application, toutes les données liées à cette application seront perdues.
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color='grey' onClick={this.closeDeleteModal}>
                        <Icon name='remove' /> Annuler
                    </Button>
                    <Button color='red' onClick={(event) => this.deleteApp(event)}>
                        <Icon name='trash' />  Supprimer l'application
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
    
    render() {

        let isLoading = null
        this.props.apps_isLoading || this.props.freeCreation_isLoading ? isLoading = true : isLoading = false

        // sortir ce block de  code de la fonction render pour la positionner dans sa propre fonction et faciliter la lecture du composant
        let apps = []
        if(this.props.apps && this.props.apps.length < 1) {
            apps = [
                <Message key={0} style={{display: 'block'}} info>
                        Tu n'a pas encore créé d'application
                </Message>
            ]
        } else if(this.props.filteredApps && this.props.filteredApps.length < 1) {
			apps = [<Message visible>Aucune application ne correspond à tes critères de recherche</Message>]
		} else {
            if (this.props.filteredApps) {
                apps = this.props.filteredApps.map((app, index) => (
                    <List.Item key={app.id} onClick={(e) => this.handleSelection(app.id, e)}>
                        <List.Content>
                            <List.Header><h4>{ app.nom }</h4></List.Header>
                            <List.Description>
    
                            <Grid><Grid.Row>
                                <Grid.Column width={5}>
                                    Créé le : { new Date(app.createdAt).getDate() }/
                                                { new Date(app.createdAt).getMonth() < 9 ? '0' + (new Date(app.createdAt).getMonth() + 1) : new Date(app.createdAt).getMonth() + 1}/
                                                { new Date(app.createdAt).getFullYear() } à { new Date(app.createdAt).getHours() }:{ new Date(app.createdAt).getMinutes() < 10 ? `0${new Date(app.createdAt).getMinutes()}` : new Date(app.createdAt).getMinutes()}
                                    <br/>
                                    Modifié le : { new Date(app.updatedAt).getDate() }/
                                                { new Date(app.createdAt).getMonth() < 9 ? '0' + (new Date(app.createdAt).getMonth() + 1) : new Date(app.createdAt).getMonth() + 1}/
                                                { new Date(app.updatedAt).getFullYear() } à { new Date(app.updatedAt).getHours() }:{ new Date(app.updatedAt).getMinutes() < 10 ? `0${new Date(app.updatedAt).getMinutes()}` : new Date(app.updatedAt).getMinutes()}
                                </Grid.Column>
                                <Grid.Column width={10}>
                                    <Label.Group>
                                        <BlocksSummaryComponent app={app} />
                                    </Label.Group>
                                </Grid.Column>
                                <Grid.Column width={1}>
                                    <Icon name="trash" onClick={(event) => {this.openDeleteModal(event); this.setState({toDeleteAppId: app.id})}}></Icon>
                                </Grid.Column>
                            </Grid.Row></Grid>
    
                            </List.Description>
                        </List.Content>
                    </List.Item>
                ))
            }
            else{
                apps = [<Message visible>Aucune application ne correspond à tes critères de recherche</Message>]
            }
        }

        return (
            <Modal closeOnDimmerClick={true} onClose={() => this.props.dispatch(freeCreationActions.closeModal())} open={this.props.freeCreation.modalIsOpen} trigger={this.triggerButton()} /*closeIcon*/>
                <Modal.Header>
                    <h2>
                        Choisir une application
                        <Button className="rounded" icon="close" floated="right" color="red" onClick={() => this.props.dispatch(freeCreationActions.closeModal())}/>
                    </h2>
                </Modal.Header>
				<Modal.Header>
                    <Input onChange={(e)=>{this.filterApps(e)}} fluid size='mini' icon='search' placeholder='Rechercher...'
                        //    action={{ color: 'teal', labelPosition: 'right', icon: 'trash', content: 'Effacer' }}
                    />
                </Modal.Header>
                <Modal.Content scrolling>
                            {isLoading ? (
                                <Dimmer active inverted>
                                    <Loader/>
                                </Dimmer>
                            ) : (
                                <List selection animated divided relaxed>
                                    {apps}
                                </List>
                            )}
                </Modal.Content>
                <Modal.Actions></Modal.Actions>
                {this.renderDeleteModal()}
            </Modal>
        )
    }
}

function mapStateToProps (state) {
    return {
        user: getActualUser(state),
        freeCreation: state.freeCreation,
        apps: state.model.apps.apps,
        filteredApps: state.model.apps.filteredApps,
        apps_isLoading: state.model.apps.isLoading,
        freeCreation_isLoading: state.freeCreation.isLoading,

    }
}

const connectedHeaderFreeCreationControlsOpenAppModal = connect(mapStateToProps)(HeaderFreeCreationControlsOpenAppModal)
// export {connectedHeaderFreeCreationControlsOpenAppModal as HeaderFreeCreationControlsOpenAppModal}
export const HeaderFreeCreationControlsOpenAppModalWithRouter = withRouter(connectedHeaderFreeCreationControlsOpenAppModal)