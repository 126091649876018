import { pluggedBricksConstants } from '../constants';


export const pluggedBricksActions = {
    plug,
    unplug
};


function plug(brick){
	return {type: pluggedBricksConstants.PLUG, brick};
}

function unplug(brick){
	return {type: pluggedBricksConstants.UNPLUG, brick};
}