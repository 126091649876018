import {groupsConstants} from '../../constants'
import { modelConstants } from '../../constants';

export const initialGroups = {
    isLoading: null,
    error: null,
    entities: {
    }
}

export const groups = (state = initialGroups, action) => {
    switch (action.type) {
        case modelConstants.CREATE_ENTITY_STARTED:
        case modelConstants.FETCH_ENTITIES_STARTED:
        case modelConstants.UPDATE_ENTITY_STARTED:
        case modelConstants.DELETE_ENTITY_STARTED:
            if(action.entities === "groups"){
                if(action.type === modelConstants.UPDATE_ENTITY_STARTED || modelConstants.DELETE_ENTITY_STARTED){
                    if(action._id){
                        //set this entity to loading
                        if(state.entities[action._id]){
                            let e = {...state.entities};
                            e[action._id].loading = true;
                            return {
                                ...state,
                                isLoading: state.isLoading+1,
                                entities: e,
                                error:null
                            }
                        }
                    }
                }
                return {
                    ...state,
                    isLoading: state.isLoading + 1
                }
            }
            return state;
        case modelConstants.FETCH_ENTITIES_DONE:
        case modelConstants.CREATE_ENTITY_DONE:
        case modelConstants.UPDATE_ENTITY_DONE:
        case modelConstants.FETCH_ENTITY_DONE:
            if(action.entities.entities && action.entities.entities.groups){
                return {
                    ...state,
                    entities: mergeGroups(state.entities, {...action.entities.entities.groups}),
                    isLoading: action.originalEntities === "groups" && state.isLoading > 0 ? state.isLoading - 1 : state.isLoading
                }
            }else if(action.originalEntities === "groups"){
                return {
                    ...state,
                    isLoading: state.isLoading > 0 ? state.isLoading-1 : 0
                }
            }
            return state;
        case modelConstants.DELETE_ENTITY_DONE:
            if(action.entities.entities && action.entities.entities.groups){
                return {
                    ...state,
                    entities: action.entities.entities.groups,
                    isLoading: action.originalEntities === "groups" && state.isLoading > 0 ? state.isLoading - 1 : state.isLoading
                }
            }else if(action.originalEntities === "groups"){
                return {
                    ...state,
                    isLoading: state.isLoading > 0 ? state.isLoading-1 : 0
                }
            }
            return state;
        case modelConstants.INVALIDATE_ENTITIES:
            return initialGroups;
        case groupsConstants.CREATE_FAILED:
        case groupsConstants.UPDATE_FAILED:
        case groupsConstants.DELETE_FAILED:
            let newState = {...state};
            if(action._id){
                if(newState.entities[action._id]){
                    newState.entities = {...state.entities}
                    if(newState[action._id])
                        newState[action._id].loading = null;
                }
            }
            newState.isLoading = state.isLoading > 0 ? state.isLoading-1 : 0
            newState.error = action.error;
            return newState
        default :
            return state
    }
}

function mergeGroups(currentGroups, newGroups){
    if(!newGroups)
        newGroups = {};
    for(let c in currentGroups){
        if(newGroups[c])
            continue;
        newGroups[c] = {};
        newGroups[c] = {...currentGroups[c]};
    }
    return newGroups;
}