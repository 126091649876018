import { sessionConstants } from '../constants';

const initialState = {
  isFetching: false,
  error: null
}

export function register(state = initialState, action) {
  switch (action.type) {
    case sessionConstants.REGISTER:
      return {
        ...state,
        isFetching: true
      }
    case sessionConstants.REGISTER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null
      }
    case sessionConstants.REGISTER_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.error.response
      }
    case sessionConstants.REGISTER_RESET_STATE:
      return {
        ...state,
        isFetching: false,
        error: null
      }
    default:
      return state;
  }
}