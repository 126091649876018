import { socketIoConstants } from '../constants';

const initialState = {
  socket: null,
  connected: false,
  listeners: []
}

export function socketIo(state = initialState, action) {
  switch (action.type) {
    case socketIoConstants.INIT:
    return {
      ...state,
      socket: action.socket
    }
    case socketIoConstants.CONNECTED:
    return {
      ...state,
      connected: true
    }
    case socketIoConstants.DISCONNECTED:
    return {
      ...state,
      connected: false
    }
    case socketIoConstants.CLOSE:
    return {
      ...state,
      socket: null,
      connected: false
    }
    case socketIoConstants.UPDATE_LISTENERS:
    return {
      ...state,
      listeners: action.listeners
    }
    default:
      return state;
  }
}