import React, {Component} from 'react';
import {connect} from 'react-redux';

import {socketIoActions, notificationActions} from '../../actions';
import {usersActions} from '../../actions/model';

import {notificationConstants} from '../../constants';

class SocketIo extends Component {

	constructor(props){
		super(props);
	}

	componentDidMount(){
		// if((!this.props.socketIo.socket || !this.props.socketIo.connected) && this.props.user && this.props.user.jwt){
		// 	this.props.dispatch(socketIoActions.init())
		// }

		// setInterval( () => {
		// 	if(this.props.socketIo.socket){
		// 		console.log("emit")
		// 		this.props.socketIo.socket.emit('thingzEvent', { type: "USER_ACTIVITY"})
		// 	}
		// }, 5000)

	}

	componentDidUpdate(prevProps){
		if((!this.props.socketIo.socket || (prevProps.socketIo.connected && !this.props.socketIo.connected)) && this.props.user && this.props.user.jwt){
			this.props.dispatch(socketIoActions.init())
			this.props.dispatch(socketIoActions.addListener(this.handleSocket))
		}
	}

	componentWillUnmount(){
		if(this.props.socketIo.socket){
			this.props.socketIo.socket.disconnect();
			this.props.dispatch(socketIoActions.close())
		}
	}

	handleSocket = (data) => {
		switch(data.type){
	  case "STRUCTURE_ADD_LEARNER":
          this.props.dispatch(usersActions.addUser(data.learner))
          return true;
	  case "STRUCTURE_REMOVE_LEARNER":
          this.props.dispatch(usersActions.removeUser(data.learner))
          return true;
      case "STRUCTURE_ONLINE_LEARNER":
          this.props.dispatch(usersActions.updatedUser(data.learner))
          return true;
      case "STRUCTURE_ACTIVITY_LEARNER":
          let user = data.learner;
          if(this.props.users.entities[user._id]){
          	const lastSeen = user.lastSeen;
          	const online = user.online;

          	user = this.props.users.entities[user._id];
          	user.lastSeen = lastSeen;
          	user.online = online;
          }
          user.animate = true;
          this.props.dispatch(usersActions.updatedUser(user))
          user.animate = false;
          this.props.dispatch(usersActions.updatedUser(user))
		  return true;
	  case "SPREADSHEET_URL_NOT_FOUND":
		    this.props.dispatch(notificationActions.addNotificationMessage(data));
		  return true;
	  case "SPREADSHEET_ERROR_URL":
			this.props.dispatch(notificationActions.addNotificationMessage(data));
		  return true;
	  case "SPREADSHEET_FILE_NO_SHARE":
			this.props.dispatch(notificationActions.addNotificationMessage(data));
		  return true;
	  case "SPREADSHEET_FILE_READ_ONLY":
		 	this.props.dispatch(notificationActions.addNotificationMessage(data));
		  return true;
	  case "SPREADSHEET_SEND_DATA":
		 	this.props.dispatch(notificationActions.addNotificationMessage(data));
		  return true;
      default:
      return false;
  	}
	}

	render(){
		return null;
	}
}

function mapStateToProps(state) {
	return {
		user: state.session.user,
		socketIo: state.socketIo,
		users: state.model.users
	}
}

const connectedSocketIo = connect(mapStateToProps)(SocketIo);
export { connectedSocketIo as SocketIo}