import {structureConstants} from '../../constants'
import { modelConstants } from '../../constants';

export const initialUserStructure = {
    isLoading: null,
    error: null,
    entities: {}
}

export const structures = (state = initialUserStructure, action) => {
    switch (action.type) {
        case modelConstants.CREATE_ENTITY_STARTED:
        case modelConstants.FETCH_ENTITIES_STARTED:
        case modelConstants.UPDATE_ENTITY_STARTED:
        case modelConstants.DELETE_ENTITY_STARTED:
            if(action.entities === "structures")
                return {
                    ...state,
                    isLoading: state.isLoading + 1
                }
            return state;
        case modelConstants.FETCH_ENTITIES_DONE:
        case modelConstants.CREATE_ENTITY_DONE:
        case modelConstants.UPDATE_ENTITY_DONE:
        case modelConstants.FETCH_ENTITY_DONE:
            if(action.entities.entities && action.entities.entities.structures){
                return {
                    ...state,
                    entities: mergeStructures(state.entities, action.entities.entities.structures),
                    isLoading: action.originalEntities === "structures" && state.isLoading > 0 ? state.isLoading - 1 : state.isLoading
                }
            }else if(action.originalEntities === "structures"){
                return {
                    ...state,
                    isLoading: state.isLoading > 0 ? state.isLoading-1 : 0
                }
            }
            return state;
        case modelConstants.DELETE_ENTITY_DONE:
            if(action.entities.entities && action.entities.entities.structures){
                return {
                    ...state,
                    entities: mergeStructures(state.entities, action.entities.entities.structures),
                    isLoading: action.originalEntities === "structures" && state.isLoading > 0 ? state.isLoading - 1 : state.isLoading
                }
            }else if(action.originalEntities === "structures"){
                return {
                    ...state,
                    isLoading: state.isLoading > 0 ? state.isLoading-1 : 0
                }
            }
            return state;
        case modelConstants.INVALIDATE_ENTITIES:
            return initialUserStructure;
        case structureConstants.FETCH_FAILED:
            return {
                ...state,
                isLoading: state.isLoading > 0 ? state.isLoading-1 : 0,
                error: action.payload || action.error
            }
        default :
            return state
    }
}

function mergeStructures(currentStructures, newStuctures){
    if(!newStuctures)
        newStuctures = {};
    
    for(let c in currentStructures){
        if(newStuctures[c])
            continue;
        newStuctures[c] = {};
        newStuctures[c] = {...currentStructures[c]};
    }
    return newStuctures;
}