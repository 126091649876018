import React, {Component} from 'react';
import { Card, Grid, Message } from 'semantic-ui-react';

import InstructorActivitySummaryComponent from '../../../../components/InstructorActivitySummaryComponent/InstructorActivitySummaryComponent';


export default class CoursesTab extends Component{

    constructor(props){
        super(props)
    }

    renderCourses(){
        if(!this.props.courses || !this.props.courses.length)
            return (<Message>Aucun parcours disponible.</Message>)
       
        let courses = this.props.courses.map(course => {
            return <InstructorActivitySummaryComponent 
                key={course._id} 
                activity={course}
                progressions={this.props.progressions}
                selectedCourses={this.props.selectedCourses}
                finished={this.props.finishedCourses}
                steps={this.props.steps}
                users={this.props.users}
                user={this.props.user}
                structures={this.props.structures}
                groups={this.props.groups} />
        })
        return (
            <div>
                <Grid.Row>
                    <Card.Group>{courses}</Card.Group>
                </Grid.Row>
            </div>
        )
    }

    render(){
        
        return(
			<div className='pane' style={{height: "100%"}}>
				{this.renderCourses()}
			</div>
		)
    }
}