import React from 'react'
import {withRouter} from 'react-router-dom';
import {appsActions, coursesActions, progressionsActions} from '../../../actions/model';

import AppSummaryComponent from '../../../components/AppSummaryComponent/AppSummaryComponent';

import {
	Container,
	Image,
	Segment,
	Header,
	Grid,
    Label,
    Icon,
    Modal,
	List,
	Item,
	Placeholder,
	Tab,
	Menu,
	Message,
	Divider,
	Button,
	Input,

} from 'semantic-ui-react';
import  '../Profil.css'

const AppsTab = (props) => {
    
    function renderLoading(){
        let l = [];
        for(let i = 0; i<3; i++){
            l.push((<Placeholder key={i}>
                <Placeholder.Header image>
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Header>
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Paragraph>
            </Placeholder>))
        }
        return l
    }

    function redirectToCreation() {
        if(props.user && props.structureOwned && props.user.role.name === 'Instructor'){
            props.history.push(`/creation/${props.structureOwned.name}`)
        }else{
            props.history.push(`/creation`)
        }
	}
    
	function listApps(){
        let apps = []
		if(props.apps && props.apps.length < 1) {
				apps = [
					<Message key='message' style={{display: 'block'}}  info={props.user.confirmed ? true : false} warning={!props.user.confirmed ? true : false}>
						<Message.Header>
						{props.user && !props.user.confirmed ? (
							<span>Tu n'as pas validé ton adresse email</span>
						) : (
							<span>Tu n'as pas encore créé d'application</span>
						)}
						</Message.Header>
						<Divider hidden/>

						{props.user && !props.user.confirmed ? (
							<p>
								Pour accéder à tes créations ouvre l'email d'activation que nous t'avons envoyé.
								<br/>
								La sauvegarde en ligne du mode de création libre est réservée aux utilisateurs ayants validés l'adresse email de leur compte.					
								<br/>
								Ouvre l'email d'activation que nous t'avons envoyé.
							</p>
						) : (
							<p>
								Si tu es débutant je te conseille de faire le parcours d'introduction,
								il t'apprendra tout ce que tu as besoin de savoir pour bien débuter.
								<br/><br/>
								Sinon tu peux tout de suite te diriger vers l'espace de création libre.
							</p>
						)}
						<Divider hidden/>
						<Button compact onClick={() => {redirectToCreation()}} color='orange' basic size={'big'}>
							Création Libre
						</Button>
					</Message>
				]
		} else if(props.filteredApps.length > 0){
            apps = props.filteredApps.map((app, index) => (
				<AppSummaryComponent key={app._id} app={app}/>
			))
		} else {
			apps = [<Message visible>Aucune application ne correspond à tes critères de recherche</Message>]
		}
		return apps
	}

    function filterApps(e){
        props.dispatch(appsActions.filterApps(e.target.value))
    }

    return (
        <div className='pane'>
            <Divider horizontal hidden/>
            <Header>Mes Créations</Header>
            { props.apps && props.apps.length > 0 &&
                <Input onChange={(e)=>{filterApps(e)}} fluid icon='search' placeholder='Rechercher...'
                    //    action={{ color: 'teal', labelPosition: 'right', icon: 'trash', content: 'Effacer' }}
                />
            }
            <Item.Group>
                {props.apps_isLoading && renderLoading()}
                {props.user && listApps()}
            </Item.Group>
            <Divider section />
        </div>
    )
}

// export default AppsTab;
export const AppsTabWithRouter = withRouter(AppsTab)
