import React, {Component} from 'react';
import {extensionActions, modalActions, serialMonitorActions, pluggedBricksActions, notificationActions} from '../../actions';
import {extensionConstants, modalConstants} from '../../constants';
import {connect} from 'react-redux';
import * as api from "../../api";
import { Button, Header, Modal } from 'semantic-ui-react'

import {extensionWebSerial} from '../../libs/ExtensionWebSerial';

const thingzSerialProtocol = {
	    type: {
	        SYSTEM:1,
	        USER:2,
	        PLUGGED:3,
	        UNPLUGGED:4,
	        OVER_RECEIVING:5
	    }
}

class Extension extends Component {
	
	constructor(props){
		super(props);
		this.onExtensionMsg = this.onExtensionMsg.bind(this);
		this.onExtensionDisconnect = this.onExtensionDisconnect.bind(this);

		// this.extensionWorker = new WebWorker(worker);
		// this.extensionWorker.addEventListener('message', this.onWorkerMsg)

		this.timeoutInProgress = false;
		this.tmpLog = "";
		this.lastReceived = Date.now();

        this.extension = extensionWebSerial;
	}

	componentDidMount(){
        if(this.props.galaxia && this.props.extension.useWebSerial){
            extensionActions.configure("webserial")
            this.extension.init()
        }else{
            extensionActions.configure("extension")
        }
        if(!this.props.extension.port)
    	    this.props.dispatch(extensionActions.connect());
	}

	componentDidUpdate(prevProps, prevState){
        if(!prevProps.extension.port && this.props.extension.port || (this.props.extension.port && prevProps.extension.port != this.props.extension.port)){
            this.props.extension.port.onMessage.addListener(this.onExtensionMsg);
            this.props.extension.port.onDisconnect.addListener(this.onExtensionDisconnect);
            this.props.dispatch(extensionActions.sendMessage({msg:{cmd:"version"}, timeout:500}))
        }

        if(!prevProps.extension.extensionVersion === null && this.props.extension.extensionVersion !== -1){
            this.props.dispatch(extensionActions.sendMessage({msg:{cmd:"reset"}, timeout:500}))
        }

        if(prevProps.extension.useWebSerial !== this.props.extension.useWebSerial){
            if(this.props.extension.useWebSerial){
                extensionActions.configure("webserial")
                this.props.dispatch(extensionActions.disconnect())
                this.props.dispatch(extensionActions.connect());
                this.extension.init()
            }else{
                extensionActions.configure("extension")
                this.props.dispatch(extensionActions.disconnect())
                this.props.dispatch(extensionActions.connect());
            }
        }
	}

	componentWillUnmount() {
        this.props.dispatch(extensionActions.disconnect())
    }

	onExtensionMsg = (msg) => {
        this.props.dispatch(extensionActions.messageReceived(msg));
        this.messageDone(msg);
    }

    openOrSearchBoard = () => {
        if(this.props.extension.autoConnect){
            this.props.dispatch(extensionActions.sendMessage({msg:{cmd:'open', options:{bitrate:9600}, noProtocol:this.props.galaxia}, timeout:10000}))
        }else{
            this.props.dispatch(extensionActions.sendMessage({msg:{cmd:'search'}, timeout:10000}))
        }
	}

    formatErrorMsg(msg){
        if(msg && msg.compileError){
        if(msg.compileError.match(/test_thingz.ino:[0-9]+:[0-9]+: error:[^\^]*/g)){
            return msg.compileError.match(/test_thingz.ino:[0-9]+:[0-9]+: error:[^\^]*/g).toString().replace(/test_thingz.ino/g, 'ligne').substring(0, 3000);
        }else if(msg.compileError.match(/Traceback/g)){
            let b, e, s
            s = msg.compileError
            b = msg.compileError.indexOf('File "frozen/user.py",')
            e = msg.compileError.indexOf("make: ***")
            
            if(b !== -1)
            s = s.substring(b+'File "frozen/user.py",'.length)
            if(e !== -1)
            s = s.substring(0, s.indexOf("make: ***"));
            return s.substring(0, 3000);;
        }else{
            return msg.compileError.substring(0, 3000);
        }
        }else{
            return "Erreur de compilation"
        }
    }

    formatWrongBoardTypeMsg(msg){
        let str = "Une ";
        let board = msg.boardsNotFiltered.map(b => {
            switch(b.name){
                case "mega":
                case "mini":
                    return "base Thingz"
                case "uno":
                    return "Arduino Uno"
                case "galaxia":
                    return "carte Galaxia"
            }
        })
        str += board[0]
        str += " a été trouvée mais n'est pas compatible avec cette interface. Tu dois utiliser: "
        board = msg.filterBoards.map(b => {
            switch(b){
                case "mega":
                case "mini":
                    return "une base Thingz"
                case "galaxia":
                    return "une carte Galaxia"
            }
        })
        board = board.filter(b => {return b})
        board = [...new Set(board)]
        board = board.join(", ")

        str += board
        
        return str;

    }

    messageDone = (msg) => {
        console.log(msg);
  	    switch(msg.cmd){
            case "version":
                this.props.dispatch({type: extensionConstants.EXTENSION_INSTALLED, version: msg.value})
                this.openOrSearchBoard()
            break;
            case "compilation":
            case "write_files":
                api.flashes.post(msg);
                let segmentMsg = Object.assign({},msg);
                delete segmentMsg.log;

                if(msg.status === 0){
                    //Success
                    try{
                        window.analytics.track('Flash successful', {flash: segmentMsg})
                    }catch(error){
                        if(window.analytics)
                            window.analytics.track('Flash successful segment error', {error})
                    }
                    this.props.dispatch(modalActions.addModalMessageMid({
                        type: modalConstants.MODAL_MESSAGE_TYPE.COMPILATION_SUCCESS
                    }))
                    this.props.dispatch({type: extensionConstants.COMPILATION_SUCCESS, undefined})
                    this.openOrSearchBoard()
                }else{
                    //Error
                    try{
                        window.analytics.track('Flash failed', {flash: segmentMsg})
                    }catch(error){
                        if(window.analytics)
                            window.analytics.track('Flash failed segment error', {error})
                    }
                    switch(msg.value){
                        case "NO_REBOOT":
                            this.props.dispatch(modalActions.addModalMessageMid({
                                type: modalConstants.MODAL_MESSAGE_TYPE.NO_REBOOT,
                                text: msg.error
                            }));
                        break;
                        case "COMPILATION_ERROR":
                            this.props.dispatch(modalActions.addModalMessageMid({
                                type: modalConstants.MODAL_MESSAGE_TYPE.COMPILATION_ERROR,
                                text: this.formatErrorMsg(msg)
                            }));
                        break;
                        case "BAD_HEX_CHECKSUM":
                            this.props.dispatch(modalActions.addModalMessageMid({
                                type: modalConstants.MODAL_MESSAGE_TYPE.BAD_HEX_CHECKSUM,
                                text: msg.error
                            }));
                        break;
                        case "MORE_THAN_ONE_CARD":
                            this.props.dispatch(modalActions.addModalMessageMid({
                                type: modalConstants.MODAL_MESSAGE_TYPE.MORE_THAN_ONE_CARD,
                                text: msg.error
                            }));
                        break;
                        case "NO_CARD":
                            if(msg.boardsNotFiltered && msg.boardsNotFiltered.length > 0){
                                this.props.dispatch(modalActions.addModalMessageMid({
                                    type: modalConstants.MODAL_MESSAGE_TYPE.WRONG_TYPE_CARD,
                                    text: this.formatWrongBoardTypeMsg(msg)
                                }));
                            }else{
                                this.props.dispatch(modalActions.addModalMessageMid({
                                    type: modalConstants.MODAL_MESSAGE_TYPE.NO_CARD,
                                    text: msg.error
                                }));
                            }
                        break;
                        case "BOARD_NOT_BOOTED_AFTER_FLASH":
                            this.props.dispatch(modalActions.addModalMessageMid({
                                type: modalConstants.MODAL_MESSAGE_TYPE.BOARD_NOT_BOOTED_AFTER_FLASH,
                                text: msg.error
                            }));
                        break;
                        case "CANT_CONNECT":
                          this.props.dispatch(modalActions.addModalMessageMid({
                                type: modalConstants.MODAL_MESSAGE_TYPE.CANT_CONNECT,
                                text: msg.error
                            }));
                        break;
                        case "UNEXPECTED_CARD":
                          this.props.dispatch(modalActions.addModalMessageMid({
                                type: modalConstants.MODAL_MESSAGE_TYPE.UNEXPECTED_CARD,
                                text: msg.error
                            }));
                        break;
                        case "CONNECTION_LOST":
                          this.props.dispatch(modalActions.addModalMessageMid({
                                type: modalConstants.MODAL_MESSAGE_TYPE.CONNECTION_LOST,
                                text: msg.error
                            }));
                        break;
                        default:
                            this.props.dispatch(modalActions.addModalMessageMid({
                                type: modalConstants.MODAL_MESSAGE_TYPE.UNKNOWN_ERROR,
                                title: msg.value
                            }));
                        break
                    }
                    
                    this.props.dispatch({type: extensionConstants.COMPILATION_FAILURE, error: msg.error, value: msg.value});
                    this.openOrSearchBoard()
                }
            break;
            case "reset":
                this.props.dispatch({type: extensionConstants.RESET_EXTENSION_DONE, undefined})
            break;
            case "open":
                this.handleOpen(msg)
            break;
            case "read":
                this.handleRead(msg)
            break;
            case "search":
                this.handleSearch(msg)
            break;
            case "close":
               this.handleClose(msg)
            break;
            default:
                this.props.dispatch({type: extensionConstants.UNKNOWN_MSG_RECEIVE, undefined});
            break;

        }
    }

    handleOpen = (msg) => {
        switch(msg.value){
            case "MORE_THAN_ONE_CARD":
            case "NO_CARD":
            case "CANT_CONNECT":
            case "BOARD_DISCONNECTED":
            case "IN_BOOTLOADER":
                this.props.dispatch({type: extensionConstants.OPEN_ERROR, status: msg.value, error: msg.value});
                this.receiveObject = this.initialReceiveObject;

                setTimeout(() => {
                    if(!this.props.extension.isComOpen)
                        this.openOrSearchBoard()
                }, 1000)
            break;
            case "FLASH_INPROGRESS":
                this.props.dispatch({type: extensionConstants.OPEN_ERROR, error: msg.value});
                this.receiveObject = this.initialReceiveObject;

                setTimeout(() => {
                    if(!this.props.extension.isComOpen)
                        this.openOrSearchBoard()
                }, 1000)
            break;
            case "OPENED":
                this.props.dispatch(serialMonitorActions.clear());
                this.props.dispatch({type: extensionConstants.OPEN_SUCCESS})
            break;
            // case "ALREADY_OPEN":
            //     this.props.dispatch({type: extensionConstants.OPEN_ERROR, error: msg.value});
            // break;

        }
	}

	handleSearch = (msg) => {
        switch(msg.value){
            case "IN_BOOTLOADER":
            case "FOUND":
            case "NO_CARD":
            case "MORE_THAN_ONE_CARD":
                this.props.dispatch({type: extensionConstants.SEARCH_RESULT, status: msg.value});
                setTimeout(() => {
                    if(!this.props.extension.isComOpen)
                        this.openOrSearchBoard()
                }, 1000)
            break;
            case "FLASH_INPROGRESS":
                this.props.dispatch({type: extensionConstants.SEARCH_ERROR, error: msg.value});
                setTimeout(() => {
                    if(!this.props.extension.isComOpen)
                        this.openOrSearchBoard()
                }, 1000)
            break;
            case "ALREADY_OPENED":
            case "SEARCH_IN_PROGRESS":
                this.props.dispatch({type: extensionConstants.SEARCH_ERROR, error: msg.value});
            break
        }
	}

	handleClose =(msg) => {
        switch(msg.value){
            case "COM_NOT_OPENED":
            case "FLASH_INPROGRESS":
                this.props.dispatch({type: extensionConstants.CLOSE_ERROR, error: msg.value});
            break;
            case "SUCCESS":
            this.props.dispatch({type: extensionConstants.CLOSE_SUCCESS});
            setTimeout(() => {
                if(!this.props.extension.isComOpen)
                    this.openOrSearchBoard()
            }, 1000)
            break;
        }
	}

	handleRead = (msg) => {
		// this.extensionWorker.postMessage(msg)
		// if(msg.value === "OVER_RECEIVING"){
		// 	this.props.dispatch({type: extensionConstants.READ_ERROR, error: msg.value})
		// 	this.props.dispatch(modalActions.addModalMessageMid({
  //                               type: modalConstants.MODAL_MESSAGE_TYPE.OVER_RECEIVING,
  //                               text: msg.error
  //                           }));
		// }
		msg.value.forEach(this.processBoardMessage)
	}

    idToName = id => {
        switch(id){
        case 1:return {name:"led", prettyName:"Led"};
        case 2:return {name:"weather", prettyName:"Météo"};
        case 4:return {name: "button", prettyName:"Bouton"};
        case 6:return {name:"buzzer", prettyName:"Buzzer"};
        case 8:return {name:"7segment", prettyName:"Chiffre"};
        case 10:return {name:"infrared", prettyName:"infrarouge"};
        case 11:return {name:"bluetooth", prettyName:"Bluetooth"};
        case 12:return {name:"hacker", prettyName:"Hacker"};
        case 13:return {name:"motion", prettyName:"Détecteur de mouvement"};
        case 21:return {name:"motion", prettyName:"Détecteur de mouvement"};
        case 14:return {name:"screen", prettyName:"Ecran"};
        case 15:return {name:"potentiometer", prettyName:"Potentiomètre"};
        case 16:return {name:"socket", prettyName:"Prise"};
        case 17:return {name:"luminosity", prettyName:"Détecteur de luminosité"};
        case 18:return {name:"makey", prettyName:"Détecteur tactile"};
        case 22:return {name:"colorScreen", prettyName:"Écran couleur"};
        case 23:return {name:"wifi", prettyName:"Wifi"};
        case 24:return {name:"weatherDS18", prettyName:"Météo"};
        default: return {name:"unknown", prettyName:""};
        }
    }

	processBoardMessage = receiveObject => {
		// console.log(receiveObject)
        let id;
        let idToName
        console.log(receiveObject)
		switch(receiveObject.type){
           // case thingzSerialProtocol.type.SYSTEM:
           // case thingzSerialProtocol.type.PLUGGED:
           // case thingzSerialProtocol.type.UNPLUGGED:
           case thingzSerialProtocol.type.USER:
               this.tmpLog += receiveObject.data;
               // console.log(this.tmpLog)
               // if(tmpLog.length > 2000){
               //     tmpLog = tmpLog.substring(tmpLog.length - 1500);
               // }
               
               if(!this.timeoutInProgress){
                   this.timeoutInProgress = true;
                   setTimeout(() => {
                       this.props.dispatch(serialMonitorActions.push(this.tmpLog))
                       this.timeoutInProgress = false;
                       this.tmpLog = null;
                       this.tmpLog = "";
                   }, 200)
               }
               this.lastReceived = Date.now();
               
           break;
           case thingzSerialProtocol.type.OVER_RECEIVING:
           	this.props.dispatch({type: extensionConstants.READ_ERROR, error: "OVER_RECEIVING"})
						this.props.dispatch(serialMonitorActions.push("Ta carte envoie trop de message, ajoute des \"attendre()\" pour corriger le problème\n"))
           break;
           case thingzSerialProtocol.type.PLUGGED:
             id = parseInt(receiveObject.data);
             idToName = this.idToName(id);
             this.props.dispatch(pluggedBricksActions.plug({id, name:idToName.name, prettyName:idToName.prettyName}));
             this.props.dispatch(notificationActions.addNotificationMessage({type: 'BRICK_PLUGGED', format:'success', id, prettyName:idToName.prettyName}));
           break;
           case thingzSerialProtocol.type.UNPLUGGED:
              id = parseInt(receiveObject.data);
              idToName = this.idToName(id);
             this.props.dispatch(pluggedBricksActions.unplug({id}));
             this.props.dispatch(notificationActions.addNotificationMessage({type: 'BRICK_UNPLUGGED', format:'success', id, prettyName:idToName.prettyName}));
           break;
       }
    }
    
    askPermissionModalClose = () => {
        this.props.dispatch(extensionActions.askPermissionModalChangeState(false));
    }

    askPermissionModalOpen = () => {
        this.props.dispatch(extensionActions.askPermissionModalChangeState(true));
    }

    onExtensionDisconnect = () => {
        this.props.dispatch(extensionActions.disconnected());
    }

    onPair = () => {
        this.extension.askNewPermision(this.props.galaxia ? ["galaxia"] : ["uno", "mini", "mega"])
    }

	render(){
        return (
            <Modal
            // onClose={() => setOpen(false)}
            // onOpen={() => setOpen(true)}
            open={this.props.extension.askPermissionModalState}
            >
                <Modal.Header>Demande d'autorisation</Modal.Header>
                <Modal.Content image>
                    <Modal.Description>
                        <Header size="small">Nous avons besoin de ton autorisation pour accéder à ta carte</Header>
                        <p>
                            Clique sur le bouton "<b>Appairer</b>", séléctionne ta carte puis clique sur "<b>Connecter</b>"
                        </p>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' onClick={this.onPair}>
                        Appairer
                    </Button>
                    {/* <Button
                    content="Yep, that's me"
                    labelPosition='right'
                    icon='checkmark'
                    onClick={() => setOpen(false)}
                    positive
                    /> */}
                </Modal.Actions>
            </Modal>
        )
	}
}

function mapStateToProps(state) {
	return {
		extension: state.extension,
		serialMonitor: state.serialMonitor
	}
}

const connectedExtension = connect(mapStateToProps)(Extension);
export { connectedExtension as Extension}
