import React, {Component} from 'react';
import {connect} from "react-redux";


import {Loader, Dimmer} from 'semantic-ui-react'

import {getActualUser} from "../../reducers/user_reducer";

import {modelActions, structuresActions, usersActions, coursesActions, stepsActions, groupsActions, selectedCoursesActions} from "../../actions/model";

import {PageWithSideMenu} from "../PageWithSideMenu";

class Admin extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount(prevProps) {
        document.title = 'Admin - Thingz'
        if(this.props.user){
            this.fetch();
        }
    }

    componentDidUpdate(prevProps){
        
    }

    componentWillUnmount(){
        this.props.dispatch(modelActions.invalidate())
    }

    fetch(){
        this.props.dispatch(structuresActions.fetchStructures());
        this.props.dispatch(selectedCoursesActions.fetchAllForStructure());
        this.props.dispatch(coursesActions.fetchForUser());
    }

    
    renderLoading(){
        return (
            <PageWithSideMenu dontFetch={true} pageName={"admin"}>
                <Dimmer active inverted>
                    <Loader inverted>Chargement...</Loader>
                </Dimmer>
            </PageWithSideMenu>
        )
    }

    render() {
        return (
            <PageWithSideMenu dontFetch={true} pageName={"admin"}>
            
            </PageWithSideMenu>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: getActualUser(state),
        structures: state.model.structures,
        users: state.model.users,
        courses: state.model.courses,
        groups: state.model.groups,
        selectedCourses: state.model.selectedCourses
    }
}

const connectedAdmin = connect(mapStateToProps)(Admin);
export { connectedAdmin as Admin }
