import * as api from "../../api";

import { selectedCoursesConstants } from '../../constants';
import { modelConstants } from '../../constants';

import { selectedCoursesSchema } from '../../schemas';

import {normalize} from 'normalizr'

export const selectedCoursesActions = {
    fetchAllForStructure,
    updateForCourse,
}

function fetchAllForStructure(){
	return dispatch => {
		dispatch({type: modelConstants.FETCH_ENTITIES_STARTED, entities:"selectedCourses"});
		return api.selectedCourses.fetchAllForStructure().then(response => {
			dispatch({type: modelConstants.FETCH_ENTITIES_DONE, originalEntities:"selectedCourses", entities: normalize(response.data, selectedCoursesSchema)});
		}).catch(err => {
			console.log(err)
			if(err.response && err.response.data && err.response.data.message)
				dispatch({type: selectedCoursesConstants.FETCH_ENTITIES_FAILED, error: err.response.data.message})
			else
            	dispatch({type: selectedCoursesConstants.FETCH_ENTITIES_FAILED, error: err})
        })
	}
}

function updateForCourse(courseId, access){
    return dispatch => {
        dispatch({type: modelConstants.UPDATE_ENTITY_STARTED, entities:"selectedCourses"});
        return api.selectedCourses.updateForCourse(courseId, access).then(response => {
            let entities = normalize(response.data, selectedCoursesSchema)
            let selectedcoursesOnly = normalize(response.data, selectedCoursesSchema)
            selectedcoursesOnly.entities = {selectedCourses: {...selectedcoursesOnly.entities.selectedCourses}}
            // console.log(selectedcoursesOnly)
            entities.entities.selectedCourses = null;
            //merging selectedcourse's associated entities without removing those not in this response
            dispatch({type: modelConstants.FETCH_ENTITIES_DONE, entities: entities})
            //We want to erase old selected courses not merging them with the new, so we do a delete
            dispatch({type: modelConstants.DELETE_ENTITY_DONE, originalEntities:"selectedCourses", entities: selectedcoursesOnly});
        })
    }
}