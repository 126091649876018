import React, {memo} from 'react';
import { View, Document, Page, Text, PDFDownloadLink, pdf } from '@react-pdf/renderer'

const GuestsList = (props) => {

    const styles = {
        body: {
            padding: 10
          },
          table: { 
            display: "table", 
            width: "auto", 
            borderStyle: "solid", 
            borderColor: '#bfbfbf',
            borderWidth: 1, 
            borderRightWidth: 0, 
            borderBottomWidth: 0 
          }, 
          tableRow: { 
            margin: "auto", 
            flexDirection: "row" 
          }, 
          tableColHeader: { 
            width: "33%", 
            borderStyle: "solid", 
            borderColor: '#bfbfbf',
            borderBottomColor: '#000',
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0
          },   
          tableCol: { 
            width: "33%", 
            borderStyle: "solid", 
            borderColor: '#bfbfbf',
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0 
          }, 
          tableCellHeader: {
            margin: "auto", 
            margin: 5, 
            fontSize: 12,
            fontWeight: 500
          },  
          tableCell: { 
            margin: "auto", 
            margin: 5, 
            fontSize: 10 
          }
    };

    let pdfTable = props.users.map(user => {
        return (
            <View key={user._id} style={styles.tableRow}> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>{user.username}</Text> 
                </View> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>{user.password}</Text> 
                </View> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>{user.group && props.groups[user.group] ? props.groups[user.group].name : ''}</Text> 
                </View> 
            </View> 
        )
    })

    const PDF = () => {
        return (
            <Document>
                <Page style={styles.body}>
                <View>
                    <Text>Liste d'élèves de {props.structureName}</Text>
                </View>
                <View>
                    <Text style={{fontSize:11, marginTop:'5px', marginBottom:"20px"}}>Générée le: {new Date().toLocaleDateString("fr-FR")}</Text>
                </View>
                <View style={styles.table}> 
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>Nom d'utilisateur</Text> 
                        </View> 
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>Mot de passe</Text> 
                        </View> 
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>Groupe</Text> 
                        </View> 
                    </View>
                    {pdfTable}
                </View>
                </Page>
            </Document>
        )
    }

    return (
        <PDFDownloadLink document={<PDF />} fileName={props.filename}>
			{({ blob, url, loading, error }) => {
				if(!loading){
                    if(props.autoDownload){
                        props.autoDownload(blob)
                    }
                }
				return loading ? 'Génération du pdf...' : 'Re-télécharger le pdf'
			}}
		</PDFDownloadLink>
    )
}

export const GuestsListPDF = memo(GuestsList, (prevProps, newProps) => {
    let oldU = prevProps.users.map(user => (user._id)).join(' ');
    let newU = newProps.users.map(user => (user._id)).join(' ');

    return (prevProps.users.length === newProps.users.length && oldU === newU)
})