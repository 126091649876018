import React, { Component } from "react";
import { Container, Header, Input, Modal } from "semantic-ui-react";

class AppShareModal extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    closeModal = () => {
        this.props.onClose();
        this.setState({ link_is_copied: false });
    };

    clearSelection() {
        if (window.getSelection) {
            window.getSelection().removeAllRanges();
        } else if (document.selection) {
            document.selection.empty();
        }
    }

    handleCopy = () => {
        /* Get the text field */
        let copyText = document.getElementById("myAppUrl");
        /* Select the text field */
        copyText.select();
        /* Copy the text inside the text field */
        document.execCommand("copy");
        /* Deselect the input value */
        this.clearSelection();
        /* Alert the copied text */

        this.setState({ link_is_copied: true });
    };

    render() {
        return (
            <Modal onClose={this.closeModal} open={this.props.open} closeOnEscape={true} closeOnDimmerClick={true} dimmer="blurring" size="small" closeIcon>
                <Header icon="share" content="Partage" />
                <Modal.Content>
                    <Modal.Description>
                        <p>Utilise le lien suivant pour permettre à d'autres utilisateurs de copier ta création :</p>
                        {/* <p>Utilise le lien suivant pour partager ta création :</p> */}
                        <Container>
                            {this.state.link_is_copied ? (
                                <Input
                                    action={{ color: "teal", content: "Copier", icon: "check", onClick: () => this.handleCopy() }}
                                    fluid
                                    type="text"
                                    value={window.location.origin.toString() + "/creation?app=" + this.props.app.id}
                                    id="myAppUrl"
                                />
                            ) : (
                                <Input
                                    action={{ color: "blue", content: "Copier", icon: "copy", onClick: () => this.handleCopy() }}
                                    fluid
                                    type="text"
                                    value={window.location.origin.toString() + "/creation?app=" + this.props.app.id}
                                    id="myAppUrl"
                                />
                            )}
                        </Container>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        );
    }
}

export default AppShareModal;
