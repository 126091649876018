import React, {Component} from 'react';
import { Message, Accordion, Icon, Segment, Modal, Button, Input, Table, Divider, Header, Label, Popup } from 'semantic-ui-react';

class Group extends Component{
	constructor(props){
		super(props)
		this.state = {
			name: "",
			edit: false
		}
	}

	componentDidUpdate(prevProps){
		if(prevProps.group.loading && !this.props.group.loading){
			this.setState({edit: false})
		}
	}

	onEdit = () => {
		this.setState({name: this.props.group.name, edit:true})
	}

	onNameChanged = (event, data) =>{
		event.persist();
		this.setState(prev => {
			return{
                ...prev,
                name: data.value
			}
		})
	}

	onSubmit = () => {
		this.props.onGroupUpdate(this.props.group._id, this.state.name)
	}

	onCancel = () => {
		this.setState({name:this.props.group.name, edit:false})
	}

	render(){
		let groupName = this.state.edit ? (<Input disabled={this.props.group.loading} value={this.state.name} onChange={this.onNameChanged}>
			<input></input>
			<Button color="orange" loading={this.props.group.loading} onClick={this.onSubmit}>Éditer</Button>
			<Popup content="Annuler" trigger={<Button color="red" icon="cancel" onClick={this.onCancel}></Button>}/>
		</Input>) : this.props.group.name

		return (
			<React.Fragment>
				{groupName} 
				<Segment style={{margin:0, padding:0}}basic floated="right">
					<div>
						<Popup content="Éditer" trigger={<Icon link name="pencil" onClick={this.onEdit}/>}/>
						<Popup content="Supprimer" trigger={<Icon link name="trash" onClick={()=>{this.props.onGroupSuppression(this.props.group._id)}}/>}/>
					</div>
				</Segment>
			</React.Fragment>
		)
	}
}


export default class AdministrationAddGroups extends Component{

    constructor(props){
        super(props)

        this.state = {
            open: false,
			name: "",
			modal:{
				open:false,
				groupId: null
			}
        }
    }

    componentDidUpdate(prevProps){

    }

    openAddGroups = () => {
        this.setState(prev => {
			return {
				...prev,
                open: !prev.open
			}
		})
    }
    
    nameChanged = (event, data) =>{
		event.persist();
		this.setState(prev => {
			return{
                ...prev,
                name: data.value
			}
		})
	}


    onAddGroupsSubmit = () => {
		this.props.onAddGroup(this.state.name);
		this.setState({name:""})
	}

	onGroupSuppression = (groupId) => {
		this.setState(prev => {
			return {
				...prev,
				modal:{
					...prev.modal,
					open: true,
					groupId: groupId
				}
			}
		})
	}

	onGroupSuppressionModalClose = () => {
		this.setState(prev => {
			return {
				...prev,
				modal:{
					...prev.modal,
					open: false
				}
			}
		})
	}

	onGroupSuppressionModalValidation = () => {
		this.setState(prev => {
			this.props.onGroupSuppression(this.state.modal.groupId)
			return {
				...prev,
				modal:{
					...prev.modal,
					open: false,
					groupId: null
				}
			}
		})
	}

	renderForm(){
		return (
			<div>
				<Input
					action={{
						color: 'orange',
						content: 'Valider',
						onClick: this.onAddGroupsSubmit
					}}
					placeholder='Nom du groupe'
					onChange={this.nameChanged}
					value={this.state.name}
				/>
			</div>
		)
	}

	renderGroups(){
		let groups = null;
		let table;
		if(Object.values(this.props.groups.entities).length > 1){
			
			groups = Object.values(this.props.groups.entities)
			.filter(group => {
				return group._id != "nogroup";
			}).map((group, i) => {

				return <Table.Row key={group._id}><Table.Cell><Group group={group} onGroupUpdate={this.props.onGroupUpdate} onGroupSuppression={this.onGroupSuppression}/></Table.Cell></Table.Row>
			})
			table = (<Table celled><Table.Body>{groups}</Table.Body></Table>)
		}else{
			table = (<Message>Vous n'avez pas encore de groupe, utilisez le formulaire ci-dessus pour en créer</Message>)
		}
		return (
			<React.Fragment>
				<Header>Mes groupes</Header>
				{table}
			</React.Fragment>
		)
	}

	renderError(){
		let error = this.props.groups.error
		if(!error)
			return null
		if(error instanceof Error)
			error=[{message: error.toString()}]
		else if(typeof error === "string"){
			error = [{message: error}]
		}
		return (
			<Message error header="Erreur" list={error.map(error => (error.message))}/>
		)
	}

    render(){
        return (
            <React.Fragment>
                <Accordion.Title
                    index={0}
                    onClick={this.openAddGroups}
                    active={this.state.open}>
                    <Icon name='users' />
                    Ajouter/Supprimer des groupes
                </Accordion.Title>
                <Accordion.Content active={this.state.open}>
                    <Segment loading={this.props.groups.isLoading > 0} style={{border: 0}}>
                        {this.renderError()}
						{this.renderForm()}
						
						{this.renderGroups()}
                    </Segment>
                </Accordion.Content>
				<Modal dimmer="blurring" open={this.state.modal.open} onClose={this.onGroupSuppressionModalClose}>
					<Header icon='trash' content="Confirmation de suppression"/>
					<Modal.Content>
						<Modal.Description>
						<p>
							Vous êtes sur le point de supprimer le groupe {this.props.groups.entities[this.state.modal.groupId] ? (<b>{this.props.groups.entities[this.state.modal.groupId].name}</b>) : ""}.
						</p>
						<p>
							Les élèves appartenant à ce groupe ne seront pas supprimés et pourront être transférés vers d'autres groupes.
						</p>
						<p>Confirmez-vous la suppression?</p>
						</Modal.Description>
					</Modal.Content>
					<Modal.Actions>
						<Button basic onClick={this.onGroupSuppressionModalClose} icon="cancel" content="Annuler"/>
						<Button
						color="red"
						icon='trash'
						content="Supprimer"
						onClick={this.onGroupSuppressionModalValidation}
						/>
					</Modal.Actions>
					</Modal>
            </React.Fragment>
        )
    }
}