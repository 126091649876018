import {modalConstants} from "../constants";

export const modalActions = {
    addModalMessage,
    destroyAllModalMessage,
    destroyTempModalMessage,
    addModalMessageMid,
    createModalMessage,
    initContextMessage,
    initInstruction,
    initClues,
    addClues
};

function addModalMessage(modalMessage, noWait =true) {
    modalMessage.noWait = noWait
    return {
        type: modalConstants.ADD_MODAL_MESSAGE,
        payload: modalMessage
    }
}

function destroyAllModalMessage() {
    return {
        type: modalConstants.DESTROY_ALL_MODAL_MESSAGE,
        payload: null
    }
}

function destroyTempModalMessage() {
    return {
        type: modalConstants.DESTROY_TEMP_MODAL_MESSAGE,
        payload: null
    }
}

function destroyClues() {
    return {
        type: modalConstants.DESTROY_CLUES_MESSAGE,
        payload: null
    }
}

//MIDDLEWARE POUR AJOUTER UN MESSAGE
function addModalMessageMid(modalMessage, noWait) {
    return (dispatch, getState) => {
        const state = getState()
        const messages = state.modal.messages;
        if (messages.length >= 7) {
            dispatch(destroyTempModalMessage());
        }
        ;
        dispatch(addModalMessage(createModalMessage(modalMessage), noWait))
    }
}

//LES PERSOS
const DOC = {
    name: 'Doc',
    class: 'doc'
}

const MISSTHINGETTE = {
    name: 'MissThinguette',
    class: 'main'
}

const TECH = {
    name: 'Le Technicien',
    class: 'tech'
}

//CREATION ET NORMALIZATION D'UN MESSAGE
function createModalMessage(modalMessage) {

    if (modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.CHECK_ERROR_NO_COMPIL) {
        modalMessage.title = "Il faudrait que tu testes ton programme avant de passer à la suite.";
        modalMessage.text = "Clique sur le bouton 'Téléverser' en haut de l'écran";
        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.author = MISSTHINGETTE;
    }

    else if (modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.CHECK_ERROR_NO_MODIF) {
        modalMessage.title = "Je crois que tu n'as rien modifié depuis la dernière fois";
        modalMessage.text = "Essaye de changer un peu ton programme. N'oublie pas de demander des indices si tu es bloqué"
        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.author = MISSTHINGETTE
    }

    else if (modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.CHECK_UNKNOWN_ERROR) {
        modalMessage.title = "Erreur";
        modalMessage.text = "Je n'arrive pas à valider ton étape"
        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.author = MISSTHINGETTE
    }

    else if (modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.CHECK_ERROR) {
        modalMessage.text = modalConstants.CHECK_ERROR_MESSAGE[Math.floor(Math.random() * modalConstants.CHECK_ERROR_MESSAGE.length - 1)];
        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.author = MISSTHINGETTE;
        modalMessage.text = !modalMessage.text ? "Ce n'est pas tout à fait ça. Les indices peuvent t'aider" : modalMessage.text
    }

    else if (modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.INDICE) {
        modalMessage.temp = false;
        modalMessage.displayInChannel = true;
        modalMessage.img = modalMessage.link || null;
        modalMessage.author = DOC;
    }

    else if (modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.CONTEXT) {
        modalMessage.temp = false;
        modalMessage.displayInChannel = true;
        modalMessage.img = modalMessage.img || null;
        modalMessage.author = MISSTHINGETTE
    }

    else if (modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.INSTRUCTION) {
        modalMessage.title = 'Ton objectif : ';
        modalMessage.temp = false;
        modalMessage.displayInChannel = true;
        modalMessage.img = modalMessage.img || null;
        modalMessage.author = MISSTHINGETTE
    }

    else if (modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.STEP_DONE) {
        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.img = modalMessage.img || null;
        modalMessage.author = MISSTHINGETTE;
        modalMessage.text = 'Bravo !'
    }

    else if (modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.COURSE_ENDED) {
        modalMessage.temp = false;
        modalMessage.displayInChannel = true;
        modalMessage.img = modalMessage.link || null;
        modalMessage.author = MISSTHINGETTE;
        modalMessage.text = "Félicitations ! Tu as franchi un cap aujourd'hui !! \n Tu peux commencer un nouveau parcours ou continuer à t'amuser ici"
    }

    else if (modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.ACTIVITY_ALREADY_VALIDATE) {
        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.img = modalMessage.link || null;
        modalMessage.author = MISSTHINGETTE;
        modalMessage.text = "Tu as déjà validé ton activité, mais tu peux toujours la modifier, ton enseignant verra tes modifications"
    }

    else if (modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.ACTIVITY_ENDED) {
        modalMessage.temp = false;
        modalMessage.displayInChannel = true;
        modalMessage.img = modalMessage.link || null;
        modalMessage.author = MISSTHINGETTE;
        modalMessage.text = "Tu as validé ton activité, mais tu peux toujours la modifier, ton enseignant verra tes modifications"
    }

    else if (modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.VALIDATE_ACTIVITY) {
        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.author = MISSTHINGETTE;
        modalMessage.text = "L'activité a bien été sauvegardée, pour que vos élèves puissent y accéder cliquez sur le bouton \"Publier\"";
    }

    else if (modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.UPDATE_ACTIVITY) {
        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.author = MISSTHINGETTE;
        modalMessage.text = "Vos modifications ont bien été prises en compte";
    }

    else if (modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.COMPILATION_ERROR) {
        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.title = "Oups, il y a une erreur dans ton code. Voilà des détails:";
        modalMessage.text = modalMessage.text;
        modalMessage.author = TECH

    }

    else if (modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.COMPILATION_SUCCESS) {
        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.text = "L'envoi sur la carte est une réussite. Maintenant c'est à toi de tester !";
        modalMessage.author = TECH
    }

    else if(modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.NO_REBOOT){
        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.text = "J'ai trouvé ta carte mais je n'arrive pas à discuter avec elle, essaye de la rebrancher et reteste ton programme";
        modalMessage.author = TECH
    }

    else if(modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.BAD_HEX_CHECKSUM){
        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.text = "Il y a eu un problème de compilation, recommence";
        modalMessage.author = TECH
    }

    else if(modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.MORE_THAN_ONE_CARD){
        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.text = "Il semble que tu aies plusieurs cartes branchées, gardes-en une seule.";
        modalMessage.author = TECH
    }

    else if(modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.NO_CARD){
        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.text = "Ta base Thingz ne me répond pas. Il faut la débrancher, la rebrancher et cliquer juste après sur \"Téléverser\". Si cela se reproduit souvent, signale le sur le chat et on t'aidera à trouver la cause du problème.";
        modalMessage.author = TECH
    }

    else if(modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.WRONG_TYPE_CARD){
        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.text = modalMessage.text
        modalMessage.author = TECH
        modalMessage.title = 'Mauvaise carte';
    }

    else if(modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.BOARD_NOT_BOOTED_AFTER_FLASH){
        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.text = "Il semblerait que ta carte n'ait pas démarré correctement, tu devrais vérifier que ton programme fonctionne bien";
        modalMessage.author = TECH
    }
    else if(modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.COMPILATION_TIMEOUT || modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.UNKNOWN_ERROR){

        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.title = "Il y a eu un problème...";
        modalMessage.text = "Essaie encore !";
        modalMessage.author = TECH;
    }

    else if(modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.OFFLINE_ERROR){
        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.title = "Que se passe-t'il ?!"
        modalMessage.text = "Tu n'es plus connecté à internet... Il faut rétablir la connexion pour que je puisse sauvegarder ton avancement dans la suite du parcours."
        modalMessage.author = TECH
    }

    else if (modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.OFFLINE_RECOVERY){
        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.title = "Youpi"
        modalMessage.text = "Tu es à nouveau connecté à internet... La sauvegarde de ta progression a repris"
        modalMessage.author = TECH
    }

    else if (modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.COMBINATED_ERROR){
        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.title = "Tu as trouvé un bug !"
        modalMessage.text = "Je ne comprends pas ton assemblage de blocs. Essaye de le modifier."
        modalMessage.author = TECH
    }
    else if (modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.NO_CODE_ERROR){
        modalMessage.temp=true;
        modalMessage.displayInChannel = false;
        modalMessage.text = "Il ne semble pas y avoir de code à compiler ! Modifie ton éditeur et réessaie !"
        modalMessage.author = TECH
    }
    else if (modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.OVER_RECEIVING){
        modalMessage.temp=true;
        modalMessage.displayInChannel = false;
        modalMessage.text = "Ta carte envoie trop de messages."
        modalMessage.author = TECH
    }else if (modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.NO_MORE_CLUES){
        modalMessage.temp=true;
        modalMessage.displayInChannel = true;
        modalMessage.text = "Je n'ai pas d'autres indices à te donner"
        modalMessage.author = DOC
    }else if (modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.NO_CLUES){
        modalMessage.temp=true;
        modalMessage.displayInChannel = true;
        modalMessage.text = "Je n'ai pas d'indice à te donner pour cette étape"
        modalMessage.author = DOC
    }
    else if (modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.CANT_SEND_MSG){
        modalMessage.temp=true;
        modalMessage.displayInChannel = false;
        modalMessage.title = "Je n'arrive pas à communiquer avec l'extension"
        modalMessage.text = "Si tu as un kit Thingz, il faut que tu installes l'extension. Si tu n'as pas de kit Thingz, tu peux utiliser le simulateur pour tester ton programme. Plus d'infos ici : "
        modalMessage.link = "https://help.thingz.co/article/redemarrer-extension-thingz"
        modalMessage.author = TECH
    }
    else if(modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.CANT_CONNECT){
        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.title = "Ta base Thingz ne répond pas."
        modalMessage.text = "Essaie de la débrancher, de la rebrancher puis clique sur \"Téléverser\" dès qu'elle est prête (le bouton \"Base TGZ\" est vert). Si cela se reproduit souvent, signale le sur le chat et on t'aidera à trouver la cause du problème.";
        modalMessage.author = TECH
    }
    else if(modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.SIMULATOR_LANG_NOT_SUPPORTED){
        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.title = modalMessage.title;
        modalMessage.text = modalMessage.text;
        modalMessage.author = TECH
    }
    else if(modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.UNEXPECTED_CARD){
        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.title = "La base détectée n'est pas la base attendue.";
        modalMessage.text = "Fais attention à ne conserver qu'une seul et même carte connectée jusqu'à la fin de l'envoie de ton programme.";
        modalMessage.author = TECH
    }
    else if(modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.CONNECTION_LOST){
        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.title = "Connexion à la base perdu.";
        modalMessage.text = "Base arrachée ou connexion instable.";
        modalMessage.author = TECH
    }else if(modalMessage.type === modalConstants.MODAL_MESSAGE_TYPE.BLOCKLY_XML_LOAD_FAILED){
        modalMessage.temp = true;
        modalMessage.displayInChannel = false;
        modalMessage.title = "Erreur de chargement blockly";
        modalMessage.text = modalMessage.msg;
        modalMessage.author = TECH
    }

    return modalMessage

}


//INITIALISATION DU CONTEXT
function initContextMessage(progressionId) {
    console.log('INIT_CONTEXT_MESSAGE');
    return (dispatch, getState) => {
        let progression = getState().model.progressions.entities[progressionId];
        if(!progression)
        return;
        let step = getState().model.steps.entities[progression.step];
        let course = getState().model.courses.entities[step.course];

        if(!step)
            return;

        dispatch(destroyAllModalMessage());
        
        if (progression.ended) {
            if (course.isActivity) {
                dispatch(addModalMessageMid({
                    type: modalConstants.MODAL_MESSAGE_TYPE.ACTIVITY_ENDED
                }));
            }
            else {
                dispatch(addModalMessageMid({
                    type: modalConstants.MODAL_MESSAGE_TYPE.COURSE_ENDED
                }, false));
                return
            }  
        }

            if (step.context && !progression.ended) {
                dispatch(addModalMessageMid({
                    type: modalConstants.MODAL_MESSAGE_TYPE.CONTEXT,
                    text: step.context,
                    img: step.contextImg || null,
                }, false));
        }
        dispatch(initInstruction(step.instruction, 700))

    }
}

function initInstruction(instruction, timing) {
    return dispatch => {
        if(instruction)
            dispatch(addModalMessageMid({
                type: modalConstants.MODAL_MESSAGE_TYPE.INSTRUCTION,
                text: instruction,
            }, false))
    }
}

//INIT INDICES DEJA DEMANDES LORS D'UN CHARGEMENT DE PAGE
function initClues(clues) {
    return (dispatch, getState) => {
        dispatch(destroyClues())
        for (let clue of clues) {
            dispatch(addModalMessageMid({
                ...clue,
                type: modalConstants.MODAL_MESSAGE_TYPE.INDICE,
            }, false))
        }
    }
}

function addClues(clues){
    return dispatch => {
        for (let clue of clues) {
            dispatch(addModalMessageMid({
                ...clue,
                type: modalConstants.MODAL_MESSAGE_TYPE.INDICE,
            }))
        }
    }
}

