import {pluggedBricksConstants, extensionConstants} from "../constants";

const initialPluggedBricksState = {
    bricks: []
}

export const pluggedBricks = (state = initialPluggedBricksState, action) => {

    switch (action.type) {

        case extensionConstants.DISCONNECTED:
        case extensionConstants.CONNECTED:
        case extensionConstants.OPEN_SUCCESS:
        case extensionConstants.CLOSE_SUCCESS:
            return {
                ...state,
                bricks: []
            }

        case pluggedBricksConstants.PLUG: 
            const bricks = [...state.bricks];
            console.log(bricks)
            bricks.push(action.brick);
            return {
                ...state,
                bricks
            }
        case pluggedBricksConstants.UNPLUG:
            const newBricks = [];
            let found = false;
            for(let b in state.bricks){
                if(state.bricks[b].id != action.brick.id || found){
                    newBricks.push(state.bricks[b]);
                }else{
                    found = true;
                }
            }
            return {
                ...state,
                bricks: newBricks
            }
        default:
            return state
    }
}


/*
export const getStep = state => {
    const avancement = getActualAvancement(state);
    const course = getSelectedCourse(state);

    return course.steps[avancement.step];
}
*/




