export const stepsConstants = {
    FETCH_BY_COURSE_FAILED:"STEPS_FETCH_BY_COURSE_FAILED",
    FETCH_NEXT_STEP_FAILED:"STEPS_FETCH_NEXT_STEP_FAILED",
    FETCH_PREVIOUS_STEP_FAILED:"STEPS_FETCH_PREVIOUS_STEP_FAILED",
    CREATE_BY_COURSE_FAILED:"STEPS_CREATE_BY_COURSE_FAILED",
    CREATE_NEXT_STEP_FAILED:"STEPS_CREATE_NEXT_STEP_FAILED",
    CREATE_PREVIOUS_STEP_FAILED:"STEPS_CREATE_PREVIOUS_STEP_FAILED",
    UPDATE_ENTITY_FAILED:"STEPS_UPDATE_ENTITY_FAILED",
    UPDATE_FAILED: "STEPS_UPDATE_FAILED",
    DELETE_FAILED: "STEPS_DELETE_FAILED"   
}