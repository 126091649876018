import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";

import { ScriptVersion } from "../../containers/Blockly";

import { HeaderFreeCreation } from "../../components/HeaderFreeCreation";
import { HeaderFreeCreationControls } from "../../components/HeaderFreeCreationControls";
import { ExtensionInformationComponent } from "../../components/ExtensionInformationComponent";
import { Blockly } from "../../containers/Blockly";
import { Extension } from "../Extension";
import { FloatingMessage } from "../../components/FloatingMessage";
import { getActualUser, getUser } from "../../reducers/user_reducer";
import { modalConstants, freeCreationConstants } from "../../constants";
import { modalActions } from "../../actions";
import { Ace } from "../../containers/Ace";
import { SerialMonitor } from "../SerialMonitor";
import { Simulator } from "../Simulator";

import { Button, Container, Grid, Icon, Popup } from "semantic-ui-react";

import { freeCreationActions, extensionActions, blocklyActions, notificationActions, serialMonitorActions } from "../../actions";

import { appsActions, modelActions, spreadsheetsActions } from "../../actions/model";

import runtimeEnv from "@mars/heroku-js-runtime-env";

import "./FreeCreation.css";

class FreeCreation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            readOnly: false,
        };
    }

    componentDidMount() {
        document.title = "Création libre - Thingz";
        this.props.dispatch(freeCreationActions.initiateMainWorkspace(this.props.match.params));
    }

    componentDidUpdate(prevProps) {
        const values = queryString.parse(this.props.location.search);
        const valuesPrev = queryString.parse(prevProps.location.search);

        if (!values.app && valuesPrev.app) {
            this.props.dispatch(freeCreationActions.initiateMainWorkspace(this.props.match.params));
        }
    }

    componentWillUnmount() {
        this.props.dispatch(modelActions.invalidate());
        this.props.dispatch(notificationActions.deleteAllNotificationMessages());
        this.props.dispatch(freeCreationActions.resetFreeCreation());
    }

    compilationRequest = () => {
        if (this.props.freeCreation.freeCreationEditorState.startsWith("Blockly") || this.props.freeCreation.freeCreationEditorState.startsWith("BlocklyAndAce")) {
            try {
                if (this.props.blockly.mainWorkspace === null) return;
                const code = window.Blockly.Arduino.workspaceToCode(this.props.blockly.mainWorkspace);
                this.props.dispatch(extensionActions.sendMessage({ msg: { cmd: "compilation", data: code, url: runtimeEnv().REACT_APP_COMPILATION_SERVER, filterBoards: ["mini", "mega", "uno"] }, timeout: 60000 }));
            } catch (error) {
                console.log("Erreur blockly", error);
                if (error) {
                    this.props.dispatch(modalActions.addModalMessageMid({ type: modalConstants.MODAL_MESSAGE_TYPE.COMBINATED_ERROR }));
                }
                return;
            }
        } else if (this.props.freeCreation.freeCreationEditorState.startsWith("Ace")) {
            try {
                let code;
                let lang;
                if (this.props.freeCreation.freeCreationEditorState === "AcePython") {
                    if (this.props.freeCreation.codePy === null) {
                        this.props.dispatch(modalActions.addModalMessageMid({ type: modalConstants.MODAL_MESSAGE_TYPE.COMBINATED_ERROR }));
                        return;
                    }
                    code = this.props.freeCreation.codePy;
                    lang = "py";
                } else {
                    if (this.props.freeCreation.code === null) {
                        this.props.dispatch(modalActions.addModalMessageMid({ type: modalConstants.MODAL_MESSAGE_TYPE.COMBINATED_ERROR }));
                        return;
                    }
                    code = this.props.freeCreation.code;
                    lang = "cpp";
                }
                this.props.dispatch(extensionActions.sendMessage({ msg: { cmd: "compilation", data: code, language: lang, url: runtimeEnv().REACT_APP_COMPILATION_SERVER, filterBoards: ["mini", "mega", "uno"] }, timeout: 60000 }));
            } catch (error) {
                console.log("Erreur ace", error);
                if (error) {
                    this.props.dispatch(modalActions.addModalMessageMid({ type: modalConstants.MODAL_MESSAGE_TYPE.COMBINATED_ERROR }));
                }
                return;
            }
            return;
        }
    };

    //****génération du code en Ace/Hybride
    //enmodeHybride
    autoSaveXml = (xml, codePy, codeHybride) => {
        this.props.dispatch(freeCreationActions.saveBlocklyXml(xml, codePy, codeHybride));
    };

    autoSaveAce = (code) => {
        this.props.dispatch(freeCreationActions.saveCodeAce(code));
    };

    xmlLoaded = () => {
        this.props.dispatch(freeCreationActions.loadBlocklyXmlDone());
    };

    onMainWorkspaceStatusChanged = (workspace) => {
        if (workspace !== null) {
            this.props.dispatch(blocklyActions.mainWorkspaceInitiated(workspace));
        } else {
            this.props.dispatch(blocklyActions.mainWorkspaceInvalid());
        }
    };

    synchroCodeAce = () => {
        this.props.dispatch(freeCreationActions.synchroHybridCode());
        this.props.dispatch(freeCreationActions.synchroCodeAce());
        this.props.dispatch(freeCreationActions.saveBlocklyXml(this.props.freeCreation.xml, this.props.freeCreation.codeHybride));
    };

    triggerSyncButton = () => {
        return (
            <Popup
                basic
                position="top center"
                trigger={
                    <Button size="small" icon color="purple" onClick={this.handleSyncModalOpening}>
                        <Icon name="sync" />
                    </Button>
                }>
                Synchro
            </Popup>
        );
    };

    handleSyncModalOpening = () => {
        this.props.dispatch(freeCreationActions.openSyncModal());
    };

    closeSyncModal = () => {
        this.props.dispatch(freeCreationActions.closeSyncModal());
    };

    //gestion de la base
    getBoardStatus = () => {
        if (this.props.extension.isComOpen) return { color: "green", title: "Ta base est bien détectée", autoConnect: this.props.extension.autoConnect };
        if (this.props.extension.boardStatus === "IN_BOOTLOADER") return { color: "blue", title: "Ta base est en mode démarrage", autoConnect: this.props.extension.autoConnect };
        if (this.props.extension.boardStatus === "FOUND") return { color: "yellow", title: "Ta base est en mode démarrage", autoConnect: this.props.extension.autoConnect };
        return { color: "red", title: "Aucune base n'est détectée", autoConnect: this.props.extension.autoConnect };
    };

    onBoardDisconnect = () => {
        this.props.dispatch(extensionActions.close());
    };

    onBoardConnect = () => {
        this.props.dispatch(extensionActions.open());
    };

    onBoardAutoConnect = () => {
        this.props.dispatch(extensionActions.toggleAutoConnect());
    };

    remixApp = () => {
        if (this.props.freeCreation.id) {
            this.props.dispatch(freeCreationActions.remixApp(this.props.freeCreation.id));
        }
    };

    //Dispatche l'action qui va changer le mode d'affichage piloté par les trois boutons de HeaderFreeCreationControls.js
    changeStateFreeCreationEditor = (currentState, targetState, readOnly) => {
        this.props.dispatch(freeCreationActions.changeStateFreeCreationEditor(currentState, targetState, readOnly));
        this.props.dispatch(freeCreationActions.synchroHybridCode());

        // Charge le code sauvegardé s'il existe OU synchronise automatiquement le code du Blockly courrant dans ace
        // if (targetState.endsWith('Python')) {
        // 	this.props.dispatch(freeCreationActions.loadCodePython());
        // } else {
        // 	this.props.dispatch(freeCreationActions.loadCode());
        // }
        // this.props.dispatch(freeCreationActions.saveBlocklyXml(this.props.freeCreation.xml, this.props.freeCreation.codePy))
    };

    //Render qui ne renvoie que le Workspace de Blockly
    renderBlockly = () => {
        return (
            <Grid.Row className={"innerRows"} style={{ flex: 1 }} columns="equal">
                <Grid.Column id={"columnTarget"} style={{ height: "100%" }}>
                    <Container id={"FreeCreationBlocklyContainer"}>
                        <Blockly
                            lang={window.LANG}
                            autoSave={this.autoSaveXml}
                            xml={this.props.freeCreation.xml}
                            xmlReadyToLoad={this.props.freeCreation.xmlReadyToLoad}
                            xmlLoaded={this.xmlLoaded}
                            onMainWorkspaceStatusChanged={this.onMainWorkspaceStatusChanged}
                            readOnly={this.props.freeCreation.readOnly}
                            // version={ScriptVersion.BlocklyGalaxia}
                        />
                    </Container>
                </Grid.Column>
            </Grid.Row>
        );
    };

    //Render qui renvoie le Workspace de Blockly et la console de Ace
    renderBlocklyAndAce = () => {
        let mode;
        let init;
        if (this.props.freeCreation.freeCreationEditorState === "BlocklyAndAceCpp" || this.props.freeCreationEditorState === "BlocklyAndAce") {
            mode = "c_cpp";
            init = "void setup()\n{\n\n}\n\n\nvoid loop()\n{\n\n}";
        } else if (this.props.freeCreation.freeCreationEditorState === "BlocklyAndAcePython") {
            mode = "python";
            init = "def setup():\r\n\tpass\r\n\r\ndef loop():\r\n\tpass\r\n";
        } else {
            mode = "c_cpp";
            init = "void setup()\n{\n\n}\n\n\nvoid loop()\n{\n\n}";
        }

        return (
            <Grid.Row className={"innerRows"} style={{ flex: 1 }} columns="equal">
                <Grid.Column id={"columnTarget"} style={{ height: "100%" }}>
                    <Container id={"FreeCreationBlocklyContainer"}>
                        <Blockly
                            // version={ScriptVersion.BlocklyGalaxia}
                            lang={window.LANG}
                            autoSave={this.autoSaveXml}
                            codeGeneration={mode}
                            //ajout props readOnly
                            readOnly={this.props.freeCreation.readOnly}
                            xml={this.props.freeCreation.xml}
                            xmlReadyToLoad={this.props.freeCreation.xmlReadyToLoad}
                            xmlLoaded={this.xmlLoaded}
                            onMainWorkspaceStatusChanged={this.onMainWorkspaceStatusChanged}
                        />
                    </Container>
                </Grid.Column>
                <Grid.Column id={"FreeAceComponent"} width={5}>
                    <div id="aceEditorDiv" style={{ height: 100 + "%", width: 100 + "%" }}>
                        <Ace value={this.props.freeCreation.codeHybride || init} readOnly={true} mode={mode} />
                    </div>
                </Grid.Column>
            </Grid.Row>
        );
    };

    //Render qui renvoie Ace Uniquement
    renderAce = () => {
        let mode;
        let value;
        if (this.props.freeCreation.freeCreationEditorState === "Ace" || this.props.freeCreation.freeCreationEditorState == "AceCpp") {
            mode = "c_cpp";
            value = this.props.freeCreation.code;
        } else {
            mode = "python";
            value = this.props.freeCreation.codePy;
        }
        return (
            <Grid.Row className={"FreeCreationRowAce"} style={{ flex: 1, width: "100%" }} centered columns={1} verticalAlign="middle">
                <Grid.Column id={"FreeAceComponentAlone"} style={{ height: "100%" }}>
                    <div id="" style={{ height: 100 + "%", width: 100 + "%" }}>
                        <Ace mode={mode} value={value} synchroCode={this.synchroCodeAce} autoSaveAce={this.autoSaveAce} readOnly={this.props.freeCreation.readOnly} />
                    </div>
                </Grid.Column>
            </Grid.Row>
        );
    };

    //Render general qui va permettre de switcher entre les trois modes en un seul appel de fonction dans le JSX ci-dessous
    renderFreeCreationEditor = () => {
        //changement pour prendre en compte le fait qu'il y ait plusieurs B&A
        if (this.props.freeCreation.freeCreationEditorState.startsWith("BlocklyAndAce")) return this.renderBlocklyAndAce();
        if (this.props.freeCreation.freeCreationEditorState.startsWith("Ace")) return this.renderAce();
        return this.renderBlockly();
    };

    renderSerialMonitor = () => {
        if (!this.props.serialMonitor.open) return null;
        return (
            <Grid.Row className={"FreeCreationRow"} centered>
                <Grid.Column style={{ width: "80%" }}>
                    <SerialMonitor />
                </Grid.Column>
            </Grid.Row>
        );
    };

    render() {
        const boardStatus = this.getBoardStatus();

        return (
            <Grid id="freeCreationGrid">
                <FloatingMessage messages={this.props.messages} />
                <Grid.Row className="FreeCreationRow">
                    <Extension />
                    <HeaderFreeCreation notification={this.props.notification} user={this.props.user} />
                    <HeaderFreeCreationControls
                        // autoSave={this.props.freeCreation.readOnly?false:this.autoSave}
                        autoSave={this.autoSave}
                        appSavingState={this.props.freeCreation.appState}
                        onCompileClick={this.compilationRequest}
                        compilationLoading={this.props.extension.pendingCompilation}
                        user={this.props.user}
                        freeCreation={this.props.freeCreation}
                        // renderFreeCreationStateEdit={this.renderFreeCreationStateEdit}
                        changeStateFreeCreationEditor={this.changeStateFreeCreationEditor}
                        editorState={this.props.freeCreation.freeCreationEditorState}
                        synchroCodeAce={this.synchroCodeAce}
                        triggerSyncButton={this.triggerSyncButton}
                        syncModalIsOpen={this.props.freeCreation.syncModalIsOpen}
                        closeSyncModal={this.closeSyncModal}
                        onRemixClick={this.remixApp}
                    />
                </Grid.Row>
                {this.renderFreeCreationEditor()}
                {this.renderSerialMonitor()}
                <Simulator />
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        extension: state.extension,
        messages: state.modal.messages,
        user: getActualUser(state),
        session: state.session,

        apps: state.model.apps,
        freeCreation: state.freeCreation,
        freeCreation_isLoading: state.freeCreation.isLoading,
        blockly: state.blockly,
        code: state.freeCreation.code,

        serialMonitor: state.serialMonitor,
        spreadsheets: state.model.spreadsheets,
        notification: state.notification,
    };
}

const connectedFreeCreation = connect(mapStateToProps)(FreeCreation);
export { connectedFreeCreation as FreeCreation };
