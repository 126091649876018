import 'normalize.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension/logOnlyInProduction';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import {sessionService} from 'redux-react-session'
import Router from "./containers/ThingzRouter";
import {SocketIo} from "./containers/SocketIo";
import JavascriptTimeAgo from 'javascript-time-ago'

// The desired locales.
import fr from 'javascript-time-ago/locale/fr'

import 'semantic-ui-css/semantic.min.css';

// Initialize the desired locales.
JavascriptTimeAgo.locale(fr)



const actionSanitizer = (action) => (
  action
);

const stateSanitizer = (state) => (
  state.blockly ? {...state, blockly:"blockly filtered"} : state
);
//INIT DU STORE
const composeEnhancers = composeWithDevTools({actionSanitizer, stateSanitizer});
const store = createStore(rootReducer, undefined, composeEnhancers(
  applyMiddleware(thunk),
));


//INIT SESSION
sessionService.initSessionService(store, {driver : 'COOKIES'});

export default store;

ReactDOM.render((
        <Provider store={store}>
        		<div  style={{
              // height: "100vh", 
              display: 'flex',
              height: "100vh",
              margin: 0

            }}>
            	<Router/>
            	<SocketIo/>
            </div>
        </Provider>
    ),
    document.getElementById('root')
);


