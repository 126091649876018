import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Router, Route, Switch, Redirect} from "react-router-dom";
import {sessionService} from 'redux-react-session';

import {modelActions} from '../actions/model'

import Login from './Login';
import App from './App';
import Course from './Course';
import {ActivityCreator} from './ActivityCreator';
import {ActivityReadOnly} from './ActivityReadOnly';
import {CourseConsultation} from './CourseConsultation';
import {ActivityRenderingList} from './ActivityRenderingList';
import Register from './Register';
import {FreeCreation} from './FreeCreation';
import {GalaxiaSpace} from './GalaxiaSpace';
import {Profil} from './Profil';
import {Structure} from './Structure';
import {StudentProfil} from './StudentProfil';
import {ConfirmUser} from './ConfirmUser';
import history from '../history';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import {Editor} from './Editor';
import {Logout} from './Logout';
import {ErrorHandling} from './ErrorHandling';
import { Admin } from './Admin';
import { ActivitiesStore } from './ActivitiesStore'

import {GuestAccessRouter} from './GuestAccess';

import * as api from '../api'

class ThingzRouter extends Component {

    constructor(props){
        super(props);
        this.state = {
            user: null,
            isChecked: false
        }
    }

    componentDidMount(){
        if(this.props.invalid){
            this.setState({user: {}});
        }
        if(this.props.checked){
            if(this.props.authenticated){
                if(this.props.user){
                    if(this.props.user.confirmed){
                        this.setState({user: this.props.user});
                    }else{
                        this.refreshUser();
                    }
                }    
            }else{
                this.setState({user: {}});
            }
        }
    }

    componentDidCatch(error){
        try{
            window.analytics.track('Javascript Error', error)
        }catch(err){
                if(window.analytics)
                        window.analytics.track('Javascript error failed segment error', {originalError:error, segmentError: err})
        }
        this.setState({error: error})
    }

    componentWillReceiveProps(nextProps){
        if((this.props.user && !nextProps.user) || (!nextProps.user && this.state.user)){
            this.setState({user: {}, isChecked: false});
        }
    }

    componentDidUpdate(prevProps){
        if((this.props.invalid && !prevProps.invalid) || (!this.props.user && prevProps.user)){
            this.setState({user: {}, isChecked: false});
        }
        //Update state.user when we know that the user is not logged
        if(!prevProps.checked && this.props.checked){
            if((!this.props.authenticated || (!this.props.user && this.props.invalid))){
                this.setState({user: {}, isChecked: false})
            }
        }

        if(this.props.authenticated !== prevProps.authenticated){
            if(!this.props.authenticated || (!this.props.user && this.props.invalid)){
                this.setState({user: {}, isChecked: false})
            }
            // }else{
            //     this.setState({user: this.props.user})
            // }
        }

        //Wait for logged user
        if(this.props.user && !this.state.isChecked){
            this.setState({isChecked: true})
            if(this.props.user.confirmed)
                this.setState({user: this.props.user});
            else{
                this.refreshUser();
            }
        }
        this.props.dispatch(modelActions.clearStatus())
    }

    refreshUser(){
        api.users.me().then( response => {
            console.log(response)
            if(response.data){
                const user = response.data;
                if(user.confirmed){
                    console.log("USER", this.props.user);
                    sessionService.saveUser({jwt: this.props.jwt, user: user})
                    .then(() => {
                        this.setState({user: user})
                    }).catch(err => {
                        console.error(err)
                    });
                }else{
                    this.setState({user: user})
                }
            }else
                this.setState({user: {}})
        })
        .catch(error => {
            this.setState({user: {}})
        })
    }

    render(){
        if(this.state.error){
            return (<Router history={history}><ErrorHandling error={this.state.error}/></Router>)
        }
        let logged = this.props.authenticated && this.state.user && this.state.user._id ? true : false
        if(this.props.checked){
            if(this.props.authenticated && this.props.user){
                if(window.analytics){
                    let u = {
                        name: this.props.user.username,
                        email: this.props.user.email,
                    }
                    try{
                        u.company = this.props.structures.entities[this.props.user.structureOwned]
                    }catch(e){

                    }
                    window.analytics.identify(this.props.user._id, u)
                }
            }else{
                if(window.analytics)
                    window.analytics.identify()
            }
        }
        // console.log(logged,this.props.authenticated, this.state.user, this.props.user, this.props)
        return (
        this.state.user && 
        <Router history={history}>
            <Switch>
                <Route path='/email-confirmation/:confirmation' render={({match}) => (
                    <ConfirmUser match={match}/>
                )}/>

                <Route path='/g/:pin' render={({match}) => (
                    <GuestAccessRouter match={match}/>
                )}/>

                <Route path='/creation/:structureName' render={({match}) => (
                    <FreeCreation match={match}/>
                )}/>
                <Route path='/creation' render={({match, location}) => (
                    <FreeCreation match={match} location={location}/>
                )}/>

                <Route path='/galaxia' render={({match, location}) => (
                    <GalaxiaSpace match={match} location={location}/>
                )}/>

                <Route path='/eyrolles' render={({match, location}) => (
                    <GalaxiaSpace match={match} location={location}/>
                )}/>

                <Route path='/structure' render={({match, location}) => (
                    logged ? <Structure location={location}/> : <Redirect to={'/login'}/>
                )}/>

                <Route path='/store/activities' render={({match, location}) => (
                    <ActivitiesStore location={location}/>
                )}/>

                <Route path='/course/read/:shareId' render={({match}) => (
                    <ActivityReadOnly match={match}/>
                )}/>

                <Route path='/course/:id' render={({match, location}) => (
                    logged ? <Course match={match} location={location}/> : <Redirect to={'/login'}/>
                )}/>

                <Route path='/courses' render={() => (
                    logged ? <App/> : <Redirect to={'/login'}/>
                )}/>

                <Route path='/activityCreator/:id' render={({match}) => (
                    logged && (this.props.user.role.type === "instructor" || this.props.user.role.type === "root") ? <ActivityCreator match={match}/> : <Redirect to={'/courses'}/>
                )}/>

                <Route path='/activityCreator' render={({match}) => (
                    logged && (this.props.user.role.type === "instructor" || this.props.user.role.type === "root") ? <ActivityCreator match={match}/> : <Redirect to={'/courses'}/>
                )}/>

                <Route path='/activityRenderingList/:id' render={({match}) => (
                    logged && (this.props.user.role.type === "instructor" || this.props.user.role.type === "root") ? <ActivityRenderingList match={match}/> : <Redirect to={'/'}/>
                )}/>

                <Route path='/courseConsulation/:courseId' render={({match, location}) => (
                    logged && (this.props.user.role.type === "instructor" || this.props.user.role.type === "root") ? <CourseConsultation match={match} location={location}/> : <Redirect to={'/courses'}/>
                )}/>

                <Route path='/profil' render={() => (
                    logged ? <Profil/> : <Redirect to={'/courses'}/>
                )}/>

                <Route path='/student/:id' render={({match}) => (
                    logged ? <StudentProfil match={match}/> : <Redirect to={'/courses'}/>
                )}/>

                <Route path='/register' render={(router) => {
                    return !logged ? <Register router={router}/> : <Redirect to={'/courses'}/>
                }}/>

                <Route path='/forgotPassword' render={() => (
                    <ForgotPassword/>
                )}/>

                <Route path='/resetPassword' render={ () => (
                    <ResetPassword/>
                )}/>

                <Route path='/login' render={() => (
                    !logged ? <Login/> : <Redirect to={'/'}/>
                )}/>

                <Route path='/editor/:id' render ={(match) =>(
                    logged ? <Editor match={match}/>:<Redirect to={'/login'}/>
                )}/>

                <Route  path={'/logout'} render={({match}) => (
                    <Logout/>
                )}/>

                {/* <Route path='/list' render ={(match) =>(
                    logged ? <Admin match={match}/>:<Redirect to={'/login'}/>
                )}/> */}

                <Route  path={'/'} render={({match, location}) => {
                    if(logged && this.props.user.role && this.props.user.role.type == "instructor" ){
                        return <Structure location={location}/>
                    }else if(logged){
                        return <App/>
                    }else{
                        return <Redirect to={'/login'}/>
                    }
                }
                }/>
                
            </Switch>
        </Router>
        )
    }
}

const mapState = (state) => ({
    checked: state.session.checked,
    authenticated: state.session.authenticated,
    invalid: state.session.invalid,
    user: state.session.user.user,
    jwt: state.session.user.jwt,
    structures: state.model.structures

});

export default connect(mapState)(ThingzRouter)
// <Route component={PageNotFound}/> <Route path='/courses' component={App}/>
// <Route path='/course/:id' component={Course}/>