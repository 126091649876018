import React, {Component} from 'react';
import { connect } from 'react-redux';

import {Modal, Button, Grid, Card, Header, Transition, Container, Divider} from 'semantic-ui-react'

import {simulatorActions} from '../../actions';

import {SimulatorBrick} from './SimulatorBrick';
import initialBricks from './SimulatorInitialBricks'

import './Simulator.css';

class Simulator extends Component {

	constructor(props){
		super(props)

		const isSkulptPresent = window.Sk
		if(isSkulptPresent){
			window.Sk.thingz = {
				Led:{
					construct: (id, ...others) => {this.addBrick("led", id, ...others)},
					switchOn: (id) => {this.updateLed(id, "on")},
					switchOff: (id) => {this.updateLed(id, "off")},
				},
				Button:{
					construct: (id, ...others) => {this.addBrick("button", id, ...others)}
				},
				Weather:{
					construct: (id, ...others) => {this.addBrick("weather", id, ...others)}
				},
				Luminosity:{
					construct: (id, ...others) => {this.addBrick("luminosity", id, ...others)}
				},
				Potentiometer:{
					construct: (id, ...others) => {this.addBrick("potentiometer", id, ...others)}
				},
				Touch:{
					construct: (id, ...others) => {this.addBrick("touch", id, ...others)}
				},
				Motion:{
					construct: (id, ...others) => {this.addBrick("motion", id, ...others)}
				},
				DigitalDisplay:{
					construct: (id, ...others) => {this.addBrick("digitalDisplay", id, ...others)},
					printDigit:(id, num) => {this.updateDigitalDisplay(id, "printDigit", {num})},
					printLetter:(id, letter) => {this.updateDigitalDisplay(id, "printLetter", {letter})},
					switchOff: (id) => {this.updateDigitalDisplay(id, "off")},
				},
				RF433:{
					construct: (id, ...others) => {this.addBrick("rf433", id, ...others)},
					switchOn: (id, plug, group) => {this.updateRF433(id, "on", {plug, group})},
					switchOff: (id) => {this.updateRF433(id, "off")},
				},
				Buzzer:{
					construct: (id, ...others) => {this.addBrick("buzzer", id, ...others)},
					tone: (id, freq) => {this.updateBuzzer(id, "tone", {freq})},
					noTone: (id) => {this.updateBuzzer(id, "noTone", {})},
				},
				Infrared:{
					construct: (id, ...others) => {this.addBrick("infrared", id, ...others)}
				},
				Screen:{
					construct: (id, ...others) => {this.addBrick("screen", id, ...others)},
					clear: (id) => {this.updateScreen(id, "clear", {})},
					clearLine: (id, line) => {this.updateScreen(id, "clearLine", {line})},
					printMsg:(id, msg, line, column) => {this.updateScreen(id, "printMsg", {msg, line, column})},
				},
				ColoredScreen:{
					construct: (id, ...others) => {this.addBrick("coloredScreen", id, ...others)},
					print: (id, msg) => {this.updateColoredScreen(id, "print", {msg})},
					println: (id, msg) => {this.updateColoredScreen(id, "println", {msg})},
					setTextSize: (id, size) => {this.updateColoredScreen(id, "setTextSize", {size})},
					setTextColor: (id, color) => {this.updateColoredScreen(id, "setTextColor", {color})},
					fillScreen: (id, color) => {this.updateColoredScreen(id, "fillScreen", {color})},
					notImplemented: (id, error) => {this.updateColoredScreen(id, "notImplemented", {error})},
				}
			};
		}

		this.state = {
			bricks: [
			],
			running:false,
			error: isSkulptPresent ? null: "Erreur de chargement du simulateur",
		}
		
	}

	componentDidMount(){
		if(window.Sk){
			window.Sk.configure({
				output:this.updateMonitor, 
				read:this.builtinRead,
				killableWhile: true,
				killableFor: true,
				__future__: window.Sk.python3
			});
		}
	}

	componentDidUpdate(prevProps){
		if(!prevProps.simulator.open && this.props.simulator.open){
			this.startSkulpt();
		}
	}

	componentWillUnmount(){
		this.props.dispatch(simulatorActions.close());
		this.stopSkulpt();
	}

	onClose = () => {
		this.props.dispatch(simulatorActions.close());
		this.state.bricks.forEach(brick => {
			if(brick.onUnmount)
				brick.onUnmount()
		})
		this.setState({bricks: []})
		this.stopSkulpt();
	}

	startSkulpt(){
		if(!window.Sk)
			return;

		this.setState({bricks: [], running:true, error:null}, function(){
			let code = "import utime\n"
			code += this.props.simulator.code
			code +="\nutime.sleep_ms(50)\nsetup()\n\nwhile True:\n\tloop()\n\tutime.sleep_ms(10)"
			console.log(code);
			window.Sk.execLimit=Infinity;
			let myPromise = window.Sk.misceval.asyncToPromise(function() {
				return window.Sk.importMainWithBody("<stdin>", false, code||"", true);
			});
			myPromise.then((mod)  => {
					console.log('success');
					this.setState({running: false});
			},
			(err) => {
				console.log(err);
				console.log(err.toString());
			
				if(err instanceof window.Sk.builtin.TimeLimitError){
					try{
						window.analytics.track('Simulator', {})
					}catch(error){
							if(window.analytics)
									window.analytics.track('Simulator failed segment error', {error})
					}
				}else{
					try{
						window.analytics.track('Simulator failed', {error: err.toString()})
					}catch(error){
							if(window.analytics)
									window.analytics.track('Simulator failed segment error', {error})
					}
				}
				this.setState({running: false, error: err.toString()});
			});
		})
	}

	restartSkulpt = ()=>{
		if(!window.Sk)
			return 
		window.Sk.execLimit = 1;
		this.setState({running: false, error:null});
		setTimeout(()=>{
			this.startSkulpt();
		}, 100);
	}

	stopSkulpt = () => {
		if(!window.Sk)
			return
			
		window.Sk.execLimit = 1;
		this.setState({running: false, error: null});
	}

	builtinRead = (x) => {
    if (window.Sk.builtinFiles === undefined || window.Sk.builtinFiles["files"][x] === undefined)
            throw "File not found: '" + x + "'";
    return window.Sk.builtinFiles["files"][x];
	}

	addBrick = (name, id, ...others) => {
		this.setState(prevState => {
			try{
				let b;
				switch(name){
					case "led":
						b = initialBricks.led(id);
					break;
					case "button":
						b = initialBricks.button(id, this.onChange, others[0]);
					break;
					case "weather":
						b = initialBricks.weather(id, this.onChange, others[0], others[1]);
						b.skulptCbTempChange(b.inputs[0].value);
						b.skulptCbHumidityChange(b.inputs[1].value);
					break;
					case "luminosity":
						b = initialBricks.luminosity(id, this.onChange, others[0]);
						b.skulptCbLuminosityChange(b.inputs[0].value);
					break;
					case "potentiometer":
						b = initialBricks.potentiometer(id, this.onChange, others[0]);
						b.skulptCbPositionChange(b.inputs[0].value);
					break;
					case "touch":
						b = initialBricks.touch(id, this.onChange, others[0]);
					break;
					case "motion":
						b = initialBricks.motion(id, this.onChange, others[0]);
					break;
					case "digitalDisplay":
						b = initialBricks.digitalDisplay(id);
					break;
					case "rf433":
						b = initialBricks.rf433(id);
					break;
					case "buzzer":
						b = initialBricks.buzzer(id);
						b.onUnmount = ()=>{
							let buzz = this.state.bricks.find(brick => {return brick.id === id})
							if(buzz && buzz.oscillator)buzz.oscillator.stop();
						}
					break;
					case "infrared":
						b = initialBricks.infrared(id, this.onChange, others[0])
					break;
					case "screen":
						b = initialBricks.screen(id)
					break;
					case "coloredScreen":
						b = initialBricks.coloredScreen(id);
					break;
					
				}
				return {bricks: [...prevState.bricks, {...b}]}
			}catch(e){
				console.error(e)
			}
		})
	}

	onChange = (brickId, inputId, value) => {
		let b = this.state.bricks.find(brick => {return brick.id == brickId})
		if(!b)
			return;
		switch(b.brick){
			case "button":
				this.updateButton(brickId, inputId, value);
				break;
			case "weather":
				this.updateWeather(brickId, inputId, value);
				break;
			case "luminosity":
				this.updateLuminosity(brickId, inputId, value);
				break;
			case "potentiometer":
				this.updatePotentiometer(brickId, inputId, value);
				break;
			case "touch":
				this.updateTouch(brickId, inputId, value);
				break;
			case "motion":
				this.updateMotion(brickId, inputId, value);
				break;
			case "infrared":
				this.updateInfrared(brickId, inputId, value);
				break;
		}
	}

	renderSensors(){
		let bricks = this.state.bricks.map((brick, i) => {
			if(brick.type != "sensor")
				return null;
			return (<SimulatorBrick key={brick.id} id={brick.id} name={brick.name} type={brick.type} status={brick.status} img={brick.img} inputs={brick.inputs}/>);
		})
		return bricks;
	}

	renderActuators(){
		return this.state.bricks.map((brick, i) => {
			if(brick.type != "actuator")
				return null;
			return (<SimulatorBrick key={brick.id} id={brick.id} name={brick.name} type={brick.type} img={brick.img} outputs={brick.outputs} onUnmount={brick.onUnmount}/>);
		})
	}

	renderRunning(){
		if(this.state.running){
			return (
				<Header as="h4" color="green" style={{margin:0}}>
					En cours d'exécution
				</Header>
			)
		}else{
			return (
				<Header as="h4" color="red" style={{margin:0}}>
					Arrêté
				</Header>
			)
		}
	}

	renderError(){
		if(!this.state.running && this.state.error){
			return (
				<Header as="h4" color="red" style={{margin:0}}>
					{this.state.error}
				</Header>
			)
		}
		return null;
	}

	render(){
		return (
				<Modal id="simulator" open={this.props.simulator.open} closeOnDimmerClick={true} onClose={this.onClose} size="fullscreen" closeIcon>
			    <Modal.Header>
			    	Simulateur
						{this.renderRunning()}
						{this.renderError()}
			    </Modal.Header>
			    
			    <Modal.Content style={{height: "80vh"}}>
						<Grid style={{height:"100%"}}>
							<Grid.Row columns={2} divided style={{height:"100%"}}>
								<Grid.Column style={{overflowY: "scroll", height:"100%"}}>
									<Header textAlign="center" as="h2">Capteurs</Header>
										<Card.Group>
										{this.renderSensors()}
										</Card.Group>
								</Grid.Column>
								<Grid.Column style={{overflowY: "scroll", height:"100%"}}>
									<Header textAlign="center"as="h2">Actionneurs</Header>
										<Card.Group>
											{this.renderActuators()}
										</Card.Group>

								</Grid.Column>
							</Grid.Row>
						</Grid>
			    </Modal.Content>
					<Modal.Actions>
						<Button onClick={this.restartSkulpt}>
							Relancer la simulation
						</Button>
					</Modal.Actions>
			  </Modal>
		)
	}

	updateButton(brickId, inputId, value){
		this.setState(prevState => {
			try{
				let b = prevState.bricks.find(brick => {return brick.id === brickId})

				if(b.inputs[inputId].type === "check"){
					b.inputs[inputId].value = value;
					if(value){
						b.inputs[1].disabled = true;
						b.inputs[1].title = "Le bouton est en mode appui verrouillé"
						b.skulptCbOnChange(true);
					}
					else{
						b.inputs[1].disabled = false;
						b.inputs[1].title = ""
						b.skulptCbOnChange(false);
					}
					return {bricks: prevState.bricks.map(brick => { if(b.id == brick.id) return {...b}; return brick})}
				}else{
					switch(value){
						case 'pressed':
							b.skulptCbOnChange(true);
						break;
						case 'released':
							//check if we need to keep it pressed
							if(!b.inputs[0].value)
								b.skulptCbOnChange(false);
						break;
					}
				}	
				return prevState;
			}catch(e){
				console.error(e);
			}
		})
	}

	updateTouch(brickId, inputId, value){
		this.setState(prevState => {
			try{
				let b = prevState.bricks.find(brick => {return brick.id === brickId})

				if(b.inputs[inputId].type === "check"){
					b.inputs[inputId].value = value;
					if(value){
						b.inputs[1].disabled = true;
						b.inputs[1].title = "Le capteur est en mode toucher verrouillé"
						b.skulptCbOnChange(true);
					}
					else{
						b.inputs[1].disabled = false;
						b.inputs[1].title = ""
						b.skulptCbOnChange(false);
					}
					return {bricks: prevState.bricks.map(brick => { if(b.id == brick.id) return {...b}; return brick})}
				}else{
					switch(value){
						case 'pressed':
							b.skulptCbOnChange(true);
						break;
						case 'released':
							//check if we need to keep it pressed
							if(!b.inputs[0].value)
								b.skulptCbOnChange(false);
						break;
					}
				}	
				return prevState;
			}catch(e){
				console.error(e);
			}
		})
	}

	updateMotion(brickId, inputId, value){
		this.setState(prevState => {
			try{
				let b = prevState.bricks.find(brick => {return brick.id === brickId})

				if(b.inputs[inputId].type === "check"){
					b.inputs[inputId].value = value;
					if(value){
						b.inputs[1].disabled = true;
						b.inputs[1].title = "Le capteur de mouvement est en mode mouvement continu"
						b.skulptCbOnChange(true);
					}
					else{
						b.inputs[1].disabled = false;
						b.inputs[1].title = ""
						b.skulptCbOnChange(false);
					}
					return {bricks: prevState.bricks.map(brick => { if(b.id == brick.id) return {...b}; return brick})}
				}else{
					switch(value){
						case 'pressed':
							b.skulptCbOnChange(true);
						break;
						case 'released':
							//check if we need to keep it pressed
							if(!b.inputs[0].value)
								b.skulptCbOnChange(false);
						break;
					}
				}	
				return prevState;
			}catch(e){
				console.error(e);
			}
		})
	}

	updateWeather(brickId, inputId, value){
		this.setState(prevState => {
			try{
				let b = prevState.bricks.find(brick => {return brick.id === brickId})
				b.inputs[inputId].value = value;
				switch(inputId){
					case 0:
						b.skulptCbTempChange(value);
					break;
					case 1:
						b.skulptCbHumidityChange(value);
					break;
				}
				return {bricks: prevState.bricks.map(brick => { if(b.id == brick.id) return {...b}; return brick})}
			}catch(e){
				console.log(e);
			}
		})
	}

	updateLuminosity(brickId, inputId, value){
		this.setState(prevState => {
			try{
				let b = prevState.bricks.find(brick => {return brick.id === brickId})
				b.inputs[inputId].value = value;
				b.skulptCbLuminosityChange(value);
				return {bricks: prevState.bricks.map(brick => { if(b.id == brick.id) return {...b}; return brick})}
			}catch(e){
				console.log(e);
			}
		})
	}

	updatePotentiometer(brickId, inputId, value){
		this.setState(prevState => {
			try{
				let b = prevState.bricks.find(brick => {return brick.id === brickId})
				b.inputs[inputId].value = value;
				b.skulptCbPositionChange(value);
				return {bricks: prevState.bricks.map(brick => { if(b.id == brick.id) return {...b}; return brick})}
			}catch(e){
				console.log(e);
			}
		})
	}

	updateLed(brickId, action){
			this.setState(prevState => {
				try{
					let l = this.state.bricks.find(brick => { return brickId == brick.id});
					switch(action){
						case 'on':
							l.outputs[0].value = "Allumée";
						break;
						case 'off':
							l.outputs[0].value = "Éteinte";
						break;
					}
					let bricks = prevState.bricks.map(brick => {
						if(brick.id == brickId){
							return {...l};
						}
						return brick;
					});
					return {bricks};
				}catch(e){
					console.error(e);
				}
			})
	}

	updateRF433(brickId, action, params){
		this.setState(prevState => {
			try{
				let rf = this.state.bricks.find(brick => { return brickId == brick.id});
				switch(action){
					case 'on':
						rf.outputs[0].value = "Allumée, prise "+params.plug+" du groupe "+params.group;
					break;
					case 'off':
						rf.outputs[0].value = "Éteinte";
					break;
				}
				let bricks = prevState.bricks.map(brick => {
					if(brick.id == brickId){
						return {...rf};
					}
					return brick;
				});
				return {bricks};
			}catch(e){
				console.error(e);
			}
		})
	}

	updateBuzzer(brickId, action, params){
		this.setState(prevState => {
			try{
				let b = this.state.bricks.find(brick => { return brickId == brick.id});
				switch(action){
					case 'tone':
						if(!b.oscillator){
							b.oscillator = b.audioContext.createOscillator()
							b.oscillator.connect(b.audioContext.destination)
							b.oscillator.type = 'sine'
							b.oscillator.start()
						}
						b.oscillator.frequency.value = params.freq;
						b.outputs[0].value = "Sonne à "+params.freq+" Hz";
					break;
					case 'noTone':
						if(b.oscillator){
							b.oscillator.stop()
							b.oscillator = null
						}
						b.outputs[0].value = "Ne sonne pas";
					break;
				}
				let bricks = prevState.bricks.map(brick => {
					if(brick.id == brickId){
						return {...b};
					}
					return brick;
				});
				return {bricks};
			}catch(e){
				console.error(e);
			}
		})
	}

	updateDigitalDisplay(brickId, action, params){
		this.setState(prevState => {
			try{
				let b = this.state.bricks.find(brick => { return brickId == brick.id});
				switch(action){
					case 'printDigit':
						b.outputs[0].value = "Affiche le chiffre "+params.num;
					break;
					case 'printLetter':
						b.outputs[0].value = "Affiche la lettre "+params.letter;
					break;
					case 'noTone':
						b.outputs[0].value = "Éteint";
					break;
				}
				let bricks = prevState.bricks.map(brick => {
					if(brick.id == brickId){
						return {...b};
					}
					return brick;
				});
				return {bricks};
			}catch(e){
				console.error(e);
			}
		})
	}

	updateInfrared(brickId, inputId, value){
		this.setState(prevState => {
			try{
				let b = prevState.bricks.find(brick => {return brick.id === brickId})

				if(b.inputs[inputId].type === "number"){
					if(value < 1){
						setTimeout(()=>{
							this.setState(prev => {
								let brick = prev.bricks.find(brick => {return brick.id === brickId})
								if(brick && brick.inputs[inputId].value < 1){
									brick.inputs[inputId].value = 1
								}
								return {bricks: prev.bricks.map(brick => { if(b.id == brick.id) return {...b}; return brick})}
							})
						}, 250)
					}
					b.inputs[inputId].value = value;
				}else{
					switch(value){
						case 'pressed':
							b.skulptCbOnSignal(b.inputs[0].value);
							clearInterval(b.intervalResend)
							b.intervalResend = setInterval(()=>{
								b.skulptCbOnSignal(b.inputs[0].value);
							}, 10)
						break;
						case 'released':
							clearInterval(b.intervalResend)
						break;
					}
				}	
				return {bricks: prevState.bricks.map(brick => { if(b.id == brick.id) return {...b}; return brick})}
			}catch(e){
				console.error(e);
			}
		})
	}

	updateScreen(brickId, action, params){
		this.setState(prevState => {
			try{
				let b = this.state.bricks.find(brick => {return brickId === brick.id});
				switch(action){
					case 'clear':
						b.outputs.forEach(output => {
							output.value = ""
						})
					break;
					case 'clearLine':
						b.outputs[params.line].value = "";
					break;
					case 'printMsg':
						b.outputs[params.line].value = params.msg;
					break;
				}
				let bricks = prevState.bricks.map(brick => {
					if(brick.id == brickId){
						return {...b};
					}
					return brick;
				});
				return {bricks};
			}catch(e){
				console.error(e);
			}
		})
	}

	color565to888(color){
		let red,green,blue;

		red = ((color >> 11)<<3) | color >> 13
		green = (((color >> 5) & ((1<<6)-1))<<2) | ((color & ((1 <<11)-1)) >> 9)
		blue =  (((color & ((1<<5)-1)) << 3) | ((color & ((1 <<5)-1)) >> 2))

		red = red.toString(16);

		if(red.length == 1) red = "0"+red;
		green = green.toString(16);

		if(green.length == 1) green = "0"+green;
		blue = blue.toString(16);

		if(blue.length == 1) blue = "0"+blue;
		return "#"+red+green+blue
	}

	updateColoredScreen(brickId, action, params){
		this.setState(prevState => {
			try{
				let b = this.state.bricks.find(brick => {return brickId === brick.id});
				switch(action){
					case 'print':
						b.outputs[1].value += params.msg
					break;
					case 'println':
						b.outputs[1].value += (params.msg+"\n")
					break;
					case 'setTextSize':
						b.outputs[1].size = "1,"+params.size+"em";
					break;
					case 'setTextColor':
						b.outputs[1].textColor = this.color565to888(params.color)
					break;
					case 'fillScreen':
						b.outputs[1].value = "";
						b.outputs[1].bgColor = this.color565to888(params.color)
					break;
					case 'notImplemented':
						b.outputs[0].value = params.error;
					break
				}
				let bricks = prevState.bricks.map(brick => {
					if(brick.id == brickId){
						return {...b};
					}
					return brick;
				});
				return {bricks};
			}catch(e){
				console.error(e);
			}
		})
	}

	updateMonitor = (text) => {
		this.setState(prevState => {
			try{
				let b = this.state.bricks.find(brick => { return brick.id == "monitor"});
				if(!b){
					b = initialBricks.monitor();
					prevState.bricks.push(b);
				}
				b.outputs[0].value += text;
				b.outputs[0].value.slice(-10000);
				let bricks = prevState.bricks.map(brick => {
					if(brick.id == "monitor"){
						return {...b};
					}
					return brick;
				});
				return {bricks};
			}catch(e){
				console.error(e);
			}
		})
	}
}

function mapStateToProps(state) {
	return {
		simulator: state.simulator
	}
}

const connectedSimulator = connect(mapStateToProps)(Simulator)
export { connectedSimulator as Simulator }