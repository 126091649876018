import {schema} from 'normalizr'
import { structureEntity, userEntity, groupEntity } from './entities';

// export const structureSchema = new schema.Entity('structures', {
// 	owner: userSchema,
// 	learners: usersSchema
// }, { idAttribute: '_id' });

structureEntity.define({
	owner: userEntity,
	learners: [userEntity],
	groups: [groupEntity]
})

export const structureSimpleSchema = structureEntity

export const structureSchema = structureEntity

export const structuresSchema = new schema.Array(structureSchema);
