export const sessionConstants = {
    LOGIN: 'SESSION_LOGIN',
    LOGIN_SUCCESS: 'SESSION_LOGIN_SUCCESS',
    LOGIN_FAILED: 'SESSION_LOGIN_FAILED',
    LOGIN_RESET_STATE: 'SESSION_LOGIN_RESET_STATE',
    REGISTER: 'SESSION_REGISTER',
    REGISTER_SUCCESS: 'SESSION_REGISTER_SUCCESS',
    REGISTER_FAILED: 'SESSION_REGISTER_FAILED',
    REGISTER_RESET_STATE: 'SESSION_REGISTER_RESET_STATE',

    REGISTER_GUEST_STARTED : 'SESSION_REGISTER_GUEST_STARTED',
    REGISTER_GUEST_SUCCEDED : 'SESSION_REGISTER_GUEST_SUCCEDED',
    REGISTER_GUEST_FAILED : 'SESSION_REGISTER_GUEST_FAILED',
    
    LINK_USER_TO_STRUCTURE_STARTED : 'SESSION_LINK_USER_TO_STRUCTURE_STARTED',
    LINK_USER_TO_STRUCTURE_SUCCEEDED : 'SESSION_LINK_USER_TO_STRUCTURE_SUCCEEDED',
    LINK_USER_TO_STRUCTURE_FAILED : 'SESSION_LINK_USER_TO_STRUCTURE_FAILED',
    
    UNLINK_USER_TO_STRUCTURE_STARTED : 'SESSION_UNLINK_USER_TO_STRUCTURE_STARTED',
    UNLINK_USER_TO_STRUCTURE_SUCCEEDED : 'SESSION_UNLINK_USER_TO_STRUCTURE_SUCCEEDED',
    UNLINK_USER_TO_STRUCTURE_FAILED : 'SESSION_UNLINK_USER_TO_STRUCTURE_FAILED',

    FORGOT_PASSWORD:'SESSION_FORGOT_PASSWORD',
    FORGOT_PASSWORD_SUCCESS:'SESSION_FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILED:'SESSION_FORGOT_PASSWORD_FAILED',
    FORGOT_PASSWORD_RESET_STATE:'SESSION_FORGOT_PASSWORD_RESET_STATE',
    RESET_PASSWORD:'SESSION_RESET_PASSWORD',
    RESET_PASSWORD_SUCCESS:'SESSION_RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILED:'SESSION_RESET_PASSWORD_FAILED',
    RESET_PASSWORD_RESET_STATE:'SESSION_RESET_PASSWORD_RESET_STATE',

    SHOW_GUEST_PASS_DONE: 'SESSION_SHOW_GUEST_PASS_DONE'
};