import React, { Component } from "react";
import { connect } from "react-redux";

import "./blockly.css";
import { TOOLBOX, TOOLBOX_GALAXIA } from "./toolbox";
import unloadScript from "../../utils";

import ModalProcedureComponent from "./ModalProcedureComponent";
import ModalVariableComponent from "./ModalVariableComponent";

import { Button } from "semantic-ui-react";

import { blocklyActions } from "../../actions";
import { BLOCKLY_CSS } from "./css";

const Event = {
    BlocklyClickCategory: "BlocklyClickCategory",
    BlocklyCreateBlock: "BlocklyCreateBlock",
    BlocklyConstruction: "construction",
};

const ScriptVersion = {
    BlocklyBase: process.env.REACT_APP_DEFAULT_BLOCKLY_VERSION,
    BlocklyGalaxia: process.env.REACT_APP_GALAXIA_BLOCKLY_VERSION,
};

class Blockly extends Component {
    constructor(props) {
        super(props);
        this.handleClickCategory = this.handleClickCategory.bind(this);
        this.handleCreateBlock = this.handleCreateBlock.bind(this);
        this.GenerateXML = this.GenerateXML.bind(this);

        this.updateToolbox = this.updateToolbox.bind(this);
        this.updateAppChangeListener = this.updateAppChangeListener.bind(this);

        this.loadXml = this.loadXml.bind(this);
        this.resetXml = this.resetXml.bind(this);
        this.updateFromXmlStart = this.updateFromXmlStart.bind(this);
        this.updateXmlSolution = this.updateXmlSolution.bind(this);
        this.console = this.console.bind(this);
        this.workspace = null;
        this.workspaceSol = null;

        this.toolbox = null;
        this.init = false;
        this.counter = 0;

        this.state = {
            openCreateProcedure: false,
            mutator: null,
            callback: null,
            openCreateList: false,
            variableMessage: null,
            variableDefaultValue: null,
            variableCallback: null,
            variableTitle: null,
            variableVarType: null,
        };
    }

    initBlockly() {
        if (!window.Blockly) {
            return;
        }

        if (this.props.version !== ScriptVersion.BlocklyGalaxia) {
            window.Blockly.Procedures.externalProcedureDefCallback = this.createProcedure;
        }
        window.Blockly.prompt = this.createList;

        this.setupBlockly();

        if (!this.props.activityCreator) {
            this.setupEvent();
        }

        this.init = true;
    }

    componentDidMount() {
        console.log("mount");

        console.log("Required version", this.props.version);
        console.log("Current blockly version", this.props.currentVersion);

        if (this.props.version && this.props.currentVersion != this.props.version) {
            console.log("new scripts required");
            this.switchBlockly(this.props.currentVersion, this.props.version);
        } else if (!this.props.version && this.props.currentVersion != ScriptVersion.BlocklyBase) {
            console.log("default scripts required");
            this.switchBlockly(this.props.currentVersion, ScriptVersion.BlocklyBase);
        } else {
            console.log("keep current scripts");
            this.initBlockly();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.lang !== prevProps.lang) {
            this.recreateBlockly();
        }

        if (this.props.readOnly !== prevProps.readOnly) {
            this.recreateBlockly();
        }

        // if (!this.init && !this.props.version) {
        //     this.initBlockly();
        //     if (!this.init) {
        //         return;
        //     }
        // }

        try {
            if (
                this.workspace &&
                (this.workspace.getParentSvg().clientWidth !== document.getElementById("blocklyDiv").offsetWidth ||
                    this.workspace.getParentSvg().clientHeight !== document.getElementById("blocklyDiv").offsetHeight)
            ) {
                window.Blockly.svgResize(this.workspace);
            }
        } catch (e) {
            console.error(e);
        }

        if (!this.props.activityCreator) {
            //window.Blockly.svgResize(this.workspace);
            if (!this.props.ended && prevProps.step && this.props.step && this.props.step._id !== prevProps.step._id) {
                window.Blockly.svgResize(this.workspace);
                // this.workspace.updateToolbox(this.toolbox);
                this.updateFromXmlStart();
                this.workspace.removeChangeListener(this.handleClickCategory);
                this.workspace.removeChangeListener(this.handleCreateBlock);
                this.setupEvent();
                this.updateXmlSolution();
            }

            if (prevProps.xmlReadyToLoad === false && this.props.xmlReadyToLoad === true) {
                this.loadXml();
                this.props.xmlLoaded();
            }

            if ((!prevProps.step && this.props.step) || (prevProps.step && this.props.step && prevProps.step.toolbox != this.props.step.toolbox)) {
                this.updateToolbox();
            }
        } else {
            if (prevProps.xml !== this.props.xml) {
                this.loadXml();
            } else if (prevProps.xmlReadyToLoad === false && this.props.xmlReadyToLoad === true) {
                this.loadXml();
                this.props.xmlLoaded();
            }

            if ((!prevProps.step && this.props.step) || (prevProps.step && this.props.step && prevProps.step.toolbox != this.props.step.toolbox)) {
                this.updateToolbox();
            }
        }

        if (this.props.resetAppRequest === true) {
            this.resetXml();
            this.props.resetAppDone();
        }

        if (this.props.defaultBlock != prevProps.defaultBlock) {
            if (this.workspace) {
                let main = null;
                //search none deletable block
                for (let b of this.workspace.getAllBlocks()) {
                    if (!b.deletable_ && b.previousConnection === null) {
                        main = b;
                        break;
                    }
                }
                if (main) {
                    let children = main.getChildren();
                    let xml = [];
                    for (let child of this.workspace.getAllBlocks()) {
                        if (child.id === main.id || child.parentBlock_) {
                            continue;
                        }

                        let x = window.Blockly.Xml.blockToDomWithXY(child);
                        xml.push(x);
                    }

                    for (let child of children) {
                        let x = window.Blockly.Xml.blockToDomWithXY(child);
                        xml.push(x);
                    }

                    this.workspace.clear();
                    let dom = window.Blockly.Xml.workspaceToDom(this.workspace);
                    for (let child of xml) {
                        dom.appendChild(child);
                    }
                    window.Blockly.Xml.domToWorkspace(dom, this.workspace);
                    let blockSetup = this.workspace.newBlock(this.props.defaultBlock ? this.props.defaultBlock : "setup");
                    blockSetup.deletable_ = false;

                    blockSetup.moveBy(this.workspace.blocksArea_.width / 2, this.workspace.blocksArea_.height / 2);
                    blockSetup.initSvg();
                    blockSetup.render();
                }
            }
        }
    }

    componentWillUnmount() {
        /* Ici, on s'attend à ce que le mainworkspace soit réinitialisé à null.
         *
         * Pour éviter des erreurs liée à l'accès aux propriétés d'un objet encore égal à null
         * lors d'un changement d'interface (Text vers hybride ou blockly principalement),
         * la réinitialisation du mainworkspace est temporairement suspendue.
         *
         * Dans l'attente d'une gestion plus globale de ce problème, le code a ici été commenté
         * et une vérification sommaire a été ajouté sur les condition de la méthode saveBlocklyXml de free_creation_action.js
         *
         * Code Initial :
         */
        // if (this.props.onMainWorkspaceStatusChanged) this.props.onMainWorkspaceStatusChanged(null);
        // if (this.props.onSolutionWorkspaceStatusChanged) this.props.onSolutionWorkspaceStatusChanged(null);
    }

    // Décharge le Blockly actuel au profit de celui attendu
    switchBlockly = (currentBlocklyVersion, requiredVersion) => {
        // Retrait des scripts Blockly actuels
        console.log("Début retirer blockly");

        switch (currentBlocklyVersion) {
            case ScriptVersion.BlocklyBase:
                unloadScript(process.env.REACT_APP_SCRIPT_SRC_1);
                unloadScript(process.env.REACT_APP_SCRIPT_SRC_2);
                unloadScript(process.env.REACT_APP_SCRIPT_SRC_3);
                unloadScript(process.env.REACT_APP_SCRIPT_SRC_4);
                unloadScript(process.env.REACT_APP_SCRIPT_SRC_5);
                unloadScript(process.env.REACT_APP_SCRIPT_SRC_6);
                break;
            case ScriptVersion.BlocklyGalaxia:
                unloadScript(process.env.REACT_APP_GALAXIA_SCRIPT_SRC_1);
                unloadScript(process.env.REACT_APP_GALAXIA_SCRIPT_SRC_2);
                unloadScript(process.env.REACT_APP_GALAXIA_SCRIPT_SRC_3);
                unloadScript(process.env.REACT_APP_GALAXIA_SCRIPT_SRC_4);
                break;
            default:
                console.error("Unexpected blockly version");
                break;
        }

        // Reset du workspace & de l'objet Blockly
        if (this.workspace) {
            this.workspace.dispose();
            this.workspace = null;
        }
        if (this.workspaceSol) {
            this.workspaceSol.dispose();
            this.workspaceSol = null;
        }
        this.init = false;
        window.Blockly = {};

        /* Alternative to window.Blockly = {} :
		   Remove each element from a reconstructed array based on Blockly object 
		   */
        // Object.keys(window.Blockly).forEach(function (key) {
        //     delete window.Blockly[key];
        // });

        console.log("Fin retirer blockly");

        // Chargement des scripts Blockly attendus
        console.log("Start loading");
        const loadScript = (src, callback, id) => {
            if (window.Blockly === {}) {
                console.log(window.Blockly, "Blockly bien déchargé.");
            }
            if (!document) {
                throw new Error("Document was not defined");
            } else {
                const script = document.createElement("script");

                script.type = "text/javascript";
                if (id) script.id = id;
                script.onload = callback;
                // script.addEventListener("load", callback);
                script.src = process.env.PUBLIC_URL + src;
                script.async = false;
                script.defer = false;

                document.head.append(script);
            }
        };

        const callBack = (totalScriptNumber) => {
            this.counter += 1;
            console.log("callback | script " + this.counter + " loaded.");
            if (!this.init) {
                if (this.counter == totalScriptNumber) {
                    if (window.Blockly) {
                        this.counter = 0;
                        this.initBlockly();
                        // "informe" le store que le blocky utilisé à changé
                        this.props.dispatch(blocklyActions.switchBlocklyVersion(requiredVersion));
                    } else {
                        this.counter = 0;
                        throw new Error("Cannot load requiered Blockly's scripts");
                    }
                }
            } else {
                console.log("Blockly already init");
            }
        };

        const removeToolboxStyle = (toRemove) => {
            try {
                for (let style of document.styleSheets) {
                    for (let rule in style.rules) {
                        if (toRemove.includes(style.rules[rule].selectorText)) {
                            style.removeRule(rule);
                        }
                    }
                }
            } catch (e) {
                console.error(e);
            }           
        };

        let toRemove = [".blocklyFlyoutBackground", ".blocklyToolboxDiv", ".blocklyFlyoutButtonBackground", ".blocklyFlyoutButtonShadow", ".blocklySvg"];
        let totalScriptNumber = 0;
        switch (requiredVersion) {
            case ScriptVersion.BlocklyGalaxia:
                removeToolboxStyle(toRemove);

                totalScriptNumber = process.env.REACT_APP_GALAXIA_SCRIPT_NUMBER;
                loadScript(process.env.REACT_APP_GALAXIA_SCRIPT_SRC_1, callBack.bind(this, totalScriptNumber));
                loadScript(process.env.REACT_APP_GALAXIA_SCRIPT_SRC_2, callBack.bind(this, totalScriptNumber));
                loadScript(process.env.REACT_APP_GALAXIA_SCRIPT_SRC_3, callBack.bind(this, totalScriptNumber));
                loadScript(process.env.REACT_APP_GALAXIA_SCRIPT_SRC_4, callBack.bind(this, totalScriptNumber));
                break;
            case ScriptVersion.BlocklyBase:
                removeToolboxStyle(toRemove);

                totalScriptNumber = process.env.REACT_APP_SCRIPT_NUMBER;
                loadScript(process.env.REACT_APP_SCRIPT_SRC_1, callBack.bind(this, totalScriptNumber));
                loadScript(process.env.REACT_APP_SCRIPT_SRC_2, callBack.bind(this, totalScriptNumber));
                loadScript(process.env.REACT_APP_SCRIPT_SRC_3, callBack.bind(this, totalScriptNumber));
                loadScript(process.env.REACT_APP_SCRIPT_SRC_4, callBack.bind(this, totalScriptNumber));
                loadScript(process.env.REACT_APP_SCRIPT_SRC_5, callBack.bind(this, totalScriptNumber));
                loadScript(process.env.REACT_APP_SCRIPT_SRC_6, callBack.bind(this, totalScriptNumber));

                break;
            default:
                console.error("Unexpected blockly version");
                break;
        }
        console.log("End loading");
    };

    setupBlockly() {
        console.log("- Version -", this.props.version);
        if (this.props.version) {
            // surchage du css
            try {
                window.Blockly.Css.register([...BLOCKLY_CSS]);
            } catch (e) {
                console.error(e);
            }
            /**
             * Thingz theme definition for galaxia space only.
             */
            window.Blockly.Themes.Thingz = window.Blockly.Theme.defineTheme("thingz", {
                base: window.Blockly.Themes.Zelos,
            });

            window.Blockly.Themes.Thingz.setComponentStyle("workspaceBackgroundColour", "#F9F9F9");

            window.Blockly.Themes.Thingz.setBlockStyle("display_blocks", { colourPrimary: "#E50E0E" });
            window.Blockly.Themes.Thingz.setBlockStyle("io_blocks", { colourPrimary: "#FFA401" });
            window.Blockly.Themes.Thingz.setBlockStyle("time_blocks", { colourPrimary: "#FFA401" });
            window.Blockly.Themes.Thingz.setBlockStyle("communication_blocks", { colourPrimary: "#5CBB38" });
            window.Blockly.Themes.Thingz.setBlockStyle("sensors_blocks", { colourPrimary: "#5CB1D6" });
            window.Blockly.Themes.Thingz.setBlockStyle("actuators_blocks", { colourPrimary: "#38BBAD" });
            window.Blockly.Themes.Thingz.setBlockStyle("robots_blocks", { colourPrimary: "#87599D" });
            window.Blockly.Themes.Thingz.setBlockStyle("grove_blocks", { colourPrimary: "#FF8C1A" });

            window.Blockly.Themes.Thingz.setCategoryStyle("display_category", { colour: "#E50E0E" });
            window.Blockly.Themes.Thingz.setCategoryStyle("io_category", { colour: "#FFA401" });
            window.Blockly.Themes.Thingz.setCategoryStyle("communication_category", { colour: "#5CBB38" });
            window.Blockly.Themes.Thingz.setCategoryStyle("sensors_category", { colour: "#5CB1D6" });
            window.Blockly.Themes.Thingz.setCategoryStyle("actuators_category", { colour: "#38BBAD" });
            window.Blockly.Themes.Thingz.setCategoryStyle("robots_category", { colour: "#87599D" });
            window.Blockly.Themes.Thingz.setCategoryStyle("grove_category", { colour: "#FF8C1A" });

        }

        console.log("setup");
        //Update this.toolbox before using it for init
        this.updateToolbox();
        let arg, argSol;
        if (this.props.readOnly) {
            arg = {
                media: "/assets/blockly/media/",
                comments: true,
                disable: true,
                collapse: false,
                zoom: {
                    controls: true,
                    wheel: true,
                    startScale: 0.75,
                    maxScale: 4,
                    minScale: 0.25,
                    scaleSpeed: 1.1,
                },
                readOnly: this.props.readOnly ? this.props.readOnly : false,
                scrollbars: true,
                //horizontalLayout:true,
                renderer: "zelos",
            };
            argSol = {
                media: "/assets/blockly/media/",
                comments: true,
                disable: true,
                collapse: false,
                zoom: {
                    controls: true,
                    wheel: true,
                    startScale: 0.75,
                    maxScale: 4,
                    minScale: 0.25,
                    scaleSpeed: 1.1,
                },
                readOnly: this.props.readOnly ? this.props.readOnly : false,
                scrollbars: true,
                //horizontalLayout:true
            };
        } else {
            arg = {
                toolbox: this.toolbox,
                media: "/assets/blockly/media/",
                comments: true,
                disable: true,
                collapse: false,
                zoom: {
                    controls: true,
                    wheel: true,
                    startScale: 0.75,
                    maxScale: 4,
                    minScale: 0.25,
                    scaleSpeed: 1.1,
                },
                //horizontalLayout:true,
                renderer: "zelos",
            };
            argSol = {
                media: "/assets/blockly/media/",
                comments: true,
                disable: true,
                collapse: false,
                zoom: {
                    controls: true,
                    wheel: true,
                    startScale: 0.75,
                    maxScale: 4,
                    minScale: 0.25,
                    scaleSpeed: 1.1,
                },
                toolbox: this.toolbox,
            };
        }

        // setTimeout(()=>{this.workspace = window.Blockly.inject('blocklyDiv', {toolbox: this.toolbox});}, 10000)

        this.workspace = window.Blockly.inject("blocklyDiv", arg);
        this.workspaceSol = window.Blockly.inject("blocklySolDiv", argSol);

        if (this.props.onMainWorkspaceStatusChanged) this.props.onMainWorkspaceStatusChanged(this.workspace);

        if (this.props.onSolutionWorkspaceStatusChanged) this.props.onSolutionWorkspaceStatusChanged(this.workspaceSol);

        this.loadXml();
        this.updateXmlSolution();
        this.init = true;

        if (this.workspace) this.workspace.addChangeListener(this.updateAppChangeListener);
        // this.workspace.addChangeListener(window.Blockly.Events.disableOrphans);

        if (this.props.version) {
            this.workspace.setTheme(window.Blockly.Themes.Thingz);
        } else {
        }
        console.log("-end setup-");
    }

    recreateBlockly() {
        console.log("Recreate");
        //détruire Blockly

        if (this.workspace) {
            this.workspace.dispose();
            this.workspace = null;
        }
        if (this.workspaceSol) {
            this.workspaceSol.dispose();
            this.workspaceSol = null;
        }
        this.init = false;
        ////réinitialiser Blockly
        this.initBlockly();
    }

    updateAppChangeListener(event) {
        // console.log(window.Blockly.Xml.domToPrettyText(window.Blockly.Xml.workspaceToDom(this.workspace)))
        window.Blockly.Events.disableOrphans(event);
        if (event.element === "category" || event.type === window.Blockly.Events.CREATE || event.type === window.Blockly.Events.UI) {
            return;
        }

        if (this.props.autoSave) {
            let xml, code;
            try {
                // console.log("SAME", this.workspace === window.Blockly.getMainWorkspace())
                // console.log(window.Blockly.Xml.domToPrettyText(window.Blockly.Xml.workspaceToDom(window.Blockly.getMainWorkspace())))
                xml = window.Blockly.Xml.domToPrettyText(window.Blockly.Xml.workspaceToDom(this.workspace));
                // console.log(window.Blockly.Xml.domToPrettyText(window.Blockly.Xml.workspaceToDom(window.Blockly.getMainWorkspace())))
                //mode pour définir le workspaceToCode
                if (this.props.codeGeneration === "c_cpp") {
                    code = window.Blockly.Arduino.workspaceToCode(this.workspace);
                } else if (this.props.codeGeneration === "python") {
                    if (this.props.version && this.props.version == ScriptVersion.BlocklyGalaxia) {
                        code = window.Blockly.Galaxia.workspaceToCode(this.workspace);
                    } else {
                        code = window.Blockly.Python.workspaceToCode(this.workspace);
                    }
                } else {
                    code = window.Blockly.Arduino.workspaceToCode(this.workspace);
                }
            } catch (error) {
                console.log("blockly error in BlocklyComponent:", error);
                if (this.props.codeGeneration === "c_cpp") {
                    code = "//Ooops il y a eu un problème lors de la génération de ton code \n\n\nvoid setup(){\n\n}\n\nvoid loop(){\n\n}\n";
                } else {
                    code = "#Les briques wifi et bluetooth ne sont pas encore\n#configurées pour le mode Python... patience !\ndef setup():\n\tpass\n\ndef loop():\n\tpass";
                }
            }
            // console.log("XML", xml);
            // console.log("CODE", code);
            this.props.autoSave(xml, code);
        }
    }

    updateToolbox() {
        if (this.props.readOnly === true) {
            return;
        }

        if (this.props.step && this.props.step.toolbox) {
            this.toolbox = this.props.step.toolbox;
        } else {
            this.toolbox = this.props.version === ScriptVersion.BlocklyGalaxia ? TOOLBOX_GALAXIA : TOOLBOX;
        }

        if (this.workspace) {
            //There is a problem with block id. If two blocks have same id or if type is equal
            this.workspace.updateToolbox('<xml id="toolbox" style="display: none"><category name="%{BKY_CATEGORY_CONTROL}" id="control" colour="#FFAB19" secondaryColour="#CF8B17"></category></xml>');
            this.workspace.updateToolbox(this.toolbox);
        }
    }

    resetXml() {
        //reset was done, xml now contains previousXml
        this.workspace.clear();

        if (this.props.activityCreator) {
            let blockSetup = this.workspace.newBlock(this.props.defaultBlock ? this.props.defaultBlock : "setup");
            blockSetup.deletable_ = false;
            blockSetup.moveBy(this.workspace.blocksArea_.width / 2, this.workspace.blocksArea_.height / 2);
            blockSetup.initSvg();
            blockSetup.render();
        } else {
            let xml = this.props.xml;
            try {
                //We used to double stringify the xml (using JSON.stringify in updateAppAvancement while it's unecessary)
                //We don't kmow if the xml is double stringify or not so we try to parse it
                xml = JSON.parse(this.props.xml);
            } catch (e) {
                xml = this.props.xml;
            }
            window.Blockly.Xml.domToWorkspace(window.Blockly.Xml.textToDom(xml), this.workspace);
        }
    }

    updateFromXmlStart() {
        // console.log('updateFromXmlStart')
        if (this.props.step && this.props.step.xmlStart) {
            // console.log('updateFromXmlStart if passed')
            this.workspace.clear();
            window.Blockly.Xml.domToWorkspace(window.Blockly.Xml.textToDom(this.props.step.xmlStart), this.workspace);
        }
    }

    updateXmlSolution() {
        if (this.props.step && this.props.step.xmlSolution) {
            // console.log('updateXmlSolution !')
            this.workspaceSol.clear();
            window.Blockly.Xml.domToWorkspace(window.Blockly.Xml.textToDom(this.props.step.xmlSolution), this.workspaceSol);
        }
    }

    loadXml() {
        if (!this.workspace) return;
        this.workspace.clear();
        //Remise à zero uniquement en creation libre si nouveau projet. L'idée est de supprimer les blocs de variables créés dans un autre projet.
        // if (!this.props.step && !this.props.activityCreator) this.workspace.updateToolbox(TOOLBOX);

        // if (this.props.activityCourse && this.props.xml) {
        //     let regex = RegExp('<block', 'g');
        //     let matches = [...this.props.xml.matchAll(regex)];
        //     console.log('1')
        //     if (matches.length === 1 && this.props.step.xmlStart) {
        //         this.updateFromXmlStart();
        //         return;
        //     }
        // }

        if (this.props.activityCourse && !this.props.xml && this.props.step.xmlStart) {
            this.updateFromXmlStart();
            return;
        }

        if (this.props.xml) {
            let xml = this.props.xml;
            try {
                //We used to double stringify the xml (using JSON.stringify in updateAppAvancement while it's unecessary)
                //We don't kmow if the xml is double stringify or not so we try to parse it
                xml = JSON.parse(this.props.xml);
            } catch (e) {
                xml = this.props.xml;
            }
            // xml = '<xml xmlns="http://www.w3.org/1999/xhtml">\r\n  <variables></variables>\r\n  <block type="setup" id="ob,B8P@#n*.y]kLYTl!y" deletable="false" x="57" y="123"></block>\r\n</xml>';
            // '<xml xmlns="https://developers.google.com/blockly/xml"></xml>';
            // console.log("preXML", xml)
            try{
                window.Blockly.Xml.domToWorkspace(window.Blockly.Xml.textToDom(xml), this.workspace);
            }catch(e){
                if(this.props.onLoadXmlError)
                    this.props.onLoadXmlError(e);
            }
            //console.log(window.Blockly.Xml.workspaceToDom(this.workspace));
        } else {
            let blockSetup = this.workspace.newBlock(this.props.defaultBlock ? this.props.defaultBlock : "setup");
            blockSetup.deletable_ = false;
            blockSetup.moveBy(document.getElementsByClassName("blocklySvg")[0].clientWidth / 2, document.getElementsByClassName("blocklySvg")[0].clientHeight / 2);
            // blockSetup.moveBy(100, 75);
            blockSetup.initSvg();
            blockSetup.render();
        }
        // this.workspace.refreshToolboxSelection_();
    }

    setupEvent() {
        if (this.props.step !== undefined) {
            switch (this.props.step.event) {
                case Event.BlocklyClickCategory:
                    this.onClickCategory();
                    break;
                case Event.BlocklyCreateBlock:
                    this.onCreateBlock();
                    break;
                default:
                    break;
            }
        }
    }

    GenerateXML() {
        const xml = window.Blockly.Xml.domToPrettyText(window.Blockly.Xml.workspaceToDom(this.workspace));
        console.log("workspaceToDom", window.Blockly.Xml.workspaceToDom(this.workspace));
        console.log("XML", JSON.stringify(xml));
    }

    onClickCategory() {
        this.workspace.addChangeListener(this.handleClickCategory);
    }

    handleClickCategory(event) {
        if (event.type === window.Blockly.Events.UI && event.element === "category" && event.newValue === this.props.step.eventTarget) {
            this.workspace.removeChangeListener(this.handleClickCategory);
            this.props.onStepDone();
        }
    }

    onCreateBlock() {
        this.workspace.addChangeListener(this.handleCreateBlock);
    }

    handleCreateBlock(event) {
        if (event.type === window.Blockly.Events.CREATE && this.workspace.getBlockById(event.blockId).type === this.props.step.eventTarget) {
            console.log("detected");
            this.workspace.removeChangeListener(this.handleCreateBlock);
            this.props.onStepDone();
        }
    }

    console(e) {
        this.GenerateXML();
        console.log("BLOCKLY", window.Blockly);
        console.log("BLOCKS", this.workspace);
    }

    createProcedure = (mutator, callback) => {
        this.setState({ openCreateProcedure: true, mutator, callback });
    };

    onModalProcedureClose = () => {
        this.setState({ openCreateProcedure: false });
        this.workspace.refreshToolboxSelection_();
        this.workspace.toolbox_.scrollToCategoryById("myBlocks");
    };

    createList = (message, defaultValue, callback, _opt_title, _opt_varType) => {
        // console.log("BLOCKLY", message, defaultValue, _opt_title,  _opt_varType)
        this.setState({ openCreateList: true, variableMessage: message, variableDefaultValue: defaultValue, variableCallback: callback, variableTitle: _opt_title, variableType: _opt_varType });
    };

    onModalListClose = () => {
        this.setState({ openCreateList: false });
    };

    render() {
        return (
            <div style={{ height: 100 + "%", width: 100 + "%" }}>
                <div id="blocklyDiv" style={{ height: 100 + "%", width: 100 + "%" }}></div>
                <div>
                    <div id="blocklySolDiv"></div>
                </div>
                <ModalProcedureComponent open={this.state.openCreateProcedure} onClose={this.onModalProcedureClose} mutator={this.state.mutator} callback={this.state.callback} />
                <ModalVariableComponent
                    open={this.state.openCreateList}
                    onClose={this.onModalListClose}
                    message={this.state.variableMessage}
                    defaultValue={this.state.variableDefaultValue}
                    callback={this.state.variableCallback}
                    title={this.state.variableTitle}
                    type={this.state.variableType}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentVersion: state.blockly.currentVersion,
    };
}

const connectedBlockly = connect(mapStateToProps)(Blockly);
export { connectedBlockly as Blockly, ScriptVersion };
