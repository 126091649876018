import React, {Component} from 'react';
import {CSSTransitionGroup} from 'react-transition-group'
import DOMPurify from 'dompurify';

import './ChannelMessageComponent.css'

import {modalConstants} from "../../constants";

export default class ChannelMessageComponent extends Component{

    // constructor(props){
    //     super(props)
    //     // this.state = {
    //     //     hidden: "hidden"
    //     // }
    // }

    // componentWillMount(){
    //     setTimeout(() => {
    //         this.show();
    //     }, this.props.wait);
    // }

    renderClassInstruction() {
        if (this.props.message.type === modalConstants.MODAL_MESSAGE_TYPE.CONSIGNE) {
            return 'consigne'
        }
        return ''
    }

    show(){
        this.setState({hidden: ""});
    }

    render(){
        return (
            <CSSTransitionGroup
                        transitionName="message"
                        transitionAppear={true}
                        transitionAppearTimeout={this.props.wait}
                        transitionEnterTimeout={0}
                        transitionLeaveTimeout={100}>
                <div className={'message'}>
                    <div className=''>
                        <div className={'message-img-container ' + this.props.message.author.class}>
                        </div>
                    </div>
                    <div className='message-body'>
                        <div className='message-body-header'>
                            {this.props.message.author && <p className='message-body-header-author'>{this.props.message.author.name}</p>}
                            {this.props.message.title && <p className='message-body-header-title'>{this.props.message.title}</p>}
                        </div>
                        <div className='message-body-content'>
                            {this.props.message.text && <p className={this.renderClassInstruction()} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.message.text)}}></p>}
                            {this.props.message.img && <img alt={"perso_profil"} className={'message-body-content-image'} src={this.props.message.img}/>}
                            {this.props.message.link && <a href={this.props.message.link}>{this.props.message.link}</a>}
                        </div>
                    </div>
                </div>
            </CSSTransitionGroup>
        );
    }
};
