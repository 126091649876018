import 
    // React,
    {Component} from 'react';
import {connect} from 'react-redux';
import {confirmUser} from "../../actions/session_actions";

class ConfirmUser extends Component{

    componentDidMount(){
    	console.log(this.props.match)
        this.props.dispatch(confirmUser(this.props.match.params.confirmation))
    }

    render(){
    	return null;
    }
}

function mapStateToProps(state) {
	return {
		
	}
}

const connectedConfirmUser = connect(mapStateToProps)(ConfirmUser)
export { connectedConfirmUser as ConfirmUser }