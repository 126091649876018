import { connect } from "react-redux";
import React, { Component } from "react";

// Import Brace and the AceEditor Component
import brace from "brace";
import AceEditor from "react-ace";
import "brace/mode/c_cpp";
import "brace/mode/python";
import "brace/mode/xml";
import "brace/theme/merbivore_soft";
import { Grid } from "semantic-ui-react";

export default class Ace extends Component {
    constructor(props, context) {
        super(props, context);
        this.timer = null;
        this.state = {
            initCpp: false,
            initPython: false,
        };
    }

    componentDidMount() {
        this.initAce();
    }

    componentDidUpdate() {
        this.initAce();
        this.refs.aceMonitor.editor.renderer.updateFull();
        this.refs.aceMonitor.editor.resize();
    }

    initAce() {
        if (!this.props.synchroCode) {
            // pas de methode sync
            return;
        }
        // methode sync
        let isNotInit = this.isNotInit();
        if (!this.props.value && isNotInit) {
            // pas de code ET premier passage
            this.props.synchroCode();
            this.updateInit();
        } else if (isNotInit) {
            // du code ET premier passage
            this.updateInit();
        } else {
            // du code ET pas le premier passage
        }
    }

    isNotInit() {
        return (!this.state.initCpp && this.props.mode === "c_cpp") || (!this.state.initPython && this.props.mode === "python");
    }

    updateInit() {
        let s = {};
        if (this.props.mode === "c_cpp") {
            s.initCpp = true;
        } else {
            s.initPython = true;
        }
        this.setState(s);
    }

    onChange = (code) => {
        this.props.autoSaveAce(code);
    };

    render() {
        return (
            <AceEditor
                mode={this.props.mode}
                theme="merbivore_soft"
                readOnly={this.props.readOnly}
                name="aceMainDiv"
                value={this.props.value || ""}
                editorProps={{
                    $blockScrolling: Infinity,
                }}
                width="auto"
                height="100%"
                fontSize={15}
                ref="aceMonitor"
                onChange={this.onChange}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        // user: getActualUser(state),
        // structures: state.model.structures,
    };
};

const connectedAceStore = connect(mapStateToProps)(Ace);
export { connectedAceStore as Ace };
