import {sessionService} from 'redux-react-session';
import * as apiUser from '../api/sessionApi';
import * as api from "../api"
import * as apiPassword from '../api/forgotPasswordApi';
import * as apiReset from '../api/resetPasswordApi';
import {sessionConstants} from '../constants/session_constants';
import {modelConstants, freeCreationConstants, structureConstants} from '../constants'
import {socketIoActions} from './'
 
import { userSchema, structureSchema } from "../schemas";

import history from '../history'

import {normalize} from 'normalizr'

export const login = (user, history) => {
    return (dispatch) => {
        dispatch({type:sessionConstants.LOGIN});
        return apiUser.loginUser(user).then(response => {
            const {token} = response;
            dispatch({type:sessionConstants.LOGIN_SUCCESS});
            sessionService.saveSession({token})
                .then(() => {
                    sessionService.saveUser(response.data)
                        .then(() => {
                            history.push('/');
                        }).catch(err => console.error(err));
                }).catch(err => console.error(err));
        },
        error => {
            dispatch({type: sessionConstants.LOGIN_FAILED, error});
        }) 
    };
};

export const loginResetState = () => {
    return {type: sessionConstants.LOGIN_RESET_STATE};
}

export const registerResetState = () =>{
    return {type: sessionConstants.REGISTER_RESET_STATE};
}

export const leaveStructure = () => {
    return async (dispatch, getState) => {
        dispatch({type:sessionConstants.UNLINK_USER_TO_STRUCTURE_STARTED})

        return api.structureApi.unlinkUserFromStructure().then(response => {
            dispatch({type:sessionConstants.UNLINK_USER_TO_STRUCTURE_SUCCEEDED, payload: response})
            sessionService.saveSession(response.data.jwt)
            .then(() => {

                sessionService.saveUser(response.data)
                    .then(() => {
                        console.log(response.data.user)
                        history.push('/courses')
                        
                        const entities = normalize(response.data.user, userSchema)
                      
                        dispatch({type: modelConstants.FETCH_ENTITIES_DONE, originalEntities: "users", entities})
                        
                    }).catch(err => console.error(err));    
            }).catch(err => console.error(err)); 
        }, error => {
            console.log(error)
            dispatch({type:sessionConstants.UNLINK_USER_TO_STRUCTURE_FAILED, error})
        })    
    }    
}

export const registerGuest = (structure, history, username, password, group) => {
    
    return async (dispatch, getState) => {
        // Si l'utilisateur a déja un compte utilisateur, nous le lions simplement à la structure et ne créons pas de compte.
        if (getState().session.authenticated && getState().session.checked) {
            dispatch({type:sessionConstants.LINK_USER_TO_STRUCTURE_STARTED})
            if(group)
                structure.group = { _id:group}
            return api.structureApi.linkUserToStructure(structure).then(response => {
                dispatch({type:sessionConstants.LINK_USER_TO_STRUCTURE_SUCCEEDED, payload: response})
                sessionService.saveSession(response.data.jwt)
                .then(() => {
                    sessionService.saveUser(response.data)
                        .then(() => {
                            history.push('/courses')
                        }).catch(err => console.error(err));
                }).catch(err => console.error(err));
            }, error => {
                console.log(error)
                dispatch({type:sessionConstants.LINK_USER_TO_STRUCTURE_FAILED, error})
            })
        }

        dispatch({type:sessionConstants.REGISTER_GUEST_STARTED})

        const g = group ? {_id:group} : null
        const datas = {
            ...structure,
            userType: 'guest',
            username,
            password,
            group:g
        }
        return apiUser.registerGuest(datas).then(response => {
            dispatch({type: sessionConstants.REGISTER_GUEST_SUCCEDED, payload: response});
            sessionService.saveSession(response.data.jwt)
            .then(() => {
                sessionService.saveUser(response.data)
                    .then(() => {
                        history.push('/courses')
                    }).catch(err => console.error(err));
            }).catch(err => console.error(err));
        },
        error => {
            console.log(error.message)
            dispatch({type: sessionConstants.REGISTER_GUEST_FAILED, error})
        })
    }
}

export const register = (datas, history) => {
    return (dispatch) => {
        dispatch({type:sessionConstants.REGISTER});

        return apiUser.registerUser(datas).then(response => {
            const {token} = response;
            dispatch({type:sessionConstants.REGISTER_SUCCESS});
            sessionService.saveSession({token})
                .then(() => {
                    sessionService.saveUser(response.data)
                        .then(() => {
                            history.push('/')
                        }).catch(err => console.error(err));
                }).catch(err => console.error(err));
        },
        error => {
            dispatch({type: sessionConstants.REGISTER_FAILED, error});
        });

    };
};


export const logout = (hist, redirectTo) => {
    console.log("logout")
    return (dispatch) => {
        if(!hist)
            hist = history
        redirectTo = redirectTo ? redirectTo : "/login"

        sessionService.deleteUser().then(()=>{
            sessionService.deleteSession().then(()=>{
                localStorage.removeItem('thingz_free_crea_xml');
                // ToDo : ? localStorage.removeItem('thingz_galaxia_space_xml');
                localStorage.removeItem("structure")
                dispatch({type: modelConstants.INVALIDATE_ENTITIES});
                dispatch(socketIoActions.close());
                hist.push(redirectTo);
            }
            ).catch(()=>{console.log(redirectTo);hist.push(redirectTo)});
        }).catch(()=>{console.log(redirectTo);hist.push(redirectTo);})
        
    }
};

export const confirmUser = (userToken) =>{
    console.log('session_actions.confirmUser() -> userToken',userToken)

    return (dispatch) => {
        return apiUser.confirmUser(userToken).then(response => {

            sessionService.saveUser({jwt: userToken, user: response.data})
            .then(() => {
                history.push('/');
            }).catch(err => {
                console.error(err)
                history.push('/');
            });
        })
        .catch(err => {
            console.log(err)
            history.push('/');
        })
    }
}

export const forgotPassword = (user)=>{
    return(dispatch)=>{
        dispatch({type:sessionConstants.FORGOT_PASSWORD})
        return apiPassword.forgotPassword(user).then(response =>{
            dispatch({type:sessionConstants.FORGOT_PASSWORD_SUCCESS,success:response})
            history.push('/forgotPassword');

        })
        .catch(error => {
            dispatch({type:sessionConstants.FORGOT_PASSWORD_FAILED,error})
            history.push('/forgotPassword');
 
           
        })
    };
   
}

export const forgotPasswordResetState = () =>{
    return {type: sessionConstants.FORGOT_PASSWORD_RESET_STATE};
}

export const resetPassword = (user) =>{
    return(dispatch)=>{
        dispatch({type:sessionConstants.RESET_PASSWORD})
        return apiReset.resetPassword(user).then(response=>{
            dispatch({type:sessionConstants.RESET_PASSWORD_SUCCESS,success:response})
            //history.push('/resetPassword');

        })
        .catch(error => {
            dispatch({type:sessionConstants.RESET_PASSWORD_FAILED,error})
            //history.push('/resetPassword');

            })
    };
}

export const resetPasswordResetState = () =>{
    return {type: sessionConstants.FORGOT_PASSWORD_RESET_STATE};
}

export const showGuestPassDone = () => {
    return {type: sessionConstants.SHOW_GUEST_PASS_DONE};
}