import React, {Component} from 'react';

import {connect} from "react-redux";
import {Container, Grid} from "semantic-ui-react"

import Bandeau from "../../components/Bandeau/Bandeau";
import SideMenu from "../../components/SideMenu/SideMenu";

import HeaderMain from "../../components/HeaderMain/HeaderMain";

import {getActualUser} from "../../reducers/user_reducer";

import {modelActions} from "../../actions/model";
import {structuresActions} from "../../actions/model";
import { groupEntity } from '../../schemas/entities';

class PageWithSideMenu extends Component {

    componentDidMount() {
        if(this.props.user && !this.props.dontFetch){
            this.fetch();
        }
    }

    componentWillMount(){
        //this.props.dispatch(modelActions.invalidate())
    }


    componentDidUpdate(prevProps){
        if(!prevProps.user && this.props.user && !this.props.dontFetch){
            this.fetch();
        }
    }

    fetch(){
        this.props.dispatch(structuresActions.fetchStructures(this.props.ignoreStructureLearners));
    }
    render() {
        
        let user=this.props.user ? this.props.users.entities[this.props.user._id] : null;

        let group;
        if(user && user.group && this.props.groups.entities[user.group]){
            group = this.props.groups.entities[user.group];
        }else{
            group = null
        }
        let structure
        if(group && this.props.structures.entities[group.structure]){
            structure = this.props.structures.entities[group.structure]
        }else if(user && this.props.structures.entities[user.structure]){
            structure = this.props.structures.entities[user.structure];
        }else{
            structure = null
        }
        let structureOwned = user ? this.props.structures.entities[user.structureOwned] : undefined;
        return (
            <div style={{flex: 1}}>
                <HeaderMain dontFetch={true}/>
                <Bandeau/>
                <Grid stackable container relaxed style={{marginTop:'10px'}}>
                    <Grid.Row columns='equal'>
                        {user && <Grid.Column>
                            <SideMenu pageName={this.props.pageName} user={this.props.user} structure={structure} group={group} structureOwned={structureOwned}/>
                        </Grid.Column>
                        }
                        <Grid.Column width={12}>
                        
                            {this.props.children}    

                        </Grid.Column>
                     
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: getActualUser(state),
        users: state.model.users,
        structures: state.model.structures,
        groups: state.model.groups
    }
}

const connectedPageWithSideMenu = connect(mapStateToProps)(PageWithSideMenu);
export { connectedPageWithSideMenu as PageWithSideMenu }
