import {schema} from 'normalizr'
import { structureEntity, groupEntity, userEntity } from './entities';


groupEntity.define({
	structure: structureEntity,
	learners: [userEntity]
})

export const groupSchema = groupEntity

export const groupsSchema = new schema.Array(groupSchema);
