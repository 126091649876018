export const appsConstants = {
    'GET_APPS_STARTED': 'APP_GET_APPS_STARTED',
    'GET_APPS_SUCCEEDED': 'APP_GET_APPS_SUCCEEDED',
    'GET_APPS_FAILED': 'APP_GET_APPS_FAILED',

    'FILTER_APPS': 'APP_FILTER_APPS',
    'RESET_APPS': 'APP_RESET_APPS',

    'DELETE_APP_STARTED': 'APP_DELETE_STARTED',
    'DELETE_APP_FAILED': 'APP_DELETE_FAILED',
    'DELETE_APP_SUCCEED': 'APP_DELETE_SUCCEED',
};