import React from 'react'
import md5 from 'js-md5'
import Jazzicon from 'react-jazzicon'

const ProfilePicture = props => {
		function compute(){
			let count = new TextEncoder().encode(md5(props.email.toLowerCase()));
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      return count = count.reduce(reducer)*10000000;

		}
		let diameter = props.diameter || 100
		return (
			<Jazzicon diameter={diameter} seed={compute()}/>
		)
}

export {ProfilePicture};