import React, {Component} from 'react';
import {Grid, Modal, Button, Header, Image} from 'semantic-ui-react'
import {connect} from 'react-redux';

import CoursesComponent from "../components/CoursesComponent/CoursesComponent";
import HeaderMain from "../components/HeaderMain/HeaderMain";
import Bandeau from "../components/Bandeau/Bandeau";
import SideMenu from "../components/SideMenu/SideMenu";

import {getActualUser} from "../reducers/user_reducer";

import {coursesActions} from "../actions/model";
import {progressionsActions} from "../actions/model";
import {modelActions} from "../actions/model";
import {structureActions} from "../actions";
import * as sessionActions from "../actions/session_actions";

import {PageWithSideMenu} from "./PageWithSideMenu";

import profil from '../img/miss.svg'


class App extends Component {

    componentDidMount() {
        document.title = 'Liste des parcours - Thingz'
        if(this.props.user){
            this.fetch();
        }
    }

    componentWillMount(){
        this.props.dispatch(modelActions.invalidate())
    }


    componentDidUpdate(prevProps){
        if(!prevProps.user && this.props.user){
            this.fetch();
        }
    }

    fetch(){
        this.props.dispatch(coursesActions.fetchForUser());
        this.props.dispatch(progressionsActions.fetchAll());
    }

    deleteProgression = (idcallback) => {
        console.log(`idAPP callback ===> ${idcallback} `)
        const p = this.props.progressions.entities;
        console.log('---___ progression ___---');
        console.log(p);
        console.log('--------------------');
        const pId = Object.keys(p).find(id => { // <= callback qui va retourner l'id si les conditions sont bonnes
                        const progression = this.props.progressions.entities[id]; // on recupère l'id de la progression (string)
                        const step = this.props.steps.entities[progression.step]; // on récupère la step de l'id ci-dessus
                        return  step && step.course == idcallback && progression.user === this.props.user._id // si la l'id de parcours lié à la step  est le même que le idcallback on retourne l'id du parcours
        })
        if(pId){
            this.props.dispatch(progressionsActions.deleteProgress(pId)); // si il return un id alors on peux dispatcher l'action
        }
    }

    showGuestPassDone = () => {
        this.props.dispatch(sessionActions.showGuestPassDone());
    }

    render() { 
        let cookieUser = this.props.user
        let user = null
        if(cookieUser)
            user={...this.props.users.entities[cookieUser._id]};

        let group;
        if(user && user.group && this.props.groups.entities[user.group]){
            group = this.props.groups.entities[user.group];
        }else{
            group = null
        }
        let structure;
        if(group && this.props.structures.entities[group.structure]){
            structure = this.props.structures.entities[group.structure]
        }else if(user && this.props.structures.entities[user.structure]){
            structure = this.props.structures.entities[user.structure];
        }else{
            structure = null
        }
        if(user){
            user.group = group
            user.structure = structure
        }
        return (
            <PageWithSideMenu pageName={"courses"}>
                {this.props.guest.password && 
                    <Modal defaultOpen={true} closeIcon onClose={this.showGuestPassDone}>
                        <Modal.Header>Bienvenue</Modal.Header>
                        <Modal.Content image>
                          <Image wrapped size='small' src={profil} />
                          <Modal.Description>
                            <Header>Ton compte a bien été créé</Header>
                            <p>Pour pouvoir réutiliser ton compte <b>souviens toi bien de ton mot de passe</b>:</p>
                            <p style={{textAlign:"center"}}><h1>{this.props.guest.password}</h1></p>
                          </Modal.Description>
                        </Modal.Content>
                     </Modal>

                }
                <CoursesComponent
                    courses={this.props.courses}
                    user={user}
                    progressions={this.props.progressions}
                    steps={this.props.steps}
                    finishedCourses={this.props.finishedCourses}
                    role = {this.props.user? this.props.user.role : null}
                    callbackAppDeleteProgress = {this.deleteProgression}
                    structures = {this.props.structures}
                    users = {this.props.users}
                />
            </PageWithSideMenu>
        );
    }
}


function mapStateToProps(state) {
    return {
        courses: state.model.courses,
        progressions: state.model.progressions,
        steps: state.model.steps,
        finishedCourses: state.model.finishedCourses,
        user: getActualUser(state),
        structures: state.model.structures,
        groups:state.model.groups,
        users: state.model.users,
        guest: state.guestAccount
    }
}

export default connect(mapStateToProps)(App);
