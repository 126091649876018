import React, { Component, Fragment } from 'react';

import {
  Label,
  Popup,
  List,
  Icon,
  Transition,
  Header
} from 'semantic-ui-react'

import "./NotificationComponent.css"

class NotificationComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visibility: false,
      textMessage: '',
      messageDiplay: {
        color: 'black',
        icon: ''
      }
    }

    this.messagesDiplay = [
      {format: 'info', color: 'blue', icon: 'info circle'},
      {format: 'success', color: 'green', icon: 'check circle outline'},
      {format: 'warning', color: 'orange', icon: 'exclamation triangle'},
      {format: 'error', color: 'red', icon: 'times'},
    ];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.notification !== this.props.notification) {  
      if (this.props.notification.messages && this.props.notification.messages.length) {
        for (let e of this.messagesDiplay) {
          if (e.format === this.props.notification.messages[0].format) {
            let messageDiplay = {...this.state.messageDiplay};
            messageDiplay.color = e.color;
            messageDiplay.icon = e.icon;
            this.setState({visible: true, textMessage: this.props.notification.messages[0].text, messageDiplay});
            setTimeout(() => {
              this.setState({textMessage: '', visible: false});
            }, 5000);
            break;
          }
        }
      }
    }
  }

  renderNotificationMessages() {
    if (this.props.notification && this.props.notification.messages && this.props.notification.messages.length) {
      let messages = [];
      this.props.notification.messages.forEach((message, i) => {
        messages.push(
          <List.Item key={i}>
            <div className='notif_item'>
              <Icon color={this.state.messageDiplay.color} name={this.state.messageDiplay.icon} /> 
              <div>
              <Header className='notif_item_header' as='h3' color={this.state.messageDiplay.color}>
                {message.text}
              </Header>
              {message.type === 'SPREADSHEET_SEND_DATA' && this.props.user && this.props.user.spreadsheetUrl && (<a id='spreadsheetUrl_link' href={this.props.user.spreadsheetUrl} target='_blank'>Lien vers la feuille Google Sheets</a>)}
              </div>
            </div>
          </List.Item>);
      });
      return messages;
    }
  } 

  render() {
    return (
      <Fragment>
        <Transition.Group as="span" animation='fade right' duration={400}>
        {this.state.visible && (
            <Label basic pointing='right' horizontal size='large' color={this.state.messageDiplay.color}><Icon color={this.state.messageDiplay.color} name={this.state.messageDiplay.icon} /> {this.state.textMessage}</Label>
            )}
        </Transition.Group>
        <Popup id={this.props.notification && this.props.notification.messages && this.props.notification.messages.length ? 'NotifPopup' : ''} trigger={<Label size='large' color='yellow'><Icon style={{margin:0}} name='bell outline' /></Label>} wide='very' hoverable position='bottom center'>
          <Popup.Header>{this.props.notification && this.props.notification.messages.length ? 'Notifications' : 'Aucune notification'}</Popup.Header>
          <Popup.Content>
              <List size='huge' celled>
              {this.renderNotificationMessages()}
              </List>
          </Popup.Content>
        </Popup>
      </Fragment>
    );
  }
}

export {NotificationComponent};
