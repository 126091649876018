import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getActualUser, getUser } from "../../reducers/user_reducer";
import {withRouter} from "react-router-dom";

import HeaderMain  from '../../components/HeaderMain/HeaderMain'
import doc from '../../img/doc.svg'

import {
	Grid,
    Button,
    Segment,
    Form,
    Header,
    Divider,
    Image,
    Message,
    List,
    Dropdown,
    Label
} from 'semantic-ui-react'

import {
    structuresActions
} from '../../actions/model';

import {
    modelConstants
} from '../../constants'

import {registerGuest} from '../../actions/session_actions';

import runtimeEnv from '@mars/heroku-js-runtime-env';

class GuestAccess extends Component {
    constructor(props){
        super(props)
        this.state = {
            username: '',
            password: "",
            groups: null
        }
    }

    componentDidMount(){
        this.props.dispatch(structuresActions.fetchStructureByPin(this.props.match.params.pin))
    }

    componentDidUpdate(prevProps){
        let structure = Object.values(this.props.structures.entities).find(s => {
            if(s.pin === this.props.match.params.pin)
                return s
        })
        let user = this.props.users.entities[this.props.user] ? this.props.users.entities[this.props.user] : this.props.user
        if(this.props.structures.isLoading < prevProps.structures.isLoading && this.props.structures.isLoading === 0){
            if(this.props.structures.error || !structure){
                this.props.history.push('/');
            }else if(user && (Object.values(this.props.groups.entities).length == 0 || user.group)){
                //This structure doesn't have groups, register the user automatically
                this.props.dispatch(registerGuest(this.props.match.params, this.props.history, null, null, user.group))
            }
        }
    }

    onChange = (e) => {
        const {value, name} = e.target;
        switch(name){
            case "username":
                this.setState({username: value})
            break;
            case "password":
                this.setState({password: value})
            break
        }
        
    }

    onGroupChange = (e, data) => {
        this.setState({group: data.value})
    }

    onSubmit = (e) => {
        e.preventDefault();
        let group = this.state.group && this.state.group !== "nogroup" ? this.state.group : null
        this.props.dispatch(registerGuest(this.props.match.params, this.props.history, this.state.username, this.state.password, group))
    }

    renderError(){
        if(!this.props.guestAccount.error)
            return null;
        else{
            let error;
            let inner;
            try{
                error = this.props.guestAccount.error.response.data.message;
                if(Array.isArray(error)){
                    error = error.map((e, i) => {
                        return (<Message.Item key={i}>{e.message}</Message.Item>)
                    })
                    inner = (<Message.List>{error}</Message.List>)
                }else{
                    inner = (<p>{error.message}</p>)
                }
            }catch(error){
                try{
                    error = this.props.guestAccount.error.response.statusText;
                    inner = (<p>{"Erreur: "+error}</p>)
                }catch(error){
                    inner = (<p>{"Erreur: Unknown error"}</p>)
                }
            }
            return (<Message negative style={{display: "block"}}><Message.Header>Erreur</Message.Header>{inner}</Message>)
        }

    }

    renderMessage(){
        let info;
        if(this.props.user){
            info = "Avant de rejoindre l'atelier choisis le groupe auqel tu appartiens"
        }else if(Object.values(this.props.groups.entities).length > 0){
            info = (
                <p>
                    Avant de rejoindre l'atelier choisis:
                    <List bulleted style={{textAlign:"initial"}}>
                        <List.Item>un nom dutilisateur qui permettra à ton encadrant de te reconnaître </List.Item>
                        <List.Item>le groupe auquel tu appartiens</List.Item>
                        <List.Item>un mot de passe pour pouvoir te reconnecter plus tard</List.Item>
                    </List>
                </p>
            )
        }else{
            info = (
                <p>
                    Avant de rejoindre l'atelier choisis:
                    <List bulleted style={{textAlign:"initial"}}>
                        <List.Item>un nom dutilisateur qui permettra à ton encadrant de te reconnaître </List.Item>
                        <List.Item>un mot de passe pour pouvoir te reconnecter plus tard</List.Item>
                    </List>
                </p>
            )
        }
        return (
            <Message info>
                <p>
                    {info}
                </p>
            </Message>
        )
    }

    renderGroups(){
        let structure = Object.values(this.props.structures.entities).find(s => {
            if(s.pin === this.props.match.params.pin)
                return s
        })
        let groups = Object.values(this.props.groups.entities).filter(g => {
            return g.structure === structure._id
        })
        if(groups.length == 0)
            return null
        groups = groups.map(group => {
            return {
                key: group._id,
                value: group._id,
                text: group.name
            }
        })

        groups.unshift({
            key: "nogroup",
            value: "nogroup",
            text: "Aucun groupe"
        })

        return (
            <React.Fragment>
                <Divider  hidden/>
                <Form.Field>
                    <label>Groupe</label>
                    <Form.Dropdown onChange={this.onGroupChange} defaultValue={groups[0].key} fluid selection options={groups}/>
                </Form.Field>
            </React.Fragment>
            
        )
    }
    
    redirectToLogin = () =>{
        this.props.history.push('/login')
    }

    render(){

        return (
            <div style={{height: "100vh", display:'flex', flexDirection:"column", flex: 1}}>
				<HeaderMain/>
                <Grid textAlign="center" style={{flex: "1 1 auto"}} verticalAlign="middle">
                    <Grid.Column style={{maxWidth:"450px", width:"60%"}}>
                    <Header color='orange' textAlign='center'>
                    <Image
                    style={{width: '4.5em', marginRight: "25px"}}
                    verticalAlign={'middle'}
                    circular
                    size={'large'}
                    src={doc}/> 
                    Rejoindre l'atelier </Header>
                    <Form size="large" loading={this.props.guestAccount.isFetching}>
                    <Segment>
                        {this.renderMessage()}
                        {this.renderError()}
                        {!this.props.user && (
                            <Form.Field>
                                <label>Nom d'utilisateur</label>
                                <Form.Input
                                    onChange={this.onChange}
                                    name={"username"}
                                    fluid
                                    icon='user'
                                    iconPosition='left'
                                />
                                <label>Mot de passe</label>
                                <Form.Input
                                    type="password"
                                    onChange={this.onChange}
                                    name={"password"}
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                />
                            </Form.Field>
                        )}
                        {this.renderGroups()}
                    </Segment>
                    <Divider hidden/>
                    <Button type="submit" fluid color='orange' onClick={this.onSubmit}>Rejoindre</Button>
                    </Form>
                    <Message style={{justifyContent:"center"}}>
                        <p>
                            <a style={{cursor: "pointer"}} onClick={this.redirectToLogin}>J'ai déjà un compte</a>
                        </p>
                    </Message>
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state) {
	return {
		user: getActualUser(state),
        session: state.session,
        guestAccount: state.guestAccount,
        groups: state.model.groups,
        structures: state.model.structures,
        users: state.model.users
	}
}

const connectedGuestAccess = connect(mapStateToProps)(GuestAccess)
// export { connectedGuestAccess as GuestAccess }
export const GuestAccessRouter = withRouter(connectedGuestAccess)