import React, {Component} from 'react';

import {Modal, Button, Image, Header, Transition} from 'semantic-ui-react'
import profil from '../../img/miss.svg'

import {ConnectionStatus} from '../../containers/ConnectionStatus';

class StepPopupInstruction extends Component {

	render(){
		return (
			<Transition visible={this.props.open} animation='scale' duration={200}>
				<Modal open={this.props.open}>
			    <Modal.Header>
			    
			    		{this.props.step.description}
			    	
			    	<div style={{float: "right"}}>
			    		<ConnectionStatus/>
			    	</div>
			    </Modal.Header>
			    
			    <Modal.Content image>
			      <Image wrapped size='small' src={profil} />
			      <Modal.Description>
			        <Header>{this.props.step.instruction}</Header>
			        <p>{this.props.step.context}</p>
			      </Modal.Description>
			    </Modal.Content>
					<Modal.Actions>
						<Button color='red' onClick={this.props.skipStep}>
							Passer cette étape
						</Button>
					</Modal.Actions>
			  </Modal>
		  </Transition>
		)
	}
}

export { StepPopupInstruction };