import * as api from "../../api";

import { coursesConstants } from '../../constants';
import { modelConstants } from '../../constants';

import { coursesSchema, courseSchema } from '../../schemas';

import {normalize} from 'normalizr'

export const coursesActions = {
	fetch,
	fetchAll,
	fetchLock,
	fetchUnlock,
	fetchForUser,
	fetchActivity,
	create,
	update,
	destroy,
	getShareId,
	cloneActivity,
	fetchActivityByShareId,
	fetchInStore
}

function fetch(id){
	return dispatch => {
		dispatch({type: modelConstants.FETCH_ENTITIES_STARTED, entities:"courses"});
		return api.courses.fetch(id).then(response => {
			console.log(response.data)
			console.log(normalize(response.data, coursesSchema))
			dispatch({type: modelConstants.FETCH_ENTITIES_DONE, originalEntities:"courses", entities: normalize(response.data, courseSchema)});
		}).catch(error => {
			dispatch({type: coursesConstants.FETCH_ALL_FAILED, error})
		})
	}
}

function fetchAll(){
	return dispatch => {
		dispatch({type: modelConstants.FETCH_ENTITIES_STARTED, entities:"courses"});
		return api.courses.fetchAll().then(response => {
			dispatch({type: modelConstants.FETCH_ENTITIES_DONE, originalEntities:"courses", entities: normalize(response.data, coursesSchema)});
		}).catch(error => {
			dispatch({type: coursesConstants.FETCH_ALL_FAILED, error})
		})
	}
}

function fetchForUser(){
	return dispatch => {
		dispatch({type: modelConstants.FETCH_ENTITIES_STARTED, entities:"courses"});
		return api.courses.fetchForUser().then(response => {
			let entities = normalize(response.data, coursesSchema)
		
			dispatch({type: modelConstants.FETCH_ENTITIES_DONE, originalEntities:"courses", entities:  entities});
		}).catch(error => {
			console.log(error)
			dispatch({type: coursesConstants.FETCH_ALL_FAILED, error})
		})
	}
}

function fetchLock(){
	return (dispatch, getState) => {
		dispatch({type: modelConstants.FETCH_ENTITIES_STARTED, entities:"courses"});
		return api.courses.fetchLock().then(response => {
			let entities=normalize(response.data, coursesSchema)
			entities.entities.courses = mergeCourses(getState().model.courses.entities, entities.entities.courses);
			dispatch({type: modelConstants.FETCH_ENTITIES_DONE, originalEntities:"courses", entities: entities});
		}).catch(error => {
			dispatch({type: coursesConstants.FETCH_UNLOCK_FAILED, error})
		})
	}
}

function fetchUnlock(){
	return (dispatch, getState) => {
		dispatch({type: modelConstants.FETCH_ENTITIES_STARTED, entities:"courses"});
		return api.courses.fetchUnlock().then(response => {
			console.log(response.data.step)
			let entities=normalize(response.data, coursesSchema)
			entities.entities.courses = mergeCourses(getState().model.courses.entities, entities.entities.courses);
			dispatch({type: modelConstants.FETCH_ENTITIES_DONE, originalEntities:"courses", entities: entities});
		}).catch(error => {
			console.log(error)
			dispatch({type: coursesConstants.FETCH_UNLOCK_FAILED, error})
		})
	}
}

function fetchActivity(id){
	return dispatch => {
		dispatch({type: modelConstants.FETCH_ENTITIES_STARTED, entities:"courses"});
		return api.courses.fetchActivity(id).then(response => {
			dispatch({type: modelConstants.FETCH_ENTITIES_DONE, originalEntities:"courses", entities: normalize(response.data, courseSchema)});
		}).catch(error => {
			dispatch({type: coursesConstants.FETCH_ACTIVITY_FAILED, error})
		})
	}
}

function create(course){
	return (dispatch) => {
		dispatch({type: modelConstants.CREATE_ENTITY_STARTED, entities:"courses"});
		return api.courses.create(course)
		.then(response => {
			let entities = normalize(response.data, courseSchema)
			dispatch({type: modelConstants.CREATE_ENTITY_DONE, originalEntities:"courses", entities: entities});
		}).catch(error => {
			console.log(error)
			dispatch({type: coursesConstants.CREATE_FAILED, error})
		})
	}
}

function update(id, course){
	return (dispatch, getState) => {
		dispatch({type: modelConstants.UPDATE_ENTITY_STARTED, entities:"courses"});
		return api.courses.update(id, course).then(response => {
			let entities=normalize(response.data, courseSchema)
			entities.entities.courses = mergeCourses(getState().model.courses.entities, entities.entities.courses);
			dispatch({type: modelConstants.UPDATE_ENTITY_DONE, originalEntities:"courses", entities: entities});
		}).catch(error => {
			console.log(error)
			dispatch({type: coursesConstants.UPDATE_FAILED, error})
		})
	}
}

function destroy(id){
	return (dispatch, getState) => {
		dispatch({type: modelConstants.DELETE_ENTITY_STARTED, entities:"courses"});
		return api.courses.destroy(id).then(response => {
			let entities = getState().model.courses.entities;
			let newEntities = {...entities}
			delete newEntities[response.data._id];
			dispatch({type: modelConstants.DELETE_ENTITY_DONE, originalEntities:"courses", entities: {entities: {courses: newEntities}}})

			// let entities=normalize(response.data, courseSchema)
			// dispatch({type: modelConstants.DELETE_ENTITY_DONE, originalEntities:"courses", entities: entities});
		}).catch(error => {
			console.log(error)
			dispatch({type: coursesConstants.DELETE_FAILED, error})
		})
	}
}

function getShareId(id){
	return (dispatch, getState) => {
		dispatch({type: modelConstants.FETCH_ENTITIES_STARTED, entities:"courses"});
		return api.courses.getShareId(id).then(response => {
			let entities=normalize(response.data, courseSchema)
			dispatch({type: modelConstants.FETCH_ENTITIES_DONE, originalEntities:"courses", entities: entities});
		}).catch(err => {
			if(err.response && err.response.data && err.response.data.message)
				dispatch({type: coursesConstants.FETCH_ACTIVITY_FAILED, error: err.response.data.message})
			else
            	dispatch({type: coursesConstants.FETCH_ACTIVITY_FAILED, error: err})
		})
	}
}

function cloneActivity(shareId){
	return (dispatch) => {
		dispatch({type: modelConstants.CREATE_ENTITY_STARTED, entities:"courses"});
		return api.courses.clone(shareId)
		.then(response => {
			let entities = normalize(response.data, courseSchema)
			dispatch({type: modelConstants.CREATE_ENTITY_DONE, originalEntities:"courses", entities: entities});
		}).catch(err => {
			console.log(err)
			if(err.response && err.response.data && err.response.data.message)
				dispatch({type: coursesConstants.CREATE_FAILED, error: err.response.data.message})
			else
            	dispatch({type: coursesConstants.CREATE_FAILED, error: err})
		})
	}
}

function fetchActivityByShareId(shareId){
	return dispatch => {
		dispatch({type: modelConstants.FETCH_ENTITIES_STARTED, entities:"courses"});
		return api.courses.fetchByShareId(shareId).then(response => {
			dispatch({type: modelConstants.FETCH_ENTITIES_DONE, originalEntities:"courses", entities: normalize(response.data, courseSchema)});
		})
		.catch(err => {
			console.log(err)
			if(err.response && err.response.data && err.response.data.message)
				dispatch({type: coursesConstants.FETCH_ALL_FAILED, error: err.response.data.message})
			else
            	dispatch({type: coursesConstants.FETCH_ALL_FAILED, error: err})
        })
	}
}

function fetchInStore(from, limit){
	return dispatch => {
		dispatch({type: modelConstants.FETCH_ENTITIES_STARTED, entities:"courses"});
		return api.courses.fetchInStore(from, limit).then(response => {
			dispatch({type: modelConstants.FETCH_ENTITIES_DONE, originalEntities:"courses", entities: normalize(response.data, coursesSchema)});
		})
		.catch(err => {
			console.log(err)
			if(err.response && err.response.data && err.response.data.message)
				dispatch({type: coursesConstants.FETCH_ALL_FAILED, error: err.response.data.message})
			else
            	dispatch({type: coursesConstants.FETCH_ALL_FAILED, error: err})
        })
	}
}

function mergeCourses(currentCourses, newCourses){
	if(!newCourses)
		newCourses = {};
	for(let c in currentCourses){
		if(newCourses[c])
			continue;
		newCourses[c] = {};
		newCourses[c] = Object.assign({}, currentCourses[c]);
	}
	return newCourses;
}