import {coursePageConstants} from "../constants";

const initialCoursePageState = {
    inAnimation: false,
    lastTestedCode: null,
    resetToPreviousXml: false,
};


export const coursePage = (state = initialCoursePageState, action) => {
    switch (action.type) {

        case coursePageConstants.ANIMATION_STARTED :  {
            return {
                ...state,
                inAnimation: true
            }
        }

        case coursePageConstants.ANIMATION_DONE : {
            return {
                ...state,
                inAnimation: false
            }
        }

        case coursePageConstants.UPDATE_LAST_TESTED_CODE:
            return {
                ...state,
                lastTestedCode: action.code
            }

        case coursePageConstants.RESET_TO_PREVIOUS_XML:
            return {
                ...state,
                resetToPreviousXml: true
            }

        case coursePageConstants.RESET_TO_PREVIOUS_XML_DONE:
            return {
                ...state,
                resetToPreviousXml: false
            }
          

        default: {
            return state
        }
    }
}