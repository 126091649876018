import React, { Component } from "react";
import { connect } from "react-redux";
import { StepCountComponent } from "../../components/StepsCountComponent/StepCountComponent";
import { stepsActions } from "../../actions/model";
import { editorActions } from "../../actions/editor_actions";
import { cluesActions } from "../../actions/model/clues_actions";
import { modelActions } from "../../actions/model";
import { EditorTextComponent } from "../../components/EditorTextsComponent/EditorTextComponent";
import HeaderMain from "../../components/HeaderMain/HeaderMain";
import { Blockly } from "../../containers/Blockly";
import { ToolboxEditorComponent } from "../../components/ToolboxEditorComponent/ToolboxEditorComponent";
import { CluesEditorComponent } from "../../components/CluesEditorComponent/CluesEditorComponent";
import { Ace } from "../../containers/Ace/";
import "./Editor.css";
import { Container, Grid, Button, TextArea, Icon, Tab, Dimmer, Loader } from "semantic-ui-react";
import { blocklyActions } from "../../actions";

class Editor extends Component {
    constructor(props) {
        super(props);
        // this.state = { currentStep: null }
        this.goToNextStep = this.goToNextStep.bind(this);
        this.goToPreviousStep = this.goToPreviousStep.bind(this);
        this.createNextStep = this.createNextStep.bind(this);
        this.createPreviousStep = this.createPreviousStep.bind(this);
        this.saveStepChanges = this.saveStepChanges.bind(this);
        // this.renderBlockly=this.renderBlockly.bind(this)
        this.saveChanges = this.saveChanges.bind(this);
        this.saveCurrentStep = this.saveCurrentStep.bind(this);
        this.switchXmlSolution = this.switchXmlSolution.bind(this);
        this.switchXmlStart = this.switchXmlStart.bind(this);
        this.xmlLoaded = this.xmlLoaded.bind(this);
        this.onMainWorkspaceStatusChanged = this.onMainWorkspaceStatusChanged.bind(this);
        this.autoSave = this.autoSave.bind(this);
        this.xmlToDom = this.xmlToDom.bind(this);
        this.domToXml = this.domToXml.bind(this);
        this.saveToolbox = this.saveToolbox.bind(this);
        this.deleteCurrentStep = this.deleteCurrentStep.bind(this);
        this.tabBlockly = this.tabBlockly.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.fetchClues = this.fetchClues.bind(this);
        this.saveCluesChanges = this.saveCluesChanges.bind(this);
        this.saveClues = this.saveClues.bind(this);
        this.createClue = this.createClue.bind(this);
        this.deleteClue = this.deleteClue.bind(this);
    }

    componentWillMount() {
        this.props.dispatch(stepsActions.fetchFirstByCourse(this.props.match.match.params.id));
    }
    componentDidMount() {}
    componentWillUpdate(prevProps, prevState) {
        //Si un step est en cours de chargement et que l'état du chargement a changé depuis l'état précédent
        /*if (prevProps.steps.isLoading > 0 && prevProps.steps.isLoading !== this.props.steps.isLoading) {
            let s = Object.keys(this.props.steps.entities).find(id => {
                
                let step = this.props.steps.entities[id]
            
                return step
            })
            //this.setState({ currentStep: s })

         
           //this.props.editor.id=s

        }*/
    }
    componentDidUpdate(prevProps) {
        if (prevProps.editor.xmlReadyToLoad == false && this.props.editor.xmlReadyToLoad == true && this.props.editor.xmlMode != "domToXml") {
            //let text = document.getElementById('XML')
            //text.value =null
            this.setXml();
        }
        console.log("WILL this.props", this.props.editor.id);
        console.log("WILL prevProps", prevProps.editor.id);

        if (prevProps.editor.id !== this.props.editor.id && this.props.editor.id) {
            this.fetchClues();
        }
    }

    componentWillUnmount() {
        //this.props.dispatch(modelActions.invalidate());
    }

    goToNextStep() {
        this.props.dispatch(stepsActions.fetchNextStep(this.props.steps.entities[this.props.editor.id]));
    }

    goToPreviousStep() {
        this.props.dispatch(stepsActions.fetchPreviousStep(this.props.steps.entities[this.props.editor.id]));
    }

    createNextStep() {
        this.props.dispatch(stepsActions.createNextStep(this.props.steps.entities[this.props.editor.id]));
    }

    createPreviousStep() {
        this.props.dispatch(stepsActions.createPreviousStep(this.props.steps.entities[this.props.editor.id]));
    }

    saveChanges(e, event) {
        this.saveStepChanges(event);
    }
    saveCluesChanges(e, changeEvent) {
        console.log("changeEvent", changeEvent);
        this.props.dispatch(editorActions.saveCluesChanges(changeEvent));
    }

    saveStepChanges(changeEvent) {
        this.props.dispatch(editorActions.saveCurrentStepChanges(this.props.steps.entities[this.props.editor.id]._id, changeEvent));
    }

    saveCurrentStep() {
        this.props.dispatch(editorActions.saveCurrentStep(this.props.steps.entities[this.props.editor.id]));
    }

    handleChange(e, data) {
        if (data) {
            if (data.activeIndex == 1) {
                this.switchXmlStart();
            }
            if (data.activeIndex == 0) {
                this.switchXmlSolution();
            }
        }
    }

    switchXmlSolution() {
        this.props.dispatch(editorActions.switchXmlMode("solution"));
    }
    switchXmlStart() {
        this.props.dispatch(editorActions.switchXmlMode("start"));
    }
    domToXml() {
        this.props.dispatch(editorActions.switchXmlMode("domToXml"));
    }
    setXml() {
        let xml = null;

        if (this.props.editor.xmlMode === "solution") xml = this.props.steps.entities[this.props.editor.id].xmlSolution;
        if (this.props.editor.xmlMode === "start") xml = this.props.steps.entities[this.props.editor.id].xmlStart;
        return xml;
    }
    onMainWorkspaceStatusChanged(workspace) {
        if (workspace !== null) {
            this.props.dispatch(blocklyActions.mainWorkspaceInitiated(workspace));
        } else {
            this.props.dispatch(blocklyActions.mainWorkspaceInvalid());
        }
    }

    xmlLoaded() {
        this.props.dispatch(editorActions.loadBlocklyXmlDone());
    }

    autoSave(xml, code) {
        if (this.props.steps.entities[this.props.editor.id]) this.props.dispatch(editorActions.saveChangeXml(this.props.steps.entities[this.props.editor.id]._id, xml));
    }

    xmlToDom() {
        let text = document.getElementById("XML");
        text.value = this.props.editor.xmlTemp;
    }

    saveClues() {
        this.props.dispatch(cluesActions.saveClues());
        console.log("cick");
    }
    saveToolbox(xml) {
        this.props.dispatch(editorActions.saveToolbox(this.props.steps.entities[this.props.editor.id]._id, xml));
    }

    deleteCurrentStep() {
        this.props.dispatch(editorActions.deleteCurrentStep(this.props.steps.entities[this.props.editor.id]._id));
    }

    fetchClues() {
        this.props.dispatch(cluesActions.fetchAllByStep(this.props.steps.entities[this.props.editor.id]._id));
    }

    createClue() {
        this.props.dispatch(cluesActions.createClue(this.props.steps.entities[this.props.editor.id]._id, this.props.editor.currentClueId[this.props.editor.currentClueId.length - 1]));
        /*else {
        this.props.dispatch(cluesActions.createFirstClue(this.props.steps.entities[this.props.editor.id]._id))
    }*/
    }
    deleteClue(clueId) {
        this.props.dispatch(cluesActions.deleteClue(this.props.steps.entities[this.props.editor.id]._id, clueId));
    }

    tabBlockly() {
        const panes = [
            {
                menuItem: "Solution",
                render: () => (
                    <Tab.Pane onClick={this.switchXmlSolution} id="blockly" className="solution" style={{ height: "100%", width: "100%" }} onClick={this.handleChange}>
                        <Blockly
                            style={{ height: "100%", width: "100%" }}
                            lang={window.LANG}
                            xml={this.props.steps.entities[this.props.editor.id].xmlSolution}
                            xmlReadyToLoad={this.props.editor.xmlReadyToLoad}
                            xmlLoaded={this.xmlLoaded}
                            onMainWorkspaceStatusChanged={this.onMainWorkspaceStatusChanged}
                            autoSave={this.autoSave}
                            step={this.props.steps.entities[this.props.editor.id]}
                            mode="solution"
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: "Start",
                render: () => (
                    <Tab.Pane onClick={this.switchXmlStart} style={{ height: "100%", width: "100%" }} id="blockly" className="start">
                        <Blockly
                            style={{ height: "100%", width: "100%" }}
                            lang={window.LANG}
                            xml={this.props.steps.entities[this.props.editor.id].xmlStart}
                            xmlReadyToLoad={this.props.editor.xmlReadyToLoad}
                            xmlLoaded={this.xmlLoaded}
                            onMainWorkspaceStatusChanged={this.onMainWorkspaceStatusChanged}
                            autoSave={this.autoSave}
                            step={this.props.steps.entities[this.props.editor.id]}
                            mode="start"
                        />
                    </Tab.Pane>
                ),
            },
        ];

        return <Tab menu={{ color: "blue", inverted: true }} style={{ height: "100%", width: "100%" }} panes={panes} onTabChange={this.handleChange} />;
    }

    render() {
        console.log(this.props.clues.entities);
        if (this.props.editor.id && this.props.steps.entities[this.props.editor.id] && this.props.clues.entities) {
            return (
                <div style={{ height: "100%" }}>
                    <Dimmer active={this.props.steps.isLoading === 1 ? true : false}>
                        <Loader size="massive">Chargement...</Loader>
                    </Dimmer>
                    <HeaderMain />

                    <StepCountComponent
                        course={this.props.courses.entities[this.props.match.match.params.id]}
                        step={this.props.steps.entities[this.props.editor.id]}
                        goToNextStep={this.goToNextStep}
                        goToPreviousStep={this.goToPreviousStep}
                        createNextStep={this.createNextStep}
                        createPreviousStep={this.createPreviousStep}
                        saveCurrentStep={this.saveCurrentStep}
                        dataToSave={this.props.editor.dataToSave}
                        deleteCurrentStep={this.deleteCurrentStep}
                    />

                    <EditorTextComponent step={this.props.steps.entities[this.props.editor.id]} saveStepChanges={this.saveStepChanges} />
                    <ToolboxEditorComponent saveToolbox={this.saveToolbox} step={this.props.steps.entities[this.props.editor.id]} />

                    <div className="flex" style={{ height: "100%", width: "100%" }}>
                        <div style={{ height: "80%", width: "50%" }}>{this.tabBlockly()}</div>

                        <div style={{ height: "80%", width: "50%" }}>
                            <Ace value={this.setXml()} readOnly={false} autoSaveAce={this.autoSave} mode="xml" />
                        </div>
                    </div>

                    {/* <CluesEditorComponent
                        fetchClues={this.fetchClues}
                        clues={this.props.clues.entities}
                        cluesId={this.props.editor.currentClueId}
                        autoSaveClue={this.saveCluesChanges}
                        createClue={this.createClue}
                        deleteClue={this.deleteClue}
                       
                        />
                   */}
                </div>
            );
        } else if (this.props.editor.entities === {}) {
            return (
                <div>
                    <p>Erreur, pas d'application </p>
                </div>
            );
        } else {
            return (
                <div>
                    <p>Chargement... </p>
                </div>
            );
        }
    }
}

Editor.propTypes = {};

function mapStateToProps(state) {
    return {
        steps: state.model.steps,
        courses: state.model.courses,
        clues: state.model.clues,
        editor: state.editor,
    };
}
const connectedEditor = connect(mapStateToProps)(Editor);
export { connectedEditor as Editor };
