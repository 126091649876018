import React from 'react';
import { Link } from "react-router-dom";
import {Message} from 'semantic-ui-react'


export const ErrorComponent = props => {

		function getError(){
			let error = {};
			error.header = props.header || "Erreur";
			error.type = props.type || "negative";

			 try{
			 			if(props.error.response.status === 403){
			 				error.header = (props.forbidden && props.forbidden.header) || "Compte non autorisé"
			 				error.details = (props.forbidden && props.forbidden.details) || "Si tu n'as pas confirmé ton email, ouvre l'email d'activation que nous t'avons envoyé"
			 				error.type = "warning"
			 				return error;
			 			}
            if(typeof props.error.response.data.message === 'string')
							error.details = (<p>{this.props.users.error.response.data.message}</p>)
            else
							error.details = (<p>{"Une erreur est survenue: "+props.error.response.statusText}</p>)
						return error;
        }catch(e){
            try{
                error.details = (<p>{"Une erreur est survenue: "+props.error.response.statusText}</p>)
                return error;
            }catch(e){
                error.details = (<p>{"Une erreur inconnue est survenue"}</p>)
                return error;
            }
        }
		}
		const error = getError();
		if(!props.error)
			return null;
    return (
      <Message negative={error.type === "negative"} warning={error.type === "warning"} info={error.type === "info"} style={{display: "block"}}>
		    <Message.Header>{error.header}</Message.Header>
		    <Message.Content>
		    	{error.details}
		    	{props.link && 
		    		<Link to={props.link.url}>{props.link.text}</Link>
		    	}
		    </Message.Content>
		  </Message>
    );
};

