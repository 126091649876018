export const galaxiaSpaceConstants = {
    INVALIDATE_GALAXIA_SPACE_STATE: "GALAXIA_SPACE_INVALIDATE_GALAXIA_SPACE_STATE",
    SELECT_APP_STARTED: "GALAXIA_SPACE_SELECT_APP_STARTED",
    SELECT_APP_SUCCEEDED: "GALAXIA_SPACE_SELECT_APP_SUCCEEDED",
    SELECT_APP_FAILED: "GALAXIA_SPACE_SELECT_APP_FAILED",
    OPEN_MODAL: "GALAXIA_SPACE_OPEN_MODAL",
    CLOSE_MODAL: "GALAXIA_SPACE_CLOSE_MODAL",

    OPEN_SYNC_MODAL: "GALAXIA_SPACE_OPEN_SYNC_MODAL",
    CLOSE_SYNC_MODAL: "GALAXIA_SPACE_CLOSE_SYNC_MODAL",

    SAVE_XML: "GALAXIA_SPACE_SAVE_XML",
    LOAD_XML: "GALAXIA_SPACE_LOAD_XML",
    SAVE_XML_FAILED: "GALAXIA_SPACE_SAVE_XML_FAILED",
    LOAD_XML_FAILED: "GALAXIA_SPACE_LOAD_XML_FAILED",
    LOAD_XML_DONE: "GALAXIA_SPACE_LOAD_XML_DONE",
    SAVE_CODE_FAILED: "GALAXIA_SPACE_SAVE_CODE_FAILED",
    LOAD_CODE: "GALAXIA_SPACE_LOAD_CODE",
    LOAD_CODE_FAILED: "GALAXIA_SPACE_LOAD_CODE_FAILED",
    EDITOR_STATE: "GALAXIA_SPACE_EDITOR_STATE",
    // GET_CPP:'GALAXIA_SPACE_GET_CPP',
    SYNCHRO_CODE_ACE: "GALAXIA_SPACE_SYNCHRO_CODE_ACE",
    SAVE_CURRENT_APP_STARTED: "GALAXIA_SPACE_SAVE_CURRENT_APP_STARTED",
    SAVE_CURRENT_APP_SUCCEEDED: "GALAXIA_SPACE_SAVE_CURRENT_APP_SUCCEEDED",
    SAVE_CURRENT_APP_FAILED: "GALAXIA_SPACE_SAVE_CURRENT_APP_FAILED",
    SAVE_NEW_APP_STARTED: "GALAXIA_SPACE_SAVE_NEW_APP_STARTED",
    SAVE_NEW_APP_SUCCEEDED: "GALAXIA_SPACE_SAVE_NEW_APP_SUCCEEDED",
    SAVE_NEW_APP_FAILED: "GALAXIA_SPACE_SAVE_NEW_APP_FAILED",

    FIRST_LOAD: "GALAXIA_SPACE_FIRST_LOAD",
    CHANGE_STATUS: "GALAXIA_SPACE_CHANGE_STATUS",
    TITLE_CHANGE: "GALAXIA_SPACE_TITLE_CHANGE",
    SAVE_CODE_ACE: "GALAXIA_SPACE_SAVE_CODE_ACE",
    SAVE_CODE_ACE_FAILED: "GALAXIA_SPACE_SAVE_CODE_ACE_FAILED",
    LOAD_CODE: "GALAXIA_SPACE_LOAD_CODE",
    LOAD_CODE_PYTHON: "GALAXIA_SPACE_LOAD_CODE_PYTHON",
    LOAD_CODE_FAILED: "GALAXIA_SPACE_LOAD_CODE",
};
