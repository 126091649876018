import React, {Component} from 'react';
import { Link } from 'react-router-dom'
import {Modal, Header, Input, Dimmer, Segment, Loader} from 'semantic-ui-react'

 class ActivityShareModal extends Component{

    constructor(props){
        super(props)

        this.state = {}
    }

    clearSelection(){
        if(window.getSelection){
            window.getSelection().removeAllRanges()
        }else if(document.selection){
            document.selection.empty()
        }
    }

    handleCopy = () => {
        /* Get the text field */
        let copyText = document.getElementById("myShareId"+this.shareId)
        /* Select the text field */
        copyText.select()
        /* Copy the text inside the text field */
        document.execCommand("copy")
        /* Deselect the input value */
        this.clearSelection()
        /* Alert the copied text */

        this.setState({link_is_copied: true})
    }

    closeModal = () => {
        this.setState({link_is_copied: false})
        this.props.onClose()
    }

    shareUrl = () => {
        return `${window.location.origin.toString()}/course/read/${this.props.shareId}`
    }

    render(){
        if(this.props.shareId === undefined){
            return (
            <Modal onClose={this.closeModal} open={this.props.open} dimmer="blurring" size='small' closeIcon>
                <Header icon='trash' content="Partage" />
                <Modal.Content>
                    <Dimmer active inverted>
                        <Loader />
                    </Dimmer>
                </Modal.Content>
            </Modal>
            )
        }else if(!this.props.shareId){
            return (
            <Modal onClose={this.closeModal} open={this.props.open} dimmer="blurring" size='small' closeIcon>
                <Header icon='trash' content="Partage" />
                <Modal.Content>

                </Modal.Content>
            </Modal>
            )
        }
        return (
            <Modal onClose={this.closeModal} open={this.props.open} dimmer="blurring" size='small' closeIcon>
                <Header icon='share' content="Partage" />
                <Modal.Content>
                    <p>
                        Utilisez le lien suivant pour permettre à d'autres enseignants de copier votre activité
                    </p>
                    <div style={{margin:'10px'}}>
                    {this.state.link_is_copied ? (
                        <Input action={{ color: "teal", content: "Copier", icon: "check", onClick: () => this.handleCopy() }} fluid type="text" value={this.shareUrl()} id={"myShareId"+this.shareId}/>
                    ) : (
                        <Input action={{ color: "blue", content: "Copier", icon: "copy", onClick: () => this.handleCopy() }} fluid type="text" value={this.shareUrl()} id={"myShareId"+this.shareId}/>
                    )}
                    </div>
                </Modal.Content>
            </Modal>
        );
    }
};

export default ActivityShareModal;
