import axios from 'axios';
import {sessionService} from 'redux-react-session'
import runtimeEnv from '@mars/heroku-js-runtime-env'
import history from '../history'

const API_BASE_URL = runtimeEnv().REACT_APP_API_BASE_URL;

const client = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: ''
    }
});

client.interceptors.request.use(async function (config) {
    try{
    const jwt = await sessionService.loadUser();
    if(jwt)
        config.headers.Authorization = 'Bearer ' + jwt.jwt;
  }catch(e){
      return config;
  }
return config
}, function (error) {
    return Promise.reject(error)
});

client.interceptors.response.use(null, function(error){
    if(error.response.data.statusCode === 403 && error.response.data.message && error.response.data.message.logout)
        history.push("/logout");
    else
        return Promise.reject(error);
})

export const steps = {
    fetchFirstByCourse,
    fetchLastByCourse,
    fetchNextStep,
    fetchPreviousStep,
    createForCourse,
    createNextStep,
    createPreviousStep,
    saveCurrentStep,
    deleteCurrentStep,
    destroy
    
}
function fetchFirstByCourse(course){
    return client.get('/steps/firstByCourse/'+course)
}

function fetchLastByCourse(course){
    return client.get('/steps/lastByCourse/'+course)
}

function fetchNextStep(nextStep){
    return client.get('/steps/'+nextStep)
}

function fetchPreviousStep(step){
return client.get('/steps/fetchPreviousStep/'+step)}

function createForCourse(course){
    // return client.post('/steps/byCourse',{"course":course})
    return client.post('/steps/byCourse', course)
}

function createNextStep(currentStep){
    return client.post('/steps/createNextStep',currentStep)
}

function createPreviousStep(currentStep){
    return client.post('/steps/createPreviousStep',{currentStep})
}

function saveCurrentStep(currentStep){
    return client.put(`/steps/saveCurrentStep/${currentStep.id}`,currentStep)
}

function deleteCurrentStep(currentStep){
    return client.delete('/steps/deleteCurrentStep/'+currentStep)
}

function destroy(step) {
    return client.delete(`/steps/${step}`);
}

