import React, {Component} from 'react';
import {connect} from 'react-redux'
import {getActualUser} from "../reducers/user_reducer";
import {logout} from "../actions/session_actions";
import {leaveStructure} from "../actions/session_actions"
import UserHeaderComponent from '../components/UserHeaderComponent/UserHeaderComponent'

import {structuresActions} from '../actions/model'
import {usersActions} from '../actions/model'



class UserStatus extends Component {

    constructor(props){
        super(props)
        this.leaveStructure = this.leaveStructure.bind(this)
        this.logout = this.logout.bind(this)
    }

    componentDidMount(){
        this.props.dispatch(usersActions.me());
        if(this.props.user && !this.props.dontFetch){
            this.fetch();
        }
    }

    componentDidUpdate(prevProps){
        if(!prevProps.user && prevProps.user && !this.props.dontFetch){
            this.fetch();
        }
    }

    fetch(){
        this.props.dispatch(structuresActions.fetchStructures());
    }

    leaveStructure(){
        this.props.dispatch(leaveStructure())
    }

    deleteAccount = () => {
        this.props.dispatch(usersActions.deleteUser(this.props.user._id))
    }

    logout(history){
        this.props.dispatch(logout(history))
    }

    render() {
        let structure;
        let user = this.props.user ? this.props.users.entities[this.props.user._id] : null;
        if(user && user.group && this.props.groups.entities[user.group] && this.props.groups.entities[user.group].structure && this.props.structures.entities[this.props.groups.entities[user.group].structure]){
            structure = this.props.structures.entities[this.props.groups.entities[user.group].structure];
        }else if(user && user.structure && this.props.structures.entities[user.structure]){
            structure = this.props.structures.entities[user.structure]
        }
        return (
            <UserHeaderComponent hideIfGuest={false} deleteAccount={false} leaveStructure={this.leaveStructure} logout={this.logout} user={this.props.user} structure={structure}/>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: getActualUser(state),
        structures: state.model.structures,
        groups: state.model.groups,
        users: state.model.users
    }
}

UserStatus.propTypes = {};

export default connect(mapStateToProps)(UserStatus)
