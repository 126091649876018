import * as api from "../../api";

import { spreadsheetsConstants, extensionConstants } from '../../constants';
import { modelConstants } from '../../constants';
import { extensionActions } from '../extension_actions'
import { spreadsheetsSchema, spreadsheetSchema } from '../../schemas';

import {normalize} from 'normalizr';

export const spreadsheetsActions = {
    generateToken
}

function generateToken() {
    return dispatch => {
        dispatch({type: modelConstants.FETCH_ENTITIES_STARTED, entities:"spreadsheets"});
        return api.spreadsheets.generateToken().then(response => {
            const token = response.data.token;
            // dispatch({type: extensionConstants.UPDATE_TOKEN, token});
            dispatch(extensionActions.updateToken(token));


        }).catch(error => {
            dispatch({type: spreadsheetsConstants.FETCH_TOKEN_FAILED, error});
        })
    }
}