import React, { Component } from 'react';
import { Button, Form, Message, Popup, Segment, Dropdown, Icon, List } from 'semantic-ui-react';

export default class FormActivityCreatorAvailabilitySettings extends Component {

    constructor(props){
        super(props)
        this.state={
            selectedAccessibility:"private",
            selectedGroups:[]
        }
    }

    componentDidMount(){
        if(this.props.selectedCourses){
            this.initAccessibility();
        }
    }

    componentDidUpdate(prevProps){
        if((!prevProps.selectedCourses && this.props.selectedCourses) 
        || (prevProps.selectedCourses && this.props.selectedCourses && prevProps.selectedCourses.length !== this.props.selectedCourses.length)
        || (!prevProps.groups && this.props.groups)
        || (prevProps.groups && this.props.groups && prevProps.groups.length !== this.props.groups.length)){
            this.initAccessibility();
        }
    }

    initAccessibility(){
        let groups = []
        let status = "private"
        for(let select of this.props.selectedCourses){
            if(select.group){
                status = "restraint"
                groups.push(select.group)
            }
            if(select.structure){
                status = "public"
                break;
            }
        }
        this.setState({selectedAccessibility: status, selectedGroups: groups})
    }


    changeKey = (e, data) => {
        this.setState({selectedAccessibility: data.value}, ()=>{
            this.props.reportChanges({...this.state})
        })
    }

    changeGroups = (e, data) => {
        this.setState({selectedGroups:data.value}, ()=>{
            this.props.reportChanges({...this.state})
        })
    }

    renderGroups(){
        const renderLabel = (label) => ({
            color: 'blue',
            content: label.text
        })

        const options = this.props.groups.map(group => {
            return {
                key: group._id,
                text: group.name,
                value: group._id
            }
        })
        let placeholder, disabled
        if(options.length){
            placeholder="Choisir les groupes"
            disabled = false
        }else{
            placeholder="Vous n'avez pas de groupes"
            disabled = true
        }
        return (
            <Dropdown fluid multiple selection placeholder={placeholder} renderLabel={renderLabel} options={options} disabled={disabled} value={this.state.selectedGroups} onChange={this.changeGroups}/>
        )
    }

    render(){
        return (
            <React.Fragment>
                <Form.Field>
                    <label>Accessibilité <Popup position="top center" trigger={<Icon name='question circle outline' />}>
                        <Popup.Header>Accessibilité</Popup.Header>
                        <Popup.Content>
                            Configurez qui aura accès à cette activité
                            <List bulleted>
                                <List.Item>Privée: Seulement vous</List.Item>
                                <List.Item>Tous les élèves: Tous les élèves de votre structure</List.Item>
                                <List.Item>Par groupe: Les élèves appartenants aux groupes sélectionnés dans le champs 'Groupes' </List.Item>
                            </List>
                        </Popup.Content>
                    </Popup>
                    </label>
                    <Dropdown value={this.state.selectedAccessibility} onChange={this.changeKey} fluid selection options={[{
                        key: 'private',
                        text: 'Privée',
                        value: 'private',
                    },
                    {
                        key: 'public',
                        text: 'Tous les élèves',
                        value: 'public',
                    },
                    {
                        key: 'restraint',
                        text: 'Par groupe',
                        value: 'restraint',
                    },]}/>
                </Form.Field>
                {this.state.selectedAccessibility === "restraint" && 
                <Form.Field>
                    <label>Groupes</label>
                    {this.renderGroups()}
                </Form.Field>
                }
            </React.Fragment>
        )
    }
}