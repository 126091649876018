import ReactDOM from 'react-dom'
import React from 'react'

import AnimationComponent from "../components/AnimationComponent/AnimationComponent";

import {progressionsActions} from "./model";
import {modalActions} from ".";
import {extensionActions} from ".";

import {coursePageConstants} from "../constants";
import {guidedTourActions} from "../actions";
import {modalConstants} from "../constants";



export const coursePageActions = {
    animationStarted,
    animationDone,
    createAnimation,
    animationTransition,
    saveProgressionXml,
    saveActivityXml,
    checkBlockly,
    checkBlocklyGuidedTour,
    resetToPreviousXml,
    resetToPreviousXmlDone,
    offlineError,
    offlineRecovery,
    checkXmlChangedSignificantly,
};

function checkXmlChangedSignificantly(progression, xml){
    return (dispatch, getState) => {
        try{
            let progressions = getState().model.progressions.entities;
            let mainWorkspace = getState().blockly.mainWorkspace;
            let oldXml = progressions && progressions[progression] ? String(progressions[progression].xml) : null;
            let newSetup = mainWorkspace.getAllBlocks().find(block => block.type === "setup");

            oldXml = oldXml.replace(/(x="-*[0-9]*")|(y="-*[0-9]*")|( )/g,'')
            newSetup = window.Blockly.Xml.domToPrettyText(window.Blockly.Xml.blockToDom(newSetup))
            newSetup = newSetup.replace(/(x="-*[0-9]*")|(y="-*[0-9]*")|(xmlns=".*?")|( )/g,'')
            if(!oldXml.includes(newSetup))
                dispatch(extensionActions.resetCompilationCode())
        }catch(error){
            console.log("checkXmlChangedSignificantly error:")
            console.log(error)
        }
    }
}

function saveProgressionXml(progression, xml){
    return dispatch => {
        dispatch({type: coursePageConstants.SAVE_PROGRESSION_XML, progression, xml})
        return dispatch(progressionsActions.update(progression))
    }
}

function saveActivityXml(step, xml) {
    return dispatch => {
        dispatch({type: coursePageConstants.SAVE_STEP_XML, step, xml});
    }
}

function animationStarted() {
    return {
        type: coursePageConstants.ANIMATION_STARTED,
        payload: null
    }
}

function animationDone() {
    return {
        type: coursePageConstants.ANIMATION_DONE,
        payload: null
    }
}

// function upZindex() {
//     if (document.getElementById('instructionHighLight')) {
//         document.getElementById('instructionHighLight').style.zIndex = "2000";
//     }
// }

function downZindex() {
    document.getElementById('instructionHighLight').style.zIndex = "1000";
}

function resetToPreviousXml(progressionId){
    return {type: coursePageConstants.RESET_TO_PREVIOUS_XML, progression: progressionId}
}

function resetToPreviousXmlDone(){
    return {type: coursePageConstants.RESET_TO_PREVIOUS_XML_DONE}
}

//ANIMATION DE FELICITATION
function createAnimation() {
    console.log('Start animation')
    downZindex();
    const message = document.createElement('div');
    message.className = 'message-transition-container';

    const text = document.createElement('div');
    text.className = 'message-transition-congrats';

    const avatar = document.createElement('div');
    avatar.className = 'message-transition-avatar';

    const messageAuthor = document.createElement('div');
    messageAuthor.className = 'message-transition-congrats-author';
    messageAuthor.textContent = 'Miss Thingette';

    const messageText = document.createElement('div');
    messageText.className = 'message-transition-congrats-text';
    messageText.textContent = 'Beau travail !!';


    text.appendChild(messageAuthor);
    text.appendChild(messageText);
    message.appendChild(avatar);
    message.appendChild(text);

    const body = document.getElementsByTagName('body')[0];
    const divRole = document.createElement('div');
    divRole.setAttribute("role", "dialog");
    divRole.setAttribute('id', "divRoleToDelete");
    divRole.style.transition = '500ms opacity linear';
    divRole.style = "position:absolute;top:0;left:0";
    body.appendChild(divRole);


    const divModalBackdrop = document.createElement('div');
    divModalBackdrop.setAttribute("class", "fade modal-backdrop in");
    divModalBackdrop.style.backgroundColor = 'transparent';
    divModalBackdrop.style.opacity = '1'
    divRole.appendChild(divModalBackdrop);
    divModalBackdrop.appendChild(message);


    const lastDiv = document.createElement('div');
    lastDiv.setAttribute("role", "dialog");
    lastDiv.setAttribute("tabindex", "-1");
    lastDiv.setAttribute("class", "fade in modal");
    lastDiv.setAttribute("style", "display: block;");
    lastDiv.style.backgroundColor = 'transparent';
    divModalBackdrop.appendChild(lastDiv);
    const animComp = <AnimationComponent key={Math.floor(Math.random() * 1000)}/>;
    ReactDOM.render(animComp, lastDiv);

    window.setTimeout(() => {
        message.style.opacity = '1'
    }, 200);


    window.setTimeout(() => {
        divRole.style.opacity = '0';

        window.setTimeout(() => {
            ReactDOM.unmountComponentAtNode(lastDiv);
            divRole.innerHTML = "";
            divRole.remove()
        }, 500)

    }, 3500)

}

function animationTransition() {
    return dispatch => {
        dispatch(animationStarted());
        createAnimation()
        dispatch(animationDone())
    }
}

function checkBlocklyGuidedTour(xmlVerif, solutions) {
    // console.log('xmlVerif', xmlVerif)
    return (dispatch, getState) => {
        if(xmlVerif) {
            const state = getState()
            const mainWorkspace = state.blockly.mainWorkspace
            const solutionWorkSpace = state.blockly.solutionWorkspace

            const setupBlock = mainWorkspace.getAllBlocks().find(block => block.type === "setup")
            const setupBlockSol = solutionWorkSpace.getAllBlocks().find(block => block.type === "setup")
            
            const courseCompareBlocklyReport = courseCompareBlockly(setupBlock, setupBlockSol, mainWorkspace, solutionWorkSpace)
            if(courseCompareBlocklyReport.length === 0) {
                console.log('La solution est bonne')
                // console.log('NEXT STEP TRUE -> checkBlocklyGuidedTour')
                dispatch(guidedTourActions.goNextStep(solutions))
            } else {
                console.log('La solution est FAUSSE')
            }
        } 
    }
}

//FAIS LA COMPARAISON ET DISPATCH INCREMENT AVANCEMENT OU INCREMENT AVANCEMENT FAILED
function checkBlockly(progressionId) {
    return (dispatch, getState) => {
        try{
            const state = getState();
            const progression = state.model.progressions.entities[progressionId];
            const step = state.model.steps.entities[progression.step];
            const course = state.model.courses.entities[step.course];
            const courseId = step.course;

            if (course.isActivity) {
                dispatch(progressionsActions.nextStep(progressionId));
                return;
            }
            
            if (state.extension.pendingCompilation) {
                return
            }


            if (step.event === 'HardwareEvent') {
                //dispatch(modalActions.destroyAllModalMessage())
                
                dispatch(progressionsActions.nextStep(progressionId))
                if(step.currentPositionInCourse === state.model.courses.entities[courseId].stepsCount){
                    dispatch(animationTransition())}
                return
            }
            
            // We now allow going next step without testing
            // if (state.extension.compilationCode === null) {
            //     dispatch(modalActions.addModalMessageMid({
            //         type: modalConstants.MODAL_MESSAGE_TYPE['CHECK_ERROR_NO_COMPIL']
            //     }));
            //     return
            // }

            // if (state.extension.compilationCode === null) {
            //     try{
            //         window.analytics.track(`Xml verification no compilation - ${step.description} ${step._id}`, {progression, step, course, verificationError: courseCompareBlocklyReport})   
            //     }catch(error){
            //         window.analytics.track('Xml verification segment error', {error})
            //     }
            //     dispatch(modalActions.addModalMessageMid({
            //         type: modalConstants.MODAL_MESSAGE_TYPE['CHECK_ERROR_NO_COMPIL']
            //     }));
            //     return
            // }

            if(step.event === 'BlocklyConstructionNoVerif' || step.event == "PlugBrick"){
                
                dispatch(progressionsActions.nextStep(progressionId))
                if(step.currentPositionInCourse === state.model.courses.entities[courseId].stepsCount){
                    dispatch(animationTransition())}
                return
            }

            const workspace = state.blockly.mainWorkspace;
            const code = window.Blockly.Arduino.workspaceToCode(workspace);

            if (code && code === state.coursePage.lastTestedCode) {
                try{
                    window.analytics.track(`Verification no modification - ${step.description} ${step._id}`, {progression, step, course})   
                }catch(error){
                    if(window.analyctics)
                        window.analytics.track('Verification segment error', {error})
                }
                dispatch(modalActions.addModalMessageMid({
                    type: modalConstants.MODAL_MESSAGE_TYPE['CHECK_ERROR_NO_MODIF']
                }));
                return
            }
            dispatch(updateLastTestedCode(code));
            console.log(" in block")
            const workspaceSol = state.blockly.solutionWorkspace;
            console.log(workspace.getAllBlocks());
            console.log(workspace.getAllBlocks().find(block => block.type === "only_loop"));
            console.log(workspaceSol.getAllBlocks());
            
            const setupBlock = workspace.getAllBlocks().find(block => block.type === "setup");
            const setupBlockSol = workspaceSol.getAllBlocks().find(block => block.type === "setup")
            const courseCompareBlocklyReport = courseCompareBlockly(setupBlock, setupBlockSol, workspace, workspaceSol);
            if (courseCompareBlocklyReport.length > 0) {
                if(window.analyctics){
                    try{
                        window.analytics.track(`Verification error - ${step.description} ${step._id}`, {progression, step, course, courseCompareBlocklyReport})   
                    }catch(error){
                        window.analytics.track('Verification segment error', {error})
                    }
                }
                
                dispatch(modalActions.addModalMessageMid({
                    type: modalConstants.MODAL_MESSAGE_TYPE['CHECK_ERROR'],
                }))
            

            } else {
                if(window.analyctics){
                    try{
                        window.analytics.track(`Verification done - ${step.description} ${step._id}`, {progression, step, course})   
                    }catch(error){
                        window.analytics.track('Verification segment error', {error})
                    }
                }
                dispatch(extensionActions.resetCompilationCode());
                dispatch(animationTransition())
                dispatch(progressionsActions.nextStep(progressionId))
            }
        }catch(e){
            if(window.analytics){
                try{
                    window.analytics.track(`Verification unknown error`, {error: e})   
                }catch(error){
                    window.analytics.track('Verification segment error', {error})
                }
            }
            console.log(e)
            dispatch(modalActions.addModalMessageMid({
                type: modalConstants.MODAL_MESSAGE_TYPE['CHECK_UNKNOWN_ERROR'],
            }))
        }
    }
}

//CONSTRUIT LES STATEMENTS D'UN BLOC
function statementBuild(inputList, blockStatementRef, id) {

    blockStatementRef[id] = {};

    for (let input of inputList) {

        if (input.connection) {
            blockStatementRef[id][input.name] = {};
            blockStatementRef[id][input.name].tab = [];
            var block = input.connection.targetBlock();
            blockStatementRef[id][input.name].order = true;
            if (input._xmlAttributes && input._xmlAttributes.notOrder && input._xmlAttributes.notOrder === "true") {
                blockStatementRef[id][input.name].order = false;
            }
        }

        while (block) {
            blockStatementRef[id][input.name].tab.push(block);
            block = block.getNextBlock();
        }
    }
}

//COMPARE LES FIELDS DE DEUX BLOCS --
function fieldCompare(inputList, inputListSol, dicoBlock, tabError, block) {

    for (let x = 0; x < inputList.length; x++) {

        var tabField = inputList[x].fieldRow;
        var tabFieldSol = inputListSol[x].fieldRow;

        for (let i = 0; i < tabField.length; i++) {


            if (tabField[i] && tabFieldSol[i] && tabField[i].name && tabFieldSol[i].name) {

                if (UNIQUE_ID_BLOCKLY.includes(tabFieldSol[i].name)) {
                    if (dicoBlock[tabFieldSol[i].getValue()] &&
                        dicoBlock[tabFieldSol[i].getValue()] !== tabField[i].getValue()) {
                        updateTabErrorBlockly(coursePageConstants.CHECK_BLOCKLY_ERROR.BLOCKY_UNIQUE_ID_ERROR, tabError, block);
                        return tabError
                    } else {
                        dicoBlock[tabFieldSol[i].getValue()] = tabField[i].getValue()
                    }
                }
                else if (IGNORED_FIELD_NAME.includes(tabFieldSol[i].name)) {

                }
                else if (tabFieldSol[i]._xmlAttributes.notStrict === "true" && tabField[i].getValue() !== "") {

                }
                else {
                    if (tabField[i].getValue() !== tabFieldSol[i].getValue()) {
                        updateTabErrorBlockly(tabField[i].name + " error".toLowerCase(), tabError, block)
                    }
                }
            }
        }
    }
}
//COMPARE DEUX BLOCKS AVEC LE TYPE ET LE NOMBRE D'ENFANTS
function compareBlockBase(block, blockSol, dicoblock, tabError) {
    // console.log('block' ,block)
    // console.log('blockSol' ,blockSol)
    // console.log('dicoblock' ,dicoblock)
    // console.log('tabError' ,tabError)
    if (block.type !== blockSol.type) {
        tabError.push({
            errorType: coursePageConstants.CHECK_BLOCKLY_ERROR.BLOCK_TYPE_ERROR,
            blockTypeSol: blockSol,
            blockType: block
        })
        return tabError
    }

    const tabInput = block.inputList;
    const tabInputSol = blockSol.inputList;

    if (tabInput.length !== tabInputSol.length) {
        updateTabErrorBlockly("message", tabError, block);
        return tabError
    }
}

const tabError = [];
const UNIQUE_ID_BLOCKLY = ['NOM', 'VAR', 'NOMVAR'];
const IGNORED_FIELD_NAME = ['ID', 'GROUP', 'EMAIL', 'TEXT', 'RESEAU'];

function courseCompareBlockly(block, blockSol, workspace, workspaceSol, dicoBlock = {}, blockStatementRef = {}, blockStatementRefSol = {}) {

    if (block.type === "setup") {
        while (tabError.length) {
            tabError.pop()
        }
    }

    if (!block || !blockSol) {
        return tabError
    }

    compareBlockBase(block, blockSol, dicoBlock, tabError);
    if (tabError.length) {
        return tabError
    }

    fieldCompare(block.inputList, blockSol.inputList, dicoBlock, tabError, block);
    if (tabError.length) {
        return tabError
    }

    statementBuild(block.inputList, blockStatementRef, block.id);
    statementBuild(blockSol.inputList, blockStatementRefSol, block.id);

    for (let statement in blockStatementRef[block.id]) {

        var tabStatement = blockStatementRef[block.id][statement + ""].tab;
        var tabStatementSol = blockStatementRefSol[block.id][statement + ""].tab;

        if (tabStatement.length !== tabStatementSol.length) {
            updateTabErrorBlockly(coursePageConstants.CHECK_BLOCKLY_ERROR.CHILD_LENGTH_ERROR, tabError, block);
            return tabError
        }

        if (!tabStatement.length && tabStatementSol.length) {
            updateTabErrorBlockly("595", block);
            return tabError
        }

        if (tabStatement.length === 0 || tabStatementSol.length === 0) {
            continue
        }

        for (let x = 0; x < tabStatement.length; x++) {
            for (let y = 0; y < tabStatementSol.length; y++) {

                courseCompareBlockly(tabStatement[x], tabStatementSol[y], workspace, workspaceSol, dicoBlock, blockStatementRef, blockStatementRefSol)

                if (blockStatementRefSol[block.id][statement].order === true
                    && tabError.length > 0) {
                    return tabError
                }

                if (blockStatementRefSol[block.id][statement].order === true
                    && tabError.length === 0) {

                    tabStatementSol.splice(y, 1);
                    break
                }

                if (blockStatementRefSol[block.id][statement].order === false
                    && tabError.length
                    && y !== tabStatementSol.length - 1) {
                    while (tabError.length) {
                        tabError.pop()
                    }
                    continue
                }

                if (blockStatementRefSol[block.id][statement].order === false
                    && tabError.length
                    && tabStatementSol.length - 1 === y) {
                    updateTabErrorBlockly("message", tabError, block);
                    return tabError;
                }

                if (blockStatementRefSol[block.id][statement].order === false
                    && !tabError.length) {
                    while (tabError.length) {
                        tabError.pop()
                    }
                    break;
                }
            }
        }
    }
    return tabError;
}

function updateTabErrorBlockly(message, tabError, block) {
    tabError.push({
        message: message,
        block: block
    })
}

function updateLastTestedCode(code){
    return { type: coursePageConstants.UPDATE_LAST_TESTED_CODE, code};
}

function offlineError(){
    return dispatch => {
        dispatch({type: modalConstants.MODAL_MESSAGE_TYPE.OFFLINE_ERROR})
        dispatch(modalActions.addModalMessageMid({
            type: modalConstants.MODAL_MESSAGE_TYPE['OFFLINE_ERROR']
        }))
    }
}

function offlineRecovery(){
    return dispatch => {
        dispatch({type: modalConstants.MODAL_MESSAGE_TYPE.OFFLINE_RECOVERY})
        dispatch(modalActions.addModalMessageMid({
            type: modalConstants.MODAL_MESSAGE_TYPE['OFFLINE_RECOVERY']
        }))
    }
}