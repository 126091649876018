import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import DOMPurify from 'dompurify';


import HeaderComponent from "../../components/HeaderComponent/HeaderComponent";
import HeaderCourse from "../../components/HeaderCourse/HeaderCourse";

import { Blockly } from '../../containers/Blockly';
import { FloatingMessage } from '../../components/FloatingMessage';

import { Extension } from '../Extension';

import { coursesActions, stepsActions, modelActions, selectedCoursesActions } from '../../actions/model';
import { blocklyActions, modalActions, extensionActions } from '../../actions';
import {logout} from "../../actions/session_actions";


import runtimeEnv from '@mars/heroku-js-runtime-env';

import { modalConstants } from "../../constants";

import { Grid, Container, Segment, Message, Form, Header, Button, Popup } from 'semantic-ui-react';
import {SerialMonitor} from '../SerialMonitor'
import { Simulator } from '../Simulator';
import { getActualUser } from '../../reducers/user_reducer';


class ActivityReadOnly extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resetBlocklyRequest: false,
            messageVisible: true
        }
    }

    componentWillMount(){
        this.props.dispatch(modelActions.invalidate())
    }

    componentDidMount() {
        if (this.props.match.params.shareId) {
            this.props.dispatch(coursesActions.fetchActivityByShareId(this.props.match.params.shareId));
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
        if(!this.state.selectedCourse){
            const course = Object.values(this.props.courses.entities).find(course => {
                if(course.share === this.props.match.params.shareId)
                    return course;
            })

            if(course)
                this.setState({selectedCourse: course._id})
        }
    }

    componentWillUnmount() {
    }

    onCompileClick = () => {
        try{
            if(this.props.blockly.mainWorkspace === null)
                return;
             
            const code = window.Blockly.Arduino.workspaceToCode(this.props.blockly.mainWorkspace);
            this.props.dispatch(extensionActions.sendMessage({msg:{cmd:"compilation", data:code, url: runtimeEnv().REACT_APP_COMPILATION_SERVER, filterBoards: ["mini", "mega", "uno"]}, timeout:60000}))
        }catch(error){ 
             console.log ("Erreur blockly", error);
             if(error){
              this.props.dispatch(modalActions.addModalMessageMid({
                type: modalConstants.MODAL_MESSAGE_TYPE.COMBINATED_ERROR}
                    )
                )
            }

            return
        }
    }

    onMainWorkspaceStatusChanged = (workspace) => {
        if(workspace !== null){
            this.props.dispatch(blocklyActions.mainWorkspaceInitiated(workspace));
        }else{
            this.props.dispatch(blocklyActions.mainWorkspaceInvalid());
        }
    }

    renderSerialMonitor = () => {
		if(!this.props.serialMonitor.open)
			return null;
		return (
			<Grid.Row className={"FreeCreationRow"} centered>
				<Grid.Column style={{width:"80%"}}>
				<SerialMonitor/>
				</Grid.Column>
			</Grid.Row>
		)
    }

    resetApp = () => {
        this.setState({resetBlocklyRequest: true})
    }
    
    resetBlocklyDone = () => {
        this.setState({resetBlocklyRequest: false})
    }

    renderActionButton(){
        if(!this.props.user){
            return (
                <Popup trigger={<Button color="blue" size="small" onClick={()=>{this.props.history.push(`/register?type=instructor`)}}>Créer votre compte</Button>}>
                    <p>
                        Si vous souhaitez proposer cette activité à vos élèves vous devez créer un compte.
                    </p>
                    <p>
                        Vous pourrez ensuite ajouter cette activité à votre espace enseignant
                    </p>
                </Popup>
            )
        }else{
            
            // if(this.props.user._id === this.props.courses.entities[this.state.selectedCourse].user)
            //     return null
            if(this.props.users.entities[this.props.user._id] && this.props.users.entities[this.props.user._id].role && this.props.users.entities[this.props.user._id].role.type === "instructor"){
                return (
                    <Popup trigger={<Button color="blue" size="small" onClick={()=>{this.props.history.push(`/structure?tab=activities&add=`+this.props.match.params.shareId)}}>Ajouter à mon espace</Button>}>
                        <p>Ajouter cette activité à votre espace.</p>
                        <p>Vous pourrez ensuite la personnaliser et la proposer à vos élèves</p>
                    </Popup>
                )
            }else{
                return (
                    <Popup trigger={<Button color="blue" size="small" onClick={()=>{this.props.dispatch(logout(null, '/register?type=instructor'))}}>Créer votre compte enseignant</Button>}>
                        <p>Votre compte n'est pas un compte enseignant.</p>
                        <p>Vous devez en créer un si vous souhaitez ajouter vos élèves et leur proposer des activités</p>
                    </Popup>
                )
            }
        }
    }
    

    render() {

        const course = this.props.courses.entities[this.state.selectedCourse]

        const step = course ? this.props.steps.entities[course.firstStep] : null

        const styleHeader = {
            margin: 0
        }

        const styleSegment = {
            marginTop: "0.6em"
        }

        if(!course || !step)
            return "Chargement..."
        
        return(
            <div style={{display: 'flex', flexDirection: "column", flex: "1"}}>
                <Simulator/>
                <FloatingMessage messages={this.props.messages} />
                
                <Grid style={{display: "flex", flexFlow: "column", flex:"1", margin:"0", padding: "0"}}>
                    <Grid.Row className='HeaderRow'>
                        <HeaderComponent 
                            title={course.title}
                            hideUserStatusIfGuest={true} 
                        />
                    </Grid.Row>
                    <Extension/>

                    <HeaderCourse 
                        progression={{}}
                        step={step}
                        compilationLoading={this.props.extension.pendingCompilation}
                        resetApp={this.resetApp}
                        course = {course}
                        onCompileClick={this.onCompileClick}
                        noNextStepButton={true}
                    />
                    

                    <Grid.Row columns='equal'style={{display: "flex", flex: "1 0 auto", padding:0, margin:0}}>
                        <Grid.Column computer={4} style={{padding: "5px", display: "flex", flexDirection: "column", flex: "0 1 auto"}}>
                            
                           {this.state.messageVisible && <Message compact style={{display: "block", marginBottom: "0"}} onDismiss={()=>{this.setState({messageVisible: false})}}>
                                <p>
                                    Vous consultez une activité, vous pouvez l'essayer et, si vous le souhaitez, l'ajouter à votre compte
                                </p>
                           </Message>}

                            <Segment fluid style={{flex: 1}}>
                                <Header size="small" style={styleHeader}>Nom de l'activité</Header>
                                <Segment fluid style={styleSegment}>
                                    {course.title}
                                </Segment>
                                <Header size="small" style={styleHeader}>Consigne de l'activité</Header>
                                <Segment fluid style={styleSegment}>
                                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(step.instruction)}}>

                                    </div>
                                </Segment>
                                <Header size="small" style={styleHeader}>Auteur</Header>
                                <Segment fluid style={styleSegment}>
                                    {this.props.users.entities[course.user] ? <b>{this.props.users.entities[course.user].username}</b> : "Quelqu'un d'autre"}
                                </Segment>
                            </Segment>
                           
                           {this.renderActionButton()}
                            
                        </Grid.Column>
                        <Grid.Column style={{padding:0, margin:0, display: "flex", flexDirection: "column", flex: "1 0 auto"}}>
                            <Container id='CourseBlocklyComponent'>
                                <Blockly
                                    lang={window.LANG}
                                    xml={step.xmlStart}
                                    onMainWorkspaceStatusChanged={this.onMainWorkspaceStatusChanged}
                                    resetAppRequest={this.state.resetBlocklyRequest}
                                    resetAppDone={this.resetBlocklyDone}
                                    step={step}
                                />
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                    {this.renderSerialMonitor()}
                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        steps: state.model.steps,
        courses: state.model.courses,
        users: state.model.users,
        isOffline: state.modal.isOffline,
        blockly: state.blockly,
        extension: state.extension,
        messages: state.modal.messages,
        serialMonitor: state.serialMonitor,
        user: getActualUser(state)
    }
}

const connectedActivityReadOnly = withRouter(connect(mapStateToProps)(ActivityReadOnly));
export {connectedActivityReadOnly as ActivityReadOnly}