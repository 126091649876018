const unloadScript = (src) => {
    if (!document) {
        throw new Error("Document was not defined");
    } else {
        // Récupération du script
        const script = document.querySelectorAll("script[src='" + src + "']");
        // Conversion de la Node List en Array
        const convertedNodeList = Array.from(script);
        // Sélection de l'élément script à supprimer après avoir identifié et supprimé son prefix URL : http || https
        const scriptToRemove = convertedNodeList.find((script) => {
            // vérification du prefix URL : http || https
            const urlCheckerRegex = script.src.match(/^https:\/\/|^http:\/\//);
            let scriptSource = "";
            // suppression du prefix URL
            if (urlCheckerRegex && urlCheckerRegex.length > 0) {
                scriptSource = script.src.substring(urlCheckerRegex[0].length);
            }
            return scriptSource === window.location.host + src;
        });
        if (!scriptToRemove) {
            console.error(src + " n'a pas été trouvé dans la page.");
            return;
        }
        // Suppression du script selectionné
        scriptToRemove.parentNode.removeChild(scriptToRemove);
        console.log(src + " removed.");
    }
};

export default unloadScript;
